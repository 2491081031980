import React from 'react';
import * as Styles from './styles';

interface Props {
  title: string;
  children?: React.ReactNode;
}

export const Section = (props: Props) => (
  <Styles.SectionHolder>
    <Styles.Title>{props.title}</Styles.Title>
    <Styles.TitleUnderline />
    {props.children && props.children}
  </Styles.SectionHolder>
);
