/**
 *
 * ModalPoliticsAndPrivacy
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import Modal from '../Modal';
import PoliticsAndPrivacy from '../PoliticsAndPrivacy';

const ModalPoliticsAndPrivacy = ({ className, showPaP, onShowPaP }) => (
  <div className={cs(className)}>
    <button className="link-button" onClick={onShowPaP}>
      POLÍTICAS DE PRIVACIDAD
    </button>
    <Modal
      show={showPaP}
      className="tac-modal"
      closeButton={{ onClick: () => onShowPaP() }}
      header="POLÍTICA DE PRIVACIDAD"
      onClickOverlay={onShowPaP}
      size="large"
      headerCentered>
      <PoliticsAndPrivacy />
    </Modal>
  </div>
);

ModalPoliticsAndPrivacy.propTypes = {
  className: PropTypes.string.isRequired,
  showPaP: PropTypes.bool,
  onShowPaP: PropTypes.func
};

export default styled(ModalPoliticsAndPrivacy)`
  .link-button {
    border: 0;
    outline: none;
    background: transparent;
    color: white;
  }
`;
