/**
 *
 * NewCalendar
 *
 */

import React, { useRef } from 'react';
import DatePicker, {
  registerLocale,
  setDefaultLocale,
  ReactDatePickerProps
} from 'react-datepicker';
import styled, { css } from 'styled-components';
import es from 'date-fns/locale/es';
import { ThemeColor } from 'Theme/types';
import NewInput, { IInputError } from 'Components/NewInput';
import { parseIso, localLong, formatIso } from 'Utils/dates';
import Label from 'Components/Label';

registerLocale('es', es);
setDefaultLocale('es');

interface NewCalendarProps {
  startDate?: string;
  endDate?: string;
  onChangeStart: (startDate: string) => void;
  onChangeEnd?: (endDate: string) => void;
  startLabel?: string;
  endLabel?: string;
  color?: CalendarColors;
  minDate?: string;
  maxDate?: string;
  startError?: IInputError;
  endError?: IInputError;
  showLabels?: boolean;
  isRange?: boolean;
}

export interface CalendarColors {
  primaryColor: ThemeColor;
  lightColor: ThemeColor;
}

const popperModifiers: ReactDatePickerProps['popperModifiers'] = {
  preventOverflow: {
    padding: 40,
    enabled: true,
    escapeWithReference: false,
    boundariesElement: 'viewport'
  }
};

const NewCalendar = ({
  startDate,
  startLabel,
  color,
  onChangeStart,
  endLabel,
  endDate,
  onChangeEnd,
  maxDate,
  startError,
  endError,
  isRange,
  showLabels,
  minDate
}: NewCalendarProps) => {
  const endDatePickerRef = useRef<DatePicker>();

  const handleChangeStart = (date: Date) => {
    onChangeStart(formatIso(date));
    if (endLabel) {
      if (endDatePickerRef.current) {
        endDatePickerRef.current.setOpen(true);
      }
    }
  };

  const handleChangeEnd = (date: Date) => {
    onChangeEnd(formatIso(date));
  };

  return (
    <Container
      color={color?.primaryColor}
      margin={Boolean(isRange)}
      lightColor={color?.lightColor}>
      <CalendarHolder>
        {showLabels && <Label color={color?.primaryColor}>{startLabel}</Label>}
        <DatePicker
          customInput={
            // TODO: USER SHOULD BE ABLE TO REMOVE THE SELECTED DATE
            <NewInput
              disabled
              icon={{
                name: 'mv-calendar',
                color: color?.primaryColor,
                size: 20
              }}
              color={color?.primaryColor}
              error={startError}
            />
          }
          selectsStart
          placeholderText={startLabel}
          startDate={startDate && parseIso(startDate)}
          endDate={endDate && parseIso(endDate)}
          dateFormat={localLong}
          monthsShown={2}
          minDate={parseIso(minDate)}
          onChange={handleChangeStart}
          selected={startDate && parseIso(startDate)}
          maxDate={parseIso(maxDate)}
          popperModifiers={popperModifiers}
        />
      </CalendarHolder>

      {isRange && (
        <CalendarHolder>
          {showLabels && <Label color={color?.primaryColor}>{endLabel}</Label>}
          <DatePicker
            customInput={
              // TODO: USER SHOULD BE ABLE TO REMOVE THE SELECTED DATE
              <NewInput
                disabled
                icon={{
                  name: 'mv-calendar',
                  color: color?.primaryColor,
                  size: 20
                }}
                color={color?.primaryColor}
                error={endError}
              />
            }
            ref={endDatePickerRef}
            selectsEnd
            startDate={startDate && parseIso(startDate)}
            endDate={endDate && parseIso(endDate)}
            placeholderText={endLabel}
            dateFormat={localLong}
            monthsShown={2}
            minDate={startDate && parseIso(startDate)}
            onChange={handleChangeEnd}
            selected={endDate && parseIso(endDate)}
            popperModifiers={popperModifiers}
          />
        </CalendarHolder>
      )}
    </Container>
  );
};

interface ContainerProps {
  color?: ThemeColor;
  lightColor?: ThemeColor;
  margin: boolean;
}

const CalendarHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 60px;
`;

const Container = styled.div<ContainerProps>`
  ${({ theme, color, lightColor, margin }) => css`
    display: flex;
    .react-datepicker-wrapper:first-of-type {
      margin-right: ${margin && theme.spacing.two};
    }
    
    .react-datepicker {
      font-family: Montserrat, serif;
      font-size: 16px !important;
    }

    .react-datepicker__month-container {
      margin-right: ${theme.spacing.four};
    }
    .react-datepicker__month-container:last-child {
      margin-right: 0;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker__day,
    .react-datepicker-year-header {
      font-family: Montserrat, serif;
      line-height: 0.86;
      letter-spacing: 0.18px;
      color: ${color ? theme.colors[color] : theme.colors.important};
      text-transform: capitalize;
    }

    .react-datepicker__current-month {
      font-size: ${theme.text.fontSize.headings.medium} !important;
      padding: ${theme.spacing.two};
    }

    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      background-color: ${
  lightColor ? theme.colors[lightColor] : theme.colors.orangeLight
} !important;
      color: ${theme.colors.white} !important;
    }

    .react-datepicker__day--in-range {
      background-color: ${theme.colors.orangeLight} !important;
      color: ${theme.colors.white} !important;
      border-radius: 0 !important;
    }

    .react-datepicker__day--selected {
      background-color: ${
  color ? theme.colors[color] : theme.colors.important
} !important;
      color: ${theme.colors.white} !important;
      border-radius: ${theme.borderRadius.smooth} !important;
    }

    .react-datepicker__day--disabled {
      color: ${theme.colors.darkGrey} !important;
      background-color: ${theme.colors.white} !important;
    }

    .react-datepicker__week,
    .react-datepicker__day-names {
      display: flex;
      margin-bottom: ${theme.spacing.one};
      justify-content: space-around;
    }
    .react-datepicker__day--today {
      background-color: white;
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
      display: flex;
      width: 40px;
      height: 30px;
      padding: ${theme.spacing.one}
      justify-content: center;
      align-items: center;
      margin: 0 !important;
    }

    .react-datepicker__day:hover {
      border-radius: ${theme.borderRadius.smooth};
      border: 1.5px solid
        ${color ? theme.colors[color] : theme.colors.important} !important;
      background-color: ${theme.colors.white} !important;
      color: ${theme.colors.important} !important;
      cursor: pointer;
    }

    .react-datepicker__navigation {
      top: 15px;
    }

    .react-datepicker__navigation--next,
    .react-datepicker__navigation--next:hover {
      border-left-color: ${
  color ? theme.colors[color] : theme.colors.important
};
    }

    .react-datepicker__navigation--previous--disabled,
    .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: ${theme.colors.darkGrey} !important;
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--previous:hover {
      border-right-color: ${
  color ? theme.colors[color] : theme.colors.important
} !important;
    }

    .react-datepicker__header {
      border: 0;
      background-color: ${theme.colors.white};
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end {
      background-color: ${
  color ? theme.colors[color] : theme.colors.orange
} !important;
      color: ${theme.colors.white} !important;
    }

    .react-datepicker__day--today {
      font-weight: normal;
      position: relative;
      &::after {
        content: 'HOY';
        font-size: 8px;
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;
      }
    }

    .react-datepicker__day--range-end {
      border-radius: 0 !important;
      border-top-right-radius: ${theme.borderRadius.smooth} !important;
      border-bottom-right-radius: ${theme.borderRadius.smooth} !important;
    }
    .react-datepicker__day--range-start {
      border-radius: 0 !important;
      border-top-left-radius: ${theme.borderRadius.smooth} !important;
      border-bottom-left-radius: ${theme.borderRadius.smooth} !important;
    }
  `}
`;

export default NewCalendar;
