/**
 *
 * Badge
 *
 */

import React from 'react';
import styled from 'styled-components';

import Theme from 'Theme';
import Icon, { TIconName } from 'Components/Icon';

type BadgeVariation =
  | 'default'
  | 'orange'
  | 'positive'
  | 'information'
  | 'violet'
  | 'warning'
  | 'yellow';

interface BadgeProps {
  variation?: BadgeVariation;
  inverted?: boolean;
  children: React.ReactNode;
  leftIcon?: TIconName;
  rightIcon?: TIconName;
}
const Badge = ({
  children,
  variation = 'default',
  leftIcon,
  rightIcon,
  inverted = false,
  ...rest
}: BadgeProps) => (
  <Container variation={variation} inverted={inverted} {...rest}>
    {leftIcon && (
      <LeftIcon
        name={leftIcon}
        hardColor={getColors(variation, inverted).color}
      />
    )}
    {children}
    {rightIcon && (
      <RightIcon
        name={rightIcon}
        hardColor={getColors(variation, inverted).color}
      />
    )}
  </Container>
);

const Container = styled.span<BadgeProps>`
  display: inline-flex;
  align-items: center;
  width: auto;
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  font-size: ${({ theme }) => theme.text.fontSize.body.default};
  font-weight: ${({ theme }) => theme.text.fontWeights.medium};
  line-height: 15px;
  text-align: center;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  padding: ${({ theme }) => `${theme.spacing.one} ${theme.spacing.two}`};
  min-width: 30px;
  box-shadow: ${({ theme }) => theme.shadows.one};
  ${({ variation, inverted }) => {
    const { color, bgColor } = getColors(variation, inverted);
    return `
    background-color: ${bgColor};
    color: ${color};
    `;
  }}
`;

const LeftIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing.one};
`;

const RightIcon = styled(Icon)`
  margin-left: ${({ theme }) => theme.spacing.one};
`;

export default Badge;

const getColors = (
  variation: BadgeVariation,
  inverted: boolean
): { color: string; bgColor: string } => {
  let color: string;
  let bgColor: string = Theme.palette.grays.white;

  switch (variation) {
    case 'default':
      color = Theme.palette.grays.meVueloGrey;
      break;
    case 'orange':
      color = Theme.palette.primary.orange;
      break;
    case 'information':
      color = Theme.palette.status.info;
      break;
    case 'positive':
      color = Theme.palette.status.positive;
      break;
    case 'violet':
      color = Theme.palette.primary.violet;
      break;
    case 'warning':
      color = Theme.palette.status.warning;
      break;
    case 'yellow':
      color = Theme.colors.yellow;
  }

  return inverted ? { bgColor: color, color: bgColor } : { color, bgColor };
};
