/**
 *
 * PackageThankyou
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'Components/Card';
import Text from 'Components/Text';
import Button from 'Components/Button';
import { goHome } from '../../actions';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import MetaTags from 'react-meta-tags';

const selector = ({ packages }) => ({
  firstName: packages.firstName
});

const ConnectedPackageThanyou = props => {
  const dispatch = useDispatch();
  const actions = {
    goHome: () => dispatch(goHome())
  };
  const state = useSelector(selector);
  return <PackageThankyou {...props} {...state} actions={actions} />;
};

const PackageThankyou = ({ firstName, actions }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { mediumDown } = useBreakpoints();

  return (
    <Container mobile={mediumDown}>
      <MetaTags>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
        <meta name="googlebot" content="noindex" />
        <meta name="googlebot-news" content="nosnippet" />
      </MetaTags>
      <Content>
        <Text context="important" size="xlarge" weight="semi-bold" paragraph>
          {firstName}, ¡ya estamos volando para vos!
        </Text>
        <Text context="secondary" size="large" weight="semi-bold">
          Nuestra tripulación ya está trabajando en tu consulta.
        </Text>
        <Text paragraph>
          En las próximas horas recibirás un contacto con toda la información de
          tu consulta. Si no lo recibis, revisá la carpeta de correo no deseado
          o spam, a veces los correos se esconden ahí.
        </Text>
        <ButtonContainer>
          <Button onClick={actions.goHome}>Volver al inicio</Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

PackageThankyou.propTypes = {
  firstName: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    goHome: PropTypes.func.isRequired
  })
};

const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding: ${({ theme }) => theme.spacing.two};
  margin: 0 auto;
  max-width: 720px;
  ${({ mobile }) => !mobile && 'min-height: 100vh;'}
`;

const Content = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.two};
`;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.two};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default ConnectedPackageThanyou;
export { PackageThankyou };
