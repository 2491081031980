/**
 *
 * NavBar
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cs from 'classnames';
import { push } from 'connected-react-router';
import { useDispatch, useSelector, batch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

// Components
import MobileNavBar from './MobileNavbar';
import Icon from 'Components/Icon';
import Link from 'Components/Link';

// Helpers
import { getEngine, removeCharacter, isEmpty } from 'Utils';
import { useUser } from 'Features/users/utils';
import { useIsScrollTopHook } from 'Utils/hooks/useIsScrollTop';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { cleanSearch } from 'Features/flights';
import { resetHotelsEngine, cleanError } from 'Features/hotels';
import { resetToEngine } from 'ReduxConfig/availability';
import {
  setActive,
  reset as resetDestinations
} from 'Features/destinations/actions';

// Constants
import * as colors from 'Constants/colors';
import { engines, routes } from 'Constants';

// Assets
//import whiteLogo from 'Assets/mevuelo-blanco.svg';
import whiteLogo from 'Assets/logo-blanco-navidad.svg';
import { isCorpo } from '../../../../Services/Users/types';

const selector = ({
  router,
  availability,
  appConfiguration,
  destinations
}) => ({
  router,
  availability,
  smallMode: appConfiguration.navBarSmallMode,
  hideNavbar: appConfiguration.hideNavbar,
  isDestination: destinations.active && !isEmpty(destinations.data)
});

const NavBar = ({ className }) => {
  const [showMenu, setShowMenu] = useState(false);
  const optionsRef = useRef(null);
  const routing = routes;
  const {
    router,
    availability,
    smallMode,
    hideNavbar,
    isDestination
  } = useSelector(selector);
  const isHomeTop = useIsScrollTopHook();
  const { mediumDown } = useBreakpoints();
  // TODO sacar los estatus a una constante
  const unused = availability.status === 'unused';
  const user = useUser();
  const dispatch = useDispatch();

  const activeNav = removeCharacter(router.location.pathname, '/');

  const engineActive = activeNav ? getEngine(activeNav) : engines.FLIGHTS.name;
  const applyDestinations =
    engineActive === engines.FLIGHTS.name && isDestination;

  // Include here the routes that leave the navbar fixed
  //TODO: change this to a isExcluded, the excluded array does not work in cases we want to exclude a whole engine
  //      or we want to exclude a route that has params
  const excluded = [
    routing.whoWeAre,
    routing.packageThankyou,
    routing.newCheckout
  ].map(e => removeCharacter(e, '/'));
  excluded.push(routing.packageItem.split('/')[1]);

  const handleSelect = (engineName, enginePath) => () => {
    batch(() => {
      dispatch(push(enginePath));
      dispatch(resetToEngine(engineName));
      dispatch(setActive(false));
      dispatch(cleanError());
    });
  };

  const handleShowMenu = () => {
    setShowMenu(old => !old);
  };

  const handleClickOutside = e => {
    if (optionsRef.current && !optionsRef.current.contains(e.target)) {
      setShowMenu(false);
    }
  };

  const goHome = () => {
    dispatch(resetToEngine(engines.FLIGHTS.name));
    dispatch(cleanSearch());
    dispatch(resetHotelsEngine());
    dispatch(resetDestinations());
  };

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 300,
    variableWidth: true
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  if (mediumDown) {
    //TODO: aplicar layout para ver si queremos o no el footer y el navbar
    if (router.location.pathname.indexOf('newCheckout') >= 0) {
      return null;
    }
    return (
      <MobileNavBar
        settings={settings}
        engines={engines}
        activeEngine={availability.engine}
        handleSelect={handleSelect}
        hideHeader={!isHomeTop}
        onLogoClick={goHome}
        smallMode={smallMode}
        hidden={hideNavbar}
      />
    );
  }

  const activateHomeTopClass =
    isHomeTop &&
    unused &&
    !excluded.includes(activeNav) &&
    !window.location.pathname.includes('checkout') &&
    !window.location.pathname.includes('blog') &&
    !window.location.pathname.includes('asistencias');

  return (
    <>
      <header
        className={cs(className, {
          'home-top':
            isHomeTop &&
            unused &&
            !excluded.includes(activeNav) &&
            engineActive !== 'blog' &&
            engineActive !== 'asistencias' &&
            engineActive !== 'Asistencias' &&
            activeNav.indexOf(excluded[2]) !== 0 &&
            !applyDestinations
        })}>
        <nav className="nav container">
          <LogoHolder>
            <Link to={routes.home} onClick={goHome}>
              <StyledLogo
                alt="mevuelo-logo"
                src={whiteLogo}
                isHomeTop={!activateHomeTopClass}
              />
              <StyledLogo
                white
                alt="mevuelo-logo"
                src={whiteLogo}
                isHomeTop={activateHomeTopClass}
              />
            </Link>
          </LogoHolder>

          <div>
            {/* TODO: THIS COULD BE A REUSABLE COMPONENT */}


            <ul className="navbar-ul">
              {Object.values(engines).map(engine => {
                // TODO: THIS IS A TEMPORAL FIX
                if (!user && engine.isPrivate) {
                  return;
                }
                if (isCorpo(user) && !engine.showInCorpo) {
                  return;
                }

                return (
                  <li className="navbar-li" key={engine.name}>
                    <Link
                      to={engine.path}
                      onClick={handleSelect(engine.name, engine.path)}
                      className={cs(
                        { active: engine.name === engineActive },
                        engine.name
                      )}>
                      {engine.name}
                    </Link>
                  </li>
                   
                );
              })}
              <li className="navbar-li">
                {user && (
                  <UserContainer isOpen={showMenu}>
                    <User onClick={handleShowMenu}>
                      <span style={{ marginLeft: 8, overflow: 'hidden', textOverflow: 'ellipsis', width: '150px', whiteSpace: 'nowrap' }}>{user.name}</span>
                      <Icon name="mv-user-circle" color="important" size={20} />
                    </User>

                    <CSSTransition
                      in={showMenu}
                      timeout={300}
                      unmountOnExit
                      classNames="options">
                      <Options ref={optionsRef}>
                        <Option
                          onClick={() => setShowMenu(false)}
                          to={routing.logout}>
                          Salir
                          <Icon name="mv-arrow-back" color="important" size={13} />
                        </Option>
                      </Options>
                    </CSSTransition>
                  </UserContainer>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </header>
      {/* Este espejado, pero con position relative, empuja el contenido del resto del sitio */}
      <div
        style={{ visibility: 'hidden', position: 'relative' }}
        className={cs(
          className,
          {
            'home-top':
              isHomeTop &&
              unused &&
              !excluded.includes(activeNav) &&
              engineActive !== 'blog' &&
              activeNav.indexOf(excluded[2]) !== 0
          },
          'mobile-header'
        )}
      />
    </>
  );
};

NavBar.propTypes = {
  className: PropTypes.string.isRequired,
  isHomeTop: PropTypes.bool,
  isHomeScrollUp: PropTypes.bool
};

export default styled(NavBar)`
  width: 100%;
  height: 72px;
  background-color: ${colors.newBackground};
  transition: background-color 0.6s ease-in-out;
  z-index: 99;
  position: fixed;
  top: 0;
  border-bottom: 2px solid ${colors.important};

  &.is-hidden {
    display: none;
  }

  &.home-top {
    background-color: transparent;
    border-bottom: 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    position: relative;

    .logo {
      width: 172px;
      position: relative;
      top: -6px;
    }

    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .navbar-ul {
    list-style-type: none;
    margin-right: 10px;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    align-items: flex-end;
    transition: 0.2s;
    position: absolute;
    right: 0;
    bottom: 2px;

    .navbar-li {
      padding: 0 8px;

      a {
        cursor: pointer;
        display: block;
        text-decoration: none;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-weight: 100;
        font-style: normal;
        font-stretch: normal;
        line-height: 17px;
        letter-spacing: 0.16px;
        text-align: left;
        color: ${colors.white};
        text-transform: capitalize;

        &.active {
          color: ${colors.yellow};
          font-weight: 600;
        }

        &:hover {
          color: ${colors.yellow};
        }
      }
    }
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.colors.white};
    margin-right: ${({ theme }) => theme.spacing.one};
  }
`;

const UserContainer = styled.div`
  position: relative;
  transition: all 300ms ease;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.smaller};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.smaller};
  z-index: 99;
  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.important};
      box-shadow: ${({ theme }) => theme.shadows.two};
      padding: ${({ theme }) => theme.spacing.two};
      transform: translateZ(10px);

      span {
        color: ${({ theme }) => theme.colors.important};
      }
    `};

  .options-enter {
    opacity: 0;
  }
  .options-enter-active {
    opacity: 1;
    transition: opacity 300ms ease;
  }
  .options-exit {
    opacity: 1;
  }
  .options-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
  }
`;

const Options = styled.ul`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.two};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.smaller};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.smaller};
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
  z-index: 90;
  overflow: hidden;
`;

const Option = styled(Link)`
  color: ${({ theme }) => theme.colors.important};
  font-weight: ${({ theme }) => theme.text.fontWeights.semibold};
  font-size: ${({ theme }) => theme.text.fontSize.body.small};
  padding: ${({ theme }) => theme.spacing.two}
    ${({ theme }) => theme.spacing.four};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 300ms ease;

  &:hover {
    color: ${({ theme }) => theme.colors.important};
    background-color: ${({ theme }) => theme.colors.greyHoverBkg};
  }
`;

const StyledLogo = styled.img`
  position: absolute;
  opacity: ${({ isHomeTop }) => (isHomeTop ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  width: 172px;
  top: ${props => (props.white ? '-56px' : '-56px')};
`;

const LogoHolder = styled.div`
  position: relative;
`;
