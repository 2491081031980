/**
 *
 * HotelAvailabilityDesktop
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import HotelAvailabilityMap from '../HotelAvailabilityMap';
import HotelCard from '../HotelCard';
import MenuTab from 'Components/MenuTab';
import FilterHolder from 'Components/FilterHolder';
import StarsFilter from 'Components/StarsFilter';
// import Slider from 'Components/Slider';
// import Radio from 'Components/Radio';
import Checkbox from 'Components/Checkbox';
import Input from 'Components/Input';
import { typeOfOrderPrice } from 'Constants';

import RatingFilterOption from 'Components/RatingFilterOption';
import { toggleMap } from '../../actions';
import { RootState } from 'ReduxConfig/store';

const HotelAvailabilityDesktop = ({
  className,
  orderBy,
  setOrderBy,
  ratingFilter,
  handleSelectStartFilter,
  searchNameValue,
  handleSearchHotelNameChange,
  // sliderMin,
  // sliderMax,
  // fareRange,
  // handleFareFilterChange,
  // fareType,
  // handleSelectFareType,
  handleFacilityFilterClick,
  facilities,
  selectedFilters,
  response,
  paginatedResponse,
  totalPeople,
  onSelectHotelProperty,
  addPage,
  hasMoreOptions,
  ratingOptions,
  reviewFilter,
  handleReviewFilterClick,
  initialCenter,
  radiusInMeters
}) => {
  // TODO disconnect component and use props
  const dispatch = useDispatch();
  const isMap = useSelector(({ hotels }: RootState) => hotels.search.showMap);

  const setMap = value => () => {
    if (value && !isMap) {
      dispatch(toggleMap());
    }
    if (!value && isMap) {
      dispatch(toggleMap());
    }
  };

  return (
    <div className={cs(className)}>
      <div className="container">
        <div className="row">
          <div className="col-3">
            {/* Filtros */}
            <MenuTab>
              <MenuTab.Item active={!isMap} onClick={setMap(false)}>
                LISTADO
              </MenuTab.Item>
              <MenuTab.Item active={isMap} onClick={setMap(true)}>
                MAPA
              </MenuTab.Item>
            </MenuTab>
            {/* TODO 
              //@ts-ignore */}
            <FilterHolder active title="CATEGORÍA">
              <div className="align-center">
                {/* TODO 
              //@ts-ignore */}
                <StarsFilter
                  initialValue={ratingFilter}
                  onSelect={handleSelectStartFilter}
                />
              </div>
            </FilterHolder>
            {/* TODO 
              //@ts-ignore */}
            <FilterHolder active title="BUSCAR POR NOMBRE">
              <div className="align-center">
                <Input
                  icon="mv-lense"
                  placeholder="Buscar..."
                  value={searchNameValue}
                  onChange={handleSearchHotelNameChange}
                />
              </div>
            </FilterHolder>

            {ratingOptions && (
              // TODO
              //@ts-ignore
              <FilterHolder active title="Valoración">
                <div className="align-center rating-holder">
                  {ratingOptions.map(option => (
                    // TODO
                    //@ts-ignore
                    <RatingFilterOption
                      key={`option-${option}`}
                      value={option}
                      active={reviewFilter === option}
                      onClick={handleReviewFilterClick}
                    />
                  ))}
                </div>
              </FilterHolder>
            )}
            {/* <FilterHolder active={true} title="VALORACIÓN" >
            <div className="align-center">
              <RatingBox>+ 1</RatingBox>
              <RatingBox>2</RatingBox>
              <RatingBox>3</RatingBox>
              <RatingBox>4</RatingBox>
              <RatingBox>5</RatingBox>
            </div>
          </FilterHolder> */}

            {/* TODO: Use when this is ready */}
            {/*
              <FilterHolder active={true} title="PRECIO">
                <Slider
                  min={sliderMin()}
                  max={sliderMax()}
                  defaultValue={fareRange}
                  onChange={handleFareFilterChange}
                  currency="USD"
                />
                <div className="filter-by-price-types ">
                  <Radio
                    checked={fareType === typeOfFareFilter.noche}
                    value={typeOfFareFilter.noche}
                    onClick={handleSelectFareType}
                    context="default">
                    Por noche
                  </Radio>
                  <Radio
                    checked={fareType === typeOfFareFilter.total}
                    value={typeOfFareFilter.total}
                    onClick={handleSelectFareType}
                    context="default">
                    Total
                  </Radio>
                </div>
              </FilterHolder>
            */}

            {facilities && (
              // TODO
              // @ts-ignore
              <FilterHolder active title="SERVICIOS">
                {facilities.map(facility => (
                  // @ts-ignore
                  <Checkbox
                    key={facility.id}
                    checked={selectedFilters.includes(facility.id)}
                    value={facility.id}
                    onClick={handleFacilityFilterClick}>
                    {facility.name}
                  </Checkbox>
                ))}
              </FilterHolder>
            )}
          </div>
          <div className="col-9">
            {isMap ? (
              // TODO
              // @ts-ignore
              <HotelAvailabilityMap
                initialCenter={initialCenter}
                hotels={response}
                radiusInMeters={radiusInMeters}
                onHotelClick={onSelectHotelProperty}
                totalPeople={totalPeople}
              />
            ) : (
              <>
                <MenuTab>
                  <MenuTab.Item
                    onClick={() => setOrderBy(typeOfOrderPrice.byPriority)}
                    active={orderBy === typeOfOrderPrice.byPriority}>
                    +CONVENIENTES
                  </MenuTab.Item>
                  <MenuTab.Item
                    active={orderBy === typeOfOrderPrice.byPrice}
                    onClick={() => setOrderBy(typeOfOrderPrice.byPrice)}>
                    +ECONÓMICOS
                  </MenuTab.Item>
                </MenuTab>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={addPage}
                  hasMore={hasMoreOptions}
                  loader={
                    <div
                      key="loading"
                      style={{ color: 'white' }}
                      className="loader">
                      Cargando ...
                    </div>
                  }>
                  {paginatedResponse &&
                    paginatedResponse.length > 0 &&
                    paginatedResponse.map((hotelItem, index) => {
                      const {
                        breakfast,
                        allInclusive,
                        airConditioning,
                        wifi,
                        pool,
                        parking,
                        gym
                      } = hotelItem.facilities;
                      const {
                        address,
                        lowestPackagePerNightPrice,
                        lowestPackagePrice,
                        price,
                        totalNight,
                        reviews
                      } = hotelItem;
                      return (
                        // @ts-ignore
                        <HotelCard
                          key={index}
                          noLazy
                          address={address}
                          starRating={hotelItem.starRating}
                          keyRating={hotelItem.keyRating}
                          textRating={hotelItem.textRating}
                          propertyName={hotelItem.name}
                          thumbnail={hotelItem.defaultImage.imageUrl}
                          price={lowestPackagePerNightPrice}
                          wifi={wifi}
                          parking={parking}
                          airConditioning={airConditioning}
                          gym={gym}
                          pool={pool}
                          breakfast={breakfast}
                          isAllInclusive={allInclusive}
                          currency={price.currency}
                          priceByNight={lowestPackagePrice}
                          localCurrency={hotelItem.localPrice?.currency}
                          localNightPrice={
                            hotelItem.localPrice?.lowestPackagePerNightPrice
                          }
                          localPrice={hotelItem.localPrice?.lowestPackagePrice}
                          totalNight={totalNight}
                          totalPeople={totalPeople}
                          reviews={reviews}
                          onSelect={onSelectHotelProperty(hotelItem.hotelId)}
                        />
                      );
                    })}
                </InfiniteScroll>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

HotelAvailabilityDesktop.propTypes = {
  className: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  ratingFilter: PropTypes.number,
  handleSelectStartFilter: PropTypes.func,
  searchNameValue: PropTypes.string,
  handleSearchHotelNameChange: PropTypes.func,
  sliderMin: PropTypes.func,
  sliderMax: PropTypes.func,
  fareRange: PropTypes.array,
  handleFareFilterChange: PropTypes.func,
  fareType: PropTypes.string,
  handleSelectFareType: PropTypes.func,
  handleFacilityFilterClick: PropTypes.func,
  facilities: PropTypes.array,
  selectedFilters: PropTypes.array,
  response: PropTypes.array,
  paginatedResponse: PropTypes.array,
  totalPeople: PropTypes.number,
  onSelectHotelProperty: PropTypes.func,
  addPage: PropTypes.func,
  hasMoreOptions: PropTypes.bool,
  ratingOptions: PropTypes.array,
  reviewFilter: PropTypes.number,
  handleReviewFilterClick: PropTypes.func,
  radiusInMeters: PropTypes.number.isRequired,
  initialCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired
};

export default styled(HotelAvailabilityDesktop)`
  display: 'block';

  .align-center {
    display: flex;
    justify-content: center;
  }

  .filter-by-price-types {
    display: flex;
    margin-top: 22px;
    justify-content: space-evenly;
  }

  .rating-holder {
    display: flex;
    justify-content: space-between;
  }
`;
