export default {
  /** Elevation 1 - Principal, botones, cards, inputs, algunas moleculas. */
  one: '1px 1px 3px 0 rgba(0, 0, 0, 0.25)' as const,
  /** Elevation 2 - Secundario, Hover de botones,  Hover de cards */
  two: '2px 2px 6px 0 rgba(0, 0, 0, 0.25)' as const,
  /** Elevation 3 - Terciario lo usamos poco, PopUps, Toasts (si hubiese). */
  three: '2px 3px 12px 0 rgba(0, 0, 0, 0.25)' as const,
  /** Elevation 4 - Modales, Cards que se apoyen directamente sobre el fondo Azul. */
  four: '4px 4px 25px 0 rgba(0, 0, 0, 0.15)' as const,

  /** Only bottom */
  bottom: '0px 2px 3px 0 rgba(0, 0, 0, 0.25)' as const
};
