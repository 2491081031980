import React from 'react';
import styled, { css } from 'styled-components';
import Heading from 'Components/Heading';

const CheckoutContentHolder = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.default};
    position: relative;
    margin: ${theme.spacing.four} auto;
    ${theme.width.fullWidth};
    height: 100%;
  `};
`;

const CheckoutHeader = styled.div`
  ${({ theme }) => css`
    text-align: center;
    background-color: ${theme.colors.violeta};
    border-top-left-radius: ${theme.borderRadius.default};
    border-top-right-radius: ${theme.borderRadius.default};
    padding: 5px;
  `};
`;

const DekstopCheckout = ({ children }: { children: React.ReactNode }) => (
  <CheckoutContentHolder>
    <CheckoutHeader>
      <Heading color="white" fontWeight="semibold">
        COMPLETÁ TUS DATOS Y FINALIZÁ TU COMPRA
      </Heading>
    </CheckoutHeader>
    {children}
  </CheckoutContentHolder>
);

export default DekstopCheckout;
