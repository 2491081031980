import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import NumberFormat from 'react-number-format';
import * as COLORS from 'Constants/colors';
import { breakpoints, getBaseString } from 'Constants';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import Slider from 'react-slick';
import SliderArrows from 'Components/SliderArrows';
import GMap from 'Components/GMap';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { Helmet } from 'react-helmet';
//import { returnImage } from 'Utils';
import { ogLanguage } from '../../../../Constants/index';
import { isValidIsoDate, parseIso } from '../../../../Utils/dates';
import { isWithinInterval, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setDepartureDateMinWithPrice, setDepartureDatePrice } from "../../actions";


const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    swipe: true,
    prevArrow: (
        <SliderArrows direction="mv-arrow-left" size={24} color="white" to="prev" />
    ),
    nextArrow: <SliderArrows size={24} color="white" to="next" />
};
const selector = ({ packages }) => ({
    departureDatePrice: packages.departureDatePrice,
    departureDateMinPriceSelected: packages.departureDateMinPriceSelected,
    departureDateMinPriceSelectedPrice: packages.departureDateMinPriceSelectedPrice,
});

const Package = ({ className, packageDetail }) => {
    const [description, setDescription] = useState(true);
    const [information, setInformation] = useState(false);
    const [optional, setOptional] = useState(false);
    const [housing, setHousing] = useState(false);
    const [fares, setFares] = useState(false);
    const [itinerary, setItinerary] = useState(false);
    const [ubication, setUbication] = useState(false);
    const [prices, setPrices] = useState(false);
    const dispatch = useDispatch();
    const {
        departureDatePrice,
        departureDateMinPriceSelectedPrice
    } = useSelector(selector);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const start = searchParams.get('start');
    const end = searchParams.get('end');

    const startDate = start && isValidIsoDate(start) ? start : null;
    const endDate = end && isValidIsoDate(end) ? end : null;


    useEffect(() => {
        if (departureDatePrice && isValidIsoDate(departureDatePrice)) {
            const priceFound = packageDetail.prices.find(price => {
                if (price.startDate && price.endDate) {
                    const startDate = parseIso(price.startDate);
                    const endDate = parseIso(price.endDate);
                    return isWithinInterval(parseIso(departureDatePrice), {
                        start: startDate,
                        end: endDate
                    });
                } else {
                    return departureDatePrice === price.startDate;
                }
            });
            if (priceFound) {
                if (process.env.REACT_APP_COUNTRY === 'CO' && priceFound.localPrice) {
                    dispatch(setDepartureDateMinWithPrice({
                        departureDateMinPriceSelected: priceFound.startDate,
                        departureDateMinPriceSelectedPrice: { amount: priceFound.localPrice.amount, currency: priceFound.localPrice.currency }
                    }));

                } else {
                    dispatch(setDepartureDateMinWithPrice({
                        departureDateMinPriceSelected: priceFound.startDate,
                        departureDateMinPriceSelectedPrice: { amount: priceFound.price.amount, currency: priceFound.price.currency }
                    }));
                }

            } else {
                dispatch(setDepartureDateMinWithPrice({
                    departureDateMinPriceSelected: departureDatePrice,
                    departureDateMinPriceSelectedPrice: null
                }));
            }
        } else if (startDate && endDate) {
            const startDateFromRange = parseIso(startDate);
            const endDateFromRange = parseIso(endDate);
            let minPriceFoundInRange = null;
            let minDateFoundInRange = null;
            packageDetail.prices.forEach(price => {
                const startDate = parseIso(price.startDate);
                const isStarDateOfPriceInRange = isWithinInterval(startDate, {
                    start: startDateFromRange,
                    end: endDateFromRange
                });
                if (isStarDateOfPriceInRange) {
                    if (minPriceFoundInRange === null || price.price.amount < minPriceFoundInRange.price.amount) {
                        minPriceFoundInRange = price;
                        minDateFoundInRange = price.startDate;
                    }
                }
            });
            if (minPriceFoundInRange) {
                dispatch(setDepartureDateMinWithPrice({
                    departureDateMinPriceSelected: minDateFoundInRange,
                    departureDateMinPriceSelectedPrice: { 
                        amount: minPriceFoundInRange.price.amount, 
                        currency: minPriceFoundInRange.price.currency 
                    }
                }));
            } else {
                dispatch(setDepartureDateMinWithPrice({
                    departureDateMinPriceSelected: startDate,
                    departureDateMinPriceSelectedPrice: null
                }));
            }
        } else {
            const priceWithDateEqualMinPrice = packageDetail.prices.find(x => x.price.amount === packageDetail.lowerPrice.amount);
            dispatch(setDepartureDateMinWithPrice({
                departureDateMinPriceSelected: priceWithDateEqualMinPrice?.startDate,
                departureDateMinPriceSelectedPrice: { 
                    amount: (process.env.REACT_APP_COUNTRY === 'CO' && packageDetail.lowerLocalPrice) ? packageDetail.lowerLocalPrice.amount : packageDetail.lowerPrice.amount, 
                     currency: (process.env.REACT_APP_COUNTRY === 'CO' && packageDetail.lowerLocalPrice) ?  packageDetail.lowerLocalPrice.currency : packageDetail.lowerPrice.currency 
                }
            }));

        }
    }, [startDate, endDate, departureDatePrice, packageDetail]);


    const getSlidesToShow = () =>
        window.innerWidth < breakpoints.medium ? 2 : 4;

    const { mediumDown } = useBreakpoints();

    const settingsInfo = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: getSlidesToShow(),
        slidesToScroll: 1,
        arrows: true,
        draggable: true,
        swipe: true,
        prevArrow: (
            <SliderArrows
                size={24}
                color="important"
                to={`infoprev ${mediumDown && 'mobile'}`}
                isMobile={mediumDown}
                packagesArrows
                direction="mv-arrow-left"
            />
        ),
        nextArrow: (
            <SliderArrows
                size={24}
                color="important"
                to="infonext"
                isMobile={mediumDown}
                packagesArrows
                direction="mv-arrow-right"
            />
        )
    };

    const infoItemsToFalse = () => {
        setDescription(false);
        setInformation(false);
        setOptional(false);
        setItinerary(false);
        setHousing(false);
        setFares(false);
        setUbication(false);
        setPrices(false);
    };

    const renderPriceCombined = price => {
        let childTags = [
            price.accommodationPrices.chTag1 ? price.accommodationPrices.chTag1 : '',
            price.accommodationPrices.chTag2 ? price.accommodationPrices.chTag2 : '',
            price.accommodationPrices.chTag3 ? price.accommodationPrices.chTag3 : '',
            price.accommodationPrices.chTag4 ? price.accommodationPrices.chTag4 : '',
            price.accommodationPrices.chTag5 ? price.accommodationPrices.chTag5 : ''
        ];

        return (
            <div key={'table-' + price.id} className="table table-responsive">
                <table className="table table-bordered">
                    <tbody>
                        <tr className="table-header">
                            <td colSpan="2" className="td-no-border">
                                <Text paragraph context="white">
                                    Ciudad
                                </Text>
                            </td>
                            <td colSpan="5" className="td-no-border">
                                <Text paragraph context="white">
                                    Nombre
                                </Text>
                            </td>
                            <td colSpan="5" className="td-no-border">
                                <Text paragraph context="white">
                                    Tipo de habitación
                                </Text>
                            </td>
                            <td colSpan="3" className="td-no-border">
                                <Text paragraph context="white">
                                    Régimen
                                </Text>
                            </td>
                        </tr>
                        {price.accommodationsData &&
                            price.accommodationsData.length &&
                            price.accommodationsData.map(data => (
                                <tr key={'infodata-' + data.id} className="table-header">
                                    <td colSpan="2" className="td-no-border">
                                        <Text paragraph context="white">
                                            {data.destinations.join(',')}
                                        </Text>
                                    </td>
                                    <td colSpan="5" className="td-no-border">
                                        <Text paragraph context="white">
                                            {data.name}
                                        </Text>
                                    </td>
                                    <td colSpan="5" className="td-no-border">
                                        <Text paragraph context="white">
                                            {data.category && data.category != ''
                                                ? data.category
                                                : '-'}
                                        </Text>
                                    </td>
                                    <td colSpan="3" className="td-no-border">
                                        <Text paragraph context="white">
                                            {data.regime && data.regime != '' ? data.regime : '-'}
                                        </Text>
                                    </td>
                                </tr>
                            ))}
                        <tr className="table-sub-header">
                            <td rowSpan="2">
                                <Text paragraph>Desde</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Hasta</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Simple</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Doble</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Triple</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Cuadruple</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Quintuple</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Sextuple</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Familiar</Text>
                            </td>
                            <td colSpan="5">
                                <Text paragraph>Menores</Text>
                            </td>
                            <td></td>
                        </tr>
                        <tr className="table-sub-header">
                            <td>{childTags[0]}</td>
                            <td>{childTags[1]}</td>
                            <td>{childTags[2]}</td>
                            <td>{childTags[3]}</td>
                            <td>{childTags[4]}</td>
                            <td></td>
                        </tr>
                        {price.accommodationPrices &&
                            price.accommodationPrices.accommodationsPrice &&
                            price.accommodationPrices.accommodationsPrice.length &&
                            price.accommodationPrices.accommodationsPrice.map(p => (
                                <tr key={'price-' + p.id} className="table-sub-header">
                                    <td>
                                        <Text paragraph>{p.startDate}</Text>
                                    </td>
                                    <td>
                                        <Text paragraph>{p.endDate}</Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceSGL && p.priceSGL > 0 ? p.priceSGL : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceDBL && p.priceDBL > 0 ? p.priceDBL : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceTPL && p.priceTPL > 0 ? p.priceTPL : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceCPL && p.priceCPL > 0 ? p.priceCPL : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceQPL && p.priceQPL > 0 ? p.priceQPL : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceSEX && p.priceSEX > 0 ? p.priceSEX : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceFAMPLAN && p.priceFAMPLAN > 0
                                                ? p.priceFAMPLAN
                                                : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.chPrice1 && p.chPrice1 > 0 ? p.chPrice1 : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.chPrice2 && p.chPrice2 > 0 ? p.chPrice2 : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.chPrice3 && p.chPrice3 > 0 ? p.chPrice3 : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.chPrice4 && p.chPrice4 > 0 ? p.chPrice4 : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.chPrice5 && p.chPrice5 > 0 ? p.chPrice5 : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Icon name="mv-information-circle" color="important" />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        );
    };
    const renderPriceSimple = price => {
        let childTags = [
            price.accommodationPrices.chTag1 ? price.accommodationPrices.chTag1 : '',
            price.accommodationPrices.chTag2 ? price.accommodationPrices.chTag2 : '',
            price.accommodationPrices.chTag3 ? price.accommodationPrices.chTag3 : '',
            price.accommodationPrices.chTag4 ? price.accommodationPrices.chTag4 : '',
            price.accommodationPrices.chTag5 ? price.accommodationPrices.chTag5 : ''
        ];

        return (
            <div key={'table-' + price.id} className="table table-responsive">
                <table className="table table-bordered">
                    <tbody>
                        <tr className="table-header">
                            <td colSpan="17">
                                <div style={{ display: 'inline-block' }}>
                                    <Text paragraph context="white">
                                        {price.accommodationsData &&
                                            price.accommodationsData.length &&
                                            price.accommodationsData[0].destinations.join(',')}
                                    </Text>
                                </div>
                                <div style={{ display: 'inline-block', marginLeft: '35%' }}>
                                    <Text paragraph context="white">
                                        {price.accommodationsData &&
                                            price.accommodationsData.length &&
                                            price.accommodationsData[0].name}
                                    </Text>
                                </div>
                            </td>
                        </tr>
                        <tr className="table-sub-header">
                            <td rowSpan="2">
                                <Text paragraph>Habitación</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Régimen</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Desde</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Hasta</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Simple</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Doble</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Triple</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Cuadruple</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Quintuple</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Sextuple</Text>
                            </td>
                            <td rowSpan="2">
                                <Text paragraph>Familiar</Text>
                            </td>
                            <td colSpan="5">
                                <Text paragraph>Menores</Text>
                            </td>
                            <td></td>
                        </tr>
                        <tr className="table-sub-header">
                            <td>{childTags[0]}</td>
                            <td>{childTags[1]}</td>
                            <td>{childTags[2]}</td>
                            <td>{childTags[3]}</td>
                            <td>{childTags[4]}</td>
                            <td></td>
                        </tr>
                        {price.accommodationPrices &&
                            price.accommodationPrices.accommodationsPrice &&
                            price.accommodationPrices.accommodationsPrice.length &&
                            price.accommodationPrices.accommodationsPrice.map(p => (
                                <tr key={'price-' + p.id} className="table-sub-header">
                                    <td>
                                        <Text paragraph>
                                            {price.accommodationsData &&
                                                price.accommodationsData.length &&
                                                price.accommodationsData[0].category}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {price.accommodationsData &&
                                                price.accommodationsData.length &&
                                                price.accommodationsData[0].regime}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>{p.startDate}</Text>
                                    </td>
                                    <td>
                                        <Text paragraph>{p.endDate}</Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceSGL && p.priceSGL > 0 ? p.priceSGL : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceDBL && p.priceDBL > 0 ? p.priceDBL : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceTPL && p.priceTPL > 0 ? p.priceTPL : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceCPL && p.priceCPL > 0 ? p.priceCPL : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceQPL && p.priceQPL > 0 ? p.priceQPL : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceSEX && p.priceSEX > 0 ? p.priceSEX : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.priceFAMPLAN && p.priceFAMPLAN > 0
                                                ? p.priceFAMPLAN
                                                : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.chPrice1 && p.chPrice1 > 0 ? p.chPrice1 : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.chPrice2 && p.chPrice2 > 0 ? p.chPrice2 : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.chPrice3 && p.chPrice3 > 0 ? p.chPrice3 : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.chPrice4 && p.chPrice4 > 0 ? p.chPrice4 : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text paragraph>
                                            {p.chPrice5 && p.chPrice5 > 0 ? p.chPrice5 : '-'}
                                        </Text>
                                    </td>
                                    <td>
                                        <Icon name="mv-information-circle" color="important" />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderPrices = () =>
        packageDetail.prices.map(price => {
            if (price.accommodationType === 1) {
                return renderPriceSimple(price);
            }
            if (price.accommodationType === 2) {
                return renderPriceCombined(price);
            }
            return '';
        });

    // SE QUE ESTA FUNCION ES HORRIBLE PERO POR AHORA DEJEMOSLA ASI.. (vamos a tener q refactorear todo este componente igual)
    const renderMenu = () =>
        getSectionQuantity() >= getSlidesToShow() ? (
            <div style={{ maxWidth: '80%', margin: '0 auto' }}>
                <Slider {...settingsInfo}>
                    {packageDetail.description && (
                        <div
                            className="item"
                            onClick={() => {
                                infoItemsToFalse();
                                setDescription(true);
                            }}>
                            <Text
                                context={description ? 'important' : 'default'}
                                paragraph
                                size="large">
                                Descripción
                            </Text>
                        </div>
                    )}
                    {packageDetail.housing && (
                        <div
                            className="item"
                            onClick={() => {
                                infoItemsToFalse();
                                setHousing(true);
                            }}>
                            <Text
                                context={housing ? 'important' : 'default'}
                                paragraph
                                size="large">
                                Alojamiento
                            </Text>
                        </div>
                    )}
                    {packageDetail.importantInfo && (
                        <div
                            className="item"
                            onClick={() => {
                                infoItemsToFalse();
                                setInformation(true);
                            }}>
                            <Text
                                context={information ? 'important' : 'default'}
                                paragraph
                                size="large">
                                Información
                            </Text>
                        </div>
                    )}
                    {packageDetail.itinerary && (
                        <div
                            className="item"
                            onClick={() => {
                                infoItemsToFalse();
                                setItinerary(true);
                            }}>
                            <Text
                                context={itinerary ? 'important' : 'default'}
                                paragraph
                                size="large">
                                Itinerario
                            </Text>
                        </div>
                    )}
                    {packageDetail.optional && (
                        <div
                            className="item"
                            onClick={() => {
                                infoItemsToFalse();
                                setOptional(true);
                            }}>
                            <Text
                                context={optional ? 'important' : 'default'}
                                paragraph
                                size="large">
                                Opcionales
                            </Text>
                        </div>
                    )}
                    {packageDetail.fares && (
                        <div
                            className="item"
                            onClick={() => {
                                infoItemsToFalse();
                                setFares(true);
                            }}>
                            <Text
                                context={fares ? 'important' : 'default'}
                                paragraph
                                size="large">
                                Tarifas
                            </Text>
                        </div>
                    )}

                    {packageDetail.mapData && (
                        <div
                            className="item"
                            onClick={() => {
                                infoItemsToFalse();
                                setUbication(true);
                            }}>
                            <Text
                                context={ubication ? 'important' : 'default'}
                                paragraph
                                size="large">
                                Ubicación
                            </Text>
                        </div>
                    )}

                    {/* ENTIENDO QUE ESTO SE VA MOMENTANEAMENTE, CONSULTAR CON SEBA*/}
                    {/*{packageDetail.prices && (*/}
                    {/*  <div*/}
                    {/*    className="item"*/}
                    {/*    onClick={() => {*/}
                    {/*      infoItemsToFalse();*/}
                    {/*      setPrices(true);*/}
                    {/*    }}>*/}
                    {/*    <Text*/}
                    {/*      context={prices ? 'important' : 'default'}*/}
                    {/*      paragraph*/}
                    {/*      size="large">*/}
                    {/*      Precios*/}
                    {/*    </Text>*/}
                    {/*  </div>*/}
                    {/*)}*/}
                </Slider>
            </div>
        ) : (
            <div className="no-slider">
                {packageDetail.description && (
                    <div
                        className="item"
                        onClick={() => {
                            infoItemsToFalse();
                            setDescription(true);
                        }}>
                        <Text
                            context={description ? 'important' : 'default'}
                            paragraph
                            size="large">
                            Descripción
                        </Text>
                    </div>
                )}
                {packageDetail.itinerary && (
                    <div
                        className="item"
                        onClick={() => {
                            infoItemsToFalse();
                            setItinerary(true);
                        }}>
                        <Text
                            context={itinerary ? 'important' : 'default'}
                            paragraph
                            size="large">
                            Itinerario
                        </Text>
                    </div>
                )}
                {packageDetail.importantInfo && (
                    <div
                        className="item"
                        onClick={() => {
                            infoItemsToFalse();
                            setInformation(true);
                        }}>
                        <Text
                            context={information ? 'important' : 'default'}
                            paragraph
                            size="large">
                            Información
                        </Text>
                    </div>
                )}
                {packageDetail.optional && (
                    <div
                        className="item"
                        onClick={() => {
                            infoItemsToFalse();
                            setOptional(true);
                        }}>
                        <Text
                            context={optional ? 'important' : 'default'}
                            paragraph
                            size="large">
                            Opcionales
                        </Text>
                    </div>
                )}
                {packageDetail.housing && (
                    <div
                        className="item"
                        onClick={() => {
                            infoItemsToFalse();
                            setHousing(true);
                        }}>
                        <Text
                            context={housing ? 'important' : 'default'}
                            paragraph
                            size="large">
                            Alojamiento
                        </Text>
                    </div>
                )}
                {packageDetail.fares && (
                    <div
                        className="item"
                        onClick={() => {
                            infoItemsToFalse();
                            setFares(true);
                        }}>
                        <Text
                            context={fares ? 'important' : 'default'}
                            paragraph
                            size="large">
                            Tarifas
                        </Text>
                    </div>
                )}

                {packageDetail.mapData && (
                    <div
                        className="item"
                        onClick={() => {
                            infoItemsToFalse();
                            setUbication(true);
                        }}>
                        <Text
                            context={ubication ? 'important' : 'default'}
                            paragraph
                            size="large">
                            Ubicación
                        </Text>
                    </div>
                )}
            </div>
        );

    const getSectionQuantity = () => {
        let amount = 0;
        if (packageDetail.description) {
            amount++;
        }

        if (packageDetail.importantInfo) {
            amount++;
        }

        if (packageDetail.itinerary) {
            amount++;
        }

        if (packageDetail.optional) {
            amount++;
        }

        if (packageDetail.housing) {
            amount++;
        }

        if (packageDetail.fares) {
            amount++;
        }

        if (packageDetail.mapData) {
            amount++;
        }

        // if (packageDetail.prices) {
        //   amount++;
        // }

        return amount;
    };

    const packageAttributes = packageDetail.compositeIcons.map(item => item.name);

    const metaImage = packageDetail.imagesWebp[0];

    return (
        <div className={cs(className)}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Paquetes de Viaje a {packageDetail.title}, promociones en paquetes
                    vacacionales, ofertas de viaje | Elegí en MeVuelo
                </title>
                <meta
                    name="description"
                    content={`Promociones en paquetes vacacionales a ${packageDetail.title}. Incluye ${packageAttributes}. ${departureDateMinPriceSelectedPrice ? `Desde ${departureDateMinPriceSelectedPrice.currency} ${departureDateMinPriceSelectedPrice.amount}` : ''} . Los Mejores paquetes de viaje a ${packageDetail.title}. ${packageDetail.subTitle}. Las Mejores ofertas de Viaje, están en MeVuelo`}
                />
                <meta
                    name="keywords"
                    content={`promociones en paquetes vacacionales, paquetes de viaje a ${packageDetail.title}, ofertas de viaje`}
                />
                <meta property="og:image" content={metaImage} />
                <meta property="og:site_name" content="mevuelo.com" />
                <meta
                    property="og:title"
                    content={`${packageDetail.title}. ${departureDateMinPriceSelectedPrice ? `Desde ${departureDateMinPriceSelectedPrice.currency} ${departureDateMinPriceSelectedPrice.amount}` : ''} `}
                />
                <meta
                    property="og:description"
                    content={`Incluye ${packageAttributes}. ${packageDetail.subTitle}`}
                />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content={ogLanguage} />
            </Helmet>
            <div className="package-container">
                <div className={cs('package-container-header', { mobile: mediumDown })}>
                    <Text paragraph size="large" className="package-title">
                        {packageDetail.title}
                    </Text>
                    <Text paragraph className="package-subtitle">
                        {packageDetail.subTitle}
                    </Text>
                    {departureDateMinPriceSelectedPrice && <>
                        <div className="package-price">
                            <div>
                                <Text paragraph context="important" size="small">
                                    Desde
                                </Text>
                                <Text paragraph context="important" size="large">
                                    {departureDateMinPriceSelectedPrice.currency}
                                </Text>
                            </div>
                            <Text
                                paragraph
                                size="xxlarge"
                                context="important"
                                weight="semi-bold">
                                {process.env.REACT_APP_COUNTRY === 'CO' ? <NumberFormat
                                    value={departureDateMinPriceSelectedPrice.amount}
                                    displayType={'text'}
                                    decimalSeparator={','}
                                    thousandSeparator={'.'}
                                /> : departureDateMinPriceSelectedPrice.amount}

                            </Text>
                        </div>
                        <div className="package-price-detail">
                            <Text paragraph size="small" context="important">
                                por pasajero en base {getBaseString(packageDetail.basePrice)}
                            </Text>
                        </div>
                    </>}
                </div>
                <div className="package-container-images">
                    <Slider {...settings}>
                        {packageDetail.imagesWebp.map((item, index) => (
                            <div key={'image-' + index} style={{ width: '100%' }}>
                                <img
                                    src={item}
                                    alt={packageDetail.title}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>

                <div className="package-container-info">{renderMenu()}</div>

                <div className="package-container-info-text">
                    {description && packageDetail.description && (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: packageDetail.description.content
                            }}
                        />
                    )}
                    {description && packageDetail.description && packageDetail.travelOn && (
                        <div>
                            <Text context="information" size="large" weight="semi-bold">
                                Condiciones tarifarias del paquete:
                            </Text>
                            <Text paragraph>
                                Encontrá tarifas disponibles para {packageDetail.travelOn}.
                            </Text>
                            <Text paragraph>
                                Las tarifas son dinámicas y y establecidas por los prestadores,
                                varían en dependencia de la fecha seleccionada. Elegí tu fecha
                                ideal en el formulario de contacto para conocer la tarifa
                                aproximada. ¡Cuanta más flexibilidad en tus fechas de viaje,
                                mejores posibilidades de encontrar las tarifas más bajas!
                            </Text>
                            <Text paragraph>
                                Precios de referencia sujetos a disponibilidad al momento de
                                realizar la reserva.
                            </Text>
                        </div>
                    )}
                    {information && packageDetail.importantInfo && (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: packageDetail.importantInfo.content
                            }}
                        />
                    )}
                    {optional && packageDetail.optional && (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: packageDetail.optional.content
                            }}
                        />
                    )}
                    {housing && packageDetail.housing && (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: packageDetail.housing.content
                            }}
                        />
                    )}
                    {fares && packageDetail.fares && (
                        <p
                            dangerouslySetInnerHTML={{ __html: packageDetail.fares.content }}
                        />
                    )}

                    {itinerary && packageDetail.itinerary && (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: packageDetail.itinerary.content
                            }}
                        />
                    )}
                    {ubication && packageDetail.mapData && (
                        <GMap
                            zoom={7}
                            initialCenter={
                                packageDetail.mapData.shapes &&
                                    packageDetail.mapData.shapes.length
                                    ? {
                                        lat: packageDetail.mapData.shapes[0].lat,
                                        lng: packageDetail.mapData.shapes[0].lng
                                    }
                                    : null
                            }
                            points={
                                packageDetail.mapData.points &&
                                    packageDetail.mapData.points.length
                                    ? packageDetail.mapData.points.map(point => {
                                        const coords = point.location.split(',');
                                        return {
                                            name: point.name,
                                            location: { lat: coords[0], lng: coords[1] }
                                        };
                                    })
                                    : []
                            }
                            polygon={
                                packageDetail.mapData.shapes &&
                                    packageDetail.mapData.shapes.length
                                    ? packageDetail.mapData.shapes.map(shape => ({
                                        lat: shape.lat,
                                        lng: shape.lng
                                    }))
                                    : []
                            }
                        />
                    )}
                    {prices && packageDetail.prices && renderPrices()}
                </div>
            </div>
        </div>
    );
};

Package.propTypes = {
    className: PropTypes.string,
    packageDetail: PropTypes.object
};

export default styled(Package)`
  .package-container {
    border-radius: 14px;
    box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.25);
    background-color: ${COLORS.white};
    max-width: 727px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: ${breakpoints.medium + 'px'}) {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
    }
  }

  .no-slider {
    display: flex;
  }

  .package-container-header {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    &.mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .package-title {
      color: ${COLORS.secondary};
    }

    .package-subtitle {
      color: ${COLORS.secondary};
      font-size: 30px;
      font-weight: 600;
    }

    .package-price {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: ${breakpoints.medium + 'px'}) {
        display: none;
      }
    }

    .package-price-detail {
      text-align: center;

      @media (min-width: ${breakpoints.medium + 'px'}) {
        display: none;
      }
    }

    @media (max-width: ${breakpoints.medium + 'px'}) {
      padding-left: 10px;
      padding-right: 10px;

      .package-title {
        font-size: 12px;
      }

      .package-subtitle {
        font-size: 16px;
      }
    }
  }

  .package-container-images {
    width: 100%;
  }

  .package-container-info {
    height: 60px;
    width: 100%;
    max-width: 100%;
    position: relative;
    top: -5px;
    align-items: center;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.25);

    .item {
      cursor: pointer;
      padding-right: 15px;
      padding-left: 15px;
      text-align: center;
      margin-top: 20px;
    }

    .item:last-child {
      border-right: 0;
    }
  }

  .package-container-info-text {
    padding: 10px;
    width: 100%;
    max-width: 100%;
  }

  .next {
    color: ${COLORS.white};
    background-color: transparent;
    right: 5%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .prev {
    color: ${COLORS.white};
    background-color: transparent;
    left: 5%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .infonext {
    color: ${COLORS.darkGrey};
    background-color: transparent;
    right: -10%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .infoprev {
    color: ${COLORS.darkGrey};
    background-color: transparent;
    left: -8%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .slick-dots {
    bottom: 25px;
  }

  .slick-prev::before {
    content: '';
  }

  .slick-next::before {
    content: '';
  }

  .slick-prev,
  .slick-next {
    z-index: 9;
    position: absolute;
  }

  .slick-slider {
    height: inherit;
  }

  .slick-dots li button::before {
    content: '▬';
    font-size: 20px;
    color: ${COLORS.navBarGrey};
    opacity: 0.5;
  }

  .slick-dots li.slick-active button::before {
    color: ${COLORS.white};
    opacity: 1;
  }

  .table-header {
    background-color: ${COLORS.violeta};
  }

  .table-sub-header {
    text-align: center;
    color: ${COLORS.violeta};

    td {
      vertical-align: middle;
      white-space: nowrap;
      width: 1%;
    }
  }

  .td-no-border {
    border: 0;
  }
`;
