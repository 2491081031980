/**
 *
 * MobileFlightDestinationCard
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { locationTypes } from 'Components/DestinationFlight';
import MobileFlightDestinationCard from './MobileFlightDestinationCard';

const destinationSelector = index => ({ flights }) => ({
  location: flights.search.routesData[index].destination,
  locationName: flights.search.routesData[index].destinationName
});

const originSelector = index => ({ flights }) => ({
  location: flights.search.routesData[index].origin,
  locationName: flights.search.routesData[index].originName
});

const getValues = type => {
  switch (type) {
    case locationTypes.DESTINATION:
      return {
        selector: destinationSelector,
        label: 'Destino',
        placeholder: '¿A dónde viajas?'
      };

    case locationTypes.ORIGIN:
      return {
        selector: originSelector,
        label: 'Origen',
        placeholder: '¿Desde donde viajas?'
      };
    default:
      break;
  }
};

const ConnectedMobileFlightDestinationCard = ({
  index = 0,
  type,
  ...props
}) => {
  const { selector, label, placeholder } = getValues(type);

  const state = useSelector(selector(index));

  return (
    <MobileFlightDestinationCard
      {...state}
      {...props}
      label={label}
      placeholder={placeholder}
      type={type}
      index={index}
    />
  );
};

ConnectedMobileFlightDestinationCard.propTypes = {
  index: PropTypes.number,
  type: PropTypes.oneOf(Object.values(locationTypes))
};

export { MobileFlightDestinationCard };
export default ConnectedMobileFlightDestinationCard;
