/**
 *
 * Tooltip
 * @see https://www.npmjs.com/package/rc-tooltip
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RCTooltip from 'rc-tooltip';
import cs from 'classnames';
import 'rc-tooltip/assets/bootstrap.css';
import './tooltip.css';

const Tooltip = ({ className, children, text, placement }) => (
  <div className={cs(className)}>
    <RCTooltip placement={placement} overlay={text}>
      {children}
    </RCTooltip>
  </div>
);

Tooltip.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.any,
  text: PropTypes.string,
  placement: PropTypes.string
};

export default styled(Tooltip)`
  .rc-tooltip {
    .rc-tooltip-content {
      .rc-tooltip-inner {
        padding: 4px 9px;
        color: #fff;
        text-align: left;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: 0.1px;
        background-image: linear-gradient(to right, #ff6700, #ff9901);
        border-radius: 6px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.35);
        min-height: 30px;
      }
    }
  }
`;
