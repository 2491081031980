/**
 *
 * PaxDetailAir
 *
 */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import PlusMinusSelector from 'Components/PlusMinusSelector';
import Select from 'Components/Select';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import { classItems, classItemsSelect, edadesOpciones } from 'Constants/mocks';
import * as COLORS from 'Constants/colors';
import useOutsideClick from '../../../../Utils/hooks/useOutsideClick';
import Checkbox from 'Components/Checkbox';
import { types } from 'Features/hotels';
import Label from 'Components/Label';

const PaxDetailAir = ({ className, adults, kids, cabinCode, actions }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const refOpenMenu = useRef();

  useOutsideClick(refOpenMenu, () => {
    if (openMenu) setOpenMenu(false);
  });

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const totalPassengers = adults + kids.length;

  const {
    onChangeAdults,
    onChangeChildren,
    onChangeChildrenAge,
    onToggleChildrenSeat,
    onChangeCabinCode
  } = actions;

  return (
    <div className={cs(className)}>
      <div className="mv-input" onClick={toggleMenu}>
        <Label color="orange">Pasajeros y Clase</Label>
        <div className="mv-input-container">
          <div className="mv-input-holder">
            <Icon name="mv-user" />
            <Text>
              {' '}
              {totalPassengers > 1
                ? `${totalPassengers} Pasajeros`
                : '1 Pasajero'}
              {cabinCode
                ? `, ${
                  classItems.filter(
                    classItem => classItem.id === cabinCode
                  )[0].name
                }`
                : ''}
            </Text>
          </div>
        </div>
      </div>
      {openMenu && (
        <div className="mv-pax-detail_popup-holder" ref={refOpenMenu}>
          <div className="divider-popup padding-popup">
            <i className="fas fa-user" /> Pasajeros
          </div>
          <div className="form-group padding-popup">
            <div className="pax-label-holder">
              <Text>Adultos</Text>
              <Text context="important">Desde los 18 años</Text>
            </div>
            <div>
              <PlusMinusSelector
                stateless
                onIncrement={onChangeAdults(types.INCREMENT)}
                onDecrement={onChangeAdults(types.DECREMENT)}
                value={adults}
                minValue={1}
              />
            </div>
          </div>

          <div className="form-group padding-popup">
            <div className="pax-label-holder">
              <Text>Menores</Text>
              <Text context="important">Hasta los 17 años</Text>
            </div>
            <div>
              <PlusMinusSelector
                stateless
                onIncrement={onChangeChildren(types.INCREMENT)}
                onDecrement={onChangeChildren(types.DECREMENT)}
                value={kids.length}
                minValue={0}
              />
            </div>
          </div>

          {kids.map((childAge, index) => (
            <div key={index} className="form-group padding-popup">
              <div className="pax-label-holder">
                <Text>Edad del menor </Text>
                <Text context="important">Al finalizar el viaje</Text>
              </div>
              <div className="selector">
                <Select
                  selectedValue={edadesOpciones[0].id}
                  items={edadesOpciones}
                  onSelect={onChangeChildrenAge(index)}
                />
                {childAge.age <= 1 && (
                  <Text className="tariff" context="important" size="sm">
                    Tarifa de bebé
                  </Text>
                )}
                {childAge.age > 1 && childAge.age <= 11 && (
                  <Text className="tariff" context="important" size="sm">
                    Tarifa de niño
                  </Text>
                )}
                {childAge.age > 11 && childAge.age <= 17 && (
                  <Text className="tariff" context="important" size="sm">
                    Tarifa de adulto
                  </Text>
                )}
                {childAge.age <= 1 && (
                  <Checkbox
                    className="custom-icon"
                    checked={childAge.seat}
                    onClick={onToggleChildrenSeat(index)}>
                    <Text> Con asiento</Text>
                  </Checkbox>
                )}
              </div>
            </div>
          ))}

          <div className="divider-popup padding-popup">Clase</div>
          <div className="padding-popup margin-class">
            <Select
              selectedValue={cabinCode}
              items={classItemsSelect}
              onSelect={onChangeCabinCode}
            />
          </div>
          <div className="apply-btn-holder">
            <Button weight="semi-bold" onClick={toggleMenu}>
              APLICAR
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

PaxDetailAir.propTypes = {
  className: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    onChangeAdults: PropTypes.func.isRequired,
    onChangeChildren: PropTypes.func.isRequired,
    onChangeChildrenAge: PropTypes.func.isRequired,
    onToggleChildrenSeat: PropTypes.func.isRequired,
    onChangeCabinCode: PropTypes.func.isRequired
  }),
  adults: PropTypes.number.isRequired,
  kids: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      seat: PropTypes.bool
    })
  ),
  cabinCode: PropTypes.number
};

export default styled(PaxDetailAir)`
  width: 100%;
  .mv-input {
    .mv-input-container {
      border-radius: 14px;
      overflow: hidden;
      box-shadow: ${COLORS.shadows.one};
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.96;
      letter-spacing: 0.1px;
      text-align: left;
      color: ${COLORS.darkGrey};

      .mv-input-holder {
        display: flex;
        border-radius: 14px;
        background: ${COLORS.white};
        padding: 0 10px;
        align-items: center;
        height: 33px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;

        .icon {
          color: ${COLORS.important};
          font-size: 20px;
        }
      }
    }
  }

  .pax-label-holder {
    display: flex;
    flex-direction: column;
  }

  .mv-pax-detail_popup-holder {
    width: 350px;
    box-shadow: ${COLORS.shadows.one};
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding-top: 13px;
    position: absolute;
    background: white;
    right: 0;
    z-index: 2;
  }

  .selector {
    max-width: 150px;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
  }

  .padding-popup {
    padding: 0 18px;
  }

  .margin-class {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .apply-btn-holder {
    height: 50px;
    background-color: ${COLORS.arena};
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divider-popup {
    background-color: ${COLORS.arena};
    color: ${COLORS.secondary};
    font-size: 16px;
  }

  .tariff {
    margin-top: 5px;
  }

  .custom-icon {
    .checkbox-square {
      .icon {
        position: absolute;
        bottom: -7px;
        left: -1px;
        font-size: 20px;
      }
    }
  }
`;
