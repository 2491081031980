import React from 'react';

import Input from 'Components/NewInput';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Radio from 'Components/Radio';
import Toggle from 'Components/Toggle';
import CostCard from '../CostCard';

import { QuotationsServiceProps } from './types';

import { Form, Header, Wrapper, Footer, FormItem } from './styles';
import { DateType } from 'Services/Quotations/types';
import Label from 'Components/Label';
import NewCalendar from 'Components/NewCalendar';
import ModalConfirmation from 'Components/ModalConfirmation';
import NewText from 'Components/NewText';

const QuotationsService = ({
  fields,
  errors,
  onSave,
  onDelete,
  onChange,
  openConfirmation,
  closeConfirmation,
  confirmationOpen,
  number,
  onCostChange,
  passengers
}: QuotationsServiceProps) => (
  <Form>
    <Header>Servicio {number}</Header>
    <div>
      <FormItem>
        <Label color="wine">Titulo:</Label>
        <Input
          onChange={onChange('title')}
          value={fields.title}
          error={{ message: errors.title }}
          placeholder="Titulo"
        />
      </FormItem>

      <FormItem>
        <Label color="wine">Descripcion para cliente:</Label>
        <Input
          onChange={onChange('description')}
          value={fields.description}
          error={{ message: errors.description }}
          placeholder="Descripción"
        />
      </FormItem>

      <FormItem>
        <Label color="wine">Nota interna:</Label>
        <Input
          onChange={onChange('internalNotes')}
          value={fields.internalNotes}
          error={{ message: errors.internalNotes }}
          placeholder="Nota interna"
        />
      </FormItem>

      <FormItem>
        <Label color="wine">Proveedor:</Label>
        <Input
          onChange={onChange('providerName')}
          value={fields.providerName}
          error={{ message: errors.providerName }}
          placeholder="Proveedor"
        />
      </FormItem>
      <Wrapper>
        <span>Fecha:</span>

        <Radio
          value={DateType.noDate}
          checked={fields.dateType === DateType.noDate}
          addMargin
          onClick={onChange('dateType')}>
          Sin fecha
        </Radio>

        <Radio
          value={DateType.one}
          checked={fields.dateType === DateType.one}
          addMargin
          onClick={onChange('dateType')}>
          Unica
        </Radio>

        <Radio
          value={DateType.range}
          checked={fields.dateType === DateType.range}
          addMargin
          onClick={onChange('dateType')}>
          Rango de fechas
        </Radio>
        {fields.dateType > 1 && (
          <NewCalendar
            startLabel="Fecha Inicio"
            endLabel="Fecha fin"
            isRange={fields.dateType === DateType.range}
            onChangeStart={onChange('dateFrom')}
            onChangeEnd={onChange('dateTo')}
            color={{ primaryColor: 'orange', lightColor: 'arena' }}
            startDate={fields.dateFrom}
            endDate={fields.dateTo}
          />
        )}
      </Wrapper>

      <Wrapper>
        <span>En paquete:</span>

        <Toggle
          size="small"
          status={!fields.isIncluded}
          onToggle={onChange('isIncluded')}
          context="important"
          leftLabel="Incluido"
          rightLabel="Opcional"
        />
      </Wrapper>

      <Wrapper>
        <span>Costo:</span>

        <Toggle
          size="small"
          onToggle={onChange('isCostByPassenger')}
          status={!fields.isCostByPassenger}
          context="important"
          leftLabel="Pasajero"
          rightLabel="File"
        />
      </Wrapper>

      <Wrapper>
        <CostCard
          passengerQty={passengers}
          cost={fields.cost}
          calculatesPVP={fields.isCostByPassenger}
          pvpeValue={fields.cost}
          onChange={onCostChange}
          showTotalCost>
          <CostCard.Line label="Pax Qty">
            <CostCard.Text value={String(passengers)} />
          </CostCard.Line>
        </CostCard>
      </Wrapper>
    </div>
    <Footer>
      <Button onClick={openConfirmation}>
        <Icon name="mv-delete" size={28} themeColor="white" />
      </Button>

      {/* TODO: ADD Proper Icon */}
      <Button onClick={onSave}>
        <Icon name="mv-activities" size={28} themeColor="white" />
      </Button>
    </Footer>
    {
      // TODO Update ModalConfirmation
      // @ts-ignore
      <ModalConfirmation
        show={confirmationOpen}
        headerCentered
        onAccept={onDelete}
        onCancel={closeConfirmation}
        onClickOverlay={closeConfirmation}
        header="Confirmación">
        <NewText>Seguro que desea borrar el servicio?</NewText>
        <NewText>Se perderán los datos ingresados</NewText>
      </ModalConfirmation>
    }
  </Form>
);

export default QuotationsService;
