/**
 *
 * ErrorPortal
 *
 */

import React from 'react';
import styled, { css } from 'styled-components';
import Portal from 'Components/Portal';
import Text from 'Components/NewText';
import Heading from 'Components/Heading';
import Icon from 'Components/Icon';
import Button from 'Components/Button';

interface ErrorPortalProps {
  show: boolean;
  title?: string;
  message?: string;
  icon?: React.ReactNode;
  onClose: () => void;
  buttonLabel?: string;
  /** Optional, defaults to onClose */
  buttonOnClick?: () => void;
  /** Optional, defaults to true */
  closeOnClickOverlay?: boolean;
}

const ErrorPortal = ({
  show,
  icon,
  title,
  message,
  onClose,
  buttonOnClick = onClose,
  buttonLabel,
  closeOnClickOverlay = true
}: ErrorPortalProps) => {
  if (!show) return null;

  const handleChildrenClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };
  return (
    <Portal>
      <Container onClick={closeOnClickOverlay && onClose}>
        <Card onClick={handleChildrenClick}>
          <Close onClick={onClose}>
            <Icon name="mv-cross" size={20} color="white" />
          </Close>
          {icon && <IconContainer>{icon}</IconContainer>}
          <SpacingContainer>
            <Heading color="wine" fontWeight="bold">
              {title}
            </Heading>
          </SpacingContainer>
          <Text paragraph>{message}</Text>
          {buttonLabel && (
            <Button fullWidth onClick={buttonOnClick}>
              {buttonLabel}
            </Button>
          )}
        </Card>
      </Container>
    </Portal>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
`;

const Card = styled.div`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.orange};
    border-radius: ${theme.borderRadius.default};
    background-color: ${theme.colors.white};
    padding: ${theme.spacing.four};
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
  `}
`;

const Close = styled.div`
  position: absolute;
  right: -30px;
  top: 0;
  cursor: pointer;
`;

const IconContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.two};
  `}
`;

const SpacingContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.two};
`;

export default ErrorPortal;
