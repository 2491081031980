import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import checkout from './checkout';
import checkoutCorpo from './checkoutCorpo';
import availability from './availability';
import banners from '../Features/banners/reducer';
import places from './places';
import appConfiguration from '../Features/app/reducer';
import hotels from '../Features/hotels';
import blog from '../Features/blog/reducer';
import packages from '../Features/packages/reducer';
import flights from '../Features/flights';
import destinations from 'Features/destinations';
import users from 'Features/users/reducer';
import quotations from 'Features/quotations/reducer';
import common from 'Features/common';

export default history =>
  combineReducers({
    checkout,
    checkoutCorpo,
    availability,
    banners,
    places,
    appConfiguration,
    packages,
    hotels,
    blog,
    flights,
    destinations,
    users,
    quotations,
    common,
    router: connectRouter(history)
  });
