import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Heading from 'Components/Heading';
import NewText from 'Components/NewText';
import NewInput from 'Components/NewInput';
import Label from 'Components/Label';
import Icon from 'Components/Icon';
import Toggle from 'Components/Toggle';
import NewSelect from 'Components/NewSelect';
import { RootState } from 'ReduxConfig/store';
import {
  FormHolder,
  InputsLine,
  InputHolder,
  WarningHolder,
  WarningIconHolder
} from '../common';

import {
  getCountries,
  getDOBDays,
  getDOBYearItems,
  listItemToString,
  returnItemById
} from 'Features/checkout/helpers';
import {
  dobMonth,
  flightPassengerDocumentsTypes
} from 'Features/checkout/constants';
import { IFormPassenger, IListItem } from 'Features/checkout/types';

interface WrapperProps {
  detailView?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  box-shadow: ${props => props.theme.shadows.one};
  width: 100%;
`;

const ButtonsHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: ${props => props.theme.spacing.four};
  margin-right: ${props => props.theme.spacing.four};

  > div {
    margin-bottom: ${props => props.theme.spacing.two};
  }
`;

const selector = ({ places }: RootState) => places.countries;

interface PaxItemCardProps extends IFormPassenger {
  onChange?: (
    paxId: string | number,
    field: string,
    value: string | number | boolean
  ) => void;
}

const PaxItemCard = (props: PaxItemCardProps) => {
  const countries = useSelector(selector);
  const countriesItemList = useMemo(() => getCountries(countries), [countries]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange(props.id, e.currentTarget.name, e.currentTarget.value);

  const handleSelectChange = (field: string) => (item: IListItem) =>
    props.onChange(props.id, field, item.id);

  const handleToggle = (val: boolean) => {
    props.onChange(props.id, 'useInfoForInvoice', !val);
  };

  const dobYearsItems = useMemo(() => getDOBYearItems(), []);
  const dobDaysItems = useMemo(() => getDOBDays(), []);

  return (
    <Wrapper>
      <WarningHolder mobile>
        <WarningIconHolder>
          <Icon size={36} color="white" name="mv-exlamation-circle" />
        </WarningIconHolder>
        <NewText color="white">
          IMPORTANTE: Los datos ingresados deben coincidir con el documento de
          viaje, de lo contrario algunas aerolineas podrían impedir el embarque.
          Los campos con asterísco (*) son obligatorios.
        </NewText>
      </WarningHolder>

      <FormHolder>
        {/* FORMULARIO : COMPONENTE ABIERTO */}
        <Heading color="newBackground" fontWeight="medium">
          Datos de Pasajero (Adulto)
        </Heading>
        <InputsLine>
          <InputHolder>
            <Label>Nombres</Label>
            <NewInput
              value={props.firstName}
              name="firstName"
              onChange={handleInputChange}
            />
          </InputHolder>
          <InputHolder>
            <Label>Apellidos</Label>
            <NewInput
              value={props.lastName}
              name="lastName"
              onChange={handleInputChange}
            />
          </InputHolder>
        </InputsLine>

        <InputsLine>
          <InputHolder>
            <Label>Fecha de nacimiento</Label>
            <NewSelect
              items={dobDaysItems}
              onItemSelected={handleSelectChange('dobDay')}
              itemToString={listItemToString}
              initialSelectedItem={returnItemById(dobDaysItems, props.dobDay)}
            />
          </InputHolder>
          <InputHolder>
            <NewSelect
              items={dobMonth}
              onItemSelected={handleSelectChange('dobMonth')}
              itemToString={listItemToString}
              initialSelectedItem={returnItemById(dobMonth, props.dobMonth)}
            />
          </InputHolder>
          <InputHolder>
            <NewSelect
              items={dobYearsItems}
              onItemSelected={handleSelectChange('dobYear')}
              itemToString={listItemToString}
              initialSelectedItem={returnItemById(dobYearsItems, props.dobYear)}
            />
          </InputHolder>
        </InputsLine>

        <Heading color="newBackground" fontWeight="medium">
          Documento
        </Heading>

        <InputsLine>
          <InputHolder>
            <Label>Tipo de documento</Label>
            <NewSelect
              items={flightPassengerDocumentsTypes}
              onItemSelected={handleSelectChange('documentType')}
              itemToString={listItemToString}
              initialSelectedItem={returnItemById(
                flightPassengerDocumentsTypes,
                props.documentType
              )}
            />
          </InputHolder>
          <InputHolder>
            <Label>Numero del documento</Label>
            <NewInput
              value={props.document}
              name="document"
              onChange={handleInputChange}
            />
          </InputHolder>
        </InputsLine>
        <InputsLine>
          <InputHolder>
            <Label>Nacionalidad</Label>
            <NewSelect
              items={countriesItemList}
              onItemSelected={handleSelectChange('nationality')}
              itemToString={listItemToString}
              initialSelectedItem={returnItemById(
                countriesItemList,
                props.nationality
              )}
            />
          </InputHolder>
          <InputHolder>
            <Label>Sexo</Label>
            <NewSelect
              items={countriesItemList}
              onItemSelected={handleSelectChange('gender')}
              itemToString={listItemToString}
              initialSelectedItem={returnItemById(
                countriesItemList,
                props.nationality
              )}
            />
          </InputHolder>
        </InputsLine>

        <ButtonsHolder>
          <Toggle
            rightLabel="Titular de la reserva y Facturacion"
            status={props.useInfoForInvoice}
            onToggle={handleToggle}
          />
        </ButtonsHolder>
      </FormHolder>
    </Wrapper>
  );
};

export default PaxItemCard;
