/**
 *
 * FlightItineraryDetails
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import AirlineLogo from '../AirLineLogo';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import Button from 'Components/Button';
import { important } from 'Constants/colors';
import { isoDateToFullDate, getCabinName } from 'Utils';
import warningImg from 'Assets/images/warning.png';
import { excludeAirlineBaggage } from 'Constants';

const FlightItineraryDetailsSegmentsBlock = ({
  segment,
  showWaitBar = false,
  scales,
  parentIndex
}) => (
  <div>
    <div className="tramo-header">
      <div>
        <Text size="large" weight="semi-bold">
          Tramo {parentIndex + 1} - Vuelo{' '}
          {segment.flightInformation.airline.code}
          {segment.flightInformation.flightNumber}
        </Text>
      </div>
      <div>
        <Text>{segment.flightInformation.airline.name}</Text>{' '}
        <AirlineLogo code={segment.flightInformation.airline.code} />
      </div>
    </div>
    <div className="tramo-itinerary-holder">
      <div className="tramo-itinerary">
        <div>
          <Text>{isoDateToFullDate(segment.departureData.flightDate)}</Text>
        </div>
        <div>
          <Text size="xlarge" weight="bold">
            {segment.departureData.flightTime}
          </Text>
        </div>
        <div>
          <Text size="large">{segment.departureData.airport.code}</Text>
        </div>
        <div>
          <Text weight="bold">{segment.departureData.airport.city.name}</Text>
        </div>
        <div>
          <Text weight="bold">{segment.departureData.airport.name}</Text>
        </div>
      </div>
      {/* <div className="tramo-itinerary-divider"></div> */}
      <div className="tramo-itinerary">
        <div>
          <Text>{isoDateToFullDate(segment.arrivalData.flightDate)}</Text>
        </div>
        <div>
          <Text size="xlarge" weight="bold">
            {segment.arrivalData.flightTime}
          </Text>
        </div>
        <div>
          <Text size="large">{segment.arrivalData.airport.code}</Text>
        </div>
        <div>
          <Text weight="bold">{segment.arrivalData.airport.city.name}</Text>
        </div>
        <div>
          <Text weight="bold">{segment.arrivalData.airport.name}</Text>
        </div>
      </div>
      {segment.duration.totalHours || segment.duration.totalMinutes ? (
        <div className="tramo-duration-class">
          <div>
            <Text>Duraci&oacute;n</Text>
          </div>
          <div>
            <Text size="xlarge" weight="bold">
              {segment.duration.totalHours}h {segment.duration.totalMinutes}m
            </Text>
          </div>
          <div>
            <Text weight="bold">
              Cabina: {getCabinName(segment.cabin.code)}
            </Text>
          </div>
        </div>
      ) : null}
    </div>
    {showWaitBar && (
      <div className="tramo-footer">
        <Text weight="bold">
          Espera de {scales[parentIndex].duration.totalHours}h{' '}
          {scales[parentIndex].duration.totalMinutes}min en{' '}
          {scales[parentIndex].airport.city.name}
          {scales[parentIndex].changeInfo.changeOfPlane &&
            !scales[parentIndex].changeInfo.changeOfAirport &&
            '(Cambio de avión)'}
        </Text>
        <Text weight="bold" context="information">
          {scales[parentIndex].changeInfo.changeOfAirport &&
            '(Cambio de aeropuerto)'}
        </Text>
        {scales[parentIndex].changeInfo.changeOfAirport && (
          <img src={warningImg} alt="warning" />
        )}
      </div>
    )}
  </div>
);

FlightItineraryDetailsSegmentsBlock.propTypes = {
  segment: PropTypes.object,
  showWaitBar: PropTypes.bool,
  scales: PropTypes.array,
  parentIndex: PropTypes.number
};

const FlightItineraryDetails = ({
  className,
  segments,
  duration,
  scales,
  baggage,
  noSelect = false
}) => {
  const noShowWaitBarIndex = segments.length - 1;
  const airlineCode = segments[0].flightInformation.airline.code;

  return (
    <div className={cs(className, 'animated fadeIn')}>
      <div className="segments-holder">
        {segments.map((segment, index) => (
          <FlightItineraryDetailsSegmentsBlock
            key={index}
            scales={scales}
            segment={segment}
            parentIndex={index}
            showWaitBar={!(index === noShowWaitBarIndex)}
          />
        ))}
      </div>

      <div className="baggage-detail-holder">
        <div className="baggage-detail-item-holder carryon">
          <Icon name="mv-hand-bag" />
          <div className="description-holder">
            {!baggage.total && excludeAirlineBaggage.includes(airlineCode) ? (
              <Text weight="bold">No incluye equipaje de mano</Text>
            ) : (
              <Text weight="bold" context="important">
                Incluye equipaje de mano
              </Text>
            )}
          </div>
        </div>
        {baggage.total ? (
          <div className="baggage-detail-item-holder maleta">
            <Icon name="mv-briefcase" />
            <div className="description-holder">
              <Text weight="bold" context="important">
                Incluye equipaje para despachar
              </Text>
              {baggage.baggageType && baggage.baggageType === 2 && (
                <Text weight="bold">
                  {baggage.total}
                  {baggage.total > 1 ? ' maletas' : ' maleta'} por adulto
                </Text>
              )}
              {baggage.baggageType && baggage.baggageType === 1 && (
                <Text weight="bold">
                  {baggage.total}
                  {baggage.total > 1 ? ' Kilos' : ' kilo'} por adulto
                </Text>
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div className="duration-detail-holder">
        Duraci&oacute;n total{' '}
        {duration.totalHours ? `${duration.totalHours}h` : ''}{' '}
        {duration.totalHours ? `${duration.totalMinutes}mins` : ''}
      </div>
      {noSelect && (
        <div className="select-button-holder">
          <Button>Seleccionar</Button>
        </div>
      )}
    </div>
  );
};

FlightItineraryDetails.propTypes = {
  className: PropTypes.string.isRequired,
  segments: PropTypes.array,
  scales: PropTypes.array,
  duration: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  baggage: PropTypes.object,
  noSelect: PropTypes.bool
};

export default styled(FlightItineraryDetails)`
  .segments-holder {
    margin: 0 10px;

    @media screen and (max-width: 578px) {
      margin: 0;
    }
  }

  .select-button-holder {
    padding-top: 7px;
    padding-bottom: 17px;
    text-align: center;
  }

  .baggage-detail-holder {
    min-height: 68px;
    background-color: #eaeceb;
    display: flex;

    @media screen and (max-width: 578px) {
      padding: 10px;
      flex-direction: column;
    }

    .baggage-detail-item-holder {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        color: ${important};
        margin-right: 8px;
      }

      &.maleta > .icon {
        font-size: 34px;
      }

      &.carryon > .icon {
        font-size: 27px;
      }

      .description-holder {
        display: flex;
        flex-direction: column;
      }

      @media screen and (max-width: 578px) {
        justify-content: flex-start;
      }
    }
  }

  .duration-detail-holder {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-size: 22.5px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.76;
    letter-spacing: 0.23px;
    text-align: center;
    color: #fff;
    padding: 7px 10px;
    background-color: #7b7b7b;

    @media screen and (max-width: 578px) {
      padding: 12px;
      font-size: 16px;
    }
  }

  .tramo-header {
    display: flex;
    justify-content: space-between;
    min-height: 49px;
    align-items: center;

    .airlineLogo {
      max-width: 32px;
      max-height: 32px;
      height: auto;
      width: auto;
      margin-right: 8px;
    }

    @media screen and (max-width: 678px) {
      padding-left: 10px;
      padding-right: 10px;

      & :first-child {
        flex: 1;
      }

      & :last-child {
        flex: 1;
        display: flex;
        justify-content: space-between;

        span {
          max-width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .tramo-itinerary-divider {
    width: 60px;
    height: 1px;
    background-color: #7b7b7b;
  }

  .tramo-duration-class {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.35);

    @media screen and (max-width: 568px) {
      border: none;

      & div:first-child {
        border-bottom: 1px solid #7b7b7b !important;
      }

      & :nth-child(2) {
        order: 2 !important;

        span {
          font-size: 14px !important;
        }
      }

      & :last-child {
        order: 3 !important;
      }
    }
  }

  .tramo-itinerary-holder {
    display: flex;
    justify-content: space-evenly;
    border-top: 1px solid #7b7b7b;
    padding-top: 8px;
    margin-bottom: 18px;

    @media screen and (max-width: 568px) {
      & :nth-child(2) {
        order: 3;
      }
    }
  }

  .tramo-itinerary {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    text-align: center;

    @media screen and (max-width: 678px) {
      /* Some media queries */
    }
  }

  .tramo-footer {
    padding: 4px 10px;
    background-color: #eaeceb;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;
