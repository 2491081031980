import React, { useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import QuotationsSearch from './QuotationsSearch';

import { getQuotationsList } from '../../actions';
import { debounce } from 'lodash';
import { searchDebounceTime, paginationLimit } from 'Constants';
import { RootState } from 'ReduxConfig/store';

interface QuotationSearchFrom {
  searchString?: string;
  orderBy?: string;
  fromDate?: string;
  endDate?: string;
}

const ConnectedQuotationSearch = () => {
  const dispatch = useDispatch();

  const { pagination } = useSelector(({ quotations }: RootState) => ({
    pagination: quotations.pagination
  }));

  const { values, setFieldValue, errors, handleSubmit } = useFormik<
    QuotationSearchFrom
  >({
    initialValues: {
      searchString: '',
      endDate: '',
      fromDate: '',
      orderBy: ''
    },
    validationSchema: Yup.object({
      searchString: Yup.string(),
      endDate: Yup.date(),
      fromDate: Yup.date(),
      orderBy: Yup.string()
    }),
    onSubmit: values => {
      dispatch(
        fetchList({
          dateFrom: values.fromDate,
          dateTo: values.endDate,
          sort: values.orderBy,
          filter: values.searchString,
          page: pagination.currentPage
        })
      );
    }
  });

  const fetchList = params =>
    getQuotationsList({
      ...params,
      size: paginationLimit,
      sort:
        !values.orderBy || values.orderBy === 'Fecha Asc'
          ? '-dateTimeCreated'
          : 'dateTimeCreated'
    });

  const handleChange = (
    name: 'orderBy' | 'searchString' | 'fromDate' | 'endDate'
  ) => (value: string) => {
    setFieldValue(name, value, true);

    if (value.length) {
      debounceFetchSuggestions();
    } else {
      handleSubmit();
    }
  };

  const debounceFetchSuggestions = useCallback(
    debounce(handleSubmit, searchDebounceTime.DESKTOP),
    []
  );

  useEffect(handleSubmit, []);

  return (
    <QuotationsSearch
      {...values}
      onChange={handleChange}
      fromDateError={errors.fromDate}
      endDateError={errors.endDate}
      searchStringError={errors.searchString}
      orderByError={errors.orderBy}
    />
  );
};

export default ConnectedQuotationSearch;
