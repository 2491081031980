/**
 *
 * ThankYouDesktop
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import Card from '../Card';
import Button from '../Button';
import Text from '../Text';
import TextHeader from '../TextHeader';

import { getTotalPrice, transferInfo } from '../../Containers/ThankYou/helpers';
import * as COLORS from 'Constants/colors';

import background from '../../Assets/images/globo-nubes-sol.png';
import RoundIcon from '../RoundIcon';
import ButtonCardPassenger from '../ButtonCardPassenger';
import FullPriceDetail from '../FullPriceDetail';
import TravelInformation from '../TravelInformation';
import MetaTags from 'react-meta-tags';

const ThankYouDesktop = ({
  className,
  selectedAvailability,
  conditions,
  descriptionTimeLimit,
  expirationInfo,
  reserveCode,
  userData,
  getPaymentSpeech,
  goToHome,
  ticket
}) => (
  <div className={cs(className)}>
    <MetaTags>
      <meta name="robots" content="noindex" />
      <meta name="robots" content="nofollow" />
      <meta name="googlebot" content="noindex" />
      <meta name="googlebot-news" content="nosnippet" />
    </MetaTags>
    <div className="content">
      <div className="header">
        <img
          className="cover"
          src={background}
          alt="Disfrutar su viaje con mevuelo.com"
        />

        <div className="info">
          <TextHeader context="secondary" size="xxl" className="title">
            ¡Muchas gracias {userData.invoice.firstName}!
          </TextHeader>

          <TextHeader context="secondary" size="xs" className="subtitle">
            Nuestra tripulación ya está trabajando en tu reserva{' '}
            <b>{reserveCode}</b>
          </TextHeader>

          <Text paragraph size="large" className="content">
            En la próximas horas recibirás un email con el estado de la misma.
            Si no lo recibes, revisá la carpeta de correo no deseado o spam, a
            veces los correos se esconden ahí.
          </Text>
        </div>
      </div>

      <div className="details">
        <Card
          header="Forma de pago"
          className="info-bill"
          borderRadius="square">
          <Text paragraph size="large" className="content">
            <Text paragraph>{getPaymentSpeech(userData, ticket)}</Text>
          </Text>

          {/* <Button context="purple" onClick={onClickMyTravels} className="goto">
             <Text size="large" weight="bold" context="white">IR A MIS VIAJES</Text>
            </Button> */}
        </Card>

        <Card header="DETALLE DEL PAGO" context="default" borderRadius="square">
          <div className="payment">
            <div className="info">
              <div className="icon-holder">
                <RoundIcon icon="mv-money" size={24} className="icon-white" />
              </div>
              <Text paragraph size="large">
                La factura se emitirá a nombre de {userData.invoice.firstName}{' '}
                {userData.invoice.lastName} -{' '}
                {userData.invoice.documentType.name} {userData.invoice.document}
              </Text>
            </div>

            <div className="badge">
              <div className="flightPrice">
                <Text context="important" weight="bold" size="large">
                  TOTAL A PAGAR
                </Text>
                <Text context="important" weight="bold" size="large">
                  {getTotalPrice(selectedAvailability)}
                </Text>
                {(selectedAvailability.localPrice ||
                  (selectedAvailability.hotelInformation &&
                    selectedAvailability.hotelInformation.localPrice)) && (
                  <Text context="important" weight="semi-bold" size="large">
                    {getTotalPrice(selectedAvailability, true)}
                  </Text>
                )}
              </div>
            </div>
            {descriptionTimeLimit && (
              <div className="transference-container">
                {/* <Button className="transference-button" onClick={onClickTransfer}>
                  Formulario de transferencia
                </Button> */}
                {/* TODO Implement Variables to api data */}
                <Text paragraph context="important" className="margin">
                  {descriptionTimeLimit &&
                    transferInfo(descriptionTimeLimit, expirationInfo)}
                </Text>
                {conditions && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: conditions
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </Card>
        {selectedAvailability.engine === 'vuelos' && (
          <Card
            header="Detalle de tu compra"
            context="default"
            borderRadius="square">
            <div className="content-passengers">
              {userData &&
                userData.passengers &&
                userData.passengers.map((passenger, i) => (
                  <ButtonCardPassenger
                    firstNames={passenger.firstNames}
                    lastNames={passenger.lastNames}
                    countryName={passenger.country && passenger.country.name}
                    document={passenger.document}
                    documentName={
                      passenger.documentType && passenger.documentType.name
                    }
                    key={i}
                    className="column-passenger"
                  />
                ))}
            </div>
          </Card>
        )}

        {selectedAvailability.engine === 'vuelos' && (
          <Card
            header="Detalle de los precios"
            context="default"
            borderRadius="square">
            <div className="content-details-prices">
              {selectedAvailability.engine === 'vuelos' && (
                <FullPriceDetail
                  className="prices"
                  pricePassengerDetail={
                    selectedAvailability.flightPrice.pricePassengerDetail
                  }
                  pricePassengerDetailLocal={
                    selectedAvailability.localPrice &&
                    selectedAvailability.localPrice.pricePassengerDetail
                  }
                />
              )}
              {/*
            {selectedAvailability.engine === 'hotel-details' && (
              <div>HOTEL PRICE DETAILS</div>
            )} */}
            </div>
          </Card>
        )}

        <TravelInformation availability={selectedAvailability} />
      </div>

      <div className="footer">
        <Button
          context="information"
          onClick={goToHome(selectedAvailability.engine)}>
          <Text size="large" weight="bold" context="white">
            VOLVER AL INICIO
          </Text>
        </Button>
      </div>
    </div>
  </div>
);

ThankYouDesktop.propTypes = {
  className: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
  conditions: PropTypes.string,
  selectedAvailability: PropTypes.object.isRequired,
  onClickTransfer: PropTypes.func,
  onClickMyTravels: PropTypes.func,
  goToHome: PropTypes.func,
  getPaymentSpeech: PropTypes.func,
  reserveCode: PropTypes.string,
  ticket: PropTypes.string,
  descriptionTimeLimit: PropTypes.string,
  expirationInfo: PropTypes.shape({
    time: PropTypes.string.isRequired,
    expirationType: PropTypes.number.isRequired
  })
};

export default styled(ThankYouDesktop)`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  @media screen and (max-width: 578px) {
    margin: 0;
  }

  .content {
    max-width: 100%;
    padding: 0;
    border-radius: 0;
    width: 790px;
    background-color: ${COLORS.white};
    overflow: hidden;

    .header {
      display: flex;
      flex-direction: column;

      .cover {
        @media screen and (max-width: 578px) {
          object-fit: cover;
        }
      }

      .info {
        background-image: ${COLORS.gradientSky};
        text-align: center;

        .title,
        .subtitle {
          color: ${COLORS.secondary};
          font-weight: 600;
        }

        .title {
          font-size: 30px;
        }

        .subtitle {
          font-size: 18px;

          @media screen and (max-width: 578px) {
            text-align: left;
            font-weight: 500;
            padding-left: 25px;
            padding-right: 25px;
          }
        }

        .content {
          width: 90%;
          margin: 0 auto 30px auto;
          background: transparent;
          border-radius: 0;

          @media screen and (max-width: 578px) {
            padding-left: 25px;
            padding-right: 25px;
            width: 100%;
            margin: 0;
            text-align: left;
          }
        }

        .separator {
          height: 8px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
          background-color: ${COLORS.otroVioleta};
        }
      }
    }

    .details {
      .info-bill {
        .content {
          margin-right: 12px;
          margin-top: 10px;
          margin-bottom: 20px;
          font-size: 16px;
          color: ${COLORS.darkGrey};
          flex: 1;
          border-radius: 0;

          .email {
            color: ${COLORS.seagull};
          }
        }

        @media screen and (max-width: 578px) {
          flex-direction: column;
          padding-top: 0;
          padding-bottom: 20px;
        }
      }

      .payment {
        width: 80%;
        margin: 20px auto;

        .info {
          display: flex;
          color: ${COLORS.darkGrey};
          margin-bottom: 12px;
          align-items: center;

          p {
            margin-left: 22px;
            margin-bottom: 0;
          }
        }

        .badge {
          display: flex;
          flex-direction: column;
          border-radius: 16.5px;
          background-color: ${COLORS.golf};
          margin-bottom: 20px;
          padding: 10px 15px;
          color: ${COLORS.important};
          font-size: 16px;
          font-weight: bold;

          .flightPrice {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }

          .localPrice {
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }
        }

        .transference-container {
          margin: 0 auto;
          text-align: center;

          button {
            margin-bottom: 20px;
          }

          p {
            text-align: left;
            margin-bottom: 5px;
          }
        }

        @media screen and (max-width: 568px) {
          width: 100%;
          padding: 10px;
          margin: 0;
        }
      }

      .content-passengers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;

        @media screen and (max-width: 568px) {
          flex-direction: column;
        }

        .column-passenger {
          display: flex;
          flex-direction: column;
          flex-basis: 47%;
          margin: 1%;

          @media screen and (max-width: 668px) {
            flex-basis: 100%;
            margin: 1%;
          }
        }
      }

      .passengers {
        h6 {
          @media screen and (max-width: 568px) {
            display: none;
          }
        }

        .list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          @media screen and (max-width: 568px) {
            grid-template-columns: repeat(1, 1fr);
            padding: 10px;
          }

          .item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            @media screen and (max-width: 568px) {
              padding: 18px;
              margin-bottom: 0;
              border-radius: 14px;
              box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
              border: solid 1px ${COLORS.important};
              background-color: ${COLORS.white};

              &:not(:last-child) {
                margin-bottom: 15px;
              }
            }

            .data {
              margin-left: 12px;

              h6 {
                color: ${COLORS.darkGrey};
                font-weight: bold;
                margin: 0;
              }

              span {
                display: block;
                color: ${COLORS.darkGrey};
                font-size: 14px;
              }
            }
          }
        }
      }

      .separator-gray {
        height: 8px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
        background-color: ${COLORS.lightGrey};
      }
    }

    .footer {
      margin: 0 auto;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`;
