/**
 *
 * SectionTitle
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from '../Text';
import Icon from '../Icon';

const SectionTitle = ({ className, title, noMargin, centered, icon }) => (
  <div className={cs(className, { noMargin }, { centered })}>
    {icon ? <Icon name={icon} size={20} color="white" /> : null}
    <Text context="white" size="large">
      {title}
    </Text>
  </div>
);

SectionTitle.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  centered: PropTypes.bool,
  icon: PropTypes.string
};

export default styled(SectionTitle)`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.four};
  margin-bottom: ${({ theme }) => theme.spacing.three};
  padding-left: ${({ theme }) => theme.spacing.four};
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.one};
  background-color: ${COLORS.violeta};

  .icon {
    margin-right: ${({ theme }) => theme.spacing.one};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.noMargin {
    margin-bottom: 0;
  }

  &.centered {
    padding-left: 0;
    text-align: center;
  }
`;
