/**
 *
 * ButtonCard
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';

const ButtonCard = ({ className, children, onClick, alignCenter = false }) => (
  <button className={cs(className, { alignCenter })} onClick={onClick}>
    {children}
  </button>
);

ButtonCard.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  alignCenter: PropTypes.bool
};

export default styled(ButtonCard)`
  display: flex;
  width: 100%;
  min-height: 67px;
  border-radius: 14px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
  border: solid 1px ${COLORS.important} !important;
  background-color: ${COLORS.white} !important;
  padding: 8px;

  &.alignCenter {
    justify-content: center;
  }
`;
