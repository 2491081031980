/**
 *
 * Label
 *
 */

import React from 'react';
import styled from 'styled-components';
import { ThemeColor } from 'Theme/types';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactNode;
  color?: ThemeColor;
  for?: string;
}

const Label = ({ color = 'darkGrey', children }: LabelProps) => (
  <Container color={color}>{children}</Container>
);

interface StyleLabelProps {
  color: ThemeColor;
}

const Container = styled.label<StyleLabelProps>`
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 0.96;
  letter-spacing: 0.1px;
  color: ${({ theme, color }) => theme.colors[color]};
`;

export default Label;
