import React from 'react';
import styled from 'styled-components';
import successIcon from '../../Assets/icons/success-icon.png';
import Colors from '../../Theme/colors';
import * as COLORS from 'Constants/colors';
const GatewayCancelTransactionHolder = styled.div`
   {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    color: #333;
    background-image: ${COLORS.gradientSky};
    border: 2px solid ${Colors.green};
    padding: 20px;
    border-radius: 10px;
  }

  .success-message {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    color: ${Colors.green};
  }
`;

const GatewayCancelTransaction = () => (
  <GatewayCancelTransactionHolder>
    <img src={successIcon} width="10%" height="auto" alt="success-icon" />
    <p className={'success-message'}>
      La transacción fue cancelada satisfactoriamente.
    </p>
  </GatewayCancelTransactionHolder>
);

export default GatewayCancelTransaction;
