/**
 *
 * Custom hook to create a React PORTAL
 *
 **/
import { useRef, useEffect } from 'react';

/**
 * Hook to generate portal logic
 * This is the hook that will imported in order to use it
 **/

// TODO: hay q pasarle un ref como valor
export const usePortal = () => {
  const element = useRef(createElement());

  useEffect(() => {
    document.body.appendChild(element.current);

    return () => element.current.remove();
  }, []);

  return element.current;
};

/**
 * Simple function to create a DIV node
 **/
const createElement = () => document.createElement('div');
