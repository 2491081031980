/**
 *
 * NewInput
 *
 */

import React from 'react';
import styled from 'styled-components';
import Icon from 'Components/Icon';
import { ThemeColor } from 'Theme/types';
import { TIconName, IconProps } from 'Components/Icon/types';
import InputErrorMessage from 'Components/InputErrorMessage';

export interface NewInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value' | 'prefix'
  > {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: IInputError;
  /** Not compatible with Prefix prop */
  icon?: IInputIcon;
  /** Not compatible with Icon Prop */
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

export interface IInputError {
  message: string;
  color?: ThemeColor;
}

interface IInputIcon {
  name: TIconName;
  color?: ThemeColor;
  size?: IconProps['size'];
}

const NewInput = ({
  value,
  onChange,
  error,
  icon,
  prefix,
  suffix,
  ...rest
}: NewInputProps) => (
  <Container>
    <InputErrorMessage message={error?.message} color={error?.color} />
    {prefix && <Prefix>{prefix}</Prefix>}
    <Input
      {...rest}
      value={value}
      onChange={onChange && onChange}
      hasPrefix={Boolean(prefix)}
      hasSuffix={Boolean(suffix)}
      hasIcon={Boolean(icon)}
    />
    {icon && (
      <LeftIcon name={icon.name} themeColor={icon.color} size={icon.size} />
    )}
    {suffix && <Suffix>{suffix}</Suffix>}
  </Container>
);

const Container = styled.div`
  position: relative;
  display: flex;
`;

interface inputProps {
  hasIcon?: boolean;
  hasPrefix?: boolean;
  hasSuffix?: boolean;
}

const Input = styled.input<NewInputProps & inputProps>`
  height: 33px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.meVueloGrey};
  border-radius: 14px;
  padding: ${({ theme }) => `${theme.spacing.one} ${theme.spacing.two}`};
  padding-left: ${({ hasIcon }) => hasIcon && '34px'};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.one};
  z-index: 1;
  position: relative;
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${({ hasPrefix }) =>
    hasPrefix &&
    `
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  `};
  ${({ hasSuffix }) =>
    hasSuffix &&
    `
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  `};

  :focus {
    border: none;
    outline: none;
  }

  ::placeholder {
    opacity: 0.7;
  }
  /* stylelint-disable */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }
  /* stylelint-enable */
`;

const LeftIcon = styled(Icon)`
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 2;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Prefix = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  background-color: ${({ theme }) => theme.palette.grays.white};
  padding-left: ${({ theme }) => theme.spacing.four};
  padding-right: ${({ theme }) => theme.spacing.two};
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.one};
  z-index: 1;
  position: relative;
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Suffix = styled(Prefix)`
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export default NewInput;
