/**
 *
 * HotelAvailabilityMobile
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import MobileHotelCard from '../MobileHotelCard';
import MobileHotelFilters from '../MobileHotelFilters';
import FloatingButton from 'Components/FloatingButton';
import { typeOfOrderPrice } from 'Constants';
import InfiniteScroll from 'react-infinite-scroller';
import { toggleMap } from '../../actions';
import HotelAvailabilityMap from '../HotelAvailabilityMap';
import { useHeight } from 'Utils/hooks';
import MenuTab from 'Components/MenuTab';
import { RootState } from 'ReduxConfig/store';

const HotelAvailabilityMobile = ({
  className,
  response,
  paginatedResponse,
  orderBy,
  setOrderBy,
  totalPeople,
  onSelectHotelProperty,
  ratingFilter,
  handleSelectStartFilter,
  searchNameValue,
  handleSearchHotelNameChange,
  sliderMax,
  sliderMin,
  fareRange,
  handleFareFilterChange,
  fareType,
  handleSelectFareType,
  handleFacilityFilterClick,
  facilities,
  selectedFilters,
  accommodations,
  handleCategoryFilterClick,
  selectedCategories,
  currency,
  reviewFilter,
  handleReviewFilterClick,
  ratingOptions,
  hasMoreOptions,
  addPage,
  radiusInMeters,
  initialCenter
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = () => setShowFilters(!showFilters);
  const dispatch = useDispatch();
  const isMap = useSelector(({ hotels }: RootState) => hotels.search.showMap);
  // Necesario para que el mapa tome el resto del espacio disponible
  const height = useHeight() - 132;
  const mapToggle = () => dispatch(toggleMap());

  return (
    <div className={cs(className)}>
      {!isMap && (
        <div className="menu-tab-holder">
          <MenuTab>
            <MenuTab.Item
              onClick={() => setOrderBy(typeOfOrderPrice.byPriority)}
              active={orderBy === typeOfOrderPrice.byPriority}>
              +CONVENIENTES
            </MenuTab.Item>
            <MenuTab.Item
              active={orderBy === typeOfOrderPrice.byPrice}
              onClick={() => setOrderBy(typeOfOrderPrice.byPrice)}>
              +ECONÓMICOS
            </MenuTab.Item>
          </MenuTab>
        </div>
      )}

      <MobileHotelFilters
        show={showFilters}
        close={toggleFilters}
        ratingFilter={ratingFilter}
        handleSelectStartFilter={handleSelectStartFilter}
        searchNameValue={searchNameValue}
        handleSearchHotelNameChange={handleSearchHotelNameChange}
        sliderMax={sliderMax}
        sliderMin={sliderMin}
        fareRange={fareRange}
        handleFareFilterChange={handleFareFilterChange}
        // @ts-ignore
        fareType={fareType}
        handleSelectFareType={handleSelectFareType}
        handleFacilityFilterClick={handleFacilityFilterClick}
        facilities={facilities}
        selectedFilters={selectedFilters}
        currency={currency}
        accommodations={accommodations}
        handleCategoryFilterClick={handleCategoryFilterClick}
        selectedCategories={selectedCategories}
        handleReviewFilterClick={handleReviewFilterClick}
        reviewFilter={reviewFilter}
        ratingOptions={ratingOptions}
      />

      {isMap ? (
        // Necesario para que el mapa tome el resto del espacio disponible
        <div style={{ height }}>
          {/* 
          // TODO
          // @ts-ignore */}
          <HotelAvailabilityMap
            initialCenter={initialCenter}
            hotels={response}
            radiusInMeters={radiusInMeters}
            onHotelClick={onSelectHotelProperty}
            totalPeople={totalPeople}
            mobile
          />
        </div>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={addPage}
          hasMore={hasMoreOptions}
          loader={
            <div key="loading" style={{ color: 'white' }} className="loader">
              Cargando ...
            </div>
          }>
          {Array.isArray(paginatedResponse) &&
            paginatedResponse &&
            paginatedResponse.map((hotel, index) => {
              const {
                breakfast,
                allInclusive,
                airConditioning,
                wifi,
                pool,
                parking,
                gym
              } = hotel.facilities;
              const {
                address,
                lowestPackagePerNightPrice,
                lowestPackagePrice,
                price,
                totalNight,
                reviews
              } = hotel;
              return (
                <MobileHotelCard
                  key={index}
                  className="mobile-card"
                  address={address}
                  starRating={hotel.starRating}
                  keyRating={hotel.keyRating}
                  textRating={hotel.textRating}
                  propertyName={hotel.name}
                  thumbnail={hotel.defaultImage.imageUrl}
                  price={lowestPackagePerNightPrice}
                  wifi={wifi}
                  parking={parking}
                  airConditioning={airConditioning}
                  gym={gym}
                  pool={pool}
                  breakfast={breakfast}
                  isAllInclusive={allInclusive}
                  currency={price.currency}
                  // TODO
                  // @ts-ignore
                  priceByNight={lowestPackagePrice}
                  localCurrency={hotel.localPrice?.currency}
                  localPrice={hotel.localPrice?.lowestPackagePerNightPrice}
                  totalNight={totalNight}
                  totalPeople={totalPeople}
                  reviews={reviews}
                  onSelect={onSelectHotelProperty(hotel.hotelId)}
                />
              );
            })}
        </InfiniteScroll>
      )}
      {/* 
      // @ts-ignore */}
      <FloatingButton
        rightLabel="Filtros"
        rightIconName="mv-selection"
        leftLabel={isMap ? 'Listado' : 'Mapa'}
        leftIconName={isMap ? 'mv-list' : 'mv-location-2'}
        onClickRight={toggleFilters}
        onClickLeft={mapToggle}
      />
    </div>
  );
};

HotelAvailabilityMobile.propTypes = {
  className: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  ratingFilter: PropTypes.number,
  handleSelectStartFilter: PropTypes.func,
  searchNameValue: PropTypes.string,
  handleSearchHotelNameChange: PropTypes.func,
  sliderMin: PropTypes.func,
  sliderMax: PropTypes.func,
  fareRange: PropTypes.array,
  handleFareFilterChange: PropTypes.func,
  fareType: PropTypes.string,
  handleSelectFareType: PropTypes.func,
  handleFacilityFilterClick: PropTypes.func,
  facilities: PropTypes.array,
  selectedFilters: PropTypes.array,
  response: PropTypes.array,
  paginatedResponse: PropTypes.array,
  totalPeople: PropTypes.number,
  onSelectHotelProperty: PropTypes.func,
  accommodations: PropTypes.array,
  handleCategoryFilterClick: PropTypes.func,
  selectedCategories: PropTypes.array,
  currency: PropTypes.string,
  reviewFilter: PropTypes.number,
  handleReviewFilterClick: PropTypes.func,
  ratingOptions: PropTypes.array,
  addPage: PropTypes.func,
  hasMoreOptions: PropTypes.bool,
  radiusInMeters: PropTypes.number.isRequired,
  initialCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired
};

export default styled(HotelAvailabilityMobile)`
  display: block;
  height: 100%;
  .menu-tab-holder {
    margin-top: ${({ theme }) => theme.spacing.two};
  }

  .mobile-card {
    padding: 8px;
    margin-bottom: 10px;
  }
`;
