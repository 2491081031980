/**
 *
 * RatingBox
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from '../Text';
import * as COLORS from 'Constants/colors';

const RatingBox = ({ className, children, big, inline }) => (
  <div className={cs(className, { big }, { inline })}>
    <Text
      context="white"
      weight={big ? 'bold' : 'semi-bold'}
      noWrap
      size={big && 'xlarge'}>
      {children}
    </Text>
  </div>
);

RatingBox.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.any,
  big: PropTypes.bool,
  inline: PropTypes.bool
};

export default styled(RatingBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 22px;
  border-radius: 4px;
  padding: 2px;
  background: ${COLORS.important};

  &.inline {
    display: inline-flex;
  }

  &.big {
    width: 65px;
    height: 45px;
  }
`;
