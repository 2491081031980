import styled from 'styled-components';

export const Wrapper = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.two};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  overflow: hidden;
`;

export const Header = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.one};
  display: flex;
`;

export const Body = styled.div`
  padding-top: ${({ theme }) => theme.spacing.two};
  padding-bottom: ${({ theme }) => theme.spacing.two};
  padding-left: ${({ theme }) => theme.spacing.four};
  padding-right: ${({ theme }) => theme.spacing.four};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  flex: 1;
`;

export const Days = styled.div`
  display: flex;
  border-right: 2px solid ${({ theme }) => theme.colors.lightGrey};

  span {
    color: ${({ theme }) => theme.colors.darkGrey};
    padding-left: ${({ theme }) => theme.spacing.three};
    padding-right: ${({ theme }) => theme.spacing.three};
  }
`;

export const Code = styled.div`
  padding-top: ${({ theme }) => theme.spacing.two};
  padding-bottom: ${({ theme }) => theme.spacing.two};
  padding-left: ${({ theme }) => theme.spacing.three};
  padding-right: ${({ theme }) => theme.spacing.three};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weight.one};
  background-color: ${({ theme }) => theme.colors.important};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 25%;
`;

export const Title = styled.div`
  padding-top: ${({ theme }) => theme.spacing.two};
  padding-bottom: ${({ theme }) => theme.spacing.two};
  padding-left: ${({ theme }) => theme.spacing.three};
  padding-right: ${({ theme }) => theme.spacing.three};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weight.one};
  background-color: ${({ theme }) => theme.colors.violeta};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  max-width: 100%;
`;

export const Services = styled.div`
  padding-left: ${({ theme }) => theme.spacing.three};
  border-right: 2px solid ${({ theme }) => theme.colors.lightGrey};
  color: ${({ theme }) => theme.colors.darkGrey};
  display: flex;
  align-items: center;

  > span {
    margin-right: ${({ theme }) => theme.spacing.three};
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: ${({ theme }) => theme.spacing.three};
  }
`;

export const Hotels = styled.div`
  padding-left: ${({ theme }) => theme.spacing.three};
  border-right: 2px solid ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGrey};

  > span {
    margin-right: ${({ theme }) => theme.spacing.three};
  }
`;

export const Flights = styled.div`
  flex-wrap: wrap;
  align-items: center;
  display: flex;
`;

export const Item = styled.div`
  display: flex;
  border-right: 2px solid ${({ theme }) => theme.colors.lightGrey};
  padding-right: ${({ theme }) => theme.spacing.three};

  &:not(:first-child) {
    padding-left: ${({ theme }) => theme.spacing.three};
  }

  span {
    font-weight: ${({ theme }) => theme.weight.one};
    color: ${({ theme }) => theme.colors.darkGrey};

    &:not(:first-child) {
      margin-left: ${({ theme }) => theme.spacing.three};
    }
  }
`;
