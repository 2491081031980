import React from 'react';
import styled from 'styled-components';
import Heading from 'Components/Heading';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Text from 'Components/NewText';
import PaxItemCardDesktop from 'Features/checkout/components/PaxItemCard/PaxItemCardDesktop';
import PaxItemCardMobile from 'Features/checkout/components/PaxItemCard/PaxItemCardMobile';
import { IFormPassenger } from '../../types';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { MobileBottomNav } from '../common';

interface CheckoutPasDataProps {
  passengers: IFormPassenger[];
  onChange: (paxId: string | number, field: string, value: string) => void;
}

const HeadingHolder = styled.div`
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadows.bottom};
  padding: ${({ theme }) => theme.spacing.two};
`;

const MobileNavContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`;

interface PaxHolderProps {
  mediumDown: boolean;
}
const PaxHolder = styled.div<PaxHolderProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => (props.mediumDown ? 0 : props.theme.spacing.two)};

  > div {
    margin-bottom: ${({ theme }) => theme.spacing.four};

    &:first-child {
      margin-top: ${({ theme }) => theme.spacing.four};
    }
  }
`;

const DekstopButtonHolder = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: ${({ theme }) => theme.spacing.four};
`;

const PaxData = (props: CheckoutPasDataProps) => {
  const { mediumDown } = useBreakpoints();
  const PaxItemComponent = mediumDown ? PaxItemCardMobile : PaxItemCardDesktop;
  return (
    <div>
      <HeadingHolder>
        <Heading color="newBackground" type="h4" fontWeight="semibold">
          ¿Quiénes viajan?
        </Heading>
      </HeadingHolder>
      <PaxHolder mediumDown={mediumDown}>
        {props.passengers.map(pax => (
          <PaxItemComponent key={pax.id} {...pax} onChange={props.onChange} />
        ))}
      </PaxHolder>
      {mediumDown ? (
        <MobileBottomNav>
          <MobileNavContent>
            <Icon color="information" size={24} name="mv-information-circle" />
            <div>
              <div>
                <Text color="important" fontWeight="semibold" fontSize="16px">
                  USD 999.999.999
                </Text>
              </div>
              <Text color="important">Precio final por adulto</Text>
            </div>
            <Button iconRight="mv-arrow-right">Cargar Datos</Button>
          </MobileNavContent>
        </MobileBottomNav>
      ) : (
        <DekstopButtonHolder>
          <Button iconRight="mv-arrow-right">Cargar Datos</Button>
        </DekstopButtonHolder>
      )}
    </div>
  );
};

export default PaxData;
