import { AxiosInstance } from 'axios';
import { StatsFlightPrice } from './types';
import * as queries from './queries';

export default function(axios: AxiosInstance) {
  const getFlightsMiniBanners = (destinationCode: string) =>
    axios.post<{ data: { flightPrices: StatsFlightPrice[] } }>(
      '',
      queries.getFlightsMiniBanners(destinationCode)
    );
  return {
    getFlightsMiniBanners
  };
}
