/**
 *
 * FlightSearchDialog
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchDialogDetailMode } from 'Features/flights';
import FlightSearchDialog from './FlightSearchDialog';
import { doValidation } from '../../index';

const selector = ({ flights }) => ({
  type: flights.search.type,
  detailMode: flights.search.detailMode,
  isReducerUnused: flights.availability.status === 'unused'
});

const ConnectedFlightSearchCompact = props => {
  const dispatch = useDispatch();
  const actions = {
    toggleDetailModeOff: () => {
      dispatch(setSearchDialogDetailMode(true));
    },
    doSearch: () => {
      dispatch(doValidation());
    }
  };
  const state = useSelector(selector);

  return <FlightSearchDialog {...props} {...state} actions={actions} />;
};

export default ConnectedFlightSearchCompact;
export { FlightSearchDialog };
