/**
 *
 * AirlineLogo
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import { getAirLineLogo } from '../../../../Utils';

const AirLineLogo = ({ className, code, name, showName = false }) => (
  <div className={cs(className)}>
    <div className="AirLineLogoImage">
      <img alt={name} className="airlineLogo" src={getAirLineLogo(code)} />
    </div>
    {showName && (
      <Text className={cs(['AirLineLogoName', 'semi-bold'])}>{name}</Text>
    )}
  </div>
);

AirLineLogo.propTypes = {
  className: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  name: PropTypes.string,
  showName: PropTypes.bool
};

export default styled(AirLineLogo)`
  align-items: center;
  display: flex;

  .AirLineLogoImage {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .AirLineLogoName {
    margin-left: 7px;
  }
`;
