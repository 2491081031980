/**
 *
 * DesktopHotelSearchCompact
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Card from 'Components/Card';
import Button from 'Components/Button';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import { totalPeople } from 'Features/hotels';
import { formatLong } from 'Utils/dates';
import { setDetailMode } from 'Features/hotels/actions';

const selector = ({ hotels }) => ({
  checkIn: hotels.search.checkIn,
  checkOut: hotels.search.checkOut,
  location: hotels.search.location,
  roomInformation: hotels.search.roomInformation
});

const ConnectedDesktopHotelSearchCompact = props => {
  const dispatch = useDispatch();
  const actions = {
    setModify: () => {
      dispatch(setDetailMode(false));
    }
  };

  const state = useSelector(selector);

  return (
    <StyledDesktopHotelSearchCompact actions={actions} {...state} {...props} />
  );
};

const DesktopHotelSearchCompact = ({
  className,
  checkIn,
  checkOut,
  roomInformation,
  location,
  actions
}) => (
  <div className={cs(className)}>
    <Card>
      <div className="detail-bar">
        <div className="detail-bar-segment">
          <div className="detail-bar-segment-item">
            <Icon name="mv-location-2" size={24} />
            <Text>{location.description} </Text>
          </div>
        </div>
        <div className="detail-bar-segment">
          <div className="detail-bar-segment-item">
            <Icon name="mv-calendar" size={24} />
            <Text>
              {formatLong(checkIn)} - {formatLong(checkOut)}
            </Text>
          </div>
        </div>
        <div className="detail-bar-segment">
          <div className="detail-bar-segment-item">
            <Icon name="mv-user" size={24} />
            <Text>{totalPeople(roomInformation)}</Text>
          </div>
          <div className="detail-bar-segment-item" />
          <div className="detail-bar-segment-item">
            <Icon name="mv-bed-double" size={24} />
            <Text>{roomInformation.length}</Text>
          </div>
        </div>
        <Button weight="semi-bold" iconLeft onClick={actions.setModify}>
          <div className="btn-modify">
            <Icon name="mv-lense" size={20} color="white" />
            MODIFICAR
          </div>
        </Button>
      </div>
    </Card>
  </div>
);

DesktopHotelSearchCompact.propTypes = {
  className: PropTypes.string.isRequired,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  roomInformation: PropTypes.array,
  location: PropTypes.shape({
    placeId: PropTypes.string,
    description: PropTypes.string
  }),
  actions: PropTypes.shape({
    setModify: PropTypes.func.isRequired
  })
};

const StyledDesktopHotelSearchCompact = styled(DesktopHotelSearchCompact)`
  padding-top: ${({ theme }) => theme.spacing.two};
  padding-bottom: ${({ theme }) => theme.spacing.two};
  width: 100%;
  .detail-bar {
    display: flex;
    align-items: center;

    .detail-bar-segment {
      align-items: center;
      border-right: 3px solid ${COLORS.lightGrey};
      display: flex;
      height: 40px;
      margin-right: 8px;
      padding-right: 10px;

      .detail-bar-segment-item {
        display: flex;
        align-items: center;
        margin-right: 8px;

        .icon {
          color: ${COLORS.important};
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }
      }

      &:first-child {
        .detail-bar-segment-item {
          width: 100%;
          max-width: 360px;
        }
      }
    }

    .btn-modify {
      display: flex;
    }
  }
`;

export default ConnectedDesktopHotelSearchCompact;
export { StyledDesktopHotelSearchCompact as DesktopHotelSearchCompact };
