export const humanize = name => {
  switch (name) {
    case 'wireTransferPayment':
      return 'Transferencias';

    case 'creditCardPayment':
      return 'Tarjeta de crédito';

    case 'branchOffices':
      return 'Pago en sucursal';

    default:
      return 'Redes de pago';
  }
};
