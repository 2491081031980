/**
 *
 * BlogCard
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import * as COLORS from 'Constants/colors';
import LazyImg from 'Components/LazyImg';
import Link from 'Components/Link';

const BlogCard = ({
  className,
  context,
  imageUrl,
  avatarUrl,
  author,
  date,
  timeRead,
  title,
  url
}) => (
  <div className={cs(className)}>
    <Link to={url}>
      <div className="image-container">
        <LazyImg src={imageUrl} alt={title} className="img" />
        <div className="author">
          <div className={cs('author_background', context)} />
          <div
            className="author_avatar"
            style={{ backgroundImage: `url(${avatarUrl})` }}
          />
          <div className="author_name">
            <Text context="white">por {author}</Text>
          </div>
        </div>
      </div>
      <div className="blog-content">
        <Text size="small" weight="regular">
          {date} - {timeRead} min read
        </Text>
        <Text h1 size="large" context="default" weight="semi-bold">
          {title}
        </Text>
      </div>
    </Link>
  </div>
);

BlogCard.propTypes = {
  className: PropTypes.string.isRequired,
  context: PropTypes.string,
  imageUrl: PropTypes.string,
  avatarUrl: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  timeRead: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string.isRequired
};

export default styled(BlogCard)`
  border-radius: 14px;
  overflow: hidden;
  display: block;
  background: ${COLORS.white};
  cursor: pointer;
  width: 100%;
  box-shadow: ${COLORS.shadows.one};
  transition: 0.3s ease-out;
  position: relative;

  .image-container {
    position: relative;

    .img {
      height: 200px;
      overflow: hidden;
    }

    .author {
      height: 30px;
      position: relative;
      bottom: 0;
      z-index: 6;

      &_avatar {
        width: 44px;
        height: 44px;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        position: absolute;
        left: 19px;
        top: -22px;
      }

      &_name {
        margin-left: 78px;
        position: absolute;
        top: 0;
      }

      &_background {
        position: absolute;
        opacity: 0.75;
        background-image: ${COLORS.gradientEminenceDeluge};
        width: 100%;
        height: 100%;

        &.important {
          background-image: ${COLORS.gradientImportant};
        }

        &.positive {
          background-image: ${COLORS.gradientPositive};
        }

        &.information {
          background-image: ${COLORS.gradientInfo};
        }
      }
    }
  }

  &:hover {
    transform: translateY(9px);
  }

  .blog-content {
    padding: 10px 18px 18px 18px;
  }
`;
