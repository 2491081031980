/**
 *
 * RatingMolecule
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import RatingBox from '../RatingBox';
import Text from '../Text';

const RatingMolecule = ({
  className,
  rating,
  amountReviews,
  ratingText,
  inline
}) => (
  <div className={cs(className)}>
    <RatingBox>{parseFloat(rating).toFixed(2)}</RatingBox>
    <div className={cs('text-data', { inline })}>
      <Text size="small" context="information" weight="semi-bold">
        {ratingText}
      </Text>
      {amountReviews && (
        <Text size="small" context="default" className="margin-left">
          {`${amountReviews} opiniones`}
        </Text>
      )}
    </div>
  </div>
);

RatingMolecule.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number,
  amountReviews: PropTypes.number,
  ratingText: PropTypes.string,
  inline: PropTypes.bool
};

export default styled(RatingMolecule)`
  display: flex;
  align-items: center;

  .text-data {
    margin-left: 5px;
    display: flex;
    flex-direction: column;

    &.inline {
      width: 100%;
      flex-direction: row;
      margin: 10;

      .margin-left {
        margin-left: 5px;
      }
    }
  }
`;
