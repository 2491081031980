/**
 *
 * MobilePaxDetailHotel
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import * as COLORS from 'Constants/colors';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import SectionTitle from 'Components/SectionTitle';
import PlusMinusSelector from 'Components/PlusMinusSelector';
import Checkbox from 'Components/Checkbox';
import MobileNavBar from 'Components/MobileNavBar';
import Button from 'Components/Button';
import PaxDetailHotelRoom from '../PaxDetailHotelRoom';
import { getPaxHotelActions } from 'Features/hotels';
import { hasInvalidKidAges } from '../../helpers';

const selector = ({ hotels }) => ({
  allowedPets: hotels.search.allowedPets,
  roomInformation: hotels.search.roomInformation
});

const ConnectedMobilePaxDetailHotel = props => {
  const dispatch = useDispatch();
  const actions = getPaxHotelActions(dispatch);
  const state = useSelector(selector);

  return <StyledMobilePaxDetailHotel actions={actions} {...state} {...props} />;
};

const MobilePaxDetailHotel = ({
  className,
  close,
  actions,
  roomInformation,
  allowedPets
}) => {
  const {
    onChangeAdults,
    onChangeChildren,
    onChangeChildrenAge,
    onTogglePets,
    removeRoom,
    addRoom
  } = actions;

  const hasInvalidRoomKidAges = () => hasInvalidKidAges(roomInformation);

  return (
    <>
      <div className={cs(className)}>
        <div className="top-bar">
          <Text weight="italic" size="large">
            ¿Cuántos pasajeros viajan?
          </Text>
          <span onClick={close}>
            <Icon name="mv-cross" size={24} />
          </span>
        </div>
        <SectionTitle title="Habitaciones" icon="mv-bed-double" />
        <div className="option">
          <div className="column">
            <Text>Habitaciones</Text>
          </div>
          <div className="column">
            <PlusMinusSelector
              stateless
              onIncrement={addRoom}
              onDecrement={removeRoom}
              value={roomInformation.length}
            />
          </div>
        </div>
        {roomInformation.map((room, index) => (
          <PaxDetailHotelRoom
            key={index}
            onChangeAdults={onChangeAdults}
            onChangeChildren={onChangeChildren}
            handleChangeChildrenAge={onChangeChildrenAge}
            room={room}
            index={index}
          />
        ))}
        <Checkbox onClick={onTogglePets} checked={allowedPets}>
          Mascotas
        </Checkbox>
        <div className="center">
          <Text context="important">
            Solo se mostraran las propiedades que permiten mascotas.
          </Text>
        </div>
        <div className="filler" />
      </div>
      <MobileNavBar className="align-center">
        <Button
          right
          context="purple"
          onClick={close}
          disabled={hasInvalidRoomKidAges()}>
          Aplicar
        </Button>
      </MobileNavBar>
    </>
  );
};

MobilePaxDetailHotel.propTypes = {
  className: PropTypes.string.isRequired,
  close: PropTypes.func,
  roomInformation: PropTypes.array,
  allowedPets: PropTypes.bool,
  actions: PropTypes.shape({
    onChangeAdults: PropTypes.func,
    onChangeChildren: PropTypes.func,
    onChangeChildrenAge: PropTypes.func,
    onTogglePets: PropTypes.func,
    removeRoom: PropTypes.func,
    addRoom: PropTypes.func
  })
};

const StyledMobilePaxDetailHotel = styled(MobilePaxDetailHotel)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${COLORS.white};
  align-items: center;
  height: fit-content;

  .top-bar {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .option {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;

    .column {
      display: flex;
      flex-direction: column;
      width: 45%;
    }
  }

  .center {
    text-align: center;
  }

  .filler {
    height: 72px;
  }
`;

export default ConnectedMobilePaxDetailHotel;
export { StyledMobilePaxDetailHotel as MobilePaxDetailHotel };
