import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import Text from 'Components/Text';
import SelectDate from 'Components/SelectDate';
import SelectPeople from 'Components/SelectPeople';
import Select from 'Components/Select';
import Input from 'Components/Input';
import TextArea from 'Components/TextArea';
import Button from 'Components/Button';
import { formKeys } from './index';
import { getBasePriceString } from 'Features/packages/helpers';
import { breakpoints } from 'Constants';

const PackageContactForm = ({
  basePrice,
  currency,
  price,
  date,
  errors = {},
  onChange,
  assistancePrefers,
  listPhoneCodes,
  prefers,
  name,
  email,
  phoneCode,
  phone,
  query,
  onSubmit,
  touched = {},
  title
}) => (
  <Container>
    {price && (
      <ContentHeader>
        <Column>
          <Text context="important" size="small">
            Desde
          </Text>
          <Text context="important">{currency}</Text>
        </Column>
        <Text context="important" weight="bold" size="xxlarge">
          {process.env.REACT_APP_COUNTRY === 'CO'? <NumberFormat
                      value={price}
                      displayType={'text'}
                      decimalSeparator={','}
                      thousandSeparator={'.'}
                    /> : price}
        </Text>
      </ContentHeader>
    )}
    {price && basePrice && (
      <>
        <Text context="important">{getBasePriceString(basePrice)[0]}</Text>
        <Text context="important" weight="semi-bold">
          {getBasePriceString(basePrice)[1]}
        </Text>
      </>
    )}
    <Text context="important" weight="semi-bold" size="xlarge">
      {title || 'Contactá a un vendedor'}
    </Text>
    <ContentBody>
      <FormLabel>
        <Text weight="semi-bold">Fecha de viaje:</Text>
        <SelectDate
          className="input"
          onChange={onChange(formKeys.date)}
          selectedValue={date}
          error={{
            message: !touched.date && 'Aca va tu fecha ideal',
            color: 'info'
          }}
        />
      </FormLabel>
      <FormLabel>
        <Text weight="semi-bold">Pasajeros:</Text>
        <SelectPeople
          className="input"
          onChange={onChange(formKeys.people)}
          error={{
            message: errors.people
              ? 'La edad de los menores es requerida'
              : undefined,
            color: 'warning'
          }}
        />
      </FormLabel>
      <FormLabel>
        <Text weight="semi-bold">Via de contacto:</Text>
        <Select
          className="input"
          placeholder="¿Por qué medio te contactamos?"
          onSelect={onChange(formKeys.prefers)}
          selectedValue={prefers && prefers.id}
          items={assistancePrefers}
          message={errors.prefers}
          context={errors.prefers && 'warning'}
        />
      </FormLabel>
      <FormLabel>
        <Text weight="semi-bold">Nombre:</Text>
        <Input
          placeholder="Ingresá tu nombre"
          className="input"
          onChange={onChange(formKeys.name)}
          value={name}
          message={errors.name}
          context={errors.name && 'warning'}
          id="name"
          autocomplete
        />
      </FormLabel>
      <FormLabel>
        <Text weight="semi-bold">Correo electrónico:</Text>
        <Input
          placeholder="Ej: hola@mevuelo.com"
          className="input"
          onChange={onChange(formKeys.email)}
          value={email}
          message={errors.email}
          context={errors.email && 'warning'}
          type="email"
          id="email"
          autocomplete
        />
      </FormLabel>
      <FormLabel>
        <Text weight="semi-bold">Teléfono:</Text>
        <FormRow>
          <Select
            className="input margin-right"
            items={listPhoneCodes}
            onSelect={onChange(formKeys.phoneCode)}
            selectedValue={phoneCode && phoneCode.id}
            message={errors.phoneCode}
            context={errors.phoneCode && 'warning'}
          />
          <Input
            placeholder="Número de teléfono"
            className="input"
            value={phone}
            message={errors.phone}
            context={errors.phone && 'warning'}
            onChange={onChange(formKeys.phone)}
          />
        </FormRow>
      </FormLabel>
      <TextArea
        className="input"
        placeholder="¿Algún comentario?"
        value={query}
        message={errors.query}
        context={errors.query && 'warning'}
        onChange={onChange(formKeys.query)}
      />
      <Button type="submit" className="input" onClick={onSubmit}>
        Envianos tu consulta
      </Button>
    </ContentBody>
  </Container>
);

PackageContactForm.propTypes = {
  date: PropTypes.string,
  people: PropTypes.shape({
    adultsCount: PropTypes.number.isRequired,
    childrenQty: PropTypes.number.isRequired
  }),
  prefers: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  query: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  phoneCode: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  currency: PropTypes.string,
  price: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  assistancePrefers: PropTypes.any,
  listPhoneCodes: PropTypes.any,
  name: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  basePrice: PropTypes.number,
  title: PropTypes.node
};

export default PackageContactForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  box-shadow: ${({ theme }) => theme.shadows.four};
  padding: ${({ theme }) => theme.spacing.one};
  .input {
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
  .margin-right {
    margin-right: ${({ theme }) => theme.spacing.two};
  }
  @media (max-width: ${breakpoints.medium + 'px'}) {
    border-radius: 0;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ContentBody = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.four};
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.one};
`;

const FormLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${({ theme }) => theme.spacing.one};
  & > div {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;
