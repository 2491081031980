/**
 *
 * ConnectedMpcItemResearch
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MpcItemResearch from './MpcItemResearch';
import { mptbItemResearch } from 'Features/flights/actions';

const selector = ({ flights }) => ({
  routesData: flights.mpcAvailability.data.request.routesData,
  totalAdult: flights.mpcAvailability.data.request.totalAdult,
  totalChildren: flights.mpcAvailability.data.request.totalChildren,
  totalInfant: flights.mpcAvailability.data.request.totalInfant,
  totalInfantWithSeat: flights.mpcAvailability.data.request.totalInfantWithSeat,
  filterType: flights.mpcAvailability.data.request.filterType,
  cityKeyword: flights.mpcAvailability.data.request.cityKeyword
});

const ConnectedMpcItemResearch = ({ ...props }) => {
  const dispatch = useDispatch();

  const actions = {
    onResearchMptb: (newAmadeusRequest, row, col) => {
      dispatch(mptbItemResearch(newAmadeusRequest, row, col));
    }
  };

  const state = useSelector(selector);

  return <MpcItemResearch actions={actions} state={state} {...props} />;
};

export default ConnectedMpcItemResearch;
