import { isValidIsoDate, parseIso } from '../../Utils/dates';
import { isBefore } from 'date-fns';
import { limits } from './constants';

export const getTotalPeopleRoom = room =>
  room.adultsCount + room.kidsAges.length;

export const validateSearch = search => {
  const validations = {
    checkIn: [],
    checkOut: [],
    roomInformation: [],
    location: []
  };

  let isValid = true;
  const { checkIn, checkOut, roomInformation, location } = search;

  if (!isValidIsoDate(checkIn)) {
    validations.checkIn.push('Check in no es una fecha válida');
    isValid = false;
  }

  if (!isValidIsoDate(checkOut)) {
    validations.checkOut.push('Check Out no es una fecha válida');
    isValid = false;
  }

  if (isBefore(parseIso(checkOut), parseIso(checkIn))) {
    validations.checkOut.push('Check out no puede ser anterior que Check in');
    isValid = false;
  }

  if (!roomInformation) {
    validations.roomInformation.push(
      'No se encontro la información de los huespedes'
    );
    isValid = false;
  }

  if (roomInformation && roomInformation.length < limits.roomAmmount.min) {
    validations.roomInformation.push('Necesitas al menos una habitación');
    isValid = false;
  }
  if (roomInformation && roomInformation.length > limits.roomAmmount.max) {
    validations.roomInformation.push('Máximo 4 habitaciónes');
    isValid = false;
  }

  if (
    roomInformation &&
    !roomInformation.every(
      room => getTotalPeopleRoom(room) <= limits.peoplePerRoom.max
    )
  ) {
    validations.roomInformation.push(
      `No puede haber mas de ${limits.peoplePerRoom.max} huespedes por habitación`
    );
    isValid = false;
  }

  if (
    roomInformation &&
    !roomInformation.every(
      room => getTotalPeopleRoom(room) >= limits.peoplePerRoom.min
    )
  ) {
    validations.roomInformation.push(
      `No puede haber menos de ${limits.peoplePerRoom.min} huespedes por habitación`
    );
    isValid = false;
  }

  if (!location || !location.placeId) {
    validations.location.push('Elige un destino');
    isValid = false;
  }

  if (
    roomInformation &&
    !roomInformation.every(room => room.adultsCount >= limits.adultsCount.min)
  ) {
    validations.roomInformation.push(
      `No puede haber menos de ${limits.adultsCount.min} adultos por habitación`
    );
    isValid = false;
  }

  if (roomInformation && hasInvalidKidAges(roomInformation)) {
    validations.roomInformation.push('Ingrese la edad del menor');
    isValid = false;
  }

  if (isValid) {
    return { isValid };
  }
  return { isValid, ...validations };
};

export const totalPeople = roomInformation =>
  roomInformation.reduce(
    (p, next) => p + next.adultsCount + next.kidsAges.length,
    0
  );

export const hasInvalidKidAges = roomInformation =>
  roomInformation.some(
    x => x.kidsAges.length > 0 && x.kidsAges.some(age => !age && age !== 0)
  );
