/**
 *
 * PackageContactForm
 *
 */

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {setDepartureDatePrice, submitForm} from '../../actions';
import PackageContactForm from './PackageContactForm';
import PackageContactFormTwoColumns from './PackageContactFormTwoColumns';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {debounce} from 'lodash';
import useQueryString from '../../../../Utils/hooks/useQueryString';

export const formKeys = Object.freeze({
  date: 'date',
  prefers: 'prefers',
  name: 'name',
  email: 'email',
  phoneCode: 'phoneCode',
  phone: 'phone',
  query: 'query',
  people: 'people'
});

const initialPeople = {
  adultsCount: 2,
  childrenQty: 0,
  kidsAges: []
};

const selector = ({ appConfiguration, places, packages }) => ({
  phoneTypes: appConfiguration.sharedConfig.phoneTypes,
  assistancePrefers: appConfiguration.sharedConfig.assistancePrefers,
  countries: places.countries,
  localize: appConfiguration.localize,
  filteredDate: packages.filteredDate,
  pricesByMonth: packages.packageItemData?.pricesByMonth,
  departureDateMinPriceSelected: packages.departureDateMinPriceSelected,
  departureDateMinPriceSelectedPrice: packages.departureDateMinPriceSelectedPrice,
});

const ConnectedPackageContactForm = ({
  twoColumns = false,
  title,
  ...props
}) => {
  const dispatch = useDispatch();
  const actions = {
    submitForm: formState => dispatch(submitForm(formState))
  };
  const state = useSelector(selector);
  const [queryDate, onSetQueryDate] = useQueryString('date');

  const { countries, localize,departureDateMinPriceSelected,departureDateMinPriceSelectedPrice } = state;
  const codeLocalize = localize && localize['phoneCode'];
  const listPhoneCodes =
    countries &&
    countries.map(country => ({
      id: `${country.phoneCode}`,
      name: `${country.name} ${country.phoneCode}`
    }));

  const defaultPhoneCode =
    countries &&
    listPhoneCodes.find(phoneCode => phoneCode.id === codeLocalize);

  useEffect(() => {
    dispatch(setDepartureDatePrice(queryDate));
  }, [queryDate]);


  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    touched,
    setFieldTouched
  } = useFormik({
    initialValues: {
      email: '',
      date:departureDateMinPriceSelected,
      prefers: null,
      name: '',
      phoneCode: defaultPhoneCode,
      phone: '',
      query: '',
      people: initialPeople
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      name: Yup.string().required('Nombre es requerido'),
      email: Yup.string()
        .trim()
        .email('La dirección de correo no es válida')
        .required('El email es requerido'),
      date: Yup.string().required('La fecha es requerida'),
      prefers: Yup.object().typeError('Por favor elija que prefiere'),
      phone: Yup.string().required('Teléfono es requerido'),
      phoneCode: Yup.object()
        .typeError('Prefijo es requerido')
        .required('Prefijo es requerido'),
      people: Yup.object().shape({
        adultsCount: Yup.number().required(),
        childrenQty: Yup.number(),
        kidsAges: Yup.array().of(Yup.object())
      })
    }),
    enableReinitialize: true,
    onSubmit: values => {
      actions.submitForm({ ...values, price:departureDateMinPriceSelectedPrice?.amount, email: values.email.trim() });
    }
  });

  const FormComponent = twoColumns
    ? PackageContactFormTwoColumns
    : PackageContactForm;

  const handleChange = key => value => {
    setFieldValue(key, value);
    key === 'date' && onSetQueryDate(value);
    setFieldTouched(key, true);
  };

  return (
    <FormComponent
      {...props}
      {...state}
      {...values}
      date={departureDateMinPriceSelected || values.date}
      errors={errors}
      onChange={handleChange}
      onSubmit={debounce(handleSubmit, 200)}
      actions={actions}
      listPhoneCodes={listPhoneCodes}
      price={departureDateMinPriceSelectedPrice?.amount}
      currency={departureDateMinPriceSelectedPrice?.currency}
      touched={touched}
      title={title}
    />
  );
};

ConnectedPackageContactForm.propTypes = {
  twoColumns: PropTypes.bool,
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      price: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired
      })
    })
  ),
  title: PropTypes.string,
};

export default ConnectedPackageContactForm;
export { PackageContactForm };
