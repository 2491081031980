import { IErrorResponse } from 'Services/Quotations/types';
import { ILoginResponse, IUser } from 'Services/Users/types';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILURE = 'RESET_USER_PASSWORD_FAILURE';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export const SET_LOGGED_USER_REQUEST = 'SET_LOGGED_USER_REQUEST';
export const SET_LOGGED_USER_FAILURE = 'SET_LOGGED_USER_FAILURE';
export const SET_LOGGED_USER_SUCCESS = 'SET_LOGGED_USER_SUCCESS';

interface IUserLoginRequest {
  type: typeof USER_LOGIN_REQUEST;
}

interface IUserLoginSuccess {
  type: typeof USER_LOGIN_SUCCESS;
  payload?: ILoginResponse;
}

interface IUserLoginFailure {
  type: typeof USER_LOGIN_FAILURE;
  payload: IErrorResponse;
}

interface IUserNewPasswordRequest {
  type: typeof RESET_USER_PASSWORD_REQUEST;
}

interface IUserNewPasswordSuccess {
  type: typeof RESET_USER_PASSWORD_SUCCESS;
}

interface IUserNewPasswordFailure {
  type: typeof RESET_USER_PASSWORD_FAILURE;
  payload?: IErrorResponse;
}

interface IUserLogoutRequest {
  type: typeof USER_LOGOUT_REQUEST;
}

interface IUserLogoutSuccess {
  type: typeof USER_LOGOUT_SUCCESS;
}

interface IUserLogoutFailure {
  type: typeof USER_LOGOUT_FAILURE;
  payload?: IErrorResponse;
}

interface ILoggedUser {
  type: typeof SET_LOGGED_USER_SUCCESS;
  payload: IUser;
}

interface ILoggedUserSuccessRequest {
  type: typeof SET_LOGGED_USER_FAILURE | typeof SET_LOGGED_USER_REQUEST;
}

export type TUserActionTypes =
  | IUserLoginFailure
  | IUserLoginRequest
  | IUserLoginSuccess
  | IUserNewPasswordFailure
  | IUserNewPasswordRequest
  | IUserNewPasswordSuccess
  | IUserLogoutSuccess
  | IUserLogoutFailure
  | IUserLogoutRequest
  | ILoggedUserSuccessRequest
  | ILoggedUser;
