/**
 *
 * AirAvailabilityCard
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cs from 'classnames';
import Icon from 'Components/Icon';
import GloboToggle from 'Components/GloboToggle';
import {
  isoDateToString,
  timeStringWithoutSeconds,
  getFormatPrice
} from '../../../../Utils';
import Scales from 'Components/Scales';
import FlightItineraryDetails from '../FlightItineraryDetails';
import Text from 'Components/Text';
import Button from 'Components/Button';
import AirlineLogo from '../AirLineLogo';
import * as COLORS from 'Constants/colors';
import Modal from 'Components/Modal';
import FullPriceDetail from 'Components/FullPriceDetail';
import AirFlexTextCommon from '../AirFlexTextCommon';
import { excludeAirlineBaggage } from '../../../../Constants/index';

const initialMultiDestinationDisplayDetail = length => {
  const toReturn = [];
  for (var i = 0; i < length; i++) {
    toReturn.push(false);
  }
  return toReturn;
};

const AirAvailabilityCard = ({
  className,
  generalSegment,
  flightPrice,
  localPrice,
  endDate,
  initialDate,
  routesData,
  onClick,
  pending,
  showAllFlights,
  mpcView = false,
  isQuotation = false,
  readOnlySelectedSegmets = false,
  id,
  toggleShowModal,
  flightObject,
  hasFlex
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const isMultiDestination = generalSegment.length > 2;
  const ida = generalSegment[0];
  const vuelta = generalSegment[1] ? generalSegment[1] : false;
  const newIda = ida
    ? ida.map(item => {
      const toReturn = { ...item, displayDetails: false };
      return toReturn;
    })
    : false;
  const newVuelta = vuelta
    ? vuelta.map(item => ({ ...item, displayDetails: false }))
    : false;
  const [showDesglose, setShowDesglose] = useState(false);
  const [componentState, setComponentState] = useState({
    stateIda: newIda,
    stateVuelta: newVuelta,
    selectedIda: { index: 0 },
    selectedVuelta: { index: 0 },
    multiDestinationDisplayDetail: initialMultiDestinationDisplayDetail(
      generalSegment[0].length
    ),
    multiDestination: isMultiDestination
  });
  const selectButtonLabel = 'SELECCIONAR';

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    const effectIda = generalSegment[0];
    const effectVuelta = generalSegment[1] ? generalSegment[1] : false;
    const effectNewIda = effectIda
      ? effectIda.map(item => ({ ...item, displayDetails: false }))
      : false;
    const effectNewVuelta = effectVuelta
      ? effectVuelta.map(item => ({ ...item, displayDetails: false }))
      : false;
    setComponentState({
      ...componentState,
      stateIda: effectNewIda,
      stateVuelta: effectNewVuelta,
      selectedIda: { index: 0, item: effectIda[0] },
      selectedVuelta: { index: 0, item: effectVuelta[0] }
    });
  }, [generalSegment]);

  const handleClickDesglose = () => {
    setShowDesglose(!showDesglose);
  };

  const handleClickShowSegment = (index, field) => () => {
    const newState = { ...componentState };
    newState[field][index].displayDetails = !newState[field][index]
      .displayDetails;
    setComponentState(newState);
  };

  const handleClickShowSegmentMultiDestination = index => () => {
    const newState = { ...componentState };
    newState.multiDestinationDisplayDetail[index] = !newState
      .multiDestinationDisplayDetail[index];
    setComponentState(newState);
  };

  const handleSelectSegment = (item, index, field) => () => {
    const newState = { ...componentState };
    newState[field] = {
      item,
      index
    };
    setComponentState(newState);
  };

  const handleSelect = () => {
    if (toggleShowModal) {
      toggleShowModal();
    }
    onClick(
      {
        ida: componentState.selectedIda,
        vuelta: componentState.selectedVuelta
      },
      id,
      flightPrice,
      flightObject
    );
  };

  const renderPriceDetail = () => (
    <>
      <div>
        <Text context="important">
          <Text
            weight="bold"
            paragraph
            className="text-center"
            size="large"
            context="important">
            {getFormatPrice(
              flightPrice.totalForOneAdult,
              flightPrice.breakdown[0].currency
                ? flightPrice.breakdown[0].currency
                : 'USD'
            )}
          </Text>
          {localPrice && (
            <Text
              weight="semi-bold"
              paragraph
              className="text-center"
              size="small"
              context="important">
              {getFormatPrice(
                localPrice.totalForOneAdult,
                localPrice.breakdown[0].currency
                  ? localPrice.breakdown[0].currency
                  : 'USD',
                true
              )}
            </Text>
          )}
        </Text>
      </div>
      <div>
        <Text context="important" size="small">
          Precio por Adulto
        </Text>
      </div>
      <div>
        <Button size="sm" onClick={handleSelect} disabled={pending}>
          {selectButtonLabel}
        </Button>
      </div>
      <div className="desglose-holder" onClick={handleClickDesglose}>
        <Text context="information">
          Desglose{' '}
          <Icon name={showDesglose ? 'mv-arrow-up' : 'mv-arrow-down'} />{' '}
        </Text>
      </div>
      {showDesglose && (
        <div className="price-detail-container animated fadeIn">
          {breakdown.map(
            (item, index) =>
              item.price > 0 && (
                <div
                  key={index}
                  className={
                    index < breakdown.length - 1
                      ? 'price-detail-item'
                      : 'total-price-detail-item'
                  }>
                  <Text
                    size="small"
                    weight={index === breakdown.length - 1 ? 'bold' : 'default'}
                    context={
                      index === breakdown.length - 1 ? 'important' : 'default'
                    }>
                    {item.label}
                  </Text>
                  <Text
                    size="small"
                    weight={index === breakdown.length - 1 ? 'bold' : 'default'}
                    context={
                      index === breakdown.length - 1 ? 'important' : 'default'
                    }>
                    {getFormatPrice(
                      item.price,
                      item.currency ? item.currency : 'USD'
                    )}
                  </Text>
                  {localPrice && index === localPrice.breakdown.length - 1 && (
                    <Text
                      paragraph
                      className="pull-right"
                      size="small"
                      weight="bold"
                      context="important">
                      {getFormatPrice(
                        localPrice.breakdown[index].price,
                        localPrice.breakdown[index].currency,
                        true
                      )}
                    </Text>
                  )}
                </div>
              )
          )}
          {mpcView && (
            <div className="text-center">
              <Button
                size="xs"
                context="information"
                onClick={toggleDetails}
                disabled={pending}>
                Detalle
              </Button>
              <Modal
                show={showDetails}
                onClickOverlay={toggleDetails}
                closeButton={{ onClick: toggleDetails }}
                header="DETALLE DE LOS PRECIOS"
                headerCentered>
                <FullPriceDetail
                  pricePassengerDetail={flightPrice.pricePassengerDetail}
                  pricePassengerDetailLocal={localPrice?.pricePassengerDetail}
                />
              </Modal>
            </div>
          )}
        </div>
      )}
    </>
  );

  const { breakdown, flexDescription, maxFees } = flightPrice;

  return (
    <FlexibleContainer hasFlex={hasFlex}>
      {hasFlex && (
        <FlexText>
          <IconMargin name="mv-timeleft" size={28} color="white" />
          <span>
            {' '}
            <AirFlexTextCommon />
            {flexDescription}
          </span>
        </FlexText>
      )}
      <div className={cs(className, { detailModal: mpcView })}>
        <div className="air-segments">
          {!isMultiDestination && (
            <div className="topHeaders">
              <div className="header date-header">
                <IconLabel>
                  <IconMargin name="mv-plane-ida" color="white" />
                  <Text context="arena" className="orange-header">
                    ida
                  </Text>
                </IconLabel>
                <Text context="white">{isoDateToString(initialDate)}</Text>
              </div>
              <div className="header">
                <Text context="arena" className="orange-header airport-code">
                  {ida[0].departureData.airport.code}
                </Text>
                <Text context="white">
                  {ida[0].departureData.airport.city.name}
                </Text>
              </div>
              <div className="header">
                <Text context="arena" className="orange-header airport-code">
                  {ida[0].arrivalData.airport.code}
                </Text>
                <Text context="white">
                  {ida[0].arrivalData.airport.city.name}
                </Text>
              </div>
            </div>
          )}

          {/* FIN DE LOS HEADERS */}

          {/* EMPIEZAN LOS SEGMENTOS IDA */}
          {!isMultiDestination &&
            componentState.stateIda &&
            componentState.stateIda.map((item, index) => {
              if (
                readOnlySelectedSegmets &&
                index !== readOnlySelectedSegmets.ida
              ) {
                return null;
              }
              return (
                <React.Fragment key={index}>
                  <div
                    className={cs('segment', {
                      active: index === componentState.selectedIda.index
                    })}>
                    <div className="whiteBlock">
                      {!readOnlySelectedSegmets && (
                        <div className="globoHolder">
                          <GloboToggle
                            active={index === componentState.selectedIda.index}
                            onClick={handleSelectSegment(
                              item,
                              index,
                              'selectedIda'
                            )}
                          />
                        </div>
                      )}
                      {item.airLines.length > 1 ? (
                        item.airLines.map((airlineItem, indexAirlinItem) => (
                          <AirlineLogo
                            key={indexAirlinItem}
                            code={airlineItem.code}
                          />
                        ))
                      ) : (
                        <React.Fragment>
                          <AirlineLogo
                            code={item.airLines[0].code}
                            name={item.airLines[0].name}
                            showName
                          />
                        </React.Fragment>
                      )}
                    </div>

                    <div className="whiteBlock spaceBetween">
                      <Text>
                        {timeStringWithoutSeconds(
                          item.departureData.flightTime
                        )}
                      </Text>
                      <Scales scales={item.scales} className="scale" />
                    </div>

                    <div className="whiteBlock spaceBetween">
                      <Text>
                        {timeStringWithoutSeconds(item.arrivalData.flightTime)}
                      </Text>
                      <Text>
                        {item.duration.totalHours > 1
                          ? `${item.duration.totalHours}h`
                          : `${item.duration.totalHours}hs`}
                        {item.duration.totalMinutes > 0
                          ? `${item.duration.totalMinutes}m`
                          : null}
                      </Text>
                      <div className="baggage-icons-holder">
                        <Icon
                          name="mv-briefcase"
                          className={cs('briefcaseIcon', {
                            disabled: item.baggage.total === 0
                          })}
                        />
                        <Icon
                          name="mv-hand-bag"
                          className={cs('handBagIconTwo', {
                            disabled:
                              item.baggage.total === 0 &&
                              excludeAirlineBaggage.includes(
                                item.airLines[0].code
                              )
                          })}
                        />
                      </div>

                      <div
                        className={cs('chevronIcon', {
                          active: item.displayDetails
                        })}
                        onClick={handleClickShowSegment(index, 'stateIda')}>
                        {!mpcView ? <Icon name="mv-arrow-down" /> : null}
                      </div>
                    </div>
                  </div>
                  {(item.displayDetails || mpcView) && (
                    <FlightItineraryDetails
                      segments={item.segments}
                      scales={item.scales}
                      duration={item.duration}
                      baggage={item.baggage}
                    />
                  )}
                </React.Fragment>
              );
            })}

          {/* EMPIEZAN LOS SEGMENTOS DE VUELTA */}

          {!isMultiDestination && componentState.stateVuelta && (
            <div className="topHeaders">
              <div className="header date-header">
                <IconLabel>
                  <IconMargin name="mv-plane-vuelta" color="white" />
                  <Text context="arena" className="orange-header">
                    Vuelta
                  </Text>
                </IconLabel>
                <Text context="white">{isoDateToString(endDate)}</Text>
              </div>
              <div className="header">
                <Text context="arena" className="orange-header airport-code">
                  {vuelta[0].departureData.airport.code}
                </Text>

                <Text context="white">
                  {vuelta[0].departureData.airport.city.name}
                </Text>
              </div>
              <div className="header">
                <Text context="arena" className="orange-header airport-code">
                  {vuelta[0].arrivalData.airport.code}
                </Text>
                <Text context="white">
                  {vuelta[0].arrivalData.airport.city.name}
                </Text>
              </div>
            </div>
          )}

          {!isMultiDestination &&
            componentState.stateVuelta &&
            componentState.stateVuelta.map((item, index) => {
              if (
                readOnlySelectedSegmets &&
                index !== readOnlySelectedSegmets.vuelta
              ) {
                return null;
              }
              return (
                <React.Fragment key={index}>
                  <div
                    className={cs('segment', {
                      active: index === componentState.selectedVuelta.index
                    })}>
                    <div className="whiteBlock">
                      {!readOnlySelectedSegmets && (
                        <div className="globoHolder">
                          <GloboToggle
                            active={
                              index === componentState.selectedVuelta.index
                            }
                            onClick={handleSelectSegment(
                              item,
                              index,
                              'selectedVuelta'
                            )}
                          />
                        </div>
                      )}
                      {item.airLines.length > 1 ? (
                        item.airLines.map((airlineItem, indexAirlinItem) => (
                          <AirlineLogo
                            key={indexAirlinItem}
                            code={airlineItem.code}
                          />
                        ))
                      ) : (
                        <React.Fragment>
                          <AirlineLogo
                            code={item.airLines[0].code}
                            name={item.airLines[0].name}
                            showName
                          />
                        </React.Fragment>
                      )}
                    </div>

                    <div className="whiteBlock spaceBetween">
                      <Text>
                        {timeStringWithoutSeconds(
                          item.departureData.flightTime
                        )}
                      </Text>
                      <Scales scales={item.scales} className="scale" />
                    </div>

                    <div className="whiteBlock spaceBetween">
                      <Text>
                        {timeStringWithoutSeconds(item.arrivalData.flightTime)}
                      </Text>
                      <Text>
                        {item.duration.totalHours > 1
                          ? `${item.duration.totalHours}h`
                          : `${item.duration.totalHours}hs`}
                        {item.duration.totalMinutes > 0
                          ? `${item.duration.totalMinutes}m`
                          : null}
                      </Text>
                      <div className="baggage-icons-holder">
                        <Icon
                          name="mv-briefcase"
                          className={cs('briefcaseIcon', {
                            disabled: item.baggage.total === 0
                          })}
                        />
                        <Icon
                          name="mv-hand-bag"
                          className={cs('handBagIconTwo', {
                            disabled:
                              item.baggage.total === 0 &&
                              excludeAirlineBaggage.includes(
                                item.airLines[0].code
                              )
                          })}
                        />
                      </div>
                      <span
                        className={cs('chevronIcon', {
                          active: item.displayDetails
                        })}
                        onClick={handleClickShowSegment(index, 'stateVuelta')}>
                        {!mpcView ? <Icon name="mv-arrow-down" /> : null}
                      </span>
                    </div>
                  </div>

                  {(item.displayDetails || mpcView) && (
                    <FlightItineraryDetails
                      segments={item.segments}
                      scales={item.scales}
                      duration={item.duration}
                      baggage={item.baggage}
                    />
                  )}

                  {mpcView && (
                    <div className="mv-center-button">
                      <Button
                        size="sm"
                        onClick={handleSelect}
                        disabled={pending}>
                        {selectButtonLabel}
                      </Button>
                    </div>
                  )}
                </React.Fragment>
              );
            })}

          {/* MULTIDESTINTO */}

          {isMultiDestination &&
            generalSegment.map((generalSegmentItem, index) => (
              <React.Fragment key={index}>
                <div className="topHeaders">
                  <div className="header">
                    <Icon name="mv-plane-vuelta" />
                    <div className="orange-header"># {index + 1}</div>
                    <Text context="white">
                      {isoDateToString(routesData[index].initialDate)}
                    </Text>
                  </div>
                  <div className="header">
                    <div className="orange-header">
                      {generalSegmentItem[0].departureData.airport.code}
                    </div>
                    <Text context="white">
                      {generalSegmentItem[0].departureData.airport.city.name}
                    </Text>
                  </div>
                  <div className="header">
                    <div className="orange-header">
                      {generalSegmentItem[0].arrivalData.airport.code}
                    </div>
                    <Text context="white">
                      {generalSegmentItem[0].arrivalData.airport.city.name}
                    </Text>
                  </div>
                </div>

                {generalSegmentItem.map((segmentItem, indexSegment) => (
                  <React.Fragment key={indexSegment}>
                    <div className={cs('segment', 'active')}>
                      <div className="whiteBlock">
                        {segmentItem.airLines.length > 1 ? (
                          segmentItem.airLines.map(
                            (airlineItem, indexAirlinItem) => (
                              <AirlineLogo
                                key={indexAirlinItem}
                                code={airlineItem.code}
                              />
                            )
                          )
                        ) : (
                          <React.Fragment>
                            <AirlineLogo code={segmentItem.airLines[0].code} />
                            <div className="grey-text-white-box">
                              {segmentItem.airLines[0].name}
                            </div>
                          </React.Fragment>
                        )}
                      </div>

                      <div className="whiteBlock">
                        <div className="grey-text-white-box">
                          {timeStringWithoutSeconds(
                            segmentItem.departureData.flightTime
                          )}
                        </div>
                        <div className="scales-holder">
                          {generalSegmentItem}
                          <Scales scales={segmentItem.scales} />
                        </div>
                      </div>

                      <div className="whiteBlock spaceBetween">
                        <div className="flexContainer">
                          <div className="grey-text-white-box">
                            {timeStringWithoutSeconds(
                              segmentItem.arrivalData.flightTime
                            )}
                          </div>
                          <div className="grey-text-white-box">
                            {segmentItem.duration.totalHours > 1
                              ? `${segmentItem.duration.totalHours}h`
                              : `${segmentItem.duration.totalHours}hs`}
                            {segmentItem.duration.totalMinutes > 0
                              ? `${segmentItem.duration.totalMinutes}m`
                              : null}
                          </div>
                        </div>
                        <div className="flexContainer">
                          <span className="briefcaseIcon">
                            <Icon name="mv-briefcase" />
                          </span>
                          <span className="handBagIcon">
                            <Icon name="mv-hand-bag" />
                          </span>
                        </div>
                        <span
                          className={cs('chevronIcon', {
                            active: componentState.multiDestinationDisplayDetail
                              ? componentState.multiDestinationDisplayDetail[
                                index
                              ]
                              : false
                          })}
                          onClick={handleClickShowSegmentMultiDestination(
                            index
                          )}>
                          <Icon name="mv-arrow-down" />
                        </span>
                      </div>
                    </div>
                    {componentState.multiDestinationDisplayDetail &&
                      componentState.multiDestinationDisplayDetail[index] && (
                      <FlightItineraryDetails
                        segments={segmentItem.segments}
                        scales={segmentItem.scales}
                        duration={segmentItem.duration}
                        baggage={segmentItem.baggage}
                        noSelect
                      />
                    )}
                  </React.Fragment>
                ))}

                {mpcView && (
                  <div className="mv-center-button">
                    <Button size="sm" disabled={pending} onClick={handleSelect}>
                      {selectButtonLabel}
                    </Button>
                  </div>
                )}
              </React.Fragment>
            ))}

          {mpcView && (
            <div className="mv-all-flight">
              <Button
                context="information"
                onClick={() => showAllFlights({ initialDate, endDate })}
                size="sm">
                <Icon name="mv-list" color="white" />
                Ver todos los vuelos para estas fechas
              </Button>
            </div>
          )}
        </div>

        <div className="holder-detail-prices priceColumn">
          <div className="header header--mpc">
            <Text context="white" size="large">
              {isQuotation ? 'Info' : 'PRECIOS'}
            </Text>
          </div>
          <div className={`${mpcView ? 'sticky-wrapper' : ''}`}>
            <div
              className={`priceColumn ${
                mpcView
                  ? ' sticky-inner price-detail-holder'
                  : 'price-detail-holder'
              }`}>
              {!isQuotation && renderPriceDetail()}
            </div>
          </div>
          {maxFees && (
            <FeesContainer>
              <Text>¡Hasta en {maxFees} cuotas! </Text>
            </FeesContainer>
          )}
        </div>
      </div>
    </FlexibleContainer>
  );
};

AirAvailabilityCard.propTypes = {
  className: PropTypes.string.isRequired,
  generalSegment: PropTypes.array,
  flightPrice: PropTypes.object,
  localPrice: PropTypes.object,
  endDate: PropTypes.string,
  initialDate: PropTypes.string,
  routesData: PropTypes.any,
  onClick: PropTypes.func,
  pending: PropTypes.bool,
  mpcView: PropTypes.bool,
  isQuotation: PropTypes.bool,
  showAllFlights: PropTypes.func,
  id: PropTypes.string,
  toggleShowModal: PropTypes.func,
  flightObject: PropTypes.object,
  readOnlySelectedSegmets: PropTypes.any,
  hasFlex: PropTypes.bool
};

const FeesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: auto;
`;

export const FlexText = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    width: 100%;
    padding: ${theme.spacing.two};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  `}
`;

const IconLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconMargin = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing.four};
`;

export const FlexibleContainer = styled.div`
  margin-bottom: 12px;
  ${({ hasFlex, theme }) =>
    hasFlex &&
    css`
      border-radius: 14px;
      background-color: ${theme.palette.primary.orange};
      padding-top: ${theme.spacing.two};
      padding-bottom: ${theme.spacing.two};
      .holder-detail-prices .header--mpc: {
        border-radius: 0 !important;
      }
    `}
`;

export default styled(AirAvailabilityCard)`
  background-color: ${COLORS.white};
  box-shadow: ${({ theme }) => theme.shadows.one};
  ${({ hasFlex }) =>
    hasFlex
      ? css`
          border-radius: 0 0 14px 14px;
        `
      : css`
          border-radius: 14px;
        `}
  min-height: 20px;
  display: flex;
  overflow: hidden;
  position: relative;

  .header {
    display: flex;
    background: ${COLORS.violeta};
    height: 52px;
    padding: 8px 8px 6px 8px;
    flex: 1;
    align-items: center;
    flex-direction: column;
    place-items: self-start;

    &.date-header {
      justify-content: space-between;
    }

    .orange-header {
      text-transform: uppercase;

      &.airport-code {
        min-width: 35px;
        margin-right: 20px;
      }
    }
  }

  .price-detail-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1px 9px;

    .desglose-holder {
      text-align: center;
      cursor: pointer;
      margin-top: 4px;
    }
  }

  &.detailModal {
    overflow: unset !important;
  }

  .price-detail-container {
    width: 100%;

    .price-detail-item {
      display: flex;
      justify-content: space-between;
    }
  }

  .total-price-detail-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid #09184c;

    > p {
      flex-grow: 1;
      min-width: 100%;
      text-align: right;
    }
  }

  .priceColumn {
    min-width: 190px;
    display: flex;
    flex-direction: column;

    .header {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .topHeaders {
    display: flex;
    align-items: center;

    &:first-child {
      overflow: hidden;
      ${({ hasFlex }) => !hasFlex && 'border-top-left-radius: 14px;'}
    }
  }

  .airlineLogo {
    max-width: 32px;
    max-height: 32px;
    height: auto;
    width: auto;
    margin-right: 8px;
  }

  .grey-text-white-box {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.93;
    letter-spacing: 0.14px;
    text-align: left;
    color: ${COLORS.darkGrey};
    margin-left: 7px;
  }

  .air-segments {
    display: flex;
    margin-right: 6px;
    flex: 1;
    flex-direction: column;
    max-width: 100%;
    position: relative;

    .header {
      margin-right: 6px;
      flex: 1;

      &:last-child {
        margin-right: 0;
      }
    }

    .segment {
      display: flex;
      background-color: ${COLORS.blackHaze};
      transition: 0.6s;

      &.active {
        z-index: 1;
        background-color: ${COLORS.white};
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
      }
    }

    .whiteBlock {
      height: 42px;
      display: flex;
      align-items: center;
      padding-left: 9px;
      flex: 1;

      &.spaceBetween {
        justify-content: space-between;
      }

      .baggage-icons-holder {
        display: flex;
        align-items: baseline;
      }

      .briefcaseIcon {
        font-size: 25px;

        &.disabled {
          color: ${COLORS.darkGrey};
        }
      }

      .chevronIcon {
        margin-right: 10px;
        cursor: pointer;
        transition: 0.6s;

        &.active {
          transform: rotate(180deg);
        }
      }

      .handBagIcon {
        position: relative;
        top: 10px;
      }

      .handBagIconTwo {
        font-size: 25px;

        &.disabled {
          color: ${COLORS.darkGrey};
        }
      }

      .flexContainer {
        display: flex;
        flex: 1;
      }

      .icon {
        color: ${COLORS.important};
      }

      .globoHolder {
        margin-right: 22px;
      }

      .scales-holder {
        margin-left: 53px;
      }

      .scale {
        margin-right: 30px;
      }
    }

    .dateColumn {
      margin-right: 6px;
      flex: 1;
    }

    .fromColumn {
      margin-right: 6px;
      flex: 1;
    }

    .toColumn {
      margin-right: 6px;
      flex: 1;
    }
  }

  .mv-center-button {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mv-all-flight {
    position: absolute;
    bottom: -35px;

    button {
      span {
        margin-right: 10px;
      }
    }
  }

  .holder-detail-prices {
    position: relative;

    .header--mpc {
      ${({ hasFlex }) => !hasFlex && 'border-top-right-radius: 14px;'}
      max-height: 52px;
      min-height: 52px;

      .close-modal {
        position: absolute;
        right: -46px;
        top: 2px;
        cursor: pointer;
        z-index: 2;
      }
    }

    .sticky-wrapper {
      position: relative;
      width: 190px;

      .sticky-inner {
        position: fixed;
        z-index: 1;
      }
    }
  }
`;
