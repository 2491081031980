/**
 *
 * ModalConfirmation
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Modal from '../Modal';
import Button from '../Button';

const ModalConfirmation = ({
  className,
  show,
  children,
  onAccept,
  onCancel,
  header,
  headerCentered,
  onClickOverlay
}) => (
  <Modal
    className={cs(className)}
    header={header}
    onClickOverlay={onClickOverlay}
    headerCentered={headerCentered}
    show={show}>
    <div className={cs({ 'modal-body': show })}>{children}</div>
    <div className="modal-actions">
      <Button onClick={onAccept}>Si</Button>
      <Button onClick={onCancel}>No</Button>
    </div>
  </Modal>
);

ModalConfirmation.propTypes = {
  className: PropTypes.string.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  header: PropTypes.string,
  headerCentered: PropTypes.bool,
  onClickOverlay: PropTypes.func
};

export default styled(ModalConfirmation)`
  .modal-body {
    display: flex;
    flex-direction: column;
  }

  .modal-actions {
    display: flex;
    justify-content: space-around;
  }
`;
