export const getFlightsMiniBanners = (destinationCode: string) => ({
  query: `{
        flightPrices(destination: "${destinationCode}"){      
            id
            dateFrom
            dateTo
            origin
            destination
            month
            price
          }
    }`
});
