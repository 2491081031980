/**
 *
 * Networks
 *
 */

// Vendors
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

// Components
import Radio from '../Radio';

import { darkGrey } from 'Constants/colors';

// Utils
import { scrollIntoView } from 'Utils';

const Networks = ({
  className,
  onSubmit,
  onValid,
  networks,
  selectedNetwork
}) => {
  const container = useRef(null);

  const onClickNetwork = network => () => {
    onSubmit(network);
  };

  useEffect(() => {
    onValid(selectedNetwork.id);
  }, [selectedNetwork]);

  useEffect(() => {
    scrollIntoView(container);
  }, []);

  return (
    <div className={cs(className)} ref={container}>
      <div className="networks__list">
        {networks &&
          networks.map(n => {
            const checked = selectedNetwork && selectedNetwork.id === n.id;

            return (
              <div className="network" key={n.id}>
                <Radio
                  className="network__radio"
                  checked={checked}
                  onClick={onClickNetwork(n)}>
                  <img src={n.image} alt={n.image} />
                  <div className="text">
                    <b className="network__name">{n.name}</b>
                    <span className="network__number"></span>
                    <span className="network__biller">Nombre: MeVuelo.com</span>
                  </div>
                </Radio>
              </div>
            );
          })}
      </div>
    </div>
  );
};

Networks.propTypes = {
  className: PropTypes.string,
  networks: PropTypes.array,
  onSubmit: PropTypes.func,
  onValid: PropTypes.func,
  selectedNetwork: PropTypes.object
};

export default styled(Networks)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .networks__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    margin-bottom: 12px;

    .network {
      &__radio {
        display: flex;
        align-items: center;
        .text {
          display: flex;
          flex-direction: column;

          .network {
            &__name {
              font-size: 16.5px;
              font-weight: 500;
              line-height: 1.45;
              color: ${darkGrey};
              text-transform: capitalize;
            }

            &__number,
            &__biller {
              font-size: 14px;
              line-height: 1.25;
              letter-spacing: 0.14px;
              color: ${darkGrey};
            }
          }
        }
        img {
          max-width: 100px;
          max-height: 250px;
          height: auto;
        }
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
