import React, { memo } from 'react';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';

// Types
import { LoginProps } from '../Login/types';

// Components
import Input from 'Components/NewInput';
import Label from 'Components/Label';
import Form from '../Form';

// Constants
import { formErrors } from 'Constants';

// Types
import { ResetValues } from './types';

const Reset = memo(({ error, pending, onSubmit }: LoginProps) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit
  }: FormikValues = useFormik<ResetValues>({
    initialValues: {
      currentPassword: '',
      newPassword: ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      currentPassword: Yup.string().required(formErrors.required),
      newPassword: Yup.string().required(formErrors.required)
    }),
    onSubmit
  });

  return (
    <Form onSubmit={handleSubmit} pending={pending} error={error}>
      <div>
        <Label color="violet">Constraseña actual:</Label>

        <Input
          placeholder="Constraseña actual"
          onChange={handleChange('currentPassword')}
          value={values.currentPassword}
          error={{ message: errors.currentPassword }}
        />
      </div>

      <div>
        <Label color="violet">Nueva constraseña:</Label>

        <Input
          placeholder="Nueva constraseña"
          onChange={handleChange('newPassword')}
          value={values.newPassword}
          error={{
            message:
              errors.newPassword ||
              (values.newPassword && formErrors.passwordPattern)
          }}
        />
      </div>
    </Form>
  );
});

export default Reset;
