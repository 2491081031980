/**
 *
 * InvoiceForm
 *
 */

// Vendors
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import TextArea from '../TextArea';

// Utils
import { useValidatedForm } from 'Utils/hooks/useValidatedForm';

// Constants
import { formErrors } from 'Constants';
import {
  commentPlaceholderFlights,
  commentPlaceholderHotels,
  engines
} from '../../Constants';

const CommentaryInput = ({ actions, corpoData, onValid, engineName }) => {
  const { fields, errors, handleChange, handleValidate } = useValidatedForm({
    fields: {
      comment: ''
    },
    errors: {
      comment: [formErrors.lessThanOne]
    },
    validators: {
      comment: comment => [comment.length < 1]
    }
  });

  const handleBlur = () => {
    actions.setCorpoData({
      comment: {
        ...corpoData.comment,
        ...fields
      }
    });
  };

  const placeholder =
    engineName === engines.FLIGHTS.name ||
    engineName === engines.FLIGHTS.name + 'MPC'
      ? commentPlaceholderFlights
      : commentPlaceholderHotels;

  useEffect(() => {
    onValid(handleValidate());
  }, [fields]);

  return (
    <div>
      <Fields>
        <TextArea
          isRequired
          className="field"
          placeholder={placeholder}
          rows={10}
          label="Comentarios"
          message={errors.comment}
          context={errors.comment && 'warning'}
          value={fields.comment}
          onChange={handleChange('comment')}
          onBlur={handleBlur}
        />
      </Fields>
    </div>
  );
};

const Fields = styled.div`
  & .field {
    width: 100%;
  }
`;

CommentaryInput.propTypes = {
  onChange: PropTypes.func,
  fields: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  actions: PropTypes.object,
  corpoData: PropTypes.object,
  engineName: PropTypes.string,
  onValid: PropTypes.func
};

export default CommentaryInput;
