/**
 *
 * BlogBreadcrumbs
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { Link } from 'react-router-dom';

import { ConnectedRouter } from 'connected-react-router';

import { history } from 'ReduxConfig/store';

import * as COLORS from 'Constants/colors';
import Text from 'Components/Text';
import { engines } from 'Constants';

const BlogBreadcrumbs = ({ className, title }) =>
  title ? (
    <div className={cs(className)}>
      <ConnectedRouter history={history}>
        <ul className="breadcrumbs">
          <li className="breadcrumbs-items">
            <Link to={engines.BLOG.path}>
              <Text context="white">Blog</Text>
            </Link>
          </li>
          <li className="breadcrumbs-items">
            <Text context="white" weight="bold">
              {title}
            </Text>
          </li>
        </ul>
      </ConnectedRouter>
    </div>
  ) : null;

BlogBreadcrumbs.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
};

export default styled(BlogBreadcrumbs)`
  display: flex;
  flex-direction: row;
  padding-top: ${({ theme }) => theme.spacing.four};
  padding-bottom: 32px;
  color: white;

  @media all and (max-width: 960px) {
    display: none;
  }

  .breadcrumbs {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: row;

    &-items {
      display: block;
      color: ${COLORS.white};
      margin: 2px 2px 2px 0;

      a {
        text-decoration: none;
      }

      &:not(:last-child) {
        &::after {
          content: '>';
          padding: 2px;
          font-size: 14px;
        }
      }
    }
  }
`;
