// Vendors
import { cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Actions
import { hideNavbar, updateLoader } from 'Features/app/actions';
import { getCitizens, getCountries } from 'ReduxConfig/places';

import {
  corpoCheckout,
  fillCorpoCheckoutSteps,
  resetCorpoData
} from 'ReduxConfig/checkoutCorpo';

// Constants
import { engineCodes, engines, requestErrors } from 'Constants';

// Utils
import { arrangeParamsCorpo } from '../../Utils/checkoutCorpo';

// Actions
import { toggleTacModal } from 'Features/common';
import { push } from 'connected-react-router';

const ProxyCorpo = ({ children }) => {
  const { corpoData, availability, engine, tacModal, hotels } = useSelector(
    ({ availability, hotels, common, checkoutCorpo }) => ({
      corpoData: checkoutCorpo.requestData,
      availability: checkoutCorpo.selectedAvailability,
      engine: availability.engine,
      steps: checkoutCorpo.steps,
      tacModal: common.tacModal,
      hotels: {
        ...hotels,
        availability: checkoutCorpo.selectedAvailability
      }
    })
  );

  const dispatch = useDispatch();

  const runProcess = () => {
    const params = arrangeParamsCorpo(
      { checkoutCorpo: corpoData, availability, hotels },
      engine
    );
    switch (engine) {
      case engines.FLIGHTS.name:
      case engines.FLIGHTS.name + 'MPC':
        dispatch(corpoCheckout(engineCodes.vuelos, params))
          .then(res => {
            dispatch(push(`/checkout-corpo/${res.reserveCode}/thank-you`));
          })
          .catch(handleError);
        break;
      case 'hotel-details':
      case engines.HOTELS.name:
        dispatch(corpoCheckout(engineCodes.hoteles, params))
          .then(res => {
            dispatch(push(`/checkout-corpo/${res.reserveCode}/thank-you`));
          })
          .catch(handleError);
        break;
      default:
    }
  };

  const handleTacModal = () => {
    dispatch(toggleTacModal());
  };

  const handleError = err => {
    if (err.response) {
      return toast.error(err.response.data.message || requestErrors.default);
    }

    toast.error(requestErrors.default);
  };

  useEffect(() => {
    dispatch(getCitizens());
    dispatch(getCountries({ size: -1, page: 0 }));
    dispatch(updateLoader(false));
  }, [availability, engine, dispatch]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    dispatch(fillCorpoCheckoutSteps(engine));
    dispatch(resetCorpoData());
    dispatch(hideNavbar(true));
    return () => {
      dispatch(hideNavbar(false));
    };
  }, []);

  return cloneElement(children, {
    runProcess,
    dispatch,
    availability,
    engine,
    handleTacModal,
    tacModal,
    corpoData,
    hotelProperty: hotels
  });
};

ProxyCorpo.propTypes = {
  children: PropTypes.element.isRequired
};

export default ProxyCorpo;
