import { AxiosInstance } from 'axios';
import {
  ILoginPayload,
  ILoginResponse,
  IUser,
  IChangePasswordPayload
} from './types';
import { IErrorResponse } from 'Services/Quotations/types';

export default function(axios: AxiosInstance) {
  const login = (payload: ILoginPayload) =>
    axios.post<ILoginResponse | IErrorResponse>('/signin', payload);

  const logout = () => axios.post<null | IErrorResponse>('/signout');

  const reset = (payload: IChangePasswordPayload) =>
    axios.put<null | IErrorResponse>('/users/password', payload);

  const getProfile = () => axios.get<IUser | IErrorResponse>('/users/profiles');

  return {
    login,
    logout,
    reset,
    getProfile
  };
}
