export default function(api) {
  async function suggestions(value) {
    return await api.get(`places/suggestions/${encodeURIComponent(value)}`);
  }

  async function mptb(value) {
    return await api.post('amadeus/mptb', value);
  }

  async function mpc(value) {
    return await api.post('amadeus/mpc', value);
  }

  async function masterPriceJoined(value) {
    return await api.post('amadeus/masterpricejoined', value);
  }

  async function checkSelection(value) {
    return await api.post('amadeus/checkselection', value);
  }

  return {
    mpc,
    mptb,
    masterPriceJoined,
    suggestions,
    checkSelection
  };
}
