import { humanize } from './PaymentMethod.helpers';

export const selector = ({ availability, checkout }) => ({
  paymentTypes:
    checkout.paymentOptions.paymentTypes &&
    checkout.paymentOptions.paymentTypes.map((pt, idx) => ({
      name: humanize(pt),
      id: idx,
      payment: pt
    })),
  cards: checkout.paymentOptions.cards,
  banks: checkout.paymentOptions.transferBanks,
  networks: checkout.paymentOptions.networks,
  offices: checkout.paymentOptions.offices,
  userData: checkout.requestData,
  engine: availability.engine
});
