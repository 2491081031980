import styled from 'styled-components';

// Components
import Text from 'Components/Text';

// Constants
import { breakpoints } from 'Constants';

export const Container = styled.div`
  width: 70px;
  height: 30px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.blackHaze};
  overflow: hidden;
  position: absolute;
  bottom: 0;

  @media screen and (max-width: ${breakpoints.medium}px) {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.newBackground};
    font-weight: ${({ theme }) => theme.text.fontWeights.semibold};
    box-shadow: ${({ theme }) => theme.shadows.two};
  }
`;

export const Month = styled(Text)`
  width: 100%;
  height: 100%;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0.08px;
  padding-top: 4px;
  display: block;
  margin: 0 auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGrey};
`;
