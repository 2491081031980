import React from 'react';
import bck from 'Features/travelInsurance/components/TravelInsuranceHome/images/insuranceBackground.webp';
import ImgTarjetaCeleste from 'Features/travelInsurance/components/TravelInsuranceHome/images/logo_tc4.png';
import ImgHDI from 'Features/travelInsurance/components/TravelInsuranceHome/images/hdi.png';
import ImgWhatsApp from 'Features/travelInsurance/components/TravelInsuranceHome/images/whatsapp.png';
import ImgPhone from 'Features/travelInsurance/components/TravelInsuranceHome/images/mv-phone.png';

import SectionBgImages from 'Components/SectionBgImages';
import Divider from 'Components/Divider';
import Heading from 'Components/Heading';
import ProductCard from '../ProductCard';
import InsuranceInfoCard from '../InsuranceInfoCard';
import NewText from 'Components/NewText';
import { Helmet } from 'react-helmet';

import {
  Wrapper,
  TopLeftContainer,
  ProductsHolder,
  PartnersHolder,
  FaqHolder,
  CardInformationContainer,
  CardInformationHeader,
  CardInformationContent,
  InfoCard,
  InfoCardTopText,
  PhoneHolder,
  PhoneItem,
  Section,
  TopRightContainer,
  TopContainer,
  LogoStyled,
  StyledHeding
} from './components';

import { productos } from './constants';
import Link from 'Components/Link';

const pushToWhatsApp = () => {
  window.open('http://wa.me/573203980618?text=Hola,%20Necesito%20asistencia');
};

const TravelInsuranceHome = () => (
  <Wrapper>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Asistencia y seguro de viaje | mevuelo.com</title>
      <meta
        name="description"
        content="En que consiste un seguro de viaje y la asistencia al viajero? Cuanto cuesta un seguro para viajar?"
      />
      <meta
        name="keywords"
        content="seguro de viajes, asistencia, asistencia al viajero"
      />
      <meta
        property="og:title"
        content="Asistencia y seguro de viaje | mevuelo.com"
      />
      <meta
        property="og:description"
        content="En que consiste un seguro de viaje y la asistencia al viajero? Cuanto cuesta un seguro para viajar?"
      />
    </Helmet>
    {/*
  // @ts-ignore*/}
    <SectionBgImages image={bck} />

    <TopContainer>
      <TopLeftContainer>
        <Heading type="h3" color="white">
          Viajar con asistencia es más seguro y en muchos paises obligatorio.
        </Heading>
        <Heading type="h5" color="white">
          Si es tu primera experiencia, te preguntarás en qué consiste, pues
          bien, te contamos: un seguro médico internacional te permite tener,
          como elemento principal, cobertura médica en caso que te suceda algo
          en el viaje, además de otras coberturas como pueden ser: equipaje,
          cancelación, odontología, etc, según el que contrates.
        </Heading>
        <Heading type="h5" color="white">
          Antes de elegir uno, debes tener claros algunos puntos que te
          comentamos el{' '}
          <Link
            to={'/como-elegir-el-mejor-y-mas-economico-seguro-de-viaje/blog'}>
            siguiente artículo
          </Link>{' '}
          de nuestro blog.
        </Heading>
      </TopLeftContainer>
      <TopRightContainer>
        <Divider />
        <InsuranceInfoCard
          title="Nuestros productos cubren Preexistencias"
          iconName="medkit"></InsuranceInfoCard>
        <InsuranceInfoCard
          title="No tienen finalizacion de vigencia"
          iconName="mv-timeleft"></InsuranceInfoCard>
        <InsuranceInfoCard
          title="Tenemos productos sin limite de edad"
          iconName="mv-family"></InsuranceInfoCard>
        <InsuranceInfoCard
          title="Cualquiera sea tu nacionalidad o residencia"
          iconName="mv-assistance"></InsuranceInfoCard>
      </TopRightContainer>
    </TopContainer>
    <Section>
      <Divider />
      <ProductsHolder>
        {productos.map(p => (
          <ProductCard data={p} key={p.id} />
        ))}
      </ProductsHolder>
    </Section>
    <Section>
      <Divider titleText="Trabajamos en tu seguridad junto con:" />
      <PartnersHolder>
        <LogoStyled src={ImgTarjetaCeleste} alt="Tarjeta-Celeste" />
        <LogoStyled src={ImgHDI} alt="HDI-Seguros" />
      </PartnersHolder>
    </Section>
    <Section>
      <Divider titleText="Preguntas frecuentes:" />
      <FaqHolder>
        <CardInformationContainer>
          <CardInformationHeader>
            <StyledHeding color="white" fontWeight="medium">
              ¿Qué es la asistencia al viajero?
            </StyledHeding>
          </CardInformationHeader>
          <CardInformationContent>
            <NewText>
              El servicio de asistencia al viajero ofrece una cobertura
              integral, no solo médica, sino también otros tipos de coberturas,
              tales como: localización de equipaje, asistencia legal, traslado
              y/o repatriación sanitaria, repatriación de restos, transferencia
              de fondos en caso de urgencia, entre otros.{' '}
            </NewText>
          </CardInformationContent>
        </CardInformationContainer>
        <CardInformationContainer>
          <CardInformationHeader>
            <StyledHeding color="white" fontWeight="medium">
              ¿Desde qué momento estoy cubierto?
            </StyledHeding>
          </CardInformationHeader>
          <CardInformationContent>
            <NewText>
              Desde el momento que comienza la vigencia de la cobertura y a 80
              km de tu residencia habitual o bien de aquella consignada en el
              Contrato de Asistencia.
            </NewText>
          </CardInformationContent>
        </CardInformationContainer>
        <CardInformationContainer>
          <CardInformationHeader>
            <StyledHeding color="white" fontWeight="medium">
              ¿Qué debo hacer si necesito asistencia?
            </StyledHeding>
          </CardInformationHeader>
          <CardInformationContent>
            <NewText>
              El viajero debe contactar con su Central de Asistencia en los
              números de teléfono que aparecen más abajo y que también se
              reflejan en tu Contrato de Asistencia.
            </NewText>
          </CardInformationContent>
        </CardInformationContainer>
        <CardInformationContainer>
          <CardInformationHeader>
            <StyledHeding color="white" fontWeight="medium">
              ¿La Asistencia cubre servicio en el hotel?
            </StyledHeding>
          </CardInformationHeader>
          <CardInformationContent>
            <NewText>
              Nuestro servicio de asistencia valora cada solicitud y decide qué
              es lo más conveniente para el viajero.
            </NewText>
          </CardInformationContent>
        </CardInformationContainer>
        <CardInformationContainer>
          <CardInformationHeader>
            <StyledHeding color="white" fontWeight="medium">
              ¿Qué es enfermedad preexistente?
            </StyledHeding>
          </CardInformationHeader>
          <CardInformationContent>
            <NewText>
              Se considera como enfermedad preexistente toda aquella dolencia
              (sea conocida o no por el paciente) presente previamente al viaje,
              como, por ejemplo, diabetes, hipertensión, problemas cardíacos,
              etc.
            </NewText>
          </CardInformationContent>
        </CardInformationContainer>
        <CardInformationContainer>
          <CardInformationHeader>
            <StyledHeding color="white" fontWeight="medium">
              ¿Cubre enfermedades crónicas?
            </StyledHeding>
          </CardInformationHeader>
          <CardInformationContent>
            <NewText>
              Existe una cobertura y su correspondiente monto económico para
              cada enfermedad preexistente. Es recomendable valorar cada una de
              las coberturas que se ofrecen en el área Nuestros Productos o
              poniéndote en contacto a través de cualquiera de nuestros canales
            </NewText>
          </CardInformationContent>
        </CardInformationContainer>
        <CardInformationContainer>
          <CardInformationHeader>
            <StyledHeding color="white" fontWeight="medium">
              ¿Estoy cubierta en caso de embarazo?
            </StyledHeding>
          </CardInformationHeader>
          <CardInformationContent>
            <NewText>
              Todos nuestros PLANES de Tarjeta Celeste cubren hasta la semana 24
              de gestación; en caso de tener un embarazo más avanzado deberá
              contratarse un Up Grade Futura Mamá.
            </NewText>
          </CardInformationContent>
        </CardInformationContainer>
        <CardInformationContainer>
          <CardInformationHeader>
            <StyledHeding color="white" fontWeight="medium">
              ¿Qué hago si se extravía mi equipaje?
            </StyledHeding>
          </CardInformationHeader>
          <CardInformationContent>
            <NewText>
              Lo primero que debe hacer el viajero es denunciarlo en la
              aerolínea SIN SALIR DEL AEROPUERTO. Una vez realizado este
              trámite, debe ponerse en comunicación con la Central de Asistencia
              para informar lo sucedido y conocer los pasos a seguir.
            </NewText>
          </CardInformationContent>
        </CardInformationContainer>
      </FaqHolder>
    </Section>
    <Section>
      <InfoCard>
        <InfoCardTopText>
          Si estás en viaje y tenes un voucher de asistencia con nosotros
          comunicate a través de los numeros de emergencia:
        </InfoCardTopText>
        <PhoneHolder>
          <PhoneItem
            onClick={e => {
              window.location.href = 'tel:+598 2901 2044';
              e.preventDefault();
            }}>
            <img src={ImgPhone} alt="Telefono" />
            +598 2901 2044
          </PhoneItem>

          <PhoneItem onClick={pushToWhatsApp}>
            <img src={ImgWhatsApp} alt="WhatsApp" />
            +57 320 3980 618
          </PhoneItem>
        </PhoneHolder>
      </InfoCard>
    </Section>
  </Wrapper>
);

export default TravelInsuranceHome;
