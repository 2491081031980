import React, { useState } from 'react';
import QuotationServices from './QuotationServices';
import { RootState } from 'ReduxConfig/store';
import { useSelector } from 'react-redux';

const selector = ({ quotations }: RootState) => ({
  list: quotations.detail?.serviceQuotations,
  id: quotations.detail?.id
});

const ConnectedQuotationServices = () => {
  const [addingNew, setAddingNew] = useState(false);
  const { list, id } = useSelector(selector);

  const onAddNew = () => setAddingNew(true);
  const onCancelNew = () => setAddingNew(false);
  return (
    <QuotationServices
      list={list}
      addingNew={addingNew}
      onAddNew={onAddNew}
      onCancelNew={onCancelNew}
      id={id}
    />
  );
};

export default ConnectedQuotationServices;
