export interface ILoginPayload {
  login: string;
  password: string;
}

export interface ILoginResponse {
  user: IUser;
  needChangePassword: boolean;
  token: string;
  hours: number;
}

export interface IUser {
  id: number;
  username: string;
  email: string;
  name: string;
  document: number;
  phone: string;
  role: number;
  scope: number;
}

export const corpoAgentRoleId = 6;

export const isCorpo = (user: IUser | null) =>
  user != null && user.role == corpoAgentRoleId;

export interface IChangePasswordPayload {
  currentPassword: string;
  newPassword: string;
}
