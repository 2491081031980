/**
 *
 * PaxDetailAir
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  onChangeAdults,
  onChangeChildren,
  onChangeChildrenAge,
  onToggleChildrenSeat,
  setCabinCode
} from '../../index';
import PaxDetailAir from './PaxDetailAir';
const selector = ({ flights }) => ({
  adults: flights.search.adults,
  kids: flights.search.children,
  cabinCode: flights.search.cabinCode
});

const ConnectedPaxDetailAir = props => {
  const dispatch = useDispatch();

  const actions = {
    onChangeAdults: type => () => {
      dispatch(onChangeAdults(type));
    },
    onChangeChildren: type => () => {
      dispatch(onChangeChildren(type));
    },
    onChangeChildrenAge: index => type => {
      dispatch(onChangeChildrenAge(index)(type));
    },
    onToggleChildrenSeat: index => () => {
      dispatch(onToggleChildrenSeat(index));
    },
    onChangeCabinCode: ({ id }) => {
      dispatch(setCabinCode(id));
    }
  };

  const state = useSelector(selector);

  return <PaxDetailAir {...state} {...props} actions={actions} />;
};

export default ConnectedPaxDetailAir;
export { PaxDetailAir };
