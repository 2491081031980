/**
 *
 * AirSegment
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from 'Components/Text';
import AirLineLogo from '../AirLineLogo';
import Card from 'Components/Card';
import Icon from 'Components/Icon';
import Scales from 'Components/Scales';
import { timeStringWithoutSeconds, isoDateToFullDate } from '../../../../Utils';
import Modal from 'Components/Modal';
import FlightItineraryDetails from '../FlightItineraryDetails';
import { excludeAirlineBaggage } from 'Constants';

const header = (title, date) => (
  <div className={cs('airSegmentHeader')}>
    <Text size="large" context="arena" weight="bold" className="way">
      {title}
    </Text>
    <Text className="date" size="large" context="white">
      {isoDateToFullDate(date)}
    </Text>
  </div>
);

const AirSegment = ({ className, title, data, date }) => {
  const [showDetails, setShowDetails] = useState(false);
  const {
    airLines,
    arrivalData: {
      airport: { code: arrivalCode },
      flightTime: arrivalFlightTime
    },
    baggage,
    departureData: {
      airport: { code: departureCode },
      flightTime: departureFlightTime
    },
    duration: { totalHours = 0, totalMinutes = 0 },
    scales,
    segments
  } = data;
  const duration = `${totalHours}h${totalHours > 1 ? 's' : ''}${
    totalMinutes > 0 ? ` ${totalMinutes}m` : ''
  }`;
  const flightTime = {
    arrival: timeStringWithoutSeconds(arrivalFlightTime),
    departure: timeStringWithoutSeconds(departureFlightTime)
  };
  const getAirLines = () =>
    airLines.map((airLine, i) => (
      <AirLineLogo
        code={airLine.code}
        key={i}
        name={airLine.name}
        showName={airLines.length === 1}
      />
    ));

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const airlineCode = airLines[0].code;

  return (
    <Card
      borderRadius="square"
      className={cs([className, 'cardSegment', 'large'])}
      header={header(title, date)}
      headerShadow
      noUppercase
      horizontalPadding={false}>
      <div className="airLines">
        {getAirLines()}
        <div className={cs('airLinesInfo')} onClick={toggleDetails}>
          {/* TODO Change this icon for the Information one */}
          <Icon name="mv-exlamation-circle" className="icon" />
        </div>
      </div>

      <div className="fligth">
        <div className={cs(['flightBlock', 'time'])}>
          <Text>{departureCode}</Text>
          <Text weight="semi-bold" size="large">
            {flightTime.departure}
          </Text>
        </div>
        <div className="flightBlock">
          <Scales scales={scales} />
        </div>
        <div className={cs(['flightBlock', 'time'])}>
          <Text>{arrivalCode}</Text>
          <Text size="large" weight="semi-bold">
            {flightTime.arrival}
          </Text>
        </div>
        <div className={cs(['flightBlock', 'duration'])}>
          <Text size="small">Duración</Text>
          <Text weight="semi-bold">{duration}</Text>
        </div>
      </div>

      <div className="baggage">
        <div className="baggageItem">
          <div className="iconContainer">
            <Icon
              name="mv-hand-bag"
              className={cs('handBagIcon', {
                disable:
                  !baggage.total && excludeAirlineBaggage.includes(airlineCode)
              })}
            />
          </div>
          <div>
            {!baggage.total && excludeAirlineBaggage.includes(airlineCode) ? (
              <Text weight="bold">No incluye equipaje de mano</Text>
            ) : (
              <Text weight="bold" context="important">
                Incluye equipaje de mano
              </Text>
            )}
          </div>
        </div>
        <div className="baggageItem">
          <div className="iconContainer">
            <Icon
              name="mv-briefcase"
              className={cs('briefcaseIcon', {
                disabled: baggage.total === 0
              })}
            />
          </div>
          <div className="briefcaseText">
            <Text
              context={baggage.total > 0 ? 'important' : undefined}
              weight="bold">
              {baggage.total > 0
                ? 'Incluye equipaje para despachar'
                : 'No incluye equipaje para despachar'}
            </Text>
            {baggage.total > 0 && (
              <Text weight="bold">
                {`${baggage.total} maleta${
                  baggage.total > 1 ? 's' : ''
                } por adulto`}
              </Text>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showDetails}
        onClickOverlay={toggleDetails}
        closeButton={{ onClick: toggleDetails }}
        header="DETALLE DE VUELO"
        headerCentered>
        <FlightItineraryDetails
          baggage={baggage}
          duration={duration}
          scales={scales}
          segments={segments}
        />
      </Modal>
    </Card>
  );
};

AirSegment.propTypes = {
  date: PropTypes.string,
  className: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default styled(AirSegment)`
  display: block;

  .airSegmentHeader {
    display: flex;
    width: 100%;

    .way {
      min-width: 65px;
      margin-right: 10px;
    }

    .date {
      justify-self: center;
    }
  }

  .airLines {
    align-items: center;
    display: grid;
    grid-column-gap: 5%;
    grid-template-columns: 5fr 1fr;
    margin-bottom: 15px;

    .airLinesInfo {
      .icon {
        color: ${COLORS.information};
      }
    }
  }

  .fligth {
    display: grid;
    grid-column-gap: 5%;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    margin-bottom: 15px;

    .flightBlock {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.time {
        line-height: 1;
        text-align: left;
      }

      &.duration {
        line-height: 1;
        white-space: nowrap;

        *:first-child {
          border-bottom: 1px solid;
          margin-bottom: 3px;
          padding-bottom: 3px;
        }
      }
    }
  }

  .baggage {
    background-color: ${COLORS.greyNurse};
    box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;
    padding: 10px !important;
    flex: 1;

    .baggageItem {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: flex-start;

      .iconContainer {
        min-width: 50px;
        display: flex;
        justify-content: center;
      }

      .handBagIcon {
        font-size: 20px;
        color: ${COLORS.important};

        &.disabled {
          color: ${COLORS.darkGrey};
        }
      }

      .briefcaseIcon {
        font-size: 37px;
        color: ${COLORS.important};

        &.disabled {
          color: ${COLORS.darkGrey};
        }
      }

      .briefcaseText {
        display: flex;
        flex-direction: column;
        text-align: left;
      }
    }
  }
`;
