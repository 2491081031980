/**
 *
 * Footer
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cs from 'classnames';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import mundo from './mundoformas.svg';
import globo from './globo.png';
import sombra from './sombra-globo.png';
import mevueloBlanco from './mevuelo-blanco.svg';
import isoTO from './isoTravelOnux.png';
import copyTO from './letrasTravelOnux.png';
import * as COLORS from 'Constants/colors';

import Modal from 'Components/Modal';
import Icon from 'Components/Icon';
import { Link } from '@reach/router';
import { routes, footerCountryData } from 'Constants';
import TermsAndConditions from 'Components/TermsAndConditions';
// import PoliticsAndPrivacy from '../../components/PoliticsAndPrivacy';
import ModalPoliticsAndPrivacy from 'Components/ModalPoliticsAndPrivacy';
import Text from 'Components/Text';
import Select from 'Components/Select';
import visaLogo from '../../../../Assets/images/visaLogo.png';
import masterLogo from '../../../../Assets/images/masterLogo.png';
import amexLogo from '../../../../Assets/images/amexLogo.png';
import pciDss from '../../../../Assets/images/pci.png';
import banksPayment from '../../../../Assets/images/mv-payment-bank.png';
import cardPayment from '../../../../Assets/images/mv-payment-creditcard.png';
import localPayment from '../../../../Assets/images/mv-payment-multi.png';
import redesPayment from '../../../../Assets/images/mv-payment-reddepagos.png';
import whatsAppLogo from '../../../../Assets/images/whatsapp-32.png';

const selector = ({ appConfiguration, router }) => ({
  sites: appConfiguration.sites,
  pathname: router.location.pathname
});

const Footer = ({ className }) => {
  const dispatch = useDispatch();
  const routing = routes;
  const { sites, pathname } = useSelector(selector);
  const sitesDefault = sites.filter(item => item.enable === true);

  const [showPaP, setshowPaP] = useState(false);

  const [showTaC, setShowTaC] = useState(false);

  const go = route => () => {
    dispatch(push(route));
  };

  const data = footerCountryData();

  const onShowPaP = () => {
    // event && event.preventDefault();

    setshowPaP(old => !old);
  };

  const pushToSocialNetwork = network => () => {
    switch (network) {
      case 'facebook':
        window.open(data.facebook);
        break;

      case 'instagram':
        window.open(data.instagram);
        break;

      case 'linkedin':
        window.open(data.linkedin);
        break;

      case 'whatsapp':
        window.open(data.whatsapp);
        break;

      default:
        break;
    }
  };

  const onShowTaC = event => {
    event && event.preventDefault();

    setShowTaC(old => !old);
  };

  {
    /*
  MVFE-926 Prevent of rendering on checkout
  No borrar este test, sin hablar con Seba
  */
  }

  return /checkout/.test(pathname) || /newCheckout/.test(pathname) ? null : (
    <div className={cs(className)}>
      <div className="manchas-bkg"></div>
      <div className="spinning-wolrd" />
      <div className="globo" />
      <div className="sombra" />
      <div className="content">
        <div className="rowFlex">
          {data.phone && (
            <div className="contentBlock">
              <Icon name="mv-phone" color="white" size={20} />
              <div className="bold-text marginTopText">LLAMANOS</div>
              <div>
                <Link
                  className="white-text"
                  to="#"
                  onClick={e => {
                    window.location = `tel:${data.phone}`;
                    e.preventDefault();
                  }}>
                  {data.phone}
                </Link>
              </div>
            </div>
          )}

          <div className="contact-data">
            <div className="contentBlock social">
              <Icon
                color="white"
                size={32}
                name="mv-facebook-square"
                onClick={pushToSocialNetwork('facebook')}
              />
              <Icon
                color="white"
                size={32}
                name="mv-twitter-square"
                onClick={pushToSocialNetwork('linkedin')}
              />
              <Icon
                color="white"
                size={32}
                name="mv-instagram-square"
                onClick={pushToSocialNetwork('instagram')}
              />
              {data.whatsapp && (
                <img
                  src={whatsAppLogo}
                  onClick={pushToSocialNetwork('whatsapp')}
                  className="icon"
                />
              )}
              {/* TODO: Precisamos tener el logo de whatsApp como un icono asi no tenemos que hacerlo asi */}
            </div>

            <Link
              className="bold-text marginTopText"
              to="#"
              onClick={e => {
                window.location = `mailto:${data.mail}`;
                e.preventDefault();
              }}>
              {data.mail}
            </Link>
          </div>

          {data.address && (
            <div className="contentBlock">
              <Icon name="mv-location-2" color="white" size={20} />
              <div className="bold-text marginTopText">VISITANOS</div>
              <div>{data.address}</div>
            </div>
          )}
        </div>

        <div className="lastBox">
          <div className="bold-text">AGENCIA DE VIAJES REGISTRADA:</div>
          <div className="registro">
            <div>{data.sociedad}</div>
            <div
              onClick={() =>
                window.open(data.validationLink, '_blank', 'noopener')
              }>
              {data.qr && <img src={data.qr} alt="qr" className="qr" />}
            </div>
            <div>
              <img
                src={data.imagen}
                alt="habilitacion"
                className="imagenLogo"
              />
            </div>
          </div>
          <div className="paymentMethods">
            <div className="bold-text"> FORMAS DE PAGO:</div>
            <div className="content-box">
              <div className="paymentsItems">
                <img src={banksPayment} alt="transferencia bancaria" />
                <Text context="white" size="small" alignment="center">
                  TRANSFERENCIA BANCARIA
                </Text>
              </div>
              <div className="paymentsItems">
                <img src={cardPayment} alt="tarjetas de credito" />
                <Text context="white" size="small" alignment="center">
                  TARJETAS DE CRÉDITO
                </Text>
              </div>
              <div className="paymentsItems">
                <img src={localPayment} alt="pago en el local" />
                <Text context="white" size="small" alignment="center">
                  PAGO EN EL LOCAL
                </Text>
              </div>
              <div className="paymentsItems">
                <img src={redesPayment} alt="redes de cobranza" />
                <Text context="white" size="small" alignment="center">
                  REDES DE COBRANZA
                </Text>
              </div>
            </div>
          </div>
          <div className="creditCardPayments">
            <div className="bold-text"> TARJETAS DE CRÉDITO:</div>
            <div className="white-box">
              <img src={data.imagenCard} alt="card" className="imagenCard" />
              <img src={visaLogo} alt="visa" className="imagenCard" />
              <img src={masterLogo} alt="mastercard" className="imagenCard" />
              <img
                src={amexLogo}
                alt="american express"
                className="imagenCard"
              />
              <img src={pciDss} alt="pci dss compilant" className="pciLogo" />
            </div>
          </div>
        </div>

        <div className="rowFlex">
          <div className="contentBlock mevueloTeam">
            <div className="bold-text">MEVUELO.COM</div>
            <div onClick={go('sobre-nosotros')}>
              <Link
                to={routing.whoWeAre}
                className="footerLink"
                rel="noopener noreferrer">
                SOBRE NOSOTROS
              </Link>
            </div>
            <div>
              <a
                className="footerLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://talento.mevuelo.com/">
                SUMATE AL EQUIPO
              </a>
            </div>
            <div>
              <a
                className="footerLink"
                target="_blank"
                rel="noopener noreferrer"
                href="/corporativo">
                VIAJES CORPORATIVOS
              </a>
            </div>
            {/* <div>
              <a
                className="footerLink"
                target="_blank"
                rel="noopener noreferrer"
                href="/agarra-vuelo-con-lg">
                AGARRA VUELO CON LG
              </a>
            </div> */}
          </div>

          <div className="contentBlock compraTranquilo">
            <div className="bold-text">COMPRÁ TRANQUILO</div>
            <div onClick={onShowTaC} className="like-link">
              TÉRMINOS Y CONDICIONES
            </div>
            <ModalPoliticsAndPrivacy showPaP={showPaP} onShowPaP={onShowPaP} />
          </div>

          <div className="contentBlock engines">
            <div className="bold-text">LO QUE QUIERAS</div>
            <div>VUELOS</div>
            <div>HOTELES</div>
            <div>PAQUETES</div>
          </div>
        </div>

        {sites && sites.length > 0 && (
          <div className="content-sites">
            <Text context="white" weight="bold" className="title-select">
              SITIO DE:
            </Text>
            <Select
              className="item-inline"
              selectedValue={sitesDefault[0]?.id}
              onSelect={item => window.open(item.id, '_blank', 'noopener')}
              items={sites}
            />
          </div>
        )}

        <div>
          <Text context="white" weight="bold">
            mevuelo.com está presente en:
          </Text>
          <br></br>
          <Text context="white">Uruguay, Paraguay, Bolivia y Colombia</Text>
        </div>

        <div className="rowFlex lastRow">
          <div className="contentBlock">
            <img src={mevueloBlanco} alt="mevuelo" />
          </div>

          <div className="contentBlock">
            <div className="copyright">
              Copyright © {new Date().getFullYear()} Mevuelo.com. Todos los derechos reservados.{' '}
            </div>
          </div>

          <div
            className="contentBlock icon"
            onClick={() =>
              window.open('https://www.travelonux.com/', '_blank', 'noopener')
            }>
            <img src={isoTO} alt="isoTO" />
            <img src={copyTO} alt="copyTO" />
          </div>
        </div>
      </div>
      <Modal
        show={showTaC}
        className="tac-modal"
        closeButton={{ onClick: () => onShowTaC() }}
        header="TÉRMINOS y CONDICIONES"
        onClickOverlay={onShowTaC}
        size="large"
        headerCentered>
        <TermsAndConditions />
      </Modal>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(Footer)`
  ${({ theme }) => css`
    display: 'block';
    min-height: 883px;
    position: relative;
    overflow: hidden;
    background-image: ${COLORS.gradientHomeMulti};
    font-family: Montserrat, serif;
    font-size: 12.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: 0.13px;
    text-align: center;
    color: ${COLORS.white};

    .like-link {
      cursor: pointer;
    }

    .icon {
      cursor: pointer;
    }

    .marginTopText {
      margin-top: ${theme.spacing.four};
    }

    .contact-data {
      display: inline-grid;
    }

    .bold-text {
      font-size: 12.5px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.56;
      letter-spacing: 0.13px;
      color: ${COLORS.white};
      margin-bottom: ${theme.spacing.two};
    }

    .white-text {
      color: ${COLORS.white};
    }

    .content {
      margin-top: 150px;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .lastBox {
        margin-top: ${theme.spacing.six};

        .registro {
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
          justify-content: center;
          padding-bottom: ${theme.spacing.four};

          .imagenLogo {
            margin-left: ${theme.spacing.five};
            height: 43px;
          }

          .qr {
            height: 107px;
            margin: 20px;
          }
        }

        .paymentMethods {
          margin-top: ${theme.spacing.six};
          .content-box {
            display: flex;
            justify-content: center;
            padding-bottom: ${theme.spacing.four};
            padding-top: ${theme.spacing.four};
            flex-wrap: wrap;
            margin-left: ${theme.spacing.two};
            margin-right: ${theme.spacing.two};
            align-items: baseline;
            @media screen and (min-width: 1330px) {
              min-width: 700px;
              justify-content: space-between;
            }
            .paymentsItems {
              display: flex;
              margin-left: ${theme.spacing.two};
              margin-right: ${theme.spacing.two};
              padding-bottom: ${theme.spacing.three};
              font-size: 12px;
              flex-flow: wrap;
              max-width: 120px;
              flex-direction: column;
              align-items: center;
              vertical-align: middle;
            }
          }
        }

        .creditCardPayments {
          margin-top: ${theme.spacing.six};
          padding-bottom: ${theme.spacing.six};
          .white-box {
            margin-top: ${theme.spacing.four};
            border-radius: ${theme.spacing.two};
            display: flex;
            justify-content: space-evenly;
            padding-bottom: ${theme.spacing.one};
            padding-top: ${theme.spacing.one};
            flex-wrap: wrap;
            margin-left: ${theme.spacing.one};
            margin-right: ${theme.spacing.one};
            .imagenCard {
              background: ${COLORS.white};
              border-radius: ${theme.spacing.two};
            }
            .pciLogo {
              height: 45.71px;
            }
          }
        }
      }

      .content-sites {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1 1 auto;
        margin-bottom: ${theme.spacing.ten};

        .mv-select {
          width: 220px;
        }

        span.title-select {
          margin: ${theme.spacing.two};
        }

        @media all and (max-width: 560px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .contentBlock {
      width: 300px;

      .footerLink {
        text-decoration: none;
        color: ${COLORS.white};
      }
    }

    .rowFlex {
      display: flex;
      margin-bottom: ${theme.spacing.eight};
      margin-top: ${theme.spacing.four};

      &.lastRow {
        align-items: flex-end;
      }
    }

    .social {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;
    }

    .spinning-wolrd {
      width: 800px;
      height: 800px;
      background-image: url(${mundo});
      position: absolute;
      top: 100%;
      margin-top: -357px;
      left: 0;
      margin-left: -304px;
      animation-name: infinite-spinning;
      animation-duration: 100s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .sombra {
      width: 66px;
      height: 20px;
      background-image: url(${sombra});
      top: 100%;
      margin-top: -144px;
      left: 137px;
      position: absolute;
      background-size: contain;
      animation-name: footer-shadow-movement;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
    }

    .globo {
      width: 117px;
      height: 153px;
      background-image: url(${globo});
      position: absolute;
      top: 100%;
      margin-top: -286px;
      left: 134px;
      animation-name: baloon-movement;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
    }

    .copyright {
      font-family: Montserrat, serif;
      font-size: 6.5px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: ${COLORS.white};
    }

    @media screen and (max-width: 1240px) {
      min-height: 924px;

      .spinning-wolrd {
        left: 50%;
        margin-left: -400px;
        margin-top: -282px;
        background-color: transparent;
      }

      .globo {
        left: 50%;
        margin-left: -42px;
        animation-name: baloon-movement_centered;
      }

      .sombra {
        animation-name: footer-shadow-movement_centered;
        left: 50%;
        margin-left: -40px;
        margin-top: -106px;
      }

      .rowFlex {
        &.lastRow {
          margin-bottom: 335px;
        }
      }
    }

    @media screen and (max-width: 790px) {
      .content {
        margin-top: 150px;
      }

      .rowFlex {
        flex-direction: column;
        margin-bottom: ${theme.spacing.five};

        &.lastRow {
          margin-bottom: 335px;
        }
      }

      .contentBlock {
        margin: ${theme.spacing.three};

        &.mevueloTeam {
          display: block;

          a:link {
            text-decoration: none;
          }
        }

        &.compraTranquilo {
          display: none;
        }

        &.engines {
          display: none;
        }
      }
    }

    @media screen and (max-width: 450px) {
      .content {
        margin-top: 150px;
        .lastBox {
          .registro {
            .imagenLogo {
              margin-top: ${theme.spacing.three};
              margin-left: 0px;
            }
          }

          .creditCardPayments {
            .white-box {
              margin-left: ${theme.spacing.five};
              margin-right: ${theme.spacing.five};
              .pciLogo {
                margin-top: ${theme.spacing.three};
              }
            }
          }
        }
      }
    }
  `}
`;
