import API from '../../Services';
import actionTypes from './actionTypes';

export const getCategories = () => async dispatch => {
  dispatch({
    type: actionTypes.GET_CATEGORIES_REQUEST,
    payload: { pending: true }
  });

  try {
    const { data } = await API.blog.getAllCategories();
    return dispatch({
      type: actionTypes.GET_CATEGORIES_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_CATEGORIES_FAILURE,
      payload: { pending: false }
    });
    // eslint-disable-next-line no-console
    console.error('Error fetching categories from strapi:', error);
  }
};

export const getSearchArticles = (search: string) => async dispatch => {
  dispatch({
    type: actionTypes.GET_SEARCH_ARTICLE_REQUEST,
    payload: { pending: true }
  });

  try {
    const { data } = await API.blog.searchArticles(search);
    return dispatch({
      type: actionTypes.GET_SEARCH_ARTICLE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_SEARCH_ARTICLE_FAILURE,
      payload: { pending: false }
    });
    // eslint-disable-next-line no-console
    console.error('Error fetching categories from strapi:', error);
  }
};

export const getArticles = (category?: string) => async dispatch => {
  dispatch({
    type: actionTypes.GET_ARTICLES_REQUEST,
    payload: { pending: true }
  });

  try {
    const { data } = await API.blog.getAllArticles(category);
    return dispatch({
      type: actionTypes.GET_ARTICLES_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ARTICLES_FAILURE,
      payload: { pending: false }
    });
    // eslint-disable-next-line no-console
    console.error('Error fetching articles from strapi:', error);
  }
};

export const getLatestArticles = () => async dispatch => {
  dispatch({
    type: actionTypes.GET_LATEST_ARTICLES_REQUEST,
    payload: { pending: true }
  });

  try {
    const { data } = await API.blog.getLatestArticles();
    return dispatch({
      type: actionTypes.GET_LATEST_ARTICLES_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_LATEST_ARTICLES_FAILURE,
      payload: { pending: false }
    });
    // eslint-disable-next-line no-console
    console.error('Error fetching articles from strapi:', error);
  }
};

export const getArticleBySlug = name => async dispatch => {
  dispatch({
    type: actionTypes.GET_ARTICLE_BY_ID_REQUEST,
    payload: { pending: true }
  });

  try {
    const { data } = await API.blog.getArticleBySlug(name);
    return dispatch({
      type: actionTypes.GET_ARTICLE_BY_ID_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ARTICLE_BY_ID_FAILURE,
      payload: { pending: false }
    });
    // eslint-disable-next-line no-console
    console.error('Error fetching one article from strapis:', error);
  }
};

export const cleanArticle = () => ({
  type: actionTypes.CLEAN_ARTICLE
});
