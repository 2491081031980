export const initialFieldState = {
  isHolder: true,
  firstName: '',
  lastName: ''
};

export const getPhoneCode = (fields, localize, phones, phoneTypes) => {
  let code = fields && fields.code ? fields.code.id : localize.phoneCode;

  const filterPhoneCode = phones.filter(phoneCode => phoneCode.id === code);

  if (filterPhoneCode.length) {
    return {
      code: filterPhoneCode[0],
      phoneType: phoneTypes[0]
    };
  }

  return {
    phoneType: phoneTypes[0].id,
    code
  };
};
