// vendor
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//utils
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { handleOnlyDestinationRequest, handleUrlChange } from '../../helpers';
import { hasContentValue } from 'Utils';

// components
import FlightSearchDialog from '../FlightSearchDialog';
import AirAvailability from '../AirAvailability';
import MpcMptbAvailability from '../MpcMptbAvailability';
import SkyLoader from 'Components/SkyLoader';
import BannersContainer from 'Features/banners/components/BannersContainer';
import AirAvailabilityError from '../AirAvailabilityError';
import SearchEngineWrapper from 'Components/SearchEngineWrapper';
import DestinationsTop from 'Features/destinations/components/DestinationsTop';
import SectionBgImages from 'Components/SectionBgImages';
import DestinationsBottom from 'Features/destinations/components/DestinationsBottom';
import MoveDownArrow from '../../../../Components/MoveDownArrow';
import Text from '../../../../Components/Text';
import {PromoBanners} from '../../../../Components/PromoBanners';

// constants
import { statusTypes, whileSearchMessages } from '../../constants';
import { setActive, setCurrentView } from 'Features/destinations';
import Button from 'Components/Button';
import Portal from 'Components/Portal';
import { mostWantedPackagesRequest } from '../../../common/actions';
import { PaymentPromoBanner } from '../../../../Components/PaymentPromoBanner';
import { PackageCommonList } from '../../../packages/components/PackageCommonList';

const getAvailability = e => {
  switch (e) {
    case 'vuelos':
      return <AirAvailability />;
    case 'vuelosMPC':
      return <MpcMptbAvailability />;
    default:
      return null;
  }
};
const getContent = (s, e) => {
  switch (s) {
    case statusTypes.loading:
      return <SkyLoader messages={whileSearchMessages} />;
    case statusTypes.ready:
      return getAvailability(e);
    default:
      return null;
  }
};

const selector = ({ flights, availability, router, destinations, common }) => ({
  status: flights.search.status,
  engine: availability.engine,
  router: router,
  error: flights.availability.error,
  mostWantedPackages: common.mostWantedPackages,
  statusReadyPackages: common.status,
  destinationData: {
    active: destinations.active,
    status: destinations.status,
    imageUrl:
      destinations.data.background && destinations.data.background.publicUrl
  }
});

const FlightsHome = () => {
  const dispatch = useDispatch();
  const {
    engine,
    status,
    router,
    error,
    destinationData,
    mostWantedPackages,
    statusReadyPackages
  } = useSelector(selector);
  const { destination } = useParams();

  const isMediumDown = useBreakpoints().mediumDown;
  const showDestination =
    destinationData.active && destinationData.status === 'ready';

  const unused = status === 'unused';
  useEffect(() => {
    handleUrlChange(router, dispatch);
  }, [router]);

  useEffect(() => {
    hasContentValue(destination) &&
      router.location.pathname.indexOf('vuelos-baratos-internacionales') < 0 &&
      handleOnlyDestinationRequest(destination, dispatch);
  }, [destination]);

  useEffect(() => {
    dispatch(setCurrentView(showDestination));
    return () => {
      dispatch(setCurrentView(showDestination));
    };
  }, [showDestination]);

  useEffect(() => {
    dispatch(mostWantedPackagesRequest());
  }, []);

  const disableDestinations = () => {
    dispatch(setActive(false));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const hasError = !!error && error !== {};

  const getErrorComponent = () =>
    isMediumDown ? (
      <Portal>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 5000
          }}>
          <AirAvailabilityError />
        </div>
      </Portal>
    ) : (
      <AirAvailabilityError />
    );

  return (
    <>
      {showDestination && <SectionBgImages image={destinationData.imageUrl} />}
      {showDestination && <DestinationsTop />}
      <SearchEngineWrapper unused={unused || hasError} isMobile={isMediumDown}>
        {/* {unused && !isMediumDown && (
          <TitleText context="white" weight="semi-bold" size="xlarge">
            &ldquo;Cuando el poder del amor sobrepase el amor al poder, el mundo
            conocerá la paz.&ldquo;
          </TitleText>
        )}
        {unused && !isMediumDown && (
          <SubTitleText context="white" weight="bold" size="medium">
            Jimi Hendrix
          </SubTitleText>
        )} */}
        <FlightSearchDialog />
        {/* {unused && (
          <MoveDownArrow name="mv-arrow-down" size={38} color="white" />
        )} */}
      </SearchEngineWrapper>
      {hasError ? getErrorComponent() : getContent(status, engine)}
      {showDestination && (
        <ShowAllFlightsButton onClick={disableDestinations} />
      )}
      {showDestination && <DestinationsBottom />}

      {unused && <PaymentPromoBanner />}
      {unused && <PromoBanners />}
      {statusReadyPackages === 'ready' &&
        mostWantedPackages.length > 0 &&
        unused && (
          <PackageCommonListContainer>
            <PackageCommonList
              packageList={mostWantedPackages}
              dividerTitle={'Los paquetes más pedidos por nuestros viajeros:'}
            />
          </PackageCommonListContainer>
        )}
      {unused && <BannersContainer />}
    </>
  );
};

// Disabling this, should go away when migrating to typescript
// eslint-disable-next-line react/prop-types
const ShowAllFlightsButton = ({ onClick }) => (
  <Container>
    <Button fullWidth onClick={onClick}>
      Ver Más
    </Button>
  </Container>
);

const Container = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: auto;
  button {
    margin: 0;
  }
`;

const TitleText = styled(Text)`
  display: block;
  position: absolute;
  top: 30%;
`;
const SubTitleText = styled(Text)`
  display: block;
  position: absolute;
  top: 35%;
`;

const PackageCommonListContainer = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    margin-top: 28px;
`;

export default FlightsHome;
