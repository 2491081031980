export default function(axiosInstance) {
  const axios = axiosInstance;

  function get(url) {
    return axios.get(url);
  }

  function post(url, payload) {
    return axios.post(url, payload);
  }

  function put(url, payload) {
    return axios.put(url, payload);
  }

  return {
    get,
    post,
    put
  };
}
