/**
 *
 * HolderForm
 *
 */

// Vendors
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import get from 'lodash/get';

// Components
import Checkbox from 'Components/Checkbox';
import TextArea from 'Components/TextArea';
import { HolderFields } from './HolderFields';
import Radio from 'Components/Radio';

// Constants
import { greyBkg } from 'Constants/colors';

const HolderForm = ({ className, userData, actions, rooms, onValid }) => {
  const [extraFields, setExtraFields] = useState({
    addons: '',
    iAgree: false,
    purchaseHolder: 0
  });

  const holder = get(userData.holder, 'holders', []);
  onValid(holder.every(holder => holder.isValid) && extraFields.iAgree);

  const handleRoomSubmit = (fields, idx, valid) => {
    holder[idx] = {
      ...fields,
      isHolder: extraFields.purchaseHolder === idx,
      isValid: valid
    };

    actions.setUserData({
      holder: {
        ...userData.holder,
        holders: holder
      }
    });

    onValid(holder.every(holder => holder.isValid && extraFields.iAgree));
  };

  const handleChange = field => value => {
    setExtraFields(old => ({
      ...old,
      [field]: value
    }));
  };

  const handleSelect = field => () => {
    setExtraFields(old => ({
      ...old,
      [field]: !old[field]
    }));
  };

  useEffect(() => {
    actions.setUserData({
      holder: {
        ...userData.holder,
        holders: userData.holder.holders.map((holder, index) => ({
          ...holder,
          isHolder: index === extraFields.purchaseHolder
        })),
        addons: extraFields.addons
      }
    });
  }, [extraFields.purchaseHolder]);

  useEffect(() => {
    actions.setUserData({
      holder: {
        ...userData.holder,
        addons: extraFields.addons
      }
    });
  }, [extraFields.addons]);

  const roomsToMap = [];

  rooms.forEach(room => {
    for (let index = 0; index < room.occupancy.rooms; index++) {
      roomsToMap.push(room);
    }
  });

  const handleRadioCheck = index =>
    setExtraFields(prev => ({ ...prev, purchaseHolder: index }));

  return (
    <div className={cs(className)}>
      {roomsToMap.map((r, idx) => (
        <>
          <HolderFields
            key={`${r.id}${idx}`}
            roomIndex={idx}
            title={r.roomName}
            rateKey={r.rateKey}
            onSubmit={handleRoomSubmit}
            initialData={holder[idx]}
          />
          <Radio
            className="radio"
            checked={extraFields.purchaseHolder === idx}
            onClick={() => handleRadioCheck(idx)}>
            Titular de Reserva y Facturación
          </Radio>
        </>
      ))}
      <div className="separator" />
      <div className="fields">
        <TextArea
          className="grid-4"
          placeholder="Pedidos adicionales al Hotel"
          label="Pedidos adicionales al Hotel"
          value={extraFields.addons}
          onChange={handleChange('addons')}
        />
      </div>
      <Checkbox
        className="grid-4"
        checked={extraFields.iAgree}
        onClick={handleSelect('iAgree')}>
        He leído y acepto los{' '}
        <a href="#" onClick={actions.toggleTacModal}>
          términos y condiciones.
        </a>
      </Checkbox>
    </div>
  );
};

HolderForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onClickTaC: PropTypes.func,
  phoneTypes: PropTypes.array,
  userData: PropTypes.object,
  places: PropTypes.object,
  localize: PropTypes.object,
  actions: PropTypes.object,
  onValid: PropTypes.func,
  rooms: PropTypes.array
};

export default styled(HolderForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
  }

  .radio {
    align-self: flex-end;
  }

  .fields {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px 12px;
    margin-bottom: 16px;
    width: 100%;

    .grid-1 {
      grid-column: 1 span;
    }

    .grid-2 {
      grid-column: 2 span;
    }

    .grid-3 {
      grid-column: 3 span;
    }

    .grid-4 {
      grid-column: 4 span;
    }

    .right {
      grid-column: 4;
      width: 200px;
    }

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;

      > div {
        margin-bottom: 12px;
      }
    }
  }

  .separator {
    background-color: ${greyBkg};
    height: 12px;
    width: calc(100% + 24px);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .submit {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
