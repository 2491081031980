import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from '../Text';
import * as COLORS from 'Constants/colors';

import b2 from '../../Assets/backgrounds/b1.webp';
import b3 from '../../Assets/backgrounds/b2.webp';
import b4 from '../../Assets/backgrounds/b3.webp';
import b5 from '../../Assets/backgrounds/b4.webp';
import b6 from '../../Assets/backgrounds/b5.webp';

const WhoWeAre = ({ className }) => {
  useEffect(() => {
    // TODO: it does not work without the timeout ¯\_(ツ)_/¯
    setTimeout(() => window.scrollTo(0, 0), 500);
  }, []);

  return (
    <div className={cs(className)}>
      <div className="whoweare-containter">
        <div className="whoweare-header">
          <div className="container">
            <Text className="whoweare-header-header" context="white" paragraph>
              EL MUNDO NECESITA VIAJEROS
            </Text>
            <Text
              className="whoweare-header-body"
              size="large"
              context="white"
              paragraph>
              Somos viajeros, creemos que viajar nos acerca. Sabemos que
              compartiendo conocimientos y experiencias nos ayudamos unos a
              otros a explorar el mundo, de nuestra propia manera. Dar y recibir
              un tip, recomendar y descubrir un lugar, como sea, viajar es un
              ciclo que siempre suma y nunca resta.
            </Text>
            <Text
              className="whoweare-header-footer"
              size="large"
              context="white"
              paragraph>
              Viajamos en Globo, sin apuros, para dar la vuelta completa.
            </Text>
          </div>
        </div>

        <div className="container">
          <div className="whoweare-body">
            <div className="text">
              <Text className="header" paragraph>
                NO SOMOS LOS MÁS GRANDES,POR ESO ESTAMOS MÁS CERCA
              </Text>
              <Text className="p1" paragraph>
                Somos una Agencia de Viajes Omnicanal, pensada por viajeros como
                vos. Sabemos que el proceso de compra de un viaje puede ser un
                viaje en sí mismo, muchas veces repleto de indecisiones. Ya nos
                vimos desorientados ante tantas opciones, o perjudicados por
                haber elegido mal nuestros pasos a seguir, o saturados ante un
                mundo tan grande y tan lleno de oportunidades que nos hacía
                difícil la tarea de saber elegir. Es entonces cuando entendimos
                lo que nos estaba haciendo falta: un consejo, una experiencia
                compartida, alguien que se acercara y nos brindara sus
                conocimientos para nosotros poder escribir nuestra mejor
                historia.
              </Text>
              <Text paragraph>
                Queremos que sientas compañía en el diagramado de tu viaje.
              </Text>
              <Text paragraph>
                Queremos que sepas que a través de nuestro sitio web, nuestros
                canales telefónicos tradicionales y online, los chats en vivo y
                nuestras oficinas de atención presencial; nos encontrás cerca
                para acompañarte en el momento y de la forma que te resulte más
                cómoda.
              </Text>
              <Text paragraph>
                Queremos que sepas que estamos disponibles para hacer por vos la
                búsqueda y la investigación de tu viaje.
              </Text>
              <Text paragraph>
                Además de consejos podemos acercarte Hoteles baratos y ofertas,
                Vuelos baratos Internacionales, promociones en Paquetes
                Vacacionales, así como Seguros y Actividades y Servicios de cada
                destino al que vayas.
              </Text>

              <Text className="p2" paragraph>
                Basta que digas que tenés ganas y nuestro Globo va por vos.
              </Text>
            </div>
            <div className="image"></div>
          </div>
        </div>
        <div className="container">
          <div className="whoweare-footer">
            <li>
              <Text className="header" context="white" paragraph>
                ¿Por qué lo hacemos?
              </Text>
              <div className="whoweare-footer-block">
                <div className="top" style={{ backgroundImage: `url(${b3})` }}>
                  <Text
                    className="top-text"
                    context="white"
                    weight="bold"
                    paragraph>
                    Porque somos del mundo y nos esta esperando
                  </Text>
                </div>
                <div className="bottom">
                  <Text className="text" paragraph>
                    Porque creemos que el mundo está hecho para descubrirlo.
                    Porque sabemos que todos nos merecemos viajar. Porque el
                    tiempo y la distancia cada vez nos ceden más oportunidades
                    para salir a explorar.
                  </Text>
                </div>
              </div>
            </li>
            <li>
              <Text className="header" context="white" paragraph>
                Tenés ganas
              </Text>
              <div className="whoweare-footer-block">
                <div className="top" style={{ backgroundImage: `url(${b6})` }}>
                  <Text
                    className="top-text"
                    context="white"
                    weight="bold"
                    paragraph>
                    El mundo es un Globo y para conocerlo, hay que animarse a
                    volar.
                  </Text>
                </div>
                <div className="bottom">
                  <Text className="text" paragraph>
                    Creemos que todos tenemos ganas de viajar, porque es durante
                    un viaje cuando más nos encontramos con nosotros mismos. Aún
                    cuando elijamos diferentes destinos, para todos será igual
                    la plenitud de viajar.
                  </Text>
                </div>
              </div>
            </li>
            <li>
              <Text className="header" context="white" paragraph>
                Nuestros colores
              </Text>
              <div className="whoweare-footer-block">
                <div className="top" style={{ backgroundImage: `url(${b2})` }}>
                  <Text
                    className="top-text"
                    context="white"
                    weight="bold"
                    paragraph>
                    Existen tantas formas de viajar como personas hay en el
                    mundo.
                  </Text>
                </div>
                <div className="bottom">
                  <Text className="text" paragraph>
                    El mundo es como una gran pintura, bañado de distintos
                    colores. Nuestro objetivo es agrupar esos colores bajo una
                    misma bandera y que el viaje nos muestre la diversidad que
                    hay: gente, historias, culturas, experiencias, paisajes y
                    sabores.
                  </Text>
                </div>
              </div>
            </li>
          </div>
        </div>
        <Text className="footer-text-sm" context="white" paragraph>
          En nuestro Globo, para todos hay un lugar.
        </Text>
        <Text className="footer-text-lg" context="white" paragraph>
          ¿Te subís?
        </Text>
      </div>
    </div>
  );
};

WhoWeAre.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(WhoWeAre)`
  .whoweare-containter {
    background-image: url(${b5});
    background-position: center -1%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    .footer-text-sm {
      font-size: 24px;
      margin-top: 20px;
      text-align: center;
      font-weight: 600;
    }

    .footer-text-lg {
      font-size: 48px;
      margin-top: 30px;
      text-align: center;
      font-weight: 600;
    }
  }
  .whoweare-header {
    height: 380px;
    margin-top: 32px;
    width: 100%;
    color: ${COLORS.white};

    @media (max-width: 576px) {
      .footer,
      .body {
        width: 100%;
      }
      .header,
      .body {
        position: relative;
      }

      background-size: cover;
    }
  }

  .whoweare-header-header {
    font-size: 24px;
    font-weight: 600;
    margin-top: 180px;
    position: relative;

    @media (max-width: 765px) {
      margin-top: 90px;
      position: relative;
    }
  }

  .whoweare-header-body {
    position: relative;
    margin-top: 10px;

    @media (max-width: 765px) {
      font-size: 14px;
      position: relative;
      width: 100%;
    }
  }

  .whoweare-header-footer {
    position: relative;
    margin-top: 20px;

    @media (max-width: 576px) {
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 20px;
      position: relative;
      width: 90%;
    }
  }
  .header {
    margin-top: 50px;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

  .whoweare-body {
    border-radius: 50px;
    margin-top: 30px;
    /* stylelint-disable */
    -ms-box-orient: horizontal;
    /* stylelint-enable */
    display: flex;
    background-color: ${COLORS.white};

    .text {
      margin: 20px;
      width: 50%;

      .header {
        font-size: 24px;
        color: #171f4b;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .p1 {
        margin-bottom: 10px;
      }

      .p2 {
        margin-top: 10px;
      }
    }

    .image {
      width: 50%;
      background-image: url(${b4});
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }

    @media (max-width: 576px) {
      flex-direction: column-reverse;
      width: 95%;
      margin: 0 auto;
      margin-top: 32px;

      .text {
        width: auto;
        margin-left: 20px;
        margin-right: 20px;

        .header {
          font-size: 18px;
        }

        .p1,
        .p2 {
          font-size: 14px;
        }
      }
      .image {
        width: 100%;
        height: 250px;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 0;
      }
    }
  }

  .whoweare-footer {
    display: flex;
    margin-top: 60px;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;

    @media (max-width: 576px) {
      flex-direction: column;
      width: 95%;
      margin: 0 auto;
    }

    .whoweare-footer-block {
      border-radius: 25px;
      width: 300px;
      height: 524px;
      background-color: ${COLORS.white};
      display: flex;
      flex-direction: column;

      .top {
        height: 338px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;

        .top-text {
          margin-top: 170px;
          margin-left: 20px;
          width: 139px;
          font-size: 18px;
        }
      }

      .bottom {
        height: 152px;
        .text {
          margin: 20px;
        }
      }

      @media (max-width: 576px) {
        width: 100%;
        height: 530px;
        .bottom {
          height: 180px;
        }
      }
    }
  }
`;
