/**
 *
 * SkyLoader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Slider from 'react-slick';

import Text from '../Text';

import nubeCerca from './assets/nubecerca.svg';
import nubeMedio from './assets/nubemedio.svg';
import nubeLejos from './assets/nubelejos.svg';
import globo from './assets/globo.svg';
import spinner from './assets/spinner.svg';
import logo from '../../Assets/logo_normal.svg';

import SkyBackground from '../SkyBackground';

import './animations.css';
const skyLoaderSliderSettings = {
  dots: false,
  autoplay: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const SkyLoader = ({ className, messages = [] }) => (
  <div className={cs(className, 'animated fadeIn')}>
    <SkyBackground />
    <div className="logoHolder container">
      <img alt="logo" className="logo" src={logo} />
    </div>

    <div className="holderNubeLejos">
      <img className="nubeLejos" alt="nube lejos :)" src={nubeLejos} />
      <img className="nubeLejos" alt="nube lejos :)" src={nubeLejos} />
      <img className="nubeLejos" alt="nube lejos :)" src={nubeLejos} />
      <img className="nubeLejos" alt="nube lejos :)" src={nubeLejos} />
    </div>
    <div className="holderNubeMedio">
      <img className="nubeMedio" alt="nube medio :V" src={nubeMedio} />
      <img className="nubeMedio" alt="nube medio :V" src={nubeMedio} />
      <img className="nubeMedio" alt="nube medio :V" src={nubeMedio} />
      <img className="nubeMedio" alt="nube medio :V" src={nubeMedio} />
    </div>
    <div className="globoHolder">
      <div className="globo">
        <img alt="nube medio (^o^) " src={globo} />
      </div>
    </div>

    <div className="holderNubesCerca">
      <img className="nubeCerca" alt="nube cerca ¯\_(ツ)_/¯" src={nubeCerca} />
      <img className="nubeCerca" alt="nube cerca ¯\_(ツ)_/¯" src={nubeCerca} />
      <img className="nubeCerca" alt="nube cerca ¯\_(ツ)_/¯" src={nubeCerca} />
      <img className="nubeCerca" alt="nube cerca ¯\_(ツ)_/¯" src={nubeCerca} />
    </div>

    <div className="spinnerHolder">
      <img alt="spinner" src={spinner} />
    </div>

    <div className="sliderHolder">
      <Slider {...skyLoaderSliderSettings}>
        {messages.map((message, index) => (
          <Text key={index} context="white" size="xlarge">
            {message}
          </Text>
        ))}
      </Slider>
    </div>
  </div>
);

SkyLoader.propTypes = {
  className: PropTypes.string.isRequired,
  messages: PropTypes.array
};

export default styled(SkyLoader)`
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 999999999;

  .logoHolder {
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
    position: relative;

    .logo {
      width: 227px;
      position: relative;
    }

    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .spinnerHolder {
    width: 64px;
    height: 64px;
    position: absolute;
    left: calc(50% - 32px);
    top: calc(50% - 100px);
  }

  .sliderHolder {
    position: absolute;
    text-align: center;
    width: 500px;
    left: calc(50% - 250px);
    top: calc(50% - 170px);
  }

  .globoHolder {
    width: 300px;
    height: 300px;
    position: absolute;
    bottom: 150px;
    left: calc(50% - 150px);
    animation-name: infinite-spinning;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .globo {
    width: 140px;
    left: 0;
    bottom: 0;
    position: absolute;
    animation-name: infinite-spinning-reverse;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .holderNubeLejos {
    bottom: 0;
    left: 0;
    margin: 0;
    position: absolute;
    display: flex;
    animation-name: nubes-lejos-anim;
    animation-duration: 180s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .nubeLejos {
    height: 264px;
    width: 945px;
  }

  .holderNubeMedio {
    bottom: 0;
    left: 0;
    position: absolute;
    display: flex;
    animation-name: nubes-lejos-anim;
    animation-duration: 90s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .nubeMedio {
    width: 955px;
    height: 255px;
  }

  .holderNubesCerca {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    animation-name: nubes-lejos-anim;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .nubeCerca {
    width: 960px;
    height: 274px;
  }
`;
