// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Passengers from './Passengers';

// Selector
import { selector } from './selector';
import { storageUserData } from 'ReduxConfig/checkout';

const ConnectedPassengers = props => {
  const state = useSelector(selector);
  const dispatch = useDispatch();
  const actions = {
    setUserData: data => dispatch(storageUserData(data))
  };

  return <Passengers actions={actions} {...props} {...state} />;
};

export { Passengers };
export default ConnectedPassengers;
