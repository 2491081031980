/**
 *
 * MobileHotelDestinationCard
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import MobileHotelDestinationSearch from '../MobileHotelDestinationSearch';
import Portal from 'Components/Portal';

const selector = ({ hotels }) => ({
  location: hotels.search.location
});

const ConnectedMobileHotelDestinationCard = props => {
  const state = useSelector(selector);
  return <StyledMobileHotelDestinationCard {...state} {...props} />;
};

const MobileHotelDestinationCard = ({ className, location }) => {
  const [openModal, setOpenModal] = useState();

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    document.body.classList.toggle('non-scroll', openModal);
  }, [openModal]);

  return (
    <div className={cs(className)}>
      <div className="container" onClick={toggleModal}>
        <div className="row">
          <Icon name="mv-location" size={32} className="icon" />
          <Text size="xlarge" weight="semi-bold">
            {location.description
              ? location.description.split(',')[0]
              : 'DESTINO'}
          </Text>
        </div>
        {location.description ? (
          <Text context="important">{location.description.split(',')[1]}</Text>
        ) : (
          <Text weight="italic">Ingresá una ciudad o alojamiento</Text>
        )}
      </div>
      {openModal && (
        <Portal>
          <MobileHotelDestinationSearch onClose={toggleModal} />
        </Portal>
      )}
    </div>
  );
};

MobileHotelDestinationCard.propTypes = {
  className: PropTypes.string.isRequired,
  location: PropTypes.shape({
    placeId: PropTypes.string,
    description: PropTypes.string
  })
};

const StyledMobileHotelDestinationCard = styled(MobileHotelDestinationCard)`
  display: flex;
  background-color: ${COLORS.white};

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.25);
    padding: 10px;

    .row {
      display: flex;
      align-items: center;
    }

    .icon {
      color: ${COLORS.important};
      margin-right: 5px;

      &::before {
        vertical-align: -15%;
      }
    }
  }
`;

export default ConnectedMobileHotelDestinationCard;
export { StyledMobileHotelDestinationCard as MobileHotelDestinationCard };
