import React, { useEffect } from 'react';
import QuotationDetails, { Id } from './QuotationDetails';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getQuotation } from 'Features/quotations/actions';
import { RootState } from 'ReduxConfig/store';

interface QuotationParams {
  id?: string;
}

const selector = ({ quotations }: RootState) => ({
  loading: quotations.pending
});

const ConnectedQuotationDetails = () => {
  const { loading } = useSelector(selector);
  const { id } = useParams<QuotationParams>();
  const dispatch = useDispatch();
  let workingId: Id;
  if (id === 'new') {
    workingId = 'new';
  } else {
    workingId = Number(id);
  }

  useEffect(() => {
    if (typeof workingId === 'number') {
      dispatch(getQuotation(workingId));
    }
  }, [workingId, dispatch]);

  return <QuotationDetails id={workingId} loading={loading} />;
};

export default ConnectedQuotationDetails;
