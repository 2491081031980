import styled from 'styled-components';

export const TextsHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormHolder = styled.div`
  display: block;
  padding: ${props => props.theme.spacing.two}
    ${props => props.theme.spacing.four};
`;

export const InputHolder = styled.div`
  display: block;
  flex: 1;
  margin: ${props => props.theme.spacing.two};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const InputsLine = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${props => props.theme.spacing.four};
`;

export const WarningHolder = styled.div<{ mobile?: boolean }>`
  background-color: ${props => props.theme.colors.important};
  border-radius: ${props =>
    props.mobile ? 0 : props.theme.borderRadius.smaller};
  display: flex;
  padding: ${props => props.theme.spacing.two}
    ${props => props.theme.spacing.four};
`;

export const WarningIconHolder = styled.div`
  margin-right: ${props => props.theme.spacing.four};
`;

export const MobileBottomNav = styled.div`
  height: 86px;
  background-color: ${props => props.theme.colors.white};
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: ${props => props.theme.shadows.three};
  z-index: 6;
`;
