// Vendors
import React from 'react';
import valid from 'card-validator';
import NumberFormat from 'react-number-format';

// Constants
import {
  mesesDelAno,
  diasDeLaSemana,
  scaleTypes,
  baggageTypes,
  routes,
  engines,
  STRAPI_IMAGE_SERVER,
  ASSETS_URL,
  LANG_COUNTRY,
  keywordsWithDestinationData
} from 'Constants';
import { classItems } from '../Constants/mocks';

export const textTruncate = (text, size) =>
  text && text.length > size ? text.substr(0, size - 1) + '...' : text;

export const getHeight = (ref = null) => {
  const targetElement = ref ? ref.current : window;
  const isClient = typeof targetElement === 'object';
  const windowHeight = isClient ? targetElement.innerHeight : undefined;
  return windowHeight;
};

export const isEmpty = value => !Object.keys(value).length;

export const getScrollPos = () => {
  const body = document.body; //IE 'quirks'
  const documentDoc = document.documentElement; //IE with doctype
  const reference = documentDoc.clientHeight ? documentDoc : body;
  return reference.scrollTop;
};

export const returnImage = (jpg, webp) =>
  isMobile.iOS() || isSafari ? jpg : webp;

export const isMobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iphone4/i),
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () =>
    navigator.userAgent.match(/IEMobile/i) ||
    navigator.userAgent.match(/WPDesktop/i),
  any: () =>
    isMobile.Android() ||
    isMobile.BlackBerry() ||
    isMobile.iOS() ||
    isMobile.Opera() ||
    isMobile.Windows()
};

export const isSafari =
  navigator.userAgent.toLowerCase().indexOf('safari') != -1 &&
  navigator.userAgent.toLowerCase().indexOf('chrome') <= -1;

export const hexToRgb = (hex, alpha = '0.7') => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgbObj = {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
  return `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, ${alpha})`;
};

export const getAirLineLogo = airlineCode =>
  `${ASSETS_URL}assets/airlines/${airlineCode}.png`;

export const isoDateToString = date => {
  if (date) {
    const arrayDate = date.split('-');
    const objDate = new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]);
    return `${objDate.getDate()} ${
      mesesDelAno[objDate.getMonth()]
    } ${objDate.getFullYear()}`;
  }
  return null;
};

export const isoDateToFullDate = date => {
  if (date) {
    const arrayDate = date.split('-');
    const objDate = new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]);

    return `${diasDeLaSemana[objDate.getDay()]} ${objDate.getDate()} de ${
      mesesDelAno[objDate.getMonth()]
    }`;
  }
  return null;
};

export const timeStringWithoutSeconds = timeString => {
  const returnTime = timeString.split(':');
  return `${returnTime[0]}:${returnTime[1]}`;
};

export const dayInWeek = date => {
  switch (date.getDay()) {
    case 0:
      return 'dom';
    case 1:
      return 'lun';
    case 2:
      return 'mar';
    case 3:
      return 'mier';
    case 4:
      return 'jue';
    case 5:
      return 'vie';
    case 6:
      return 'sab';
    default:
  }
};

export const month = date => {
  const months = [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic'
  ];
  return months[date.getMonth()];
};

export const getStrapiImage = imageUrl =>
  imageUrl ? `${STRAPI_IMAGE_SERVER}${imageUrl}` : null;

//TODO: esto deberia ser un helper de packages, solo se usa ahi
export const getFormatMoney = (prefix, value) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    decimalSeparator={','}
    thousandSeparator={'.'}
    prefix={`${prefix} `}
  />
);

export const getScaleLabel = type => {
  switch (type) {
    case scaleTypes.all:
      return 'Todas las opciones';
    case scaleTypes.maxDirect:
      return 'Vuelos directos';
    case scaleTypes.maxOne:
      return 'Máximo una escala';
    default:
      return null;
  }
};

export const getBaggageLabel = type => {
  switch (type) {
    case baggageTypes.all:
      return 'Todas las opciones';
    case baggageTypes.maxCargo:
      return 'Con equipaje en Bodega';
    case baggageTypes.maxHand:
      return 'Solo equipaje de mano';
    default:
      return null;
  }
};

export const getCabinName = cabinCode => {
  const cabinItem = classItems.filter(({ id }) => id === cabinCode)[0];
  return cabinItem ? cabinItem.name : cabinCode;
};

export const getPassengerType = (passengerCode, totalPassengers = 1) => {
  switch (passengerCode) {
    case 'CNN':
      return totalPassengers > 1 ? 'Niños' : 'Niño';

    case 'INF':
      return totalPassengers > 1 ? 'Bebés' : 'Bebé';

    case 'INS':
      return totalPassengers > 1 ? 'Bebés con asiento' : 'Bebé con asiento';

    default:
      return totalPassengers > 1 ? 'Adultos' : 'Adulto';
  }
};
export const getLastFour = number => number.slice(number.length - 4);

export const getEngine = routeName => {
  if (
    routeName.includes('checkout') ||
    routeName.includes('login') ||
    routeName.includes('reset-password')
  ) {
    return routeName;
  }

  if (routeName.includes('blog') || routeName.includes('article')) {
    return engines.BLOG.name;
  }

  const routesByEngine = {
    HOME: removeCharacter(routes.home, '/'),
    FLIGHT_HOME_ONE_WAY: removeCharacter(routes.flight, '/'),
    FLIGHT_HOME_ROUND_TRIP: removeCharacter(routes.flight, '/'),
    FLIGHT_HOME_MULTIDESTINATION: removeCharacter(
      routes.flightMultiDestination,
      '/'
    ),
    HOTEL_HOME: removeCharacter(routes.hotel, '/'),
    PACKAGE_HOME: removeCharacter(routes.package, '/'),
    QUOTATIONS: removeCharacter(routes.quotations, '/'),
    TRAVELINSURANCE: removeCharacter(routes.travelInsurance, '/'),
    CORPORATE: removeCharacter(routes.corporate, '/')
  };

  if (routeName.includes(routesByEngine.HOTEL_HOME)) {
    return engines.HOTELS.name;
  }

  if (routeName.includes(routesByEngine.PACKAGE_HOME)) {
    return engines.PACKAGES.name;
  }

  if (routeName.includes(routesByEngine.QUOTATIONS)) {
    return engines.QUOTATIONS.name;
  }

  // TODO: hacerlo todo con if, pq si viene destination va a ser un problema

  switch (routeName) {
    case routesByEngine.HOME:
      return engines.FLIGHTS.name;
    case routesByEngine.FLIGHT_HOME_ONE_WAY:
      return engines.FLIGHTS.name;
    case routesByEngine.FLIGHT_HOME_ROUND_TRIP:
      return engines.FLIGHTS.name;
    case routesByEngine.FLIGHT_HOME_MULTIDESTINATION:
      return engines.FLIGHTS.name;
    case routesByEngine.HOTEL_HOME:
      return engines.HOTELS.name;
    case routesByEngine.PACKAGE_HOME:
      return engines.PACKAGES.name;
    case routesByEngine.QUOTATIONS:
      return engines.QUOTATIONS.name;
    case routesByEngine.TRAVELINSURANCE:
      return engines.TRAVELINSURANCE.name;
    case routesByEngine.CORPORATE:
      return engines.CORPORATE.name;

    default:
      return engines.FLIGHTS.name;
  }
};

export const removeCharacter = (content, character) =>
  content.replace(character, '');

export const scrollIntoView = ref =>
  ref &&
  ref.current &&
  ref.current.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  });

export const getFormatPrice = (price, currency, isoLangIsoCountry) => {
  const coinCurrency = currency ? currency : 'USD';

  if (isNaN(price)) {
    throw 'El parámetro price no es un número!';
  }

  return isoLangIsoCountry && price
    ? Intl.NumberFormat(LANG_COUNTRY, {
      style: 'currency',
      currency: coinCurrency,
      minimumFractionDigits: 0
    }).format(price)
    : `${coinCurrency} ${price
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
};

export const existInLocalize = (localize, attribute) =>
  // eslint-disable-next-line
  localize && localize.hasOwnProperty(attribute) && localize[attribute];

export const getPhonesCodes = places => {
  const listPhoneCodes =
    places && places.countries
      ? places.countries.map(country => ({
        id: `${country.phoneCode}`,
        name: `${country.name} ${country.phoneCode}`
      }))
      : [];
  return listPhoneCodes;
};

export const getCountriesCodes = places => {
  const listPhoneCodes =
    places && places.countries
      ? places.countries.map(country => ({
        id: `${country.code}`,
        name: `${country.name}`
      }))
      : [];
  return listPhoneCodes;
};

export const toCreditCardFormat = value => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || '';
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  } else {
    return value;
  }
};

export const formatPaxDetails = (adultQty, childQty, cabinCode) => {
  /*
    adultos: todos los adutlos, mas los ninos que tenga 12 o mas anos
    totalChildren: de 11 hasta hasta 2
    totalInfant: 1 ano o menos  menor sin asiento
    totalInfantWithSeat: menores de 2 sin asiento que le pusieron que quieren asiento
    */
  const outPut = {
    totalAdult: adultQty + childQty.filter(child => child.age > 11).length,
    totalChildren: childQty.filter(child => child.age < 12 && child.age > 1)
      .length,
    totalInfant: childQty.filter(child => child.age < 2 && !child.seat).length,
    totalInfantWithSeat: childQty.filter(child => child.age < 2 && child.seat)
      .length,
    cabinCode: cabinCode
  };
  return outPut;
};

export const generateCustomCards = () => {
  // TODO: MAKE THIS FUNCTION MORE REUSABLE

  valid.creditCardType.addCard({
    niceType: 'OCA',
    type: 'oca',
    patterns: [491385, 542991, 549576, 549530, 549564, 549571, 589892],
    gaps: [4, 8, 12],
    lengths: [16],
    code: {
      name: 'CVV',
      size: 3
    }
  });
  /* valid.creditCardType.addCard({
    card: {
      niceType: 'American Express',
      type: 'american-express',
      patterns: [379194],
      gaps: [4, 10],
      lengths: [15],
      code: {name: 'CID', size: 4}
    },
    isPotentiallyValid: true, // if false, indicates there is no way the card could be valid
    isValid: true // if true, number is valid for submission
  }) */
};

export const hasContentValue = keyword =>
  keywordsWithDestinationData.includes(keyword);

export const firstLetterLowerCase = string =>
  string[0].toLowerCase() + string.slice(1);
