/**
 *
 * DestinationFlight
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDestination,
  setOrigin,
  setCityKeyword
} from '../../Features/flights';

import DestinationFlight from './DestinationFlight';

const destinationSelector = index => ({ flights }) => ({
  location: flights.search.routesData[index].destination,
  locationName: flights.search.routesData[index].destinationName,
  messages: flights.search.validations.routesData[index].destination
});

const originSelector = index => ({ flights }) => ({
  locationName: flights.search.routesData[index].originName,
  messages: flights.search.validations.routesData[index].origin
});

export const locationTypes = {
  DESTINATION: 'DESTINATION',
  ORIGIN: 'ORIGIN'
};

const getValues = type => {
  switch (type) {
    case locationTypes.DESTINATION:
      return {
        action: setDestination,
        selector: destinationSelector,
        label: 'Destino',
        placeHolder: '¿A dónde viajas?'
      };

    case locationTypes.ORIGIN:
      return {
        action: setOrigin,
        selector: originSelector,
        label: 'Origen',
        placeHolder: '¿Desde donde viajas?'
      };
    default:
      break;
  }
};

const ConnectedDestinationFlight = ({ index = 0, type, ...props }) => {
  const dispatch = useDispatch();

  const { action, selector, label, placeHolder } = getValues(type);

  const actions = {
    setLocation: (
      location: string,
      locationName: string,
      cityKeyword: string
    ) => {
      dispatch(action(index, location, locationName));
      if (cityKeyword) dispatch(setCityKeyword(cityKeyword));
    }
  };

  const state = useSelector(selector(index));

  return (
    <DestinationFlight
      actions={actions}
      {...state}
      {...props}
      label={label}
      placeHolder={placeHolder}
    />
  );
};

ConnectedDestinationFlight.propTypes = {
  index: PropTypes.number,
  type: PropTypes.oneOf(Object.keys(locationTypes))
};

export default ConnectedDestinationFlight;
