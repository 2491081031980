/**
 *
 * FilterCheckboxImageItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import Badge from '../Badge';
import Checkbox from '../Checkbox';
import Text from '../Text';
import AirlineLogo from '../../Features/flights/components/AirLineLogo';

const FilterCheckboxImageItem = ({
  className,
  checked,
  onClick,
  imgSrc,
  imgAlt,
  name,
  badgeText,
  airlineCode,
  value
}) => (
  <div className={cs(className)}>
    <div className="left-item">
      <Checkbox checked={checked} onClick={() => onClick(value)} />
      {airlineCode ? (
        <AirlineLogo code={airlineCode} />
      ) : (
        imgSrc && <img src={imgSrc} alt={imgAlt} className="airline-thumb" />
      )}
      <Text className="label">{name}</Text>
    </div>

    <div className="right-item">
      {badgeText && (
        <Badge variation="orange" inverted>
          {badgeText}
        </Badge>
      )}
    </div>
  </div>
);

FilterCheckboxImageItem.propTypes = {
  className: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  name: PropTypes.string.isRequired,
  badgeText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  airlineCode: PropTypes.string
};

export default styled(FilterCheckboxImageItem)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

  .left-item {
    display: flex;
    align-items: center;
    flex: 1;

    .airline-thumb {
      width: 28px;
      height: 23.8px;
    }
  }

  .right-item {
    margin-left: 8px;

    & > span {
      min-width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .label {
    margin-left: 13px;
  }
`;
