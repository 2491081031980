export default function(api) {
  async function banners(value) {
    return await api.get(`homes/${value}`);
  }

  async function localize() {
    return await api.get('localize');
  }

  // async function countries(){
  //   return await api.get('countries', {
  //     params: {
  //       size: -1,
  //       page: 0
  //     }
  //   });
  // }

  return {
    banners,
    localize
    // countries
  };
}
