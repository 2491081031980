/**
 *
 * CardReview
 *
 */

import React from 'react';
import styled from 'styled-components';
import NewText from 'Components/NewText';

interface CardReviewProps {
  rating: string | number;
  ratingText: string;
  author: string;
  reviews: string;
  created: string;
}

const CardReview = ({
  rating,
  ratingText,
  author,
  created,
  reviews,
  ...rest
}: CardReviewProps) => (
  <Container {...rest}>
    <Header>
      <div>
        <Rating>
          <NewText color="white" fontWeight="semibold">
            {rating}
          </NewText>
        </Rating>
        <NewText color="info" fontWeight="bold">
          {ratingText}
        </NewText>
      </div>
      <AuthorContainer>
        <NewText color="orange" fontWeight="medium">
          {author}
        </NewText>
        <NewText fontWeight="light" fontStyle="italic">
          {created}
        </NewText>
      </AuthorContainer>
    </Header>
    <Content>
      <NewText fontStyle="italic">{reviews}</NewText>
    </Content>
  </Container>
);

const Container = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.one};
  background-color: ${({ theme }) => theme.palette.grays.white};
  padding: ${({ theme }) => `${theme.spacing.two} ${theme.spacing.four}`};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
  }
`;

const Rating = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.orange};
  border-radius: 4px;
  padding: ${({ theme }) => `2px ${theme.spacing.one}`};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing.two};
`;
const AuthorContainer = styled.div`
  span:last-child {
    margin-left: ${({ theme }) => theme.spacing.two};
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    &:last-child {
      margin-left: 0;
    }
  }
`;

const Content = styled.div`
  margin-top: ${({ theme }) => theme.spacing.four};
`;

export default CardReview;
