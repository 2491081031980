export const toSlug = text => text.toLowerCase().replace(/\s/g, '-');

export const toMoney = (price, symbol = '$') => {
  let result = '';

  if (price) {
    const decimals = 0;
    const decSep = '.';
    const thousandSep = ',';
    const sign = price && price < 0 ? '-' : '';
    const i = price
      ? parseInt((price = Math.abs(price).toFixed(decimals))) + ''
      : 0;
    const j = i.length > 3 ? i.length % 3 : 0;

    result =
      sign +
      symbol +
      ' ' +
      (j ? i.slice(0, j) + thousandSep : '') +
      i.slice(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandSep) +
      (decimals
        ? decSep +
          Math.abs(price - i)
            .toFixed(decimals)
            .slice(2)
        : '');
  }

  return result;
};
