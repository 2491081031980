// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Breakpoint } from 'react-socks';

// Actions
import { resetToEngine } from '../../ReduxConfig/availability';

// Utils

import MobileThankYouCorpo from '../../Components/MobileThankYouCorpo';
import ThankYouDesktopCorpo from '../../Components/ThankYouDesktopCorpo';

const ThankYouCorpo = () => {
  const dispatch = useDispatch();
  const { selectedAvailability, corpoData } = useSelector(
    ({ checkoutCorpo, availability, hotels, flights }) => ({
      selectedAvailability: {
        ...checkoutCorpo.selectedAvailability,
        engine: availability.engine,
        hotels,
        flights
      },
      ticket: checkoutCorpo.reservationData.ticket,
      reserveCode: checkoutCorpo.reservationData.reserveCode,

      corpoData: checkoutCorpo.requestData
    })
  );

  const goToHome = engineName => () => {
    dispatch(resetToEngine(engineName));
    dispatch(push('/'));
  };

  return (
    <>
      <Breakpoint small down>
        <MobileThankYouCorpo
          selectedAvailability={selectedAvailability}
          corpoData={corpoData}
        />
      </Breakpoint>
      <Breakpoint medium up>
        <ThankYouDesktopCorpo
          goToHome={goToHome}
          selectedAvailability={selectedAvailability}
          corpoData={corpoData}
        />
      </Breakpoint>
    </>
  );
};

export default ThankYouCorpo;
