import spacing from './spacing';
import shadows from './shadows';
import scrollbar from './scrollbar';
import zIndex from './zIndex';
import borderRadius from './borderRadius';
import text from './text';
import colors from './colors';
import gradients from './gradients';
import palette from './palette';
import weight from './weight';
import mediaQueries from './mediaQueries';
import width from './width';
import { breakpoints } from 'Constants';

export default {
  colors: { ...colors, ...palette.all },
  spacing,
  scrollbar,
  shadows,
  zIndex,
  borderRadius,
  gradients,
  palette,
  text,
  weight,
  width,
  mediaQueries,
  breakpoints
};

export * from './types';
