/**
 *
 * ReviewHeader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import * as COLORS from 'Constants/colors';
import RatingBox from '../RatingBox';
import Text from '../Text';
import PowerByGoogle from '../../Assets/images/powered_by_google/desktop/powered_by_google_on_white.png';

const ReviewHeader = ({ className, rating, ratingText }) => {
  const isReview = rating && ratingText;
  const subtitle = isReview
    ? 'valoración de viajeros'
    : 'Valoración de viajeros';

  return (
    <div className={cs(className)}>
      {isReview && (
        <>
          <RatingBox big inline>
            {rating}
          </RatingBox>
          <Text size="large" context="information" weight="semi-bold">
            {ratingText}
          </Text>
        </>
      )}
      <Text size="large">{subtitle}</Text>
      <img src={PowerByGoogle} alt="Powered by google" />
    </div>
  );
};

ReviewHeader.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number.isRequired,
  ratingText: PropTypes.string.isRequired
};

export default styled(ReviewHeader)`
  height: 75px;
  padding: 8px 0 10px 10px;
  border-bottom: 6px solid ${COLORS.blackHaze};
  box-shadow: 0 4px 4px -3px ${COLORS.blackHaze};
  position: relative;

  span {
    padding-left: 4px;
  }

  img {
    width: auto;
    display: block;
    max-width: 100%;
    height: 18px;
    position: absolute;
    right: 2%;
    bottom: 2%;
  }

  /* Small screens */
  @media all and (max-width: 500px) {
    span {
      display: block;
      position: absolute;

      &:nth-child(1) {
        padding-left: 7px;
      }

      &:nth-child(2) {
        top: 10px;
        left: 80px;
      }

      &:nth-child(3) {
        top: 25px;
        left: 80px;
      }
    }
  }
`;
