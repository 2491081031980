import React from 'react';
import styled from 'styled-components';
import errorIcon from '../../../../Assets/icons/error-icon.png';
import Colors from '../../../../Theme/colors';

export interface Props {
  error: string;
}

const GatewayFailedTransactionHolder = styled.div`
   {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #fff;
    border: 2px solid ${Colors.warning};
    padding: 20px;
    border-radius: 10px;
  }

  h2 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  .error-message {
    font-weight: bold;
    color: ${Colors.warning};
    margin-bottom: 20px;
  }
`;

const GatewayFailedTransaction = ({ error }: Props) => (
  <GatewayFailedTransactionHolder>
    <img src={errorIcon} width="10%" height="auto" alt="error-icon" />
    <h2>Ups! Hubo un error</h2>
    <p>Lo sentimos, no se puede procesar tu pago por la siguiente razón:</p>
    <p className="error-message">{error}</p>
    <p>Por favor, contacta con tu banco.</p>
  </GatewayFailedTransactionHolder>
);

export default GatewayFailedTransaction;
