import ACTION_TYPES from './actionTypes';
import { initialState, statusTypes } from './constants';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.BANNERS_REQUEST:
      return {
        ...state,
        status: statusTypes.loading
      };

    case ACTION_TYPES.BANNERS_SUCCESS:
      return {
        ...state,
        status: statusTypes.ready,
        data: action.payload
      };

    case ACTION_TYPES.BANNERS_FAILURE:
      return {
        ...state,
        status: statusTypes.error,
        error: action.payload
      };

    default:
      return state;
  }
};
