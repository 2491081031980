/**
 *
 * InvoiceForm
 *
 */

// Vendors
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import Select from 'Components/Select';
import Input from 'Components/Input';
import Checkbox from 'Components/Checkbox';

// Utils
import { useValidatedForm } from 'Utils/hooks/useValidatedForm';

// Constants
import { formErrors, formValidators } from 'Constants';

import {
  defaultBillingDocumentType,
  billingDocumentTypes,
  defaultPersonType,
  personTypes
} from 'Features/checkout/constants';

const getCountries = (countries = []) =>
  countries.map(country => ({
    ...country,
    id: country.code
  }));

const getCountryFromCode = (countries, code) =>
  countries.filter(country => country.code === code)[0];

const InvoiceForm = ({
  actions,
  countries,
  userData,
  onValid,
  localization
}) => {
  const countriesWithId = getCountries(countries);

  const { fields, errors, handleChange, handleValidate } = useValidatedForm({
    fields: {
      ...userData.invoice,
      fiscal: defaultPersonType,
      documentType: defaultBillingDocumentType,
      city: localization && localization.city,
      state: localization && localization.city,
      country:
        localization &&
        getCountryFromCode(countriesWithId, localization.countryCode)
    },
    errors: {
      document: [formErrors.nonLetters, formErrors.nonSymbols],
      firstName: [formErrors.lessThanOne, formErrors.nonNumbers],
      lastName: [formErrors.lessThanOne, formErrors.nonNumbers],
      dir: formErrors.lessThanFour,
      city: formErrors.nonNumbers,
      state: formErrors.nonNumbers,
      country: formErrors.required,
      documentType: formErrors.required,
      fiscal: formErrors.required,
      iAgree: formErrors.required
    },
    validators: {
      document: formValidators.nonSymbols,
      firstName: firstName => [firstName.length < 1, /\d/.test(firstName)],
      lastName: lastName => [lastName.length < 1, /\d/.test(lastName)],
      dir: dir => dir.length < 2,
      city: city => /\d/.test(city),
      state: state => /\d/.test(state)
    }
  });

  const handleBlur = () => {
    actions.setUserData({
      invoice: {
        ...userData.invoice,
        ...fields
      }
    });
  };

  const handleTacModal = e => {
    e && e.preventDefault();

    actions.toggleTacModal();
  };

  useEffect(() => {
    onValid(handleValidate());
  }, [fields]);

  return (
    <div>
      <Fields>
        <Select
          isRequired
          selectedValue={fields.fiscal && fields.fiscal.id}
          className="grid-3"
          label="Situación fiscal"
          onSelect={handleChange('fiscal')}
          items={personTypes}
          onBlur={handleBlur}
        />

        <Select
          isRequired
          className="grid-2"
          selectedValue={fields.documentType && fields.documentType.id}
          label="Tipo de Documento"
          onSelect={handleChange('documentType')}
          onBlur={handleBlur}
          items={billingDocumentTypes}
        />

        <Input
          isRequired
          className="grid-2"
          placeholder="EJ: 12345678"
          label="Número de documento"
          message={errors.document}
          context={errors.document && 'warning'}
          value={fields.document}
          onChange={handleChange('document')}
          onBlur={handleBlur}
        />

        <Input
          isRequired
          className="grid-3"
          placeholder="Nombres"
          label="Nombres"
          message={errors.firstName}
          context={errors.firstName && 'warning'}
          value={fields.firstName}
          onChange={handleChange('firstName')}
          onBlur={handleBlur}
        />

        <Input
          isRequired
          className="grid-3"
          placeholder="Apellidos"
          label="Apelidos"
          message={errors.lastName}
          context={errors.lastName && 'warning'}
          value={fields.lastName}
          onChange={handleChange('lastName')}
          onBlur={handleBlur}
        />

        <Input
          isRequired
          className="grid-3"
          placeholder="Dirección"
          label="Dirección"
          value={fields.dir}
          onChange={handleChange('dir')}
          onBlur={handleBlur}
        />

        <Select
          isRequired
          label="País"
          className="grid-2"
          selectedValue={fields.country && fields.country.id}
          onSelect={handleChange('country')}
          items={getCountries(countries)}
          onBlur={handleBlur}
        />

        <Input
          isRequired
          placeholder="Ciudad"
          label="Ciudad"
          className="grid-1"
          value={fields.city}
          message={errors.city}
          context={errors.city && 'warning'}
          onChange={handleChange('city')}
          onBlur={handleBlur}
        />

        <Input
          isRequired
          placeholder="Estado"
          label="Estado"
          className="grid-1"
          value={fields.state}
          message={errors.city}
          context={errors.lastName && 'warning'}
          onChange={handleChange('state')}
          onBlur={handleBlur}
        />

        <Checkbox
          className="grid-3"
          checked={fields.iAgree}
          onClick={handleChange('iAgree')}>
          He leído y acepto los{' '}
          <a href="#" onClick={handleTacModal}>
            términos y condiciones.
          </a>
        </Checkbox>
      </Fields>
    </div>
  );
};

const Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;

  & .grid-1 {
    grid-column: span 1;
  }

  & .grid-2 {
    grid-column: span 2;
  }

  & .grid-3 {
    grid-column: span 3;
  }

  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);

    & .grid-1,
    & .grid-2,
    & .grid-3 {
      grid-column: span 4 !important;
    }
  }
`;

InvoiceForm.propTypes = {
  onSubmit: PropTypes.func,
  countries: PropTypes.array,
  onChange: PropTypes.func,
  fields: PropTypes.object,
  onClickTaC: PropTypes.func,
  actions: PropTypes.object,
  errors: PropTypes.object,
  userData: PropTypes.object,
  handleChange: PropTypes.func,
  onValid: PropTypes.func,
  localization: PropTypes.shape({
    countryCode: PropTypes.string,
    countryName: PropTypes.string,
    city: PropTypes.string
  })
};

export default InvoiceForm;
