import colors from './colors';
export default {
  gradientVioleta: `linear-gradient(
    to right,
    ${colors.eminence},
    ${colors.deluge}
  )`,
  gradientOrange: `linear-gradient(to right, ${colors.orange}, ${colors.sunShade})`,
  gradientHomeMulti: `linear-gradient(to bottom, ${colors.newBackground}, ${colors.backgroundBreaker} 38%, ${colors.backgroundFooter})`,
  gradientEminenceDeluge: `linear-gradient(to top, ${colors.eminence}, ${colors.deluge})`,
  gradientImportant: `linear-gradient(to top, ${colors.important}, ${colors.sunShade})`,
  gradientPositive: `linear-gradient(to top, ${colors.greenPea}, ${colors.darkGreen})`,
  gradientInfo: `linear-gradient(to top, ${colors.bahamaBlue}, ${colors.pictonBlue})`,
  gradientInfoRight: `linear-gradient(to Right, ${colors.bahamaBlue}, ${colors.pictonBlue})`,
  gradientSky: `linear-gradient(to bottom, ${colors.prim}, ${colors.white})`,
  gradientGoldRight: `linear-gradient(to Right, #FFD78B, ${colors.white})`,
  gradientDestinationsBG: `linear-gradient(to bottom, rgba(23, 31, 75, 0) 70%, ${colors.newBackground})`,
  gradientDefault: `linear-gradient(to bottom, ${colors.orange}, ${colors.darkOrange})`
};
