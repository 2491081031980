import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from '../../Theme/colors';
import Text from '../../Components/Text';
import useMaxZIndex from '../../Utils/hooks/useMaxIndex';
import { manageAtcResponse } from '../../Features/checkout/helpers';
import API from '../../Services';
import {
  defaultCheckPaymentErrorMessage,
  maxAtcAttempts
} from '../../Features/checkout/constants';
import { toast } from 'react-toastify';
import GatewayFailedTransaction from '../../Features/checkout/components/GatewayFailedTransaction';
import { parse } from 'query-string';
import { push } from 'connected-react-router';
import { routes } from '../../Constants';
import { useDispatch } from 'react-redux';

// @ts-ignore
const ProgressBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .progress-bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .progress-bar {
    width: 100%;
    height: 10px;
    background-color: #f2f2f2;
    border-radius: 5px;
    position: relative;
  }

  .progress {
    height: 100%;
    border-radius: 5px;
    background-color: ${Colors.orange};
    width: 0%;
    position: absolute;
    top: 0;
    left: 0;
    animation: progress-animation 1s ease-in-out infinite;
  }

  .progress-text {
    margin-top: 10px;
    text-align: center;
    min-width: 80vw;
  }

  @keyframes progress-animation {
    0% {
      width: 0;
    }
    50% {
      width: 50%;
    }
    100% {
      width: 100%;
    }
  }
`;
const AtcCheckPayment = ({ location }) => {
  const maxZIndex = useMaxZIndex();
  const [loading, setLoading] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(1);
  const [gatewayError, setGatewayError] = useState<string | null>(null);
  const { reference } = parse(location.search);
  const dispatch = useDispatch();
  const getResponse = async () => {
    try {
      const { alertType, message } = manageAtcResponse(
        (await API.paymentSimulator.confirmPaymentStatusAtc(reference)).data
      );
      if (
        alertType == 'error' ||
        alertType == 'success' ||
        counter + 1 === maxAtcAttempts
      ) {
        setLoading(false);
        if (alertType !== 'success') {
          const messageError =
            message !== '' ? message : defaultCheckPaymentErrorMessage;
          setGatewayError(messageError);
        } else {
          dispatch(push(routes.checkoutSuccessPayment));
        }
      } else {
        setCounter(counter + 1);
      }
    } catch (error) {
      setLoading(false);
      toast.error(defaultCheckPaymentErrorMessage);
    }
  };

  useEffect(() => {
    setTimeout(() => getResponse(), 5000);
  }, [counter]);

  return (
    <>
      {loading && (
        <ProgressBar style={{ zIndex: maxZIndex + 1 }}>
          <div className="progress-bar-container">
            <div className="progress-bar">
              <div className="progress"></div>
            </div>
            <div className="progress-text">
              <Text context="white">Chequeando estado del pago...</Text>
            </div>
          </div>
        </ProgressBar>
      )}
      {gatewayError && (
        <GatewayFailedTransaction
          error={gatewayError}></GatewayFailedTransaction>
      )}
    </>
  );
};
export default AtcCheckPayment;
