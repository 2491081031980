import { useState, useEffect } from 'react';

import { breakpoints } from 'Constants';

const checkBreakPoints = () => ({
  smallDown: document.body.clientWidth < breakpoints.small,
  mediumDown: document.body.clientWidth <= breakpoints.medium,
  largeDown: document.body.clientWidth <= breakpoints.large,
  xlDown: document.body.clientWidth <= breakpoints.xlarge,
  xxlDown: document.body.clientWidth <= breakpoints.xxlarge
});

export default () => {
  const [results, setResults] = useState(checkBreakPoints());

  function handleResize() {
    setResults(checkBreakPoints());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return results;
};
