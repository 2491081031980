import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Calendar from 'Components/NewCalendar';
import PaxDetailAir from '../PaxDetailAir';
import Checkbox from 'Components/NewCheckbox';
import {
  formatIso,
  getDaysInISORange,
  getMaxFlightDate
} from '../../../../Utils/dates';
import DestinationFlight, {
  locationTypes
} from '../../../../Components/DestinationFlight';
import NewText from 'Components/NewText';

const RoundTrip = ({
  initialDateError,
  endDateError,
  initialDate,
  endDate,
  isMPC,
  minDateToStartSearch,
  knowDestination,
  actions,
  isCurrentViewDestinations
}) => (
  <>
    <div className="mv-buscador-line-details">
      <div>
        <div className="mv-buscador-locations">
          <DestinationFlight type={locationTypes.ORIGIN} />
          <DestinationFlight type={locationTypes.DESTINATION} right />
        </div>
        {!isCurrentViewDestinations && (
          <CheckboxContainer>
            <Checkbox
              name="know-destination"
              checked={knowDestination}
              color="orange"
              addMargin
              onChange={actions.setKnowDestination}
            />
            <NewText type="small">Conocer más del destino</NewText>
          </CheckboxContainer>
        )}
      </div>
      <div className="pax-calendar-holder">
        <CalendarContainer>
          <Calendar
            showLabels
            startDate={initialDate}
            onChangeStart={actions.setInitialDate}
            endDate={endDate}
            onChangeEnd={actions.setEndDate}
            startLabel="Fechas"
            endLabel={`${getDaysInISORange(endDate, initialDate) + 1} días`}
            maxDate={formatIso(getMaxFlightDate())}
            minDate={minDateToStartSearch}
            color={{ primaryColor: 'orange', lightColor: 'orangeLight' }}
            startError={{
              message: initialDateError.length > 0 && initialDateError[0]
            }}
            endError={{
              message: endDateError.length > 0 && endDateError[0]
            }}
            isRange
          />
          <CheckboxContainer>
            <Checkbox
              checked={isMPC}
              name="close-dates"
              addMargin
              onChange={actions.toggleMPC}
              color="orange"
            />
            <NewText type="small">Buscar fechas cercanas</NewText>
          </CheckboxContainer>
        </CalendarContainer>
        <PaxDetailAir />
      </div>
    </div>
  </>
);

RoundTrip.propTypes = {
  validationMessages: PropTypes.object,
  isMPC: PropTypes.bool,
  knowDestination: PropTypes.bool.isRequired,
  initialDate: PropTypes.string.isRequired,
  minDateToStartSearch: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    toggleMPC: PropTypes.func.isRequired,
    setKnowDestination: PropTypes.func.isRequired,
    setInitialDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired
  }),
  endDateError: PropTypes.array,
  initialDateError: PropTypes.array,
  isCurrentViewDestinations: PropTypes.bool
};

export default RoundTrip;

export const CheckboxContainer = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing.two};
`;

export const CalendarContainer = styled.div`
  flex-shrink: 0.5;
`;
