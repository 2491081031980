/**
 *
 * SearchEngineWrapper
 *
 */

import styled from 'styled-components';

const SearchEngineWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
  justify-content: center;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  ${({ isMobile, theme }) =>
    isMobile && `background-color: ${theme.colors.white};`};
  ${({ unused }) => unused && 'position: relative; z-index: 1;'};
  ${({ unused, isMobile }) => unused && !isMobile && 'height: 50vh;'};
`;

export default SearchEngineWrapper;
