/**
 *
 * PlusMinusSelector
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

const PlusMinusSelector = ({
  className,
  size,
  context,
  value = 0,
  onChange,
  maxValue = null,
  minValue = null,
  stateless,
  onIncrement,
  onDecrement
}) => {
  const [counterValue, setCounterValue] = React.useState(value);

  const increment = () => {
    if (stateless) {
      return onIncrement();
    }
    const nextValue = counterValue + 1;
    if (maxValue !== null) {
      if (nextValue <= maxValue) {
        setCounterValue(nextValue);
        onChange(nextValue);
      }
    } else {
      setCounterValue(nextValue);
      onChange(nextValue);
    }
  };

  const decrement = () => {
    if (stateless) {
      return onDecrement();
    }
    const nextValue = counterValue - 1;
    if (minValue !== null) {
      if (nextValue >= minValue) {
        setCounterValue(nextValue);
        onChange(nextValue);
      }
    } else {
      setCounterValue(nextValue);
      onChange(nextValue);
    }
  };

  return (
    <div className={cs(className, size, context)}>
      <div className="action-btn left" onClick={decrement}>
        -
      </div>
      <div>{stateless ? value : counterValue}</div>
      <div className="action-btn right" onClick={increment}>
        +
      </div>
    </div>
  );
};

PlusMinusSelector.propTypes = {
  className: PropTypes.string.isRequired,
  context: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  maxValue: PropTypes.any,
  minValue: PropTypes.any,
  stateless: PropTypes.bool,
  onIncrement: PropTypes.func,
  onDecrement: PropTypes.func
};

export default styled(PlusMinusSelector)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  width: 151px;
  overflow: hidden;

  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 37px;
  text-align: center;
  color: #7b7b7b;

  .action-btn {
    width: 52px;
    height: 37px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 39px;
    cursor: pointer;

    &.left {
      background-image: linear-gradient(89deg, #ff6700, #ff9901);
      line-height: 30px;
    }
    &.right {
      background-image: linear-gradient(268deg, #ff6700, #ff9901);
    }
  }

  &.large {
    width: 249px;
    line-height: 61px;
    font-size: 32px;

    .action-btn {
      width: 85px;
      height: 61px;
    }
  }

  &.positive {
    .action-btn {
      &.left {
        background-image: linear-gradient(89deg, #1f672d, #8bb136);
      }
      &.right {
        background-image: linear-gradient(268deg, #1f672d, #8bb136);
      }
    }
  }
`;
