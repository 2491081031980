/**
 *
 * HotelCheckout
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import HolderForm from 'Components/HolderForm';
import PaymentMethod from 'Components/PaymentMethod';
import Wizard from 'Components/Wizard';
import HotelInvoiceForm from 'Features/hotels/components/HotelInvoiceForm';
import HotelCheckoutBreakdown from 'Features/hotels/components/HotelCheckoutBreakdown';

// Utils
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { useUser } from '../../../users/utils';
import CommentaryInput from 'Components/CommentaryInput';
import HolderFormCorpo from '../../../../Components/HolderFormCorpo';

const HotelCheckout = ({
  availability,
  userData,
  onFinish,
  onClose,
  containerRef
}) => {
  const breakpoints = useBreakpoints();
  const agent = useUser();
  // Steps
  const steps = [
    {
      name: '¿Quién es el titular de la reserva?',
      render: props => <HolderForm {...props} />
    },
    {
      name: '¿A quién emitimos la factura?',
      render: props => <HotelInvoiceForm {...props} />
    },
    {
      name: 'Medios de pago',
      render: props => <PaymentMethod {...props} />,
      byPass:
        userData.creditCardPayment && userData.creditCardPayment.checkedFee
    }
  ];
  // Steps Agent
  const stepsAgent = [
    {
      name: '¿Quién es el titular de la reserva?',
      render: props => <HolderFormCorpo {...props} />
    },
    {
      name: 'Comentarios',
      render: props => <CommentaryInput {...props} />
    }
  ];

  return (
    <>
      <div className="content">
        <Wizard
          header="COMPLETÁ TUS DATOS Y FINALIZÁ TU COMPRA"
          onClose={onClose}
          headerCentered
          withBackButton
          steps={agent ? stepsAgent : steps}
          onFinish={onFinish}
          containerRef={containerRef}
          hiddenElement={{
            price:
              availability.availability.hotelInformation.packagePrice
                .finalPricePerNight,
            render: (
              <HotelCheckoutBreakdown
                address={availability.property.address}
                starRating={availability.property.starRating}
                keyRating={availability.property.keyRating}
                textRating={availability.property.textRating}
                propertyName={availability.property.name}
                thumbnail={availability.property.images[0].imageUrl}
                isAllInclusive={
                  availability.property.facilities.facilitiesIcons.allInclusive
                }
                reviews={availability.property.reviews}
                facilities={availability.property.facilities}
                request={{
                  checkIn: availability.search.checkIn,
                  checkOut: availability.search.checkOut
                }}
                packageRoom={availability.availability.hotelInformation}
                checkIn={availability.availability.checkIn}
                checkOut={availability.availability.checkOut}
                rateChanged={
                  availability.availability.hotelInformation.rateChanged
                }
              />
            )
          }}
        />
      </div>

      {!breakpoints.mediumDown && (
        <div className="details">
          <HotelCheckoutBreakdown
            address={availability.property.address}
            starRating={availability.property.starRating}
            keyRating={availability.property.keyRating}
            textRating={availability.property.textRating}
            propertyName={availability.property.name}
            thumbnail={availability.property.images[0].imageUrl}
            isAllInclusive={
              availability.property.facilities.facilitiesIcons.allInclusive
            }
            reviews={availability.property.reviews}
            facilities={availability.property.facilities}
            request={{
              checkIn: availability.search.checkIn,
              checkOut: availability.search.checkOut
            }}
            packageRoom={availability.availability.hotelInformation}
            checkIn={availability.availability.checkIn}
            checkOut={availability.availability.checkOut}
            rateChanged={availability.availability.hotelInformation.rateChanged}
          />
        </div>
      )}
    </>
  );
};

HotelCheckout.propTypes = {
  availability: PropTypes.object,
  userData: PropTypes.object,
  onFinish: PropTypes.func,
  onClose: PropTypes.func,
  containerRef: PropTypes.object
};

export default styled(HotelCheckout)`
  display: block;
`;
