/**
 *
 * MobileHotelOptionItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import GloboToggle from 'Components/GloboToggle';
import { getFormatPrice } from 'Utils';

const MobileHotelOptionItem = ({
  className,
  item,
  index,
  active,
  handleClick
}) => {
  const { details, packagePrice, hasFreeCancellation } = item;
  const { currency, finalPrice, finalPricePerNight } = packagePrice;

  return (
    <div className={cs(className, { active })}>
      <Text context="white" weight="semi-bold" size="large" className="text">
        ¡Tarifa seleccionada!
      </Text>
      <div className="innerCard">
        <div className="header">
          <div>
            <Text context="important" size="large" weight="semi-bold">
              Opción {index + 1}{' '}
            </Text>
            {hasFreeCancellation ? (
              <Text context="important">Acepta todas las formas de pago</Text>
            ) : (
              <div className="withIcon">
                <Text context="important">Solo tarjeta de crédito</Text>
                <Icon name="mv-creditcard" color="important" size={18} />
              </div>
            )}
          </div>
          <div>
            <GloboToggle active={active} onClick={handleClick} />
          </div>
        </div>
        <div className="context">
          <ul>
            {details.map((d, i) => (
              <li key={i}>
                <Text context={d.context}>{d.label}</Text>
              </li>
            ))}
          </ul>
        </div>
        <hr className="separator" />
        <div className="prices">
          <Text paragraph context="important" weight="">
            Precio total <br />
            <Text context="important" size="large" weight="bold">
              {getFormatPrice(finalPrice, currency)}
            </Text>
            <br />
            {item.localPrice && (
              <Text context="important" size="large" weight="bold">
                {getFormatPrice(
                  item.localPrice.finalPrice,
                  item.localPrice.currency,
                  true
                )}
              </Text>
            )}
          </Text>
          <br />
          <Text>
            {currency} {finalPricePerNight} por noche
          </Text>
          {item.localPrice && (
            <Text>
              {getFormatPrice(
                item.localPrice.finalPricePerNight,
                item.localPrice.currency,
                true
              )}{' '}
              por noche
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

MobileHotelOptionItem.propTypes = {
  className: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object,
  active: PropTypes.bool,
  handleClick: PropTypes.func
};

export default styled(MobileHotelOptionItem)`
  display: flex;
  background: transparent;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  padding: 5px;

  &.active {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-image: linear-gradient(
      to right,
      ${COLORS.gradientDefault.left},
      ${COLORS.gradientDefault.right}
    );

    .text {
      visibility: visible;
    }
  }

  &.text {
    visibility: hidden;
  }

  .innerCard {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background-color: ${COLORS.white};
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    width: 100%;
  }

  .header {
    background-color: ${COLORS.golf};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    width: 100%;
    border-radius: 15px 15px 0 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);

    .withIcon {
      display: flex;
    }

    .icon {
      display: flex;
      align-items: center;
      margin-left: 5px;
    }
  }

  .context {
    background-color: ${COLORS.white};
    padding: 5px;
    width: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding-left: 1em;
        text-indent: -0.7em;
        margin-bottom: 10px;
      }

      li::before {
        content: '• ';
        color: ${COLORS.orange};
        font-size: 14px !important;
      }
    }
  }

  .prices {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100%;
    border-radius: 0 0 15px 15px;
  }

  .separator {
    height: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.greyBkg};
    margin: 0;
    width: 100%;
  }
`;
