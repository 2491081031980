// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import PassengersCorpo from './PassengersCorpo';

// Selector
import { selector } from './selector';
import { storageCorpoData } from '../../../../ReduxConfig/checkoutCorpo';

const ConnectedPassengersCorpo = props => {
  const state = useSelector(selector);
  const dispatch = useDispatch();
  const actions = {
    setCorpoData: data => dispatch(storageCorpoData(data))
  };

  return <PassengersCorpo actions={actions} {...props} {...state} />;
};

export { PassengersCorpo };
export default ConnectedPassengersCorpo;
