/**
 *
 * MeteorologySeasons
 *
 */

import React from 'react';

// Components
import Text from 'Components/Text';
import { Season } from './styles';

// Types
import { MeteorologySeasonsProps } from './types';

const MeteorologySeasons = ({ season, color }: MeteorologySeasonsProps) => (
  <Season color={color}>
    <Text context="white" weight="bold" isUppercase>
      {season}
    </Text>
  </Season>
);

export default MeteorologySeasons;
