import React from 'react';
import styled, { css } from 'styled-components';
import NewText from 'Components/NewText';
import Heading from 'Components/Heading';
import Button from 'Components/Button';
import { useDispatch } from 'react-redux';
import { toggleCallMe } from 'Features/common';
import ContactCenter from 'Assets/images/contact-center.jpeg';

export const ExpertCard = () => {
  const dispatch = useDispatch();

  return (
    <Container>
      <div>
        <LeftContainer>
          <Titles>
            <Heading fontWeight="bold" fontSize="18px" color="wine">
              ¿Tenés Dudas?
            </Heading>
            <Heading fontWeight="bold" fontSize="18px" color="wine">
              ¡Preguntale a un experto!
            </Heading>
          </Titles>
          <NewText color="wine">
            Te ayudamos a encontrar las mejoras tarifas en vuelos ¡Es gratis!
          </NewText>
        </LeftContainer>
        <ButtonLeft onClick={() => dispatch(toggleCallMe('form'))}>
          CONTACTAR A UN EXPERTO
        </ButtonLeft>
      </div>
      {/* TODO change placeholder */}
      <ContactImage src={ContactCenter} />
    </Container>
  );
};

const ContactImage = styled.img`
  object-fit: cover;
`;

const Container = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.three};
    background-color: ${theme.palette.grays.white};
    border-radius: ${theme.borderRadius.default};
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  `}
`;

const LeftContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.two};
    padding: ${theme.spacing.four};
    display: flex;
    flex-direction: column;
  `}
`;

const ButtonLeft = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.four};
  `}
`;

const Titles = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.two};
  `}
`;
