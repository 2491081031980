export default {
  arena: '#e9d6a6' as const,
  blue: '#0a194b' as const,
  darkBlue: '#09184c' as const,
  middleBlue: '#002456' as const,
  darkGrey: '#7b7b7b' as const,
  middleGrey: '#888988' as const,
  middle2Grey: '#7b7e7c' as const,
  faded: '#7996ad' as const,
  golf: '#faeed7' as const,
  greyBkg: '#f4f4f4' as const,
  greyHoverBkg: '#f6f6f6' as const,
  important: '#ff6700' as const,
  orange: '#ff6700' as const,
  darkOrange: '#ff9824' as const,
  information: '#71bced' as const,
  lightGrey: '#d2d2d2' as const,
  navBarBckgColor: '#1a1a20' as const,
  navBarGrey: '#c1c6c4' as const,
  navBarMobileBckgColor: '#16141a' as const,
  otroVioleta: '#8b67a7' as const,
  positive: '#77a334' as const,
  green: '#77a334' as const,
  darkGreen: '#8bb136' as const,
  secondary: '#171f4b' as const,
  violeta: '#8167a3' as const,
  warning: '#ff0000' as const,
  white: '#fff' as const,
  yellow: '#fcb427' as const,
  shipCove: '#747fbc' as const,
  cornFlower: '#96caed' as const,
  spindle: '#c0dbed' as const,
  givry: '#f8e6c3' as const,
  goldenRod: '#ffce6f' as const,
  atomicTangerine: '#ffa162' as const,
  eminence: '#612f94' as const,
  deluge: '#7c67a3' as const,
  greyNurse: '#f1f2f1' as const,
  midnight: '#002441' as const,
  bahamaBlue: '#006495' as const,
  pictonBlue: '#5cbeea' as const,
  greenPea: '#1f672d' as const,
  blackHaze: '#EAECEB' as const,
  seagull: '#71bbec' as const,
  newBackground: '#31174b' as const,
  backgroundBreaker: '#4e2973' as const,
  backgroundFooter: '#713f90' as const,
  sunShade: '#ff9724' as const,
  prim: '#f9f1f7' as const,
  tussock: '#d09c4a' as const,
  bridalHeath: '#fffaf1' as const,
  eggSour: '#fff3db' as const,
  sandyBeach: '#ffebc5' as const,
  peachYellow: '#f9dda7' as const,
  grandis: '#ffd78b' as const
};
