import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteFlightQuotation,
  putFlightQuotation
} from 'Features/quotations/actions';
import { RootState } from 'ReduxConfig/store';
import QuotationAir from './QuotationsAir';
import { useParams } from 'react-router';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { ICostCardOnChangePayload } from 'Features/quotations/components/CostCard';
import { IPaxPassenger } from 'Services/Quotations/types';

const selector = ({ quotations }: RootState) => ({
  data: quotations?.detail?.flightQuotations
});

const ConnectedQuotationAir = () => {
  const state = useSelector(selector);
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const handleDelete = (id: number, flightId: number) => () => {
    dispatch(deleteFlightQuotation(id, flightId));
  };

  const handleUpdateCost = (flightId: number, passengerId: number) => (
    data: ICostCardOnChangePayload
  ) => {
    const myQuotation = state.data.find(item => item.id === flightId);
    const persistedPassengers = myQuotation.passengers.filter(
      item => item.id !== passengerId
    );
    const passengerToBeEdited: IPaxPassenger = {
      ...myQuotation.passengers.find(item => item.id === passengerId)
    };
    passengerToBeEdited.costByPax = Number(data.cost);
    passengerToBeEdited.salePriceByPax = Number(data.pvpTotal);
    passengerToBeEdited.markup = Number(data.markup);
    passengerToBeEdited.priceForEdit = data.pve ? data.pvpeValue : null;
    persistedPassengers.push(passengerToBeEdited);
    myQuotation.passengers = persistedPassengers;

    const request = myQuotation;
    dispatch(putFlightQuotation(Number(id), flightId, request));
  };

  return state.data ? (
    <QuotationAir
      {...state}
      breakpoints={breakpoints}
      onDelete={handleDelete}
      mainQuotationId={Number(id)}
      onUpdateCost={handleUpdateCost}
    />
  ) : null;
};

export default ConnectedQuotationAir;
