import React from 'react';
import styled from 'styled-components';
import { TIconName } from 'Components/Icon/types';
import Theme from 'Theme';
import Icon from 'Components/Icon';

export type ButtonSize = 'S' | 'M' | 'L' | 'XL';

export type ButtonVariation =
  | 'default'
  | 'orange'
  | 'positive'
  | 'information'
  | 'violet';

export interface ButtonProps {
  size?: ButtonSize;
  variation?: ButtonVariation;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  left?: boolean;
  right?: boolean;
  iconLeft?: TIconName;
  iconRight?: TIconName;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  squareBottom?: boolean;
}

const Button = ({
  children,
  size = 'L',
  variation = 'orange',
  onClick,
  iconLeft,
  iconRight,
  ...rest
}: ButtonProps) => (
  <Container size={size} onClick={onClick} variation={variation} {...rest}>
    {iconLeft && (
      <LeftIcon size={iconSizes[size]} name={iconLeft} color="white" />
    )}
    {children}
    {iconRight && (
      <RightIcon size={iconSizes[size]} name={iconRight} color="white" />
    )}
  </Container>
);

const iconSizes = {
  xs: 10,
  s: 10,
  M: 12,
  sm: 16,
  L: 16,
  lg: 24,
  XL: 24
};

const getSizeStyle = (size: ButtonSize): string => {
  switch (size) {
    // TODO this maps old props to the new ones
    // @ts-ignore
    case 'xs':
    case 'S':
      return `
      font-size: 10px;
      line-height: 12px;
      `;
    case 'M':
      return `
        font-size: 12px;
        line-height: 15px;
      `;
    // TODO this maps old props to the new ones
    // @ts-ignore
    case 'sm':
    case 'L':
      return `
        font-size: 16px;
        font-height: 20px;
      `;
    // TODO this maps old props to the new ones
    // @ts-ignore
    case 'lg':
    case 'XL':
      return `
        font-size: 24px;
        line-height: 29px;
      `;
  }
};

const getVariationColor = (variation: ButtonVariation): string => {
  switch (variation) {
    case 'default':
      return Theme.palette.grays.darkGrey;
    case 'information':
      return Theme.palette.status.info;
    case 'positive':
      return Theme.palette.status.positive;
    case 'orange':
      return Theme.palette.primary.orange;
    case 'violet':
      return Theme.palette.primary.violet;
  }
};

const Container = styled.button<ButtonProps>`
  display: inline-flex;
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  padding: ${({ theme }) => `${theme.spacing.one} ${theme.spacing.four}`};
  ${({ size }) => getSizeStyle(size)};
  border: 0;
  color: ${({ theme }) => theme.palette.grays.white};
  background: ${({ variation }) => variation && getVariationColor(variation)};
  text-align: center;
  justify-content: center;
  align-items: center;
  :hover {
    box-shadow: ${({ theme }) => theme.shadows.two};
  }

  ${({ squareBottom, theme }) =>
    squareBottom &&
    `
    border-radius: ${theme.borderRadius.default};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    `}
    
    

  ${({ fullWidth, theme }) =>
    fullWidth &&
    `
    width: 100%;
    margin-left: ${theme.spacing.four}
    margin-right: ${theme.spacing.four}
    `}
  ${({ left }) =>
    left &&
    `
    position: absolute;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    left: 0;
  `}
  ${({ right }) =>
    right &&
    `
    position: absolute;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    right: 0;
  `}
`;
// @ts-ignore
const LeftIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing.one};
`;

const RightIcon = styled(Icon)`
  margin-left: ${({ theme }) => theme.spacing.one};
`;

export default Button;
