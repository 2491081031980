/**
 *
 * Heading
 *
 */

import React from 'react';
import styled, { css } from 'styled-components';
import {
  ThemeFont,
  ThemeFontSize,
  ThemeFontStyle,
  ThemeFontWeight,
  ThemeColor,
  TextHeading
} from 'Theme';

interface HeadingProps {
  children: React.ReactNode;
  fontFamily?: ThemeFont;
  fontSize?: ThemeFontSize;
  fontStyle?: ThemeFontStyle;
  fontWeight?: ThemeFontWeight;
  color?: ThemeColor;
  type?: TextHeading;
  inline?: boolean;
  uppercase?: boolean;
}

const Heading = ({ children, type = 'h5', ...rest }: HeadingProps) => (
  <Container {...rest} type={type} as={type}>
    {children}
  </Container>
);

const Container = styled.div<HeadingProps>`
  margin: 0;
  ${({ type, theme }) =>
    type ? theme.text.headingStyle[type] : theme.text.bodyStyle.medium};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight && theme.text.fontWeights[fontWeight]};
  font-style: ${({ fontStyle }) => fontStyle && fontStyle};
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.palette.grays.meVueloGrey};
  ${({ inline }) => inline && 'display: inline;'};
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export default Heading;
