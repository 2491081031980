import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Login from './Login';

// Types
import { IAuthFields } from './types';

import { selector } from './selector';
import { breakpoints } from 'Constants';
import { userLogin } from '../../actions';

const ConnectedLogin = () => {
  const { pending, error } = useSelector(selector);
  const dispatch = useDispatch();

  const handleLogin = (fields: IAuthFields): void => {
    dispatch(userLogin(fields));
  };

  return (
    <Wrapper>
      <Login onSubmit={handleLogin} pending={pending} error={error} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: calc(100vh - 72px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.medium}px) {
    display: block;
  }
`;

export default ConnectedLogin;
