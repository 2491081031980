/**
 *
 * SelectDate
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import cs from 'classnames';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

import * as COLORS from 'Constants/colors';
import { InputErrorMessageProps } from 'Components/InputErrorMessage';
import { CustomSelect } from '../NewSelect';
import { formatIso, formatSuperLong, parseIso } from 'Utils/dates';
registerLocale('es', es);
setDefaultLocale('es');

interface SelectDateProps {
  selectedValue: string;
  onChange: (e: any) => void;
  placeholder?: string;
  error: InputErrorMessageProps;
  className?: string;
}

const SelectDate = ({
  className,
  selectedValue,
  onChange,
  placeholder = 'Elegí tu fecha de viaje',
  error
}: SelectDateProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = e => {
    if (e && e.key !== 'Tab') e.preventDefault();
    setIsOpen(!isOpen);
  };

  const close = () => setIsOpen(false);

  const chooseValue = date => {
    onChange(formatIso(date));
    setIsOpen(false);
  };

  return (
    <CustomSelect
      className={cs(className)}
      label={placeholder}
      toggleOpen={toggleOpen}
      isOpen={isOpen}
      close={close}
      color="orange"
      error={error}
      showValue={selectedValue && formatSuperLong(selectedValue)}>
      <DatePicker
        selected={selectedValue && parseIso(selectedValue)}
        inline
        calendarContainer={Container}
        onChange={chooseValue}
        minDate={new Date()}
        showDisabledMonthNavigation
      />
    </CustomSelect>
  );
};

const Container = ({ children }: { children: React.ReactNode }) => (
  <div className="date-picker-holder">{children}</div>
);

export default styled(SelectDate)`
  .react-datepicker {
    font-family: Montserrat, serif;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day,
  .react-datepicker-year-header {
    font-family: Montserrat, serif;
    line-height: 0.86;
    letter-spacing: 0.18px;
    color: ${COLORS.important};
  }

  .react-datepicker__day--selected {
    background-color: ${COLORS.important};
    color: ${COLORS.white};
  }

  .react-datepicker__day--disabled {
    color: ${COLORS.darkGrey};
  }

  .react-datepicker__week,
  .react-datepicker__day-names {
    display: flex;
    margin-bottom: 5px;
    justify-content: space-around;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    display: flex;
    width: 40px;
    height: 20px;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__day:hover {
    border-radius: 5px;
    background-color: ${COLORS.arena};
    cursor: pointer;
  }

  .react-datepicker__navigation--next,
  .react-datepicker__navigation--next:hover {
    border-left-color: ${COLORS.important};
  }

  .react-datepicker__navigation--previous--disabled,
  .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: ${COLORS.darkGrey} !important;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--previous:hover {
    border-right-color: ${COLORS.important};
  }

  .react-datepicker__header {
    border: 0;
    background-color: ${COLORS.white};
  }
  .react-datepicker__month-container {
    width: 100%;
  }
`;
