import { useEffect, useState } from 'react';
import { getHeight } from '../index';

export default (ref = null) => {
  const targetElement = ref ? ref.current : window;
  const [heightSize, setheightSize] = useState(getHeight());

  useEffect(() => {
    function updateValue() {
      setheightSize(getHeight());
    }
    targetElement.addEventListener('resize', updateValue);
    return () => {
      targetElement.removeEventListener('resize', updateValue);
    };
  }, [heightSize, ref, targetElement]);

  return heightSize;
};
