import {
  IQuotation,
  IPagination,
  IQuotationFull,
  IErrorResponse
} from 'Services/Quotations/types';
import { paginationLimit } from 'Constants';

export interface IQuotationState {
  list: IQuotation[];
  pending: boolean;
  pagination: IPagination;
  detail: IQuotationFull | null;
  error?: IErrorResponse;
}

export const initialState: IQuotationState = {
  pagination: {
    currentPage: 0,
    totalPages: 0,
    totalItems: 0,
    limit: paginationLimit
  },
  list: [],
  pending: false,
  detail: null
};
