export default function(apiGraphql) {
  const prefix = 'api';

  async function getCitiesContent(value) {
    return await apiGraphql.post(prefix, value);
  }

  return {
    getCitiesContent
  };
}
