import { useRef, useLayoutEffect, useState } from 'react';
import { getScrollPos } from '../index';
import { throttle } from 'lodash';

export const useIsScrollTop = effect => {
  const isScrollTop = useRef(true);
  useLayoutEffect(() => {
    const scrollListener = () => {
      const currentScrollPos = getScrollPos();
      const isTop = currentScrollPos < 50;
      if (isTop !== isScrollTop.current) {
        isScrollTop.current = isTop;
        effect({ isTop });
      }
    };

    const throttledListener = throttle(scrollListener, 200, { leading: true });
    window.addEventListener('scroll', throttledListener, { passive: true });

    return () => () => window.removeEventListener('scroll', throttledListener);
  });
};

export const useIsScrollTopHook = () => {
  const [isHomeTop, setIsHomeTop] = useState(true);

  useIsScrollTop(
    ({ isTop }) => {
      setIsHomeTop(isTop);
    },
    [isHomeTop]
  );

  return isHomeTop;
};
