/**
 *
 * LazyImg
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import cs from 'classnames';
import LazyLoad from 'react-lazy-load';
import * as COLORS from 'Constants/colors';

const LazyImg = ({ className, src, alt, skip, height, imgFallback }) => {
  const [loaded, setLoaded] = useState(skip);
  const loaderClassName = loaded ? 'img-loaded' : 'img-loading';
  const loadImg = () => setLoaded(true);

  const handleError = e => {
    if (imgFallback) {
      e.target.src = imgFallback;
      e.target.onerror = null;
    }
  };

  if (skip) {
    return (
      <div className={cs(className)}>
        <img
          src={src}
          alt={alt}
          className={cs('image', 'img-loaded')}
          onError={handleError}
        />
      </div>
    );
  }

  return (
    <LazyLoad
      offsetVertical={skip ? 0 : 500}
      debounce={false}
      throttle={250}
      height={height}>
      <div className={cs(className)}>
        <img
          src={src}
          alt={alt}
          onError={handleError}
          className={cs('image', loaderClassName)}
          onLoad={loadImg}
        />
      </div>
    </LazyLoad>
  );
};

LazyImg.propTypes = {
  className: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  usePlaceholder: PropTypes.bool,
  placeholder: PropTypes.string,
  imgFallback: PropTypes.string,
  skip: PropTypes.bool,
  height: PropTypes.number
};
/* stylelint-disable */
const fadeIn = keyframes`
    from {
        transform: scale(1.02, 1.02);
        opacity: 0;
        top: -3;
    }

    to {
        transform: scale(1, 1);
        opacity: 1;
        top: 0;
    }
`;
/* stylelint-enable */

export default styled(LazyImg)`
  position: relative;
  overflow: hidden;
  background-color: ${COLORS.white};

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img-loading {
    opacity: 0;
    width: 100%;
    height: auto;
  }

  .img-loaded {
    animation: ${fadeIn} cubic-bezier(0.23, 1, 0.32, 1) 1;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }

  .placeholder {
    position: relative;
    width: 100%;
    filter: blur(5px);
    transform: scale(1);
  }

  .hidden {
    display: none;
  }
`;
