/**
 *
 * FareCheckoutBreakdown
 *
 */

// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

// Constants
import * as COLORS from 'Constants/colors';

// Components
import PricePassengerDetail from 'Components/PricePassengerDetail';
import AirSegment from 'Features/flights/components/AirSegment';

const FareCheckoutBreakdown = ({ className, selectedAvailability }) => {
  const [toggled, setToggle] = useState(false);
  const onTouchStart = () => setToggle(oldState => !oldState);
  const showAvailability =
    selectedAvailability && !selectedAvailability.hasOwnProperty('engine');

  return showAvailability ? (
    <div
      className={cs(className, 'details', 'air-segments', { toggled })}
      onTouchStart={onTouchStart}>
      <PricePassengerDetail
        flightPrice={selectedAvailability.flightPrice}
        localPrice={selectedAvailability.localPrice}
      />

      <AirSegment
        data={selectedAvailability.selectedSegment.ida.item}
        date={
          selectedAvailability.selectedSegment.ida.item.summaryDateAndAirports
            .flightDate
        }
        title="IDA"
      />

      {selectedAvailability.selectedSegment.vuelta.item && (
        <AirSegment
          data={selectedAvailability.selectedSegment.vuelta.item}
          date={
            selectedAvailability.selectedSegment.vuelta.item
              .summaryDateAndAirports.flightDate
          }
          title="VUELTA"
        />
      )}
    </div>
  ) : (
    <></>
  );
};

FareCheckoutBreakdown.propTypes = {
  className: PropTypes.string.isRequired,
  selectedAvailability: PropTypes.object
};

export default styled(FareCheckoutBreakdown)`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
  display: block;

  .cardSegment {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);

    .mv-mevuelo {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    }

    .mv-card-content {
      padding: 12px 0 0;
      background-color: ${COLORS.white} !important;

      > * {
        padding-left: 17px;
        padding-right: 17px;
      }
    }

    &:first-child {
      background-color: transparent;

      .mv-card-content {
        padding: 12px 0;
      }

      .mv-mevuelo {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        font-size: 18px;
      }
    }

    &:last-child {
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;

      .mv-card-content {
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        padding: 12px 0 20px;
      }
    }

    &:not(:first-child) {
      .mv-mevuelo {
        margin-left: 1px;
        width: calc(100% + 1px);
      }
    }
  }
`;
