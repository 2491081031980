import ACTION_TYPES from './actionTypes';
import { initialState } from './constants';
import { IDestinationsReducer } from 'Features/destinations/types';

export default (state = initialState, action): IDestinationsReducer => {
  switch (action.type) {
    case ACTION_TYPES.DESTINATIONS_SEARCH_REQUEST:
      return {
        ...state,
        status: 'loading'
      };

    case ACTION_TYPES.DESTINATIONS_SEARCH_SUCCESS:
      return {
        ...state,
        status: 'ready',
        data: action.payload
      };

    case ACTION_TYPES.DESTINATIONS_SEARCH_FAILURE:
      return {
        ...state,
        status: 'failure',
        error: action.payload
      };

    case ACTION_TYPES.DESTINATIONS_SET_ACTIVE:
      return {
        ...state,
        active: action.payload,
        status: !action.payload ? 'unused' : state.status
      };
    case ACTION_TYPES.DESTINATIONS_SET_CURRENT_VIEW:
      return {
        ...state,
        isCurrentView: action.payload
      };

    case ACTION_TYPES.DESTINATIONS_FLIGHTS_SUCCESS:
      return {
        ...state,
        flightsBanners: action.payload
      };

    case ACTION_TYPES.DESTINATIONS_RESET:
      return initialState;

    case ACTION_TYPES.PACKAGES_BY_DESTINATION_REQUEST:
      return {
        ...state,
        status: 'loading'
      };
    case ACTION_TYPES.PACKAGES_BY_DESTINATION_SUCCESS:
      return {
        ...state,
        packagesByDestinationResult: action.payload,
        status: 'ready'
      };
    case ACTION_TYPES.PACKAGES_BY_DESTINATION_FAILURE:
      return {
        ...state,
        status: 'failure',
        error: action.payload
      };

    default:
      return state;
  }
};
