/**
 *
 * GMap
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { GOOGLE_MAPS_KEY } from 'Constants';
import * as COLORS from 'Constants/colors';
import { GoogleMap, Marker, LoadScript, Polygon } from '@react-google-maps/api';

const GMap = ({
  className,
  initialCenter,
  markerName,
  zoom = 15,
  polygon,
  points
}) => {
  const mapOptions = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false
  };

  const mapContainerStyle = {
    height: '100%',
    width: '100%',
    borderRadius: '14px'
  };

  return (
    <div className={cs(className)}>
      <LoadScript id="script-loader" googleMapsApiKey={GOOGLE_MAPS_KEY}>
        <GoogleMap
          id="gmap"
          mapContainerStyle={mapContainerStyle}
          zoom={zoom}
          options={mapOptions}
          center={initialCenter}>
          <Marker
            title={markerName ? markerName : 'not set'}
            position={initialCenter && initialCenter}
          />
          {polygon && polygon.length && (
            <Polygon
              paths={polygon}
              strokeColor={COLORS.eminence}
              strokeOpacity={0.8}
              strokeWeight={1}
              fillColor={COLORS.lightGrey}
              fillOpacity={0.35}
            />
          )}

          {points &&
            points.length &&
            points.map((point, index) => (
              <Marker
                key={'marker-' + index}
                label={point.name ? point.name : ''}
                position={point.location}
              />
            ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

GMap.propTypes = {
  className: PropTypes.string.isRequired,
  google: PropTypes.object,
  initialCenter: PropTypes.object,
  markerName: PropTypes.string,
  zoom: PropTypes.number,
  polygon: PropTypes.array,
  points: PropTypes.array
};

export default styled(GMap)`
  height: 400px;
  position: relative;
  border-radius: 14px;
  overflow: hidden;
`;
