/**
 *
 * Select
 *
 */

// Vendors
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Icon from '../Icon';
import useOutsideClick from 'Utils/hooks/useOutsideClick';

// constants
import {
  white,
  important,
  darkGrey,
  warning,
  information,
  positive
} from 'Constants/colors';
import { isMobile } from 'Utils';

const Select = ({
  className,
  context,
  label,
  multiselect,
  items,
  selectedValue,
  onSelect,
  fullWidth,
  placeholder,
  isRequired,
  disabled,
  message,
  onBlur
}) => {
  const [value, setValue] = useState(selectedValue);
  const [searchValue, setSearchValue] = useState('');
  /**
   * Para abrir y cerrar el select
   */
  const [isOpen, setIsOpen] = useState(false);
  const refIsOpen = useRef();
  const [currentItems, setCurrentItems] = useState([]);
  const selectedStringDefault = placeholder
    ? placeholder
    : 'Seleccione una opción';

  useOutsideClick(refIsOpen, () => {
    if (isOpen) {
      setIsOpen(false);
      onBlur && onBlur();
    }
  });

  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    setCurrentItems(items);
  }, [items, setCurrentItems]);

  const toggleSelect = () => {
    if (disabled) {
      return;
    }

    setIsOpen(!isOpen);
  };

  const returnSelectedString = () => {
    if (searchValue) return searchValue;
    if (multiselect) {
      if (!value || !Array.isArray(value) || value.length < 1) {
        return '';
      }
      let finalString = '';
      value.forEach((item, index) => {
        const [first] = items.filter(
          itemFromItemList => itemFromItemList.id === item
        );
        const text = first ? first.name : '';
        finalString += index === 0 ? text : `, ${text}`;
      });
      return finalString;
    } else {
      const [first] = items ? items.filter(i => i.id === value) : [];
      const text = first ? first.name : '';
      return value || value === 0 ? text : '';
    }
  };
  const selectOption = item => () => {
    clearSearch();
    if (multiselect) {
      if (!Array.isArray(value)) {
        const newValue = [];
        newValue.push(item.id);
        setValue(newValue);
        onSelect && onSelect(newValue);
      } else {
        if (isItemAlreadySelectedInMultiselect(item.id)) {
          const newValue = value.filter(item => item !== item.id);
          setValue(newValue);
          onSelect && onSelect(newValue);
        } else {
          const newValue = [...value];
          newValue.push(item.id);
          setValue(newValue);
          onSelect && onSelect(newValue);
        }
      }
    } else {
      setValue(item.id);
      onSelect && onSelect(item);
      toggleSelect();
      onBlur && onBlur();
    }
  };
  const isItemAlreadySelectedInMultiselect = id => {
    if (Array.isArray(value)) {
      return value.includes(id);
    } else {
      return false;
    }
  };

  const handleChange = event => {
    setValue('');
    if (!isOpen) {
      setIsOpen(true);
    }
    setSearchValue(event.target.value);
    let currentList = items ? [...items] : [];
    let newList = [];
    if (event && event.target.value !== '') {
      newList = currentList.filter(item => {
        const lc = item.name.toLowerCase();
        const filter = event.target.value.toLowerCase();
        return lc.includes(filter);
      });
      setCurrentItems(newList);
    } else {
      setCurrentItems(items);
    }
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  return (
    <div
      className={cs(
        className,
        context,
        { open: isOpen },
        { disabled },
        { 'full-width': fullWidth }
      )}>
      {label && (
        <label className="select-label">
          {isRequired && <b style={{ color: important }}>*</b>}

          {label}
        </label>
      )}

      <div className={cs('mv-select', { 'is-open': isOpen })}>
        <div
          className="mv-select-container"
          onClick={toggleSelect}
          tabIndex={0}>
          <input
            className="selected-value-holder"
            placeholder={selectedStringDefault}
            value={returnSelectedString()}
            onChange={handleChange}
          />

          <div className="arrow" />
        </div>

        {message && <div className="mv-select-message">{message}</div>}

        <div className="mv-select-options" ref={refIsOpen}>
          <ul>
            {' '}
            {currentItems && currentItems.length > 0 ? (
              currentItems.map((item, index) => (
                <li key={index} onClick={selectOption(item)}>
                  <span>{item ? item.name : ''}</span>

                  {multiselect && (
                    <div className="checkbox-square">
                      {isItemAlreadySelectedInMultiselect(
                        item ? item.id : 0
                      ) && <Icon name="mv-checkmark" />}
                    </div>
                  )}
                </li>
              ))
            ) : (
              <li>No hay coincidencias</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string.isRequired,
  context: PropTypes.string,
  label: PropTypes.string,
  multiselect: PropTypes.bool,
  items: PropTypes.array,
  selectedValue: PropTypes.any,
  onSelect: PropTypes.func,
  fullWidth: PropTypes.bool,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  message: PropTypes.string,
  onBlur: PropTypes.func
};

// TODO: Arrange css classes later.
export default styled(Select)`
  position: relative;

  &.full-width {
    width: 100%;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .mv-select-container {
    font-family: Montserrat, serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.38;
    letter-spacing: 0.2px;
    text-align: left;
    color: ${important};
    height: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    border-radius: 12px;
    background-color: ${white};

    &:focus {
      outline: none;
    }

    .selected-value-holder {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      height: 33px;
      width: 100%;
      font-weight: 500;
      line-height: 21px;
      background-color: ${white};
      border: 0;
      color: ${darkGrey};
      font-size: ${isMobile.any() ? '14px' : '14px'};

      &:focus {
        outline: none;
      }
    }

    .arrow {
      border-color: ${important};
      border-left: 3px solid;
      border-bottom: 3px solid;
      width: 12px;
      height: 12px;
      transform: rotate(-45deg);
      transition: 0.6s;
      margin-left: 10px;
    }
  }

  .mv-select-message {
    padding: 0 10px;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: left;
    color: ${white};
    font-weight: 500;
    width: 100%;
  }

  .mv-select {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    border-radius: 14px;
    overflow: hidden;

    &.is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  label {
    font-family: Montserrat, serif;
    color: ${important};
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.96;
    letter-spacing: 0.2px;
    text-align: left;
    text-transform: uppercase;
  }

  &.important {
    .mv-select {
      background-color: ${important};

      .arrow {
        border-color: ${important};
      }
    }
  }

  &.positive {
    .mv-select {
      background-color: ${positive};

      .arrow {
        border-color: ${positive};
      }
    }
  }

  &.information {
    .mv-select {
      background-color: ${information};

      .arrow {
        border-color: ${information};
      }
    }
  }

  &.warning {
    .mv-select {
      background-color: ${warning};

      .arrow {
        border-color: ${warning};
      }
    }
  }

  .mv-select-options {
    display: none;
    z-index: 3;
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    text-align: left;
    color: ${darkGrey};
    background-color: ${white};
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35);
    max-height: 120px;
    overflow-y: auto;
    width: 100%;
    border-radius: 0 0 14px 14px;

    &::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }

    .mv-search-items {
      position: sticky;
      top: 0;

      input[type='text'] {
        width: 100%;
      }

      button {
        position: absolute;
        right: 5px;
        top: 2px;
        width: 24px;
        height: 24px;
        padding: 1px;
        outline: none;
      }
    }

    ul {
      list-style-type: none;
      margin-bottom: 0;
      padding-left: 0;

      li {
        height: 32px;
        padding: 5px 12px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          background-color: #eaeceb;
        }

        .checkbox-square {
          position: relative;
          width: 16px;
          height: 15px;
          border-radius: 3px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
          border: solid 1px #7b7b7b;
          background-color: #fff;
          display: inline-block;

          .icon {
            position: absolute;
            color: ${important};
            bottom: -7px;
            left: -1px;
          }
        }
      }
    }
  }

  &.open {
    .mv-select-container {
      border-radius: 14px 14px 0 0;

      .arrow {
        transform: rotate(-225deg);
      }
    }

    .mv-select-options {
      display: block;
      ${({ theme }) => theme.scrollbar(important, 15)};
    }
  }
`;
