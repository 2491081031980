import styled from 'styled-components';
import { breakpoints } from '../../../../Constants';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.three};

  @media screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.four};
  box-shadow: ${({ theme }) => theme.shadows.two};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${({ theme }) => theme.spacing.two};
  padding-top: ${({ theme }) => theme.spacing.two};

  label {
    color: ${({ theme }) => theme.palette.primary.violet};
  }

  .full {
    grid-column: span 3;
  }

  .two {
    grid-column: span 2;

    @media screen and (max-width: ${breakpoints.medium}px) {
      grid-column: span 3;
    }
  }

  .one {
    grid-column: span 1;

    @media screen and (max-width: ${breakpoints.medium}px) {
      grid-column: span 3;
    }
  }
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.text.fontSize.headings.large};
  color: ${({ theme }) => theme.colors.important};
  font-weight: ${({ theme }) => theme.text.fontWeights.medium};
`;

export const History = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};

  span {
    font-weight: ${({ theme }) => theme.text.fontWeights.medium};
  }
`;

export const Passengers = styled.div`
  button {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const Actions = styled.div`
  button {
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.two};
    }
  }
`;

export const ID = styled.div`
  padding: ${({ theme }) => theme.spacing.two};
  background-color: ${({ theme }) => theme.colors.important};
  box-shadow: ${({ theme }) => theme.shadows.two};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weight.one};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  font-size: ${({ theme }) => theme.text.fontSize.body.small};
  display: inline-block;

  @media screen and (max-width: ${breakpoints.medium}px) {
    display: block;
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
