import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import QuotationsHome from './QuotationsHome';

import { getQuotationsList } from '../../actions';
import { paginationLimit } from 'Constants';
import { selector } from './selector';

const defaultFilters = {
  filter: '',
  dateTo: '',
  dateFrom: '',
  sort: ''
};

const ConnectedQuotationsHome = () => {
  const dispatch = useDispatch();
  const { pending, list, pagination, resultsFound } = useSelector(selector);

  const handleNextPage = () => {
    dispatch(
      fetchList({
        page: pagination.currentPage + 1
      })
    );

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePreviousPage = () => {
    dispatch(
      fetchList({
        page: pagination.currentPage - 1
      })
    );

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const fetchList = params =>
    getQuotationsList({
      ...params,
      ...defaultFilters,
      size: paginationLimit
    });

  const props = {
    onDeleteQuotation: () => alert('Deleted'),
    onAddQuotation: () => alert('Added'),
    onCopyQuotation: () => alert('Copied'),
    onNextPage: handleNextPage,
    onPreviousPage: handlePreviousPage,
    list,
    pending,
    pagination,
    resultsFound
  };

  return <QuotationsHome {...props} />;
};

export default ConnectedQuotationsHome;
