/**
 *
 * MobileAirFilter
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Icon from 'Components/Icon';
import MobileFilterHolder from 'Components/MobileFilterHolder';
import FilterRadioItem from 'Components/FilterRadioItem';
import Slider from 'Components/Slider';
import Checkbox from 'Components/Checkbox';
import FilterCheckboxImageItem from 'Components/FilterCheckboxImageItem';
import Text from 'Components/Text';
import MobileNavBar from 'Components/MobileNavBar';
import Button from 'Components/Button';
import {
  getScaleLabel,
  getBaggageLabel,
  getCabinName
} from '../../../../Utils';

const MobileAirFilter = ({
  className,
  close,
  fareSliderDetails,
  filtersData,
  handleFareFilterChange,
  handleClickLuggageFilter,
  handleClickScalesFilter,
  handleAirlineFilter,
  handleAllianceFilter,
  handleClassFilter,
  handleNoAirportChange,
  handleFlexFilterChange,
  filters
}) => {
  const {
    alliances,
    airlines,
    scales,
    cabins,
    baggage,
    totalFlex
  } = filtersData;
  const { minFare, maxFare, currency } = fareSliderDetails;
  useEffect(() => {
    document.body.classList.toggle('non-scroll', true);
    // Fixes some cases where this toggle does not fire on unmount
    return () => {
      document.body.classList.remove('non-scroll');
    };
  }, []);

  return (
    <div className={cs(className)}>
      <div>
        <div className="topBar">
          <Text weight="italic" size="xlarge">
            Filtros
          </Text>
          <div onClick={close} className="close">
            <Icon name="mv-cross" className="close" />
          </div>
        </div>
        {totalFlex && (
          <MobileFilterHolder title="Flex" active>
            <Checkbox
              checked={filters.hasFlex}
              onClick={handleFlexFilterChange}>
              Tarifa Flexible
            </Checkbox>
          </MobileFilterHolder>
        )}
        <MobileFilterHolder title="Escalas" active>
          {Object.keys(scales).map((scale, index) => (
            <FilterRadioItem
              badgeText={scales[scale]}
              label={getScaleLabel(scale)}
              key={index}
              value={scale}
              onClick={handleClickScalesFilter}
              checked={filters.scales === scale}
            />
          ))}
          <div className="checkbox">
            <Checkbox
              checked={filters.airPortChange === false}
              onClick={handleNoAirportChange}>
              Sin cambio de aeropuerto
            </Checkbox>
          </div>
        </MobileFilterHolder>
        <MobileFilterHolder title="Equipaje" active>
          {Object.keys(baggage).map((b, index) => (
            <FilterRadioItem
              badgeText={baggage[b]}
              label={getBaggageLabel(b)}
              key={index}
              value={b}
              onClick={handleClickLuggageFilter}
              checked={filters.luggageFilter === b}
            />
          ))}
        </MobileFilterHolder>
        <MobileFilterHolder title="Precio" active={false}>
          <Slider
            min={minFare}
            max={maxFare}
            currency={currency}
            onChange={handleFareFilterChange}
            defaultValue={[minFare, maxFare]}
            className="slider"
            showToolTip
          />
        </MobileFilterHolder>
        {/* Commented this sections because of the integration, this is not done yet */}
        {/* <MobileFilterHolder title="Horario de Ida" active={false}>
        <div className="label-holder">
          <Text size="large" weight="semi-bold">
            Despegue de {ida.takeoff.city}
          </Text>
        </div>
        <Slider
          min={ida.takeoff.min}
          max={ida.takeoff.max}
          defaultValue={[ida.takeoff.min, ida.takeoff.max]}
          className="slider"
        />
        <div className="label-holder">
          <Text size="large" weight="semi-bold">
            Aterrizaje en {ida.landing.city}
          </Text>
        </div>
        <Slider
          min={ida.landing.min}
          max={ida.landing.max}
          defaultValue={[ida.takeoff.min, ida.takeoff.max]}
          className="slider"
        />
        <div className="label-holder">
          <Text size="large" weight="semi-bold">
            Duración total
          </Text>
        </div>
        <Slider
          min={ida.duration.min}
          max={ida.duration.max}
          defaultValue={[ida.duration.min, ida.duration.max]}
          className="slider"
        />
        <div className="checkbox">
          <Checkbox checked={false}>Salir y llegar el mismo día</Checkbox>
        </div>
      </MobileFilterHolder>
      <MobileFilterHolder title="Horario de Vuelta" active={false}>
        <div className="label-holder">
          <Text size="large" weight="semi-bold">
            Despegue de {vuelta.takeoff.city}
          </Text>
        </div>
        <Slider
          min={vuelta.takeoff.min}
          max={vuelta.takeoff.max}
          defaultValue={[vuelta.takeoff.min, vuelta.takeoff.max]}
          className="slider"
        />
        <div className="label-holder">
          <Text size="large" weight="semi-bold">
            Aterrizaje en {vuelta.landing.city}
          </Text>
        </div>
        <Slider
          min={vuelta.landing.min}
          max={vuelta.landing.max}
          defaultValue={[vuelta.takeoff.min, vuelta.takeoff.max]}
          className="slider"
        />
        <div className="label-holder">
          <Text size="large" weight="semi-bold">
            Duración total
          </Text>
        </div>
        <Slider
          min={vuelta.duration.min}
          max={vuelta.duration.max}
          defaultValue={[vuelta.duration.min, vuelta.duration.max]}
          className="slider"
        />
        <div className="checkbox">
          <Checkbox checked={false}>Salir y llegar el mismo día</Checkbox>
        </div>
      </MobileFilterHolder> */}

        <MobileFilterHolder title="Aerolíneas" active={false}>
          {airlines.map(({ airline, total }) => (
            <FilterCheckboxImageItem
              checked={filters.airLinesFilter.includes(airline.code)}
              name={airline.name}
              key={airline.id}
              airlineCode={airline.code}
              value={airline.code}
              onClick={handleAirlineFilter}
              badgeText={total}
            />
          ))}
        </MobileFilterHolder>
        <MobileFilterHolder title="Clase de Servicio" active={false}>
          {cabins.map(cabin => (
            <FilterCheckboxImageItem
              checked={filters.serviceClassFilter.includes(cabin.cabinCode)}
              onClick={handleClassFilter}
              value={cabin.cabinCode}
              key={cabin.cabinCode}
              name={getCabinName(cabin.cabinCode)}
            />
          ))}
        </MobileFilterHolder>
        <MobileFilterHolder title="Alianzas" active={false}>
          {alliances.map(({ alliance, total }, index) => (
            <FilterCheckboxImageItem
              key={index}
              checked
              // TODO
              onClick={handleAllianceFilter}
              name={alliance.name}
              imgAlt={alliance.name}
              imgSrc={alliance.image}
              badgeText={total}
            />
          ))}
        </MobileFilterHolder>
        <MobileNavBar>
          <div className="bottom-navbar">
            <Button iconLeft left onClick={close} context="purple">
              <Icon name="mv-arrow-left" color="white" size={24} />
            </Button>
            <Button right context="purple" onClick={close}>
              Aplicar
            </Button>
          </div>
        </MobileNavBar>
      </div>
    </div>
  );
};

MobileAirFilter.propTypes = {
  className: PropTypes.string.isRequired,
  close: PropTypes.func,
  show: PropTypes.bool,
  filtersData: PropTypes.object.isRequired,
  fareSliderDetails: PropTypes.object.isRequired,
  handleFareFilterChange: PropTypes.func.isRequired,
  handleClickLuggageFilter: PropTypes.func.isRequired,
  handleClickScalesFilter: PropTypes.func.isRequired,
  handleAirlineFilter: PropTypes.func.isRequired,
  handleAllianceFilter: PropTypes.func.isRequired,
  handleClassFilter: PropTypes.func.isRequired,
  handleNoAirportChange: PropTypes.func.isRequired,
  handleFlexFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};

export default styled(MobileAirFilter)`
  width: 100vw;
  height: 100vh;
  z-index: ${({ theme }) => theme.zIndex.modal};
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.grays.white};
  overflow-y: scroll;

  /* To account for bottom navbar */
  padding-bottom: 40px;

  .close {
    cursor: pointer;
    float: right;
    align-self: center;
  }

  .topBar {
    width: 100%;
    height: 62px;
    background-color: ${COLORS.white};
    padding-left: 13px;
    padding-right: 13px;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0.5px 0.5px 1.5px 0 rgba(0, 0, 0, 0.25);
  }

  .Footer {
    width: 100%;
    height: 71px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.golf};
    display: flex;
  }

  .radio-badge {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .checkbox {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .slider {
    margin-bottom: 8px;
  }

  .label-holder {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .bottom-navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;
