/**
 *
 * RatingFilterOption
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from '../Text';

const RatingFilterOption = ({ className, active, value, onClick }) => (
  <div className={cs(className, { active })}>
    <div onClick={onClick(value)}>
      <Text context="white" weight="semi-bold">
        + {value}
      </Text>
    </div>
  </div>
);

RatingFilterOption.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  value: PropTypes.number.isRequired
};

export default styled(RatingFilterOption)`
  border-radius: 4px;
  padding: 0 5px;
  width: 44px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.lightGrey};
  cursor: pointer;

  &.active {
    background-color: ${COLORS.important};
  }
`;
