import ACTION_TYPES from './actionTypes';
import {
  initialState,
  initialRoom,
  statusCodes,
  viewingCodes
} from './constants';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.HOTELS_SEARCH_SUCCESS: {
      const {
        searchFormRequest,
        searchId,
        sessionId,
        payload,
        filtersData
      } = action.payload;
      const {
        placeName,
        placeId,
        checkIn,
        roomInformation,
        checkOut,
        allowedPets,
        placeIdGeoLocation,
        radiusInMeters
      } = searchFormRequest;
      return {
        ...state,
        search: {
          ...state.search,
          location: {
            description: placeName,
            placeId: placeId
          },
          checkIn,
          checkOut,
          roomInformation,
          allowedPets,
          searchId,
          sessionId,
          placeIdGeoLocation,
          radiusInMeters,
          isEmpty: {
            dates: false,
            rooms: false
          }
        },
        status: statusCodes.READY,
        results: payload,
        filtersData: filtersData,
        viewing: viewingCodes.AVAILABILITY
      };
    }

    case ACTION_TYPES.HOTELS_PROPERTY_REQUEST:
    case ACTION_TYPES.HOTELS_SEARCH_REQUEST:
      return {
        ...state,
        error: null,
        status: statusCodes.LOADING,
        search: {
          ...state.search,
          detailMode: true
        }
      };

    case ACTION_TYPES.HOTELS_PROPERTY_FAILURE:
    case ACTION_TYPES.HOTELS_SEARCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: statusCodes.ERROR,
        search: {
          ...state.search,
          detailMode: false
        }
      };

    case ACTION_TYPES.HOTELS_PROPERTY_SUCCESS: {
      const { detailRequest, payload } = action.payload;
      const {
        sessionId,
        checkIn,
        checkOut,
        placeId,
        placeName,
        placeIdGeoLocation,
        roomInformation
      } = detailRequest;
      return {
        ...state,
        search: {
          ...state.search,
          location: {
            description: placeName,
            placeId
          },
          checkIn,
          checkOut,
          sessionId,
          placeIdGeoLocation,
          roomInformation
        },
        property: payload,
        status: statusCodes.READY,
        viewing: viewingCodes.PROPERTY
      };
    }

    case ACTION_TYPES.HOTELS_SET_DESTINATION:
      return {
        ...state,
        search: {
          ...state.search,
          location: action.payload.location,
          suggestions: {
            ...state.search.suggestions,
            pending: action.payload.pending,
            openMenu: action.payload.openMenu
          }
        }
      };

    case ACTION_TYPES.HOTELS_SET_CHECKIN:
      return {
        ...state,
        search: {
          ...state.search,
          checkIn: action.payload,
          isEmpty: {
            ...state.search.isEmpty,
            dates: false
          }
        }
      };

    case ACTION_TYPES.HOTELS_SET_CHECKOUT:
      return {
        ...state,
        search: {
          ...state.search,
          checkOut: action.payload,
          isEmpty: {
            ...state.search.isEmpty,
            dates: false
          }
        }
      };

    case ACTION_TYPES.HOTELS_ADD_ROOM:
      return {
        ...state,
        search: {
          ...state.search,
          roomInformation: [...state.search.roomInformation, initialRoom],
          isEmpty: {
            ...state.search.isEmpty,
            rooms: false
          }
        }
      };

    case ACTION_TYPES.HOTELS_REMOVE_ROOM:
      return {
        ...state,
        search: {
          ...state.search,
          roomInformation: state.search.roomInformation.slice(0, -1),
          isEmpty: {
            ...state.search.isEmpty,
            rooms: false
          }
        }
      };

    case ACTION_TYPES.HOTELS_CHANGE_ADULTS:
      return {
        ...state,
        search: {
          ...state.search,
          roomInformation: state.search.roomInformation.map((item, index) => {
            if (index !== action.index) {
              return item;
            }
            return {
              ...item,
              adultsCount: action.payload
            };
          }),
          isEmpty: {
            ...state.search.isEmpty,
            rooms: false
          }
        }
      };

    case ACTION_TYPES.HOTELS_CHANGE_CHILDREN:
      return {
        ...state,
        search: {
          ...state.search,
          roomInformation: state.search.roomInformation.map((item, index) => {
            if (index !== action.index) {
              return item;
            }
            return {
              ...item,
              kidsAges: action.payload
            };
          }),
          isEmpty: {
            ...state.search.isEmpty,
            rooms: false
          }
        }
      };

    case ACTION_TYPES.HOTELS_SET_ALLOW_PETS:
      return {
        ...state,
        search: {
          ...state.search,
          allowedPets: action.payload
        }
      };

    case ACTION_TYPES.HOTELS_SUGGESTIONS_REQUEST:
    case ACTION_TYPES.HOTELS_SUGGESTIONS_FAILURE:
    case ACTION_TYPES.HOTELS_SUGGESTIONS_SUCCESS:
    case ACTION_TYPES.HOTELS_SUGGESTIONS_CLOSE_MENU:
      return {
        ...state,
        search: {
          ...state.search,
          suggestions: {
            ...state.search.suggestions,
            ...action.payload
          }
        }
      };

    case ACTION_TYPES.HOTELS_VALIDATION_FAILURE:
      return {
        ...state,
        search: {
          ...state.search,
          validations: action.payload.validations
        }
      };

    case ACTION_TYPES.HOTELS_TOGGLE_MAP:
      return {
        ...state,
        search: {
          ...state.search,
          showMap: !state.search.showMap
        }
      };

    case ACTION_TYPES.HOTELS_CLEAN_ERROR:
      return {
        ...state,
        error: null
      };

    case ACTION_TYPES.HOTELS_SET_DETAIL_MODE:
      return {
        ...state,
        search: {
          ...state.search,
          detailMode: action.payload
        }
      };

    case ACTION_TYPES.HOTELS_RESET_ENGINE:
      return initialState;

    case ACTION_TYPES.HOTELS_CHECKRATE_REQUEST:
      return {
        ...state,
        status: statusCodes.LOADING
      };

    case ACTION_TYPES.HOTELS_CHECKRATE_FAILURE: {
      const { ratePackagesRooms } = state.property;
      const filteredRatePackages = ratePackagesRooms.map(room => ({
        ...room,
        packages: room.packages.filter(p => p.id !== action.payload)
      }));
      return {
        ...state,
        status: statusCodes.READY,
        checkRateError: 'Lo sentimos pero no pudimos resevar su selección',
        property: {
          ...state.property,
          ratePackagesRooms: filteredRatePackages
        }
      };
    }

    case ACTION_TYPES.HOTELS_CHECKRATE_CLEAN_ERROR: {
      return {
        ...state,
        checkRateError: null
      };
    }

    default:
      return state;
  }
};
