/**
 *
 * MobilePaxDetailAirCard
 *
 */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from 'Components/Text';
import { classItems } from 'Constants/mocks';
import * as COLORS from 'Constants/colors';
import useOutsideClick from '../../../../Utils/hooks/useOutsideClick';
import cs from 'classnames';
import Modal from 'Components/Modal';
import MobilePaxDetailAir from '../MobilePaxDetailAir';

const MobilePaxDetailAirCard = ({ className, adults, kids, cabinCode }) => {
  const [openModal, setOpenModal] = useState(false);
  const refOpenModal = useRef();

  useOutsideClick(refOpenModal, () => {
    if (openModal) setOpenModal(false);
  });

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const totalPassengers = adults + kids.length;

  return (
    <div className={cs(className)}>
      <div className="container-pax" onClick={toggleModal}>
        <Text
          weight="semi-bold"
          className={cs(
            'header',
            { sm: totalPassengers > 0 },
            { lg: totalPassengers === 0 }
          )}>
          PASAJEROS Y CLASE
        </Text>

        <Text
          context="large"
          className={cs(
            'passengers',
            { darkGrey: totalPassengers === 0 },
            { 'f-style-normal': totalPassengers === 0 },
            { important: totalPassengers > 0 },
            { 'f-weight-500': totalPassengers > 0 }
          )}>
          {totalPassengers === 0
            ? '¿Quiénes viajan?'
            : totalPassengers > 1
              ? `${totalPassengers} Pasajeros`
              : '1 Pasajero'}
        </Text>
        {classItems && (
          <Text
            className={cs('class-of-service', { none: totalPassengers === 0 })}
            context="large">
            {cabinCode
              ? `, ${
                classItems.filter(classItem => classItem.id === cabinCode)[0]
                  .name
              }`
              : ''}
          </Text>
        )}
      </div>
      <Modal
        show={openModal}
        onClickOverlay={toggleModal}
        transparent
        isSecondModal>
        <MobilePaxDetailAir onClose={toggleModal} />
      </Modal>
    </div>
  );
};

MobilePaxDetailAirCard.propTypes = {
  className: PropTypes.string,
  adults: PropTypes.number.isRequired,
  kids: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      seat: PropTypes.bool
    })
  ),
  cabinCode: PropTypes.string.isRequired
};
export default styled(MobilePaxDetailAirCard)`
  display: block;
  background-color: ${COLORS.white};
  width: 48%;

  .container-pax {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 130px;
    border-radius: 14px;
    box-shadow: ${COLORS.shadows.one};
    background-color: ${COLORS.white};
  }

  .header {
    width: 145px;
    color: ${COLORS.darkGrey};
    text-align: center;

    &.sm {
      height: 16px;
      font-size: 12px;
    }

    &.lg {
      height: 44px;
      font-size: 16px;
    }
  }

  .passengers {
    width: 145px;
    height: 20px;
    text-align: center;

    &.f-weight-500 {
      font-weight: 500;
    }

    &.f-style-normal {
      font-style: italic;
    }

    &.darkGrey {
      color: ${COLORS.darkGrey};
      font-size: 14px;
    }
  }

  .class-of-service {
    display: block;
    width: 145px;
    height: 20px;
    color: ${COLORS.darkGrey};
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.none {
      display: none;
    }
  }
`;
