/**
 *
 * MobileMpcFlightDetails
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import Button from 'Components/Button';
import FullPriceDetail from 'Components/FullPriceDetail';
import SectionTitle from 'Components/SectionTitle';
import MobileNavBar from 'Components/MobileNavBar';
import MobileNavBarPrice from 'Components/MobileNavBarPrice';
import FlightItineraryDetails from '../FlightItineraryDetails';
import { getFormatPrice } from '../../../../Utils';

const MobileMpcFlightDetails = ({
  className,
  close,
  handleShowAllFlights,
  generalSegment,
  flightPrice,
  localPrice,
  initialDate,
  endDate,
  onClick
}) => {
  const ida = generalSegment[0];
  const vuelta = generalSegment[1] ? generalSegment[1] : false;
  return (
    <>
      <div className={cs(className)}>
        <div className="top-bar">
          <Text size="large">Detalle y precios del vuelo</Text>
          <span className="top-bar-close" onClick={close}>
            <Icon name="mv-cross" size={24} />
          </span>
        </div>
        <div className="btn-information">
          <Button
            context="information"
            onClick={() => handleShowAllFlights({ initialDate, endDate })}>
            <Icon name="mv-list" color="white" />
            Ver más vuelos para mis fechas
          </Button>
        </div>

        <SectionTitle title="DETALLE DE IDA" noMargin centered />
        <div className="content no-padding">{FlightDetails(ida[0])}</div>
        {vuelta ? (
          <>
            <SectionTitle title="DETALLE DE VUELTA" noMargin centered />
            <div className="content no-padding">{FlightDetails(vuelta[0])}</div>
          </>
        ) : null}
        <SectionTitle title="DETALLE DE LOS PRECIOS" noMargin centered />
        <div className="content">
          <FullPriceDetail
            className="prices"
            pricePassengerDetail={flightPrice.pricePassengerDetail}
            pricePassengerDetailLocal={
              localPrice && localPrice.pricePassengerDetail
            }
          />
          <div className="dollar">
            <Text context="important" weight="bold" size="large">
              TOTAL
            </Text>
            <Text context="important" weight="bold" size="large">
              {getFormatPrice(
                flightPrice.totalPrice,
                flightPrice.breakdown[0].currency
              )}
            </Text>
          </div>
          {localPrice && (
            <div className="local">
              <Text context="important" weight="bold" size="large">
                {getFormatPrice(
                  localPrice.totalPrice,
                  localPrice.breakdown[0].currency,
                  true
                )}
              </Text>
            </div>
          )}
        </div>
        <div className="filler"></div>
      </div>
      <MobileNavBar>
        <Button left context="purple" size="lg">
          <Icon name="mv-arrow-left" size={24} />
        </Button>
        <MobileNavBarPrice
          topText="Precio Total Final"
          middleText={getFormatPrice(
            flightPrice.totalPrice,
            flightPrice.breakdown[0].currency
          )}
          bottomText={
            localPrice &&
            getFormatPrice(
              localPrice.totalPrice,
              localPrice.breakdown[0].currency,
              true
            )
          }
        />
        <Button
          right
          onClick={() => {
            onClick({
              ida: { index: 0, item: ida[0] },
              vuelta: { index: 0, item: vuelta[0] }
            });
            close();
          }}>
          Seleccionar
        </Button>
      </MobileNavBar>
    </>
  );
};

MobileMpcFlightDetails.propTypes = {
  className: PropTypes.string.isRequired,
  generalSegment: PropTypes.array.isRequired,
  handleShowAllFlights: PropTypes.func,
  close: PropTypes.func,
  flightPrice: PropTypes.object.isRequired,
  localPrice: PropTypes.object.isRequired,
  endDate: PropTypes.string,
  initialDate: PropTypes.string,
  onClick: PropTypes.func
};

const FlightDetails = item => {
  const { baggage, duration, scales, segments } = item;
  return (
    <FlightItineraryDetails
      baggage={baggage}
      duration={duration}
      scales={scales}
      segments={segments}
    />
  );
};

export default styled(MobileMpcFlightDetails)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${COLORS.white};
  height: fit-content;

  .top-bar {
    display: flex;
    width: 100%;
    height: 62px;
    background-color: ${COLORS.white};
    align-items: center;
    padding: 5px;
  }

  .top-bar-close {
    display: flex;
    position: absolute;
    width: 10%;
    height: 62px;
    right: 0;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    color: ${COLORS.lightGrey};
    float: right;

    .icon {
      display: flex;
      align-items: center;
    }
  }

  .btn-information {
    .icon {
      margin-right: 5px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 100%;
    background-color: ${COLORS.white};

    &.no-padding {
      padding: 0;
    }

    .dollar {
      width: 100%;
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      padding: 0 34px;
    }

    .local {
      width: 100%;
      flex: 1 1 auto;
      text-align: right;
      display: flex;
      padding: 0 34px;
      flex-direction: row-reverse;
    }
  }

  .prices {
    width: 100%;
  }

  .filler {
    height: 72px;
  }
`;
