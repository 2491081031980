/**
 *
 * Well
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import {
  information,
  positive,
  arena,
  important,
  white,
  warning,
  violeta
} from 'Constants/colors';

const Well = ({ className, children, context }) => (
  <div className={cs(className, context)}>{children}</div>
);

Well.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.any,
  context: PropTypes.string,
  background: PropTypes.string
};

export default styled(Well)`
  border-radius: 14px;
  padding: 19px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: center;
  display: table;
  margin: 0 auto;
  position: relative;
  background-color: ${arena};
  color: ${important};

  &.positive {
    background-color: ${positive};
    color: ${white};
  }

  &.information {
    background-color: ${information};
    color: ${white};
  }

  &.warning {
    background-color: ${warning};
    color: ${white};
  }

  &.violeta {
    background-color: ${violeta};
    color: ${white};
  }
`;
