import { IProduct } from 'Features/travelInsurance/components/ProductCard';

export const productos: IProduct[] = [
  {
    id: 'a',
    title: '40K',
    details: [
      'USD 40.000 de cobertura global por accidente o enfermedad no preexistente',
      'USD 400 de cobertura global por accidente o enfermedad preexistente',
      '800 USD por cancelación de viaje',
      'Hasta USD 1.200 de compensación por pérdida de equipaje',
      'Este plan cubre asistencia médica por Covid-19 hasta los 75 años inclusive'
    ],
    price: 'USD 4',
    priceTime: 'Por día',
    termsUrl:
      'https://mevuelo-storage-production.s3.sa-east-1.amazonaws.com/pdfs/40k+mevuelocom.pdf'
  },
  {
    id: 'b',
    title: '40K preex',
    details: [
      'USD 40.000 de cobertura global por accidente o enfermedad no preexistente',
      'USD 7.000 de cobertura global por accidente o enfermedad preexistente',
      '800 USD por cancelación de viaje',
      'Hasta USD 1.200 de compensación por pérdida de equipaje',
      'Este plan cubre asistencia médica por Covid-19 hasta los 75 años inclusive'
    ],
    price: 'USD 5',
    priceTime: 'Por día',
    termsUrl:
      'https://mevuelo-storage-production.s3.sa-east-1.amazonaws.com/pdfs/40k+preex+mevuelocom.pdf'
  },
  {
    id: 'c',
    title: '60K preex',
    details: [
      'USD 60.000 de cobertura global por accidente o enfermedad no preexistente',
      'USD 7.000 de cobertura global por accidente o enfermedad preexistente',
      '1.000 USD por cancelación de viaje',
      'Hasta USD 1.200 de compensación por pérdida de equipaje',
      'Este plan cubre asistencia médica por Covid-19 hasta los 75 años inclusive'
    ],
    price: 'USD 6',
    priceTime: 'Por día',
    termsUrl:
      'https://mevuelo-storage-production.s3.sa-east-1.amazonaws.com/pdfs/60k+preex+mevuelocom.pdf'
  },
  {
    id: 'd',
    bestOption: true,
    title: 'Viajeros',
    details: [
      'USD 80.000 de cobertura global por accidente o enfermedad no preexistente',
      'USD 15.000 de cobertura global por accidente o enfermedad preexistente',
      '2.000 USD por cancelación de viaje',
      'Hasta USD 1.200 de compensación por pérdida de equipaje',
      'Este plan cubre asistencia médica por Covid-19 hasta los 75 años inclusive'
    ],
    price: 'USD 8',
    priceTime: 'Por día',
    termsUrl:
      'https://mevuelo-storage-production.s3.sa-east-1.amazonaws.com/pdfs/viajeros+mevuelocom.pdf'
  },
  {
    id: 'f',
    title: 'Plus preex 30',
    details: [
      'USD 30.000 de cobertura global por accidente o enfermedad no preexistente',
      'USD 30.000 de cobertura global por accidente o enfermedad preexistente',
      '1.000 USD por cancelación de viaje',
      'Hasta USD 1.200 de compensación por pérdida de equipaje',
      'Este plan cubre asistencia médica por Covid-19 hasta los 75 años inclusive'
    ],
    price: 'USD 9',
    priceTime: 'Por día',
    termsUrl:
      'https://mevuelo-storage-production.s3.sa-east-1.amazonaws.com/pdfs/plus+preex+30+mundial+mevuelocom.pdf'
  },
  {
    id: 'g',
    title: 'Plus preex 100',
    details: [
      'USD 10.000 de cobertura global por accidente o enfermedad no preexistente',
      'USD 100.000 de cobertura global por accidente o enfermedad preexistente',
      '1.000 USD por cancelación de viaje',
      'Hasta USD 1.200 de compensación por pérdida de equipaje',
      'Este plan cubre asistencia médica por Covid-19 hasta los 75 años inclusive'
    ],
    price: 'USD 23',
    priceTime: 'Por día',
    termsUrl:
      'https://mevuelo-storage-production.s3.sa-east-1.amazonaws.com/pdfs/100k+preex+mevuelocom.pdf'
  },
  {
    id: 'h',
    title: 'Multiviajes 30',
    details: [
      'USD 50.000 de cobertura global por accidente o enfermedad no preexistente',
      'USD 400 de cobertura global por accidente o enfermedad preexistente',
      '1.000 USD por cancelación de viaje',
      'Hasta USD 1.200 de compensación por pérdida de equipaje',
      'Este plan cubre asistencia médica por Covid-19 hasta los 75 años inclusive'
    ],
    price: 'USD 220',
    priceTime: 'Total 30 días',
    termsUrl:
      'https://mevuelo-storage-production.s3.sa-east-1.amazonaws.com/pdfs/multiviajes+30+mevuelocom.pdf'
  },
  {
    id: 'i',
    title: 'Plus preex 30 Anual',
    details: [
      'USD 100.000 de cobertura global por accidente o enfermedad no preexistente',
      'USD 30.000 de cobertura global por accidente o enfermedad preexistente',
      '1.000 USD por cancelación de viaje',
      'Hasta USD 1.200 de compensación por pérdida de equipaje',
      'Este plan cubre asistencia médica por Covid-19 hasta los 75 años inclusive'
    ],
    price: 'USD 300',
    priceTime: 'Total 30 días',
    termsUrl:
      'https://mevuelo-storage-production.s3.sa-east-1.amazonaws.com/pdfs/plus30+preex+anual+mevuelocom.pdf'
  }
];
