import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import getDestinationQuery from 'Features/destinations/graphql/queries/getDestination';
import {
  getDestination,
  packagesByDestinationRequest
} from 'Features/destinations';
import DestinationsHome from './DestinationsHome';
import { RootState } from 'ReduxConfig/store';
import Loader from 'Features/app/components/Loader';
import { routes } from 'Constants';
import { setActive, setCurrentView } from 'Features/destinations/actions';
import { setCityKeyword, setDestination } from 'Features/flights';
import { defaultPackageOrigin } from '../../../../Constants';

interface DestinatonsRouteParams {
  destination?: string;
}

const selector = ({ destinations }: RootState) => ({
  bgUrl: destinations.data?.background?.publicUrl,
  status: destinations.status,
  destinationCode: destinations.data?.iata,
  name: destinations.data?.name,
  packagesByDestinationResult: destinations.packagesByDestinationResult
});

const destinationSlugToReplace = new Map<string, string>([
  ['medellin', 'medellín']
]);

const ConnectedDestinationsHome = () => {
  const { destination } = useParams<DestinatonsRouteParams>();
  const needToReplaceDestinationSlug = destinationSlugToReplace.has(
    destination
  );

  const dispatch = useDispatch();
  const {
    bgUrl,
    status,
    destinationCode,
    name,
    packagesByDestinationResult
  } = useSelector(selector);
  const query = getDestinationQuery(destination);

  useEffect(() => {
    if (!needToReplaceDestinationSlug) {
      dispatch(setActive(true));
      dispatch(getDestination(query));
    }
  }, [destination, query.query, dispatch]);

  useEffect(() => {
    if (!needToReplaceDestinationSlug) {
      if (destinationCode) {
        dispatch(setCityKeyword(destination));
        dispatch(setDestination(0, destinationCode, name));
        dispatch(
          packagesByDestinationRequest(defaultPackageOrigin, destinationCode)
        );
      }
    }
  }, [destinationCode, destination, name]);

  useEffect(() => {
    dispatch(setCurrentView(true));
    return () => {
      dispatch(setCurrentView(false));
    };
  }, []);
  useEffect(() => {
    if (needToReplaceDestinationSlug) {
      return window.location.replace(
        routes.destination.replace(
          ':destination',
          destinationSlugToReplace.get(destination) || ''
        )
      );
    }
  }, []);

  if (status === 'loading' || status === 'unused') {
    return <Loader />;
  }

  if (status === 'ready') {
    return (
      <DestinationsHome
        bgUrl={bgUrl}
        destination={destination}
        packagesByDestination={packagesByDestinationResult}
      />
    );
  }
  if (status === 'failure') {
    return <Redirect to={routes.home} />;
  }
};

export default ConnectedDestinationsHome;
