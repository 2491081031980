/**
 *
 * MpcItemResearch
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import spinner from 'Assets/loader/spinner.svg';
import Icon from 'Components/Icon';

const MpcItemResearch = ({
  className,
  detailItem,
  row,
  col,
  state,
  actions
}) => {
  const { onResearchMptb } = actions;
  const {
    routesData,
    totalAdult,
    totalChildren,
    totalInfant,
    totalInfantWithSeat,
    filterType,
    cityKeyword
  } = state;

  const [isLoading, setIsLoading] = useState(false);

  if (isLoading)
    return (
      <div className={cs(className, 'LOADING')}>
        <div className="content-spinner">
          <img alt="spinner" src={spinner} />
          {isLoading}
        </div>
      </div>
    );

  const handleResearch = () => {
    setIsLoading(true);
    const routes = routesData;
    const newRoutes = routes.map(v => ({
      ...v,
      initialDate: detailItem.departureDate,
      endDate: detailItem.arrivalDate
    }));
    const newAmadeousRequest = {
      routesData: newRoutes,
      totalAdult,
      totalChildren,
      totalInfant,
      totalInfantWithSeat,
      filterType,
      cityKeyword
    };
    onResearchMptb(newAmadeousRequest, row, col);
  };

  return (
    <div className={cs(className, 'RESEARCH')} onClick={handleResearch}>
      <div className="content-icon">
        <Icon name="mv-lense" size={24} color="important" />
      </div>
    </div>
  );
};

MpcItemResearch.propTypes = {
  className: PropTypes.string.isRequired,
  detailItem: PropTypes.object.isRequired,
  row: PropTypes.number.isRequired,
  col: PropTypes.number.isRequired,
  actions: PropTypes.object,
  state: PropTypes.object
};

export default styled(MpcItemResearch)`
  background-color: ${COLORS.white};
  color: ${COLORS.important};
  display: flex;
  width: 151px;
  height: 91px;
  border: solid 1px ${COLORS.important};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;

  .content-spinner {
    img {
      width: 24px;
      height: 24px;
    }
  }

  @media all and (max-width: 768px) {
    height: 42px;
    width: 14.28%;

    .content-icon {
      height: 24 px;
    }

    .content-spinner {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
`;
