/**
 *
 * Divider
 *
 */

import React from 'react';
import styled from 'styled-components';

import { DividerProps } from './types';
import Heading from 'Components/Heading';

const BorderBox = styled.div`
  display: inline-block;
  border-bottom: 4px solid ${({ theme }) => theme.colors.orange};
  padding-bottom: 4px;
  margin-bottom: 20px;
`;

const Divider = ({ titleText, subtitleText }: DividerProps) => (
  <BorderBox>
    {titleText && (
      <Heading type="h3" color="white" fontWeight="semibold">
        {titleText}
      </Heading>
    )}
    {titleText && subtitleText && (
      <Heading type="h4" color="white" fontWeight="light">
        {subtitleText}
      </Heading>
    )}
  </BorderBox>
);

export default Divider;
