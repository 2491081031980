/**
 *
 * ButtonCardPassenger
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import pss from '../../Assets/icons/avatar-52-px-copia-3.svg';
import ButtonCardThumbnail from '../ButtonCardThumbnail';
import Text from '../Text';

const ButtonCardPassenger = ({
  className,
  onClick,
  firstNames,
  lastNames,
  documentName,
  document,
  countryName
}) => (
  <ButtonCardThumbnail
    className={className}
    onClick={onClick}
    imgAlt="passenger"
    imgSrc={pss}>
    <div className="info">
      <Text size="large">
        {firstNames}
        <br />
        {lastNames}
      </Text>
      <Text size="small" context="information">
        Titular de la reserva
      </Text>
      <Text>
        {documentName} {countryName} <br />
        {document}
      </Text>
    </div>
  </ButtonCardThumbnail>
);

ButtonCardPassenger.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  documentName: PropTypes.string,
  document: PropTypes.string,
  countryName: PropTypes.string,
  firstNames: PropTypes.string,
  lastNames: PropTypes.string
};

export default styled(ButtonCardPassenger)`
  display: 'block';

  .info {
    text-align: left;
    display: flex;
    flex-direction: column;
  }
`;
