import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HotelSearchDialog from './HotelSearchDialog';
import { setDetailMode } from 'Features/hotels/actions';

const selector = ({ hotels }) => ({
  detailMode: hotels.search.detailMode,
  status: hotels.status
});

const ConnectedHotelSearchDialog = props => {
  const dispatch = useDispatch();
  const actions = {
    setDetailMode: value => {
      dispatch(setDetailMode(value));
    }
  };

  const state = useSelector(selector);

  return <HotelSearchDialog actions={actions} {...state} {...props} />;
};

export default ConnectedHotelSearchDialog;
export { HotelSearchDialog };
