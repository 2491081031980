// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Component

import CommentaryInput from './ComentaryInput';

// Actions
import { storageCorpoData } from 'ReduxConfig/checkoutCorpo';

// Constants
import { selector } from './CommentaryInput.selector';

const ConnectedCommentaryInput = props => {
  const state = useSelector(selector);
  const dispatch = useDispatch();
  const actions = {
    setCorpoData: data => dispatch(storageCorpoData(data))
  };

  return <CommentaryInput actions={actions} {...props} {...state} />;
};

export default ConnectedCommentaryInput;
