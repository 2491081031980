import { useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { CIBERSOURCE_URL } from '../../Constants';
import { toast } from 'react-toastify';

const selector = ({ checkout }) => checkout.paymentGateways.atcInitPaymentForm;
const AtcInitPayment = () => {
  const formAtcRef = useCallback(form => {
    if (
      form !== null &&
      form.submit &&
      typeof form.submit.tagName == 'string' &&
      form.submit.click
    ) {
      form.submit.click();
    } else {
      toast.error('No se pudo iniciar el formulario de pago');
    }
  }, []);
  const atcInitPaymentForm = useSelector(selector);
  return (
    <form
      ref={formAtcRef}
      noValidate
      id="payment_confirmation"
      action={CIBERSOURCE_URL}
      method="post">
      {atcInitPaymentForm.fieldsToSends.map(field => (
        <input
          type="hidden"
          key={field.name}
          id={field.name}
          name={field.name}
          value={field.value}></input>
      ))}
      <input
        type="submit"
        id="submit"
        value="Confirm"
        style={{ display: 'none' }}
      />
    </form>
  );
};
export default AtcInitPayment;
