/**
 *
 * MobileHotelDetails
 *
 */

import React, { Fragment, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Slider from 'react-slick';

import Text from 'Components/Text';
import Icon from 'Components/Icon';
import StarsFilter from 'Components/StarsFilter';
import Button from 'Components/Button';
import GMap from 'Components/GMap';
import MobileHotelRooms from '../MobileHotelRooms';
import MobileNavBar from 'Components/MobileNavBar';
import MobileNavBarPrice from 'Components/MobileNavBarPrice';
import Modal from 'Components/Modal';
import HotelServices from '../HotelServices';
import HotelsGoogleComments from '../HotelsGoogleComments';
import { ReactComponent as HotelIcon } from 'Assets/icons/mv-hotel-timeout.svg';

import * as COLORS from 'Constants/colors';
import { getFormatPrice } from 'Utils';
import ErrorPortal from 'Components/ErrorPortal';
import Badge from 'Components/Badge';

// I'm duplicating because I'm not sure how reusable is this
const shouldShowMore = descriptions => {
  let chars = 0;

  descriptions.forEach(d => {
    if (d.paragraph && d.title) {
      chars += d.paragraph.length + d.title.length;
    }
  });

  return chars > 350;
};

const sliderSettings = {
  infinite: false,
  dots: true,
  slidesToShow: 1,
  speed: 500,
  lazyLoad: false
};

const MobileHotelDetails = ({
  className,
  images,
  name,
  starRating,
  keyRating,
  textRating,
  checkIn,
  checkOut,
  descriptions,
  initialCenter,
  ratePackagesRooms,
  handleClickReservation,
  facilities,
  address,
  reviews,
  closeError,
  checkRateError
}) => {
  const [showServices, setShowServices] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [selected, setSelected] = useState(ratePackagesRooms[0].packages[0]);
  const sectionRef = useRef(null);

  const hasMoreDetails = useMemo(() => shouldShowMore(descriptions), [
    descriptions
  ]);

  const { facilitiesIcons } = facilities;

  const toggleServices = () => setShowServices(!showServices);
  const onShowMoreDetails = force => () => {
    setShowMoreDetails(force);

    if (!force && sectionRef) {
      const el =
        sectionRef.current.getBoundingClientRect().top + window.scrollY;

      window.scroll({
        top: el,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <ErrorPortal
        show={checkRateError}
        onClose={closeError}
        buttonLabel="Volver al Listado"
        icon={<HotelIcon />}
        message="Por favor, volvé al listado a buscar tu hotel."
        title="Lo sentimos, el hotel no nos ha devuelto disponibilidad para sus habitaciones."
      />
      <Modal show={showServices} transparent whiteBackground>
        <HotelServices
          images={images}
          facilitiesGroup={facilities.facilitiesGroup}
          name={name}
          close={toggleServices}
          mobile
        />
      </Modal>
      <div className={cs(className)}>
        <Slider {...sliderSettings}>
          {images.map((image, index) => (
            <img
              src={image.imageUrl}
              alt={image.description}
              key={index}
              className="slider-img"
            />
          ))}
        </Slider>
        <div className="hotel-header">
          <Text weight="semi-bold" size="large">
            {name}
          </Text>
          <div className="location">
            <Icon name="mv-location-2" color="important" size={20} />
            <Text>{address}</Text>
          </div>
          {starRating && <StarsFilter justDisplay initialValue={starRating} />}
          {keyRating && (
            <StarsFilter
              justDisplay
              initialValue={keyRating}
              isKey={keyRating}
            />
          )}
          {textRating && (
            <Badge variation="yellow" inverted>
              {textRating}
            </Badge>
          )}
        </div>
        <div className="section-header" ref={sectionRef}>
          <Text weight="semi-bold">Servicios</Text>
          <Button size="xs" onClick={toggleServices}>
            Ver todos
          </Button>
        </div>
        <div className="separator" />
        <div className="services">
          <Icon
            name="mv-wifi"
            size={24}
            color={facilitiesIcons.wifi ? 'important' : 'default'}
          />
          <Icon
            name="mv-car-parking"
            size={24}
            color={facilitiesIcons.parking ? 'important' : 'default'}
          />
          <Icon
            name="mv-swimming-pool"
            size={24}
            color={facilitiesIcons.pool ? 'important' : 'default'}
          />
          <Icon
            size={24}
            color={facilities.breakfast ? 'important' : 'default'}
            name="mv-breakfast"
          />

          <Icon
            name="mv-aircon"
            size={24}
            color={facilitiesIcons.airConditioning ? 'important' : 'default'}
          />
          <Icon
            name="mv-gym"
            size={24}
            color={facilitiesIcons.gym ? 'important' : 'default'}
          />
        </div>
        <div className="section-header">
          <Text weight="semi-bold">Horarios</Text>
        </div>
        <div className="separator" />
        <div className="column margin-horizontal">
          <div>
            <Text context="important" weight="semi-bold">
              Check In:&nbsp;
            </Text>
            <Text>a partir de las {checkIn}hs</Text>
          </div>
          <div>
            <Text context="important" weight="semi-bold">
              Check out:&nbsp;
            </Text>
            <Text>hasta las {checkOut}hs</Text>
          </div>
        </div>

        <div className="section-header">
          <div className="text-icon">
            <Icon name="mv-information-circle" size={20} color="information" />
            <Text weight="semi-bold">Información</Text>
          </div>
        </div>
        <div className="separator" />
        <div className="column margin-horizontal align-center hotel-info">
          <Text
            paragraph
            className={cs('hotel-descr', { 'show-more': showMoreDetails })}>
            {descriptions.map((desc, index) => (
              <Fragment key={index}>
                <b>{desc.title}</b>
                <br />
                <br />
                {desc.paragraph}
                <br />
                <br />
              </Fragment>
            ))}
          </Text>

          {hasMoreDetails && (
            <Button
              size="xs"
              onClick={onShowMoreDetails(!showMoreDetails)}
              className="mt-2">
              {showMoreDetails ? 'Ver menos' : 'Ver más'}
            </Button>
          )}
        </div>
        <div className="section-header">
          <div className="text-icon">
            <Icon name="mv-price-circle" size={20} color="important" />
            <Text weight="semi-bold">Tarifas y habitaciones</Text>
          </div>
        </div>
        <div className="separator" />

        <MobileHotelRooms
          rooms={ratePackagesRooms}
          onChange={setSelected}
          selected={selected}
        />

        {reviews && (
          <>
            <div className="section-header">
              <div className="text-icon">
                <Icon name="mv-globo" size={20} color="secondary" />
                <Text weight="semi-bold">Valoración de viajeros</Text>
              </div>
            </div>

            <div className="separator" />

            <HotelsGoogleComments reviews={reviews}></HotelsGoogleComments>
          </>
        )}

        <div className="section-header">
          <div className="text-icon">
            <Icon name="mv-location-1" size={20} color="secondary" />
            <Text weight="semi-bold">Mapa</Text>
          </div>
        </div>

        <div className="separator" />

        <GMap initialCenter={initialCenter} markerName={name} />

        <MobileNavBar>
          <Button left iconLeft context="purple" disabled>
            <Icon name="mv-arrow-left" color="white" size={24} />
          </Button>

          {selected && (
            <>
              <MobileNavBarPrice
                topText="Precio Total"
                middleText={getFormatPrice(
                  selected.packagePrice.finalPrice,
                  selected.packagePrice.currency
                )}
                bottomText={`${getFormatPrice(
                  selected.packagePrice.finalPricePerNight,
                  selected.packagePrice.currency
                )} por noche`}
              />
              <Button
                right
                context="purple"
                onClick={() => handleClickReservation(selected)}>
                Continuar
              </Button>
            </>
          )}
        </MobileNavBar>
      </div>
    </>
  );
};

MobileHotelDetails.propTypes = {
  className: PropTypes.string.isRequired,
  images: PropTypes.array,
  name: PropTypes.string,
  starRating: PropTypes.number,
  keyRating: PropTypes.number,
  textRating: PropTypes.string,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  descriptions: PropTypes.array,
  initialCenter: PropTypes.object,
  ratePackagesRooms: PropTypes.array,
  handleClickReservation: PropTypes.func,
  facilities: PropTypes.array,
  address: PropTypes.string,
  reviews: PropTypes.shape({
    amountReviews: PropTypes.number,
    rating: PropTypes.number,
    ratingText: PropTypes.string,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        author_name: PropTypes.string,
        author_url: PropTypes.string,
        id: PropTypes.string,
        language: PropTypes.string,
        profile_photo_url: PropTypes.string,
        rating: PropTypes.number,
        ratingText: PropTypes.string,
        relative_time_description: PropTypes.string,
        text: PropTypes.string,
        time: PropTypes.number
      })
    )
  }).isRequired,
  closeError: PropTypes.func,
  checkRateError: PropTypes.string,
  accommodationCode: PropTypes.string
};

export default styled(MobileHotelDetails)`
  display: block;
  background-color: ${COLORS.white};
  overflow: hidden;

  .services {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-around;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .hotel-header {
    padding: 5px 20px;

    .location {
      display: flex;
      margin-bottom: 5px;
      margin-top: 5px;

      .icon {
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;

    &.align-center {
      align-items: center;
    }
  }

  .margin-horizontal {
    margin-left: 20px;
    margin-right: 20px;
  }

  .slick-dots {
    bottom: 20px;
  }

  .slider-img {
    max-height: 230px;
    object-fit: cover;
  }

  .slick-dots li button::before {
    font-family: Montserrat, serif;
    content: '–';
    font-weight: 900;
    font-size: 35px;
    color: ${COLORS.lightGrey};
    opacity: 0.7;
  }

  .slick-dots li:nth-child(n + 7) {
    display: none;
  }

  .slick-dots li.slick-active button::before {
    color: ${COLORS.white};
    opacity: 1;
  }

  .section-header {
    margin: 10px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text-icon {
      display: flex;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
      }
    }
  }

  .hotel-info {
    .hotel-descr {
      max-height: 200px;
      transition: all 1s ease-in-out;
      overflow: hidden;

      &.show-more {
        display: block;
        max-height: 8000px;
      }
    }
  }

  .separator {
    height: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.greyBkg};
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
  }
`;
