import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { confirmPaymentStatus } from '../../ReduxConfig/checkout';
import BancardProcessPayment from 'Containers/ProcessPayment/Bancard';
import WompiProcessPayment from 'Containers/ProcessPayment/Wompi';
import ErrorPortal from 'Components/ErrorPortal';

const ProcessPayment = ({ match, location }) => {
  const [response, setResponse] = useState({});

  const dispatch = useDispatch();

  // TODO: MAKE ENGINE THING DYNAMIC
  useEffect(() => {
    dispatch(confirmPaymentStatus(6, match.params.processId)).then(res =>
      setResponse(res)
    );
  }, []);
  if (process.env.REACT_APP_BANCARD === 'On') {
    return <BancardProcessPayment match={match} location={location} />;
  } else if (process.env.REACT_APP_WOMPI_STATUS === 'On') {
    return <WompiProcessPayment match={match}/>;
  } else {
    return <ErrorPortal />;
  }
};

ProcessPayment.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default ProcessPayment;
