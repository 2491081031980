import API from '../Services';
import ACTION_TYPES from './actionTypes';

// Reducer
const initialState = {
  citizen: [],
  countries: [],
  pending: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CITIZEN_REQUEST:
      return {
        ...state,
        pending: true
      };

    case ACTION_TYPES.CITIZEN_SUCCESS:
      return {
        ...state,
        pending: false,
        citizen: action.payload
      };

    case ACTION_TYPES.CITIZEN_FAILURE:
      return {
        ...state,
        pending: false
      };

    case ACTION_TYPES.COUNTRIES_REQUEST:
      return {
        pending: true,
        ...state
      };

    case ACTION_TYPES.COUNTRIES_SUCCESS:
      return {
        ...state,
        pending: false,
        countries: action.payload
      };

    case ACTION_TYPES.COUNTRIES_FAILURE:
      return {
        ...state,
        pending: false
      };

    default:
      return state;
  }
};

// Action
export const getCitizens = params => async dispatch => {
  try {
    dispatch(getCitizensRequest());

    const res = await API.places.getCitizens(params);

    dispatch(getCitizensSuccess(res.data));
  } catch (error) {
    dispatch(getCitizensFailure());

    throw error;
  }
};

// Actions creators
const getCitizensRequest = () => ({
  type: ACTION_TYPES.CITIZEN_REQUEST
});

const getCitizensSuccess = payload => ({
  type: ACTION_TYPES.CITIZEN_SUCCESS,
  payload
});

const getCitizensFailure = () => ({
  type: ACTION_TYPES.CITIZEN_FAILURE
});

// Action
export const getCountries = params => async dispatch => {
  try {
    dispatch(getCountriesRequest());

    const res = await API.places.getCountries(params);

    dispatch(getCountriesSuccess(res.data));
  } catch (error) {
    dispatch(getCountriesFailure());

    throw error;
  }
};

// Actions creators
const getCountriesRequest = () => ({
  type: ACTION_TYPES.COUNTRIES_REQUEST
});

const getCountriesSuccess = payload => ({
  type: ACTION_TYPES.COUNTRIES_SUCCESS,
  payload
});

const getCountriesFailure = () => ({
  type: ACTION_TYPES.COUNTRIES_FAILURE
});
