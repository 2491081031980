// Vendors
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styled from 'styled-components';

// Components
import Card from 'Components/Card';
import Select from 'Components/Select';
import CreditCard from 'Components/CreditCard';
import Banks from 'Components/Banks';
import Networks from 'Components/Networks';
import BranchOffices from 'Components/BranchOffices';
import ButtonCard from 'Components/ButtonCard';
import Icon from 'Components/Icon';
import Text from 'Components/Text';

// Constants
import { BANCARD_STATUS, SIEMPRE_PAGO_STATUS } from 'Constants';

// Utils
import useBreakpoints from 'Utils/hooks/useBreakpoints';

const PaymentMethod = memo(
  ({
    paymentTypes,
    offices,
    banks,
    cards,
    networks,
    actions,
    engine,
    userData,
    className,
    onValid
  }) => {
    const breakpoints = useBreakpoints();
    const isGateway =
      (BANCARD_STATUS || SIEMPRE_PAGO_STATUS) &&
      (engine === 'hotel-details' || engine === 'hoteles');

    const handleUserData = data => {
      actions.setUserData({
        [userData.paymentMethod.payment]: {
          ...userData[userData.paymentMethod.payment],
          ...data
        }
      });
    };

    return (
      <div className={className}>
        <Card
          borderRadius="square"
          header="¿Como querés pagar?"
          className={cs('pay-container', { selected: userData.paymentMethod })}
          headerCentered>
          <div className="pay-methods">
            {!breakpoints.mediumDown && (
              <Select
                selectedValue={userData.paymentMethod.id}
                items={paymentTypes || []}
                onSelect={payment =>
                  actions.setUserData({ paymentMethod: payment })
                }
                fullWidth
              />
            )}

            {breakpoints.mediumDown &&
              paymentTypes.map(p => (
                <ButtonCard
                  className="button"
                  onClick={() => actions.setUserData({ paymentMethod: p })}
                  key={p.id}>
                  <Icon
                    color="important"
                    size={26}
                    name={cs(
                      p.payment === 'creditCardPayment' && 'mv-creditcard',
                      p.payment === 'wireTransferPayment' && 'university',
                      p.payment === 'branchOffices' && 'mv-flight-voucher',
                      p.payment === 'paymentNetwork' && 'mv-price-circle'
                    )}
                  />

                  <Text context="important" size="large" weight="bold">
                    {p.name}
                  </Text>
                </ButtonCard>
              ))}
          </div>
        </Card>

        {userData.paymentMethod.payment === 'creditCardPayment' && (
          <CreditCard
            onSubmit={handleUserData}
            cardInfo={userData.creditCardPayment}
            cards={cards}
            isGateway={isGateway}
            onValid={onValid}
          />
        )}

        {userData.paymentMethod.payment === 'wireTransferPayment' && (
          <Card
            borderRadius="square"
            header="¿Cuál es tu banco?"
            headerCentered>
            <Banks
              onSubmit={handleUserData}
              selectedBank={userData.wireTransferPayment}
              banks={banks}
              onValid={onValid}
            />
          </Card>
        )}

        {userData.paymentMethod.payment === 'paymentNetwork' && (
          <Card
            borderRadius="square"
            header="¿Cuál es tu Red de Pagos?"
            headerCentered>
            <Networks
              networks={networks}
              onSubmit={handleUserData}
              onValid={onValid}
              selectedNetwork={userData.paymentNetwork}
            />
          </Card>
        )}

        {userData.paymentMethod.payment === 'branchOffices' && (
          <Card borderRadius="square" header="Elegí la sucursal" headerCentered>
            <BranchOffices
              offices={offices}
              onValid={onValid}
              onSubmit={handleUserData}
              selectedOffice={userData.branchOffices}
            />
          </Card>
        )}
      </div>
    );
  }
);

PaymentMethod.propTypes = {
  offices: PropTypes.array.isRequired,
  banks: PropTypes.array.isRequired,
  cards: PropTypes.array.isRequired,
  userData: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  paymentTypes: PropTypes.array,
  networks: PropTypes.array,
  engine: PropTypes.string,
  className: PropTypes.string,
  onValid: PropTypes.func
};

export default styled(PaymentMethod)`
  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 238px);

    .pay-container {
      min-height: 200px;

      .mv-card-content {
        min-height: calc(100vh - 272px);
      }

      .pay-methods {
        .button {
          margin-bottom: 16px;
          display: flex;
          align-items: center;

          span:first-child {
            margin-right: 16px;
            height: 32px;
          }
        }
      }
    }
  }
`;
