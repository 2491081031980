import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import NewText from 'Components/NewText';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Portal from 'Components/Portal';
import Heading from 'Components/Heading';
import globo from 'Assets/loader/mv-GLOBO.svg';

export const HygieneProtocolsCard = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('non-scroll', showModal);
    return () => {
      document.body.classList.remove('non-scroll');
    };
  }, [showModal]);

  return (
    <Container>
      <Logo alt="me-vuelo" className="logo" src={globo} />
      <NewText>
        Aprendé a viajar con los nuevos protocolos de <b>seguridad e higiene</b>
      </NewText>
      <Button onClick={() => setShowModal(true)}>
        <ButtonLabel>Ver más</ButtonLabel>
      </Button>
      {showModal ? (
        <HygieneProtocols onClose={() => setShowModal(false)} />
      ) : null}
    </Container>
  );
};

const ButtonLabel = styled.span`
  white-space: nowrap;
`;

const Logo = styled.img`
  ${({ theme }) => css`
    max-height: 40px;
    margin-right: ${theme.spacing.two};
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.three};
    background-color: ${theme.palette.grays.white};
    border-radius: ${theme.borderRadius.default};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${theme.spacing.four};
  `}
`;

interface HygieneProtocolsProps {
  onClose: () => void;
}

const paragraphProps = {
  paragraph: true,
  type: 'small' as const
};
const HygieneProtocols = ({ onClose }: HygieneProtocolsProps) => (
  <Portal>
    <ModalOverlay>
      <Modal>
        <ModalCard>
          <Close onClick={onClose}>
            <Icon name="mv-cross" size={20} color="default" />
          </Close>
          <Heading type="h3">
            Conocé los nuevos y más comunes protocolos de viaje
          </Heading>
          <NewText {...paragraphProps}>
            Te contamos cómo están operando las aerolíneas, los aeropuertos y
            qué podés hacer vos para viajar con tranquilidad.
          </NewText>
          <NewText {...paragraphProps}>
            Estas son las medidas más comunes que se están implementando:
          </NewText>
          <NewText {...paragraphProps}>
            Antes de viajar te recomendamos contactarnos para conocer posibles
            restricciones y revisar los sitios oficiales del país de destino
            para informarte sobre posibles regulaciones gubernamentales.
          </NewText>
          <Heading type="h4">¿Cómo están operando las aerolíneas?</Heading>
          <Heading type="h5">Personal capacitado</Heading>
          <NewText {...paragraphProps}>
            Los tripulantes, quienes ya de por si son profesionales altamente
            capacitados, contarán con herramientas necesarias para orientarte en
            tu viaje respecto a medidas de bioseguridad.
          </NewText>
          <Heading type="h5">Sistema HEPA de filtración de aire</Heading>
          <NewText {...paragraphProps}>
            Este sistema renovará el aire de la cabina durante el vuelo
            eliminando hasta el 99% de virus y bacterias. No te sorprenderá
            escuchar que el aire en la cabina del avión es tan o más limpio que
            el de un quirófano.
          </NewText>
          <Heading type="h5">Protocolos de higienización</Heading>
          <NewText {...paragraphProps}>
            Los aviones siguen protocolos oficiales de desinfección y limpieza y
            disponen de los elementos necesarios para la higiene.
          </NewText>
          <Heading type="h5">Cambios en los servicios de cabina</Heading>
          <NewText {...paragraphProps}>
            No te molestes si no hay servicio a bordo, tené en cuenta que el
            objetivo hoy es evitar los contactos innecesarios. Algunas
            areolíneas te daran un pack cerrado al momento del embarque, otras y
            en vuelos más largos, te podrán dar packs cerrados durante el vuelo.
            Chequeá antes del viaje si tu itinerario cuenta con estos servicios.
          </NewText>
          <Heading type="h4">¿Qué están haciendo los aeropuertos?</Heading>
          <Heading type="h5">Abordaje por grupos</Heading>
          <NewText {...paragraphProps}>
            Prestá atención a los mensajes del personal de la aerolínea antes de
            abordar. Hoy, más que nunca, es importante que abordemos al avión
            cuando nos corresponda y evitemos hacer filas innecesarias.
          </NewText>
          <Heading type="h5">Controles de temperatura</Heading>
          <NewText {...paragraphProps}>
            Seguramente te tomen la temperatura como una medida extra de control
            y seguridad. En muchos aeropuertos encontrarás un control de
            Sanidad, dispensadores de tapabocas, alcohol en gel y mamparas
            divisorias.
          </NewText>
          <Heading type="h5">Equipaje</Heading>
          <NewText {...paragraphProps}>
            Posicionate en un lugar frente a las cintas de equipaje y no te
            desplaces para tomar tu valija hasta que no pase por delante tuyo.
          </NewText>
          <Heading type="h4">¿Qué podrás hacer vos?</Heading>
          <Heading type="h5">Chequeá las políticas de ingreso</Heading>
          <NewText {...paragraphProps}>
            Asegurate de contar con la documentación requerida para viajar.
            Revisá los sitios oficiales del gobierno del país de destino.
          </NewText>
          <Heading type="h5">Llevá tu boarding pass digital</Heading>
          <NewText {...paragraphProps}>
            Hacer el check in online te facilitará la circulación en el
            aeropuerto reduciendo el riesgo de contacto.
          </NewText>
          <Heading type="h5">Usá tapabocas y alcohol en gel</Heading>
          <NewText {...paragraphProps}>
            Con esta medida reducirás el riesgo al contagio. Llevá tapabocas de
            recambio si tu vuelo es muy largo. Algunas aerolíneas te pueden
            exigir que lo cambies cada 3 horas.
          </NewText>
          <Heading type="h5">Mantené la distancia social</Heading>
          <NewText {...paragraphProps}>
            Respetá las filas de abordaje y desabordaje por grupos, seguí las
            instrucciones del personal autorizado de la aerolínea o aeropuerto.
          </NewText>
          <Heading type="h5">Te acompañamos siempre</Heading>
          <NewText {...paragraphProps}>
            Podes contactarnos en cualquier momento: antes, durante o después de
            tu viaje para pedirnos el asesoramiento que necesites.
          </NewText>
        </ModalCard>
      </Modal>
    </ModalOverlay>
  </Portal>
);

const ModalOverlay = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
  ${({ theme }) => css`
    z-index: ${theme.zIndex.modal};
  `}
`;

const Modal = styled.div`
  max-width: 800px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;

const ModalCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: ${theme.spacing.four};
    display: flex;
    flex-direction: column;
    position: relative;
    h4 {
      margin-bottom: ${theme.spacing.two};
    }
    @media screen and (min-width: 767px) {
      margin: ${theme.spacing.four};
      border-radius: ${theme.borderRadius.default};
    }
  `}
`;

const Close = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.two};
    right: ${theme.spacing.two};
    cursor: pointer;
  `}
`;
