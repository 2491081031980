/**
 *
 * AirAvailabilityDesktop
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import MenuTab from 'Components/MenuTab';
import Modal from 'Components/Modal';
import AirAvailabilityCard from '../AirAvailabilityCard';
import AirFilter from '../AirFilter';
import { Breakpoint } from 'react-socks';

import { filterListTypes } from 'Constants';
import AirCheckError from '../AirCheckError';
import CardAlert from 'Components/CardAlert';
import Text from 'Components/Text';
import { ExpertCard } from 'Features/flights/components/ExpertCard';
import { HygieneProtocolsCard } from 'Features/flights/components/HygieneProtocols';

const AirAvailabilityDesktop = ({
  className,
  parseResponse,
  handleClickListType,
  filters,
  fareSliderDetails,
  handleClickLuggageFilter,
  filtersData,
  handleClickScalesFilter,
  handleAirlineFilterClick,
  handleAllianceFilterClick,
  handleServiceClassFilterClick,
  handleFareFilterChange,
  handleNoAirportChange,
  handleFlexFilterChange,
  onSelectAvailability,
  request,
  initialDate,
  endDate,
  isChecking,
  showErrorModal,
  toggleErrorModal,
  isDestination,
  orderPriceInfo
}) => (
  <div className={cs(className)}>
    {parseResponse && (
      <div className="availability-holder container">
        {!isDestination && (
          <div className="menu-tab-holder">
            <MenuTab>
              <MenuTab.Item
                active={filters.resultListType === filterListTypes.convenientes}
                onClick={handleClickListType(filterListTypes.convenientes)}>
                +CONVENIENTES<br></br>
                {orderPriceInfo.moreConvenient.currency}{' '}
                {orderPriceInfo.moreConvenient.amount}
              </MenuTab.Item>

              <MenuTab.Item
                active={filters.resultListType === filterListTypes.economico}
                onClick={handleClickListType(filterListTypes.economico)}>
                +ECONÓMICOS<br></br>
                {orderPriceInfo.cheaper.currency}{' '}
                {orderPriceInfo.cheaper.amount}
              </MenuTab.Item>

              <MenuTab.Item
                active={filters.resultListType === filterListTypes.rapidos}
                onClick={handleClickListType(filterListTypes.rapidos)}>
                +RÁPIDOS<br></br>
                {orderPriceInfo.faster.currency} {orderPriceInfo.faster.amount}
              </MenuTab.Item>
            </MenuTab>
          </div>
        )}

        <div className="row">
          {!isDestination && (
            <Breakpoint className="col-lg-3" xl up>
              <AirFilter
                fareSliderDetails={fareSliderDetails}
                filters={filters}
                filtersData={filtersData}
                handleClickLuggageFilter={handleClickLuggageFilter}
                handleClickScalesFilter={handleClickScalesFilter}
                handleFareFilterChange={handleFareFilterChange}
                handleAirlineFilter={handleAirlineFilterClick}
                handleAllianceFilter={handleAllianceFilterClick}
                handleClassFilter={handleServiceClassFilterClick}
                handleNoAirportChange={handleNoAirportChange}
                handleFlexFilterChange={handleFlexFilterChange}
              />
            </Breakpoint>
          )}
          <div className={cs('col-lg-12', { 'col-xl-9': !isDestination })}>
            {parseResponse &&
              parseResponse.length > 0 &&
              request.routesDataChanged && (
              <CardAlert
                nameIcon="mv-circuit"
                color="information"
                title="Tu búsqueda no coincide con los vuelos disponibles.">
                <Text paragraph>
                    Te ofrecemos{' '}
                  <b>vuelos a {request.routesData[0].destinationName}</b>, la
                    opción más conveniente para viajar desde{' '}
                  {request.routesData[0].originName}.
                </Text>
              </CardAlert>
            )}
            {parseResponse && parseResponse.length === 0 && (
              <CardAlert
                color="important"
                nameIcon="mv-information-circle"
                title={`¡Ups! no hemos encontrado vuelos a ${request.routesData[0].originName}`}>
                <Text paragraph>
                  Puede ser que no haya lugar en los vuelos o que no haya vuelos
                  en esos días.
                  <br />
                  Por favor, intenta modificar la búsqueda.
                </Text>
              </CardAlert>
            )}
            {Array.isArray(parseResponse) &&
              parseResponse.map((item, i) => (
                <div key={item.id}>
                  <AirAvailabilityCard
                    id={item.id}
                    generalSegment={item.generalSegments}
                    flightPrice={item.flightPrice}
                    localPrice={item.localPrice}
                    initialDate={initialDate}
                    endDate={endDate}
                    pending={isChecking}
                    routesData={request.routesData}
                    onClick={onSelectAvailability(item)}
                    flightObject={item}
                    hasFlex={item.hasFlex}
                  />
                  {i === 0 && <HygieneProtocolsCard />}
                  {i === 2 && <ExpertCard />}
                </div>
              ))}
          </div>
        </div>
      </div>
    )}
    <Modal
      show={showErrorModal}
      onClickOverlay={toggleErrorModal}
      noCard
      modalWidth={700}>
      <AirCheckError close={toggleErrorModal} desktop />
    </Modal>
  </div>
);
AirAvailabilityDesktop.propTypes = {
  className: PropTypes.string.isRequired,
  handleClickListType: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  fareSliderDetails: PropTypes.object.isRequired,
  handleClickLuggageFilter: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
  handleClickScalesFilter: PropTypes.func.isRequired,
  handleAirlineFilterClick: PropTypes.func.isRequired,
  handleAllianceFilterClick: PropTypes.func.isRequired,
  handleFareFilterChange: PropTypes.func.isRequired,
  getCabinName: PropTypes.func,
  handleServiceClassFilterClick: PropTypes.func.isRequired,
  onSelectAvailability: PropTypes.func.isRequired,
  handleFlexFilterChange: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  initialDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  isChecking: PropTypes.bool,
  handleNoAirportChange: PropTypes.func.isRequired,
  parseResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  showErrorModal: PropTypes.bool,
  toggleErrorModal: PropTypes.func,
  isDestination: PropTypes.bool,
  orderPriceInfo: PropTypes.object
};

export default styled(AirAvailabilityDesktop)`
  display: block;

  .availability-holder {
    padding-top: 31px;
  }

  .scale-filter-line {
    display: flex;
    justify-content: space-between;
  }

  .airlineCheckbox {
    margin-bottom: 8px;
    white-space: nowrap;
    width: 100%;

    label {
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      width: calc(100% - 10px - 15px);
    }
  }
`;
