export const getAllArticles = () => ({
  query: `{
    allBlogArticles {
        id
        name
        url
        thumbnailPhoto {
            publicUrl
        }
        timeToRead,
        author {
            name
            photo {
                publicUrl
            }
        }
        createdDate
        }
    }`
});

export const getArticlesByCategory = (categoryName: string) => ({
  query: `{
    allBlogArticles(where: {categories_some: { name: "${categoryName}"}}) {
        id
        name
        url
        thumbnailPhoto {
            publicUrl
        }
        timeToRead,
        author {
            name
            photo {
                publicUrl
            }
        }
        createdDate
        }
    }`
});

export const getAllCategories = () => ({
  query: `{
    tripStyle: allBlogCategories(where: {categoryType: TripStyle}) {
        id
        name
        categoryType
        photo {
            publicUrl
        }
    }
    tips: allBlogCategories(where: {categoryType: Tips}) {
        id
        name
        categoryType
        photo {
            publicUrl
        }
    }
    region: allBlogCategories(where: {categoryType: Region}) {
        id
        name
        categoryType
        photo {
            publicUrl
        }
    }
    }`
});

export const getLatestArticles = () => ({
  query: `{
        allBlogArticles(sortBy: createdDate_DESC, first: 3) {
            id
            url
            name
            thumbnailPhoto {
                publicUrl
            }
            timeToRead
            author {
                name
                photo {
                    publicUrl
                }
            }
            createdDate
        }
    }`
});

export const searchArticles = (search: string) => ({
  query: `{
        allBlogArticles(search: "${search}") {
            name
            url
            thumbnailPhoto {
                publicUrl
            }
            timeToRead
            author {
                name
                photo {
                    publicUrl
                }
            }
            createdDate
        }
    }`
});

export const getArticleById = (id: string) => ({
  query: `{
        BlogArticle(where: { id: "${id}"}) {
            name
            mainPhoto{
                publicUrl
            },
            thumbnailPhoto {
                publicUrl
            }
            content
            timeToRead
            author {
                name
                photo {
                    publicUrl
                }
            }
            createdDate
            urlRelatedPackages
            urlRelatedFlights
            urlRelatedHotels
            categories {
                id
                name
                categoryType
            }
            metaTitle
            metaKeywords
            metaDescription
        }
    }`
});

export const getArticleBySlug = (slug: string) => ({
  query: `{
        allBlogArticles(where: { url: "${slug}"}) {
            name
            url
            mainPhoto{
                publicUrl
            },
            thumbnailPhoto {
                publicUrl
            }
            content
            timeToRead
            author {
                name
                photo {
                    publicUrl
                }
            }
            createdDate
            urlRelatedPackages
            urlRelatedFlights
            urlRelatedHotels
            categories {
                id
                name
                categoryType
            }
            metaTitle
            metaKeywords
            metaDescription
        }
    }`
});
