import { IQuotationService, DateType } from 'Services/Quotations/types';
import { QuotationsFields } from './types';

export const getInitialValues = (
  service: IQuotationService | null
): QuotationsFields =>
  !service
    ? {
      title: '',
      description: '',
      internalNotes: '',
      providerName: '',
      dateType: DateType.noDate,
      dateTo: '',
      dateFrom: '',
      isCostByPassenger: false,
      isIncluded: false,
      markup: 0,
      cost: 0
    }
    : {
      ...service
    };
