/**
 *
 * SearchModalContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from '../Text';
import Icon from '../Icon';

const SearchModalContainer = ({ className, children, close }) => (
  <div className={cs(className)}>
    <div className="topBar">
      <Text weight="italic" size="xlarge">
        Modificá tu busqueda
      </Text>
      <div onClick={close} className="close">
        <Icon name="mv-cross" className="close" />
      </div>
    </div>
    {children}
  </div>
);

SearchModalContainer.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
  close: PropTypes.func
};

export default styled(SearchModalContainer)`
  width: 100%;
  background: ${COLORS.white};

  .close {
    cursor: pointer;
    float: right;
    align-self: center;
  }

  .topBar {
    width: 100%;
    height: 62px;
    background-color: ${COLORS.white};
    padding-left: 13px;
    padding-right: 13px;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0.5px 0.5px 1.5px 0 rgba(0, 0, 0, 0.25);
  }
`;
