import { breakpoints } from 'Constants';
import styled from 'styled-components';

export const Banner = styled.img`
  width: 100%;
`;

export const IconHolder = styled.div`
  width: 40px;
  height: 40px;
`;

const generateColums = (value: number) => {
  let result = '';
  for (let i = 0; i < value; i++) {
    result += '1fr ';
  }
  return result;
};

export const Row = styled.div`
  display: flex;
  gap: 8px;
  @media screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`;

export const FormCard = styled.div`
  max-width: 714px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #ffffff;
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding: 16px;
`;

export const LogosHolder = styled.div<{ columns: number }>`
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ columns }) => generateColums(columns)};
  gap: 16px;
  align-items: center;

  img {
    width: 100%;
  }
`;

export const PartnerHolder = styled.div`
  background-color: white;
  border-radius: 12px;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 2px;
`;

export const PhoneCodeInput = styled.div`
  width: 160px;
  padding-right: 8px;
`;

export const MarginOnlyDesktop= styled.div`
  @media screen and (min-width: ${breakpoints.medium}px) {
    margin: 10px;
  }
`;