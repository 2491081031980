import React from 'react';
import styled from 'styled-components';
import QuotationsGeneral from '../QuotationsGeneral';
import QuotationServices from '../QuotationServices';
import QuotationAir from '../QuotationsAir';
import Loader from 'Features/app/components/Loader';

export type Id = 'new' | number;

export interface QuotationDetailsProps {
  id: Id;
  loading: boolean;
}

const QuotationDetails = ({ id, loading }: QuotationDetailsProps) => {
  if (loading) return <Loader />;
  return (
    <Container>
      <QuotationsGeneral id={id} />
      <QuotationAir />
      <QuotationServices />
    </Container>
  );
};

const Container = styled.div`
  ${({ theme }) => theme.width.fullWidth};
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.spacing.four};
  & > div:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
`;
export default QuotationDetails;
