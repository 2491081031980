import ACTION_TYPES from './actionTypes';
import { updateLoader } from '../Features/app/actions';
import API from '../Services';
import { getPassengers } from '../Features/flights/components/Passengers/helpers';
import { getCorpoSteps } from '../Containers/CheckoutCorpo/helpers';

const initialState = {
  reservationData: {
    ticket: null,
    reserveCode: null
  },

  steps: {
    current: 0,
    total: 0,
    titles: [],
    allowed: [0]
  },

  requestData: {
    passengers: [],
    holder: { holders: [] },
    branchOffices: {},
    paymentNetwork: {},
    comment: null
  },

  selectedAvailability: null,

  pending: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CORPO_CHECKOUT_REQUEST:
      return {
        ...state,
        pending: true
      };

    case ACTION_TYPES.CORPO_CHECKOUT_SUCCESS:
      return {
        ...state,
        reservationData: {
          reserveCode: action.payload.reserveCode,
          ticket: action.payload.ticket
        },
        reserveCode: action.payload.reserveCode,
        ticket: action.payload.ticket,
        pending: false
      };

    case ACTION_TYPES.STORAGE_CORPO_DATA:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          ...action.payload
        }
      };

    case ACTION_TYPES.SELECT_AVAILABILITY_CORPO:
      return {
        ...state,
        selectedAvailability: action.payload,
        requestData: {
          ...state.requestData,
          passengers:
            action.payload &&
            action.payload.flightPrice &&
            getPassengers(action.payload.flightPrice.pricePassengerDetail)
        }
      };

    case ACTION_TYPES.RESET_CORPO_DATA:
      return {
        ...state,
        requestData: {
          ...initialState.requestData,
          passengers: state.requestData.passengers
        }
      };

    case ACTION_TYPES.CORPO_CHECKOUT_STEP_FILL:
      return {
        ...state,
        steps: {
          ...state.steps,
          titles: getCorpoSteps(action.payload)
        }
      };

    case ACTION_TYPES.CORPO_CHECKOUT_FAILURE:
      return {
        ...state,
        pending: false
      };
    default:
      return state;
  }
};

export const corpoCheckout = (engineId, params) => async dispatch => {
  try {
    dispatch(corpoCheckoutRequest());
    dispatch(updateLoader(true));

    const res = await API.corpoCheckout.payCorpo(engineId, params);
    dispatch(corpoCheckoutSuccess(res.data));
    dispatch(updateLoader(false));

    return res.data;
  } catch (error) {
    dispatch(corpoCheckoutFailure());
    dispatch(updateLoader(false));

    throw error;
  }
};

export const selectAvailabilityCorpo = payload => dispatch => {
  dispatch({
    type: ACTION_TYPES.SELECT_AVAILABILITY_CORPO,
    payload
  });
};

// CORPO CHECKOUT ACTIONS
const corpoCheckoutRequest = () => ({
  type: ACTION_TYPES.CORPO_CHECKOUT_REQUEST
});

const corpoCheckoutSuccess = payload => ({
  type: ACTION_TYPES.CORPO_CHECKOUT_SUCCESS,
  payload
});

const corpoCheckoutFailure = () => ({
  type: ACTION_TYPES.CORPO_CHECKOUT_FAILURE
});

// Storage Corpo data
export const storageCorpoData = corpoData => dispatch => {
  dispatch({
    type: ACTION_TYPES.STORAGE_CORPO_DATA,
    payload: corpoData
  });
};

export const fillCorpoCheckoutSteps = payload => dispatch => {
  dispatch({
    type: ACTION_TYPES.CORPO_CHECKOUT_STEP_FILL,
    payload
  });
};

// Reset user data
export const resetCorpoData = () => dispatch => {
  dispatch({ type: ACTION_TYPES.RESET_CORPO_DATA });
};
