import { IErrorResponse, IQuotationFull } from 'Services/Quotations/types';

export const GET_QUOTATIONS_LIST_REQUEST = 'GET_QUOTATIONS_LIST_REQUEST';
export const GET_QUOTATIONS_LIST_SUCCESS = 'GET_QUOTATIONS_LIST_SUCCESS';
export const GET_QUOTATIONS_LIST_FAILURE = 'GET_QUOTATIONS_LIST_FAILURE';

export const GET_QUOTATION_DETAILS_REQUEST = 'GET_QUOTATION_DETAILS_REQUEST';
export const GET_QUOTATION_DETAILS_SUCCESS = 'GET_QUOTATION_DETAILS_SUCCESS';
export const GET_QUOTATION_DETAILS_FAILURE = 'GET_QUOTATION_DETAILS_FAILURE';

export const DELETE_FLIGHT_QUOTATION_REQUEST =
  'DELETE_FLIGHT_QUOTATION_REQUEST';
export const DELETE_FLIGHT_QUOTATION_SUCCESS =
  'DELETE_FLIGHT_QUOTATION_SUCCESS';
export const DELETE_FLIGHT_QUOTATION_FAILURE =
  'DELETE_FLIGHT_QUOTATION_FAILURE';

export const PUT_FLIGHT_QUOTATION_REQUEST = 'PUT_FLIGHT_QUOTATION_REQUEST';
export const PUT_FLIGHT_QUOTATION_SUCCESS = 'PUT_FLIGHT_QUOTATION_SUCCESS';
export const PUT_FLIGHT_QUOTATION_FAILURE = 'PUT_FLIGHT_QUOTATION_FAILURE';

interface IQuotationsRequest {
  type: typeof GET_QUOTATIONS_LIST_REQUEST;
}

interface IQuotationsSuccess {
  type: typeof GET_QUOTATIONS_LIST_SUCCESS;
  payload?: any;
}

interface IQuotationsFailure {
  type: typeof GET_QUOTATIONS_LIST_FAILURE;
}

interface IQuotationDetailsRequest {
  type: typeof GET_QUOTATION_DETAILS_REQUEST;
}
interface IQuotationDetailsSuccess {
  type: typeof GET_QUOTATION_DETAILS_SUCCESS;
  payload: IQuotationFull;
}
interface IQuotationDetailsFailure {
  type: typeof GET_QUOTATION_DETAILS_FAILURE;
  payload?: IErrorResponse;
}

interface IQuotationDeleteFlightRequest {
  type: typeof DELETE_FLIGHT_QUOTATION_REQUEST;
  payload?: IErrorResponse;
}

interface IQuotationDeleteFlightSuccess {
  type: typeof DELETE_FLIGHT_QUOTATION_SUCCESS;
  payload?: {
    id: number;
    flightId: number;
  };
}

interface IQuotationDeleteFlightFailure {
  type: typeof DELETE_FLIGHT_QUOTATION_FAILURE;
  payload?: IErrorResponse;
}

interface IQuotationPutFlightRequest {
  type: typeof PUT_FLIGHT_QUOTATION_REQUEST;
}

interface IQuotationPutFlightSuccess {
  type: typeof PUT_FLIGHT_QUOTATION_SUCCESS;
}

interface IQuotationPutFlightFailure {
  type: typeof PUT_FLIGHT_QUOTATION_FAILURE;
  payload?: IErrorResponse;
}

export type TQuotationAction =
  | IQuotationsRequest
  | IQuotationsSuccess
  | IQuotationsFailure
  | IQuotationDetailsRequest
  | IQuotationDetailsSuccess
  | IQuotationDetailsFailure
  | IQuotationDeleteFlightRequest
  | IQuotationDeleteFlightSuccess
  | IQuotationDeleteFlightFailure
  | IQuotationPutFlightRequest
  | IQuotationPutFlightSuccess
  | IQuotationPutFlightFailure;
