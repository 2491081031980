/**
 *
 * MobileNavBarPrice
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from '../Text';
import Icon from '../Icon';

const MobileNavBarPrice = ({
  className,
  topText,
  middleText,
  bottomText,
  localMiddleText,
  localBottomText,
  hasIcon,
  onClick
}) => (
  <div className={cs(className)} onClick={onClick}>
    <div className="text-wrapper">
      <Text weight="semi-bold" context="important">
        {topText}
      </Text>
      <Text size="large" weight="bold" context="important">
        {middleText}
      </Text>
      {localMiddleText && (
        <Text size="large" weight="bold" context="important">
          {localMiddleText}
        </Text>
      )}
      {bottomText && (
        <Text size="small" weight="semi-bold" context="important">
          {bottomText}
        </Text>
      )}
      {localBottomText && (
        <Text size="small" weight="bold" context="important">
          {localBottomText}
        </Text>
      )}
    </div>
    {hasIcon ? (
      <div className="icon-wrapper">
        <Icon name="mv-exlamation-circle" />
      </div>
    ) : null}
  </div>
);

MobileNavBarPrice.propTypes = {
  className: PropTypes.string.isRequired,
  topText: PropTypes.string,
  middleText: PropTypes.string,
  localMiddleText: PropTypes.string,
  bottomText: PropTypes.string,
  localBottomText: PropTypes.string,
  onClick: PropTypes.func,
  hasIcon: PropTypes.bool
};

export default styled(MobileNavBarPrice)`
  display: flex;
  flex: 1;
  background-color: ${COLORS.white};
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.25);
  margin-right: 3px;
  position: relative;
  border-radius: 7px;
  max-width: 160px;

  // TODO: THIS IS A TEMPORARY FIX, BUTTON IS BUGGED WITH LEFT AND RIGHT PROPS IT SHOULDNT BE ABSOLUTE
  margin-left: 60px;

  .text-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .icon-wrapper {
    position: absolute;
    top: 5px;
    right: 10px;
    color: ${COLORS.information};
  }
`;
