import { formatStringCustomDate } from './dates';

const resolveChildAges = data => JSON.parse(data);

const resolveRoomInformation = data => {
  const toReturn = [];
  if ('adults1' in data) {
    const room1 = {
      adultsCount: parseInt(data['adults1']),
      kidsAges: 'childs1' in data ? resolveChildAges(data['room-1']) : []
    };
    toReturn.push(room1);

    if ('adults2' in data) {
      const room2 = {
        adultsCount: parseInt(data['adults2']),
        kidsAges: 'childs2' in data ? resolveChildAges(data['room-2']) : []
      };
      toReturn.push(room2);
    }

    if ('adults3' in data) {
      const room3 = {
        adultsCount: parseInt(data['adults3']),
        kidsAges: 'childs3' in data ? resolveChildAges(data['room-3']) : []
      };
      toReturn.push(room3);
    }

    if ('adults4' in data) {
      const room4 = {
        adultsCount: parseInt(data['adults4']),
        kidsAges: 'childs4' in data ? resolveChildAges(data['room-4']) : []
      };
      toReturn.push(room4);
    }
  }
  return toReturn;
};

export default queryParameters => {
  const allowedPets =
    'type' in queryParameters && queryParameters['type'] === 'acepta-mascotas';
  return {
    roomInformation: resolveRoomInformation(queryParameters),
    checkIn: formatStringCustomDate(queryParameters['from-date']),
    checkOut: formatStringCustomDate(queryParameters['to-date']),
    allowedPets,
    lang: 'en',
    knowMore: false,
    location: {
      placeId: queryParameters.placeId,
      description: queryParameters['to'].replace('-', ' '),
      iconType: 'locality'
    },
    placeId: queryParameters.placeId
  };
};
