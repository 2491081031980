/**
 *
 * FullPriceDetail
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from '../Text';

import { getPassengerType, getFormatPrice } from '../../Utils';

const FullPriceDetail = ({
  className,
  pricePassengerDetail,
  pricePassengerDetailLocal
}) => (
  <div className={cs(className)}>
    {pricePassengerDetail.map((passengerDetail, idx) => (
      <div className="passenger" key={idx}>
        <Text size="large" weight="semi-bold">
          {`Detalle ${getPassengerType(
            passengerDetail.passengerCode,
            passengerDetail.totalPassenger
          )}`}
        </Text>
        <div className="passenger-prices">
          <div className="passenger-prices__row">
            <Text size="small">Tarifa</Text>
            <Text size="small">
              {getFormatPrice(
                passengerDetail.totalBaseRate,
                passengerDetail.currency
              )}
            </Text>
            {pricePassengerDetailLocal && (
              <Text paragraph size="small">
                {getFormatPrice(
                  pricePassengerDetailLocal[idx].totalBaseRate,
                  pricePassengerDetailLocal[idx].currency,
                  true
                )}
              </Text>
            )}
          </div>
          {passengerDetail.totalTaxes > 0 && (
            <div className="passenger-prices__row">
              <Text size="small">Impuestos y tasas</Text>
              <Text size="small">
                {getFormatPrice(
                  passengerDetail.totalTaxes,
                  passengerDetail.currency
                )}
              </Text>
              {pricePassengerDetailLocal && (
                <Text paragraph size="small">
                  {getFormatPrice(
                    pricePassengerDetailLocal[idx].totalTaxes,
                    pricePassengerDetailLocal[idx].currency,
                    true
                  )}
                </Text>
              )}
            </div>
          )}
          {passengerDetail.totalFee > 0 && (
            <div className="passenger-prices__row">
              <Text size="small">Cargos</Text>
              <Text size="small">
                {getFormatPrice(
                  passengerDetail.totalFee,
                  passengerDetail.currency
                )}
              </Text>
              {pricePassengerDetailLocal && (
                <Text paragraph size="small">
                  {getFormatPrice(
                    pricePassengerDetailLocal[idx].totalFee,
                    pricePassengerDetailLocal[idx].currency,
                    true
                  )}
                </Text>
              )}
            </div>
          )}
          {passengerDetail.totalDiscount > 0 && (
            <div className="passenger-prices__row">
              <Text size="small">Descuentos</Text>
              <Text size="small">
                {getFormatPrice(
                  passengerDetail.totalDiscount,
                  passengerDetail.currency
                )}
              </Text>
              {pricePassengerDetailLocal && (
                <Text paragraph size="small">
                  {getFormatPrice(
                    pricePassengerDetailLocal[idx].totalDiscount,
                    pricePassengerDetailLocal[idx].currency,
                    true
                  )}
                </Text>
              )}
            </div>
          )}
          <div className="passenger-prices__row">
            <Text size="small" weight="bold">
              Tarifa por {getPassengerType(passengerDetail.passengerCode, 1)}
            </Text>
            <Text size="small" weight="bold">
              {getFormatPrice(
                passengerDetail.totalPrice,
                passengerDetail.currency
              )}
            </Text>
            {pricePassengerDetailLocal && (
              <Text paragraph size="small" weight="bold">
                {getFormatPrice(
                  pricePassengerDetailLocal[idx].totalPrice,
                  pricePassengerDetailLocal[idx].currency,
                  true
                )}
              </Text>
            )}
          </div>

          <div className="passenger-prices__row">
            <Text size="small" weight="bold" context="important">
              Total por {passengerDetail.totalPassenger}{' '}
              {getPassengerType(
                passengerDetail.passengerCode,
                passengerDetail.totalPassenger
              )}
            </Text>
            <Text size="small" weight="bold" context="important">
              {getFormatPrice(
                passengerDetail.totalForAllPassenger,
                passengerDetail.currency
              )}
            </Text>
            {pricePassengerDetailLocal && (
              <Text paragraph size="small" weight="bold" context="important">
                {getFormatPrice(
                  pricePassengerDetailLocal[idx].totalForAllPassenger,
                  pricePassengerDetailLocal[idx].currency,
                  true
                )}
              </Text>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
);

FullPriceDetail.propTypes = {
  className: PropTypes.string.isRequired,
  pricePassengerDetail: PropTypes.array.isRequired,
  pricePassengerDetailLocal: PropTypes.array
};

export default styled(FullPriceDetail)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;

  .passenger {
    margin-bottom: 12px;
    width: 80%;
    max-width: 95%;

    &-type {
      margin-bottom: 8px;
    }

    &-prices {
      padding-bottom: 12px;
      border-bottom: 2px solid ${COLORS.darkGrey};

      &__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        span:first-child {
          margin-right: 12px;
          flex: 1 1 auto;
        }

        p {
          width: 100%;
          flex: 1 1 auto;
          text-align: right;
        }
      }
    }
  }
`;
