import * as qs from 'query-string';

export const setQueryStringWithoutPageReload = qsValue => {
  const newurl = window.location.origin + window.location.pathname + qsValue;

  window.history.pushState({ path: newurl }, '', newurl);
};

export const setQueryStringValue = (
  key,
  value,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (
  key,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  return values[key];
};
