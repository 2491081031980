/**
 *
 * MobileFlightDestination
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import MobileFlightDestinationCard from '../MobileFlightDestinationCard';
import { locationTypes } from 'Components/DestinationFlight';

const MobileFlightDestination = ({ className, index }) => (
  <div className={cs(className)}>
    <div className="container-main-mfd">
      <MobileFlightDestinationCard type={locationTypes.ORIGIN} index={index} />
      <MobileFlightDestinationCard
        type={locationTypes.DESTINATION}
        index={index}
      />
    </div>
  </div>
);

MobileFlightDestination.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number
};

export default styled(MobileFlightDestination)`
  .container-main-mfd {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
`;
