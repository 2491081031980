import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer, Zoom } from 'react-toastify';
import store from 'ReduxConfig/store';
import App from 'Features/app/components/AppRouter';
import TagManager from 'react-gtm-module';
import theme from 'Theme';

import 'react-toastify/dist/ReactToastify.min.css';
import 'sanitize.css/sanitize.css';
import './index.css';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
};

TagManager.initialize(tagManagerArgs);

const target = document.querySelector('#root');
render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>

    <ToastContainer
      draggable
      pauseOnHover
      newestOnTop
      position="bottom-center"
      transition={Zoom}
      autoClose={false}
    />
  </Provider>,
  target
);
