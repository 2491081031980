/**
 *
 * HotelSelectFare
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import GloboToggle from 'Components/GloboToggle';
import Text from 'Components/Text';

import { greyBkg, lightGrey, white } from 'Constants/colors';
import { getFormatPrice } from 'Utils';

const HotelSelectFare = ({
  className,
  active,
  details,
  price,
  localPrice,
  priceType,
  currency,
  localCurrency,
  onClick,
  isCheckout = false
}) => (
  <div className={cs(className)} onClick={onClick}>
    {!isCheckout && (
      <div className="toggle-holder">
        <GloboToggle active={active} />
      </div>
    )}

    <div className="fare-details">
      {details &&
        details.map((item, index) => (
          <div key={index}>
            <Text context={item.context}>{item.label}</Text>
          </div>
        ))}

      <div className="price-holder">
        <div className="price-type">
          <Text context="important">PRECIO TOTAL</Text>

          <Text context="important">
            {priceType === 'PER_NIGHT' ? 'POR NOCHE' : 'POR ESTADIA'}
          </Text>
        </div>

        <div className="price">
          <Text size="xlarge" weight="bold" context="important">
            {getFormatPrice(price, currency)}
          </Text>
          {localPrice && (
            <Text size="large" weight="bold" context="important">
              {getFormatPrice(localPrice, localCurrency, true)}
            </Text>
          )}
        </div>
      </div>
    </div>
  </div>
);

HotelSelectFare.propTypes = {
  className: PropTypes.string.isRequired,
  active: PropTypes.bool,
  details: PropTypes.array,
  currency: PropTypes.string,
  localCurrency: PropTypes.string,
  onClick: PropTypes.func,
  price: PropTypes.number,
  localPrice: PropTypes.number,
  priceType: PropTypes.string,
  isCheckout: PropTypes.bool
};

export default styled(HotelSelectFare)`
  background-color: ${greyBkg};
  max-width: 438px;
  min-height: 100px;
  border-radius: 14px;
  display: flex;
  padding: 10px;
  align-items: center;
  transition: 0.6s;

  &:hover {
    background-color: ${white};
  }

  &.active {
    background-color: ${white};
  }

  .toggle-holder {
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid ${lightGrey};
    margin-right: 16px;
    padding-right: 10px;
  }

  .fare-details {
    .price-holder {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .price-type {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        width: 140px;
      }

      .price {
        display: flex;
        width: 230px;
        flex-direction: column;
      }
    }
  }
`;
