/**
 *
 * MeteorologyMonth
 *
 */

import React from 'react';

// Components
import { Container, Month } from './styles';

// Types
import { MeteorologyMonthProps } from './types';

const MeteorologyMonth = ({ children }: MeteorologyMonthProps) => (
  <Container>
    <Month size="large" weight="bold">
      {children}
    </Month>
  </Container>
);

export default MeteorologyMonth;
