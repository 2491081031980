/**
 *
 * Loader
 *
 */

import React from 'react';
import styled, { css } from 'styled-components';
import spinner from './assets/spinner.svg';

interface LoaderProps {
  fullScreen?: boolean;
}

const Loader = ({ fullScreen = true }: LoaderProps) => {
  const renderSpinner = (fullScreen: boolean) => (
    <SpinnerHolder fullScreen={fullScreen}>
      <img alt="spinner" src={spinner} />
    </SpinnerHolder>
  );
  return fullScreen ? (
    <Container>{renderSpinner(fullScreen)}</Container>
  ) : (
    renderSpinner(fullScreen)
  );
};

const Container = styled.div`
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 9999999999;
  background: rgba(0, 0, 0, 0.8);
`;

const SpinnerHolder = styled.div<LoaderProps>`
  width: 64px;
  height: 64px;
  ${({ fullScreen }) =>
    fullScreen &&
    css`
      position: absolute;
      left: calc(50% - 32px);
      top: calc(50% - 32px);
    `}
`;

export default Loader;
