import Axios from 'axios';
import { getToken } from 'Features/users/utils';

import BaseApi from './BaseAPI';
import Common from './Common';
import Flights from './Flights';
import Hotels from './Hotels';
import PaymentSimulator from './PaymentSimulator';
import CorpoCheckout from './CorpoCheckout';
import Places from './Places';
import Destinations from './Destinations';
import Packages from './Packages';
import AppGeneral from './AppGeneral';
import Blog from './Blog';
import Users from './Users';

import { breakpoints } from 'Constants';
import { checkTokenExpiration } from 'Features/users/actions';
import FlightsGraphql from './FlightsGraphql';

const getResolution = () => {
  const width = window.innerWidth;
  if (width < breakpoints.medium) {
    return 1;
  }
  if (width < breakpoints.large) {
    return 2;
  }
  return 3;
};

export const pullTokenIfExist = config => {
  const token = getToken();
  if (token) {
    config.headers['X-Auth-Token'] = token;
  }

  return config;
};

const defaultHeaders = {
  'Content-Type': 'application/json',
  'X-Api-Key': process.env.REACT_APP_API_KEY,
  //FIXME: Lang Default ES por el momento...tenemos que hacerlo dinamico cuando tengamos I18n
  'Accept-Language': 'es',
  Resolution: getResolution()
};

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: defaultHeaders
});

axios.interceptors.response.use(undefined, checkTokenExpiration);
axios.interceptors.request.use(pullTokenIfExist);

export const contentfulClient = Axios.create({
  baseURL:`https://graphql.contentful.com/content/v1/spaces/pceiyna9aow1/environments/master`,
  headers:{Authorization:'Bearer b-H3jUOUHhA4uzw3-AmcbDCQ9c2htfLgqm8XtLPBBRo'}
})

export const flightAxios = Axios.create({
  baseURL: process.env.REACT_APP_FLIGHT_API_URL,
  headers: defaultHeaders
});

export const hotelAxios = Axios.create({
  baseURL: process.env.REACT_APP_HOTEL_API_URL,
  headers: defaultHeaders
});

export const packagesAxios = Axios.create({
  baseURL: process.env.REACT_APP_PACKAGES_API_URL,
  headers: defaultHeaders
});

const axiosGraphql = Axios.create({
  baseURL: process.env.REACT_APP_STRAPI_SERVER_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const axiosDestinations = Axios.create({
  baseURL: process.env.REACT_APP_KEYSTONEJS,
  headers: {
    'Content-Type': 'application/json'
  }
});

const axiosFlightsGraphql = Axios.create({
  baseURL: process.env.REACT_APP_FLIGHTS_GRAPHQL_API_URL,
  headers: {
    ...defaultHeaders,
    'Content-Type': 'application/json'
  }
});

const api = BaseApi(axios);
const flightApi = BaseApi(flightAxios);
const hotelsApi = BaseApi(hotelAxios);
const packagesApi = BaseApi(packagesAxios);

export default {
  axios,
  axiosGraphql,
  hotels: Hotels(hotelsApi),
  flights: Flights(flightApi),
  common: Common(api),
  paymentSimulator: PaymentSimulator(api),
  corpoCheckout: CorpoCheckout(api),
  places: Places(api),
  destinations: Destinations(axiosDestinations),
  packages: Packages(packagesApi),
  blog: Blog(axiosDestinations),
  appGeneral: AppGeneral(api),
  users: Users(axios),
  flightsGraphql: FlightsGraphql(axiosFlightsGraphql)
};
