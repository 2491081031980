import actionTypes from './actionTypes';
import { initialState, BlogState } from './constants';
import {
  BasicBlogArticle,
  BlogCategories,
  BlogArticle
} from 'Services/Blog/types';

export default (state: BlogState = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ARTICLES_SUCCESS: {
      const { allBlogArticles } = action.payload.data;
      return {
        ...state,
        articles: allBlogArticles as BasicBlogArticle[],
        articlesLoading: false
      };
    }

    case actionTypes.GET_ARTICLE_BY_ID_SUCCESS: {
      const article = action.payload.data.allBlogArticles[0];
      return {
        ...state,
        articleData: article as BlogArticle,
        articleLoading: false
      };
    }

    case actionTypes.GET_LATEST_ARTICLES_SUCCESS: {
      const { allBlogArticles } = action.payload.data;
      return {
        ...state,
        latestArticles: allBlogArticles as BasicBlogArticle[]
      };
    }

    case actionTypes.GET_CATEGORIES_SUCCESS: {
      const { tripStyle, tips, region } = action.payload.data;
      return {
        ...state,
        categories: {
          tripStyle,
          tips,
          region
        } as BlogCategories,
        categoriesLoading: false
      };
    }

    case actionTypes.GET_SEARCH_ARTICLE_REQUEST:
    case actionTypes.GET_ARTICLES_REQUEST:
      return {
        ...state,
        articlesLoading: true
      };

    case actionTypes.GET_ARTICLE_BY_ID_REQUEST:
      return {
        ...state,
        articleLoading: true
      };

    case actionTypes.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        categoriesLoading: true
      };

    case actionTypes.GET_SEARCH_ARTICLE_SUCCESS: {
      const { allBlogArticles } = action.payload.data;
      return {
        ...state,
        articles: allBlogArticles as BasicBlogArticle[],
        articlesLoading: false
      };
    }
    case actionTypes.CLEAN_ARTICLE: {
      return {
        ...state,
        articleData: null
      };
    }

    default:
      return state;
  }
};
