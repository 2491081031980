/**
 *
 * SelectPeople
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from '../Text';
import * as COLORS from 'Constants/colors';
import PlusMinusSelector from '../PlusMinusSelector';
import { edadesOpciones } from 'Constants/mocks';
import Select from '../Select';
import Button from '../Button';
import { CustomSelect } from '../NewSelect';

const defaultState = {
  adultsCount: 2,
  childrenQty: 0,
  kidsAges: []
};

const SelectPeople = ({ className, initialState, onChange, error }) => {
  const [state, setState] = useState(
    initialState ? initialState : defaultState
  );
  const [isOpen, setIsOpen] = useState(false);

  const { adultsCount, childrenQty, kidsAges } = state;

  const handleChange = property => value => {
    setState({ ...state, [property]: value });
  };

  useEffect(() => {
    const newKidsAges = [...state.kidsAges];
    if (newKidsAges.length > childrenQty) {
      newKidsAges.pop();
    }
    if (newKidsAges.length < childrenQty) {
      newKidsAges.push(null);
    }
    setState({ ...state, kidsAges: newKidsAges });
  }, [childrenQty]);

  const handleChangeChildren = index => value => {
    const newKidsAges = [...state.kidsAges];
    newKidsAges[index] = value;
    setState({ ...state, kidsAges: newKidsAges });
  };

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state]);

  const toggleOpen = e => {
    if (e && e.key !== 'Tab') e.preventDefault();
    setIsOpen(prev => !prev);
  };

  const close = () => setIsOpen(false);

  const totalPeople = adultsCount + childrenQty;

  return (
    <CustomSelect
      className={cs(className)}
      label="¿Quiénes viajan?"
      toggleOpen={toggleOpen}
      close={close}
      isOpen={isOpen}
      color="orange"
      error={error}
      showValue={`${totalPeople} personas`}>
      <div className="content-holder">
        <div className="row-holder">
          <div className="column">
            <Text>Adultos</Text>
            <Text context="important">Mayores de 18 años</Text>
          </div>
          <PlusMinusSelector
            value={adultsCount}
            minValue={1}
            onChange={handleChange('adultsCount')}
          />
        </div>
        <div className="row-holder">
          <div className="column">
            <Text>Menores</Text>
            <Text context="important">Menores de 17 años</Text>
          </div>
          <PlusMinusSelector
            value={childrenQty}
            minValue={0}
            onChange={handleChange('childrenQty')}
          />
        </div>
        {childrenQty > 0 &&
          kidsAges.map((child, i) => (
            <div className="row-holder" key={i}>
              <div className="column">
                <Text>Edad del menor {i + i}</Text>
                <Text context="important">Al finalizar la estadia</Text>
              </div>
              <Select
                selectedValue={child && child.id}
                items={edadesOpciones}
                onSelect={handleChangeChildren(i)}
              />
            </div>
          ))}
      </div>
      <div className="apply-btn-holder">
        <Button weight="semi-bold" onClick={toggleOpen}>
          APLICAR
        </Button>
      </div>
    </CustomSelect>
  );
};

SelectPeople.propTypes = {
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  initialState: PropTypes.object,
  error: PropTypes.shape({
    message: PropTypes.string,
    color: PropTypes.string
  })
};

export default styled(SelectPeople)`
  .content-holder {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .row-holder {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .apply-btn-holder {
    height: 50px;
    background-color: ${COLORS.arena};
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
