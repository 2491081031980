const prefix = 'FLIGHTS';

const apiDefaultActionTypes = [
  'SUGGESTIONS',
  'VALIDATION',
  'MPTB_AND_MPC',
  'MPTB_ITEM_RESEARCH',
  'MPTB_AVAILABILITY',
  'CHECK_SELECTION'
];

const apiCustomActionTypes = [
  'SET_ORIGIN',
  'SET_DESTINATION',
  'SET_INITIAL_DATE',
  'SET_END_DATE',
  'SET_ADULTS',
  'SET_CHILDREN',
  'SET_TYPE',
  'TOGGLE_MPC',
  'SET_CABIN_CODE',
  'SET_ROUTES',
  'RESET_VALIDATIONS',
  'SEARCH_TYPE_FLIGHT',
  'AVAILABILITY_RESET_FILTERS',
  'SET_SEARCH_STATUS',
  'CLEAN_ERROR',
  'CLEAN_SEARCH',
  'SET_CITY_KEYWORD',
  'LOAD_SEARCH_FROM_REQUEST',
  'SET_DETAIL_MODE'
];

const defaultActionTypes = {};
const customActionTypes = {};

apiDefaultActionTypes.forEach(item => {
  defaultActionTypes[`${prefix}_${item}_REQUEST`] = `${prefix}_${item}_REQUEST`;
  defaultActionTypes[`${prefix}_${item}_SUCCESS`] = `${prefix}_${item}_SUCCESS`;
  defaultActionTypes[`${prefix}_${item}_FAILURE`] = `${prefix}_${item}_FAILURE`;
});

apiCustomActionTypes.forEach(item => {
  customActionTypes[`${prefix}_${item}`] = `${prefix}_${item}`;
});

export default {
  ...defaultActionTypes,
  ...customActionTypes
};
