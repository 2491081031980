import { axios } from 'Services/index';
import {
  ICodeResponse,
  IErrorResponse,
  ISuggestionResponse,
  IQuotationGeneralInfo,
  IQuotation,
  IQuotationFlightPayload,
  IQuotationSimple,
  IPostQuotationFlight,
  IQuotationService,
  IPostQuotationAccomodation,
  IQuotationAccomodation,
  IQuotationFull,
  IOptionSuggestion,
  IQuotationParams,
  IFlightQuotation
} from './types';

import { handleParams } from './utils';

/** Filter the quotes by a given value, this endpoint is used to fill the combo of the screen to add to an existing quote   */
const getSuggestions = (filter: string) =>
  axios.get<ISuggestionResponse | IErrorResponse>(
    `/quotations/suggestion/${filter}`
  );

/** List all possible quote option numbers for a specified quote id */
const getAccomodationOptions = (id: number) =>
  axios.get<IOptionSuggestion | IErrorResponse>(
    `/quotations/${String(id)}/accommodationsoptions`
  );

/** Get quotation by Id */
const getQuotation = (id: number) =>
  axios.get<IQuotationFull | IErrorResponse>(`/quotations/${String(id)}`);
/** Update Quotation */
const updateQuotation = (id: number, quotation: IQuotationGeneralInfo) =>
  axios.put<ICodeResponse | IErrorResponse>(`/quotations/${id}`, quotation);

/** Delete quotation */
const deleteQuotation = (id: number) =>
  axios.delete<ICodeResponse | IErrorResponse>(`/quotations/${String(id)}`);

/** Post Quotation */
const postQuotation = (quotation: IQuotationGeneralInfo) =>
  axios.post<ICodeResponse | IErrorResponse>('/quotations', quotation);

/** Get all Quotations for authenticated user */
const getQuotations = (payload: IQuotationParams) =>
  axios.get<IQuotation[] | IErrorResponse>(handleParams(payload));

/** Creates a quotation from a flight */
const postFlight = (payload: IPostQuotationFlight) =>
  axios.post<IQuotationSimple | IErrorResponse>('/quotations/flights', payload);

/** Add flight to existing quotation */
const patchFlight = (id: number, payload: IQuotationFlightPayload) =>
  axios.patch<ICodeResponse | IErrorResponse>(
    `/quotations/${String(id)}/flights`,
    payload
  );

/** Update an existing flight within a quotation */
const putFlight = (id: number, payload: IFlightQuotation, flightId: number) =>
  axios.put<ICodeResponse | IErrorResponse>(
    `/quotations/${String(id)}/flights/${flightId}`,
    { ...payload }
  );

/** Removes flight from quotation */
const deleteFlight = (id: number, flightId: number) =>
  axios.delete<ICodeResponse | IErrorResponse>(
    `/quotations/${String(id)}/flights/${flightId}`
  );

/** Add service to quotation */
const patchService = (id: number, payload: IQuotationService) =>
  axios.patch<ICodeResponse, IErrorResponse>(
    `/quotations/${String(id)}/services`,
    payload
  );

/** Update quotation service */
const putService = (
  id: number,
  payload: IQuotationService,
  serviceId: number
) =>
  axios.patch<ICodeResponse, IErrorResponse>(
    `/quotations/${String(id)}/services/${serviceId}`,
    payload
  );

/** Delete quotation service */
const deleteService = (id: number, serviceId: number) =>
  axios.delete<ICodeResponse, IErrorResponse>(
    `/quotations/${String(id)}/services/${String(serviceId)}`
  );

/** Create a quotation from accommodation */
const postAccommodation = (payload: IPostQuotationAccomodation) =>
  axios.post<ICodeResponse | IErrorResponse>(
    '/quotations/accommodations',
    payload
  );

/** Add acommodation to quotation */
const patchAccommodation = (
  id: number,
  option: number,
  payload: IQuotationAccomodation
) =>
  axios.patch<ICodeResponse | IErrorResponse>(
    `/quotations/${String(id)}/${String(option)}/accommodations`,
    payload
  );

/** Updates accommodation */
const putAccommodation = (
  id: number,
  accommodationId: number,
  payload: IQuotationAccomodation
) =>
  axios.put<ICodeResponse | IErrorResponse>(
    `/quotations/${String(id)}/accommodations/${accommodationId}`,
    payload
  );

/** Deletes accommodation */
const deleteAccommodation = (id: number, accommodationId: number) =>
  axios.delete<ICodeResponse | IErrorResponse>(
    `/quotations/${String(id)}/accommodations/${accommodationId}`
  );

export default {
  getSuggestions,
  getAccomodationOptions,
  getQuotation,
  postQuotation,
  updateQuotation,
  deleteQuotation,
  getQuotations,
  postFlight,
  patchFlight,
  putFlight,
  deleteFlight,
  patchService,
  putService,
  deleteService,
  postAccommodation,
  patchAccommodation,
  putAccommodation,
  deleteAccommodation
};
