import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
`;

export const Title = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #31174b;
  padding: 16px;
`;

export const Card = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  max-width: 350px;
  overflow: hidden;
  transition: .6s;
  top: 0;

  &:hover {
    top: 10px;
  }
`;

export const PriceCurrency = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ff6700;
`;

export const Price = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  color: #ff6700;
  display: flex;
  align-items: baseline;
`;

export const Cuotas = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #70b42a;
  margin-bottom: 16px;
`;

export const BuyButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 19px;
  position: absolute;
  width: 112px;
  height: 36px;
  right: 0px;
  bottom: 16px;
  background: #ff6700;
  border-radius: 14px 0px 0px 14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

export const FeeHolder = styled.div`
  padding-left: 16px;
`;
