import styled from 'styled-components';
import * as COLORS from 'Constants/colors';

export default Base => styled(Base)`
  transition: 0.3s ease-out;
  position: relative;
  cursor: pointer;

  > a {
    text-decoration: none;
  }

  &:hover {
    transform: translateY(9px);
  }

  .package-availability-card-container {
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    box-shadow: ${COLORS.shadows.four};
    background-color: ${COLORS.white};
    position: relative;
  }

  .title-holder {
    padding: 16px;
  }
  .prices-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .description-holder {
    display: flex;
    padding: 0 16px 16px 16px;

    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .package-availability-card-image {
    position: relative;

    .img {
      overflow: hidden;
      width: 100%;
      height: 259px;
      object-fit: cover;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
    }

    .icons-holder {
      position: absolute;
      background-image: linear-gradient(
        to top,
        ${props => props.color},
        ${props => props.color}
      );
      height: 28px;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: 0 16px;
      border-top-right-radius: 14px;

      span {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .tag-holder {
      position: absolute;
      background-color: ${COLORS.orange};
      height: 28px;
      top: 12px;
      right: 0;
      display: flex;
      align-items: center;
      padding: 0 16px;
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
    }
  }
`;
