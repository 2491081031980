export enum CheckoutSteps {
  'Pasajeros',
  'Contacto y Facturacion',
  'Medios de Pago'
}

export interface ICheckoutPassenger {
  type: string;
  code: string;
  passengerCode: string;
  id: number;
}

export interface IFormPassenger {
  id: string;
  firstName: string;
  lastName: string;
  dobDay: string;
  dobMonth: string;
  dobYear: string;
  gender: number;
  nationality: string;
  documentType: number;
  document: string;
  travelerCode: string;
  useInfoForInvoice: boolean;
}

export interface IListItem {
  id: number | string;
  name: string | number;
}

export enum AtcPaymentResponseCodes {
  PENDING = 0,
  APPROVED = 100,
  INVALID_DATA = 102,
  REPEAT_TRANSACTION = 104,
  PARTIAL_PAYMENT = 110,
  GENERAL_ERROR = 150,
  TIMEOUT_ERROR_SERVER = 151,
  TIMEOUT_ERROR_SERVICE = 152,
  APPROVED_BUT_REJECT_IN_CIBER = 200,
  QUESTION_BY_BANK = 201,
  EXPIRED_CARD = 202,
  REJECTED_CARD = 203,
  INSUFFICIENT_FUNDS = 204,
  STOLEN_CARD = 205,
  ISSUING_BANK_NOT_AVAILABLE = 207,
  INACTIVE_CARD = 208,
  CREDIT_LIMIT_REACHED = 210,
  INVALID_CVN = 211,
  INPUT_FILE_NEGATIVE = 221,
  ACCOUNT_FROZEN = 222,
  APPROVED_BUT_REJECT_CVN_IN_CIBER = 230,
  INVALID_ACCOUNT = 231,
  NOT_ACCEPTED_CARD = 232,
  GENERAL_DECLINE = 233,
  PROBLEM_CIBER_ACCOUNT = 234,
  PROCESSOR_FAILURE = 236,
  INVALID_CARD = 240,
  CARDHOLDER_IS_ENROLLED = 475,
  PAYER_AUTHENTICATION_FAILED = 476,
  TRANSACTION_DECLINED = 481,
  APPROVED_BUT_REJECT_BY_CONFIG_IN_CIBER = 520
}

export interface IAtcCheckPaymentResponse {
  responseCode: AtcPaymentResponseCodes;
  description: string;
}

export interface IManageResponseResult {
  alertType: 'error' | 'warning' | 'success';
  message: string;
}
