export enum MonthsEnum {
  jan = 1,
  feb,
  mar,
  apr,
  may,
  jun,
  jul,
  aug,
  sep,
  oct,
  nov,
  dec
}

export interface MonthByIndex {
  index: MonthsEnum;
  name: string;
}

export const MonthsList: MonthByIndex[] = [
  {
    index: MonthsEnum.jan,
    name: 'Ene'
  },
  {
    index: MonthsEnum.feb,
    name: 'Feb'
  },
  {
    index: MonthsEnum.mar,
    name: 'Mar'
  },
  {
    index: MonthsEnum.apr,
    name: 'Abr'
  },
  {
    index: MonthsEnum.may,
    name: 'May'
  },
  {
    index: MonthsEnum.jun,
    name: 'Jun'
  },
  {
    index: MonthsEnum.jul,
    name: 'Jul'
  },
  {
    index: MonthsEnum.aug,
    name: 'Ago'
  },
  {
    index: MonthsEnum.sep,
    name: 'Sep'
  },
  {
    index: MonthsEnum.oct,
    name: 'Oct'
  },
  {
    index: MonthsEnum.nov,
    name: 'Nov'
  },
  {
    index: MonthsEnum.dec,
    name: 'Dic'
  }
];

export interface Price {
  amount: number;
  currency: string;
}

export interface PackagePriceByMonth {
  month: MonthsEnum;
  price: Price;
}

export interface PricesProps {
  month: string | number;
  color: string;
  amount: number;
  minMax: { min: number; max: number };
  currency: string;
}

export interface PriceProps {
  color: string;
  value: number;
  currency: string;
  minMax: { min: number; max: number };
}
