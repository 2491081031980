import React from 'react';
import styled from 'styled-components';
import '@brainhubeu/react-carousel/lib/style.css';
import Carousel from '@brainhubeu/react-carousel';
import Divider from 'Components/Divider';
import { IPricesInfo } from 'Features/destinations/types';
import Icon from 'Components/Icon';
import { returnCarrouselItems } from '../../helpers';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import CardInformation from 'Features/destinations/components/CardInformation';

const IconHolder = styled.div`
  width: 40px;
  height: 40px;
`;

interface PricesInfoProps extends IPricesInfo {
  currency: string;
  name: string;
}

const PricesInfo = (props: PricesInfoProps) => {
  const breakpoints = useBreakpoints();

  if (
    !props.comboMeal &&
    !props.bigMac &&
    !props.milk &&
    !props.apples &&
    !props.superMarketBeer &&
    !props.coke &&
    !props.gas &&
    !props.dinner &&
    !props.pub &&
    !props.cocktail &&
    !props.beer &&
    !props.wine &&
    !props.capuccino &&
    !props.taxi &&
    !props.publicTransport
  ) {
    return null;
  }

  return (
    <div>
      <Divider titleText={`Precios útiles en ${props.name}:`} />
      <Carousel
        slidesPerPage={returnCarrouselItems(breakpoints)}
        arrows
        infinite
        arrowLeft={
          <IconHolder>
            <Icon name="mv-arrow-left" color="white" size={20} />
          </IconHolder>
        }
        arrowRight={
          <IconHolder>
            <Icon name="mv-arrow-right" color="white" size={20} />
          </IconHolder>
        }
        addArrowClickHandler>
        {props.comboMeal && (
          <CardInformation
            title="Almuerzo Diario"
            description="Almuerzo basico (incluyendo bebida)"
            icon="mv-family-restaurant"
            value={`USD ${props.comboMeal}`}
          />
        )}
        {props.bigMac && (
          <CardInformation
            title="Combo"
            description="Combo en un restaurant de comida rapida"
            icon="mv-fastfood"
            value={`USD ${props.bigMac}`}
          />
        )}
        {props.milk && (
          <CardInformation
            title="Leche"
            description="1lt de leche entera"
            icon="mv-milk"
            value={`USD ${props.milk}`}
          />
        )}
        {props.apples && (
          <CardInformation
            title="Manzanas"
            description="1kg de manzanas"
            icon="mv-apple"
            value={`USD ${props.apples}`}
          />
        )}
        {props.superMarketBeer && (
          <CardInformation
            title="Cerveza"
            description="1/2 litro de cerveza en un supermercado"
            icon="mv-beer-bottle"
            value={`USD ${props.superMarketBeer}`}
          />
        )}
        {props.coke && (
          <CardInformation
            title="Refresco"
            description="2 lts de bebida cola"
            icon="mv-cola-bottle"
            value={`USD ${props.coke}`}
          />
        )}
        {props.gas && (
          <CardInformation
            title="Combustible"
            description="1 lt de combustible"
            icon="mv-gasoline"
            value={`USD ${props.gas}`}
          />
        )}
        {props.dinner && (
          <CardInformation
            title="Cena"
            description="Cena para dos en un restaurant italiano en un lugar turistico (incluyendo entrada)"
            icon="mv-food-wine"
            value={`USD ${props.dinner}`}
          />
        )}
        {props.pub && (
          <CardInformation
            title="Pub"
            description="Cena basica en un bar"
            icon="mv-dinner"
            value={`USD ${props.pub}`}
          />
        )}
        {props.cocktail && (
          <CardInformation
            title="Cocktail"
            description="Valor de una copa/trago"
            icon="mv-cocktail"
            value={`USD ${props.cocktail}`}
          />
        )}
        {props.beer && (
          <CardInformation
            title="Cerveza en un bar"
            description="Medio litro de cerveza en un pub o bar"
            icon="mv-beer"
            value={`USD ${props.beer}`}
          />
        )}
        {props.wine && (
          <CardInformation
            title="Botella de vino"
            description="Precio de una botella de vino de mesa 1lt"
            icon="mv-wine-bottle"
            value={`USD ${props.wine}`}
          />
        )}
        {props.capuccino && (
          <CardInformation
            title="Capuccino"
            description="Capuccino en un area turistica"
            icon="mv-breakfast"
            value={`USD ${props.capuccino}`}
          />
        )}
        {props.taxi && (
          <CardInformation
            title="Taxi"
            description="Viaje promedio en taxi (8 km ó 5 millas)"
            icon="mv-taxi"
            value={`USD ${props.taxi}`}
          />
        )}
        {props.publicTransport && (
          <CardInformation
            title="Boleto"
            description="Precio del boleto de omnibus"
            icon="mv-transport"
            value={`USD ${props.publicTransport}`}
          />
        )}
      </Carousel>
    </div>
  );
};

export default PricesInfo;
