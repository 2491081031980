/**
 *
 * MpcMptbAvailability
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import AirAvailability from '../AirAvailability';
import MPCAvailability from '../MpcAvailability';
import CardAlert from 'Components/CardAlert';
import Text from 'Components/Text';

import { formatFullWithoutYear } from 'Utils/dates';

const TextHolder = styled.div`
  margin-top: 40px;
`;

const MpcMptbAvailability = ({
  className,
  hasMPCData,
  hasMPTBData,
  mpcAvailability,
  mptbAvailability,
  hasError,
  messagesError,
  routesData
}) => {
  const destination = routesData[0].destinationName ? routesData[0].destinationName.split(',')[0] : routesData[0].destination;

  return (
    <div className={cs(className)}>
      {hasError && (
        <CardAlert
          className="container-alert"
          color="important"
          nameIcon="mv-information-circle"
          title={messagesError}>
          <Text paragraph>
            Puede ser que no haya lugar en los vuelos o que no haya vuelos en esos
            días.
            <br />
            Por favor, intenta modificar la búsqueda.
          </Text>
        </CardAlert>
      )}

      {hasMPCData && !hasError && (
        <MPCAvailability availability={mpcAvailability} />
      )}
      {hasMPTBData && !hasError && (
        <>
          <TextHolder>
            <div className="container">
              <Text context="white" size="xmlarge">
                Estos son todos los resultados para volarte a{' '}
                {destination}{' '}
                {routesData[0].endDate && 'entre'} el{' '}
                {formatFullWithoutYear(routesData[0].initialDate)}{' '}
                {routesData[0].endDate &&
                  `y el ${formatFullWithoutYear(routesData[0].endDate)}`}{' '}
              </Text>
            </div>
          </TextHolder>
          <AirAvailability availability={mptbAvailability} />
        </>
      )}
    </div>
  )
};

MpcMptbAvailability.propTypes = {
  className: PropTypes.string.isRequired,
  hasMPCData: PropTypes.bool,
  hasMPTBData: PropTypes.bool,
  mpcAvailability: PropTypes.object,
  mptbAvailability: PropTypes.object,
  hasError: PropTypes.bool,
  messagesError: PropTypes.string,
  routesData: PropTypes.object
};

export default styled(MpcMptbAvailability)`
  .availability-holder {
    padding-top: 10px;

    .menu-tab-holder {
      display: flex;
      justify-content: center;
      width: 100%;

      > div {
        width: 100%;

        > button {
          width: 32%;
        }
      }
    }
  }

  .container-alert {
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;
    text-align: center;

    @media all and (max-width: 768px) {
      margin-top: 10px;
    }
  }
`;
