/**
 *
 * PageIndicator
 *
 */

import React from 'react';
import styled from 'styled-components';
import Text from 'Components/Text';
import Icon from 'Components/Icon';

interface PageIndicatorProps {
  total: number;
  current: number;
  onClickNext: () => void;
  onClickPrev: () => void;
  rightButtonQAId?: string;
  leftButtonQAId?: string;
}

const PageIndicator = ({
  total,
  current,
  onClickNext,
  onClickPrev,
  leftButtonQAId,
  rightButtonQAId
}: PageIndicatorProps) => {
  const leftDisabled = current <= 1;
  const rightDisabled = current >= total;
  return (
    <Container>
      <ButtonLeft
        onClick={onClickPrev}
        disabled={leftDisabled}
        data-qa-id={leftButtonQAId}>
        <Icon name="mv-arrow-left" color="white" />
        {
          // @ts-ignore
          <Text context="white" weight="semi-bold">
            Previa
          </Text>
        }
      </ButtonLeft>
      <TextContainer>
        {
          // @ts-ignore
          <Text>
            Página{' '}
            {
              // @ts-ignore
              <Text context="important" weight="semi-bold">
                {current}
              </Text>
            }{' '}
            de {total}
          </Text>
        }
      </TextContainer>
      <ButtonRight
        onClick={onClickNext}
        disabled={rightDisabled}
        data-qa-id={rightButtonQAId}>
        {
          // @ts-ignore
          <Text context="white" weight="semi-bold">
            Siguiente
          </Text>
        }
        <Icon name="mv-arrow-right" color="white" />
      </ButtonRight>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: stretch;
`;

const TextContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing.one} ${theme.spacing.four}`};
  background-color: ${({ theme }) => theme.colors.white};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.important};
  padding: ${({ theme }) => `${theme.spacing.one} ${theme.spacing.two}`};
  border: none;
  width: 100px;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

const ButtonRight = styled(Button)`
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.default};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.default};
`;

const ButtonLeft = styled(Button)`
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.default};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.default};
`;

export default PageIndicator;
