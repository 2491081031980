/**
 *
 * FilterRadioItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Radio from '../Radio';
import Badge from '../Badge';
import Text from '../Text';

const FilterRadioItem = ({
  className,
  checked,
  onClick,
  badgeText,
  label,
  value
}) =>
  value && badgeText ? (
    <div className={cs(className)}>
      <div className="left-item">
        <Radio checked={checked} onClick={() => onClick(value)} />
        <Text className="label">{label}</Text>
      </div>

      <div className="right-item">
        <Badge variation="orange" inverted>
          {badgeText}
        </Badge>
      </div>
    </div>
  ) : null;

FilterRadioItem.propTypes = {
  className: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  badgeText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  value: PropTypes.string
};

export default styled(FilterRadioItem)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

  .left-item {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .right-item {
    margin-left: 8px;

    & > span {
      min-width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .label {
    margin-left: 13px;
  }
`;
