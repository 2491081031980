const primary = {
  orange: '#FF6700' as const,
  violet: '#8167a3' as const,
  darkBlue: '#171f4b' as const,
  arena: '#faeed7' as const
};

const secondary = {
  arenaDark: '#F1D7A5' as const,
  blue: '#2776B8' as const,
  yellow: '#FCB427' as const
};

const grays = {
  white: '#FFFFFF' as const,
  grey: '#EAECEB' as const,
  darkGrey: '#C1C6C4' as const,
  /** Alias 7b Grey */
  meVueloGrey: '#7B7B7B' as const,
  black: '#1A1A20' as const
};

const dataVisualization = {
  /** Cold 0% - 10% */
  cold1: '#747FBC' as const,
  /** Cold 10% - 30% */
  cold2: '#96CAED' as const,
  /** Cold 30% - 50% */
  cold3: '#C0DBED' as const,
  /** Warm 50% - 70% */
  warm1: '#F8E6C3' as const,
  /** Warm 70% - 90% */
  warm2: '#FFCE6F' as const,
  /** Warm 90% - 100% */
  warm3: '#FFA162' as const
};

const status = {
  positive: '#70b42a' as const,
  /** Alias SkyBlue */
  info: '#71bced' as const,
  warning: '#ff0000' as const,
  wine: '#31174b' as const,
  orangeLight: '#FF9724' as const
};

export enum Gradient {
  toTop = 0,
  toRight = 90,
  toBottom = 180,
  toLeft = 260
}

type Deg = Gradient | number;

export default {
  primary,
  status,
  secondary,
  grays,
  dataVisualization,
  all: {
    ...primary,
    ...status,
    ...secondary,
    ...grays,
    ...dataVisualization
  },
  gradients: {
    orange: (deg: Deg = 90) =>
      `linear-gradient(${deg}deg, #FF6700 0%, #FF9724 100%)`,
    blue: (deg: Deg = 90) =>
      `linear-gradient(${deg}deg, #006495 0%, #5CBEEA 100%)`,
    violet: (deg: Deg = 90) =>
      `linear-gradient(${deg}deg, #612F94 0%, #7C67A3 100%)`,
    green: (deg: Deg = 90) =>
      `linear-gradient(${deg}deg, #1F672D 0%, #8BB136 100%)`,
    grey: (deg: Deg = 90) =>
      `linear-gradient(${deg}deg, #848484 0%, #BABEBC 100%)`,
    wine: (deg: Deg = 180) =>
      `linear-gradient(${deg}deg, #31174B 0%, #4E2973 47.4%, #713F90 100%)`
  }
};
