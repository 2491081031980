/**
 *
 * PricePassengerDetail
 *
 */

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Button from '../Button';
import Text from '../Text';
import Card from '../Card';
import Modal from '../Modal';

import FullPriceDetail from '../FullPriceDetail';
import { getFormatPrice } from '../../Utils';

const PricePassengerDetail = ({ className, flightPrice, localPrice }) => {
  const [showDetails, setShowDetails] = useState(false);

  const { pricePassengerDetail, breakdown } = flightPrice;
  const priceDetails = pricePassengerDetail[0];
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Card
      borderRadius={'square'}
      className={cs(['cardSegment', 'large'])}
      header="DETALLE"
      headerCentered
      headerShadow
      horizontalPadding={false}>
      <div className={cs(className)}>
        <div className="passenger">
          <div className="averagePrice">
            <Text weight="semi-bold" size="xlarge" context="important">
              {getFormatPrice(priceDetails.totalPrice, 'USD')}
            </Text>
            {localPrice && (
              <Text weight="semi-bold" size="small" context="important">
                {getFormatPrice(
                  localPrice.pricePassengerDetail[0].totalPrice,
                  localPrice.pricePassengerDetail[0].currency,
                  true
                )}
              </Text>
            )}
            <Text className={cs(['important', 'semi-bold', 'small'])}>
              Precio final por adulto
            </Text>
          </div>

          {breakdown.map((item, index) => {
            if (index === breakdown.length - 1 && item.price > 0) {
              return (
                <Fragment key={index}>
                  <hr />
                  <div className="prices" key={index}>
                    <Text weight="semi-bold" size="small" context="important">
                      {item.label}
                    </Text>
                    <Text weight="semi-bold" size="small" context="important">
                      {getFormatPrice(item.price, item.currency)}
                    </Text>
                    {localPrice && (
                      <Text
                        paragraph
                        weight="semi-bold"
                        size="small"
                        context="important">
                        {getFormatPrice(
                          localPrice.breakdown[localPrice.breakdown.length - 1]
                            .price,
                          localPrice.breakdown[localPrice.breakdown.length - 1]
                            .currency,
                          true
                        )}
                      </Text>
                    )}
                  </div>
                </Fragment>
              );
            }
            if (item.price > 0) {
              return (
                <div key={item.label} className="prices">
                  <Text size="small">{item.label}</Text>
                  <Text size="small">
                    {getFormatPrice(item.price, item.currency)}
                  </Text>
                </div>
              );
            }
          })}

          <Button
            onClick={toggleDetails}
            context="information"
            className={cs(['xs', 'detailsBtn'])}>
            Detalles
          </Button>
        </div>
      </div>
      <Modal
        show={showDetails}
        onClickOverlay={toggleDetails}
        closeButton={{ onClick: toggleDetails }}
        header="DETALLE DE LOS PRECIOS"
        headerCentered>
        <FullPriceDetail
          pricePassengerDetail={pricePassengerDetail}
          pricePassengerDetailLocal={
            localPrice && localPrice.pricePassengerDetail
          }
        />
      </Modal>
    </Card>
  );
};

PricePassengerDetail.propTypes = {
  className: PropTypes.string.isRequired,
  flightPrice: PropTypes.object.isRequired,
  localPrice: PropTypes.object.isRequired
};

export default styled(PricePassengerDetail)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .passenger {
    margin-bottom: 12px;
    width: 55%;

    &:last-child {
      margin-bottom: 0;
    }

    .averagePrice {
      display: flex;
      flex-direction: column;
      font-size: 11.2px;
      line-height: 1;
      margin-bottom: 20px;
    }

    .prices {
      display: flex;
      flex-wrap: wrap;
      font-size: 11.2px;
      justify-content: space-between;

      > *:first-child {
        margin-right: 12px;
        text-align: left;
      }

      > *:last-child {
        text-align: right;
      }

      p {
        width: 100%;
        flex: 1 1 auto;
        text-align: right;
      }
    }

    hr {
      border-color: ${COLORS.darkGrey};
      border-top-width: 2px;
    }

    .detailsBtn {
      margin-top: 5px;
    }
  }
`;
