/**
 *
 * MobileThankYou
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';

import Text from '../Text';
import ButtonCardPassenger from '../ButtonCardPassenger';
import TravelInformation from '../TravelInformation';
import FullPriceDetail from '../FullPriceDetail';

import background from '../../Assets/images/globo-nubes-sol.png';
import { useUser } from '../../Features/users/utils';
import MetaTags from 'react-meta-tags';

const MobileThankYouCorpo = ({
  className,
  corpoData,
  selectedAvailability,
  reserveCode
  // FIXME:
  // eslint-disable-next-line no-unused-vars
  // onClickMyTravels
}) => {
  const agentName = useUser().username;
  return (
    <div className={cs(className, 'background-gradient')}>
      <MetaTags>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
        <meta name="googlebot" content="noindex" />
        <meta name="googlebot-news" content="nosnippet" />
      </MetaTags>
      <img className="cover" src={background} alt="mevuelo-background" />
      <div className="content background-gradient">
        <Text
          context="secondary"
          size="xlarge"
          weight="semi-bold"
          className="margin">
          ¡Muchas gracias {agentName}!
        </Text>
        <Text context="secondary" size="large" className="margin">
          Nuestra tripulación ya está trabajando en la reserva.
        </Text>
        <Text paragraph className="margin">
          En la próximas horas recibirás un email con el estado de la misma. Si
          no lo recibes, revisá la carpeta de correo no deseado o spam, a veces
          los correos se esconden ahí.
        </Text>
        <Text paragraph className="margin">
          También podrás revisar el estado de la reserva <b>{reserveCode}</b> en
          tu <b>Panel de Viajero</b> y en <b>Mis Viajes</b>.
        </Text>
        <div>
          {/* TODO: se agrega cuando tengamos esta funcionalidad */}
          {/* <Button context="purple" onClick={onClickMyTravels}>
          IR A MIS VIAJES
        </Button> */}
        </div>
      </div>
      {selectedAvailability.engine === 'vuelos' && (
        <>
          <div className="divider-title">
            <Text context="white" size="large" weight="bold">
              Detalle de la compra
            </Text>
          </div>

          <div className="content background-white">
            {corpoData.passengers &&
              corpoData.passengers.map((passenger, i) => (
                <ButtonCardPassenger
                  key={i}
                  firstNames={passenger.firstNames}
                  lastNames={passenger.lastNames}
                  countryName={passenger.country && passenger.country.name}
                  document={passenger.document}
                  documentName={
                    passenger.documentType && passenger.documentType.name
                  }
                  className="margin"
                />
              ))}
          </div>
        </>
      )}
      {selectedAvailability.engine === 'vuelos' && (
        <>
          <div className="divider-title">
            <Text context="white" size="large" weight="bold">
              Detalle de los precios
            </Text>
          </div>
          <div className="content background-white">
            <FullPriceDetail
              className="prices"
              pricePassengerDetail={
                selectedAvailability.flightPrice.pricePassengerDetail
              }
              pricePassengerDetailLocal={
                selectedAvailability.localPrice &&
                selectedAvailability.localPrice.pricePassengerDetail
              }
            />
            {/*
            {selectedAvailability.engine === 'hotel-details' && (
              <div>HOTEL PRICE DETAILS</div>
            )} */}
          </div>
        </>
      )}

      <TravelInformation availability={selectedAvailability} />
    </div>
  );
};

MobileThankYouCorpo.propTypes = {
  className: PropTypes.string.isRequired,
  corpoData: PropTypes.object.isRequired,
  selectedAvailability: PropTypes.object.isRequired,
  reserveCode: PropTypes.string
};

export default styled(MobileThankYouCorpo)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .cover {
    width: 105%;
    object-fit: cover;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 100%;
  }

  .no-padding {
    padding: 0;
  }

  .background-gradient {
    background-image: ${COLORS.gradientSky};
  }

  .background-white {
    background-color: ${COLORS.white};
  }

  .margin {
    margin-bottom: 8px;
  }

  .divider-title {
    width: 100%;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.violeta};
  }

  .badge {
    display: flex;
    flex-direction: column;
    border-radius: 16.5px;
    background-color: ${COLORS.golf};
    width: 100%;
    align-items: center;
    padding: 10px 15px;

    .flightPrice {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .localPrice {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .icon-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
  }

  .prices {
    width: 100%;
  }

  .transference-container {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .transference-button {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
  }
`;
