/**
 *
 * ArticleContainer
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

// Components
import Package from '../Package';
import PackageContactForm from '../PackageContactForm';
import Divider from 'Components/Divider';
import Text from 'Components/Text';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import PackageAvailabilityCard from '../PackageAvailabilityCard';
import { PaymentPromoBanner } from '../../../../Components/PaymentPromoBanner';

// Constants
import * as COLORS from 'Constants/colors';

// Utils
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { buildPackageUrl } from 'Features/packages/helpers';
import Link from 'Components/Link';
import { routes } from 'Constants';
import PackagePricesGraph from '../PackagePricesGraph';
import PackageError from '../PackageError';

const BackLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999999999999999;
`;

const PackageContainer = ({
  className,
  actions,
  packageItemData,
  error,
  upSelling,
  keyword,
  id,
  loading
}) => {
  const { largeDown, mediumDown } = useBreakpoints();
  const itemClassName = cs('item', {
    medium: mediumDown,
    large: largeDown
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    actions.getPackage(keyword, id);
  }, [keyword, id]);

  useEffect(() => {
    if (!largeDown) {
      actions.getUpselling(id);
    }
  }, [id]);

  return (
    <>
      <div className={cs(className, { mobile: mediumDown })}>
        {loading && <BackLoader />}
        {!mediumDown && (
          <Link to={routes.package}>
            <IconButton context="information">
              <Icon name="mv-list" color="white" />
              Volver al listado
            </IconButton>
          </Link>
        )}

        <div className={cs('package-holder', { mobile: largeDown })}>
          {error && <PackageError />}
          {packageItemData && (
            <>
              <Package packageDetail={packageItemData} />
              {!largeDown && (
                <div className="form-container">
                  <div className={cs('form-holder', { mobile: largeDown })}>
                    <PackageContactForm
                      prices={packageItemData.prices}
                      basePrice={packageItemData.basePrice}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {!largeDown && upSelling.length > 0 && (
          <div className="bottom-content">
            <PaymentPromoBanner />
            {packageItemData && packageItemData.pricesByMonth.length > 1 && (
              <>
                <GraphContainer>
                  <Text context='white' size='large' weight='semi-bold'>Precio "desde" por mes:</Text>
                  <PackagePricesGraph
                    pricesData={packageItemData.pricesByMonth}
                  />
                </GraphContainer>
              </>
            )}
            <Divider
              titleText="Otros paquetes que te pueden interesar"
              size="default"
              context="default"
            />

            <div
              className={cs(
                'more-packages',
                { large: largeDown },
                { medium: mediumDown }
              )}>
              {upSelling.map(pckg => (
                <div className={itemClassName} key={pckg.id + pckg.title}>
                  <PackageAvailabilityCard
                    image={pckg.image}
                    price={pckg.lowerPrice.amount}
                    currency={pckg.lowerPrice.currency}
                    title={pckg.title}
                    icons={pckg.compositeIcons}
                    color={pckg.shadowColor ? pckg.shadowColor : COLORS.green}
                    lowerLocalPrice={pckg.lowerLocalPrice}
                    duration={pckg.duration}
                    travelOn={pckg.travelOn}
                    url={buildPackageUrl(pckg.keyword, pckg.id)}
                    basePrice={pckg.basePrice}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {largeDown && packageItemData && (
          <div className="fixed-contact-form">
            <PaymentPromoBanner />
            {packageItemData && packageItemData.pricesByMonth.length > 1 && (
              <>
                <GraphContainer>
                  <Text context='white' size='large' weight='semi-bold'>Precio "desde" por mes:</Text>
                  <PackagePricesGraph
                    pricesData={packageItemData.pricesByMonth}
                  />
                </GraphContainer>
              </>
            )}
            <PackageContactForm
              prices={packageItemData.prices}
              basePrice={packageItemData.basePrice}
            />
          </div>
        )}

        {mediumDown && (
          <div className="go-back-mobile">
            <Link to={routes.package}>
              <IconButton context="information">
                <Icon name="mv-list" color="white" />
                Volver al listado
              </IconButton>
            </Link>
          </div>
        )}
        {}
      </div>
    </>
  );
};

PackageContainer.propTypes = {
  className: PropTypes.string.isRequired,
  keyword: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  packageItemData: PropTypes.object,
  error: PropTypes.object,
  upSelling: PropTypes.array,
  history: PropTypes.object,
  actions: PropTypes.shape({
    getPackage: PropTypes.func.isRequired,
    getUpselling: PropTypes.func.isRequired
  }),
  loading: PropTypes.bool
};

const GraphContainer = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.two};
  .icon {
    margin-right: ${({ theme }) => theme.spacing.one};
  }
`;

export default styled(PackageContainer)`
  padding-top: ${({ theme }) => theme.spacing.four};
  max-width: 1110px;
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.spacing.four};
  &.mobile {
    margin-top: 0;
    padding-top: 0;

    .go-back-mobile {
      display: flex;
      justify-content: center;
      margin-top: ${({ theme }) => theme.spacing.four};
    }

    .fixed-contact-form {
      margin-top: ${({ theme }) => theme.spacing.four};
    }
  }

  .package-holder {
    display: flex;
    justify-content: center;
    align-items: stretch;

    &.mobile {
      flex-direction: column;
    }

    .form-holder {
      top: 99px;
      position: sticky;
      margin-left: 10px;

      &.mobile {
        top: auto;
        position: relative;
        width: 100%;
        max-width: 722px;
        margin: 50px auto 0 auto;
      }
    }
  }

  .bottom-content {
    margin-top: ${({ theme }) => theme.spacing.eight};
  }

  .more-packages {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1100px;

    .item {
      width: 33%;
      padding: 10px;

      &.large {
        width: 50%;
      }

      &.medium {
        width: 100%;
      }
    }
  }
`;
