// Vendors
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { Redirect } from 'react-router';

// Components
import Text from 'Components/Text';
import Modal from 'Components/Modal';
import ModalConfirmation from 'Components/ModalConfirmation';
import TermsAndConditions from 'Components/TermsAndConditions';
import HotelCheckout from 'Features/hotels/components/HotelCheckout';
import AirCheckout from 'Features/flights/components/AirCheckout';

// Actions
import { resetToEngine } from 'ReduxConfig/availability';

// Constants
import { white } from 'Constants/colors';

// Utils
import { isMobile } from 'Utils';
import { engines } from '../../Constants';
import CorpoNotRefundableConfirmation from '../../Features/hotels/components/CorpoNotRefundableConfirmation';

const CheckoutCorpo = ({
  dispatch,
  availability,
  engine,
  runProcess,
  corpoData,
  handleTacModal,
  hotelProperty,
  tacModal
}) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [corpoNotRefundableShow, setCorpoNotRefundableShow] = useState(false);

  const isFlights =
    engine === engines.FLIGHTS.name || engine === engines.FLIGHTS.name + 'MPC';
  const isHotels = engine === engines.HOTELS.name || engine === 'hotel-details';

  const pageRef = useRef();

  const onCloseCheckout = engineName => () => {
    dispatch(resetToEngine(engineName));
    dispatch(push('/'));
  };

  const handleCloseConfirm = () => {
    setConfirmationOpen(old => !old);
  };

  const handleFinish = () =>
    isFlights || (isHotels && availability.hotelInformation.hasFreeCancellation)
      ? runProcess()
      : setCorpoNotRefundableShow(true);

  const handleShowNotRefundableModal = () => {
    setCorpoNotRefundableShow(false);
  };

  if (!availability) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {isHotels &&
        !availability.hotelInformation.hasFreeCancellation &&
        corpoNotRefundableShow && (
        <CorpoNotRefundableConfirmation
          showNotRefundableModal={corpoNotRefundableShow}
          onShowNotRefundableModal={handleShowNotRefundableModal}
          onAccept={runProcess}
        />
      )}
      <ModalConfirmation
        show={confirmationOpen}
        headerCentered
        onAccept={isMobile ? onCloseCheckout(engine) : undefined}
        onCancel={handleCloseConfirm}
        onClickOverlay={handleCloseConfirm}
        header="Confirmación">
        <Text>Seguro que desea salir</Text>
        <Text>Se perderán los datos ingresados</Text>
      </ModalConfirmation>

      <Modal
        show={tacModal}
        className="tac-modal"
        closeButton={{ onClick: () => handleTacModal() }}
        header="TÉRMINOS y CONDICIONES"
        onClickOverlay={handleTacModal}
        size="large"
        headerCentered>
        <TermsAndConditions />
      </Modal>

      <CheckoutPage className="checkout-page" ref={pageRef}>
        <Wrapper>
          {isFlights && (
            <AirCheckout
              containerRef={pageRef}
              availability={availability}
              onFinish={handleFinish}
              onClose={setConfirmationOpen}
            />
          )}

          {isHotels && (
            <HotelCheckout
              containerRef={pageRef}
              availability={hotelProperty}
              userData={corpoData}
              onFinish={handleFinish}
              onClose={setConfirmationOpen}
            />
          )}
        </Wrapper>
      </CheckoutPage>
    </>
  );
};

const CheckoutPage = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 50px;

  .shrink {
    max-width: 95%;
  }

  .w100 {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    margin-top: 140px;

    & > .mv-card-content {
      padding: 0;
    }
  }

  @media screen and (max-width: 560px) {
    min-height: auto;
    overflow-y: auto;
    height: calc(100vh - 70px);
    background-color: ${white};
    position: fixed;
    align-items: flex-start;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: ${({ theme }) => theme.zIndex.checkoutPage};
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 15px;
  width: 1200px;
  max-width: 100%;

  .content {
    flex: 1;
    background-color: ${white};
    border-radius: 14px;
    margin-right: 16px;

    .pay-methods {
      padding: 20px;
      width: 60%;
      margin: 0 auto;
      min-height: 200px;

      .payment-opt {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .mv-radio {
        label {
          font-size: 18px !important;
          font-weight: 500;
        }
      }

      @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      @media screen and (max-width: 574px) {
        width: 100%;
        padding: 5px;
      }
    }

    .next-button {
      text-align: center;
      padding: 20px;

      @media screen and (max-width: 960px) {
        display: none;
      }
    }

    @media screen and (max-width: 768px) {
      margin: 0;
      width: 100%;
    }
  }

  .details {
    width: 360px;
    border-radius: 14px;
    text-align: center;

    .hidden {
      display: none;
    }

    &-card {
      > div.mv-card-content {
        padding: 0;

        .air-segments {
          padding: 0 !important;
        }
      }

      .passenger {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }

  .close-checkout {
    position: fixed;
    right: 5px;
    top: 5px;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
  }
`;

CheckoutCorpo.propTypes = {
  runProcess: PropTypes.func,
  availability: PropTypes.object,
  dispatch: PropTypes.func,
  engine: PropTypes.string,
  tacModal: PropTypes.bool,
  handleTacModal: PropTypes.func,
  steps: PropTypes.object,
  corpoData: PropTypes.object,
  hotelProperty: PropTypes.object
};

export default CheckoutCorpo;
