/**
 *
 * MpcItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import * as COLORS from 'Constants/colors';
import { mpcItemStatus } from 'Features/flights/constants';
import { getClassColors } from 'Features/flights/helpers';

const MpcItem = ({
  className,
  status,
  currency,
  price,
  description,
  totalPrice,
  onClick,
  empty,
  isCenter
}) => {
  if (empty && status === mpcItemStatus.EMPTY)
    return <div className={cs(className, 'EMPTY')}></div>;

  const { minPrice, middlePrice, maxPrice } = status;
  const itemStatus = getClassColors(price, minPrice, middlePrice, maxPrice);

  return (
    <div
      onClick={onClick}
      className={cs(className, `${itemStatus}`, { isCenter })}>
      {status && (
        <>
          <div>
            <span className="currency">
              <Text context="important" weight="bold">
                {currency}
              </Text>
            </span>
            <Text context="important" size="large" weight="bold">
              {price}
            </Text>
          </div>
          <div>
            <Text size="small">{description}</Text>
            <br />
            <Text size="small">{totalPrice}</Text>
          </div>
          <div className="content-divider" />
        </>
      )}
    </div>
  );
};

MpcItem.propTypes = {
  className: PropTypes.string.isRequired,
  status: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      minPrice: PropTypes.number,
      middlePrice: PropTypes.number,
      maxPrice: PropTypes.number
    })
  ]),
  currency: PropTypes.string,
  price: PropTypes.number,
  description: PropTypes.string,
  totalPrice: PropTypes.string,
  onClick: PropTypes.func,
  empty: PropTypes.bool,
  isCenter: PropTypes.bool
};

export default styled(MpcItem)`
  width: 151px;
  height: 91px;
  display: flex;
  border: solid 1px ${COLORS.important};
  background-color: ${COLORS.white};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;

  .content-icon {
    height: 30px;
  }

  .content-spinner {
    img {
      width: 24px;
      height: 24px;
    }
  }

  &.isCenter {
    box-shadow: 4.6px 3.9px 6px 0 rgba(0, 0, 0, 0.35);
    z-index: 1;
    border: 3px solid #8167a3;
  }

  &.MORE_EXPENSIVE {
    background-color: ${COLORS.white};
  }

  &.CHEAPER_MIN {
    background-color: ${COLORS.white};
  }

  &.CHEAPER_60 {
    background-color: ${COLORS.sandyBeach};
  }

  &.CHEAPER_80 {
    background-color: ${COLORS.peachYellow};
  }

  &.CHEAPER_MAX {
    background-color: ${COLORS.grandis};

    .content-divider {
      background: ${COLORS.gradientInfoRight};
      width: 92px;
      height: 2px;
    }
  }

  &.EMPTY {
    background-color: ${COLORS.navBarGrey};
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: 4.6px 3.9px 6px 0 rgba(0, 0, 0, 0.35);
  }

  .currency {
    margin-right: 5px;
  }

  &.empty {
    border: none;
  }
`;
