/**
 *
 * MobilePaxDetailAir
 *
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import PlusMinusSelector from 'Components/PlusMinusSelector';
import Select from 'Components/Select';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import { classItemsSelect, edadesOpciones } from 'Constants/mocks';
import * as COLORS from 'Constants/colors';
import useOutsideClick from '../../../../Utils/hooks/useOutsideClick';
import Checkbox from 'Components/Checkbox';
import MobileNavBar from 'Components/MobileNavBar';

import { types } from 'Features/hotels';

const MobilePaxDetailAir = ({
  className,
  onClose,
  adults,
  kids,
  cabinCode,
  actions
}) => {
  const refOpenMenu = useRef();

  useOutsideClick(refOpenMenu, () => {
    onClose();
  });

  const {
    onChangeAdults,
    onChangeChildren,
    onChangeChildrenAge,
    onToggleChildrenSeat,
    onChangeCabinCode
  } = actions;

  return (
    <div className={cs(className)}>
      <div className="modaloptions">
        <div className="modaloptionsheader">
          <Text className="modaloptionsheadertext">
            ¿Cuántos pasajeros viajan?{' '}
          </Text>
          <div className="modaloptionsheaderclose" onClick={onClose}>
            <Icon name="mv-cross" />
          </div>
        </div>
        <div className="modaloptionscontent">
          <div className="modaldivider">
            <Icon className="paxicon" name="mv-user" />
            <Text className="modaldividertext">Pasajeros</Text>
          </div>
          <div className="paxcontainer">
            <div className="paxlabelcontainer">
              <Text className="f-weight-500" context="large">
                Adultos
              </Text>
              <Text context="important">Desde los 18 años</Text>
            </div>
            <PlusMinusSelector
              stateless
              onIncrement={onChangeAdults(types.INCREMENT)}
              onDecrement={onChangeAdults(types.DECREMENT)}
              value={adults}
              minValue={1}
            />
          </div>

          <div className="paxcontainer">
            <div className="paxlabelcontainer">
              <Text className="f-weight-500" context="large">
                Menores
              </Text>
              <Text context="important">Hasta los 17 años</Text>
            </div>
            <PlusMinusSelector
              stateless
              onIncrement={onChangeChildren(types.INCREMENT)}
              onDecrement={onChangeChildren(types.DECREMENT)}
              value={kids.length}
              minValue={0}
            />
          </div>

          {kids.map((childAge, index) => (
            <div className="paxcontainer" key={index}>
              <div className="paxlabelcontainer">
                <Text className="f-weight-500" context="large">
                  Edad del menor {index + 1}
                </Text>
                <Text context="important">Al finalizar el viaje</Text>
              </div>
              <div className="paxchildcontainer">
                <Select
                  selectedValue={edadesOpciones[0].id}
                  items={edadesOpciones}
                  onSelect={onChangeChildrenAge(index)}
                />
                {childAge.age <= 1 && (
                  <Text className="tariff" context="important">
                    Tarifa de bebé
                  </Text>
                )}
                {childAge.age > 1 && childAge.age <= 11 && (
                  <Text className="tariff" context="important">
                    Tarifa de niño
                  </Text>
                )}
                {childAge.age > 11 && childAge.age <= 17 && (
                  <Text className="tariff" context="important">
                    Tarifa de adulto
                  </Text>
                )}
                {childAge.age <= 1 && (
                  <Checkbox
                    className="custom-icon"
                    checked={childAge.seat}
                    onClick={onToggleChildrenSeat(index)}>
                    Con asiento
                  </Checkbox>
                )}
              </div>
            </div>
          ))}
          <div className="modaldivider">
            <Icon className="paxicon" name="mv-flight-class" />
            <div className="modaldividertext">Clases de servicio</div>
          </div>
          <div className="paxclasscontainer">
            <Select
              selectedValue={cabinCode}
              items={classItemsSelect}
              onSelect={onChangeCabinCode}
            />
          </div>
          <MobileNavBar className="bottom-bar">
            <Button
              context="purple"
              weight="semi-bold"
              onClick={onClose}
              className="apply-button">
              APLICAR
            </Button>
          </MobileNavBar>
        </div>
      </div>
    </div>
  );
};

MobilePaxDetailAir.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  actions: PropTypes.shape({
    onChangeAdults: PropTypes.func.isRequired,
    onChangeChildren: PropTypes.func.isRequired,
    onChangeChildrenAge: PropTypes.func.isRequired,
    onToggleChildrenSeat: PropTypes.func.isRequired,
    onChangeCabinCode: PropTypes.func.isRequired
  }),
  adults: PropTypes.number.isRequired,
  kids: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      seat: PropTypes.bool
    })
  ),
  cabinCode: PropTypes.string.isRequired
};

export default styled(MobilePaxDetailAir)`
  display: block;
  background-color: ${COLORS.white};
  width: 100%;

  .buttonclose {
    border: 0;
  }

  .modaloptions {
    background-color: ${COLORS.white};
  }

  .modaldivider {
    width: 100%;
    height: 41px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: ${COLORS.shadows.one};
    background-color: ${COLORS.violeta};
  }

  .modaldividertext {
    flex-grow: 2;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    text-align: left;
    margin-left: 20px;
    color: ${COLORS.white};
  }

  .modaloptionsheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 35px;
    padding-top: 23px;
    padding-right: 20px;
    padding-bottom: 23px;
    padding-left: 20px;
    box-shadow: ${COLORS.shadows.one};
    background-color: ${COLORS.white};
  }

  .modaloptionsheadertext {
    flex-grow: 2;
    height: 18px;
    font-size: 18px;
    font-style: italic;
    line-height: 0.56;
    letter-spacing: 0.18px;
    text-align: left;
    color: ${COLORS.middleGrey};
  }

  .modaloptionsheaderclose {
    flex-grow: 1;
    text-align: right;
    color: ${COLORS.darkGrey};

    :hover {
      cursor: pointer;
    }
  }

  .modaloptionscontent {
    width: 100%;
    height: 173px;
    font-size: 9px;
    font-weight: 500;
    color: ${COLORS.middleGrey};
  }

  .paxcontainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    padding: 0 18px;
  }

  .paxlabelcontainer {
    display: flex;
    flex-direction: column;
  }

  .paxchildcontainer {
    display: flex;
    flex-direction: column;
  }

  .paxclasscontainer {
    margin: 0 auto;
    padding: 0 18px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${COLORS.shadows.one};
    background-color: ${COLORS.white};
  }

  .tariff {
    margin-top: 5px;
  }

  .paxicon {
    margin-left: 15px;
    font-size: 20px;
    color: ${COLORS.white};
  }

  .custom-icon {
    .checkbox-square {
      .icon {
        position: absolute;
        bottom: -7px;
        left: -1px;
        font-size: 20px;
      }
    }
  }

  .icon-margin {
    margin-left: 15px;
  }

  .f-weight-500 {
    font-weight: 500;
  }

  .bottom-bar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
