/**
 *
 * Tabs
 *
 */

import React from 'react';
import styled from 'styled-components';

interface searchType {
  id: string;
  label: string;
  path: string;
}

export interface TabProps {
  items: searchType[];
  isMobile: boolean;
  actions: TabActions;
  type: string;
}

interface TabActions {
  setTypeFlight: (type: string) => void;
}

const Tabs = ({ items, isMobile = false, actions, type }: TabProps) => {
  const clickTab = (type: string) => () => {
    actions.setTypeFlight(type);
  };

  return (
    <Container>
      <HorizontalList isMobile={isMobile}>
        {items.map(item => (
          <ListItem
            isMobile={isMobile}
            active={item.id === type}
            key={item.id}
            onClick={clickTab(item.id)}>
            {item.label}
          </ListItem>
        ))}
      </HorizontalList>
    </Container>
  );
};

interface HorizontalListProps {
  isMobile: boolean;
}
const Container = styled.div`
  display: flex;
  align-self: flex-start;
`;

const HorizontalList = styled.ul<HorizontalListProps>`
  list-style-type: none;
  margin: 0;
  display: flex;
  ${({ isMobile, theme }) =>
    isMobile &&
    `
    margin: 0 auto;
    padding: 0;
    border-radius: ${theme.borderRadius.default};
    box-shadow: ${theme.shadows.one};
    margin-bottom: ${theme.spacing.two};
  `}
`;

interface ListItemProps {
  active?: boolean;
  isMobile?: boolean;
}

const ListItem = styled.li<ListItemProps>`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.meVueloGrey};
  transition: 0.2s ease-in-out;

  /* Custom */
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: ${({ theme }) => `${theme.spacing.one} ${theme.spacing.four}`};

  /* Custom shadow */
  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.colors.orange};
    color: ${theme.colors.white};
    font-weight: ${theme.text.fontWeights.bold};
    box-shadow: 4px 0 5px -2px rgba(0, 0, 0, 0.25);
    z-index: 1;
  `}
  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.default};
    ${({ isMobile, theme }) =>
    isMobile && `border-bottom-left-radius: ${theme.borderRadius.default}`}
  }

  &:last-child {
    border-top-right-radius: ${({ theme }) => theme.borderRadius.default};
    ${({ isMobile, theme }) =>
    isMobile && `border-bottom-right-radius: ${theme.borderRadius.default}`}
  }
`;

export default Tabs;
