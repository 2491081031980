import {
  AtcPaymentResponseCodes,
  IAtcCheckPaymentResponse,
  IFormPassenger,
  IListItem,
  IManageResponseResult
} from './types';

export const getDOBYearItems = () => {
  const today = new Date();
  const presentYear = today.getFullYear();
  const hundredYearsBack = presentYear - 100;
  let itemsToRetrun = [];
  for (let i = presentYear; i > hundredYearsBack; i--) {
    itemsToRetrun.push({
      id: i,
      name: i
    });
  }
  return itemsToRetrun;
};

export const getDOBDays = () => {
  let itemsToReturn = [];
  for (let i = 1; i <= 12; i++) {
    itemsToReturn.push({
      id: i,
      name: i
    });
  }
  return itemsToReturn;
};

export const getCountries = countries =>
  [...countries].map(country => ({
    id: country.code,
    name: country.name
  }));

export const getArrayIndexByObjId = (array, id) => {
  let index = null;
  array.forEach((i, indexNumber) => {
    if (i.id == id) {
      index = indexNumber;
    }
  });
  return index;
};

export const getInitialPassengersValues = (passengers): IFormPassenger[] =>
  passengers.map(
    (passenger, index): IFormPassenger => ({
      id: passenger.id,
      firstName: '',
      lastName: '',
      dobDay: '',
      dobMonth: '',
      dobYear: '',
      gender: 1,
      nationality: '',
      documentType: 1,
      document: '',
      travelerCode: passenger.code,
      useInfoForInvoice: index === 0
    })
  );

export const listItemToString = (value: {
  id: number | string;
  name: number | string;
}) => String(value.name);
export const returnItemById = (
  list: IListItem[],
  id: number | string
): IListItem => list.find(i => i.id === id);

export const manageAtcResponse = (
  response: IAtcCheckPaymentResponse
): IManageResponseResult => {
  switch (response.responseCode) {
    case AtcPaymentResponseCodes.APPROVED:
      return { alertType: 'success', message: 'El pago fue aprobado' };
    case AtcPaymentResponseCodes.PENDING:
      return {
        alertType: 'warning',
        message: 'El pago está en proceso de confirmación'
      };
    default:
      return { alertType: 'error', message: response.description };
  }
};
