/**
 *
 * BlogContainer
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import cs from 'classnames';
import Masonry from 'react-masonry-css';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import BlogCard from '../BlogCard';
import {
  returnArticleUrl,
  returnCreatedDate,
  getCategoryNameBySlug
} from 'Features/blog/helpers';
import BlogCarouselItemCategory from '../BlogCarouselItemCategory';
import BlogSearch from '../BlogSearch';
import * as COLORS from 'Constants/colors';
import { debounce } from 'lodash';
import { searchDebounceTime, routes, breakpoints } from 'Constants';
import Text from 'Components/Text';
import { Helmet } from 'react-helmet';
import Tag from 'Components/Tag';
import Link from 'Components/Link';
import { BasicBlogArticle, BlogCategories } from 'Services/Blog/types';
import MenuTab from 'Components/MenuTab';
import { useHistory } from 'react-router';
import { CategoryType } from '.';
import Spinner from 'Components/Spinner';

interface BlogContainerProps {
  className?: string;
  actions: BlogContainerActions;
  categories: BlogCategories | null;
  selectedCategory?: string;
  categoryType: CategoryType;
  articles: BasicBlogArticle[];
  articlesLoading: boolean;
  categoriesLoading: boolean;
}

interface BlogContainerActions {
  getArticles: (category?: string) => void;
  getSearchArticles: (info: string) => void;
  getCategories: () => void;
}

const BlogContainer = ({
  className,
  actions,
  articles,
  categories,
  selectedCategory,
  categoryType,
  articlesLoading,
  categoriesLoading
}: BlogContainerProps) => {
  const [value, setValue] = useState('');

  const history = useHistory();

  useEffect(() => {
    actions.getCategories();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (categories) {
      actions.getArticles(getCategoryNameBySlug(selectedCategory, categories));
    }
  }, [selectedCategory, categories]);

  const { largeDown, mediumDown } = useBreakpoints();
  const itemClassName = cs('item', {
    medium: mediumDown,
    large: largeDown
  });

  const fetchData = info => {
    setValue(info);
    if (info.length > 3) {
      actions.getSearchArticles(info);
    }
    if (!info) {
      actions.getArticles();
    }
    return;
  };

  const returnColumnQuantity = () => {
    if (mediumDown) {
      return 1;
    } else if (largeDown) {
      return 2;
    } else {
      return 3;
    }
  };

  const debouncedFetchData = debounce(fetchData, searchDebounceTime.DESKTOP);

  const onSearchArticle = value => {
    debouncedFetchData(value);
  };

  const onClearSearch = () => {
    onSearchArticle('');
    setValue(null);
  };

  const handleMenuTab = (category: CategoryType) => () => {
    history.push(routes.blog.replace(':catType', category));
  };

  const sortedArticles = articles.sort(
    (y, x) => +new Date(x.createdDate) - +new Date(y.createdDate)
  );

  const showErrors = !articlesLoading && !articles.length;
  return (
    <div className={cs(className)}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog de Viajes | mevuelo.com</title>
        <meta
          name="description"
          content="Encontrá tips de compra de vacaciones, tips de viajes, guías de viaje, hoteles, boletos aéreos, destinos e historias de viajeros en el blog de MeVuelo. El mundo necesita viajeros."
        />
        <meta
          name="keywords"
          content="blog de viajes, guía de viajes, tips de compra de viajes, tips de viajes"
        />
        <meta property="og:title" content="Blog de Viajes | mevuelo.com" />
        <meta
          property="og:description"
          content="Encontrá tips de compra de vacaciones, tips de viajes, guías de viaje, hoteles, boletos aéreos, destinos e historias de viajeros en el blog de MeVuelo. El mundo necesita viajeros."
        />
      </Helmet>
      <StyledMenuTab>
        <MenuTab.Item
          active={categoryType === 'region'}
          onClick={handleMenuTab('region')}>
          Región
        </MenuTab.Item>
        <MenuTab.Item
          active={categoryType === 'tips'}
          onClick={handleMenuTab('tips')}>
          Tips
        </MenuTab.Item>
        <MenuTab.Item
          active={categoryType === 'tripStyle'}
          onClick={handleMenuTab('tripStyle')}>
          Style
        </MenuTab.Item>
      </StyledMenuTab>
      {categoriesLoading ? (
        <Spinner block minHeight={220} />
      ) : (
        categories && (
          <BlogCarouselItemCategory
            categories={categories[categoryType]}
            selectedCategory={selectedCategory}
          />
        )
      )}
      <TagHolder>
        {selectedCategory && categories && (
          <Link to={routes.blog}>
            <Tag onClick={() => {}} color={COLORS.important}>
              {getCategoryNameBySlug(selectedCategory, categories)}
            </Tag>
          </Link>
        )}
        {value && (
          // TODO
          // @ts-ignore
          <Tag onClick={onClearSearch} color={COLORS.important}>
            {value}
          </Tag>
        )}
      </TagHolder>
      {
        // TODO
        // @ts-ignore
        <BlogSearch
          onChange={onSearchArticle}
          onClick={onClearSearch}
          value={value}
        />
      }
      <div className="container">
        {articlesLoading ? (
          <Spinner block minHeight={350} />
        ) : (
          <Masonry
            breakpointCols={returnColumnQuantity()}
            className="card-holder"
            columnClassName="card-holder_column">
            {sortedArticles.map(item => {
              const date = returnCreatedDate(item.createdDate);
              return (
                <div className={itemClassName} key={item.name}>
                  {
                    // TODO
                    // @ts-ignore
                    <BlogCard
                      imageUrl={item.thumbnailPhoto.publicUrl}
                      avatarUrl={item.author.photo.publicUrl}
                      author={item.author.name}
                      url={returnArticleUrl(item.url)}
                      date={date}
                      timeRead={item.timeToRead}
                      title={item.name}
                    />
                  }
                </div>
              );
            })}
          </Masonry>
        )}
      </div>
      {showErrors && articles.length === 0 && (
        <div className="not-found-articles">
          <Text context="white" weight="bold" size="xlarge" paragraph>
            No hay resultados disponibles, por favor, modificar la búsqueda
          </Text>
        </div>
      )}
    </div>
  );
};

const TagHolder = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const StyledMenuTab = styled(MenuTab)`
  margin-bottom: ${({ theme }) => theme.spacing.eight};
`;

export default styled(BlogContainer)`
  display: block;
  padding-top: ${({ theme }) => theme.spacing.four};
  min-height: 1080px;
  max-width: 1110px;
  margin: 0 auto;

  &::before {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 1080px;
    background-image: linear-gradient(
      to bottom,
      rgba(23, 31, 75, 0) 49%,
      ${COLORS.newBackground}
    );
  }

  .card-holder {
    display: flex;
    width: auto;
    z-index: 1;
    position: relative;
  }

  .not-found-articles {
    p {
      text-align: center;
    }
  }

  .card-holder_column {
    background-clip: padding-box;

    & > div {
      margin-bottom: 30px;
    }

    .not-found-articles {
      p {
        text-align: center;
      }
    }

    @media screen and (min-width: ${breakpoints.medium}px) {
      padding-left: 30px; /* gutter size */
    }
  }
`;
