import styled, { css } from 'styled-components';

// Types
import { MeteorologyProps } from './types';

// Contants
import { breakpoints } from 'Constants';

// Utils

const minHeight = 30;
const maxHeight = 115;
const minWidth = 60;
const maxWidth = 250;

const scaleValue = (
  value: number,
  from: [number, number],
  to: [number, number]
) => {
  const scale = (to[1] - to[0]) / (from[1] - from[0]);
  const capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
  return ~~(capped * scale + to[0]);
};

const getHeight = (value: number, min: number, max: number) => {
  const scaled = scaleValue(value, [min, max], [minHeight, maxHeight]);
  return scaled;
};

const getWidth = (value: number, min: number, max: number) => {
  const scaled = scaleValue(value, [min, max], [minWidth, maxWidth]);
  return scaled;
};

export const Meteorology = styled.div<MeteorologyProps>`
  width: 70px;
  height: ${minHeight}px;
  border-radius: ${({ theme }) => theme.borderRadius.smooth};
  position: relative;
  bottom: 0;
  text-align: center;
  transition: 0.6s;

  ${({ type, color, value, theme, minMax }) =>
    type === 'temperature' &&
    css`
    height: ${minHeight + getHeight(value, minMax.min, minMax.max)}px;

    ${color === 'shipCove' &&
      css`
        background-color: ${theme.colors.shipCove};
      `}

    ${color === 'cornFlower' &&
      css`
        background-color: ${theme.colors.cornFlower};
      `}

    ${color === 'spindle' &&
      css`
        background-color: ${theme.colors.spindle};
      `}

    ${color === 'givry' &&
      css`
        background-color: ${theme.colors.givry};
      `}

    ${color === 'goldenRod' &&
      css`
        background-color: ${theme.colors.goldenRod};
      `}

    ${color === 'atomicTangerine' &&
      css`
        background-color: ${theme.colors.atomicTangerine};
      `}
    
    @media screen and (max-width: ${breakpoints.medium}px) {
      width: ${minWidth + getWidth(value, minMax.min, minMax.max)}px;
    }
  `}

  ${({ type, color, value, theme, minMax }) =>
    type === 'sea' &&
    css`
  height: ${minHeight + getHeight(value, minMax.min, minMax.max)}px;

    ${color === 'cornFlower' &&
      css`
        background-color: ${theme.colors.cornFlower};
      `}


    ${color === 'shipCove' &&
      css`
        background-color: ${theme.colors.shipCove};
      `}

    ${color === 'spindle' &&
      css`
        background-color: ${theme.colors.spindle};
      `}
    
    @media screen and (max-width: ${breakpoints.medium}px) {
      width: ${minWidth + getWidth(value, minMax.min, minMax.max)}px;
    }
  `}

  span {
    position: absolute;
    font-size: 16px;
    font-weight: 600;
    left: 0;
    right: 0;
    top: 2.5px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    height: 30px;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.default};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.default};

    span {
      text-align: right;
      margin-right: ${({ theme }) => theme.spacing.three};
    }
  }
`;
