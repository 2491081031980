/**
 *
 * DestinationsBottom
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import TipsDestination from '../TipsDestination';
import MeteorologyTemperature from '../MeteorologyTemperature';
import BestDeals from 'Features/destinations/components/BestDeals';
import { ogLanguage } from '../../../../Constants/index';

import Banners from '../Banners';
import {
  IAirport,
  IBestZone,
  IDealButtons,
  IDidYouKnow,
  IEvents,
  IHowToMove,
  IMeteorologyTemperature,
  IPracticalInfo,
  IPricesInfo,
  ITips,
  IWhatToDo,
  TSeason
} from '../../types';
import DidYouKnow from 'Features/destinations/components/DidYouKnow';
import PricesInfo from 'Features/destinations/components/PricesInfo';
import PracticalInfo from 'Features/destinations/components/PracticalInfo';
import { StatsFlightPrice } from 'Services/FlightsGraphql/types';
import { PaymentPromoBanner } from '../../../../Components/PaymentPromoBanner';

interface DestinationsBottomPops {
  name: string;
  bestZones?: IBestZone[];
  temperature?: IMeteorologyTemperature | null;
  tips?: ITips[];
  whatToDos?: IWhatToDo[];
  howToMove?: IHowToMove[];
  events?: IEvents[];
  didYouKnows?: IDidYouKnow[];
  prices?: IPricesInfo;
  currency?: string;
  practicalInfo?: IPracticalInfo;
  airports?: IAirport[];
  banners?: IDealButtons[];
  timeToTravel?: string;
  packageUrl?: string;
  seasonsEneMar?: TSeason;
  seasonsAbrJun?: TSeason;
  seasonsJulSet?: TSeason;
  seasonsOctDic?: TSeason;
  metaTitle?: string;
  metaDescription?: string;
  metaKeywords?: string;
  flightsBanners: StatsFlightPrice[];
  destination: string;
}

const DestinationsBottom = (props: DestinationsBottomPops) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.metaTitle}</title>
      <meta name="description" content={props.metaDescription} />
      <meta name="keywords" content={props.metaKeywords} />
      <meta property="og:site_name" content="mevuelo.com" />
      <meta property="og:title" content={`${props.metaTitle}. mevuelo.com `} />
      <meta property="og:description" content={props.metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={ogLanguage} />
    </Helmet>
    <Container>
      {props.flightsBanners && props.flightsBanners.length > 0 && (
        <BestDeals
          banners={props.flightsBanners}
          packageUrl={props.packageUrl}
          name={props.name}
          destination={props.destination}
        />
      )}
      <PaymentPromoBanner />
      {props.airports && props.airports.length > 0 && (
        <Banners
          items={props.airports}
          forceOneColumn
          title={`Principales aeropuertos de ${props.name}`}
        />
      )}

      {props.practicalInfo && (
        <PracticalInfo {...props.practicalInfo} name={props.name} />
      )}
      {props.bestZones && props.bestZones.length > 0 && (
        <Banners
          items={props.bestZones}
          title={`¿Cuáles son las mejores zonas para alojarse en ${props.name}?`}
        />
      )}

      {props.temperature &&
        props.seasonsAbrJun &&
        props.seasonsEneMar &&
        props.seasonsJulSet &&
        props.seasonsOctDic && (
        <MeteorologyTemperature
          title={`¿Cuál es la mejor época para viajar a ${props.name}?`}
          subtitle="Información promedio por mes"
          description={props.timeToTravel}
          temperaturesData={props.temperature}
          seasons={[
            props.seasonsEneMar,
            props.seasonsAbrJun,
            props.seasonsJulSet,
            props.seasonsOctDic
          ]}
        />
      )}
      {props.prices && (
        <PricesInfo
          currency={props.currency}
          {...props.prices}
          name={props.name}
        />
      )}
      {props.whatToDos && props.whatToDos.length > 0 && (
        <Banners
          items={props.whatToDos}
          title={`¿Qué hacer en ${props.name}?`}
        />
      )}
      {props.howToMove && props.howToMove.length > 0 && (
        <Banners
          items={props.howToMove}
          title={`Transporte en ${props.name}`}
        />
      )}
      {props.didYouKnows && props.didYouKnows.length > 0 && (
        <DidYouKnow didYouKnows={props.didYouKnows} name={props.name} />
      )}
      {props.events && props.events.length > 0 && (
        <Banners items={props.events} title={`Eventos en ${props.name}`} />
      )}
      {props.tips && props.tips.length > 0 && (
        <TipsDestination tips={props.tips} name={props.name} />
      )}
    </Container>
  </>
);

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.two};
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;

  & > div {
    margin-bottom: ${({ theme }) => theme.spacing.eight};
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export default DestinationsBottom;
