import React from 'react';
import styled from 'styled-components';
import Heading from 'Components/Heading';
import Icon from 'Components/Icon';
import Link from 'Components/Link';

const IconHolder = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  box-shadow: ${props => props.theme.shadows.two};
  display: flex;
  justify-content: center;
  align-content: center;
`;

const ArrowHolder = styled.div`
  width: 24px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const Container = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.smooth};
  box-shadow: ${props => props.theme.shadows.two};
  padding: ${props => props.theme.spacing.two}
    ${props => props.theme.spacing.four};
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export interface DealButtonProps {
  title: string;
  price: string;
  url: string;
}

const DealButton = (props: DealButtonProps) => (
  <Link
    className="thisIsNeeded"
    // alt={props.title}
    to={props.url}>
    <Container>
      <IconHolder>
        <Icon color="violeta" size={32} name="mv-flights" />
      </IconHolder>
      <TextContainer>
        <Heading type="h5" color="violeta" fontWeight="semibold">
          {props.title}
        </Heading>
        <Heading type="h5" color="important" fontWeight="bold">
          {props.price}
        </Heading>
      </TextContainer>
      <ArrowHolder>
        <Icon color="important" size={32} name="mv-arrow-right" />
      </ArrowHolder>
    </Container>
  </Link>
);

export default DealButton;
