const apiActionTypes = [
  'SEARCH',
  'PROPERTY',
  'SUGGESTIONS',
  'VALIDATION',
  'CHECKRATE'
];
const actionTypes = {};
apiActionTypes.forEach(item => {
  actionTypes[`HOTELS_${item}_REQUEST`] = `HOTELS_${item}_REQUEST`;
  actionTypes[`HOTELS_${item}_SUCCESS`] = `HOTELS_${item}_SUCCESS`;
  actionTypes[`HOTELS_${item}_FAILURE`] = `HOTELS_${item}_FAILURE`;
});

export default {
  ...actionTypes,
  HOTELS_SET_CHECKIN: 'HOTELS_SET_CHECKIN',
  HOTELS_SET_CHECKOUT: 'HOTELS_SET_CHECKOUT',
  HOTELS_SET_DESTINATION: 'HOTELS_SET_DESTINATION',
  HOTELS_ADD_ROOM: 'HOTELS_ADD_ROOM',
  HOTELS_REMOVE_ROOM: 'HOTELS_REMOVE_ROOM',
  HOTELS_CHANGE_ADULTS: 'HOTELS_CHANGE_ADULTS',
  HOTELS_CHANGE_CHILDREN: 'HOTELS_CHANGE_CHILDREN',
  HOTELS_SET_ALLOW_PETS: 'HOTELS_SET_ALLOW_PETS',
  HOTELS_RESET_SEARCH: 'HOTELS_RESET_SEARCH',
  HOTELS_FROM_URL: 'HOTELS_FROM_URL',
  HOTELS_SUGGESTIONS_CLOSE_MENU: 'HOTELS_SUGGESTIONS_CLOSE_MENU',
  HOTELS_TOGGLE_MAP: 'HOTELS_TOGGLE_MAP',
  HOTELS_CLEAN_ERROR: 'HOTELS_CLEAN_ERROR',
  HOTELS_SET_DETAIL_MODE: 'HOTELS_SET_DETAIL_MODE',
  HOTELS_RESET_ENGINE: 'HOTELS_RESET_ENGINE',
  HOTELS_CHECKRATE_CLEAN_ERROR: 'HOTELS_CHECKRATE_CLEAN_ERROR'
};
