/**
 *
 * Scales
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import warningImg from '../../Assets/images/warning.png';

const Scales = ({ className, scales = [] }) => {
  const hasWarning =
    scales.filter(scaleItem => scaleItem.changeInfo.changeOfAirport).length > 0;
  return (
    <div className={cs(className)}>
      {scales.length ? (
        <div className={cs('label', { warning: hasWarning })}>
          {scales.length} {scales.length > 1 ? 'escalas' : 'escala'}
          {hasWarning && <img src={warningImg} alt="warning" />}
        </div>
      ) : (
        <div className="label directo">directo</div>
      )}
      <div className="timeline-holder">
        <div className={cs('timeline', { directo: scales.length })}></div>
        {scales.map((i, index) => (
          <div
            key={index}
            className={cs('scale-dot', {
              warning: i.changeInfo.changeOfAirport
            })}
          />
        ))}
      </div>
    </div>
  );
};

Scales.propTypes = {
  className: PropTypes.string.isRequired,
  scales: PropTypes.array,
  warningLegs: PropTypes.array
};

export default styled(Scales)`
  height: 38px;
  width: 91px;
  .directo {
    background-color: #2776b8 !important;
    color: white !important;
  }

  .label {
    height: 17px;
    font-family: Montserrat;
    font-size: 11px;
    border-radius: 10px;
    font-weight: 500;
    text-align: center;
    color: #171f4b;
    text-transform: uppercase;

    img {
      position: relative;
      left: 10px;
    }
  }

  .timeline-holder {
    position: relative;
    width: 100%;
    height: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5px;

    .timeline {
      height: 6px;
      position: absolute;
      top: 4px;
      background-color: #2776b8;
      width: 100%;
      border-radius: 5px;
    }
    .scale-dot {
      background-color: #2776b8;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      z-index: 1;

      &.warning {
        background-color: white;
        border: 2px solid #2776b8;
      }
    }
  }
`;
