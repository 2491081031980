// Vendors
import { useCallback, useState } from 'react';

// Utils
import { initialSchema } from './utils';

/*
 * @param {object} schema - Body of the validation logic
 * @param {object} schema.fields - Fields to handle
 * @param {object} schema.errors - Your error messages (has to be identical to schema.fields keys
 * @param {object} schema.validators - Functions to handle validations rules (has to be identical to schema.errors keys)
 * @param {function} callback - Will be fired when all errors are solved (or there's no errors directly).
 */
export const useValidatedForm = (schema = initialSchema, callback) => {
  const [fields, setFields] = useState(schema.fields);
  const [errors, setErrors] = useState({});
  const isDisabled =
    schema.errors &&
    Object.keys(schema.errors).some(key => {
      if (
        typeof fields[key] === 'object' &&
        !Array.isArray(fields[key]) &&
        fields[key] !== null
      ) {
        return !fields[key].name;
      }

      return !fields[key];
    });
  let tempErrors = {};

  const handleReset = clearState => {
    setFields({ ...clearState });
  };

  const handleChange = useCallback(
    field => value => {
      if (value === null || value === undefined) {
        return setFields(oldState => ({
          ...oldState,
          [field]: !oldState[field]
        }));
      }

      setFields(oldState => ({
        ...oldState,
        [field]: value
      }));
    },
    [fields]
  );

  const handleValidate = e => {
    e && e.preventDefault();

    const handleErrors = (field, message) => {
      setErrors(oldErrors => ({
        ...oldErrors,
        [field]: message
      }));

      tempErrors = {
        ...tempErrors,
        [field]: message
      };
    };

    Object.keys(fields).forEach(key => {
      const setValidator = schema.validators[key];
      const field = fields[key];

      if (setValidator) {
        const validatorValue = schema.validators[key](field);

        if (Array.isArray(validatorValue)) {
          let index = null;

          validatorValue.some((validator, idx) => {
            if (validator) {
              index = idx;
              return true;
            }

            return false;
          });

          if (index === null) {
            return handleErrors(key, '');
          }

          return handleErrors(key, schema.errors[key][index]);
        }

        if (setValidator(field)) {
          return handleErrors(key, schema.errors[key]);
        }

        handleErrors(key, '');
      }
    });

    const errors = Object.keys(tempErrors);

    const isValid =
      errors.length > 0 && !errors.some(key => tempErrors[key].length > 0);

    if (isValid && !isDisabled) {
      let returned;

      if (callback) {
        returned = callback();
      }

      // TODO: Future use.
      if (!returned) {
        return isValid;
      }

      if (typeof returned === 'function') {
        returned(setFields);

        return isValid;
      } else {
        throw new SyntaxError('Returned value should be a function.');
      }
    }

    return isValid && !isDisabled;
  };

  return {
    fields,
    errors,
    isDisabled,
    handleValidate,
    handleChange,
    handleReset
  };
};
