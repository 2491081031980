import { AxiosInstance } from 'axios';
import { BlogArticles, BlogCategories, BlogArticle } from './types';
import * as queries from './queries';

export default function(axios: AxiosInstance) {
  const prefix = 'api';

  const getAllArticles = (category?: string) =>
    axios.post<BlogArticles>(
      prefix,
      category
        ? queries.getArticlesByCategory(category)
        : queries.getAllArticles()
    );

  const getLatestArticles = () =>
    axios.post<BlogArticles>(prefix, queries.getLatestArticles());

  const getAllCategories = () =>
    axios.post<BlogCategories>(prefix, queries.getAllCategories());

  const getArticleById = (id: string) =>
    axios.post<BlogArticle>(prefix, queries.getArticleById(id));

  const searchArticles = (search: string) =>
    axios.post<BlogArticles>(prefix, queries.searchArticles(search));

  const getArticleBySlug = (slug: string) =>
    axios.post<BlogArticle>(prefix, queries.getArticleBySlug(slug));

  return {
    getAllArticles,
    getLatestArticles,
    getAllCategories,
    getArticleBySlug,
    getArticleById,
    searchArticles
  };
}
