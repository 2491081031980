import { TextWeight, TextContext } from './../Text/types';
import { ToggleContext, ToggleSizes } from './types';

import theme from 'Theme';
export const getSizes = (size: ToggleSizes) => {
  switch (size) {
    case 'small':
      return {
        sliderHeight: '15px',
        sliderWidth: '28px',
        sliderBorder: '1px',
        switchHeight: '11px',
        siwtchWidth: '11px',
        translateX: '12px'
      };
    case 'big':
      return {
        sliderHeight: '30px',
        sliderWidth: '56px',
        sliderBorder: '3px',
        switchHeight: '22px',
        siwtchWidth: '22px',
        translateX: '24px'
      };
    case 'medium':
      return {
        sliderHeight: '22.5px',
        sliderWidth: '42px',
        sliderBorder: '2px',
        switchHeight: '16.5px',
        siwtchWidth: '16.5px',
        translateX: '18px'
      };
  }
};

export const getColor = (context: ToggleContext) => {
  switch (context) {
    case 'important':
      return theme.colors.important;
    case 'information':
      return theme.colors.information;
    case 'positive':
      return theme.colors.positive;
    case 'purple':
      return theme.colors.violeta;
  }
};

export const getLabelProps = (
  active: boolean,
  context: ToggleContext
): { weight: TextWeight; context?: TextContext } => {
  let textContext = '' as TextContext;
  switch (context) {
    case 'important':
      textContext = 'important';
      break;
    case 'information':
      textContext = 'information';
      break;
    case 'positive':
      textContext = 'positive';
      break;
    case 'purple':
      textContext = 'primary';
      break;
  }
  if (active) {
    return {
      weight: 'bold',
      context: textContext
    };
  }
  return {
    weight: 'semi-bold'
  };
};
