/**
 *
 * HotelInvoiceForm
 *
 */

// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Component
import HotelInvoiceForm from './HotelInvoiceForm';

// Actions
import { storageUserData } from 'ReduxConfig/checkout';

// Constants
import { selector } from './HotelInvoiceForm.selector';

const ConnectedInvoiceForm = props => {
  const state = useSelector(selector);
  const dispatch = useDispatch();
  const actions = {
    setUserData: data => dispatch(storageUserData(data))
  };

  return <HotelInvoiceForm actions={actions} {...props} {...state} />;
};

export default ConnectedInvoiceForm;
