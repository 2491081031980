/**
 *
 * MeteorologyBar
 *
 */

import React from 'react';

// Components
import MeteorologyColor from '../MeteorologyColor';
import MeteorologyMonth from '../MeteorologyMonth';
import { Container } from './styles';

// Types
import { MeteorologyBarProps } from './types';

const MeteorologyBar = ({
  month,
  temperature,
  type,
  symbol,
  minMax
}: MeteorologyBarProps) => (
  <Container>
    <MeteorologyColor
      type={type}
      value={temperature}
      symbol={symbol}
      minMax={minMax}
    />

    <MeteorologyMonth>{month}</MeteorologyMonth>
  </Container>
);

export default MeteorologyBar;
