import styled from 'styled-components';
import * as COLORS from 'Constants/colors';

export default DesktopPackageSearch => styled(DesktopPackageSearch)`
  display: block;
  min-height: 20px;
  max-width: 1140px;
  width: 100%;

  .tags-holder {
    display: flex;
    flex-direction: row-reverse;
  }

  .package-search_bottom-card {
    margin: 8px 0;
  }

  .form-item {
    display: flex;
    align-items: center;
    margin-right: 10px;

    label {
      margin: 0;
      white-space: nowrap;
      margin-right: 8px;
    }

    &.top-margin {
      margin-top: 8px;
    }

    &.to-right {
      justify-content: flex-end;
    }

    .form-item_label-holder {
      margin-right: 8px;
    }

    &.space-between {
      justify-content: space-between;
    }
  }

  .package-search_top-card {
    background-color: ${COLORS.white};
    border-radius: 14px;
    box-shadow: ${COLORS.shadows.three};
    overflow: hidden;

    .package-search-tabs_holder {
      display: flex;

      .package-search-tabs_item {
        transition: 0.2s;
        cursor: pointer;
        height: 46px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
          background-color: ${COLORS.important};
          box-shadow: ${COLORS.shadows.three};
        }
      }
    }

    .package-search-options_holder {
      min-height: 218px;
      box-shadow: ${COLORS.shadows.three};
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0;

      .icons-list {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        &.wrap {
          flex-wrap: wrap;
        }
      }
    }
  }

  @media screen and (max-width: 1140px) {
    width: 90%;

    .package-search_top-card {
      .package-search-options_holder {
        max-height: 218px;

        .icons-list_holder {
          overflow-y: auto;
          margin: 0 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icons-list {
            display: flex;
            justify-content: center;
            align-items: center;

            &.wrap {
              flex-wrap: nowrap;
            }
          }
        }
      }
    }
  }
`;
