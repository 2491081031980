export default {
  DESTINATIONS_SEARCH_REQUEST: 'DESTINATIONS_SEARCH_REQUEST',
  DESTINATIONS_SEARCH_SUCCESS: 'DESTINATIONS_SEARCH_SUCCESS',
  DESTINATIONS_SEARCH_FAILURE: 'DESTINATIONS_SEARCH_FAILURE',
  DESTINATIONS_SET_ACTIVE: 'DESTINATIONS_SET_ACTIVE',
  DESTINATIONS_RESET: 'DESTINATIONS_RESET',
  DESTINATIONS_SET_CURRENT_VIEW: 'DESTINATIONS_SET_CURRENT_VIEW',
  DESTINATIONS_FLIGHTS_REQUEST: 'DESTINATIONS_FLIGHTS_REQUEST',
  DESTINATIONS_FLIGHTS_SUCCESS: 'DESTINATIONS_FLIGHTS_SUCCESS',
  DESTINATIONS_FLIGHTS_FAILURE: 'DESTINATIONS_FLIGHTS_FAILURE',
  PACKAGES_BY_DESTINATION_REQUEST: 'PACKAGES_BY_DESTINATION_REQUEST',
  PACKAGES_BY_DESTINATION_FAILURE: 'PACKAGES_BY_DESTINATION_FAILURE',
  PACKAGES_BY_DESTINATION_SUCCESS: 'PACKAGES_BY_DESTINATION_SUCCESS'
};
