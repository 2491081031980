export const statusTypes = {
  unused: 'unused',
  ready: 'ready',
  error: 'error',
  loading: 'loading'
};

export const initialState = {
  status: statusTypes.unused,
  data: null
};
