import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PackageCommonList } from 'Features/packages/components/PackageCommonList';
import { mostWantedPackagesRequest } from 'Features/common/actions';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

import { Section } from './components/Section';
import { logosColumnQty, returnBanner, } from './helpers';
import { Banner, FormCard, LogosHolder, Row, PartnerHolder, PhoneCodeInput, MarginOnlyDesktop } from './styles';

import { SlideList } from './components/SlidesList';

import AMV from './assets/logos/AMV.svg';
import devoto from './assets/logos/devoto.jpg';
import disco from './assets/logos/disco.jpg';
import divino from './assets/logos/divino.svg';
import elDorado from './assets/logos/elDorado.svg';
import eurogen from './assets/logos/eurogen.svg';
import geant from './assets/logos/geant.svg';
import hawaii from './assets/logos/hawaii.svg';
import duty from './assets/logos/dutyFree.svg';
import laSensacion from './assets/logos/lasensacion.jpg';
import loi from './assets/logos/loi.jpg';
import magicCenter from './assets/logos/magicCenter.jpg';
import misBeneficios from './assets/logos/misBeneficios.svg';
import multiAhorro from './assets/logos/multiAhorro.svg';
import oca from './assets/logos/oca.svg';
import sodimac from './assets/logos/sodimac.svg';
import tiendaInglesa from './assets/logos/tiendaInglesa.svg';
import lg from './assets/logos/lg-logo.svg';

import Text from 'Components/Text';
import Select from 'Components/Select';
import Input from 'Components/Input';

import products from './products.json';
import TextArea from 'Components/TextArea';
import Button from 'Components/Button';
import { FormData } from './types';
import { useValidatedForm } from '../../Utils/hooks/useValidatedForm';
import { formErrors } from '../../Constants';
import { emailPattern } from '../../Constants/regex';
import { createPromoMessage } from './api';

interface FormState {
  isForm: boolean;
  wasSuccess: boolean;
}

export const LGPromo = () => {
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const [isValidForm, setIsValidForm] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const formInitialValue: FormData = {
    fullname: '',
    phone: '',
    email: '',
    message: '',
    countryCode: ''
  };
  const [requestState, setRequestState] = useState<FormState>({
    isForm: true,
    wasSuccess: true
  });

  const {
    fields,
    errors,
    handleChange,
    handleValidate,
    handleReset
  } = useValidatedForm({
    fields: formInitialValue,
    errors: {
      fullname: [
        formErrors.maxLength255,
        formErrors.nonNumbers,
        formErrors.wrongContentField
      ],
      email: [formErrors.wrongEmail, formErrors.maxLength255],
      countryCode: formErrors.required,
      phone: [formErrors.required],
      message: [formErrors.maxLength1000, formErrors.wrongContentField]
    },
    validators: {
      fullname: fullname => [
        fullname.length > 255,
        /\d/.test(fullname),
        fullname.length > 0 && fullname.trim().length == 0
      ],
      email: email => [email && !emailPattern.test(email), email.length > 255],
      phone: phone => [phone == ''],
      countryCode: countryCode => countryCode == '',
      message: message => [
        message.length > 1000,
        message.length > 0 && message.trim().length == 0
      ]
    }
  });

  const { mostWantedPackages, countries, localize } = useSelector(state => ({
    // @ts-ignore No idea what is going on here. it seems we are missing some types declration for the reducers root
    mostWantedPackages: state.common.mostWantedPackages,
    // @ts-ignore No idea what is going on here. it seems we are missing some types declration for the reducers root
    countries: state.places.countries,
    // @ts-ignore
    localize: state.appConfiguration.localize
  }));

  const listPhoneCodes = countries
    ? countries.map(country => ({
      id: `${country.phoneCode}`,
      name: `${country.name} ${country.phoneCode}`
    }))
    : [];

  useEffect(() => {
    dispatch(mostWantedPackagesRequest());
  }, []);

  useEffect(() => {
    if (localize) {
      handleChange('countryCode')(localize.phoneCode || '');
    }
  }, [localize]);

  const handleChangeSelect = (key: keyof FormData) => (e: any) => {
    handleChange(key)(e.id);
  };

  const handleRequest = async () => {
    if (isValidForm) {
      setIsSendingRequest(true);
      setRequestState({
        isForm: false,
        wasSuccess: await createPromoMessage(fields as FormData)
      });
      setIsSendingRequest(false);
    }
  };

  useEffect(() => {
    handleValidate();
  }, [fields]);

  useEffect(() => {
    setIsValidForm(areAllEmptyKeyOfErrors());
  }, [errors]);

  const areAllEmptyKeyOfErrors = () => {
    for (const key in errors) {
      if (errors.hasOwnProperty(key) && errors[key]) {
        return false;
      }
    }
    return true;
  };

  const resetForm = () => {
    handleReset({ ...formInitialValue, countryCode: localize.phoneCode || '' });
  };

  const handleBackToForm = () => {
    setRequestState({
      isForm: true,
      wasSuccess: true
    });
    resetForm();
    setIsSendingRequest(false);
  };

  return (
    <div>
      <a href="https://www.ltienda.com.uy/catalogo?grp=50" target="_blank" rel="noopener noreferrer">
        <Banner src={returnBanner(breakpoints)} alt="bannerLG" />
      </a>
      <Section title="Productos participantes">
        <SlideList slideList={products} />
      </Section>
      <Section title="¡Elegí tu destino!">
        <PackageCommonList packageList={mostWantedPackages} />
      </Section>
      <Section title="Locales participantes">
        <LogosHolder columns={logosColumnQty(breakpoints)}>
          <PartnerHolder>
            <a href='https://productos.misbeneficios.com.uy/agarra-vuelo' target="_blank" rel="noopener noreferrer">
              <img src={misBeneficios} alt="mis beneficios" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <a href='https://www.geant.com.uy/busca/?fq=H:8862' target="_blank" rel="noopener noreferrer">
              <img src={geant} alt="Geant" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <a href='https://www.tiendainglesa.com.uy/busqueda?0,0,LG,0' target="_blank" rel="noopener noreferrer">
              <img src={tiendaInglesa} alt="Tienda Inglesa" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <a href='https://www.multiahorro.com.uy/catalogo?grp=1346' target="_blank" rel="noopener noreferrer">
              <img src={multiAhorro} alt="MultiAhorro Hogar" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <a href='https://amvstore.com.uy/catalogo?grp=46' target="_blank" rel="noopener noreferrer">
              <img src={AMV} alt="AMV" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <MarginOnlyDesktop>
              <a href='https://magiccenter.com.uy/catalogo?grp=36' target="_blank" rel="noopener noreferrer">
                <img src={magicCenter} alt="Magic Center" />
              </a>
            </MarginOnlyDesktop>
          </PartnerHolder>
          <PartnerHolder>
            <MarginOnlyDesktop>
              <a href='https://www.disco.com.uy/lg?_q=LG&map=ft' target="_blank" rel="noopener noreferrer">
                <img src={disco} alt="Disco" />
              </a>
            </MarginOnlyDesktop>
          </PartnerHolder>
          <PartnerHolder>
            <a href='https://montevideo.shopdutyfree.com/es/lg-smart-tv-55-nano-model-nano75spa' target="_blank" rel="noopener noreferrer">
              <img src={duty} alt="duty free" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <MarginOnlyDesktop>
              <a href='https://www.devoto.com.uy/LG?_q=LG&sc=3' target="_blank" rel="noopener noreferrer">
                <img src={devoto} alt="Devoto" />
              </a>
            </MarginOnlyDesktop>
          </PartnerHolder>
          <PartnerHolder>
            <MarginOnlyDesktop> 
              <a href='https://loi.com.uy/buscar/?query=LG' target="_blank" rel="noopener noreferrer">
                <img src={loi} alt="LOI" />
              </a>
            </MarginOnlyDesktop>
          </PartnerHolder>
          <PartnerHolder>
            <MarginOnlyDesktop>
              <a href='https://www.lasensacion.com.uy/catalogo/promociones' target="_blank" rel="noopener noreferrer">
                <img src={laSensacion} alt="la Sensacion" />
              </a>
            </MarginOnlyDesktop>
          </PartnerHolder>
          <PartnerHolder>
            <a href='https://www.eldorado.com.uy/lg?_q=lg&map=ft' target="_blank" rel="noopener noreferrer">
              <img src={elDorado} alt="el Dorado" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <a href='https://www.sodimac.com.uy/sodimac-uy/search?Ntt=LG' target="_blank" rel="noopener noreferrer">
              <img src={sodimac} alt="Sodimac" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <a href='https://metraje.oca.com.uy/imperdibles-lg-' target="_blank" rel="noopener noreferrer">
              <img src={oca} alt="OCA metraje" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <a href='https://www.divino.com.uy/catalogo?q=LG' target="_blank" rel="noopener noreferrer">
              <img src={divino} alt="Divino" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <a href='https://hawaii5cerro.com.uy/' target="_blank" rel="noopener noreferrer">
              <img src={hawaii} alt="Hawaii 5 cerro" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <a href='https://eurogen.com.uy/detalles.php?id=3226&color=' target="_blank" rel="noopener noreferrer">
              <img src={eurogen} alt="Eurogen" />
            </a>
          </PartnerHolder>
          <PartnerHolder>
            <a style={{margin: 10}} href='https://www.ltienda.com.uy/catalogo' target="_blank" rel="noopener noreferrer">
              <img src={lg} alt="LG tienda oficial" />
            </a>
          </PartnerHolder>
        </LogosHolder>
      </Section>

      <Section title="¿Tenés dudas? ¡Te ayudamos!">
        {requestState.isForm ? (
          <FormCard>
            <Row>
              <Input
                label="Nombre:"
                placeholder="Ingresa tu nombre"
                onChange={handleChange('fullname')}
                value={fields?.fullname}
              />
              <Input
                label="Correo electronico:"
                placeholder="nombre@domain.com"
                onChange={handleChange('email')}
                value={fields?.email}
              />
            </Row>
            <div className="form-label">
              <Text weight="semi-bold">TELÉFONO:</Text>
              <div className="form-row">
                <div className="column">
                  <PhoneCodeInput>
                    {/* @ts-ignore FIXME */}
                    <Select
                      isRequired
                      items={listPhoneCodes}
                      onSelect={handleChangeSelect('countryCode')}
                      selectedValue={fields?.countryCode}
                    />
                  </PhoneCodeInput>
                </div>
                <div className="column fields">
                  <Input
                    isRequired
                    placeholder="Número de teléfono"
                    className="input"
                    onChange={handleChange('phone')}
                    value={fields?.phone}
                  />
                </div>
              </div>
            </div>

            {/* @ts-ignore Fixme */}
            <TextArea
              label="¿Algún comentario?"
              placeholder="Información adicional"
              onChange={handleChange('message')}
              value={fields?.message}
              rows={10}
            />
            <Button
              disabled={!isValidForm || isSendingRequest}
              onClick={handleRequest}>
              Enviar
            </Button>
          </FormCard>
        ) : (
          <FormCard>
            <div>
              <Text
                weight="bold"
                context={requestState.wasSuccess ? 'primary' : 'warning'}
                size="xlarge"
                paragraph
                alignment="center">
                {requestState.wasSuccess
                  ? '¡Gracias! Recibimos tu mensaje. Nos pondremos en contacto a la brevedad posible.'
                  : 'No pudimos enviar tu mensaje. Intenta de nuevo más tarde.'}
              </Text>
              <br />
              <Text
                weight="bold"
                context="primary"
                size="xlarge"
                paragraph
                alignment="center">
                <Button onClick={handleBackToForm}>Aceptar</Button>
              </Text>
            </div>
          </FormCard>
        )}
      </Section>

      <Section title="Bases y condiciones">
        <FormCard>
          <Text
            paragraph
            className="with-separator title-background"
            weight="bold"
            context="primary">
            BASES Y CONDICIONES DE LA CAMPAÑA &ldquo;Agarrá Vuelo desde
            casa&ldquo;
          </Text>
          <Text paragraph className="with-separator">
            Participantes y Mecánica de participación. Estawol S.A. (LG) y
            Masterbox S.A. (mevuelo.com) son las empresas organizadoras de la
            presente campaña cuyas bases y condiciones se detallan a
            continuación:
          </Text>
          <Text
            paragraph
            className="with-separator title-background"
            weight="bold"
            context="primary">
            Proceso de Venta - Territorio Nacional
          </Text>
          <Text paragraph className="with-separator">
            1 . Puntos de venta admitidos: e-commerce y retails dentro del
            territorio nacional que comercialicen los productos LG distribuidos
            por Estawol S.A. 2. Fechas de las facturas: Se admitirán facturas
            emitidas entre los días 03/04/2023 y 09/07/2023. La persona deberá
            ponerse en contacto con la agencia en un periodo de máximo 4 meses
            luego de emitida la factura de compra (no siendo necesario que el
            viaje sea dentro de ese período). 3. Productos comprendidos: Son los
            productos OLED48A2PSA, OLED48C2PSA, OLED55A2PSA, OLED55C2PSA,
            OLED65C2PSA, CK99, WD14VVC4S6C, WD15DG2S6, LS66SXSC de la marca LG y
            los que puedan agregarse en el futuro durante la vigencia de la
            promoción (&ldquo;Productos comprendidos&ldquo;). 4. Premio:
            mevuelo.com reconocerá, por cada factura de Productos comprendidos,
            USD 400 a favor en la compra de paquetes de viaje de valor base
            superior a USD 1.500. Se reconocerá un solo crédito de USD 400 por
            paquete de viaje, no siendo acumulable en el mismo paquete con otras
            facturas ni con la presentación de compras por parte de otra
            persona. Cada factura será validada por ambas empresas (mevuelo.com
            y Estawol S.A.) con fines de confirmar que sea válida al momento del
            reclamo.
          </Text>
          <Text
            paragraph
            className="with-separator title-background"
            weight="bold"
            context="primary">
            Proceso de Venta - Régimen Free Shop
          </Text>
          <Text paragraph className="with-separator">
            1 . Puntos de venta admitidos: Duty Free Uruguay - Aeropuerto
            Internacional de Carrasco y Aeropuerto Internacional Laguna del
            Sauce 2. Fechas de las facturas: Se admitirán facturas emitidas
            entre los días 03/04/2023 y 09/07/2023. La persona deberá ponerse en
            contacto con la agencia en un periodo de máximo 4 meses luego de
            emitida la factura de compra (no siendo necesario que el viaje sea
            dentro de ese período). 3. Productos comprendidos: Por la limitación
            de franquicia que no permite la venta de productos libre de
            impuestos mayor a USD 849, la promoción aplica únicamente para el
            producto: &ldquo;Smart TV LG Nanocell 4K 55&ldquo; 4. Premio:
            mevuelo.com reconocerá, por cada factura del Producto referido, USD
            200 a favor en la compra de paquetes de viaje de valor base superior
            a USD 1.500. Se reconocerá un solo crédito de USD 200 por paquete de
            viaje, no siendo acumulable en el mismo paquete con otras facturas
            ni con la presentación de compras por parte de otra persona. Cada
            factura será validada por ambas empresas (mevuelo.com y Estawol
            S.A.) con fines de confirmar que sea válida al momento del reclamo.
            <Text
              paragraph
              className="with-separator title-background"
              weight="bold"
              context="primary">
              Fechas y condiciones del viaje:
            </Text>
            <Text paragraph className="with-separator">
              Las fechas y condiciones del viaje deberán ser acordadas en forma
              directa entre los compradores y mevuelo.com, no teniendo
              responsabilidad alguna LG-Estawol S.A. en dicha gestión. El monto
              de regalo de la campaña, sólo podrá ser reclamado por personas
              mayores de 18 años. El monto de regalo será transferible a otro
              usuario que lo pueda utilizar en caso de no poder utilizarse por
              la persona que ha comprado el/los productos comprendidos, siempre
              y cuando se cumplan todos los requisitos anteriores mencionados.
              Para hacer el reclamo del monto de regalo en mevuelo.com, el
              usuario acreedor deberá ponerse en contacto al Whatsapp +598 97
              683 318. Promoción válida en las fechas expresadas o hasta agotar
              stock.
            </Text>
          </Text>
        </FormCard>
      </Section>
    </div>
  );
};
