/**
 *
 * MobilePaxDetailHotelCard
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useSelector } from 'react-redux';
import * as COLORS from 'Constants/colors';
import Text from 'Components/Text';
import Modal from 'Components/Modal';
import MobilePaxDetailHotel from '../MobilePaxDetailHotel';
import { totalPeople } from 'Features/hotels';

const selector = ({ hotels }) => ({
  roomInformation: hotels.search.roomInformation,
  isEmpty: false
});

const ConnectedMobilePaxDetailHotelCard = props => {
  const state = useSelector(selector);

  return <StyledMobilePaxDetailHotelCard {...state} {...props} />;
};

const MobilePaxDetailHotelCard = ({
  className,
  minHeight,
  roomInformation,
  isEmpty
}) => {
  const [openModal, setOpenModal] = useState(false);
  // TODO Implement this in reducer
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const totalRoomInformation = totalPeople(roomInformation);
  const totalRooms = roomInformation.length;

  return (
    <>
      <div className={cs(className)} onClick={toggleModal}>
        {isEmpty ? (
          <div className={cs('container', { minHeight })}>
            <Text weight="semi-bold" alignment="center">
              HU&Eacute;SPEDES Y HABITACI&Oacute;NES
            </Text>
            <Text weight="italic">¿Qui&eacute;nes viajan?</Text>
          </div>
        ) : (
          <div className={cs('container', { minHeight })}>
            <Text weight="semi-bold">Habitaciones</Text>
            <Text context="important" weight="semi-bold">
              {totalRooms > 1
                ? `${totalRooms} habitaciones`
                : `${totalRooms} habitación`}
            </Text>
            <Text context="important" weight="semi-bold">
              {totalRoomInformation > 1
                ? `${totalRoomInformation} huéspedes`
                : `${totalRoomInformation} huésped`}
            </Text>
          </div>
        )}
      </div>

      <Modal
        show={openModal}
        onClickOverlay={toggleModal}
        transparent
        whiteBackground
        isSecondModal>
        <MobilePaxDetailHotel close={toggleModal} />
      </Modal>
    </>
  );
};

MobilePaxDetailHotelCard.propTypes = {
  className: PropTypes.string.isRequired,
  minHeight: PropTypes.number,
  roomInformation: PropTypes.array,
  isEmpty: PropTypes.bool
};

const StyledMobilePaxDetailHotelCard = styled(MobilePaxDetailHotelCard)`
  display: block;
  background-color: ${COLORS.white};
  width: 48%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    border-radius: 14px;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.25);
    background-color: ${COLORS.white};
    text-align: center;

    &.minHeight {
      min-height: ${props => props.minHeight}px;
    }
  }
`;

export default ConnectedMobilePaxDetailHotelCard;
export { StyledMobilePaxDetailHotelCard as MobilePaxDetailHotelCard };
