// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Component
import HolderForm from './HolderForm';

// Actions
import { storageUserData } from '../../ReduxConfig/checkout';
import { toggleTacModal } from '../../Features/common';

// Constants
import { selector } from './HolderForm.selector';

const ConnectedInvoiceForm = props => {
  const state = useSelector(selector);
  const dispatch = useDispatch();
  const actions = {
    toggleTacModal: () => dispatch(toggleTacModal()),
    setUserData: data => dispatch(storageUserData(data))
  };

  return <HolderForm actions={actions} {...props} {...state} />;
};

export default ConnectedInvoiceForm;
