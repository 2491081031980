import { choicesRoute, airSearchTypes, airSearchFilterType } from 'Constants';
import { isoFifteenDays, isoTwentyDays, parseIso } from 'Utils/dates';
import { limits, validationMessages, mpcItemStatus } from './constants';
import { setActive } from 'Features/destinations/actions';
import { isBefore } from 'date-fns';

import qs from 'query-string';
import {
  masterPriceJoinedRequest,
  mptbRequest,
  setSearchDialogDetailMode
} from './actions';
import { resetToEngine } from '../../ReduxConfig/availability';
import { batch } from 'react-redux';
import destinationQuery from 'Features/destinations/graphql/queries/getDestination';
import { getDestination } from 'Features/destinations';

export const flightRequestToUrl = (searchState, destinationActive) => {
  switch (searchState.type) {
    case airSearchTypes.oneWay:
      return oneWayRequestUrl(searchState, destinationActive);
    case airSearchTypes.roundTrip:
      return roundTripRequestUrl(searchState, destinationActive);
    case airSearchTypes.multi:
      return multiRequestUrl(searchState, destinationActive);
    default:
      break;
  }
};

export const validateSearch = searchState => {
  const { type, routesData, adults, children, cabinCode } = searchState;
  const validations = {
    paxDetails: [],
    routesData: []
  };

  const { totalAdult, totalInfant } = formatPaxDetails(
    adults,
    children,
    cabinCode
  );

  const totalPassengers = totalPeople(adults, children);

  const isMulti = airSearchTypes.multi === type;
  const isRoundtrip = airSearchTypes.roundTrip === type;

  let isValid = true;

  validations.routesData = routesData.map((route, index) => {
    const errors = {
      origin: [],
      destination: [],
      initialDate: [],
      endDate: []
    };
    const { origin, destination, initialDate, endDate } = route;

    if (!isMulti && index > 0) {
      return errors;
    }

    if (!origin) {
      errors.origin.push(validationMessages.origin.empty);
      isValid = false;
    }

    if (!destination) {
      errors.destination.push(validationMessages.destination.empty);
      isValid = false;
    }

    if (origin === destination) {
      errors.destination.push(validationMessages.destination.same);
      isValid = false;
    }

    if (!initialDate) {
      errors.initialDate.push(validationMessages.initialDate.empty);
      isValid = false;
    }

    if (initialDate && !isBefore(new Date(), parseIso(initialDate))) {
      errors.initialDate.push(validationMessages.initialDate.inPast);
      isValid = false;
    }

    if (
      isMulti &&
      index > 0 &&
      isBefore(
        parseIso(initialDate),
        parseIso(routesData[index - 1].initialDate)
      )
    ) {
      errors.initialDate.push(validationMessages.initialDate.beforePrevious);
      isValid = false;
    }

    if (isRoundtrip && !endDate) {
      errors.endDate.push(validationMessages.endDate.empty);
      isValid = false;
    }

    if (isRoundtrip && isBefore(parseIso(endDate), parseIso(initialDate))) {
      errors.endDate.push(validationMessages.endDate.beforeInitial);
      isValid = false;
    }

    return errors;
  });

  if (totalAdult < limits.adults.min) {
    validations.paxDetails.push(validationMessages.adults.min);
    isValid = false;
  }

  if (totalAdult > limits.adults.max) {
    validations.paxDetails.push(validationMessages.adults.max);
    isValid = false;
  }

  if (totalInfant > totalAdult) {
    validations.paxDetails.push(validationMessages.totalInfant.max);
    isValid = false;
  }

  if (totalPassengers > limits.totalPeople.max) {
    validations.paxDetails.push(validationMessages.totalPassangers.max);
    isValid = false;
  }

  if (totalPassengers < limits.totalPeople.min) {
    validations.paxDetails.push(validationMessages.totalPassangers.min);
    isValid = false;
  }

  if (isValid) {
    return { isValid };
  }
  return { isValid, ...validations };
};

export const getIndexLowestFlightPrice = data => {
  const indexLowest = data.reduce(
    (lowest, next, index) =>
      next.flightPrice.totalPrice < lowest ? index : lowest,
    0
  );
  return indexLowest;
};

export const getClassColors = (itemPrice, minPrice, middlePrice, maxPrice) => {
  const betweenMinMiddle = (minPrice + middlePrice) / 2;
  const betweenMiddleMax = (middlePrice + maxPrice) / 2;
  if (itemPrice === minPrice) return mpcItemStatus.CHEAPER_MAX;
  if (itemPrice === maxPrice) return mpcItemStatus.MORE_EXPENSIVE;
  if (itemPrice > minPrice && itemPrice <= betweenMinMiddle)
    return mpcItemStatus.CHEAPER_80;
  if (itemPrice >= betweenMiddleMax && itemPrice < maxPrice)
    return mpcItemStatus.CHEAPER_MIN;
  if (itemPrice > betweenMinMiddle && itemPrice < betweenMiddleMax)
    return mpcItemStatus.CHEAPER_60;
};

export const getItemDetailValueByKey = (data, key) => {
  if (data.length > 0) {
    return data[getIndexLowestFlightPrice(data)][key];
  }
  return key === 'generalSegments' ? [] : {};
};

export const totalPeople = (adults, children) =>
  children ? adults + children.length : adults;

export const formatPaxDetails = (adults, children) => {
  const childrens = children || [];
  const paxDetails = {
    /*
      adultos: todos los adutlos, mas los ninos que tenga 12 o mas anos
      totalChildren: de 11 hasta hasta 2
      totalInfant: 1 ano o menos  menor sin asiento
      totalInfantWithSeat: menores de 2 sin asiento que le pusieron que quieren asiento
      children.age: Edades de infantes
      children.seat: Si esos infantes llevan asientos o no
      */
    totalAdult: adults + childrens.filter(child => child.age > 11).length,
    totalChildren: childrens.filter(child => child.age < 12 && child.age > 1)
      .length,
    totalInfant: childrens.filter(child => child.age < 2 && !child.seat).length,
    totalInfantWithSeat: childrens.filter(child => child.age < 2 && child.seat)
      .length,
    childrens: childrens
      .map(item => `${item.age}-${item.seat ? 's' : 'n'}`)
      .toString()
  };
  return paxDetails;
};

export const oneWayRequestUrl = (searchState, destinationActive) => {
  const { type, routesData, adults, children, cabinCode, isMPC } = searchState;

  const knowDestination = destinationActive;

  const {
    totalAdult,
    totalChildren,
    totalInfant,
    totalInfantWithSeat,
    childrens
  } = formatPaxDetails(adults, children);

  let requestOWUrl = `type=${type}&from=${routesData[0].origin}&to=${
    routesData[0].destination
  }&from-date=${
    routesData[0].initialDate
  }&adults=${totalAdult}&childs=${totalChildren}&infants=${totalInfant}&infants-with-seat=${totalInfantWithSeat}&class-of-service=${cabinCode}&sorting=recomendado&view-type=${
    knowDestination ? choicesRoute.withContent : choicesRoute.withoutContent
  }&isMPC=${isMPC ? 'si' : 'no'}`;

  if (childrens) {
    requestOWUrl = requestOWUrl.concat(`&children=${childrens}`);
  }

  return `?${requestOWUrl}`;
};

export const roundTripRequestUrl = (searchState, destinationActive) => {
  const { type, routesData, adults, children, cabinCode, isMPC } = searchState;

  const knowDestination = destinationActive;

  const {
    totalAdult,
    totalChildren,
    totalInfant,
    totalInfantWithSeat,
    childrens
  } = formatPaxDetails(adults, children);

  let requestRTUrl = `type=${type}&from=${routesData[0].origin}&to=${
    routesData[0].destination
  }&from-date=${routesData[0].initialDate}&to-date=${
    routesData[0].endDate
  }&adults=${totalAdult}&childs=${totalChildren}&infants=${totalInfant}&infants-with-seat=${totalInfantWithSeat}&class-of-service=${cabinCode}&sorting=recomendado&view-type=${
    knowDestination ? choicesRoute.withContent : choicesRoute.withoutContent
  }&isMPC=${isMPC ? 'si' : 'no'}`;

  if (childrens) {
    requestRTUrl = requestRTUrl.concat(`&children=${childrens}`);
  }

  return `?${requestRTUrl}`;
};

export const convertChildrenToObject = children => {
  let ages = children.split(',').map(ageItem => {
    const [age, seat] = ageItem.split('-');
    return { age: parseInt(age), seat: seat === 's' ? true : false };
  });
  return ages;
};

export const multiRequestUrl = (searchState, destinationActive) => {
  const { type, routesData, adults, children, cabinCode, isMPC } = searchState;

  const knowDestination = destinationActive;

  const {
    totalAdult,
    totalChildren,
    totalInfant,
    totalInfantWithSeat,
    childrens
  } = formatPaxDetails(adults, children, cabinCode);

  const urlRoutes = routesData.map(
    route =>
      `&from=${route.origin}&to=${route.destination}&from-date=${route.initialDate}`
  );

  let requestMUrl = `?type=${type}${urlRoutes.join(
    ''
  )}&adults=${totalAdult}&childs=${totalChildren}&infants=${totalInfant}&infants-with-seat=${totalInfantWithSeat}&class-of-service=${cabinCode}&sorting=recomendado&view-type=${
    knowDestination ? choicesRoute.withContent : choicesRoute.withoutContent
  }&isMPC=${isMPC ? 'si' : 'no'}`;

  if (childrens) {
    requestMUrl = requestMUrl.concat(`&children=${childrens}`);
  }

  return `?${requestMUrl}`;
};

const setDestinationInfo = (cityKeyword, withContent, GQL, dispatch) => {
  if (cityKeyword && cityKeyword !== 'None') {
    batch(() => {
      dispatch(getDestination(GQL));
    });
  }
};

const setWithContent = (hasContent, dispatch) => {
  hasContent && dispatch(setActive(true));
};

export const handleUrlChange = (router, dispatch) => {
  const cityKeyword = router.location.pathname.split('/')[1] || 'None';
  const queryParameters = qs.parse(router.location.search);
  const queryDestinationsGQL = destinationQuery(cityKeyword);
  const withContent = queryParameters['view-type'] === 'con-contenido';
  setDestinationInfo(cityKeyword, withContent, queryDestinationsGQL, dispatch);

  //Round Trip
  if (queryParameters['type'] === airSearchTypes.roundTrip) {
    const request = {
      routesData: [
        {
          origin: queryParameters.from,
          destination: queryParameters.to,
          initialDate: queryParameters['from-date'],
          endDate: queryParameters['to-date']
        }
      ],
      totalAdult: parseInt(queryParameters.adults),
      totalChildren: parseInt(queryParameters.childs),
      totalInfant: parseInt(queryParameters.infants),
      totalInfantWithSeat: parseInt(queryParameters['infants-with-seat']),
      cabinCode:
        queryParameters['class-of-service'] !== 'null'
          ? queryParameters['class-of-service']
          : null,
      filterType: airSearchFilterType.ROUND_TRIP,
      groupResults: true,
      knowDestination: false,
      cityKeyword
    };

    if (queryParameters.children) {
      request['children'] = convertChildrenToObject(
        queryParameters['children']
      );
    }

    if (queryParameters.isMPC === 'si') {
      delete request.groupResults;

      batch(() => {
        dispatch(resetToEngine('vuelosMPC'));
        dispatch(setSearchDialogDetailMode(true));
        dispatch(masterPriceJoinedRequest(request));
      });
    } else {
      batch(() => {
        dispatch(resetToEngine('vuelos'));
        dispatch(setSearchDialogDetailMode(true));
        dispatch(mptbRequest(request));
      });
    }
  } else if (queryParameters['type'] === airSearchTypes.oneWay) {
    const request = {
      routesData: [
        {
          origin: queryParameters.from,
          destination: queryParameters.to,
          initialDate: queryParameters['from-date']
        }
      ],
      totalAdult: parseInt(queryParameters.adults),
      totalChildren: parseInt(queryParameters.childs),
      totalInfant: parseInt(queryParameters.infants),
      totalInfantWithSeat: parseInt(queryParameters['infants-with-seat']),
      cabinCode:
        queryParameters['class-of-service'] !== 'null'
          ? queryParameters['class-of-service']
          : null,
      filterType: airSearchFilterType.ONE_WAY,
      groupResults: true,
      knowDestination: false,
      cityKeyword
    };

    if (queryParameters.children) {
      request['children'] = convertChildrenToObject(
        queryParameters['children']
      );
    }

    if (queryParameters.isMPC === 'si') {
      delete request.groupResults;
      batch(() => {
        dispatch(resetToEngine('vuelosMPC'));
        dispatch(setSearchDialogDetailMode(true));
        dispatch(masterPriceJoinedRequest(request));
      });
    } else {
      batch(() => {
        dispatch(resetToEngine('vuelos'));
        dispatch(setSearchDialogDetailMode(true));
        dispatch(mptbRequest(request));
      });
    }
  } else if (queryParameters['type'] === airSearchTypes.multi) {
    const routesData = queryParameters.from.map((from, i) => ({
      origin: from,
      destination: queryParameters.to[i],
      initialDate: queryParameters['from-date'][i]
    }));
    const request = {
      routesData,
      totalAdult: parseInt(queryParameters.adults),
      totalChildren: parseInt(queryParameters.childs),
      totalInfant: parseInt(queryParameters.infants),
      totalInfantWithSeat: parseInt(queryParameters['infants-with-seat']),
      cabinCode:
        queryParameters['class-of-service'] !== 'null'
          ? queryParameters['class-of-service']
          : null,
      filterType: airSearchFilterType.MULTIDESTINATION,
      groupResults: true,
      knowDestination: false,
      cityKeyword
    };

    if (queryParameters.children) {
      request['children'] = convertChildrenToObject(
        queryParameters['children']
      );
    }

    batch(() => {
      dispatch(resetToEngine('vuelos'));
      dispatch(setSearchDialogDetailMode(true));
      dispatch(mptbRequest(request));
    });
  }
};

export const handleOnlyDestinationRequest = (keyword, dispatch) => {
  const request = {
    routesData: [
      {
        origin: process.env.REACT_APP_AIRPORT_CODE_DEFAULT,
        initialDate: isoFifteenDays,
        endDate: isoTwentyDays
      }
    ],
    totalAdult: 1,
    totalChildren: 0,
    totalInfant: 0,
    totalInfantWithSeat: 0,
    filterType: airSearchFilterType.ROUND_TRIP,
    groupResults: true,
    byDestinationURL: true,
    destinationURL: keyword
  };

  batch(() => {
    dispatch(resetToEngine('vuelos'));
    dispatch(setSearchDialogDetailMode(true));
    setWithContent(true, dispatch);
    dispatch(mptbRequest(request));
  });
};
export const handleUrlToObjectSearchFlight = (pathname, search, newDates) => {
  const cityKeyword = pathname.split('/')[1] || 'None';
  const queryParameters = qs.parse(search);
  //Flight Search
  const request = {
    routesData: [
      {
        origin: queryParameters.from,
        destination: queryParameters.to,
        initialDate:
          newDates.hasOwnProperty('initialDate') && newDates.initialDate
            ? newDates.initialDate
            : queryParameters['from-date'],
        endDate:
          newDates.hasOwnProperty('endDate') && newDates.endDate
            ? newDates.endDate
            : queryParameters['to-date']
      }
    ],
    totalAdult: parseInt(queryParameters.adults),
    totalChildren: parseInt(queryParameters.childs),
    totalInfant: parseInt(queryParameters.infants),
    totalInfantWithSeat: parseInt(queryParameters['infants-with-seat']),
    filterType: airSearchFilterType.ROUND_TRIP,
    cabinCode:
      queryParameters['class-of-service'] !== 'null'
        ? queryParameters['class-of-service']
        : null,
    type: queryParameters['type'],
    knowDestination: queryParameters['view-type'] === choicesRoute.withContent,
    cityKeyword
  };

  if (queryParameters['children']) {
    request['children'] = convertChildrenToObject(queryParameters['children']);
  }

  if (queryParameters['isMPC'] === 'false') {
    request['groupResults'] = true;
  }

  return request;
};

export const handleObjectSearchFlightToUrl = (typeSearch, searchObject) =>
  ({
    ONE_WAY: oneWayRequestUrl(searchObject),
    ROUND_TRIP: roundTripRequestUrl(searchObject),
    MULTIDESTINATION: multiRequestUrl(searchObject)
  }[typeSearch]);

export const getTypeFlightSearch = type =>
  ({
    1: 'ONE_WAY',
    2: 'ROUND_TRIP',
    3: 'MULTIDESTINATION'
  }[type]);
