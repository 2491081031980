// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Component
import HolderFormCorpo from './HolderFormCorpo';

// Actions
import { toggleTacModal } from '../../Features/common';

// Constants
import { selector } from './HolderFormCorpo.selector';
import { storageCorpoData } from '../../ReduxConfig/checkoutCorpo';

const ConnectedHolderFormCorpo = props => {
  const state = useSelector(selector);
  const dispatch = useDispatch();
  const actions = {
    toggleTacModal: () => dispatch(toggleTacModal()),
    setCorpoData: data => dispatch(storageCorpoData(data))
  };

  return <HolderFormCorpo actions={actions} {...props} {...state} />;
};

export default ConnectedHolderFormCorpo;
