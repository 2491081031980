import React from 'react';
import styled from 'styled-components';
import '@brainhubeu/react-carousel/lib/style.css';
import Carousel from '@brainhubeu/react-carousel';
import Divider from 'Components/Divider';
import { IPracticalInfo } from 'Features/destinations/types';
import Icon from 'Components/Icon';
import {
  returnCarrouselItems,
  convertTimeToLocalInDestinations
} from '../../helpers';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import CardInformation from 'Features/destinations/components/CardInformation';

const IconHolder = styled.div`
  width: 40px;
  height: 40px;
`;

const reslovePracticalInfoArray = (data: IPracticalInfo) => {
  const returnArray = [];
  data.time &&
    returnArray.push(
      <CardInformation
        title="Hora local"
        icon="mv-time"
        value={`${convertTimeToLocalInDestinations(data.time)} hs`}
      />
    );
  data.areaCode &&
    returnArray.push(
      <CardInformation
        title="Codigo de area"
        icon="mv-phone"
        value={data.areaCode}
      />
    );
  data.currency &&
    returnArray.push(
      <CardInformation
        title="Moneda local"
        icon="mv-money"
        value={data.currency}
      />
    );
  data.is220 &&
    returnArray.push(
      <CardInformation title="Voltaje" icon="mv-voltage" value=" 220V" />
    );
  data.is110 &&
    returnArray.push(
      <CardInformation title="Voltaje" icon="mv-voltage" value=" 110V" />
    );
  data.isPlugTypeA &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-a" />);
  data.isPlugTypeB &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-b" />);
  data.isPlugTypeC &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-c" />);
  data.isPlugTypeD &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-d" />);
  data.isPlugTypeE &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-e" />);
  data.isPlugTypeF &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-f" />);
  data.isPlugTypeG &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-g" />);
  data.isPlugTypeH &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-h" />);
  data.isPlugTypeI &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-i" />);
  data.isPlugTypeJ &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-j" />);
  data.isPlugTypeK &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-k" />);
  data.isPlugTypeL &&
    returnArray.push(<CardInformation title="Enchufe" icon="mv-plug-type-l" />);

  return returnArray;
};

const PracticalInfo = (props: IPracticalInfo) => {
  const breakpoints = useBreakpoints();
  return (
    <div>
      <Divider titleText={`Información práctica sobre ${props.name}`} />
      <Carousel
        slidesPerPage={returnCarrouselItems(breakpoints)}
        arrows
        infinite
        arrowLeft={
          <IconHolder>
            <Icon name="mv-arrow-left" color="white" size={20} />
          </IconHolder>
        }
        arrowRight={
          <IconHolder>
            <Icon name="mv-arrow-right" color="white" size={20} />
          </IconHolder>
        }
        addArrowClickHandler>
        {reslovePracticalInfoArray(props).map(item => item)}
      </Carousel>
    </div>
  );
};

export default PracticalInfo;
