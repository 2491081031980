/**
 *
 * FlightSearchCompact
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlightSearchCompact from './FlightSearchCompact';
import { setSearchDialogDetailMode } from 'Features/flights/actions';

const selector = ({ flights }) => ({
  originName: flights.search.routesData[0].originName,
  destinationName: flights.search.routesData[0].destinationName,
  initialDate: flights.search.routesData[0].initialDate,
  endDate: flights.search.routesData[0].endDate,
  adults: flights.search.adults,
  kids: flights.search.children,
  type: flights.search.type
});

const ConnectedFlightSearchCompact = props => {
  const dispatch = useDispatch();
  const actions = {
    setModify: () => {
      dispatch(setSearchDialogDetailMode(false));
    }
  };
  const state = useSelector(selector);

  return <FlightSearchCompact {...props} {...state} actions={actions} />;
};

export default ConnectedFlightSearchCompact;
export { FlightSearchCompact };
