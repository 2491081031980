/**
 *
 * HotelHeader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import Button from 'Components/Button';
import StarsFilter from 'Components/StarsFilter';
import { blue, important } from 'Constants/colors';
import { textTruncate } from 'Utils';
import Badge from 'Components/Badge';

const HotelHeader = ({
  className,
  name,
  image1,
  image2,
  image3,
  starRating,
  keyRating,
  textRating,
  checkin,
  checkout,
  onShowServices,
  address,
  facilities
}) => (
  <div className={cs(className)}>
    {image1 && (
      <div className="photos-holder">
        <div
          className={cs('big-background', 'photo-item')}
          style={{ background: `url(${image1})`, marginRight: image2 ? 6 : 0 }}
          alt="photo1"
        />
        {image2 && (
          <div className={cs('small-photos-container')}>
            <div
              className={cs('photo-item')}
              style={{ background: `url(${image2})`, marginBottom: 3 }}
              alt="photo1"
            />
            {image3 && (
              <div
                className={cs('photo-item')}
                style={{ background: `url(${image3})`, marginTop: 3 }}
                alt="photo1"
              />
            )}
          </div>
        )}
      </div>
    )}
    <div className="information-holder">
      <div className="info-item">
        <Text size="xlarge" weight="semi-bold" context="blue">
          {textTruncate(name, 24)}
        </Text>
        <div>
          <Icon name="mv-location-1" />
          <Text>{address}</Text>
        </div>
        {starRating && <StarsFilter justDisplay initialValue={starRating} />}
        {keyRating && (
          <StarsFilter justDisplay initialValue={keyRating} isKey={keyRating} />
        )}
        {textRating && (
          <Badge variation="yellow" inverted>
            {textRating}
          </Badge>
        )}
      </div>
      <div className="info-item borders">
        <div className="text-center">
          <Text size="xlarge" weight="semi-bold" context="blue">
            Horarios
          </Text>
        </div>
        <div>
          <Text weight="semi-bold" context="important">
            Horario de Check In:
          </Text>{' '}
          <Text>a partir de las {checkin}</Text>
        </div>
        <div>
          <Text weight="semi-bold" context="important">
            Horario de Check Out:
          </Text>{' '}
          <Text>hasta las {checkout}</Text>
        </div>
      </div>
      <div className="info-item">
        <div className="text-center">
          <Text size="xlarge" weight="semi-bold" context="blue">
            Servicios
          </Text>
        </div>
        <div className="facilities-holder">
          <Icon
            size={32}
            color={facilities && facilities.wifi ? 'important' : 'default'}
            name="wifi"
          />
          <Icon
            size={32}
            color={facilities && facilities.parking ? 'important' : 'default'}
            name="mv-car-parking"
          />
          <Icon
            size={32}
            color={facilities && facilities.pool ? 'important' : 'default'}
            name="mv-swimming-pool"
          />
          <Icon
            size={32}
            color={facilities && facilities.breakfast ? 'important' : 'default'}
            name="mv-breakfast"
          />
          <Icon
            size={32}
            color={
              facilities && facilities.airConditioning ? 'important' : 'default'
            }
            name="mv-sun"
          />
          <Icon
            size={32}
            color={facilities && facilities.gym ? 'important' : 'default'}
            name="mv-gym"
          />
        </div>
        <div className="button-holder">
          <Button context="information" size="xs" onClick={onShowServices}>
            Ver todos
          </Button>
        </div>
      </div>
    </div>
  </div>
);

HotelHeader.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string,
  image1: PropTypes.string,
  image2: PropTypes.string,
  image3: PropTypes.string,
  starRating: PropTypes.number,
  keyRating: PropTypes.number,
  textRating: PropTypes.string,
  checkin: PropTypes.string,
  checkout: PropTypes.string,
  onShowServices: PropTypes.func,
  address: PropTypes.string,
  facilities: PropTypes.object,
  accommodationCode: PropTypes.string
};

export default styled(HotelHeader)`
  display: block;
  border-radius: 14px;
  overflow: hidden;
  background: #fff;
  margin-bottom: 30px;

  .button-holder {
    display: flex;
    justify-content: center;
  }

  .facilities-holder {
    display: flex;
    font-size: 24px;
    justify-content: space-around;
    margin-top: 11px;
    color: ${important};
  }

  .photos-holder {
    display: flex;
    min-height: 380px;

    .big-background {
      flex-grow: 2;
    }

    .photo-item {
      background-size: cover !important;
      background-position: center !important;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
    }

    .small-photos-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .photo-item {
        flex-grow: 1;
      }
    }
  }

  .information-holder {
    display: flex;
    margin: 10px;

    .info-item {
      flex: 1;
      padding: 11px;

      &.borders {
        border-right: 2px solid ${blue};
        border-left: 2px solid ${blue};
      }
    }
  }
`;
