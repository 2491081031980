/**
 *
 * Slider
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Range, RangeProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Text from 'Components/Text';

export interface SliderProps extends RangeProps {
  showToolTip?: boolean;
  currency?: string;
  defaultValue?: number[];
}

const Slider = ({
  min,
  max,
  defaultValue = [min, max],
  onChange,
  currency = '',
  showToolTip = true
}: SliderProps) => {
  const [display, setDisplay] = useState<[number, number]>([min, max]);

  const handleChange = ([min, max]: [number, number]) => {
    onChange([min, max]);
    setDisplay([min, max]);
  };

  return (
    <Container>
      {showToolTip && (
        <>
          <LeftTooltip>
            <Text size="small" weight="semi-bold">
              {currency} {display[0]}
            </Text>
          </LeftTooltip>
          <RightTooltip>
            <Text size="small" weight="semi-bold">
              {currency} {display[1]}
            </Text>
          </RightTooltip>
        </>
      )}
      <SliderRange
        min={min}
        max={max}
        defaultValue={defaultValue}
        onChange={handleChange}
        allowCross={false}
      />
    </Container>
  );
};

export default Slider;

const Container = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing.three};
`;

const Tooltip = styled.div`
  position: absolute;
  top: 0;
`;

const LeftTooltip = styled(Tooltip)`
  left: 0;
`;

const RightTooltip = styled(Tooltip)`
  right: 0;
`;

const SliderRange = styled(Range)`
  position: relative;
  margin: ${({ theme }) => theme.spacing.four};

  /* The important is required for the slider to stay in container */
  width: auto !important;

  .rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.blackHaze};
    height: 20px;
    border-radius: 0;
  }

  .rc-slider-track {
    background: ${({ theme }) => theme.gradients.gradientOrange};
    height: 20px;
  }

  .rc-slider-handle {
    width: 16px;
    height: 16px;

    /* Custom shadow, as i need to show it outside of the orange border */
    box-shadow: 0 0 0 10px ${({ theme }) => theme.colors.important},
      10px 10px 6px rgba(0, 0, 0, 0.25);
    background-color: ${({ theme }) => theme.colors.white};
    margin-top: 0;
    outline: none;
    border: none;

    &:focus,
    &:active,
    &:hover {
      border: none;
      outline: none;
      cursor: pointer;
      box-shadow: 0 0 0 10px ${({ theme }) => theme.colors.important},
        10px 10px 6px rgba(0, 0, 0, 0.25);
    }
  }
`;
