import API from 'Services';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';

import {
  RESET_USER_PASSWORD_FAILURE,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_FAILURE,
  TUserActionTypes,
  SET_LOGGED_USER_FAILURE,
  SET_LOGGED_USER_REQUEST,
  SET_LOGGED_USER_SUCCESS
} from './actionTypes';
// Types
import { IAuthFields, INewPasswordFields } from './components/Login/types';
import { updateLoader } from '../app/actions';
import { resetToEngine } from 'ReduxConfig/availability';
import { engines, routes } from 'Constants';
import { setToken, removeToken, getToken } from './utils';
import { IErrorResponse } from 'Services/Quotations/types';
import { ILoginResponse } from 'Services/Users/types';

function userLoginRequest(): TUserActionTypes {
  return {
    type: USER_LOGIN_REQUEST
  };
}

function userLoginSuccess(payload: ILoginResponse): TUserActionTypes {
  return {
    type: USER_LOGIN_SUCCESS,
    payload
  };
}

function userLoginFailure(payload: IErrorResponse): TUserActionTypes {
  return {
    type: USER_LOGIN_FAILURE,
    payload
  };
}

export function userLogin(params: IAuthFields): (dispatch: Dispatch) => void {
  return async function(dispatch) {
    dispatch(userLoginRequest());

    try {
      const { data } = await API.users.login({
        login: params.username,
        password: params.password
      });
      if ('token' in data) {
        setToken(data.token);

        if (!data.needChangePassword) {
          toast.success('Usted ha iniciado sesion exitosamente.', {
            autoClose: 2500,
          });
        }

        dispatch(userLoginSuccess(data));

        dispatch(push(data.needChangePassword ? routes.resetPassword : '/'));
      }
    } catch (error) {
      dispatch(userLoginFailure(error.response.data));
    }
  };
}

function resetUserPasswordPending(): TUserActionTypes {
  return {
    type: RESET_USER_PASSWORD_REQUEST
  };
}

function resetUserPasswordSuccess(): TUserActionTypes {
  return {
    type: RESET_USER_PASSWORD_SUCCESS
  };
}

function resetUserPasswordFailure(payload: IErrorResponse): TUserActionTypes {
  return {
    type: RESET_USER_PASSWORD_FAILURE,
    payload
  };
}

export function resetUserPassword(
  params: INewPasswordFields
): (dispatch: Dispatch) => void {
  return async function(dispatch) {
    dispatch(resetUserPasswordPending());

    try {
      const { status, data } = await API.users.reset({
        currentPassword: params.currentPassword,
        newPassword: params.newPassword
      });

      if (status >= 200 && status < 300) {
        toast.info('Usted ha modificado su constraseña con éxito.');
        dispatch(resetUserPasswordSuccess());
      } else {
        dispatch(resetUserPasswordFailure(data));
      }

      dispatch(push('/'));
    } catch (error) {
      dispatch(resetUserPasswordFailure(error.response.data));
    }
  };
}

function userLogoutRequest(): TUserActionTypes {
  return {
    type: USER_LOGOUT_REQUEST
  };
}

function userLogoutSuccess(): TUserActionTypes {
  return {
    type: USER_LOGOUT_SUCCESS
  };
}

function userLogoutFailure(payload: IErrorResponse): TUserActionTypes {
  return {
    type: USER_LOGOUT_FAILURE,
    payload
  };
}

export function userLogout(): (
  dispatch: Dispatch,
  getState: () => any
) => void {
  return async function(dispatch) {
    dispatch(userLogoutRequest());
    // @ts-ignore TODO: REMOVE WHEN TYPES ARE AVAILABLE
    dispatch(updateLoader(true));

    try {
      await API.users.logout();
      dispatch(userLogoutSuccess());
      // @ts-ignore TODO: REMOVE WHEN TYPES ARE AVAILABLE
      dispatch(updateLoader(false));
      // @ts-ignore TODO: REMOVE WHEN TYPES ARE AVAILABLE
      dispatch(resetToEngine(engines.FLIGHTS));
      dispatch(push('/'));
      dispatch(setLoggedUser(null));

      removeToken();

      toast.success('Usted a cerrado sesión éxitosamente.', {
        autoClose: 2500,
      });
    } catch (error) {
      // @ts-ignore TODO: REMOVE WHEN TYPES ARE AVAILABLE
      dispatch(updateLoader(false));
      dispatch(userLogoutFailure(error.response.data));
      dispatch(push('/'));
    }
  };
}

export const fetchUser = () => async dispatch => {
  if (getToken()) {
    dispatch({ type: SET_LOGGED_USER_REQUEST });
    try {
      const { data } = await API.users.getProfile();
      if ('username' in data) {
        dispatch(setLoggedUser(data));
      }
    } catch (error) {
      dispatch({ type: SET_LOGGED_USER_FAILURE });
      removeToken();
    }
  }
};

export function checkTokenExpiration(error: AxiosError) {
  if (error.response?.status === 403) {
    toast.info(error.response.data.message);
    removeToken();
    return Promise.reject(error);
  }

  return Promise.reject(error);
}

export const setLoggedUser = payload => ({
  type: SET_LOGGED_USER_SUCCESS,
  payload
});
