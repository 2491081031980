import { useState, useEffect } from 'react';

const useMaxZIndex = () => {
  const [maxZIndex, setMaxZIndex] = useState<number>(0);

  useEffect(() => {
    const elements: NodeListOf<Element> = document.querySelectorAll('*');
    let zIndexArray: number[] = [];
    elements.forEach(element => {
      const zIndex: number = parseInt(getComputedStyle(element).zIndex);
      if (!isNaN(zIndex)) zIndexArray.push(zIndex);
    });
    const highestZIndex: number = Math.max(...zIndexArray);
    setMaxZIndex(highestZIndex);
  }, []);

  return maxZIndex;
};

export default useMaxZIndex;
