import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Stepper from 'Components/Stepper';
import Dekstop from './HomeCheckoutDesktop';
import Mobile from './HomeCheckoutMobile';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

import PaxData from '../PaxData';
import { CheckoutSteps } from 'Features/checkout/types';

const IndexCheckout = () => {
  const { mediumDown } = useBreakpoints();
  const Wrapper = mediumDown ? Mobile : Dekstop;
  return (
    <Wrapper>
      <Stepper
        activeStep={0}
        steps={Object.values(CheckoutSteps).filter(
          value => typeof CheckoutSteps[value as string] === 'number'
        )}
        onStepClick={() => {}}
        stepsAllowed={[]}
      />

      <Switch>
        <Route path="/">
          <PaxData />
        </Route>
        <Route path="/paxData">
          <PaxData />
        </Route>
      </Switch>
    </Wrapper>
  );
};

export default IndexCheckout;
