import axios from 'axios';
import { FormData } from './types';

export const createPromoMessage = async (data: FormData) => {
  let toReturn = true;
  try {
    const {
      data: { result }
    } = (await axios.get(
      `https://mevuelo.bitrix24.es/rest/1/bx2gmahqblv00ruk/crm.contact.add.json?FIELDS[NAME]=${encodeURIComponent(
        data.fullname
      )}&FIELDS[EMAIL][0][VALUE]=${encodeURIComponent(
        data.email
      )}&FIELDS[PHONE][0][VALUE]=${encodeURIComponent(
        `${data.countryCode} ${data.phone}`
      )}`
    )) as any;
    await axios.get(
      `https://mevuelo.bitrix24.es/rest/1/qprsc9zfeuqsulp8/crm.deal.add.json?FIELDS[TITLE]=${encodeURIComponent(
        'Promo LG: Agarra Vuelo desde Casa'
      )}&FIELDS[COMMENTS]=${encodeURIComponent(
        data.message
      )}&FIELDS[UF_CRM_1621546298]=87&[OPENED]=Y&FIELDS[CONTACT_ID]=${result}`
    );
  } catch (e) {
    toReturn = false;
  }

  return toReturn;
};
