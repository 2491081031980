export const classItems = [
  { id: 'Y', name: 'Económica', toSelect: true },
  { id: 'M', name: 'Económica', toSelect: false },
  { id: 'W', name: 'Económica Premium', toSelect: true },
  { id: 'C', name: 'Ejecutiva', toSelect: true },
  { id: 'F', name: 'Primera Clase', toSelect: true },
  { id: 'MX', name: 'Combinada', toSelect: false }
];
export const classItemsSelect = classItems.filter(x => x.toSelect);

export const edadesOpciones = [
  { id: 0, name: 'menos de 1 año' },
  { id: 1, name: '1 año' },
  { id: 2, name: '2 años' },
  { id: 3, name: '3 años' },
  { id: 4, name: '4 años' },
  { id: 5, name: '5 años' },
  { id: 6, name: '6 años' },
  { id: 7, name: '7 años' },
  { id: 8, name: '8 años' },
  { id: 9, name: '9 años' },
  { id: 10, name: '10 años' },
  { id: 11, name: '11 años' },
  { id: 12, name: '12 años' },
  { id: 13, name: '13 años' },
  { id: 14, name: '14 años' },
  { id: 15, name: '15 años' },
  { id: 16, name: '16 años' },
  { id: 17, name: '17 años' }
];

export const phoneTypes = [
  {
    id: 1,
    name: 'Celular'
  },
  {
    id: 2,
    name: 'Linea fija'
  }
];

export const phoneCodes = [
  {
    id: 1,
    name: '+51'
  }
];

export const personType = [
  {
    id: 1,
    name: 'Persona fisica'
  }
];
