import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useParams } from 'react-router';

import { getPackageInfo, getUpsellingInfo } from '../../actions';

import PackageContainer from './PackageContainer';

const selector = ({ packages }) => ({
  error: packages.error,
  packageItemData: packages.packageItemData,
  upSelling: packages.upSelling,
  loading: packages.loading
});
const ConnectedComponent = props => {
  const { keyword, id } = useParams();
  const dispatch = useDispatch();
  const actions = {
    getPackage: (keyword, id) => {
      dispatch(getPackageInfo(keyword, id));
    },
    getUpselling: id => {
      dispatch(getUpsellingInfo(id));
    }
  };

  const state = useSelector(selector);
  return (
    <>
      <PackageContainer
        actions={actions}
        keyword={keyword}
        id={id}
        {...state}
        {...props}
      />
    </>
  );
};

ConnectedComponent.propTypes = {
  match: PropTypes.object
};

export default withRouter(ConnectedComponent);
