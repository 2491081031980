export default {
  one: '4px' as const,
  two: '8px' as const,
  three: '12px' as const,
  four: '16px' as const,
  five: '20px' as const,
  six: '24px' as const,
  seven: '28px' as const,
  eight: '32px' as const,
  nine: '36px' as const,
  ten: '40px' as const,
  eleven: '44px' as const,
  twelve: '48px' as const
};
