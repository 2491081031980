import { addHours, format, subHours } from 'date-fns';
import { IUseBreakpoints } from 'Constants/types';

export const returnCarrouselItems = (breakpoints: IUseBreakpoints) => {
  if (breakpoints.smallDown) {
    return 1;
  } else if (breakpoints.mediumDown) {
    return 2;
  } else if (breakpoints.largeDown) {
    return 3;
  } else {
    return 4;
  }
};

export const capitalize = string => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const returnCenterSlidePercent = (breakpoints: IUseBreakpoints) => {
  if (breakpoints.smallDown) {
    return 90;
  } else if (breakpoints.mediumDown) {
    return 50;
  } else {
    return 29;
  }
};

export const convertTimeToLocalInDestinations = (
  destinationTimezone: string
) => {
  const timeZone = destinationTimezone.replace('t', '');
  const isMinus = timeZone.indexOf('m') >= 0;

  // TODO: este time number va a cambiar cuando se actualice el objeto de GMTs en Keyston
  const timeNumber =
    Math.floor(Number(timeZone.replace('m', '').replace('p', '.'))) - 1;
  const myDate = new Date();
  const ISOString = myDate.toISOString();
  const time = ISOString.split('T')[1].split(':');

  if (isMinus) {
    return `${format(subHours(Number(time[0]), timeNumber), 'HH')}:${time[1]}`;
  } else {
    return `${format(addHours(Number(time[0]), timeNumber), 'HH')}:${time[1]}`;
  }
};
