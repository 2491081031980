import React from 'react';
import styled from 'styled-components';
import DestinationsTop from '../DestinationsTop';
import DestinationsBottom from '../DestinationsBottom';
import SectionBgImages from 'Components/SectionBgImages';
import FlightSearchDialog from 'Features/flights/components/FlightSearchDialog';
import { capitalize } from 'Features/destinations/helpers';
import { PackageCommonList } from '../../../packages/components/PackageCommonList';
import { PackageList } from '../../../common/types';

interface DestinationsHomeParams {
  bgUrl?: string;
  destination: string;
  packagesByDestination: PackageList[];
}

const DestinationsHome = ({
  bgUrl,
  destination,
  packagesByDestination
}: DestinationsHomeParams) => (
  <div>
    {/* TODO Fix SectionBgImages */}
    <SectionBgImages image={bgUrl} className="" />
    <DestinationsTop />
    <SearchWrapper>
      <FlightSearchDialog />
    </SearchWrapper>
    {packagesByDestination.length > 0 && (
      <PackageCommonListContainer>
        <PackageCommonList
          packageList={packagesByDestination}
          dividerTitle={`Paquetes recomendados para ${capitalize(destination)}`}
        />
      </PackageCommonListContainer>
    )}
    <DestinationsBottom destination={destination} />
  </div>
);

const SearchWrapper = styled.div`
  margin: 0 auto;

  max-width: 1100px;
  width: 100%;
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.palette.grays.white};
    border-radius: ${({ theme }) => theme.spacing.two};
  }
`;

const PackageCommonListContainer = styled.div`
width: 100%;
padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;
max-width: 1140px;
margin-top: 28px;
`;

export default DestinationsHome;
