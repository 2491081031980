/**
 *
 * SkyBackground
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import * as COLORS from 'Constants/colors';

import mid from './assets/mid.svg';
import topbot from './assets/topbot.svg';
import stars from './assets/stars.svg';

const SkyBackground = ({ className }) => (
  <div className={cs(className)}>
    <div className="topbotHolder">
      <img className="topbot" alt="cielo" src={topbot} />
      <img className="topbot" alt="cielo" src={topbot} />
    </div>

    <div className="starsHolder">
      <img className="stars" alt="cielo" src={stars} />
    </div>

    <div className="midHolder">
      <img className="mid" alt="cielo" src={mid} />
      <img className="mid" alt="cielo" src={mid} />
    </div>
  </div>
);

SkyBackground.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(SkyBackground)`
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: ${COLORS.gradientHomeMulti};

  .topbotHolder {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    animation-name: cielo-anim;
    animation-duration: 300s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .topbot {
      width: 3840px;
      height: 100%;
    }
  }

  .midHolder {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    animation-name: cielo-anim-reverse;
    animation-duration: 120s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .mid {
      position: relative;
      width: 3840px;
      height: 38%;
      top: 31%;
    }
  }

  .starsHolder {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;

    .stars {
      position: relative;
      width: 3840px;
      height: 100%;
    }
  }
`;
