
import { useState, useEffect } from 'react';

export const useBreakpoints = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const breakpoints = {
    xsmall: windowSize < 540,
    small: windowSize >= 540 && windowSize < 768,
    medium: windowSize >= 768 && windowSize < 960,
    large: windowSize >= 960 && windowSize < 1140,
    xlarge: windowSize >= 1140 && windowSize < 1366,
    xxlarge: windowSize >= 1366
  };

  return breakpoints;
};