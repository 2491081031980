import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import HotelSearchDialog from 'Features/hotels/components/HotelSearchDialog';
import HotelAvailability from 'Features/hotels/components/HotelAvailability';
import BannersContainer from 'Features/banners/components/BannersContainer';
import HotelError from 'Features/hotels/components/HotelError';
import { statusCodes, viewingCodes } from 'Features/hotels/constants';
import SkyLoader from 'Components/SkyLoader';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import HotelPropertyPage from 'Features/hotels/components/HotelPropertyPage';
import { handleUrlChange } from 'Features/hotels/components/HotelsHome/helpers';
import SearchEngineWrapper from 'Components/SearchEngineWrapper';
import MoveDownArrow from '../../../../Components/MoveDownArrow';
import { PaymentPromoBanner } from '../../../../Components/PaymentPromoBanner';
import { mostWantedPackagesRequest } from '../../../common/actions';
import { PackageCommonList } from '../../../packages/components/PackageCommonList';
import {PromoBanners} from '../../../../Components/PromoBanners';

const selector = ({ hotels, router, common }) => ({
  error: hotels.error,
  detailMode: hotels.search.detailMode,
  status: hotels.status,
  location: router.location,
  viewing: hotels.viewing,
  mostWantedPackages: common.mostWantedPackages,
  statusReadyPackages: common.status
});

const messages = ['Estamos volando para vos', '¡Tenés ganas!'];

const HotelSearch = () => {
  const dispatch = useDispatch();
  const mediumDown = useBreakpoints().mediumDown;
  const {
    error,
    status,
    location,
    viewing,
    mostWantedPackages,
    statusReadyPackages
  } = useSelector(selector, shallowEqual);

  useEffect(() => {
    handleUrlChange(location, dispatch);
  }, [location, dispatch]);

  useEffect(() => {
    dispatch(mostWantedPackagesRequest());
  }, []);

  const unused = status === statusCodes.UNUSED;
  const loading = status === statusCodes.LOADING;

  return (
    <>
      {error && <HotelError />}
      <SearchEngineWrapper
        unused={unused || error !== null}
        isMobile={mediumDown}>
        <HotelSearchDialog />
        {unused && (
          <MoveDownArrow name="mv-arrow-down" size={38} color="white" />
        )}
      </SearchEngineWrapper>
      {loading && <SkyLoader messages={messages} />}
      {!error && viewing === viewingCodes.AVAILABILITY && <HotelAvailability />}
      {!error && viewing === viewingCodes.PROPERTY && <HotelPropertyPage />}
      {unused && <PaymentPromoBanner />}
      {statusReadyPackages === 'ready' &&
        mostWantedPackages.length > 0 &&
        unused && (
          <PackageCommonListContainer >
            <PackageCommonList
              packageList={mostWantedPackages}
              dividerTitle={'Los paquetes más pedidos por nuestros viajeros:'}
            />
          </PackageCommonListContainer>
        )}
      {unused && <PromoBanners />}
      {unused && <BannersContainer />}
    </>
  );
};

HotelSearch.propTypes = {};

const PackageCommonListContainer = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    margin-top: 28px;
`;

export default HotelSearch;
