import React from 'react';
import {
  BuyButton,
  Card,
  Cuotas,
  FeeHolder,
  Price,
  PriceCurrency,
  Title,
  Image
} from './styles';

export interface IProduct {
  id: string;
  title: string;
  link: string;
  image: string;
  currency: string;
  price: string;
  fees: string;
}

interface Props {
  product: IProduct;
}

export const Product = ({ product }: Props) => {
  const handleClick = () => window.open(product.link, '_blank');

  return (
    <Card onClick={handleClick}>
      <Image src={product.image} />
      <div>
        <Title>{product.title}</Title>
        <div>
          <FeeHolder>
            <Price>
              <PriceCurrency>{product.currency}</PriceCurrency>
              <span>{product.price}</span>
            </Price>
            <Cuotas>{product.fees}</Cuotas>
          </FeeHolder>
          <div>
            <BuyButton onClick={handleClick}>Ver más</BuyButton>
          </div>
        </div>
      </div>
    </Card>
  );
};
