/**
 *
 * HotelDetailReservation
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import moment from 'moment';
import Text from 'Components/Text';
import Card from 'Components/Card';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import { isoDateToString, getFormatPrice } from 'Utils';

const HotelDetailReservation = ({
  className,
  packageRoom,
  request,
  onClick,
  checkIn,
  checkOut
}) => {
  const total =
    packageRoom.peopleByRoom &&
    packageRoom.peopleByRoom.reduce((t, t1) => ({
      adults: t.adults + t1.adults,
      kids: t.kids + t1.kids
    }));
  const totalPeople = total ? total.adults + total.kids : 0;
  const days = moment(request.checkOut).diff(moment(request.checkIn), 'days');
  const [open, setOpen] = useState(false);
  return (
    <div className={cs(className)}>
      <Card
        header="Detalle de tu Reserva"
        context="arena"
        headerCentered
        boxShadow>
        <div>
          <Text weight="bold">Fecha de la reserva:</Text>
          <Text paragraph weight="semi-bold" context="important">
            Inicia: <Text>{`${isoDateToString(request.checkIn)}`}</Text>
          </Text>
          <Text paragraph weight="semi-bold" context="important">
            Finaliza: <Text>{`${isoDateToString(request.checkOut)}`}</Text>
          </Text>
        </div>
        <hr />
        <div>
          <Text weight="bold">Registrarse:</Text>
        </div>
        <div>
          <Text weight="semi-bold" context="important">
            Horario de Check In:
          </Text>{' '}
          <Text>a partir de las {checkIn}hs</Text>
        </div>
        <div>
          <Text weight="semi-bold" context="important">
            Horario de Check Out:
          </Text>{' '}
          <Text>hasta las {checkOut}hs</Text>
        </div>
        <hr />
        <div>
          <Text context="important" paragraph>
            Precio final por noche para {totalPeople} persona
            {totalPeople > 1 ? 's' : ''}
          </Text>
          <Text context="important" size="xlarge" weight="semi-bold">
            {getFormatPrice(
              packageRoom.packagePrice.finalPricePerNight,
              packageRoom.packagePrice.currency
            )}
          </Text>
          <br />
          {packageRoom.localPrice && (
            <Text context="important" size="small" weight="semi-bold">
              {getFormatPrice(
                packageRoom.localPrice.finalPricePerNight,
                packageRoom.localPrice.currency,
                true
              )}
            </Text>
          )}
          <br />
          <br />
          <Text size="small" context="secondary" paragraph>
            Precio por {days} noche{days > 1 ? 's ' : ' '}
            <br />
            <Text weight="semi-bold">
              {packageRoom.packagePrice.currency}{' '}
              {parseFloat(packageRoom.packagePrice.finalPrice)}{' '}
            </Text>
            <br />
            {packageRoom.localPrice && (
              <Text size="small" weight="semi-bold">
                {getFormatPrice(
                  packageRoom.localPrice.finalPrice,
                  packageRoom.localPrice.currency,
                  true
                )}
              </Text>
            )}
          </Text>
          <br />

          {packageRoom.hasFreeCancellation ? (
            <Text size="small" paragraph className="pay-form">
              Acepta todas nuestras formas de pago
            </Text>
          ) : (
            ''
          )}
        </div>
        <div className="text-center">
          <Button
            onClick={() => {
              onClick(packageRoom);
            }}>
            Reservar ahora
          </Button>

          <div>
            <div
              className="hdr-detail"
              onClick={() => {
                setOpen(!open);
              }}>
              <Text paragraph context="information">
                Desglose
                <Icon name={open ? 'mv-arrow-up' : 'mv-arrow-down'} />
              </Text>
            </div>
            {open &&
              packageRoom.details &&
              packageRoom.details.map((detail, index) => (
                <Text key={index} paragraph context={detail.context}>
                  {detail.label}
                </Text>
              ))}
            {open ? (
              <div className="room-detail">
                {packageRoom.rooms &&
                  packageRoom.rooms.map((room, index) => (
                    <div key={room.allocation}>
                      <Text weight="semi-bold" paragraph size="small">
                        Habitaci&oacute;n {index + 1}
                      </Text>
                      <Text context="important" size="small">
                        <Icon name="mv-user" />
                        <Text context="default" size="small">
                          Hu&eacute;spedes x{room.adultsCount + room.kidsCount}
                        </Text>
                      </Text>
                    </div>
                  ))}
              </div>
            ) : (
              ''
            )}
          </div>
          <Text
            className="policy-header"
            paragraph
            weight="semi-bold"
            size="large">
            Pol&iacute;ticas de cancelaci&oacute;n
          </Text>
          <hr style={{ margin: '0' }} />
          {packageRoom.policyDetails &&
            packageRoom.policyDetails.map((policy, index) => (
              <Text key={index} paragraph context={policy.context}>
                {policy.label}
              </Text>
            ))}
          {packageRoom.rateCommentDetails && (
            <>
              <Text
                className="policy-header"
                paragraph
                weight="semi-bold"
                size="large">
                Observaciones Importantes
              </Text>
              <hr style={{ margin: '0' }} />
              {packageRoom.rateCommentDetails.map((comment, index) => (
                <Text key={index} paragraph context={comment.context}>
                  {comment.label}
                </Text>
              ))}
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

HotelDetailReservation.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  packageRoom: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string
};

export default styled(HotelDetailReservation)`
  height: '100%';
  width: '269px';

  .room-detail {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 10px;
    justify-content: space-between;
  }

  .pay-form {
    color: #70b42a;
    margin-bottom: 10px;
  }

  .policy-header {
    margin-top: 10px;
    font-size: 18px;
  }

  .hdr-detail {
    margin: 20px;

    p {
      text-align: center;
    }

    .icon {
      margin-left: 5px;
      margin-top: 2px;
      cursor: pointer;
    }
  }
`;
