import styled, { css } from 'styled-components';
import Text from '../../Components/Text';
import { breakpoints } from 'Constants';
import { PriceProps } from './types';

const minHeight = 75;
const maxHeight = 200;
const minWidth = 90;
const maxWidth = 250;

const scaleValue = (
  value: number,
  from: [number, number],
  to: [number, number]
) => {
  const scale = (to[1] - to[0]) / (from[1] - from[0]);
  const capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
  return ~~(capped * scale + to[0]);
};

const getHeight = (value: number, min: number, max: number) =>
  scaleValue(value, [min, max], [minHeight, maxHeight]);

const getWidth = (value: number, min: number, max: number) =>
  scaleValue(value, [min, max], [minWidth, maxWidth]);

export const Price = styled.div<PriceProps>`
  width: 70px;
  height: ${minHeight}px;
  border-radius: ${({ theme }) => theme.borderRadius.smooth};
  position: relative;
  bottom: 0;
  text-align: center;
  transition: 0.6s;
  
  ${({ color, value, minMax }) => css`
    height: ${minHeight + getHeight(value, minMax.min, minMax.max)}px;

    ${color &&
      css`
        background-color: ${color};
      `}
    
    @media screen and (max-width: ${breakpoints.medium}px) {
      width: ${minWidth + getWidth(value, minMax.min, minMax.max)}px;
    }
  `}

  span {
    position: absolute;
    font-size: 16px;
    font-weight: 600;
    left: 0;
    right: 0;
    top: 2.5px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    max-width:60px
    width: 100%;
  }
  
  @media screen and (max-width: ${breakpoints.medium}px) {
    height: 30px;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.default};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.default};
    
    span {
      text-align: right;
      max-width: 100%;
      margin-right: ${({ theme }) => theme.spacing.three};
    }
  }
`;

export const Container = styled.div`
  width: 70px;
  height: 30px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.blackHaze};
  overflow: hidden;
  position: absolute;
  bottom: 0;

  @media screen and (max-width: ${breakpoints.medium}px) {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.newBackground};
    font-weight: ${({ theme }) => theme.text.fontWeights.semibold};
    box-shadow: ${({ theme }) => theme.shadows.two};
  }
`;

// @ts-ignore
export const MonthChip = styled(Text)`
  width: 100%;
  height: 100%;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0.08px;
  padding-top: 4px;
  display: block;
  margin: 0 auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGrey};
`;
