import { updateLoader } from '../app/actions';
import API from '../../Services';
import {
  MOST_WANTED_PACKAGES_REQUEST,
  MOST_WANTED_PACKAGES_SUCCESS,
  MOST_WANTED_PACKAGES_FAILURE
} from './index';
export const mostWantedPackagesRequest = () => async dispatch => {
  dispatch({
    type: MOST_WANTED_PACKAGES_REQUEST
  });
  dispatch(updateLoader(true));
  try {
    const { data } = await API.packages.mostWanted();
    dispatch(updateLoader(false));
    await dispatch({
      type: MOST_WANTED_PACKAGES_SUCCESS,
      payload: data
    });
    return Promise.resolve();
  } catch (error) {
    dispatch(updateLoader(false));
    dispatch({
      type: MOST_WANTED_PACKAGES_FAILURE
    });
    return Promise.resolve();
  }
};
