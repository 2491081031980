/**
 *
 * MpcLegend
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from 'Components/Text';

const MpcLegend = ({ className }) => (
  <div className={cs(className)}>
    <div className="positive">
      <Text context="important" weight="semi-bold">
        +
      </Text>
      <div className="divider" />
    </div>
    <div>
      <Text context="important" weight="semi-bold">
        Económicos
      </Text>
    </div>
    <div className="negative">
      <Text context="important" weight="semi-bold">
        -
      </Text>
    </div>
  </div>
);

MpcLegend.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(MpcLegend)`
  height: 25px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 16px;
  background: ${COLORS.gradientGoldRight};
  box-shadow: ${COLORS.shadows.three};
  margin-top: 10px;

  .positive {
    padding-left: 8%;
    text-align: center;
    height: 23px;

    .divider {
      background: ${COLORS.gradientInfoRight};
      height: 2px;
      width: 76px;
      margin: -4px;
    }
  }

  .negative {
    padding-right: 8%;
  }

  @media all and (max-width: 992px) {
    .positive {
      padding-left: 4%;

      .divider {
        margin: -2px;
        width: 36px;
      }
    }
  }
`;
