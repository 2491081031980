/**
 *
 * CardInformation
 *
 */

import React from 'react';
import styled from 'styled-components';
import Icon from 'Components/Icon';
import Heading from 'Components/Heading';
import NewText from 'Components/NewText';
import { TIconName } from 'Components/Icon/types';

interface CardInformationProps {
  title: string;
  description?: string;
  icon: TIconName;
  value?: string;
}

const CardInformationContainer = styled.div`
  width: 200px;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
`;

const CardInformationHeader = styled.div`
  height: 30px;
  background-color: ${({ theme }) => theme.colors.otroVioleta};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardInformationContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.four};
  text-align: center;
`;

const IconHolder = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.two};
`;

const CardInformation = (props: CardInformationProps) => (
  <CardInformationContainer>
    <CardInformationHeader>
      <Heading color="white" fontWeight="medium">
        {props.title}
      </Heading>
    </CardInformationHeader>

    <CardInformationContent>
      <IconHolder>
        <Icon name={props.icon} themeColor="orange" size={58} />
      </IconHolder>
      <Heading color="wine" fontWeight="semibold" type="h5">
        {props.value}
      </Heading>
      {props.description && (
        <NewText paragraph fontWeight="medium" type="small">
          {props.description}
        </NewText>
      )}
    </CardInformationContent>
  </CardInformationContainer>
);

export default CardInformation;
