/**
 *
 * Tag
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as COLORS from 'Constants/colors';

import Text from '../Text';
import Icon from '../Icon';

const Tag = ({ children, onClick, color = COLORS.violeta }) => (
  <Container color={color}>
    <Text context="white" size="small" weight="semi-bold">
      {children}
    </Text>
    {onClick && (
      <>
        <div className="gap" />
        <Icon
          name="mv-cross"
          color="white"
          size={15}
          onClick={onClick}
          pointer
        />
      </>
    )}
  </Container>
);

Tag.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string
};

const Container = styled.div`
  display: inline-flex;
  background-color: ${({ color }) => color};
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
  margin: 8px 6px;

  .gap {
    width: 16px;
  }
`;

export default Tag;
