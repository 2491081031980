/**
 *
 * MobileHotelSearchCompact
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import * as COLORS from 'Constants/colors';
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import { formatLong } from 'Utils/dates';
import { totalPeople, setDetailMode } from 'Features/hotels';

const selector = ({ hotels }) => ({
  checkIn: hotels.search.checkIn,
  checkOut: hotels.search.checkOut,
  location: hotels.search.location,
  roomInformation: hotels.search.roomInformation
});

const ConnectedMobileHotelSearchCompact = props => {
  const dispatch = useDispatch();
  const actions = {
    setModify: () => {
      dispatch(setDetailMode(false));
    }
  };

  const state = useSelector(selector);

  return (
    <StyledMobileHotelSearchCompact actions={actions} {...state} {...props} />
  );
};

const MobileHotelSearchCompact = ({
  className,
  checkIn,
  checkOut,
  location,
  roomInformation,
  actions
}) => (
  <div className={cs(className)}>
    <div className="destination-container">
      <div className="flex-row">
        <div className="flex-row noWrap">
          <Icon name="mv-location-2" size={20} />
          <Text weight="semi-bold" noWrap>
            {location.description}
          </Text>
        </div>
        <div className="flex-row margin-left">
          <Icon name="mv-bed-double" size={20} />
          <Text weight="semi-bold">{roomInformation.length}</Text>
        </div>
        <div className="flex-row margin-left">
          <Icon name="mv-user" size={20} />
          <Text weight="semi-bold">{totalPeople(roomInformation)}</Text>
        </div>
      </div>
      <div className="flex-row">
        <Icon name="mv-calendar" size={20} />
        <Text weight="semi-bold">
          {formatLong(checkIn)} - {formatLong(checkOut)}
        </Text>
      </div>
    </div>
    <div className="search-container" onClick={actions.setModify}>
      <Icon name="mv-lense" size={24} />
    </div>
  </div>
);

MobileHotelSearchCompact.propTypes = {
  className: PropTypes.string.isRequired,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  roomInformation: PropTypes.array,
  location: PropTypes.shape({
    placeId: PropTypes.string,
    description: PropTypes.string
  }),
  actions: PropTypes.shape({
    setModify: PropTypes.func.isRequired
  })
};

const StyledMobileHotelSearchCompact = styled(MobileHotelSearchCompact)`
  display: flex;
  justify-content: space-between;
  padding: 5px 15px 5px 15px;
  background: ${COLORS.white};

  .search-container {
    color: ${COLORS.important};
    border-left: 1px solid ${COLORS.darkGrey};
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 15px;
      margin-right: 10px;
      color: ${COLORS.important};
    }
  }

  .destination-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .flex-row {
      display: flex;
      align-items: center;

      &.noWrap {
        max-width: 150px;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
        color: ${COLORS.important};
      }
    }
  }

  .margin-left {
    margin-left: 15px;
    margin-right: 5px;
  }
`;

export default ConnectedMobileHotelSearchCompact;
export { StyledMobileHotelSearchCompact as MobileHotelSearchCompact };
