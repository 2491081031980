/**
 *
 * PaxDetailHotel
 *
 */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import { edadesOpciones } from 'Constants/mocks';

import Text from 'Components/Text';
import Icon from 'Components/Icon';
import Select from 'Components/Select';
import Button from 'Components/Button';
import PlusMinusSelector from 'Components/PlusMinusSelector';
import useOutsideClick from 'Utils/hooks/useOutsideClick';
import { types, getPaxHotelActions } from 'Features/hotels';
import NewCheckbox from 'Components/NewCheckbox';
import NewText from 'Components/NewText';
import Label from 'Components/Label';
import InputErrorMessage from '../../../../Components/InputErrorMessage';
import { hasInvalidKidAges } from '../../helpers';

const selector = ({ hotels }) => ({
  allowedPets: hotels.search.allowedPets,
  roomInformation: hotels.search.roomInformation,
  messages: hotels.search.validations.roomInformation
});

const ConnectedPaxDetailHotel = props => {
  const dispatch = useDispatch();
  const actions = getPaxHotelActions(dispatch);

  const state = useSelector(selector);

  return <StyledPaxDetailHotel actions={actions} {...state} {...props} />;
};

const PaxDetailHotel = ({
  className,
  actions,
  allowedPets,
  roomInformation,
  messages
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const refOpenMenu = useRef();

  const {
    onChangeAdults,
    onChangeChildren,
    onChangeChildrenAge,
    onTogglePets,
    removeRoom,
    addRoom
  } = actions;

  useOutsideClick(refOpenMenu, () => {
    if (openMenu) setOpenMenu(false);
  });

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const onClickApply = () => {
    toggleMenu();
  };

  const totalRooms = roomInformation.length;

  const hasInvalidRoomKidAges = () => hasInvalidKidAges(roomInformation);

  return (
    <div className={cs(className)}>
      <div className="mv-field-container">
        <div className="mv-input" onClick={toggleMenu}>
          <Label color="orange">HABITACIONES Y HUéspedes</Label>
          <InputErrorMessage message={messages[0] || null} topPosition="28px" />
          <div className="mv-input-container">
            <div className="mv-input-holder">
              <Icon name="mv-bed-double" />
              <Text>
                {totalRooms} {totalRooms > 1 ? 'Habitaciones' : 'Habitación'}
              </Text>
            </div>
          </div>
        </div>
      </div>

      {openMenu && (
        <div className="mv-pax-detail_popup-holder" ref={refOpenMenu}>
          <div className="divider-popup padding-popup">
            <Icon name="mv-bed-double" themeColor="wine" /> Habitaciones
          </div>
          <div className="form-group padding-popup">
            <div>
              <Text>Habitaciones</Text>
            </div>
            <div>
              <PlusMinusSelector
                stateless
                onIncrement={addRoom}
                onDecrement={removeRoom}
                value={roomInformation.length}
                minValue={1}
                maxValue={4}
              />
            </div>
          </div>
          {roomInformation.map((room, index) => (
            <React.Fragment key={index}>
              <div className="divider-popup padding-popup">
                <Icon name="mv-user" themeColor="wine" /> Habitación número{' '}
                {index + 1}
              </div>
              <div className="divider-popup padding-popup">
                {room.adultsCount + room.kidsAges.length > 4 ? (
                  <Text context="important">
                    El número máximo de huéspedes por habitación es 4
                  </Text>
                ) : null}
              </div>
              <div className="form-group padding-popup">
                <div>
                  <Text>Adultos</Text>
                  <br />
                  <Text context="important">Desde los 18 años</Text>
                </div>
                <div>
                  <PlusMinusSelector
                    stateless
                    onIncrement={onChangeAdults(index, types.INCREMENT)}
                    onDecrement={onChangeAdults(index, types.DECREMENT)}
                    value={room.adultsCount}
                    minValue={1}
                    maxValue={4}
                  />
                </div>
              </div>

              <div className="form-group padding-popup">
                <div>
                  <Text>Menores</Text>
                  <br />
                  <Text context="important">Hasta los 17 años</Text>
                </div>
                <div>
                  <PlusMinusSelector
                    stateless
                    onIncrement={onChangeChildren(index, types.INCREMENT)}
                    onDecrement={onChangeChildren(index, types.DECREMENT)}
                    value={room.kidsAges.length}
                    minValue={0}
                    maxValue={2}
                  />
                </div>
              </div>
              {room.kidsAges.map((age, indexChildren) => (
                <div key={indexChildren} className="form-group padding-popup">
                  <div>
                    <Text>Edad del menor</Text>
                    <br />
                    <Text context="important">Al finalizar el viaje</Text>
                  </div>
                  <div>
                    <Select
                      items={edadesOpciones}
                      selectedValue={age}
                      onSelect={onChangeChildrenAge(index, indexChildren)}
                    />
                    {!age && age !== 0 && (
                      <Text context="warning">Requerido</Text>
                    )}
                    {age <= 2 && (
                      <Text context="important">Tarifa de bebe</Text>
                    )}
                    {age > 2 && <Text context="important">Tarifa de niño</Text>}
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))}

          <div className="divider">
            <mv-divider context="light" size=""></mv-divider>
          </div>

          <PetsContainer>
            <div>
              <NewCheckbox
                checked={allowedPets}
                onChange={onTogglePets}
                addMargin
                color="orange"
              />
              <NewText>Mascotas</NewText>
            </div>
            {allowedPets && (
              <Text context="important">
                Solo se mostrarán las propiedades que permiten mascotas.
              </Text>
            )}
          </PetsContainer>

          <div className="apply-btn-holder">
            <Button
              weight="semi-bold"
              onClick={onClickApply}
              disabled={hasInvalidRoomKidAges()}>
              APLICAR
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

PaxDetailHotel.propTypes = {
  className: PropTypes.string.isRequired,
  roomInformation: PropTypes.array,
  allowedPets: PropTypes.bool,
  messages: PropTypes.array,
  actions: PropTypes.shape({
    onChangeAdults: PropTypes.func,
    onChangeChildren: PropTypes.func,
    onChangeChildrenAge: PropTypes.func,
    onTogglePets: PropTypes.func,
    removeRoom: PropTypes.func,
    addRoom: PropTypes.func
  })
};

const PetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.one};
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledPaxDetailHotel = styled(PaxDetailHotel)`
  .divider-popup {
    background-color: ${COLORS.arena};
    color: ${({ theme }) => theme.colors.wine};
    font-size: 16px;
    display: flex;
    .icon {
      margin-right: ${({ theme }) => theme.spacing.two};
    }
  }

  .header {
    margin-bottom: ${({ theme }) => theme.spacing.two};
  }

  .mv-field-container {
    position: relative;
    .mv-error-input {
      position: relative;
      z-index: 1;
    }
  }

  .mv-input {
    z-index: 2;
    position: relative;
    .mv-input-container {
      border-radius: 14px;
      z-index: 1;
      overflow: hidden;
      position: relative;
      box-shadow: ${({ theme }) => theme.shadows.one};
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.96;
      letter-spacing: 0.1px;
      text-align: left;
      color: ${COLORS.darkGrey};

      .mv-input-holder {
        display: flex;
        border-radius: 14px;
        background: ${COLORS.white};
        padding: 0 10px;
        align-items: center;
        height: 33px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .icon {
          margin-right: 10px;
          color: ${COLORS.important};
          font-size: 20px;
        }
      }
    }
  }

  .mv-pax-detail_popup-holder {
    width: 350px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.35);
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding-top: 13px;
    position: absolute;
    background: white;
    right: 0;
    overflow-y: auto;
    z-index: 2;
    ${({ theme }) => theme.scrollbar(theme.colors.orange)}
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
  }

  .padding-popup {
    padding: 0 18px;
  }

  .margin-class {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .apply-btn-holder {
    height: 50px;
    background-color: ${COLORS.arena};
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divider {
    margin-bottom: 18px;
  }
`;

export default ConnectedPaxDetailHotel;
export { StyledPaxDetailHotel as PaxDetailHotel };
