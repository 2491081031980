import API from 'Services/Quotations';
import { Dispatch } from 'redux';

import {
  PUT_FLIGHT_QUOTATION_SUCCESS,
  PUT_FLIGHT_QUOTATION_FAILURE,
  PUT_FLIGHT_QUOTATION_REQUEST,
  GET_QUOTATIONS_LIST_REQUEST,
  GET_QUOTATIONS_LIST_SUCCESS,
  GET_QUOTATIONS_LIST_FAILURE,
  GET_QUOTATION_DETAILS_FAILURE,
  GET_QUOTATION_DETAILS_REQUEST,
  GET_QUOTATION_DETAILS_SUCCESS,
  DELETE_FLIGHT_QUOTATION_FAILURE,
  DELETE_FLIGHT_QUOTATION_REQUEST,
  DELETE_FLIGHT_QUOTATION_SUCCESS,
  TQuotationAction
} from './actionTypes';
import {
  IErrorResponse,
  IFlightQuotation,
  IQuotationParams
} from 'Services/Quotations/types';

// GET QUOTATION LIST
const getQuotationsListRequest = (): TQuotationAction => ({
  type: GET_QUOTATIONS_LIST_REQUEST
});

const getQuotationsListSuccess = (payload): TQuotationAction => ({
  type: GET_QUOTATIONS_LIST_SUCCESS,
  payload
});

const getQuotationsListFailure = (): TQuotationAction => ({
  type: GET_QUOTATIONS_LIST_FAILURE
});

export const getQuotationsList = (params: IQuotationParams) => async (
  dispatch: Dispatch
) => {
  dispatch(getQuotationsListRequest());

  try {
    const { data } = await API.getQuotations(params);

    dispatch(getQuotationsListSuccess(data));
  } catch (err) {
    dispatch(getQuotationsListFailure());
  }
};

// GET QUOTATION DETAIL
export const getQuotation = (id: number) => async (dispatch: Dispatch) => {
  dispatch({ type: GET_QUOTATION_DETAILS_REQUEST });

  try {
    const { data } = await API.getQuotation(id);
    if ('id' in data) {
      dispatch({ type: GET_QUOTATION_DETAILS_SUCCESS, payload: data });
    } else {
      dispatch({ type: GET_QUOTATION_DETAILS_FAILURE, payload: data });
    }
  } catch (error) {
    dispatch({ type: GET_QUOTATION_DETAILS_FAILURE, payload: error });
  }
};

//PATCH FLIGHT QUOTATION
const putFlightQuotationRequest = (): TQuotationAction => ({
  type: PUT_FLIGHT_QUOTATION_REQUEST
});

const putFlightQuotationSuccess = (): TQuotationAction => ({
  type: PUT_FLIGHT_QUOTATION_SUCCESS
});

const putFlightQuotationFailure = (err: IErrorResponse): TQuotationAction => ({
  type: PUT_FLIGHT_QUOTATION_FAILURE,
  payload: err
});

export const putFlightQuotation = (
  quotationId: number,
  flightId: number,
  request: IFlightQuotation
) => async (dispatch: Dispatch) => {
  dispatch(putFlightQuotationRequest());

  try {
    await API.putFlight(quotationId, request, flightId);

    dispatch(putFlightQuotationSuccess());
  } catch (err) {
    dispatch(putFlightQuotationFailure(err));
  }
};

// DELETE FLIGHT QUOTATION
const deleteFlightQuotationRequest = (): TQuotationAction => ({
  type: DELETE_FLIGHT_QUOTATION_REQUEST
});

const deleteFlightQuotationSuccess = (payload: {
  id: number;
  flightId: number;
}): TQuotationAction => ({
  type: DELETE_FLIGHT_QUOTATION_SUCCESS,
  payload
});

const deleteFlightQuotationFailure = (error: any): TQuotationAction => ({
  type: DELETE_FLIGHT_QUOTATION_FAILURE,
  payload: error
});

export const deleteFlightQuotation = (id: number, flightId: number) => async (
  dispatch: Dispatch
) => {
  dispatch(deleteFlightQuotationRequest());

  try {
    await API.deleteFlight(id, flightId);

    dispatch(deleteFlightQuotationSuccess({ id, flightId }));
  } catch (err) {
    dispatch(deleteFlightQuotationFailure(err));
  }
};
