const getCountry = () => {
  const countryCode = process.env.REACT_APP_COUNTRY;
  const countryNames = {
    UY: 'Uruguay',
    PY: 'Paraguay',
    BO: 'Bolivia',
    CO: 'Colombia'
  };
  return countryNames[countryCode];
};

export default (cityKeyword: string) => ({
  query: ` {
  allCities( where: { sluggedName: "${cityKeyword}" }) {
    id,
    iata,
    isIata,
    isCapital,
    areaCode,
    name,
    description,
    timeToTravel,
    background{
      publicUrl
    },
    comboMeal,
    bigMac,
    milk,
    apples,
    superMarketBeer,
    coke,
    gas,
    dinner,
    pub,
    cocktail,
    beer,
    wine,
    capuccino,
    taxi,
    publicTransport,
    timeZone,
    currency,
    is220,
    is110,
    isPlugTypeA,
    isPlugTypeB,
    isPlugTypeC,
    isPlugTypeD,
    isPlugTypeE,
    isPlugTypeF,
    isPlugTypeG,
    isPlugTypeH,
    isPlugTypeI,
    isPlugTypeJ,
    isPlugTypeK,
    isPlugTypeL,
    minTemperature {
      jan,
      feb,
      mar,
      apr,
      may,
      jun,
      jul,
      aug,
      sep,
      oct,
      nov,
      dec
    },  
    maxTemperature{
      jan,
      feb,
      mar,
      apr,
      may,
      jun,
      jul,
      aug,
      sep,
      oct,
      nov,
      dec
    },
    overseas{
      jan,
      feb,
      mar,
      apr,
      may,
      jun,
      jul,
      aug,
      sep,
      oct,
      nov,
      dec
    },
    rainy{
      jan,
      feb,
      mar,
      apr,
      may,
      jun,
      jul,
      aug,
      sep,
      oct,
      nov,
      dec
    },
    airports{
      id,
      name,
      description,
      distanceToDowntown,
      photo {
        publicUrl
      }
    }
    bestZone {
      id,
      title,
      description,
      photo {
        publicUrl
      }
    },
    whatToDo {
      id,
      title,
      description,
      photo {
        publicUrl
      }
    },
    events {
      id,
      title,
      description,
      photo {
        publicUrl
      }
    },
    tips {
      tip
    },
    didYouKnow {
      id,
      title,
      description
    },
    howToMove {
      id,
      title,
      description,
      photo{
        publicUrl
      }
    },
    banner(where:{country:{name:"${getCountry()}"}}){
      id,
      title,
      price,
      url
    }
    uyPackageUrl,
    pyPackageUrl,
    seasonsEneMar,
    seasonsAbrJun,
    seasonsJulSet,
    seasonsOctDic,
    metaTitle,
    metaDescription,
    metaKeywords,
  }
}
`
});
