import { engines } from '../Constants';

export const arrangeParamsCorpo = (
  { availability, checkoutCorpo, hotels },
  engine
) => {
  switch (engine) {
    case engines.FLIGHTS.name:
    case engines.FLIGHTS.name + 'MPC':
      return {
        airSellInfo: {
          selectedOption: {
            completeFlights: Object.keys(availability.selectedSegment)
              .filter(key => availability.selectedSegment[key].item)
              .map(key => ({
                segments: availability.selectedSegment[key].item.segments
              })),
            validatingCarrier: availability.flightPrice.validatingCarrier
          },
          totalPriceSale: availability.flightPrice.totalPrice,
          coinId: 10
        },
        passengers: checkoutCorpo.passengers.map(passenger => ({
          firstName: passenger.firstNames,
          lastName: passenger.lastNames,
          birthDate: {
            day: Number(passenger.day.name),
            month: Number(passenger.month.id),
            year: Number(passenger.year.name)
          },
          gender: passenger.gender.id || 1,
          documentData: {
            nationality: passenger.country.plCitizenCode || 'URY',
            documentType:
              passenger.documentType.id === 1 ? passenger.documentType.id : 2,
            document: passenger.document
          },
          travelerCode: passenger.code
        })),
        markRuleInfoApplied: availability.markRuleInfoApplied,
        comment: checkoutCorpo.comment.comment
      };
    case 'hotel-details':
    case engines.HOTELS.name:
      return {
        additionalInfo: {
          bookingPrice: availability.hotelInformation.packagePrice.finalPrice,
          hotelId: hotels.property.id,
          coinId: 10,
          packageId: availability.hotelInformation.packageId,
          sessionId: hotels.search.sessionId,
          supplierId: availability.hotelInformation.supplierId,
          checkIn: hotels.search.checkIn + 'T' + hotels.property.checkIn,
          checkOut: hotels.search.checkOut + 'T' + hotels.property.checkOut,
          priceInSupplier: availability.hotelInformation.packagePrice.netPrice,
          isFreeCancellation: availability.hotelInformation.hasFreeCancellation
        },
        passengersRooms: {
          rooms: availability.hotelInformation.rooms.map(
            ({ rateKey, boardName, roomName, occupancy, rateComments }) => ({
              rateKey,
              boardName,
              roomName,
              rateComments,
              totalRooms: occupancy.rooms,
              totalAdults: occupancy.adults,
              totalChildren: occupancy.children,
              childrenAges: occupancy.paxes
                ? occupancy.paxes.map(({ age }) => age)
                : [],
              holders: checkoutCorpo.holder.holders
                .filter(holder => holder.rateKey === rateKey)
                .map(({ firstName, lastName }) => ({ firstName, lastName }))
            })
          ),
          titularPassenger: {
            firstName: checkoutCorpo.holder.holders.find(
              holder => holder.isHolder
            ).firstName,
            lastName: checkoutCorpo.holder.holders.find(
              holder => holder.isHolder
            ).lastName
          }
        },
        additionalRequirements: checkoutCorpo.holder.addons,
        comment: checkoutCorpo.comment.comment
      };
    default:
      throw Error('Not Implemented');
  }
};
