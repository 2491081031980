/**
 *
 * MpcAvailability
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, batch } from 'react-redux';
import moment from 'moment';

import useBreakpoints from 'Utils/hooks/useBreakpoints';

import MpcAvailabilityDesktop from '../MpcAvailabilityDesktop';
import MobileMpcAvailability from '../MobileMpcAvailability';
import { dateForMpc } from 'Utils/returnDate';
import Text from 'Components/Text';
import { updateLoader } from 'Features/app/actions';
import { resetToEngine } from 'ReduxConfig/availability';
import { engines, routes } from 'Constants';
import { push } from 'connected-react-router';
import {
  handleUrlToObjectSearchFlight,
  handleObjectSearchFlightToUrl,
  getTypeFlightSearch
} from 'Features/flights/helpers';
import { checkSelectionRequest } from 'Features/flights/actions';
import { useUser } from '../../../users/utils';
import { isCorpo } from '../../../../Services/Users/types';

const searchSelector = ({ router }) => ({
  pathname: router.location.pathname,
  search: router.location.search
});

const MpcAvailability = ({ availability }) => {
  const { selectionError } = availability;
  const { request } = availability.data;
  const {
    detail,
    departureDates,
    arrivalDates,
    minPrice,
    middlePrice,
    maxPrice
  } = availability.data.response;
  const { pathname, search } = useSelector(searchSelector);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { isChecking } = useSelector(({ availability }) => ({
    isChecking: availability.pending
  }));
  const [requestState, setRequestState] = useState(request);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [mptbDates, setMptbDates] = useState();

  const dispatch = useDispatch();
  const isMediumDown = useBreakpoints().mediumDown;
  const user = useUser();

  const handleMPCItemClick = detailItem => () => {
    const flightPrice = detailItem.flightPrice;
    const localPrice = detailItem.localPrice;
    const initialDate = detailItem.departureDate;
    const endDate = detailItem.arrivalDate;
    const onClick = handleSelectFlight(detailItem);
    const newState = {
      generalSegment: detailItem.generalSegments,
      pending: isChecking,
      flightPrice,
      localPrice,
      initialDate,
      endDate,
      onClick
    };

    const newRoutesData = request.routesData.map(item => {
      item.initialDate = initialDate;
      if (item && item.endDate) item.endDate = endDate;
      return item;
    });
    setRequestState({
      ...requestState,
      routesData: newRoutesData
    });

    setModalData(newState);
    setShowModal(true);
  };

  useEffect(() => {
    if (selectionError) {
      const { ida, vuelta } = selectionError.selectedSegment;
      const endDate = vuelta.item.departureData.flightDate;
      const initialDate = ida.item.departureData.flightDate;
      setMptbDates({ endDate, initialDate });
      setShowErrorModal(true);
    }
  }, [selectionError]);

  const handleSelectFlight = availability => selectedSegment => {
    const segments = Object.keys(selectedSegment)
      .map(key => ({
        segments:
          selectedSegment[key].item !== undefined
            ? selectedSegment[key].item.segments
            : undefined
      }))
      .filter(value => value.segments !== undefined);

    const params = {
      selectedOption: {
        completeFlights: segments,
        validatingCarrier: availability.flightPrice.validatingCarrier
      },
      passengerInfo: {
        totalAdult: request.totalAdult,
        totalChildren: request.totalChildren,
        totalInfant: request.totalInfant,
        totalInfantWithSeat: request.totalInfantWithSeat
      }
    };
    dispatch(updateLoader(true));
    dispatch(
      checkSelectionRequest(
        params,
        availability,
        selectedSegment,
        true,
        isCorpo(user),
        true
      )
    );
  };

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const toggleErrorModal = () => {
    setShowErrorModal(false);
    handleShowAllFlights(mptbDates);
  };

  const handleShowAllFlights = newParams => {
    let changeSearch = handleUrlToObjectSearchFlight(
      pathname,
      search,
      newParams
    );
    changeSearch['adults'] = changeSearch['totalAdult'];
    changeSearch['isMPC'] = false;
    const typeSearch = getTypeFlightSearch(changeSearch.filterType);
    const newSearchQs = handleObjectSearchFlightToUrl(typeSearch, changeSearch);
    batch(() => {
      dispatch(resetToEngine(engines.FLIGHTS.name));
      dispatch(
        push({
          pathname: routes.flightSearch.replace(
            ':destination',
            changeSearch.cityKeyword
          ),
          search: newSearchQs
        })
      );
    });
  };

  const canSubstract = () => {
    const { routesData } = request;
    const { initialDate, endDate } = routesData[0];
    return moment(initialDate)
      .add(7, 'days')
      .isBefore(endDate);
  };

  const canDecreaseInitialDate = () => {
    const { routesData } = request;
    const { initialDate } = routesData[0];
    return moment(initialDate)
      .subtract(7, 'days')
      .isAfter(moment());
  };

  const arrowKeys = {
    right: 'right',
    left: 'left',
    up: 'up',
    down: 'down'
  };

  const handleArrowButton = arrowKey => () => {
    const map = {
      [arrowKeys.right]: {
        action: 'add',
        field: 'initialDate'
      },
      [arrowKeys.left]: {
        action: 'subtract',
        field: 'initialDate'
      },
      [arrowKeys.down]: {
        action: 'add',
        field: 'endDate'
      },
      [arrowKeys.up]: {
        action: 'subtract',
        field: 'endDate'
      }
    };
    const { action, field } = map[arrowKey];

    const newRequest = { ...request };
    newRequest.routesData[0][field] = moment(newRequest.routesData[0][field])
      // eslint-disable-next-line no-unexpected-multiline
      [action](7, 'days')
      .format('YYYY-MM-DD');

    const newDates = {
      initialDate: newRequest.routesData[0]['initialDate'],
      endDate: newRequest.routesData[0]['endDate']
    };
    let changeSearch = handleUrlToObjectSearchFlight(
      pathname,
      search,
      newDates
    );
    changeSearch['adults'] = changeSearch['totalAdult'];
    changeSearch['isMPC'] = true;
    const typeSearch = getTypeFlightSearch(changeSearch.filterType);
    const newSearchQs = handleObjectSearchFlightToUrl(typeSearch, changeSearch);

    dispatch(
      push({
        pathname: routes.flightSearch.replace(
          ':destination',
          changeSearch.cityKeyword
        ),
        search: newSearchQs
      })
    );
  };

  const leftArrowContent = () => (
    <>
      <Text>IDA</Text>
      <Text>{dateForMpc(request.routesData[0].initialDate)}</Text>
    </>
  );

  const rightArrowContent = () => (
    <>
      <Text>VUELTA</Text>
      <Text>{dateForMpc(request.routesData[0].endDate)}</Text>
    </>
  );

  return isMediumDown ? (
    <MobileMpcAvailability
      canDecreaseInitialDate={canDecreaseInitialDate}
      canSubstract={canSubstract}
      handleArrowButton={handleArrowButton}
      arrowKeys={arrowKeys}
      arrivalDates={arrivalDates}
      departureDates={departureDates}
      showModal={showModal}
      toggleShowModal={toggleShowModal}
      modalData={modalData}
      handleShowAllFlights={handleShowAllFlights}
      handleMPCItemClick={handleMPCItemClick}
      detail={detail}
      minPrice={minPrice}
      middlePrice={middlePrice}
      maxPrice={maxPrice}
      leftArrowContent={leftArrowContent()}
      rightArrowContent={rightArrowContent()}
      showErrorModal={showErrorModal}
      toggleErrorModal={toggleErrorModal}
    />
  ) : (
    <MpcAvailabilityDesktop
      canDecreaseInitialDate={canDecreaseInitialDate}
      canSubstract={canSubstract}
      handleArrowButton={handleArrowButton}
      arrowKeys={arrowKeys}
      arrivalDates={arrivalDates}
      departureDates={departureDates}
      showModal={showModal}
      toggleShowModal={toggleShowModal}
      modalData={modalData}
      minPrice={minPrice}
      middlePrice={middlePrice}
      maxPrice={maxPrice}
      handleShowAllFlights={handleShowAllFlights}
      handleMPCItemClick={handleMPCItemClick}
      detail={detail}
      showErrorModal={showErrorModal}
      toggleErrorModal={toggleErrorModal}
    />
  );
};

MpcAvailability.propTypes = {
  availability: PropTypes.any
};

export default MpcAvailability;
