/**
 *
 * Tabs
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTypeFlight } from '../../Features/flights';
import Tabs from './Tabs';

const selector = ({ flights }) => ({
  type: flights.search.type
});

const ConnectedTabs = props => {
  const dispatch = useDispatch();

  const actions = {
    setTypeFlight: (type: string) => {
      dispatch(setTypeFlight(type));
    }
  };

  const state = useSelector(selector);

  return <Tabs {...state} {...props} actions={actions} />;
};

export default ConnectedTabs;
export { Tabs };
