/**
 *
 * CardAlert
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
// import * as COLORS from 'Constants/colors';
import Icon from '../Icon';
import Text from '../Text';
import Card from '../Card';

const CardAlert = ({ className, children, nameIcon, title, color }) => (
  <Card className={cs(className, 'content-alert')}>
    <Icon name={nameIcon} color={color} size={24} />
    <Text weight="bold" context={color}>
      {' '}
      {title}{' '}
    </Text>
    {children}
  </Card>
);

CardAlert.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  nameIcon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default styled(CardAlert)`
  height: auto;
  margin-bottom: 12px;

  .mv-card-content {
    display: flex;
    flex-flow: row wrap;

    span:nth-child(2) {
      padding-top: 5px;
      padding-left: 5px;
    }

    p {
      width: 100%;
      flex: 1 1 auto;
    }
  }

  @media all and (max-width: 600px) {
    margin: 12px;

    .mv-card-content {
      flex-flow: column wrap;
      text-align: center;
    }
  }
`;
