/**
 *
 * MeteorologyColor
 *
 */

import React from 'react';

// Components
import Text from 'Components/Text';
import { Meteorology } from './styles';

// Types
import { MeteorologyColorProps, TTemperatureColors } from './types';

// Contants
import { meteorologyColors } from 'Constants';

// Utils
import { getValueByKey } from 'Utils/objectUtils';

const MeteorologyColor = ({
  type,
  value,
  symbol,
  minMax
}: MeteorologyColorProps) => {
  const temperatureColor: TTemperatureColors =
    type && getValueByKey(meteorologyColors, type, value, 'color');

  return (
    <Meteorology
      type={type}
      color={temperatureColor}
      value={value}
      minMax={minMax}>
      <Text context="white" weight="bold">
        {value}
        {symbol}
      </Text>
    </Meteorology>
  );
};

export default MeteorologyColor;
