import styled from 'styled-components';

export const Form = styled.div`
  padding: ${({ theme }) => theme.spacing.four};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.shadows.four};
`;

export const Header = styled.div`
  font-size: ${({ theme }) => theme.text.fontSize.headings.large};
  color: ${({ theme }) => theme.palette.status.wine};
  font-weight: ${({ theme }) => theme.weight.one};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.three};

  > span {
    margin-right: ${({ theme }) => theme.spacing.four};
    color: ${({ theme }) => theme.colors.newBackground};
    font-weight: ${({ theme }) => theme.weight.one};
  }
`;

export const Footer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.four};
  text-align: right;

  > button {
    margin-right: ${({ theme }) => theme.spacing.three};
  }
`;

export const FormItem = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.four};
`;
