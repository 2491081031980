import API from 'Services';
import { routes } from 'Constants';
import { updateLoader } from '../app/actions';
import ACTION_TYPES from './actionTypes';
import {
  filterByCity,
  filterByHow,
  filterByRegion,
  filterByWhen,
  orderByDuration,
  orderByTitle
} from './helpers';
import { push } from 'connected-react-router';
import { orderByOptions, filterKeys } from './constants';
import qs from 'query-string';
import { cleanSearch } from 'Features/flights';

export const packagesRequestSuccess = data => dispatch => {
  dispatch({
    type: ACTION_TYPES.PACKAGES_SUCCESS,
    payload: {
      ...data
    }
  });
  return Promise.resolve();
};

export const loadFilters = queryString => (dispatch, getState) => {
  const {
    where,
    when: { months, seasons },
    how
  } = getState().packages.searcher;

  const queryParameters = qs.parse(queryString);
  let newFilters = {};
  Object.keys(queryParameters).map(key => {
    switch (key) {
      case filterKeys.WHEN: {
        const month = months.find(month => month.name === queryParameters[key]);
        const season = seasons.find(
          season => season.name === queryParameters[key]
        );
        if (month) {
          const filterItem = { type: 'MONTH', option: month };
          newFilters = { ...newFilters, [key]: filterItem };
        }
        if (season) {
          const filterItem = { type: 'SEASON', option: season };
          newFilters = { ...newFilters, [key]: filterItem };
        }
        break;
      }
      case filterKeys.HOW: {
        const option = how.find(h => h.name === queryParameters[key]);
        if (option) {
          newFilters = { ...newFilters, [key]: option };
        }
        break;
      }
      case filterKeys.REGION: {
        const option = where.find(w => w.name === queryParameters[key]);
        if (option) {
          newFilters = { ...newFilters, [key]: option };
        }
        break;
      }
      case filterKeys.ORDER_BY:
      case filterKeys.TEXT:
        newFilters = { ...newFilters, [key]: queryParameters[key] };
        break;
    }
  });
  dispatch({
    type: ACTION_TYPES.PACKAGES_LOAD_FILTERS,
    payload: newFilters
  });
  dispatch(updateResults());
};

export const updateFilters = (key, value, queryString) => {
  const queryParameters = qs.parse(queryString);
  let newQuery = { ...queryParameters };
  if (!value) {
    delete newQuery[key];
  } else {
    switch (key) {
      case filterKeys.WHEN:
        newQuery = { ...newQuery, [key]: value.option.name };
        break;
      case filterKeys.HOW:
      case filterKeys.REGION:
        newQuery = { ...newQuery, [key]: value.name };
        break;
      case filterKeys.ORDER_BY:
        newQuery = { ...newQuery, [key]: value };
        break;
      case filterKeys.TEXT:
        newQuery = { ...newQuery, [key]: value };
        break;
      case filterKeys.DEPARTURE:
        newQuery = { ...newQuery, [key]: value };
        break;
    }
  }
  const searchQuery = qs.stringify(newQuery);

  return dispatch => {
    dispatch(push({ search: searchQuery }));
    dispatch({
      type: ACTION_TYPES.PACKAGES_UPDATE_FILTER_DATA,
      payload: {
        key,
        value
      }
    });
    return Promise.resolve();
  };
};

export const updateResults = () => (dispatch, getState) => {
  const { results, filterData } = getState().packages;
  const {
    [filterKeys.ORDER_BY]: orderBy,
    [filterKeys.REGION]: region,
    [filterKeys.CITY]: city,
    [filterKeys.WHEN]: stateWhen,
    [filterKeys.HOW]: stateHow,
    [filterKeys.TEXT]: filterByText
  } = filterData;

  let filtered = [...results];

  if (region) {
    filtered = filterByRegion(filtered, region);
  }
  if (city) {
    filtered = filterByCity(filtered, city);
  }

  if (stateWhen) {
    filtered = filterByWhen(filtered, stateWhen);
  }
  if (stateHow) {
    filtered = filterByHow(filtered, stateHow);
  }
  if (orderBy === orderByOptions.NOMBRE) {
    filtered = orderByTitle(filtered);
  }
  if (orderBy === orderByOptions.DURACION) {
    filtered = orderByDuration(filtered);
  }
  // TODO: hacer el orderBy Duracion aun api no manda esa data

  if (filterByText.length > 2) {
    filtered = filtered.filter(
      item =>
        item.title
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(
            filterByText
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) >= 0
    );
  }

  return dispatch({
    type: ACTION_TYPES.PACKAGES_UPDATE_RESULTS,
    payload: filtered
  });
};

export const onUpdateFilters = (key, value, queryString) => dispatch => {
  dispatch(updateFilters(key, value, queryString)).then(
    dispatch(updateResults())
  );
};

export const packagesRequest = id => async dispatch => {
  dispatch({
    type: ACTION_TYPES.PACKAGES_REQUEST
  });
  dispatch(updateLoader(true));

  try {
    const { data } = await API.packages.simples(id);
    dispatch(updateLoader(false));
    await dispatch(packagesRequestSuccess(data));
    dispatch(updateResults());
    return Promise.resolve();
  } catch (error) {
    dispatch(updateLoader(false));
    dispatch({
      type: ACTION_TYPES.PACKAGES_FAILURE
    });
    return Promise.resolve();
  }
};

const getPackageInfoSuccess = data => dispatch => {
  dispatch(updateLoader(false));
  dispatch({
    type: ACTION_TYPES.PACKAGES_GET_INFO_SUCCESS,
    payload: {
      ...data
    }
  });
};
const getPackageInfoFailure = data => dispatch => {
  dispatch(updateLoader(false));
  dispatch({
    type: ACTION_TYPES.PACKAGES_GET_INFO_FAILURE,
    payload: {
      ...data
    }
  });
};

export const getPackageInfo = (keyword, id) => async dispatch => {
  dispatch({
    type: ACTION_TYPES.PACKAGES_GET_INFO_REQUEST
  });
  dispatch(updateLoader(true));

  try {
    const { data } = await API.packages.getPackage(keyword, id);
    dispatch(getPackageInfoSuccess(data));
  } catch (error) {
    dispatch(getPackageInfoFailure(error.response));
    if (error.response && error.response.status === 404) {
      dispatch(push(routes.notFound));
    }
    return dispatch => {
      dispatch({
        type: ACTION_TYPES.PACKAGES_GET_INFO_FAILURE
      });
    };
  }
};

export const packageClearErrors = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.PACKAGES_CLEAR_ERRORS
  });
  dispatch(push(routes.package));
};

const getUpsellingInfoSuccess = data => dispatch => {
  dispatch({
    type: ACTION_TYPES.PACKAGES_GET_UPSELLING_INFO_SUCCESS,
    payload: data
  });
};
const getUpsellingInfoFailure = data => dispatch => {
  dispatch({
    type: ACTION_TYPES.PACKAGES_GET_UPSELLING_INFO_FAILURE,
    payload: data
  });
};

export const getUpsellingInfo = id => async dispatch => {
  dispatch({
    type: ACTION_TYPES.PACKAGES_GET_UPSELLING_INFO_REQUEST
  });

  try {
    const { data } = await API.packages.getUpsellingOfPackage(id);
    dispatch(getUpsellingInfoSuccess(data));
  } catch (error) {
    getUpsellingInfoFailure(error);
  }
};

export const resetFilters = () => dispatch => {
  dispatch(
    push({
      search: ''
    })
  );
  dispatch({
    type: ACTION_TYPES.PACKAGES_RESET_FILTER
  });
  return Promise.resolve();
};

export const onResetFilters = () => dispatch => {
  dispatch(resetFilters()).then(dispatch(updateResults()));
};

export const goHome = () => dispatch => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  dispatch(cleanSearch());
  dispatch(push(routes.home));
  dispatch({ type: ACTION_TYPES.PACKAGES_RESET_STATE });
};

export const submitForm = form => (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPES.PACKAGES_SUBMIT_FORM_REQUEST,
    payload: true
  });
  const { packageItemData } = getState().packages;

  const {
    name,
    email,
    phoneCode,
    phone,
    query,
    prefers,
    people,
    date,
    price
  } = form;
  const request = {
    name,
    email,
    numberPhone: phone,
    codePhone: phoneCode.id,
    query,
    prefers: prefers.id,
    adultCount: people.adultsCount,
    childrenCount: people.childrenQty,
    dateTravel: date,
    url: window.location.href,
    price
  };

  if (packageItemData && packageItemData.id) {
    request.productId = packageItemData.id;
  }

  if (people.kidsAges && people.kidsAges.length > 0) {
    request.childrenAges = people.kidsAges.map(c => c.id);
  }
  API.packages
    .assistances(request)
    .then(() => {
      dispatch({
        type: ACTION_TYPES.PACKAGES_SUBMIT_FORM_SUCCESS,
        payload: request.name
      });
      dispatch(push(routes.packageThankyou));
    })
    .catch(err => {
      dispatch({
        type: ACTION_TYPES.PACKAGES_SUBMIT_FORM_FAILURE,
        payload: err
      });
    });
};

export const toggleModal = () => (dispatch, getState) => {
  const showModal = getState().packages.showModal;
  dispatch({
    type: ACTION_TYPES.PACKAGES_TOGGLE_MODAL,
    payload: !showModal
  });
};

export const setActiveStep = stepId => ({
  type: ACTION_TYPES.PACKAGES_SET_ACTIVE_STEP,
  payload: stepId
});

export const setDepartureDatePrice = payload => ({
  type: ACTION_TYPES.PACKAGES_ONCHANGE_DEPARTURE_DATE_PRICE,
  payload
});

export const setDepartureDateMinWithPrice = payload => ({
  type: ACTION_TYPES.PACKAGES_SET_DEPARTURE_DATE_WITH_PRICE,
  payload
});
