/**
 *
 * FlightSearchDialog
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styled from 'styled-components';
import style from './style';
import Button from 'Components/Button';
import Tabs from 'Components/Tabs';
import Card from 'Components/Card';
import Icon from 'Components/Icon';
import MobileRoundTrip from '../MobileRoundTrip';
import MobileOneWay from '../MobileOneWay';
import RoundTrip from 'Features/flights/components/RoundTrip';
import OneWay from '../OneWay';
// import MultiDestination from '../Multidestination';
// import MobileMultiDestination from '../MobileMultiDestination';
import MobileFlightSearchCompact from '../MobileFlightSearchCompact';
import Modal from 'Components/Modal';
import SearchModalContainer from 'Components/SearchModalContainer';
import useBreakpoints from '../../../../Utils/hooks/useBreakpoints';
import { flightSearchTypes, airSearchTypes } from 'Constants';
import FlightSearchCompact from '../FlightSearchCompact';

// TODO Refactor this component, most of this style is not used,
// we should just decide wich component to render, delegate styling to
// Component itself,
const FlightSearchDialog = ({
  className,
  type,
  detailMode,
  isReducerUnused,
  actions
}) => {
  const isMediumDown = useBreakpoints().mediumDown;
  const [fixedHolder, setFixedHolder] = useState(false);

  const updateHolder = () => {
    setFixedHolder(!(window.scrollY < 260));
  };

  useEffect(() => {
    window.addEventListener('scroll', updateHolder);

    return () => {
      window.removeEventListener('scroll', updateHolder);
    };
  }, []);

  const { doSearch, toggleDetailModeOff } = actions;

  if (detailMode) {
    return isMediumDown ? (
      <MobileFlightSearchCompact />
    ) : (
      <FlightSearchCompact />
    );
  }

  if (isMediumDown) {
    return isReducerUnused ? (
      <MobileSearchContainer>
        <Tabs items={flightSearchTypes} selected={type} isMobile />
        {RenderSearch(type, true)}
        <div className="holder-mobile-search-btn">
          <Button weight="semi-bold" fullWidth onClick={doSearch}>
            <ButtonContent>
              <Icon color="white" name="mv-lense" /> BUSCAR
            </ButtonContent>
          </Button>
        </div>
      </MobileSearchContainer>
    ) : (
      <Modal show transparent onClickOverlay={toggleDetailModeOff}>
        <SearchModalContainer close={toggleDetailModeOff}>
          <MobileSearchContainer>
            <Tabs items={flightSearchTypes} selected={type} isMobile />
            <MobileSearchContainer></MobileSearchContainer>
            {RenderSearch(type, true)}
            <div className="holder-mobile-search-btn">
              <Button weight="semi-bold" fullWidth onClick={doSearch}>
                <ButtonContent>
                  <Icon color="white" name="mv-lense" /> BUSCAR
                </ButtonContent>
              </Button>
            </div>
          </MobileSearchContainer>
        </SearchModalContainer>
      </Modal>
    );
  }

  return (
    <div
      // TODO arreglar todo este relajo de clases aca
      className={cs(className, {
        'detailed-mode': detailMode && !isMediumDown
      })}>
      {!isMediumDown && (
        <div
          id="air-search-holder"
          className={cs(
            { detailMode: detailMode },
            {
              'fixed-holder':
                fixedHolder === true && !detailMode && isReducerUnused
            },
            { noDetailedInReducerUsed: !detailMode && !isReducerUnused }
          )}>
          {detailMode ? (
            <FlightSearchCompact />
          ) : (
            <DesktopSearchContainer>
              <Tabs items={flightSearchTypes} selected={type} />
              <CardBlock>{RenderSearch(type)}</CardBlock>
              <Button
                size="XL"
                iconLeft="mv-lense"
                squareBottom
                onClick={doSearch}>
                Buscar
              </Button>
            </DesktopSearchContainer>
          )}
        </div>
      )}
    </div>
  );
};

const RenderSearch = (filterType, mobile) => {
  switch (filterType) {
    case airSearchTypes.roundTrip:
      return mobile ? <MobileRoundTrip /> : <RoundTrip />;
    case airSearchTypes.oneWay:
      return mobile ? <MobileOneWay /> : <OneWay />;
    // case airSearchTypes.multi:
    //   return mobile ? (
    //     <MobileMultiDestination  />
    //   ) : (
    //     <MultiDestination  />
    //   );
    default:
  }
};

FlightSearchDialog.propTypes = {
  className: PropTypes.string.isRequired,
  detailMode: PropTypes.bool,
  isReducerUnused: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(airSearchTypes)),
  actions: PropTypes.shape({
    toggleDetailModeOff: PropTypes.func.isRequired,
    doSearch: PropTypes.func.isRequired
  })
};

export default style(FlightSearchDialog);

const DesktopSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.four};
  width: 100%;
`;

const MobileSearchContainer = styled.div`
  display: block;
  padding: 13px;
  width: 100%;

  .holder-mobile-search-btn {
    margin-top: 12px;
    display: flex;
    justify-content: center;

    .mobile-search-btn {
      width: 80%;
      margin-bottom: 25px;
    }
  }
`;

const CardBlock = styled(Card)`
  width: 100%;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    margin-right: ${({ theme }) => theme.spacing.one};
  }
`;
