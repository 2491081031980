/**
 *
 * SectionBgImages
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';

const SectionBgImages = ({ className, image }) =>
  image ? <div className={cs(className)}></div> : null;

SectionBgImages.propTypes = {
  /**
   * Custom className
   */
  className: PropTypes.string,
  /**
   * The image of the section images component.
   */
  image: PropTypes.string
};

export default styled(SectionBgImages)`
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-image: url(${props => props.image});
  transition: opacity 0.6s ease-in-out;
  background-position: center;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: ${COLORS.gradientDestinationsBG};
  }
`;
