export default url =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    script.async = true;
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      document.body.removeChild(script);
      resolve();
    });

    script.addEventListener('error', () => {
      document.body.removeChild(script);
      reject();
    });
  });
