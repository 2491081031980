/**
 *
 * RoundIcon
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Icon from '../Icon';

const RoundIcon = ({ className, icon, size }) => (
  <div className={cs(className)}>
    <Icon name={icon} size={size} color="white" />
  </div>
);

RoundIcon.propTypes = {
  className: PropTypes.string.isRequired,
  icon: PropTypes.string,
  size: PropTypes.number
};

export default styled(RoundIcon)`
  border-radius: 50%;
  background-image: linear-gradient(to right, #c97300 0%, #e79a00);
  overflow: hidden;
  color: white;
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 33px;

  &.small {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 18px;
  }

  &.large {
    width: 76px;
    height: 76px;
    line-height: 76px;
    font-size: 50px;
  }

  &.xl {
    width: 132px;
    height: 132px;
    line-height: 132px;
    font-size: 83px;
  }
`;
