import { paginationLimit } from 'Constants';
import { IQuotationParams } from './types';

export const handleParams = (payload: IQuotationParams): string => {
  let query = '/quotations?envelope=true';

  query += `&size=${payload.size || paginationLimit}`;
  query += `&page=${payload.page || 0}`;
  query += `&sort=${payload.sort || 'dateTimeCreated'}`;

  payload.dateFrom && (query += `&dateFrom=${payload.dateFrom}`);
  payload.dateTo && (query += `&dateTo=${payload.dateTo}`);
  payload.filter && (query += `&filter=${payload.filter}`);

  return query;
};
