import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../actions';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userLogout());
  }, []);

  return null;
};

export default Logout;
