// @ts-nocheck
import React from 'react';
import styled from 'styled-components';

// Types
import { ErrorProps, LoginProps, OverlayProps } from '../Login/types';

// Components
import Button from 'Components/Button';
import Well from 'Components/Well';

// Constants
import logo from 'Assets/logo_normal.svg';
import { white } from 'Constants/colors';
import { breakpoints } from 'Constants';
import spinner from '../../../app/components/Loader/assets/spinner.svg';

const Form = ({ error, pending, onSubmit, children }: LoginProps) => (
  /* @ts-ignore */
  <Container onSubmit={onSubmit}>
    <Header>
      <img className="logo" src={logo} alt="Me vuelo sign in" />
    </Header>

    <Body>{children}</Body>

    <Footer>
      {/* TODO: Add typescript to Button Component
            // @ts-ignore */}
      <Button type="submit">Ingresar</Button>
    </Footer>

    <Overlay show={pending}>
      <img alt="spinner" src={spinner} />
    </Overlay>
    {/* TODO: Add Typescript to Well Component
            // @ts-ignore */}
    <Error context="warning" show={Boolean(error)}>
      {error}
    </Error>
  </Container>
);

const Container = styled.form<HTMLFormElement>`
  background-color: ${white};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  padding: ${({ theme }) => theme.spacing.four};
  overflow: hidden;
  width: 420px;
  position: relative;

  @media screen and (max-width: ${breakpoints.medium}px) {
    margin: 0 auto;
  }
`;

const Overlay = styled.div<OverlayProps>`
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  transition: opacity 300ms ease;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  ${({ show }) =>
    show &&
    `
    opacity: 1;
    z-index: 1;
  `};
`;

const Error = styled(Well)<ErrorProps>`
  width: calc(100% + 64px);
  max-width: calc(100% + 64px);
  word-break: break-word;
  transition: margin 200ms cubic-bezier(0.85, 0.01, 0.13, 0.99);
  margin: ${({ theme }) => `
    ${theme.spacing.eight} -${theme.spacing.four} -70px -${theme.spacing.eight}
  `};

  ${({ show, theme }) =>
    show &&
    `
    margin-bottom: -${theme.spacing.four};
    margin-top: ${theme.spacing.four};
  `};
`;

const Header = styled.div`
  text-align: center;

  .logo {
    max-width: 250px;
  }
`;

const Body = styled.div`
  margin-top: ${({ theme }) => theme.spacing.four};
  margin-bottom: ${({ theme }) => theme.spacing.four};

  > div {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.four};
    }
  }
`;

const Footer = styled.div`
  text-align: right;
`;

export default Form;
