import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { contentfulClient } from 'Services';

import { useBreakpoints } from './useBreakpoints';


const StyledImg = styled.img`
  border-radius: 14px;
  cursor: pointer;
`;
const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  `;

  const query = (isTesting) =>{
    const date = new Date()
    return `
    query {  
      bannersCollection(where: {
        startDateTime_lt: "${date.toISOString()}"
        endDateTime_gte: "${date.toISOString()}"
        agencyCode:"${process.env.REACT_APP_COUNTRY}"
        environment:"${isTesting ? 'testing' : 'production'}"
      }) {
        items {
             xsmall {
               url
             },
             small {
              url
            },
            medium{
              url
            },
            large{
              url
            },
            xlarge{
              url
            },
            xxlarge{
              url
            },
          startDateTime
          agencyCode
          name
          url
          environment
        }
      }
    }`
  } 


export const PromoBanners = () => {

  const [bannerData, setBannerData] = useState(null);
  const breakpoints = useBreakpoints();

  const isTesting = (window.location.host.indexOf('testing') > -1);

  const getImageSource = (item) => {
    let imageSrc: string;
    if (breakpoints.xxlarge) {
      imageSrc = item.xxlarge.url;
    } else if (breakpoints.xlarge) {
      imageSrc = item.xlarge.url;
    } else if (breakpoints.large) {
      imageSrc = item.large.url;
    } else if (breakpoints.medium) {
      imageSrc = item.medium.url;
    } else if (breakpoints.small) {
      imageSrc = item.small.url;
    } else {
      imageSrc = item?.xsmall?.url || item.small.url;
    }

    return imageSrc
  }

  useEffect(() => {
    const getBannerData = async () => {
      const response = await contentfulClient.post('', { query: query(isTesting) });
      const data = response.data.data.bannersCollection.items;
      setBannerData(data);
    };
    getBannerData();
  }, [isTesting, setBannerData]);

  const handleClick = (url) => () => window.open(url, "_blank");

  return bannerData && bannerData.map((item, index) => (
    <BannerContainer key={index} onClick={handleClick(item.url)}>
      <StyledImg src={getImageSource(item)} alt={item.name} />
    </BannerContainer>
  ));
}
