/**
 *
 * DesktopHotelDetails
 *
 */

// Vendors
import React, { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { Element, scroller } from 'react-scroll';

// Components
import HotelHeader from '../HotelHeader';
import Card from 'Components/Card';
import MenuTab from 'Components/MenuTab';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import TextHeader from 'Components/TextHeader';
import Divider from 'Components/Divider';
import Checkbox from 'Components/Checkbox';
import Radio from 'Components/Radio';
import GMap from 'Components/GMap';
import HotelServices from '../HotelServices';
import Modal from 'Components/Modal';
import HotelDetailFareBlock from '../HotelDetailFareBlock';
import HotelDetailReservation from '../HotelDetailReservation';
import HotelsGoogleComments from '../HotelsGoogleComments';
import { ReactComponent as HotelIcon } from 'Assets/icons/mv-hotel-timeout.svg';

// Constants
import { hotelPropertyPriceTypes } from 'Constants';
import * as COLORS from 'Constants/colors';
import ErrorPortal from 'Components/ErrorPortal';

const tabValues = {
  information: true,
  rates: false,
  opinions: false,
  map: false
};

const scrollType = {
  duration: 600,
  delay: 100,
  smooth: true, // linear “easeInQuint” “easeOutCubic”
  offset: -110
};

// I'm duplicating because I'm not sure how reusable is this
const shouldShowMore = descriptions => {
  let chars = 0;

  descriptions.forEach(d => {
    if (d.paragraph && d.title) {
      chars += d.paragraph.length + d.title.length;
    }
  });

  return chars > 350;
};

const DesktopHotelDetails = ({
  className,
  ratePackagesRooms,
  name,
  images,
  starRating,
  keyRating,
  textRating,
  checkIn,
  checkOut,
  initialCenter,
  facilities,
  descriptions,
  detailRequest,
  handleClickReservation,
  address,
  reviews,
  closeError,
  checkRateError,
  accommodationCode
}) => {
  const [tabActive, setTabActive] = useState(tabValues);
  const [selectedFare, setSelectedFare] = useState(
    ratePackagesRooms &&
      ratePackagesRooms[0] &&
      ratePackagesRooms &&
      ratePackagesRooms[0].packages &&
      ratePackagesRooms[0].packages[0] &&
      ratePackagesRooms[0].packages[0]
  );
  const [breakfastIncluded, setBreakfastIncluded] = useState(false);
  const [freeCancellation, setFreeCancellation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [priceType, setPriceType] = useState(hotelPropertyPriceTypes.PER_NIGHT);

  const onActiveTab = tabName => {
    const newTabValuesInFalse = {
      ...Object.keys(tabValues).reduce(
        (reduced, key) => ({ ...reduced, [key]: false }),
        {}
      )
    };
    const newTabValues = Object.assign(
      newTabValuesInFalse,
      (newTabValuesInFalse[tabName] = true)
    );
    setTabActive(newTabValues);
    scroller.scrollTo(`${tabName}-hotel`, scrollType);
  };

  const getRooms = () => {
    if (ratePackagesRooms) {
      if (freeCancellation && breakfastIncluded) {
        return ratePackagesRooms.filter(
          room => room.hasFreeCancellationOpt && room.hasBreakfastOpt
        );
      } else if (freeCancellation) {
        return ratePackagesRooms.filter(room => room.hasFreeCancellationOpt);
      } else if (breakfastIncluded) {
        return ratePackagesRooms.filter(room => room.hasBreakfastOpt);
      }
      return ratePackagesRooms;
    }
    return null;
  };

  const rooms = getRooms();

  const limit = 4;

  const [showMoreRooms, setShowMoreRooms] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const hasMoreToShow = rooms && rooms.length > limit;
  const hasMoreDetails = useMemo(() => shouldShowMore(descriptions), [
    descriptions
  ]);

  const onShowMoreRooms = () => setShowMoreRooms(old => !old);
  const onShowMoreDetails = () => setShowMoreDetails(old => !old);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const getPackages = packages => {
    if (packages) {
      if (freeCancellation && breakfastIncluded) {
        return packages.filter(
          item => item.hasFreeCancellation && item.hasBreakfast
        );
      } else if (freeCancellation) {
        return packages.filter(item => item.hasFreeCancellation);
      } else if (breakfastIncluded) {
        return packages.filter(item => item.hasBreakfast);
      }
      return packages;
    }
  };

  const handleSelectFare = fareItem => {
    setSelectedFare(fareItem);
  };

  const handleToggleBreakfastIncluded = () => {
    setBreakfastIncluded(!breakfastIncluded);
  };

  const handleToggleFreeCancellation = () => {
    setFreeCancellation(!freeCancellation);
  };

  return (
    <>
      <ErrorPortal
        show={checkRateError}
        onClose={closeError}
        buttonLabel="Volver al Listado"
        icon={<HotelIcon />}
        message="Por favor, volvé al listado a buscar tu hotel."
        title="Lo sentimos, el hotel no nos ha devuelto disponibilidad para sus habitaciones."
      />
      <Modal
        show={showModal}
        onClickOverlay={toggleShowModal}
        modalWidth={790}
        transparent>
        <div style={{ marginTop: 50, marginBottom: 50, width: 790 }}>
          <HotelServices
            images={images}
            facilitiesGroup={facilities.facilitiesGroup}
            name={name}
            close={toggleShowModal}
          />
        </div>
      </Modal>
      <div className={cs(className)}>
        <div className="container">
          <HotelHeader
            name={name}
            image1={images[0] && images[0].imageUrl}
            image2={images[1] && images[1].imageUrl}
            image3={images[2] && images[2].imageUrl}
            checkin={checkIn ? checkIn : 'not set'}
            checkout={checkOut ? checkOut : 'not set'}
            starRating={starRating}
            keyRating={keyRating}
            textRating={textRating}
            accommodationCode={accommodationCode}
            onShowServices={toggleShowModal}
            address={address}
            facilities={facilities.facilitiesIcons}
          />
          <Card>
            <div className="anchor-holder">
              <MenuTab>
                <MenuTab.Item
                  color="information"
                  active={tabActive.information}
                  onClick={() => {
                    onActiveTab('information');
                  }}>
                  <Icon name="mv-information-circle" size={20} color="white" />
                  INFORMACIÓN
                </MenuTab.Item>
                <MenuTab.Item
                  active={tabActive.rates}
                  onClick={() => {
                    onActiveTab('rates');
                  }}>
                  <Icon name="mv-price-circle" size={20} color="white" />
                  TARIFAS Y HABITACIONES
                </MenuTab.Item>
                {reviews && (
                  <MenuTab.Item
                    color="positive"
                    active={tabActive.opinions}
                    onClick={() => {
                      onActiveTab('opinions');
                    }}>
                    <Icon name="mv-globo" size={20} color="white" />
                    OPINIONES
                  </MenuTab.Item>
                )}
                <MenuTab.Item
                  color="purple"
                  active={tabActive.map}
                  onClick={() => {
                    onActiveTab('map');
                  }}>
                  <Icon name="mv-location-1" size={20} color="white" />
                  MAPA
                </MenuTab.Item>
              </MenuTab>
            </div>

            {/* INFORMATION TAB */}
            <Element name="information-hotel">
              <div className="row">
                <div className="col-12 hotel-info">
                  <TextHeader>
                    <Icon
                      name="mv-information-circle"
                      size={20}
                      color="information"
                    />{' '}
                    Información
                  </TextHeader>

                  <Text
                    paragraph
                    className={cs('hotel-descr', {
                      'show-more': showMoreDetails
                    })}>
                    {descriptions.map((desc, index) => (
                      <Fragment key={index}>
                        <b>{desc.title}</b>
                        <br />
                        {desc.paragraph}
                        <br />
                        <br />
                      </Fragment>
                    ))}
                  </Text>

                  {hasMoreDetails && (
                    <div style={{ textAlign: 'center', padding: 12 }}>
                      <Button onClick={onShowMoreDetails} size="sm">
                        {showMoreDetails ? 'Ver menos' : 'Ver más'}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Element>

            <Divider context="light" />

            <div className="filters-holder">
              <Text context="important" weight="semi-bold">
                Mostrar s&oacute;lo habitaciones con:
              </Text>
              <div className="filter-item">
                <Checkbox
                  onClick={handleToggleBreakfastIncluded}
                  checked={breakfastIncluded}>
                  Desayuno Incluido
                </Checkbox>
              </div>
              <div className="filter-item">
                <Checkbox
                  onClick={handleToggleFreeCancellation}
                  checked={freeCancellation}>
                  Cancelaci&oacute;n Gratis
                </Checkbox>
              </div>
              <div className="filter-item">
                <Radio
                  checked={priceType === hotelPropertyPriceTypes.PER_NIGHT}
                  value={hotelPropertyPriceTypes.PER_NIGHT}
                  onClick={setPriceType}>
                  Precio por noche
                </Radio>
              </div>
              <div className="filter-item">
                <Radio
                  checked={priceType === hotelPropertyPriceTypes.PER_STAY}
                  value={hotelPropertyPriceTypes.PER_STAY}
                  onClick={setPriceType}>
                  Total por estancia
                </Radio>
              </div>
            </div>
            <div className="row d-flex align-items-stretch">
              <div className="col-9">
                {/* RATES HOTEL */}
                <Element name="rates-hotel">
                  <div className="hotel-fares-title">ELIJE UNA TARIFA</div>
                  {!rooms.length && <div>No se encontraron habitaciones.</div>}

                  {rooms.length > 0 &&
                    rooms.map((room, index) => (
                      <HotelDetailFareBlock
                        className={cs({
                          hidden: index > limit - 1 && !showMoreRooms
                        })}
                        nameRoom={room.nameRoom}
                        activeId={selectedFare.packageId}
                        key={index}
                        packages={getPackages(room.packages)}
                        priceType={priceType}
                        images={room.imagesUrl}
                        onSelectFare={handleSelectFare}
                      />
                    ))}

                  {hasMoreToShow && (
                    <Button onClick={onShowMoreRooms} context="information">
                      {showMoreRooms
                        ? 'Ver menos habitaciones'
                        : 'Ver más habitaciones'}
                    </Button>
                  )}
                </Element>

                {/* OPINIONS HOTEL */}
                {reviews && (
                  <Element name="opinions-hotel">
                    <div className="section-header">
                      <div className="text-icon">
                        <Icon name="mv-globo" size={20} color="secondary" />
                        <Text weight="semi-bold">Valoración de viajeros</Text>
                      </div>
                    </div>

                    <div className="separator" />

                    <HotelsGoogleComments
                      reviews={reviews}></HotelsGoogleComments>
                  </Element>
                )}

                {/* MAP HOTEL */}
                <Element name="map-hotel">
                  <div className="section-header">
                    <div className="text-icon">
                      <Icon name="mv-location-1" size={20} color="secondary" />
                      <Text weight="semi-bold">Mapas</Text>
                    </div>
                  </div>

                  <div className="separator" />

                  <GMap initialCenter={initialCenter} markerName={name} />
                </Element>
              </div>

              <div className="col">
                <div className="sticky">
                  <HotelDetailReservation
                    packageRoom={selectedFare}
                    request={detailRequest}
                    checkIn={checkIn}
                    checkOut={checkOut}
                    onClick={handleClickReservation}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

DesktopHotelDetails.propTypes = {
  className: PropTypes.string.isRequired,
  images: PropTypes.array,
  name: PropTypes.string,
  starRating: PropTypes.number,
  keyRating: PropTypes.number,
  textRating: PropTypes.string,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  descriptions: PropTypes.array,
  initialCenter: PropTypes.object,
  ratePackagesRooms: PropTypes.array,
  facilities: PropTypes.object,
  detailRequest: PropTypes.object,
  handleClickReservation: PropTypes.func,
  address: PropTypes.string,
  reviews: PropTypes.shape({
    amountReviews: PropTypes.number,
    rating: PropTypes.number,
    ratingText: PropTypes.string,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        author_name: PropTypes.string,
        author_url: PropTypes.string,
        id: PropTypes.string,
        language: PropTypes.string,
        profile_photo_url: PropTypes.string,
        rating: PropTypes.number,
        ratingText: PropTypes.string,
        relative_time_description: PropTypes.string,
        text: PropTypes.string,
        time: PropTypes.number
      })
    )
  }).isRequired,
  closeError: PropTypes.func,
  checkRateError: PropTypes.string,
  accommodationCode: PropTypes.string
};

export default styled(DesktopHotelDetails)`
  display: block;

  .hidden {
    display: none;
  }

  .image-item {
    width: 100%;
  }

  .anchor-holder {
    display: flex;
    justify-content: center;
    background: ${COLORS.white};
  }

  .button-icon-content {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 15px;
    }
  }

  .menu-tab {
    width: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.35);
  }

  .description-paragraph {
    margin-bottom: 20px;
  }

  .filters-holder {
    display: flex;
    margin: 10px 0;
    justify-content: center;
  }

  .filter-item {
    margin-left: 16px;
  }

  .hotel-fares-title {
    height: 25px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.golf};
    font-family: Montserrat, serif;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 25px;
    position: relative;
    letter-spacing: 0.16px;
    text-align: center;
    color: ${COLORS.important};
    width: calc(100% + 12px);
    left: -12px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    margin-bottom: 18px;
  }

  .sticky {
    position: sticky;
    top: 92px;
  }

  .btn-showmore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
  }

  .hotel-info {
    .hotel-descr {
      overflow: hidden;

      /* Change this to variate closed height */
      max-height: 200px;
      transition: all 1s ease-in-out;

      &.show-more {
        display: block;
        max-height: 8000px;
      }
    }
  }

  .section-header {
    margin: 10px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text-icon {
      display: flex;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
      }
    }
  }
`;
