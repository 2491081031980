import React from 'react';
import styled, { css } from 'styled-components';
import spinner from 'Assets/loader/spinner.svg';

interface SpinnerProps {
  block?: boolean;
  minHeight?: number;
}

const Spinner = ({ block, minHeight = 0 }: SpinnerProps) => (
  <Container block={block} minHeight={minHeight}>
    <img src={spinner} alt="loading" />
  </Container>
);

const Container = styled.div<SpinnerProps>`
  ${({ theme, block, minHeight }) => css`
    display: flex;
    width: ${block ? '100%' : 'fit-content'};
    padding: ${theme.spacing.four};
    justify-content: center;
    min-height: ${minHeight}px;
  `}
`;

export default Spinner;
