import ACTION_TYPES from './actionTypes';
import API from 'Services';
import { RootState } from 'ReduxConfig/store';
import { updateLoader } from '../app/actions';

export const getDestination = (query: { query: string }) => dispatch => {
  dispatch({ type: ACTION_TYPES.DESTINATIONS_SEARCH_REQUEST });

  API.destinations
    .getCitiesContent(query)
    .then(function(response) {
      const responseData =
        response.data.data.allCities.length > 0
          ? { data: response.data.data.allCities[0] }
          : {};
      const { data } = responseData;
      dispatch({
        type: ACTION_TYPES.DESTINATIONS_SEARCH_SUCCESS,
        payload: {
          ...data
        }
      });
      dispatch(getMiniBanners());
    })
    .catch(function(error) {
      dispatch({
        type: ACTION_TYPES.DESTINATIONS_SEARCH_FAILURE,
        payload: error
      });
    });
};

export const setActive = (status: boolean) => ({
  type: ACTION_TYPES.DESTINATIONS_SET_ACTIVE,
  payload: status
});

export const reset = () => ({ type: ACTION_TYPES.DESTINATIONS_RESET });

export const setCurrentView = (value: boolean) => ({
  type: ACTION_TYPES.DESTINATIONS_SET_CURRENT_VIEW,
  payload: value
});

const getMiniBanners = () => (dispatch, getState) => {
  dispatch({ type: ACTION_TYPES.DESTINATIONS_FLIGHTS_REQUEST });
  const { destinations }: RootState = getState();

  const destinationCode = destinations?.data?.iata;
  if (!destinationCode) {
    return dispatch({ type: ACTION_TYPES.DESTINATIONS_FLIGHTS_FAILURE });
  }

  API.flightsGraphql
    .getFlightsMiniBanners(destinationCode)
    .then(({ data }) => {
      dispatch({
        type: ACTION_TYPES.DESTINATIONS_FLIGHTS_SUCCESS,
        payload: data.data.flightPrices
      });
    })
    .catch(error =>
      dispatch({
        type: ACTION_TYPES.DESTINATIONS_FLIGHTS_FAILURE,
        payload: error
      })
    );
};

export const packagesByDestinationRequest = (
  departure,
  destination
) => async dispatch => {
  dispatch({
    type: ACTION_TYPES.PACKAGES_BY_DESTINATION_REQUEST
  });
  dispatch(updateLoader(true));

  try {
    const { data } = await API.packages.simplesByDestination(
      departure,
      destination
    );
    dispatch(updateLoader(false));
    await dispatch(packagesByDestinationRequestSuccess(data.results));
    return Promise.resolve();
  } catch (error) {
    dispatch(updateLoader(false));
    dispatch({
      type: ACTION_TYPES.PACKAGES_BY_DESTINATION_FAILURE,
      payload: error
    });
    return Promise.resolve();
  }
};

export const packagesByDestinationRequestSuccess = data => dispatch => {
  dispatch({
    type: ACTION_TYPES.PACKAGES_BY_DESTINATION_SUCCESS,
    payload: data
  });
  return Promise.resolve();
};
