/**
 *
 * CardImage
 *
 */

import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Text from '../NewText';
import { CardImageProps } from './types';
import Heading from 'Components/Heading';
import Button from 'Components/Button';

const CardImage = ({
  name,
  description,
  imageUrl,
  imageAlt,
  horizontal = false,
  upperCaseTitle = true,
  distance
}: CardImageProps) => {
  const altImage = imageAlt && imageAlt.length > 3 ? imageAlt : name;

  return (
    <Container horizontal={horizontal}>
      <ImageContainer horizontal={horizontal}>
        <Image
          src={imageUrl}
          alt={altImage}
          // @ts-ignore
          loading="lazy"
        />
      </ImageContainer>
      <Content>
        <Heading type="h4" uppercase={upperCaseTitle}>
          {name}
        </Heading>
        <Description description={description} clamp={!horizontal} />
        {distance && <Text paragraph>Distancias: {distance}</Text>}
      </Content>
    </Container>
  );
};

interface ContainerProps {
  horizontal: boolean;
}

const Container = styled.div<ContainerProps>`
  ${({ theme, horizontal }) => css`
    display: flex;
    flex: 1;
    border-radius: ${theme.borderRadius.default};
    box-shadow: ${theme.shadows.four};
    background-color: ${theme.colors.white};
    overflow: hidden;
    color: ${theme.colors.meVueloGrey};
    margin-bottom: ${theme.spacing.four};
    flex-direction: ${horizontal ? 'row' : 'column'};
    max-height: ${horizontal ? '300px' : '100%'};
    ${!horizontal &&
      css`
        max-width: 300px;
      `}
  `}
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div<ContainerProps>`
  overflow: hidden;
  flex: 0 0 auto;
  ${({ horizontal }) =>
    horizontal
      ? css`
          max-height: 100%;
          width: 200px;
        `
      : css`
          max-height: 266px;
          max-width: 100%;
        `}
`;

const Content = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.four} ${theme.spacing.two};
    overflow-y: auto;
    overflow-x: hidden;
  `}
`;

export default CardImage;

const DescriptionText = styled(Text)<Omit<DescriptionProps, 'description'>>`
  ${({ clamp }) =>
    clamp
      ? css`
          display: -webkit-box;
          -webkit-line-clamp: 10;
          -webkit-box-orient: vertical;
          overflow: hidden;
        `
      : css``}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface DescriptionProps {
  description: string;
  clamp: boolean;
}
export const Description = ({ description, clamp }: DescriptionProps) => {
  const [showMore, setShowMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const ref = useRef<HTMLParagraphElement>();

  const toggleShowMore = () => setShowMore(prev => !prev);

  useEffect(() => {
    const isBig = ref.current
      ? ref.current.scrollHeight > ref.current.offsetHeight
      : false;
    if (isBig) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, [ref.current]);

  return (
    <DescriptionContainer>
      <DescriptionText paragraph clamp={showMore ? false : clamp} ref={ref}>
        {description}
      </DescriptionText>
      {isOverflowing && (
        <ButtonContainer>
          <Button onClick={toggleShowMore}>
            {showMore ? 'Ver menos' : 'Ver más'}
          </Button>
        </ButtonContainer>
      )}
    </DescriptionContainer>
  );
};
