export default {
  GET_CATEGORIES_REQUEST: '@blog/GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS: '@blog/GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: '@blog/GET_CATEGORIES_FAILURE',
  GET_ARTICLES_REQUEST: '@blog/GET_ARTICLES_REQUEST',
  GET_ARTICLES_SUCCESS: '@blog/GET_ARTICLES_SUCCESS',
  GET_ARTICLES_FAILURE: '@blog/GET_ARTICLES_FAILURE',
  GET_ARTICLE_BY_ID_REQUEST: '@blog/GET_ARTICLE_BY_ID_REQUEST',
  GET_ARTICLE_BY_ID_SUCCESS: '@blog/GET_ARTICLE_BY_ID_SUCCESS',
  GET_ARTICLE_BY_ID_FAILURE: '@blog/GET_ARTICLE_BY_ID_FAILURE',
  GET_LATEST_ARTICLES_REQUEST: '@blog/GET_LATEST_ARTICLES_REQUEST',
  GET_LATEST_ARTICLES_SUCCESS: '@blog/GET_LATEST_ARTICLES_SUCCESS',
  GET_LATEST_ARTICLES_FAILURE: '@blog/GET_LATEST_ARTICLES_FAILURE',
  GET_SEARCH_ARTICLE_REQUEST: '@blog/GET_SEARCH_ARTICLE_REQUEST',
  GET_SEARCH_ARTICLE_SUCCESS: '@blog/GET_SEARCH_ARTICLE_SUCCESS',
  GET_SEARCH_ARTICLE_FAILURE: '@blog/GET_SEARCH_ARTICLE_FAILURE',
  CLEAN_ARTICLE: '@blog/CLEAN_ARTICLE'
};
