/**
 *
 * BlogItemCategory
 *
 */

import React from 'react';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';

import Text from 'Components/Text';
import Icon from 'Components/Icon';
import Link from 'Components/Link';

interface BlogItemCategoryProps {
  className?: string;
  name: string;
  imageUrl: string;
  imageAlt?: string;
  selected?: boolean;
  url: string;
}

const BlogItemCategory = ({
  className,
  name,
  imageUrl,
  imageAlt,
  selected,
  url
}: BlogItemCategoryProps) => (
  <div className={cs(className)}>
    <Link to={url}>
      <div className="content-image">
        <img
          className={cs({ selected: selected })}
          src={imageUrl}
          alt={imageAlt ? imageAlt : name}
        />
        {selected && (
          <CloseButton>
            <Icon name="mv-cross" color="white" />
          </CloseButton>
        )}
      </div>
      <BottomLabel>
        <Text
          size="large"
          weight="bold"
          context={selected ? 'important' : 'white'}>
          {name}
        </Text>
      </BottomLabel>
    </Link>
  </div>
);

const CloseButton = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.important};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 25px;
  height: 25px;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.one};
  top: 20px;
  right: 20px;
`;

const BottomLabel = styled.div`
  padding: 14px;
  text-align: center;
  border: 0;
  cursor: pointer;
`;

export default styled(BlogItemCategory)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 220px;

  .content-image {
    position: relative;
    z-index: 8;

    img {
      width: 220px;
      height: 220px;
      object-fit: cover;
      object-position: center;
      border-radius: 50px;
      box-shadow: ${COLORS.shadows.one};
      background-color: ${COLORS.white};
      cursor: pointer;

      &.selected {
        border: 6px solid ${COLORS.important};
        box-shadow: ${COLORS.shadows.three};
      }
    }
  }
`;
