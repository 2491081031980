// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Breakpoint } from 'react-socks';

// Actions
import { resetToEngine } from '../../ReduxConfig/availability';

// Components
import MobileThankYou from '../../Components/MobileThankYou';
import ThankYouDesktop from '../../Components/ThankYouDesktop';

// Utils
import { getPaymentSpeech } from './helpers';

const ThankYou = () => {
  const dispatch = useDispatch();
  const {
    selectedAvailability,
    userData,
    ticket,
    reserveCode,
    conditions,
    descriptionTimeLimit,
    expirationInfo
  } = useSelector(({ checkout, availability, hotels, flights }) => ({
    selectedAvailability: {
      ...checkout.selectedAvailability,
      engine: availability.engine,
      hotels,
      flights
    },
    ticket: checkout.reservationData.ticket,
    reserveCode: checkout.reservationData.reserveCode,

    userData: checkout.requestData,

    conditions: checkout.conditions,
    descriptionTimeLimit: checkout.descriptionTimeLimit,
    expirationInfo: checkout.expirationInfo
  }));

  const goToHome = engineName => () => {
    dispatch(resetToEngine(engineName));
    dispatch(push('/'));
  };

  return (
    <>
      <Breakpoint small down>
        <MobileThankYou
          selectedAvailability={selectedAvailability}
          ticket={ticket}
          reserveCode={reserveCode}
          userData={userData}
          conditions={conditions}
          expirationInfo={expirationInfo}
          descriptionTimeLimit={descriptionTimeLimit}
        />
      </Breakpoint>
      <Breakpoint medium up>
        <ThankYouDesktop
          getPaymentSpeech={getPaymentSpeech}
          goToHome={goToHome}
          selectedAvailability={selectedAvailability}
          ticket={ticket}
          reserveCode={reserveCode}
          userData={userData}
          conditions={conditions}
          expirationInfo={expirationInfo}
          descriptionTimeLimit={descriptionTimeLimit}
        />
      </Breakpoint>
    </>
  );
};

export default ThankYou;
