/**
 *
 * AirCheckError
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import background from '../../../../Assets/images/AirAvailabilityError.webp';
import Text from 'Components/Text';
import Button from 'Components/Button';
import Icon from 'Components/Icon';

const AirCheckError = ({ className, close, mobile, desktop }) => (
  <div className={cs(className, { mobile }, { desktop })}>
    <div onClick={close} className="close">
      <Icon name="mv-cross" size={24} />
    </div>
    <Text className="margin" context="white" size="xlarge">
      <b>!Ups!</b> Lamentablemente la aerolínea no nos esta confirmando
      disponibilidad en el vuelo que elegiste <br />
      Prueba con otra de las opciones disponibles!
    </Text>
    <Button className="margin" onClick={close}>
      Buscar
    </Button>
  </div>
);

AirCheckError.propTypes = {
  className: PropTypes.string.isRequired,
  close: PropTypes.func,
  mobile: PropTypes.bool,
  desktop: PropTypes.bool
};

export default styled(AirCheckError)`
  &.desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${background});
    background-position: center;
    height: 300px;
    padding: 15px;
    text-align: center;
    position: relative;
  }

  &.mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${background});
    background-size: cover;
    background-position: center;
    text-align: center;
    position: relative;
    height: 100%;
  }

  .margin {
    margin-top: 20px;
  }

  .close {
    position: absolute;
    color: ${COLORS.white};
    top: 5px;
    right: 5px;
  }
`;
