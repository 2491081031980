/**
 *
 * TextArea
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styled from 'styled-components';
import {
  important,
  darkGrey,
  white,
  warning,
  positive,
  information
} from 'Constants/colors';
import Icon from '../Icon';
import { isMobile } from '../../Utils';

const TextArea = ({
  className,
  value,
  onChange,
  label,
  message,
  icon,
  placeholder,
  context,
  rows = 5,
  isRequired = false,
  hasResize = false,
  onBlur
}) => (
  <div className={cs(className)}>
    {label && (
      <label>
        {isRequired && <b style={{ color: important }}>*</b>}
        {label}
      </label>
    )}

    <div className={cs('mv-textarea-container', context)}>
      <div className="mv-textarea-holder">
        {icon && <Icon name={icon} />}
        <textarea
          className={!hasResize && 'has-resize'}
          value={value}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          rows={rows}
          placeholder={placeholder}
        />
      </div>
      {message && <div className="mv-textarea-message">{message}</div>}
    </div>
  </div>
);

TextArea.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  context: PropTypes.string,
  onChange: PropTypes.func,
  icon: PropTypes.string,
  hasResize: PropTypes.bool,
  rows: PropTypes.number,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  onBlur: PropTypes.func
};

export default styled(TextArea)`
  label {
    font-family: Montserrat, serif;
    color: ${darkGrey};
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.96;
    letter-spacing: 0.1px;
    text-align: left;
    text-transform: uppercase;
  }

  .mv-textarea-container {
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);

    &.important {
      border-color: ${important};
      background-color: ${important};

      textarea {
        color: ${important};
      }
    }

    &.information {
      border-color: ${information};
      background-color: ${information};

      textarea {
        color: ${information};
      }
    }

    &.positive {
      border-color: ${positive};
      background-color: ${positive};

      textarea {
        color: ${positive};
      }
    }

    &.warning {
      border-color: ${warning};
      background-color: ${warning};

      textarea {
        color: ${warning};
      }
    }

    .mv-textarea-holder {
      display: flex;
      border-radius: 14px;
      background: ${white};
      overflow: hidden;
      padding: 10px 10px 0 10px;
      align-items: center;

      .icon {
        color: ${important};
        font-size: 20px;
      }

      textarea {
        border: 0;
        width: 100%;
        font-size: ${isMobile.any() ? '18px' : '14px'};
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.96;
        letter-spacing: 0.1px;
        text-align: left;
        color: ${darkGrey};
        overflow: auto;
        outline: none;

        &.has-resize {
          resize: none;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .mv-textarea-message {
      padding: 0 10px;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.1px;
      text-align: left;
      color: ${white};
      font-weight: 500;
    }
  }
`;
