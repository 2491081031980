/**
 *
 * InputErrorMessage
 *
 */

import React from 'react';
import styled from 'styled-components';
import { ThemeColor } from 'Theme/types';

export interface InputErrorMessageProps {
  message?: string;
  color?: ThemeColor;
  translateYValue?: string;
  topPosition?: string;
}

const InputErrorMessage = ({
  message,
  color,
  translateYValue,
  topPosition
}: InputErrorMessageProps) => (
  <ErrorMessage
    show={!!message}
    color={color}
    translateYValue={translateYValue}
    topPosition={topPosition}>
    <span>{message}</span>
  </ErrorMessage>
);

interface IErrorMessageProps {
  color?: ThemeColor;
  show?: boolean;
  translateYValue?: string;
  topPosition?: string;
}

const ErrorMessage = styled.div<IErrorMessageProps>`
  position: absolute;
  width: 100%;
  top: ${({ topPosition }) => topPosition || '0'};
  left: 0;
  right: 0;
  min-height: 33px;
  z-index: 1;
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.important};
  box-shadow: ${({ theme }) => theme.shadows.one};
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 600;
  opacity: 0;
  transition: all 750ms ease-in-out;
  ${({ show, translateYValue }) =>
    show &&
    `
    transform: translateY(${translateYValue || '50%'});
  opacity: 1;
  `};

  span {
    margin-top: 8px;
    font-size: 12px;
  }
`;

export default InputErrorMessage;
