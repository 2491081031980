import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { engines } from 'Constants';

const Backgrounds = ({ engineActive, isReducerUnused }) => (
  <>
    {Object.values(engines).map(engine => (
      <BackgroundLayer
        key={engine.name}
        background={engine.background}
        isActive={engine.name === engineActive && isReducerUnused}
      />
    ))}
  </>
);

Backgrounds.propTypes = {
  engineActive: PropTypes.string.isRequired,
  isReducerUnused: PropTypes.bool.isRequired
};

const BackgroundLayer = styled.div`
  background-size: cover;
  background-attachment: fixed
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 54vh;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  background-image: url(${({ background }) => background});
  transition: opacity 0.6s ease-in-out;
  z-index: -1;
  background-position: center;
`;

export default memo(Backgrounds);
