/**
 *
 * PassengersModal
 *
 */

// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from 'Components/Modal';
import Input from 'Components/Input';
import Select from 'Components/Select';
import Text from 'Components/Text';

// Constants
import { Fields, Submit } from '../Passengers/styles';

// Utils
import { flightPassengerDocumentsTypes } from 'Features/checkout/constants';

const PassengersModal = ({
  show,
  fields,
  errors,
  onChange,
  onClose,
  onValidated,
  isDisabled,
  citizens,
  days,
  months,
  years,
  genders
}) => (
  <Modal
    headerCentered
    show={show}
    header="INFORMACIÓN DEL PASAJERO"
    closeButton={{
      onClick: () => onClose(false)
    }}>
    <div>
      <Fields>
        <Input
          placeholder="Jhon Albert"
          label="Nombres"
          message={errors.firstNames}
          context={errors.firstNames && 'warning'}
          value={fields.firstNames}
          className="grid-4"
          onChange={onChange('firstNames')}
        />

        <Input
          placeholder="Doe Spilberg"
          label="Apellidos"
          message={errors.lastNames}
          context={errors.lastNames && 'warning'}
          className="grid-4"
          value={fields.lastNames}
          onChange={onChange('lastNames')}
        />

        <Select
          label="Nacionalidad"
          className="grid-2"
          selectedValue={fields.country && fields.country.id}
          onSelect={onChange('country')}
          message={errors.country}
          context={errors.country && 'warning'}
          items={citizens}
        />

        <Select
          label="Género"
          className="grid-2"
          onSelect={onChange('gender')}
          selectedValue={fields.gender && fields.gender.id}
          message={errors.gender}
          context={errors.gender && 'warning'}
          items={genders}
        />

        <Text className="grid-4" weight="semi-bold">
          TIPO DE DOCUMENTO
        </Text>

        <Select
          className="grid-2"
          selectedValue={fields.documentType && fields.documentType.id}
          onSelect={onChange('documentType')}
          message={errors.documentType}
          context={errors.documentType && 'warning'}
          items={flightPassengerDocumentsTypes}
        />

        <Input
          placeholder="Ej: 123456678"
          className="grid-2"
          value={fields.document}
          message={errors.document}
          context={errors.document && 'warning'}
          onChange={onChange('document')}
        />

        <Text className="grid-4" weight="semi-bold">
          FECHA DE NACIMIENTO
        </Text>

        <Select
          placeholder="Año"
          className="grid-2"
          onSelect={onChange('year')}
          message={errors.year}
          context={errors.year && 'warning'}
          selectedValue={fields.year && fields.year.id}
          items={years}
        />

        <Select
          placeholder="Mes"
          onSelect={onChange('month')}
          message={errors.month}
          context={errors.month && 'warning'}
          selectedValue={fields.month && fields.month.id}
          items={months}
        />

        <Select
          placeholder="Día"
          onSelect={onChange('day')}
          message={errors.day}
          context={errors.day && 'warning'}
          selectedValue={fields.day && fields.day.id}
          items={days}
        />
      </Fields>

      <Submit className="submit" disabled={isDisabled} onClick={onValidated}>
        CONTINUAR
      </Submit>

      <Text context="warning">
        “Los datos ingresados deben coincidir con el documento de viaje, de lo
        contrario algunas aerolíneas podrían impedir el embarque”
      </Text>
    </div>
  </Modal>
);

PassengersModal.propTypes = {
  show: PropTypes.bool,
  isDisabled: PropTypes.bool,
  fields: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onValidated: PropTypes.func,
  citizens: PropTypes.array,
  days: PropTypes.array,
  months: PropTypes.array,
  years: PropTypes.array,
  genders: PropTypes.array
};

export default PassengersModal;
