/**
 *
 * TipsDestination
 *
 */

import React from 'react';
import styled from 'styled-components';
import '@brainhubeu/react-carousel/lib/style.css';
import Carousel from '@brainhubeu/react-carousel';
import Divider from 'Components/Divider';
import Icon from 'Components/Icon';
import { ITips } from 'Features/destinations/types';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { returnCarrouselItems } from '../../helpers';
import { Description } from 'Components/CardImage';
import Text from 'Components/Text';

interface TipsDestinationsProps {
  tips: ITips[];
  name: string;
}

const IconHolder = styled.div`
  margin-right: ${props => props.theme.spacing.two};
`;

const ArrowIconHolder = styled.div`
  width: 40px;
  height: 40px;
`;

const TipBox = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.default};
  margin: ${props => props.theme.spacing.two};
  padding: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const HeaderHolder = styled.div`
  padding-bottom: ${props => props.theme.spacing.two};
  display: flex;
`;

const TipsDestination = ({ tips, name }: TipsDestinationsProps) => {
  const breakpoints = useBreakpoints();
  return (
    <>
      <Divider titleText={`MeVuelo Tips para tu viaje a ${name}`} />
      <Carousel
        slidesPerPage={returnCarrouselItems(breakpoints)}
        arrows
        infinite
        arrowLeft={
          <ArrowIconHolder>
            <Icon name="mv-arrow-left" color="white" size={20} />
          </ArrowIconHolder>
        }
        arrowRight={
          <ArrowIconHolder>
            <Icon name="mv-arrow-right" color="white" size={20} />
          </ArrowIconHolder>
        }
        addArrowClickHandler>
        {tips?.map((tip, index) => (
          <TipBox key={index}>
            <HeaderHolder>
              <IconHolder>
                <Icon name="mv-globo-circle" color="important" size={20} />
              </IconHolder>
              <Text> Tip </Text>
            </HeaderHolder>
            <Description description={tip.tip} clamp />
          </TipBox>
        ))}
      </Carousel>
    </>
  );
};

export default TipsDestination;
