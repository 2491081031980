/**
 *
 * MobileHotelFilters
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from 'Components/Text';
import Modal from 'Components/Modal';
import MobileNavBar from 'Components/MobileNavBar';
import Button from 'Components/Button';
import Slider from 'Components/Slider';
import Icon from 'Components/Icon';
import MobileFilterHolder from 'Components/MobileFilterHolder';
import StarsFilter from 'Components/StarsFilter';
import Input from 'Components/Input';
import Checkbox from 'Components/Checkbox';
import RatingFilterOption from 'Components/RatingFilterOption';

const MobileHotelFilters = ({
  className,
  show = true,
  close,
  currency,
  ratingFilter,
  handleSelectStartFilter,
  searchNameValue,
  handleSearchHotelNameChange,
  sliderMin,
  sliderMax,
  fareRange,
  handleFareFilterChange,
  facilities,
  handleFacilityFilterClick,
  selectedFilters,
  accommodations,
  handleCategoryFilterClick,
  selectedCategories,
  handleReviewFilterClick,
  reviewFilter,
  ratingOptions
}) => (
  <Modal className={cs(className)} show={show} whiteBackground transparent>
    <div>
      <div className="topBar">
        <Text weight="italic" size="xlarge">
          Filtros
        </Text>
        <div onClick={close} className="close">
          <Icon name="mv-cross" className="close" />
        </div>
      </div>
      <MobileFilterHolder title="Categoría" active>
        <div className="align-center f-content">
          <StarsFilter
            initialValue={ratingFilter}
            onSelect={handleSelectStartFilter}
          />
        </div>
      </MobileFilterHolder>
      {ratingOptions && (
        <MobileFilterHolder title="Valoración" active>
          <div className="rating-holder f-content">
            {ratingOptions.map(option => (
              <RatingFilterOption
                key={`option-${option}`}
                value={option}
                active={reviewFilter === option}
                onClick={handleReviewFilterClick}
              />
            ))}
          </div>
        </MobileFilterHolder>
      )}
      <MobileFilterHolder title="Precio" active>
        <div className="f-content">
          <Slider
            min={sliderMin()}
            max={sliderMax()}
            currency={currency}
            showToolTip
            onChange={handleFareFilterChange}
            defaultValue={fareRange}
          />
        </div>
      </MobileFilterHolder>
      <MobileFilterHolder title="Nombre del hotel" active>
        <div className="f-content">
          <Input
            icon="mv-lense"
            placeholder="Buscar..."
            value={searchNameValue}
            onChange={handleSearchHotelNameChange}
          />
        </div>
      </MobileFilterHolder>
      {facilities && (
        <MobileFilterHolder title="Servicios" active>
          <div className="f-content">
            {facilities.map(facility => (
              <Checkbox
                key={facility.id}
                checked={selectedFilters.includes(facility.id)}
                value={facility.id}
                onClick={handleFacilityFilterClick}>
                {facility.name}
              </Checkbox>
            ))}
          </div>
        </MobileFilterHolder>
      )}
      {accommodations && (
        <MobileFilterHolder title="Alojamiento" active>
          <div className="f-content">
            {accommodations.map(category => (
              <Checkbox
                key={category.id}
                checked={selectedCategories.includes(category.id)}
                value={category.id}
                onClick={handleCategoryFilterClick}>
                {category.name}
              </Checkbox>
            ))}
          </div>
        </MobileFilterHolder>
      )}
      <div className="filler" />
      <MobileNavBar>
        <div className="bottom-navbar">
          <Button iconLeft left onClick={close} context="purple">
            <Icon name="mv-arrow-left" color="white" size={24} />
          </Button>
          <Button right context="purple" onClick={close}>
            Aplicar
          </Button>
        </div>
      </MobileNavBar>
    </div>
  </Modal>
);

MobileHotelFilters.propTypes = {
  className: PropTypes.string.isRequired,
  ratingFilter: PropTypes.number,
  handleSelectStartFilter: PropTypes.func,
  searchNameValue: PropTypes.string,
  handleSearchHotelNameChange: PropTypes.func,
  sliderMin: PropTypes.func,
  sliderMax: PropTypes.func,
  fareRange: PropTypes.array,
  handleFareFilterChange: PropTypes.func,
  currency: PropTypes.string,
  show: PropTypes.bool,
  close: PropTypes.func,
  handleFacilityFilterClick: PropTypes.func,
  facilities: PropTypes.array,
  selectedFilters: PropTypes.array,
  accommodations: PropTypes.array,
  handleCategoryFilterClick: PropTypes.func,
  selectedCategories: PropTypes.array,
  reviewFilter: PropTypes.number,
  handleReviewFilterClick: PropTypes.func,
  ratingOptions: PropTypes.array
};

export default styled(MobileHotelFilters)`
  & > div {
    width: 100%;
  }

  .close {
    cursor: pointer;
    float: right;
    align-self: center;
  }

  .topBar {
    width: 100%;
    height: 62px;
    background-color: ${COLORS.white};
    padding-left: 13px;
    padding-right: 13px;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0.5px 0.5px 1.5px 0 rgba(0, 0, 0, 0.25);
  }

  .rating-holder {
    display: flex;
    justify-content: space-between;
  }

  .align-center {
    display: flex;
    justify-content: center;
  }

  .f-content {
    margin-left: 30px;
    margin-right: 30px;
  }

  .bottom-navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .filler {
    height: 65px;
  }
`;
