/**
 *
 * Banks
 *
 */

import React, { useEffect, useRef } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as COLORS from 'Constants/colors';

import Radio from '../Radio';

// Utils
import { scrollIntoView } from 'Utils';

const isOnlySameBank = (statement, bank) => {
  if (statement) {
    return `(Solo desde cuentas ${bank.name})`;
  }

  return '(Para cualquier tipo de cuenta)';
};

const formatAccountType = (type, coin) => {
  let message;

  switch (type.name.toLowerCase()) {
    case 'corriente':
      message = `Cuenta corriente en ${coin.code}`;
      break;

    case 'ahorros':
      message = `Caja de ahorros en ${coin.code}`;
      break;
    default:
      return null;
  }

  return message;
};

// TODO: ASK ABOUT THE BANKS ARRAY INSTEAD OF AN OBJECT
const Banks = ({ onSubmit, onValid, selectedBank, banks }) => {
  const container = useRef(null);

  const onClickBank = bank => () => {
    onSubmit(bank);
  };

  useEffect(() => {
    onValid(selectedBank.id);
  }, [selectedBank]);

  useEffect(() => {
    scrollIntoView(container);
  }, []);

  return (
    <Wrapper ref={container}>
      <List>
        {banks &&
          banks.length > 0 &&
          banks.map(b => {
            const checked = selectedBank && selectedBank.id === b.id;

            return (
              <div className={cs('bank', { active: checked })} key={b.id}>
                <Radio checked={checked} onClick={onClickBank(b)}>
                  <img src={b.bank.image} alt={b.bank.name} />

                  <div className="body">
                    <h6 className="title">{b.bank.name}</h6>

                    <div className="data">
                      <span>
                        Numero de cuenta: <b>{b.accountNumber}</b>
                      </span>
                      <span>
                        Nombre: <b>{b.accountHolder}</b>
                      </span>
                      <span>{formatAccountType(b.accountType, b.coin)}</span>
                    </div>

                    <span className="help-text">
                      {isOnlySameBank(b.onlySameBank, b.bank)}
                    </span>
                  </div>
                </Radio>
              </div>
            );
          })}
      </List>
    </Wrapper>
  );
};

Banks.propTypes = {
  onSubmit: PropTypes.func,
  onValid: PropTypes.func,
  banks: PropTypes.array,
  selectedBank: PropTypes.object
};

const Wrapper = styled.div`
  padding: 30px 30px 20px 30px;

  .next {
    display: block;
    margin: 15px auto 0 auto;
  }

  @media screen and (max-width: 425px) {
    padding: 5px;
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 5px;
    grid-gap: 10px;
  }

  .bank {
    label {
      display: flex;
      align-items: center;

      img {
        max-width: 100px;
        max-height: auto;
      }

      .body {
        flex-direction: column;
        display: flex;
        margin-left: 5px;

        .title {
          font-size: 16.5px;
          font-weight: 500;
          line-height: 1.14;
          letter-spacing: 0.21px;
          color: ${COLORS.midnight};
        }

        .data {
          font-size: 14px;
          line-height: 1.2;
          letter-spacing: 0.18px;
          margin-bottom: 5px;

          span {
            display: block;
          }
        }

        .help-text {
          font-size: 14px;
          font-weight: 300;
          font-style: italic;
          letter-spacing: 0.14px;
          color: ${COLORS.information};
        }
      }

      @media screen and (max-width: 425px) {
        margin: 0;
        padding: 8px;

        img {
          display: inline-block;
          margin-right: calc(135px - 120px);
        }
      }
    }

    @media screen and (max-width: 425px) {
      border-radius: 14px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
      border: solid 1px ${COLORS.important};
      background-color: ${COLORS.white};

      &.active {
        border: solid 3px ${COLORS.important};
      }

      .radio-circle {
        display: none;
      }
    }
  }
`;

export default Banks;
