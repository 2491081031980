/**
 *
 * PaxDetailHotelRoom
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import PlusMinusSelector from 'Components/PlusMinusSelector';
import Select from 'Components/Select';
import { edadesOpciones } from 'Constants/mocks';
import SectionTitle from 'Components/SectionTitle';
import { types } from 'Features/hotels';

const PaxDetailHotelRoom = ({
  className,
  onChangeAdults,
  onChangeChildren,
  handleChangeChildrenAge,
  room,
  index
}) => (
  <div className={cs(className)}>
    <SectionTitle title={`Habitación ${index + 1} - Huéspedes`} />
    <div className="option">
      <div className="column">
        <Text>Adultos</Text>
        <Text context="important">Desde los 18 a&ntilde;os</Text>
      </div>
      <div className="column">
        <PlusMinusSelector
          stateless
          onIncrement={onChangeAdults(index, types.INCREMENT)}
          onDecrement={onChangeAdults(index, types.DECREMENT)}
          value={room.adultsCount}
        />
      </div>
    </div>
    <div className="option">
      <div className="column">
        <Text>Menores</Text>
        <Text context="important">Hasta los 17 a&ntilde;os</Text>
      </div>
      <div className="column">
        <PlusMinusSelector
          stateless
          onIncrement={onChangeChildren(index, types.INCREMENT)}
          onDecrement={onChangeChildren(index, types.DECREMENT)}
          value={room.kidsAges && room.kidsAges.length}
        />
      </div>
    </div>
    {room.kidsAges &&
      room.kidsAges.length > 0 &&
      room.kidsAges.map((child, i) => (
        <div className="option" key={i}>
          <div className="column">
            <Text>Edad del menor {i + 1}</Text>
            <Text context="important">Al finalizar el viaje</Text>
          </div>
          <div className="column">
            <Select
              items={edadesOpciones}
              selectedValue={child}
              onSelect={handleChangeChildrenAge(index, i)}
            />
            {!child && child !== 0 && <Text context="warning">Requerido</Text>}
            {child <= 2 && <Text context="important">Tarifa de bebe</Text>}
            {child > 2 && <Text context="important">Tarifa de niño</Text>}
          </div>
        </div>
      ))}
  </div>
);

PaxDetailHotelRoom.propTypes = {
  className: PropTypes.string.isRequired,
  room: PropTypes.object,
  onChangeAdults: PropTypes.func,
  onChangeChildren: PropTypes.func,
  handleChangeChildrenAge: PropTypes.func,
  index: PropTypes.number
};

export default styled(PaxDetailHotelRoom)`
  display: block;
  width: 100%;
`;
