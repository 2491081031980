/**
 *
 * AirAvailabilityMobile
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import MenuTab from 'Components/MenuTab';
import Button from 'Components/Button';
import MobileAirFilter from '../MobileAirFilter';
import MobileNavBar from 'Components/MobileNavBar';
import MobileAirAvailabilityCard from '../MobileAirAvailabilityCard';
import { filterListTypes } from 'Constants';
import Modal from 'Components/Modal';
import MobileFlightPriceDetails from '../MobileFlightPriceDetails';
import MobileFlightItineraryDetails from '../MobileFlightItineraryDetails';
import AirCheckError from '../AirCheckError';
import Icon from 'Components/Icon';
import CardAlert from 'Components/CardAlert';
import Text from 'Components/Text';
import Portal from 'Components/Portal';
import { ExpertCard } from 'Features/flights/components/ExpertCard';
import { HygieneProtocolsCard } from 'Features/flights/components/HygieneProtocols';

const AirAvailabilityMobile = ({
  className,
  parseResponse,
  handleClickListType,
  filters,
  fareSliderDetails,
  handleClickLuggageFilter,
  filtersData,
  handleClickScalesFilter,
  handleAirlineFilterClick,
  handleServiceClassFilterClick,
  handleAllianceFilterClick,
  handleFareFilterChange,
  handleNoAirportChange,
  handleFlexFilterChange,
  onSelectAvailability,
  showErrorModal,
  toggleErrorModal,
  request,
  orderPriceInfo
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [openFlightModal, setOpenFlightModal] = useState(false);
  const [flightPrice, setFlightPrice] = useState();
  const [flightInfo, setFlightInfo] = useState();
  const [direction, setDirection] = useState(0);

  const closePrice = () => {
    setOpenPriceModal(false);
    setFlightPrice();
  };
  const showPrice = flightPrice => {
    setFlightPrice(flightPrice);
    setOpenPriceModal(true);
  };

  const closeFlight = () => {
    setOpenFlightModal(false);
    setFlightInfo();
  };

  const showFlight = (flightInfo, dir) => {
    setFlightInfo(flightInfo);
    setDirection(dir);
    setOpenFlightModal(true);
  };

  const toggleFilters = () => setShowFilters(!showFilters);
  return (
    <div className={cs(className)}>
      <Modal
        show={showErrorModal}
        onClickOverlay={toggleErrorModal}
        transparent>
        <AirCheckError close={toggleErrorModal} mobile />
      </Modal>
      {parseResponse && (
        <>
          {showFilters && (
            <Portal>
              <MobileAirFilter
                close={toggleFilters}
                fareSliderDetails={fareSliderDetails}
                filters={filters}
                filtersData={filtersData}
                handleClickLuggageFilter={handleClickLuggageFilter}
                handleClickScalesFilter={handleClickScalesFilter}
                handleFareFilterChange={handleFareFilterChange}
                handleAirlineFilter={handleAirlineFilterClick}
                handleAllianceFilter={handleAllianceFilterClick}
                handleClassFilter={handleServiceClassFilterClick}
                handleNoAirportChange={handleNoAirportChange}
                handleFlexFilterChange={handleFlexFilterChange}
              />
            </Portal>
          )}

          <Modal
            show={openPriceModal}
            onClickOverlay={closePrice}
            transparent
            whiteBackground>
            {flightPrice ? (
              <MobileFlightPriceDetails
                price={flightPrice}
                closeModal={closePrice}
              />
            ) : (
              <div></div>
            )}
          </Modal>
          <Modal
            show={openFlightModal}
            onClickOverlay={closeFlight}
            transparent
            whiteBackground>
            <div>
              {flightInfo && (
                <MobileFlightItineraryDetails
                  segments={flightInfo.segments}
                  duration={flightInfo.duration}
                  scales={flightInfo.scales}
                  baggage={flightInfo.baggage}
                  closeModal={closeFlight}
                  direction={direction}
                />
              )}
            </div>
          </Modal>
          <div className="menu-tab-holder">
            <MenuTab>
              <MenuTab.Item
                active={filters.resultListType === filterListTypes.convenientes}
                onClick={handleClickListType(filterListTypes.convenientes)}>
                +CONVENIENTES <br></br>
                {orderPriceInfo.moreConvenient.currency}{' '}
                {orderPriceInfo.moreConvenient.amount}
              </MenuTab.Item>

              <MenuTab.Item
                active={filters.resultListType === filterListTypes.economico}
                onClick={handleClickListType(filterListTypes.economico)}>
                +ECONÓMICOS <br></br>
                {orderPriceInfo.cheaper.currency}{' '}
                {orderPriceInfo.cheaper.amount}
              </MenuTab.Item>

              <MenuTab.Item
                active={filters.resultListType === filterListTypes.rapidos}
                onClick={handleClickListType(filterListTypes.rapidos)}>
                +RÁPIDOS <br></br>
                {orderPriceInfo.faster.currency} {orderPriceInfo.faster.amount}
              </MenuTab.Item>
            </MenuTab>
          </div>

          {parseResponse &&
            parseResponse.length > 0 &&
            request.routesDataChanged && (
            <CardAlert
              nameIcon="mv-circuit"
              color="information"
              title="Tu búsqueda no coincide con los vuelos disponibles.">
              <Text paragraph>
                  Te ofrecemos{' '}
                <b>vuelos a {request.routesData[0].destinationName}</b>, la
                  opción más conveniente para viajar desde{' '}
                {request.routesData[0].originName}.
              </Text>
            </CardAlert>
          )}
          {parseResponse && parseResponse.length === 0 && (
            <CardAlert
              color="important"
              nameIcon="mv-information-circle"
              title={`¡Ups! no hemos encontrado vuelos a ${request.routesData[0].originName}`}>
              <Text paragraph>
                Puede ser que no haya lugar en los vuelos o que no haya vuelos
                en esos días.
                <br />
                Por favor, intenta modificar la búsqueda.
              </Text>
            </CardAlert>
          )}

          {Array.isArray(parseResponse) &&
            parseResponse.map((item, i) => (
              <div key={item.id} className="card-container">
                <MobileAirAvailabilityCard
                  generalSegment={item.generalSegments}
                  flightPrice={item.flightPrice}
                  localPrice={item.localPrice}
                  onSelect={onSelectAvailability(item)}
                  showPrice={() => showPrice(item.flightPrice)}
                  showFlight={showFlight}
                  id={item.id}
                  hasFlex={item.hasFlex}
                />
                {i === 0 && <HygieneProtocolsCard />}
                {i === 2 && <ExpertCard />}
              </div>
            ))}
          <MobileNavBar>
            <Button right context="purple" onClick={toggleFilters} iconLeft>
              <Icon name="mv-selection" color="white" size={20} />
              Filtros
            </Button>
          </MobileNavBar>
        </>
      )}
    </div>
  );
};

AirAvailabilityMobile.propTypes = {
  className: PropTypes.string.isRequired,
  handleClickListType: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  fareSliderDetails: PropTypes.object.isRequired,
  handleClickLuggageFilter: PropTypes.func.isRequired,
  filtersData: PropTypes.object.isRequired,
  handleClickScalesFilter: PropTypes.func.isRequired,
  handleAirlineFilterClick: PropTypes.func.isRequired,
  handleAllianceFilterClick: PropTypes.func.isRequired,
  handleFareFilterChange: PropTypes.func.isRequired,
  returnCabinCodeName: PropTypes.func,
  handleServiceClassFilterClick: PropTypes.func.isRequired,
  onSelectAvailability: PropTypes.func.isRequired,
  handleFlexFilterChange: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  initialDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  isChecking: PropTypes.bool,
  handleNoAirportChange: PropTypes.func.isRequired,
  parseResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  showErrorModal: PropTypes.bool,
  toggleErrorModal: PropTypes.func,
  orderPriceInfo: PropTypes.object
};

export default styled(AirAvailabilityMobile)`
  display: block;

  .menu-tab-holder {
    margin-top: ${({ theme }) => theme.spacing.two};
  }

  .card-container {
    margin-left: 14px;
    margin-right: 14px;
    margin-bottom: 5px;
  }
`;
