import styled from 'styled-components';

// Components
import MenuTab from 'Components/MenuTab';

export const Container = styled.div``;

export const GraphContainer = styled.div`
  padding: 16px;
`;

export const Wrapper = styled.div`
  padding: 0;
  margin: 44px 0 15px 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  > div {
    max-width: 100%;
  }

  .mv-card-content {
    padding: 0;
  }
`;

export const Description = styled.div`
  width: 1000px;
  top: 0;
  position: relative;
  left: 0;
  margin: 0 auto;
  padding-top: 0.4%;
  padding-bottom: ${({ theme }) => theme.spacing.two};
  max-width: 100%;
`;

// TODO: Styled is not working on MenuTab components for some reason...
export const Menu = styled(MenuTab)``;
