import React from 'react';
import styled from 'styled-components';
import Heading from 'Components/Heading';
import Button from 'Components/Button';
import { IQuotationService } from 'Services/Quotations/types';
import QuotationsService from '../QuotationsService';
import { ReactComponent as PlusSign } from 'Assets/icons/plus-sign.svg';

interface QuotationServicesProps {
  list: IQuotationService[];
  addingNew: boolean;
  onAddNew: () => void;
  onCancelNew: () => void;
  id: number | undefined;
}

const QuotationServices = ({
  list = [],
  addingNew,
  onAddNew,
  onCancelNew,
  id
}: QuotationServicesProps) => (
  <Container>
    <TopBar>
      <Heading type="h3" color="orange">
        Servicios
      </Heading>
      <Button variation="violet" onClick={onAddNew}>
        <PlusIcon /> Servicio
      </Button>
    </TopBar>
    <List>
      {addingNew && (
        <QuotationsService
          number="Nuevo"
          quotationId={id}
          onCancelNew={onCancelNew}
        />
      )}
      {list.map((service, index) => (
        <QuotationsService
          key={service.id}
          number={index}
          service={service}
          quotationId={id}
          onCancelNew={onCancelNew}
        />
      ))}
    </List>
  </Container>
);

const Container = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.four};
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.four};
`;

const List = styled.div`
  & > div:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
`;

const PlusIcon = styled(PlusSign)`
  margin-right: ${({ theme }) => theme.spacing.two};
`;

export default QuotationServices;
