import ACTION_TYPES from './actionTypes';
import { types } from '../hotels/constants';
import { limits, emptyRoute, emptyRouteValidations } from './constants';
import { routes } from 'Constants';
import { push } from 'connected-react-router';
import { flightRequestToUrl, validateSearch, totalPeople } from './helpers';
import API from 'Services';
import { selectAvailability } from 'ReduxConfig/checkout';
import { updateLoader } from 'Features/app/actions';
import { resetToEngine } from 'ReduxConfig/availability';
import { batch } from 'react-redux';
import { statusTypes } from 'Features/banners/constants';
import { selectAvailabilityCorpo } from '../../ReduxConfig/checkoutCorpo';

/**
 * @param {*} status
 */
export const setSearchStatus = status => ({
  type: ACTION_TYPES.FLIGHTS_SET_SEARCH_STATUS,
  payload: status
});

export const setOrigin = (index, origin, originName) => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_SET_ORIGIN,
    payload: { index, origin, originName }
  });
};

export const setDestination = (
  index,
  destination,
  destinationName
) => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_SET_DESTINATION,
    payload: { index, destination, destinationName }
  });
};

export const setCityKeyword = cityKeyword => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_SET_CITY_KEYWORD,
    payload: cityKeyword
  });
};

export const setLoadSearchFromRequest = request => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_LOAD_SEARCH_FROM_REQUEST,
    payload: request
  });
};

export const setInitialDate = (index, initialDate) => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_SET_INITIAL_DATE,
    payload: { index, initialDate }
  });
};

export const setEndDate = (index, endDate) => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_SET_END_DATE,
    payload: { index, endDate }
  });
};

export const setAdults = adults => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_SET_ADULTS,
    payload: adults
  });
};

export const setChildren = children => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_SET_CHILDREN,
    payload: children
  });
};

export const setRoutes = (routesData, validations) => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_SET_ROUTES,
    payload: { routesData, validations }
  });
};

export const toggleMPC = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_TOGGLE_MPC
  });
};

export const onChangeAdults = type => (dispatch, getState) => {
  const { adults, children } = getState().flights.search;
  const totalPassengers = totalPeople(adults, children);
  switch (type) {
    case types.INCREMENT:
      if (
        adults < limits.adults.max &&
        totalPassengers < limits.totalPeople.max
      )
        dispatch(setAdults(adults + 1));
      break;
    case types.DECREMENT:
      if (adults > limits.adults.min) dispatch(setAdults(adults - 1));
      break;
    default:
      break;
  }
};

export const onChangeChildren = type => (dispatch, getState) => {
  const { children, adults } = getState().flights.search;
  const totalPassengers = totalPeople(adults, children);
  switch (type) {
    case types.INCREMENT:
      if (totalPassengers < limits.totalPeople.max)
        dispatch(setChildren([...children, { age: 1, seat: false }]));
      break;
    case types.DECREMENT:
      dispatch(setChildren(children.slice(0, -1)));
      break;
    default:
      break;
  }
};

export const onChangeRoutes = type => (dispatch, getState) => {
  const { routesData, validations } = getState().flights.search;
  switch (type) {
    case types.INCREMENT:
      if (routesData.length < limits.routes.max) {
        const newValidations = {
          ...validations,
          routesData: [...validations.routesData, emptyRouteValidations]
        };
        dispatch(setRoutes([...routesData, emptyRoute], newValidations));
      }
      break;
    case types.DECREMENT:
      if (routesData.length > limits.routes.min) {
        const newValidations = {
          ...validations,
          routesData: validations.routesData.slice(0, -1)
        };
        dispatch(setRoutes(routesData.slice(0, -1), newValidations));
      }
      break;

    default:
      break;
  }
};

export const onChangeChildrenAge = childrenIndex => value => (
  dispatch,
  getState
) => {
  const { children } = getState().flights.search;
  const newChildren = [...children];
  newChildren[childrenIndex].age = value.id;
  dispatch(setChildren(newChildren));
};

export const onToggleChildrenSeat = childrenIndex => (dispatch, getState) => {
  const { children } = getState().flights.search;
  const newChildren = [...children];
  newChildren[childrenIndex].seat = !children[childrenIndex].seat;
  dispatch(setChildren(newChildren));
};

export const setCabinCode = value => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_SET_CABIN_CODE,
    payload: value
  });
};

export const doValidation = () => (dispatch, getState) => {
  const search = getState().flights.search;
  const destinationActive = getState().destinations.active;
  dispatch({
    type: ACTION_TYPES.FLIGHTS_VALIDATION_REQUEST
  });

  const { isValid, ...validations } = validateSearch(search);

  if (isValid) {
    dispatch({
      type: ACTION_TYPES.FLIGHTS_VALIDATION_SUCCESS
    });

    const searchString = flightRequestToUrl(search, destinationActive);
    search.isMPC
      ? dispatch(resetToEngine('vuelosMPC'))
      : dispatch(resetToEngine('vuelos'));
    dispatch(
      push({
        pathname: routes.flightSearch.replace(
          ':destination',
          search.cityKeyword
        ),
        search: searchString
      })
    );
  } else {
    return dispatch({
      type: ACTION_TYPES.FLIGHTS_VALIDATION_FAILURE,
      payload: validations
    });
  }
};

export const masterPriceJoinedRequest = amadeusRequest => dispatch => {
  batch(() => {
    dispatch({
      type: ACTION_TYPES.FLIGHTS_MPTB_AND_MPC_REQUEST
    });
    dispatch(setSearchStatus(statusTypes.loading));
  });

  API.flights
    .masterPriceJoined(amadeusRequest)
    .then(function(response) {
      const { data } = response;
      batch(() => {
        dispatch({
          type: ACTION_TYPES.FLIGHTS_MPTB_AND_MPC_SUCCESS,
          payload: data
        });
        // dispatch(setLoadSearchFromRequest(data.mptb.request));
      });
    })
    .catch(function(error) {
      batch(() => {
        dispatch({
          type: ACTION_TYPES.FLIGHTS_MPTB_AND_MPC_FAILURE,
          payload: error
        });
        dispatch(setSearchStatus(statusTypes.error));
      });
    });
};

const mptbItemResearchSuccess = (data, row, col) => ({
  type: ACTION_TYPES.FLIGHTS_MPTB_ITEM_RESEARCH_SUCCESS,
  payload: { ...data, row, col }
});

const mptbItemResearchFailure = (row, col) => ({
  type: ACTION_TYPES.FLIGHTS_MPTB_ITEM_RESEARCH_FAILURE,
  payload: { row, col }
});

export const mptbItemResearch = (amadeusRequest, row, col) => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_MPTB_ITEM_RESEARCH_REQUEST
  });

  API.flights
    .mptb(amadeusRequest)
    .then(response => {
      const { data } = response;
      if (data.response.length > 0) {
        dispatch(mptbItemResearchSuccess(data, row, col));
      } else {
        dispatch(mptbItemResearchFailure(row, col));
      }
    })
    .catch(() => {
      dispatch(mptbItemResearchFailure(row, col));
    });
};

export const resetValidations = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_RESET_VALIDATIONS
  });
};

export const setTypeFlight = type => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_SET_TYPE,
    payload: type
  });
};

export const resetFilters = value => ({
  type: ACTION_TYPES.FLIGHTS_AVAILABILITY_RESET_FILTERS,
  payload: value
});

const mptbAirAvailabilitySuccess = data => ({
  type: ACTION_TYPES.FLIGHTS_MPTB_AVAILABILITY_SUCCESS,
  payload: {
    ...data
  }
});

const mptbAirAvailabilityFailure = error => ({
  type: ACTION_TYPES.FLIGHTS_MPTB_AVAILABILITY_FAILURE,
  payload: {
    ...error
  }
});

const mpcAirAvailabilitySuccess = data => ({
  type: ACTION_TYPES.FLIGHTS_MPC_AVAILABILITY_SUCCESS,
  payload: {
    ...data
  }
});

const mpcAirAvailabilityFailure = error => ({
  type: ACTION_TYPES.FLIGHTS_MPC_AVAILABILITY_FAILURE,
  payload: {
    ...error
  }
});

export const mptbRequest = amadeusRequest => dispatch => {
  batch(() => {
    dispatch({ type: ACTION_TYPES.FLIGHTS_MPTB_AVAILABILITY_REQUEST });
    dispatch(setSearchStatus(statusTypes.loading));
  });

  API.flights
    .mptb(amadeusRequest)
    .then(function(response) {
      const { data } = response;
      if (data.response.length > 0) {
        batch(() => {
          dispatch(mptbAirAvailabilitySuccess(data));
          dispatch(setLoadSearchFromRequest(data.request));
        });
      } else {
        batch(() => {
          dispatch(mptbAirAvailabilityFailure(data));
          dispatch(setSearchStatus(statusTypes.error));
        });
      }
    })
    .catch(function(error) {
      batch(() => {
        dispatch(mptbAirAvailabilityFailure(error));
        dispatch(setSearchStatus(statusTypes.error));
      });
    });
};

export const mpcRequest = amadeusRequest => dispatch => {
  dispatch({
    type: ACTION_TYPES.FLIGHTS_MPC_AVAILABILITY_REQUEST
  });
  API.flights
    .mpc(amadeusRequest)
    .then(function(response) {
      const { data } = response;
      if (data && data.response && data.response.detail.length > 0) {
        dispatch(mpcAirAvailabilitySuccess(data));
      } else {
        dispatch(mpcAirAvailabilityFailure(data));
      }
    })
    .catch(function(error) {
      dispatch(mpcAirAvailabilityFailure(error));
    });
  return dispatch => {
    dispatch({
      type: ACTION_TYPES.FLIGHTS_MPC_AVAILABILITY_REQUEST
    });
  };
};

export const checkSelectionRequest = (
  params,
  availability,
  selectedSegment,
  id,
  isCorpo = false,
  isMPC = false
) => async dispatch => {
  try {
    dispatch({
      type: ACTION_TYPES.FLIGHTS_CHECK_SELECTION_REQUEST
    });

    const { data } = await API.flights.checkSelection(params);
    if (data.available) {
      if (isCorpo) {
        dispatch(selectAvailabilityCorpo({ ...availability, selectedSegment }));
        dispatch(push('/checkout-corpo'));
      } else {
        dispatch(selectAvailability({ ...availability, selectedSegment }));
        dispatch(push('/checkout'));
      }
    } else {
      if (isMPC) {
        //TODO: pedir diseno para este error
        alert(
          'Lo sentimos, pero la tarifa que solicitada no continua disponible.'
        );
        dispatch(updateLoader(false));
      }
      dispatch({
        type: ACTION_TYPES.FLIGHTS_CHECK_SELECTION_FAILURE,
        payload: { selectedSegment, id }
      });
    }
  } catch (error) {
    //TODO
    dispatch({
      type: ACTION_TYPES.FLIGHTS_CHECK_SELECTION_FAILURE,
      payload: { selectedSegment, id }
    });

    throw error;
  }
};

export const cleanError = () => ({
  type: ACTION_TYPES.FLIGHTS_CLEAN_ERROR
});

export const cleanSearch = () => ({
  type: ACTION_TYPES.FLIGHTS_CLEAN_SEARCH
});

export const setSearchDialogDetailMode = value => ({
  type: ACTION_TYPES.FLIGHTS_SET_DETAIL_MODE,
  payload: value
});
