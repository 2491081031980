/**
 *
 * FlightItineraryDetails
 *
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from 'Components/Text';
import * as COLORS from 'Constants/colors';
import useOutsideClick from '../../../../Utils/hooks/useOutsideClick';
import cs from 'classnames';
import Icon from 'Components/Icon';

const MobileFlightPriceDetails = ({ price, closeModal, className }) => {
  const breakdown = price.breakdown;
  const total = breakdown.filter(item => item.label.toUpperCase() === 'TOTAL');
  const refOpenMenu = useRef();

  useOutsideClick(refOpenMenu, () => {
    closeModal();
  });

  return (
    <div className={className}>
      <div className="container-mfpd ">
        <div className="TopBar">
          <span className="TopBarLabel">Detalle de la tarifa</span>
          <div className="TopBarClose" onClick={() => closeModal()}>
            <Icon name="mv-cross" />
          </div>
        </div>
        <div className="price-detail-header">
          {total.length > 0 ? (
            <Text
              context="important"
              paragraph
              className="price-detail-header-label">
              {`${total[0].currency} ${total[0].price}`}
            </Text>
          ) : (
            ''
          )}
          <Text
            className="price-detail-subheader-label"
            paragraph
            context="important">
            Precio Total
          </Text>
        </div>

        <div className="price-detail-container">
          {breakdown &&
            breakdown.map((item, index) => (
              <div
                key={index}
                className={cs('price-detail-container-item', {
                  divider: item.label === 'TOTAL'
                })}>
                <Text
                  paragraph
                  context={cs({ bold: item.label.toUpperCase() === 'TOTAL' })}>
                  {item.label}
                </Text>
                <Text
                  paragraph
                  context={cs({ bold: item.label.toUpperCase() === 'TOTAL' })}>
                  {item.currency} {item.price}
                </Text>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

MobileFlightPriceDetails.propTypes = {
  price: PropTypes.shape({
    breakdown: PropTypes.array.isRequired
  }),
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired
};

export default styled(MobileFlightPriceDetails)`
  .container-mfpd {
    width: 360px;
    background-color: ${COLORS.white};
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .TopBar {
    width: 100%;
    height: 62px;
    background-color: ${COLORS.white};
    padding-left: 13px;
    padding-right: 13px;
    display: flex;
    text-align: center;
  }

  .TopBarLabel {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: 0.56;
    letter-spacing: 0.18px;
    text-align: left;
    color: ${COLORS.middleGrey};
    margin: auto;
  }

  .TopBarClose {
    display: flex;
    align-items: center;
  }

  .price-detail-header {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .price-detail-header-label {
    color: ${COLORS.important};
    font-size: 18px;
    text-align: center;
  }

  .price-detail-subheader-label {
    text-align: center;
  }

  .price-detail-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 10px;
    color: ${COLORS.darkGrey};

    .divider {
      margin-top: 5px;
      padding-top: 2px;
      border-top: 2px solid ${COLORS.darkGrey};
    }
  }

  .price-detail-container-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }
`;
