import { LANG_COUNTRY } from 'Constants';

export const formatMoney = (
  amount: number,
  currency?: string,
  maxFractionDigis = 2,
  minFractionDigits = 0
) => {
  const formatter = new Intl.NumberFormat(LANG_COUNTRY, {
    style: currency ? 'currency' : undefined,
    currency,
    maximumFractionDigits: maxFractionDigis,
    minimumFractionDigits: minFractionDigits
  });
  return formatter.format(amount);
};
