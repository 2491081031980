/**
 *
 * MobileNavBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import * as COLORS from 'Constants/colors';

const MobileNavBar = ({
  className,
  children,
  hiddenElement,
  onTouch,
  show
}) => (
  <Container className={className} isUp={show} visible={window.innerHeight}>
    <div
      className="content"
      onTouchStart={typeof onTouch === 'function' ? onTouch : undefined}>
      {children}
    </div>

    <Panel visible={window.innerHeight}>{hiddenElement}</Panel>
  </Container>
);

export default MobileNavBar;

MobileNavBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  hiddenElement: PropTypes.node,
  onTouch: PropTypes.func,
  show: PropTypes.bool
};

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${COLORS.golf} !important;
  z-index: 999;
  transition: all cubic-bezier(1, 0.01, 0, 1) 400ms;
  box-shadow: 0.1px -3px 6px 0 rgba(0, 0, 0, 0.35);
  height: 70px;

  .content {
    display: flex;
    align-items: center;
    height: 71px;
    justify-content: flex-start;
    background-color: ${COLORS.golf} !important;
    padding: 5px 0;
  }

  ${({ visible, isUp }) =>
    isUp &&
    css`
      bottom: calc(${visible}px - 70px);

      .content {
        height: auto;
      }
    `}
`;

const Panel = styled.div`
  transition: all 500ms ease;
  background-color: ${COLORS.golf} !important;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 10px;
  height: calc(100vh - 65px);

  > div {
    width: 100%;
  }
`;
