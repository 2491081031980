export default function(api) {
  async function suggestions(value) {
    return await api.get(`places/suggestions/${encodeURIComponent(value)}`);
  }

  async function search(request) {
    return await api.post('searches', request);
  }

  async function hotelDetails(request) {
    return await api.post('details', request);
  }

  function checkRates(request) {
    return api.post('checkrates', request);
  }

  return {
    suggestions,
    search,
    hotelDetails,
    checkRates
  };
}
