/**
 *
 * CreditCardForm
 *
 */

// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import Input from '../Input';
import Select from '../Select';

// Constants
import Years from '../../Resources/years';
import Months from '../../Resources/months';

const getYears = (yearsJSON = []) =>
  yearsJSON
    .map((year, idx) => ({
      ...year,
      id: idx
    }))
    .filter(year => {
      const currentYear = new Date().getFullYear();

      return (
        Number(year.name) >= currentYear &&
        Number(year.name) <= currentYear + 10
      );
    })
    .reverse();

const Form = ({ fields, errors, onChange, onBlur }) => (
  <form>
    <Fields>
      <Input
        isRequired
        className="grid-3"
        placeholder="Ej: 4444-4444-4444-4444"
        context={errors && errors.cardNumber && 'warning'}
        label="Número de tarjeta"
        value={fields && fields.cardNumber}
        message={errors && errors.cardNumber}
        onChange={onChange('cardNumber')}
        onBlur={onBlur}
        isCreditCard
      />

      <Input
        isRequired
        className="grid-3"
        placeholder="Ej: Jhon Doe"
        label="Titular de la tarjeta"
        context={errors.cardName && 'warning'}
        value={fields.cardName}
        message={errors.cardName}
        onBlur={onBlur}
        onChange={onChange('cardName')}
      />

      <Input
        isRequired
        className="grid-2"
        placeholder="Ej: 143"
        label="Código de Seguridad"
        context={errors.cvc && 'warning'}
        value={fields.cvc}
        message={errors.cvc}
        onBlur={onBlur}
        onChange={onChange('cvc')}
      />

      <Select
        isRequired
        className="grid-2"
        label="Mes de expiración"
        context={errors.exp && 'warning'}
        onSelect={onChange('exp')}
        message={errors.exp}
        items={Months}
      />

      <Select
        isRequired
        className="grid-2"
        label="Año de expiración"
        onSelect={onChange('year')}
        context={errors.year && 'warning'}
        items={getYears(Years)}
      />

      <Input
        isRequired
        className="grid-2"
        placeholder="Ej: 123-1234-1234"
        label="Documento del títular de la tarjeta"
        context={errors.document && 'warning'}
        message={errors.document}
        value={fields.document}
        onBlur={onBlur}
        onChange={onChange('document')}
      />
    </Fields>
  </form>
);

const Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1em;

  .grid-3 {
    grid-column: span 3;
  }

  .grid-2 {
    grid-column: span 2;
  }

  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;

    .grid-3,
    .grid-2 {
      margin-bottom: 16px;
    }
  }
`;

Form.propTypes = {
  fields: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
};

export default Form;
