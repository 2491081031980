/**
 *
 * MenuTab
 *
 */

import React from 'react';
import styled from 'styled-components';
import NewText from 'Components/NewText';
import { ThemeColor } from 'Theme';

export interface MenuTabProps {
  children: React.ReactNode;
}

const MenuTab = ({ children, ...rest }: MenuTabProps) => (
  <Container {...rest}>{children}</Container>
);

export interface TabContainerProps {
  active?: boolean;
  color?: ThemeColor;
}

export interface TabProps {
  children: React.ReactNode;
  active?: boolean;
  color?: ThemeColor;
  onClick?: () => void;
}

const Tab = ({ children, ...rest }: TabProps) => (
  <TabContainer {...rest}>
    <FlexText color="white" fontWeight="medium">
      {children}
    </FlexText>
  </TabContainer>
);

const FlexText = styled(NewText)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.one};
`;

const TabContainer = styled.li<TabContainerProps>`
  all: unset;
  text-align: center;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  color: white;
  flex: 1;
  white-space: nowrap;
  transition: 0.6s;
  cursor: pointer;
  ${({ active, theme, color }) =>
    active &&
    `
      background-color:
        ${color ? theme.colors[color] : theme.palette.primary.orange};
      box-shadow: ${theme.shadows.one};
    `}
`;

const Container = styled.ul`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background: ${({ theme }) => theme.palette.all.violet};
  margin-bottom: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) => `0 ${theme.spacing.four}`};
  width: 100%;
  transition: 0.6s;
  overflow: auto;
`;

MenuTab.Item = Tab;
export default MenuTab;
