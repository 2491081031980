import {
  format,
  parse,
  addDays,
  isValid,
  addMonths,
  subDays,
  differenceInCalendarDays,
  isFriday
} from 'date-fns';
import es from 'date-fns/locale/es';

export const isoFormat = 'yyyy-MM-dd';
export const formatDMY = 'dd-MM-yyyy';
export const localLong = 'iii, dd MMM yyyy';
export const localSuperLong = 'iiii, dd MMM yyyy';
export const localLongWithDate = 'iii, dd MMM yyyy HH:mm';
export const localShort = 'iii. dd MMM.';
export const localSuperShort = 'dd MMM.';
export const localFullWithoutYear = 'iiii, d \'de\' MMMM';

export const formatStringCustomDate = date => {
  if (date) {
    const arrayDate = date.split('-');
    const dateObj = new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]);
    return format(dateObj, isoFormat);
  }
  return date;
};

export const formatIso = date => format(date, isoFormat);

export const parseIso = date => parse(date, isoFormat, new Date());

const getNextFriday = () => {
  let friday = new Date();
  for (let index = 1; index < 8; index++) {
    friday = addDays(friday, 1);
    if (isFriday(friday)) {
      return friday;
    }
  }
  return friday;
};

//Todo: get starting date from store

export const isoStartingDate = formatIso(addDays(new Date(), 3));
export const isoToday = formatIso(new Date());

export const isoFiveDays = formatIso(addDays(new Date(), 5));
export const isoTenDays = formatIso(addDays(new Date(), 10));
export const isoFifteenDays = formatIso(addDays(new Date(), 15));
export const isoTwentyDays = formatIso(addDays(new Date(), 20));

export const isoNextFriday = formatIso(getNextFriday());
export const isoNextSaturday = formatIso(addDays(getNextFriday(), 1));

export const formatLocale = (date, f) =>
  date instanceof Date
    ? capitalize(format(date, f, { locale: es }))
    : capitalize(format(parseIso(date), f, { locale: es }));

export const formatSuperShort = date => formatLocale(date, localSuperShort);

export const formatShort = date => formatLocale(date, localShort);

export const formatLong = date => formatLocale(date, localLong);

export const formatSuperLong = date => formatLocale(date, localSuperLong);
export const formatFullWithoutYear = date =>
  formatLocale(date, localFullWithoutYear);

const capitalize = string => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isValidIsoDate = isoDate => {
  const date = parseIso(isoDate);
  return isValid(date);
};

export const getMaxFlightDate = () => {
  const today = new Date();
  return subDays(addMonths(today, 11), 5);
};

export const formatLongWithTime = date => formatLocale(date, localLongWithDate);

export const getDaysInISORange = (rangeEnd, rangeStart) =>
  differenceInCalendarDays(parseIso(rangeEnd), parseIso(rangeStart));
