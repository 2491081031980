/**
 *
 * MobileHotelRooms
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styled from 'styled-components';
import Button from 'Components/Button';
import MobileHotelRoom from '../MobileHotelRoom';
import Text from 'Components/Text';

const MobileHotelRooms = ({ rooms, onChange, selected, className }) => {
  const [showMore, setShowMore] = useState(false);
  const limit = 4;

  const hasMoreToShow = rooms && rooms.length > limit;
  const showMe = bool => () => setShowMore(bool);

  const showMoreButton = (
    <div className="btn-showmore">
      {showMore ? (
        <Button onClick={showMe(false)} context="information">
          Ver menos habitaciones
        </Button>
      ) : (
        <Button onClick={showMe(true)} context="information">
          Ver más habitaciones
        </Button>
      )}
    </div>
  );

  return (
    <div className={className}>
      <div className="header-title">
        <Text context="important" size="large" weight="bold">
          ELIGE UNA OPCION
        </Text>
      </div>

      {rooms.map((room, i) => (
        <div
          key={i}
          className={cs('room-container', {
            hidden: i > limit - 1 && !showMore
          })}>
          <MobileHotelRoom
            room={room}
            selected={selected}
            setSelected={onChange}
          />
        </div>
      ))}
      {hasMoreToShow && showMoreButton}
    </div>
  );
};

MobileHotelRooms.propTypes = {
  rooms: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.object,
  className: PropTypes.string
};

export default styled(MobileHotelRooms)`
  display: block;
  padding: 5px;

  .hidden {
    display: none;
  }

  .room-container {
    margin-bottom: 10px;
  }

  .header-title {
    text-align: center;
  }

  .btn-showmore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
  }
`;
