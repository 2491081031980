import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { RootState } from 'ReduxConfig/store';

export const setToken = (token): void => {
  Cookies.set('authToken', token);
};

export const getToken = (): string => Cookies.get('authToken');

export const removeToken = (): void => {
  Cookies.remove('authToken');
};

export const useUser = () =>
  useSelector((state: RootState) => state.users.user?.user);
