import React from 'react';
import Icon from '../Icon';
import styled from 'styled-components';
import Text from '../Text';
import { useIsScrollTopHook } from 'Utils/hooks/useIsScrollTop';
import Button from '../Button';
import Agent from '../../Assets/images/callMe.jpg';
import { footerCountryData } from 'Constants';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCallMe } from 'Features/common';
import { RootState } from 'ReduxConfig/store';
import { Link } from '@reach/router';

const data = footerCountryData();

const pushToWhatsApp = () => {
  window.open(data.whatsapp);
};

const CallMeSmall = styled.div`
  border: 1px solid;
  padding: ${({ theme }) => theme.spacing.one};
  position: fixed;
  top: 80px;
  right: 0;
  background-color: ${({ theme }) => theme.colors.orange};
  border-top-left-radius: ${({ theme }) => theme.spacing.three};
  border-bottom-left-radius: ${({ theme }) => theme.spacing.three};
  width: 161px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  transition: 0.6s;
  z-index: ${({ theme }) => theme.zIndex.contactButton};

  &.small {
    right: -122px;
  }

  @media (max-width: 740px) {
    top: 26px;
  }
`;

const CallMeForm = styled.div`
  width: 300px;
  position: fixed;
  top: 80px;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 450px;
  z-index: 9999999999;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadows.three};
  border-radius: ${({ theme }) => theme.spacing.four};
  padding-bottom: ${({ theme }) => theme.spacing.three};
  .back-button {
    align-self: flex-end;
    padding-top: ${({ theme }) => theme.spacing.four};
    padding-right: ${({ theme }) => theme.spacing.two};
  }
  .information {
    text-align: center;
    margin: ${({ theme }) => theme.spacing.four};
    .country-phone {
      margin-top: ${({ theme }) => theme.spacing.two};
    }
  }
  .call-online-button {
    width: 200px;
    align-self: center;
  }

  .whatsapp-button {
    margin-top: 4px;
    width: 200px;
    align-self: center;
  }
`;
const AvatarImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.orange};
  align-self: center;
  padding: ${({ theme }) => theme.spacing.one};
  margin: ${({ theme }) => theme.spacing.two};
`;

type CallMeStatus = 'small' | 'form';

export function CallMe() {
  const status = useSelector((state: RootState) => state.common.callMeStatus);
  const dispatch = useDispatch();
  const isTop = useIsScrollTopHook();

  const toggleStatus = () => dispatch(toggleCallMe());

  return status === 'form' ? (
    <CallMeForm>
      <Icon
        pointer
        className="back-button"
        onClick={toggleStatus}
        name="mv-cross"
        size={24}
        color="important"
      />
      <AvatarImage src={Agent} />
      <div className="information">
        <Text weight="bold">Para ventas:</Text> <br />
        <Text>
          De Lunes a Viernes de 10 a 18:00hs, Sábados de 10 a 13hs
        </Text>{' '}
        <br />
        <Button
          className="whatsapp-button"
          variation="orange"
          size="L"
          onClick={e => {
            window.location.href = `tel:${data.phone}`;
            e.preventDefault();
          }}>
          Llamanos
        </Button>
        <br />
        <Link
          className="country-phone"
          to="#"
          onClick={e => {
            window.location.href = `tel:${data.phone}`;
            e.preventDefault();
          }}>
          <Text weight="bold" context="primary">
            {data.phone}
          </Text>
        </Link>
      </div>
      {data.whatsapp && (
        <Button
          className="whatsapp-button"
          variation="positive"
          size="L"
          onClick={pushToWhatsApp}>
          Whatsappeanos
        </Button>
      )}
      <Link
        className="information"
        to="#"
        onClick={e => {
          window.location.href = `mailto:${data.mail}`;
          e.preventDefault();
        }}>
        <Text>{data.mail}</Text>
      </Link>
    </CallMeForm>
  ) : (
    <CallMeSmall onClick={toggleStatus} className={!isTop && 'small'}>
      <Icon name="mv-chat" size={24} color="white" />
      <Text context="white"> Contactanos </Text>
    </CallMeSmall>
  );
}
