import { format } from 'date-fns/esm';
import es from 'date-fns/locale/es';

const locales = { es };

export const dateForMpc = date => {
  if (date) {
    const arrayDate = date.split('-');
    const dateObj = new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]);
    return format(dateObj, 'iii dd/MM', {
      locale: locales.es
    }).toUpperCase();
  }
  return null;
};
export default date => {
  if (date) {
    const arrayDate = date.split('-');
    const dateObj = new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]);
    return format(dateObj, 'iii, dd MMM yyyy', {
      locale: locales.es
    });
  }
  return null;
};

export const getMaxDate = checkOut => {
  const selectedDate = new Date(checkOut);

  return new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate() + 29
  );
};
