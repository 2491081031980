/**
 *
 * HotelDetailFareBlock
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Button from 'Components/Button';
import HotelSelectFare from '../HotelSelectFare';
import { otroVioleta, white } from 'Constants/colors';
import { hotelPropertyPriceTypes } from 'Constants';
import LazyImg from 'Components/LazyImg';
import genericRoom from 'Assets/images/Hotel-Room-Generic.jpg';

const LIMIT = 4;

const HotelDetailFareBlock = ({
  images,
  packages,
  nameRoom,
  onSelectFare,
  priceType,
  activeId,
  className
}) => {
  const [showMore, setShowMore] = useState(false);
  const hasMoreToShow = packages && packages.length > LIMIT;

  const handleSelectFare = packageItem => () => {
    onSelectFare(packageItem);
  };

  const showMe = bool => () => setShowMore(bool);

  const showMoreButton = showMore ? (
    <Button onClick={showMe(false)}>Mostrar menos</Button>
  ) : (
    <Button onClick={showMe(true)}>Mostrar más</Button>
  );

  return (
    <div className={className}>
      <div className="hotel-fare-header">{nameRoom}</div>

      <div className="details-fare-holder">
        <div className="room-details">
          {images.map((image, index) => (
            <div className="room-photo" key={index}>
              {/* 
              // TODO
              // @ts-ignore */}
              <LazyImg
                src={image}
                alt={nameRoom}
                className="room-photo"
                imgFallback={genericRoom}
              />
              {index === 0 && (
                <Button
                  size="S"
                  variation="information"
                  className="room-photo-button">
                  Ver detalle de la habitación
                </Button>
              )}
            </div>
          ))}
        </div>
        <div className="room-fares">
          {packages &&
            packages.map((roomFareItem, index) => {
              const price =
                priceType === hotelPropertyPriceTypes.PER_NIGHT
                  ? roomFareItem.packagePrice.finalPricePerNight
                  : roomFareItem.packagePrice.finalPrice;

              const localPrice =
                priceType === hotelPropertyPriceTypes.PER_NIGHT
                  ? roomFareItem.localPrice?.finalPricePerNight
                  : roomFareItem.localPrice?.finalPrice;

              return (
                <div
                  key={index}
                  className={cs('room-fare-item', {
                    hidden: index > LIMIT - 1 && !showMore
                  })}>
                  <HotelSelectFare
                    price={price}
                    localPrice={localPrice}
                    priceType={priceType}
                    currency={roomFareItem.packagePrice.currency}
                    localCurrency={roomFareItem.localPrice?.currency}
                    details={roomFareItem.details}
                    active={activeId === roomFareItem.packageId}
                    // TODO
                    // @ts-ignore
                    onSelectFare={onSelectFare}
                    id={roomFareItem.id}
                    onClick={handleSelectFare(roomFareItem)}
                  />
                </div>
              );
            })}

          {hasMoreToShow && showMoreButton}
        </div>
      </div>
    </div>
  );
};

HotelDetailFareBlock.propTypes = {
  images: PropTypes.array,
  packages: PropTypes.array,
  nameRoom: PropTypes.string,
  onSelectFare: PropTypes.func,
  priceType: PropTypes.string,
  activeId: PropTypes.any,
  className: PropTypes.string
};

export default styled(HotelDetailFareBlock)`
  margin-bottom: 20px;

  .hidden {
    display: none;
  }

  .hotel-fare-header {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: ${otroVioleta};
    height: 31px;
    font-family: Montserrat, serif;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    text-align: left;
    color: ${white};
    line-height: 31px;
    position: relative;
    left: -12px;
    padding-left: 12px;
    width: calc(100% + 12px);
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    margin-bottom: 18px;
  }

  .details-fare-holder {
    display: flex;

    .room-details {
      margin-right: 24px;

      .room-photo {
        width: 299px;
        height: 168px;
        border-radius: 14px;
        background-size: cover;
        background-position: center;
        position: relative;

        .room-photo-button {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .mv-room-detail {
        margin-top: 10px;
      }
    }

    .room-fares {
      .room-fare-item {
        margin-bottom: 14px;
      }
    }
  }
`;
