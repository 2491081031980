import React from 'react';
import Heading from 'Components/Heading';
import Button from 'Components/Button';
import NewText from 'Components/NewText';
import styled from 'styled-components';
import { footerCountryData } from 'Constants';

const ExtraWrapper = styled.div<{ bestOption?: boolean }>`
  background: ${props => (props.bestOption ? props.theme.colors.orange : '')};
  max-width: ${props => (props.bestOption ? '520' : '480')};
  border-radius: ${props => props.theme.borderRadius.default};
`;

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.default};
  padding: ${props => props.theme.spacing.four};
  max-width: 480px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const OrangeLine = styled.div`
  height: 2px;
  background-color: ${props => props.theme.colors.important};
  margin: ${props => props.theme.spacing.two} 0;
`;

const TextDetailHolder = styled.div`
  margin: ${props => props.theme.spacing.two} 0;
`;

const TextDetailProduct = styled(NewText)`
  display: block;
`;

const BottomContentHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PriceDataHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CovidBadge = styled.div`
  background-color: ${props => props.theme.colors.newBackground};
  max-width: fit-content;
  padding-right: ${props => props.theme.spacing.two};
  padding-left: ${props => props.theme.spacing.two};
  border-radius: ${props => props.theme.borderRadius.rounded};
`;

export interface IProduct {
  id: string;
  bestOption?: boolean;
  title: string;
  details: string[];
  price: string;
  priceTime: string;
  termsUrl?: string;
}

const countryData = footerCountryData();

const ProductCard = ({ data }: { data: IProduct }) => {
  const urlMessage = `http://wa.me/${countryData.whatsNumber}?text=Hola!%20Más%20info%20sobre%20la%20asistencia%20al%20viajero%20"${data.title}"%20por%20favor.%20Gracias!`;
  const pushToWhatsApp = () => {
    window.open(urlMessage);
  };
  const pushToTerms = () => {
    window.open(data.termsUrl);
  };

  return (
    <ExtraWrapper bestOption={data.bestOption}>
      <Wrapper>
        <Header>
          <Heading type="h3" color="newBackground">
            {data.title}
          </Heading>
          {data.bestOption ? (
            <CovidBadge>
              <NewText fontSize="12px" color="white" fontWeight="bold">
                MEJOR OPCIÓN
              </NewText>
            </CovidBadge>
          ) : (
            ''
          )}
        </Header>
        <TextDetailHolder>
          {data.details.map((d, index) => (
            <TextDetailProduct key={index}>- {d}</TextDetailProduct>
          ))}
        </TextDetailHolder>
        <Header>
          <CovidBadge>
            <NewText fontSize="12px" color="white" fontWeight="bold">
              + COVID-19
            </NewText>
          </CovidBadge>
          <Button size="S" variation="information" onClick={pushToTerms}>
            Ver condiciones
          </Button>
        </Header>
        <OrangeLine />
        <BottomContentHolder>
          <PriceDataHolder>
            <NewText fontSize="12px">Precio por pasajero</NewText>
            <Heading type="h5" color="important" fontWeight="bold">
              {data.price}
            </Heading>
            <NewText fontSize="12px">{data.priceTime}</NewText>
          </PriceDataHolder>
          <div>
            {countryData.whatsNumber && (
              <Button onClick={pushToWhatsApp} variation="orange">
                Consultar
              </Button>
            )}
          </div>
        </BottomContentHolder>
      </Wrapper>
    </ExtraWrapper>
  );
};

export default ProductCard;
