/**
 *
 * DidYouKnow
 *
 */

import React from 'react';
import styled from 'styled-components';
import '@brainhubeu/react-carousel/lib/style.css';
import Carousel from '@brainhubeu/react-carousel';
import Divider from 'Components/Divider';
import { Description } from 'Components/CardImage';
import Heading from 'Components/Heading';
import Icon from 'Components/Icon';
import { IDidYouKnow } from 'Features/destinations/types';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { returnCarrouselItems } from '../../helpers';

interface DidYouKnowProps {
  didYouKnows: IDidYouKnow[];
  name: string;
}

const IconHolder = styled.div`
  margin-right: ${props => props.theme.spacing.two};
`;

const HeaderHolder = styled.div`
  display: flex;
  padding-bottom: ${props => props.theme.spacing.two};
`;

const ArrowIconHolder = styled.div`
  width: 40px;
  height: 40px;
`;

const TipBox = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.default};
  margin: ${props => props.theme.spacing.two};
  padding: 16px;
  display: flex;
  align-items: flex-start;
`;

const DidYouKnow = ({ didYouKnows, name }: DidYouKnowProps) => {
  const breakpoints = useBreakpoints();
  return (
    <>
      <Divider titleText={`¿Qué más saber sobre ${name}?`} />
      <Carousel
        slidesPerPage={returnCarrouselItems(breakpoints)}
        arrows
        infinite
        arrowLeft={
          <ArrowIconHolder>
            <Icon name="mv-arrow-left" color="white" size={20} />
          </ArrowIconHolder>
        }
        arrowRight={
          <ArrowIconHolder>
            <Icon name="mv-arrow-right" color="white" size={20} />
          </ArrowIconHolder>
        }
        addArrowClickHandler>
        {didYouKnows?.map(item => (
          <TipBox key={item.id}>
            <div>
              <HeaderHolder>
                <IconHolder>
                  <Icon name="mv-globo-circle" color="important" size={20} />
                </IconHolder>
                <Heading>{item.title}</Heading>
              </HeaderHolder>

              <Description description={item.description} clamp />
            </div>
          </TipBox>
        ))}
      </Carousel>
    </>
  );
};

export default DidYouKnow;
