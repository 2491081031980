/**
 *
 * Toggle
 *
 */

import React from 'react';
import styled from 'styled-components';
import { ToggleProps, ToggleSliderProps, ToggleContainerProps } from './types';
import Text from 'Components/Text';
import { getSizes, getColor, getLabelProps } from './helpers';

const Toggle = ({
  status,
  size = 'big',
  onToggle,
  context = 'important',
  /**
   *
   * Left Label OPTIONAL
   * Having both labels on will not have 'off - grey' state
   *
   */

  leftLabel,
  /**
   *
   * Right Label OPTIONAL
   * Having both labels on will not have 'off - grey' state
   *
   */
  rightLabel
}: ToggleProps) => {
  const handleToggle = () => {
    if (onToggle) {
      onToggle(status);
    }
  };
  const sizes = getSizes(size);
  const color = getColor(context);
  const alwaysColor = Boolean(leftLabel && rightLabel);

  return (
    <Container>
      {leftLabel && (
        <Text {...getLabelProps(!status, context)}>{leftLabel}</Text>
      )}
      <ToggleContainer sizes={sizes}>
        <HiddenInput type="checkbox" />
        <ToggleSlider
          status={status}
          sizes={sizes}
          onClick={handleToggle}
          color={color}
          alwaysColor={alwaysColor}
        />
      </ToggleContainer>
      {rightLabel && (
        <Text {...getLabelProps(status, context)}>{rightLabel}</Text>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleContainer = styled.label<ToggleContainerProps>`
  position: relative;
  width: ${({ sizes }) => sizes.sliderWidth};
  margin: auto ${({ theme }) => theme.spacing.two};
`;

const HiddenInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleSlider = styled.span<ToggleSliderProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  transition: all 400ms ease-in-out;
  border-radius: 14px;
  width: ${({ sizes }) => sizes.sliderWidth};
  height: ${({ sizes }) => sizes.sliderHeight};
  background-color: ${({ theme, alwaysColor, color }) =>
    alwaysColor ? color : theme.colors.navBarGrey};
  border: ${({ sizes, theme }) =>
    `${sizes.sliderBorder} solid ${theme.colors.blackHaze}`};
  ${({ status, color }) => status && `background-color: ${color};`};

  &::before {
    position: absolute;
    content: '';
    width: ${({ sizes }) => sizes.siwtchWidth};
    height: ${({ sizes }) => sizes.switchHeight};
    margin: auto 0;
    top: 0;
    bottom: 0;
    left: 2px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: all 400ms ease-in-out;
    border-radius: 50%;
    ${({ status, sizes }) =>
    status && `transform: translateX(${sizes.translateX})`};
    box-shadow: ${({ theme }) => theme.shadows.one};
  }
`;

export default Toggle;
export * from './types';
