import React from 'react';

import QuotationsSearch from '../QuotationsSearch';
import QuotationsAvailabilityCard from '../QuotationsAvailabilityCard';

import Button from 'Components/Button';
import PageIndicator from 'Components/PageIndicator';

import { QuotationsHomeProps } from './types';

import spinner from 'Assets/loader/spinner.svg';

import {
  Container,
  SearchWrapper,
  ListWrapper,
  Actions,
  Error,
  Loading,
  PaginationContainer
} from './styles';
import Link from 'Components/Link';
import { routes } from 'Constants';

const QuotationsHome = ({
  onDeleteQuotation,
  onCopyQuotation,
  onNextPage,
  onPreviousPage,
  list,
  pending,
  pagination,
  resultsFound
}: QuotationsHomeProps) => (
  <Container>
    <SearchWrapper>
      <QuotationsSearch />
    </SearchWrapper>

    <Actions right>
      <Link to={routes.quotationsDetails.replace(':id', 'new')}>
        <Button>+ Cotizacion</Button>
      </Link>
    </Actions>

    <ListWrapper>
      {!pending &&
        resultsFound &&
        list.map(l => (
          <Link
            to={routes.quotationsDetails.replace(':id', String(l.id))}
            key={l.code}>
            <QuotationsAvailabilityCard
              {...l}
              id={l.code}
              flights={l.routes}
              hotels={l.accommodationOptions}
              services={l.services}
              nights={2}
              onDelete={onDeleteQuotation}
              onCopy={onCopyQuotation}
            />
          </Link>
        ))}

      {!pending && !resultsFound && (
        <Error>No se encontraron resultados.</Error>
      )}

      {pending && (
        <Loading>
          <img src={spinner} alt="loading" />
        </Loading>
      )}
    </ListWrapper>

    {!pending && resultsFound && (
      <PaginationContainer>
        <PageIndicator
          current={pagination.currentPage + 1}
          total={pagination.totalPages}
          onClickNext={onNextPage}
          onClickPrev={onPreviousPage}
        />
      </PaginationContainer>
    )}
  </Container>
);

export default QuotationsHome;
