import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMPC, setInitialDate } from '../../index';
import { setActive } from 'Features/destinations/actions';
import OneWay from './OneWay';
import { RootState } from 'ReduxConfig/store';

const selector = ({ flights, destinations, appConfiguration }: RootState) => ({
  isMPC: flights.search.isMPC,
  knowDestination: destinations.active,
  minDateToStartSearch: appConfiguration.flightsConfig.minDateToStartSearch,
  initialDate: flights.search.routesData[0].initialDate,
  dateMessages: flights.search.validations.routesData[0].initialDate,
  isCurrentViewDestinations: destinations.isCurrentView
});

const ConnectedOneWay = props => {
  const dispatch = useDispatch();
  const actions = {
    toggleMPC: () => {
      dispatch(toggleMPC());
    },
    setKnowDestination: () => {
      dispatch(setActive(!state.knowDestination));
    },
    setInitialDate: date => {
      dispatch(setInitialDate(0, date));
    }
  };

  const state = useSelector(selector);

  return <OneWay {...props} {...state} actions={actions} />;
};

export { OneWay };
export default ConnectedOneWay;
