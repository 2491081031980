/**
 *
 * FilterHolder
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import * as COLORS from 'Constants/colors';

import Icon from '../Icon';
import Text from '../Text';

const FilterHolder = ({
  className,
  children,
  title,
  context,
  active = true,
  isCollapse = false
}) => {
  const [state, setState] = useState(active);
  const toggleActive = () => {
    if (isCollapse) setState(!state);
  };

  return (
    <div className={cs(className)}>
      <div className={cs('filter-title-bar', context)} onClick={toggleActive}>
        <Text context="white" weight="bold">
          {title}
        </Text>
        {isCollapse && (
          <Text className={cs('icon-box', { active: active })}>
            <Icon
              name={state ? 'mv-arrow-down' : 'mv-arrow-up'}
              color="white"
            />
          </Text>
        )}
      </div>
      <div className={cs('filter-content', { active: state })}>{children}</div>
    </div>
  );
};

FilterHolder.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  context: PropTypes.string,
  active: PropTypes.bool,
  isCollapse: PropTypes.bool
};

export default styled(FilterHolder)`
  width: 100%;
  position: relative;
  margin-bottom: 8px;

  .filter-title-bar {
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    height: 26px;
    box-shadow: ${COLORS.shadows.one};
    background-color: ${COLORS.violeta};
    font-family: Montserrat Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    text-align: left;
    color: ${COLORS.white};
    display: flex;
    align-items: center;
    padding: 9px 13px 7px 13px;
    justify-content: space-between;

    .icon-box {
      color: ${COLORS.tussock};
      position: relative;
      transition: transform 0.26s ease;

      &.active {
        transform: rotate(180deg);
        top: -5px;
      }
    }

    &.inverse {
      background-color: ${COLORS.arena};
      color: ${COLORS.secondary};

      .icon-box {
        color: ${COLORS.secondary};
      }
    }
  }

  .filter-content {
    background-color: ${COLORS.greyNurse};
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
    overflow: hidden;
    max-height: 0;
    padding: 0;
    transition: max-height 0.26s ease, padding 0.26s ease;

    &.active {
      max-height: 800px;
      padding: 11px;
    }
  }
`;
