/**
 *
 * MobileOneWay
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MobileFlightDestination from '../MobileFlightDestination';
import MobilePaxDetailAirCard from '../MobilePaxDetailAirCard';
import MobileDateSelector from 'Components/MobileDateSelector';
import Toggle from 'Components/Toggle';
import { getMaxFlightDate } from 'Utils/dates';

const MobileOneWay = ({
  className,
  knowDestination,
  isMPC,
  actions,
  initialDate,
  isCurrentViewDestinations
}) => (
  <div className={className}>
    <div className="container-main">
      <div className="container-top">
        <MobileFlightDestination />
      </div>
      <div className="container-bottom">
        <MobilePaxDetailAirCard />
        <MobileDateSelector
          oneWay
          departureDate={initialDate}
          setDepartureDate={actions.setInitialDate}
          maxDate={getMaxFlightDate()}
          isFlight
        />
      </div>
      <div className="container-checkboxes">
        {!isCurrentViewDestinations && (
          <Toggle
            size="medium"
            context="important"
            leftLabel="Conocer más"
            status={knowDestination}
            onToggle={actions.setKnowDestination}
          />
        )}

        <Toggle
          size="medium"
          context="important"
          leftLabel="Fechas cercanas"
          status={isMPC}
          onToggle={actions.toggleMPC}
        />
      </div>
    </div>
  </div>
);

MobileOneWay.propTypes = {
  className: PropTypes.string,
  validationMessages: PropTypes.object,
  actions: PropTypes.shape({
    toggleMPC: PropTypes.func.isRequired,
    setKnowDestination: PropTypes.func.isRequired,
    setInitialDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired
  }),
  isMPC: PropTypes.bool,
  knowDestination: PropTypes.bool,
  initialDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  isCurrentViewDestinations: PropTypes.bool
};

export default styled(MobileOneWay)`
  .container-bottom {
    display: flex;
    margin-top: 5px;
    padding-left: 2px;
    padding-right: 2px;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }

  .container-checkboxes {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: ${({ theme }) => theme.spacing.two};
  }
`;
