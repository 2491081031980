import styled from 'styled-components';

import { QuotationsActionsProps } from './types';

export const Container = styled.div`
  min-height: 100vh;
  width: 1110px;
  max-width: 95%;
  margin: 0 auto;
`;

export const SearchWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.four};
  padding-bottom: ${({ theme }) => theme.spacing.four};
`;

export const ListWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.four};
  padding-bottom: ${({ theme }) => theme.spacing.four};

  > a > div {
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
`;

export const Error = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.two};
  padding: ${({ theme }) => theme.spacing.four};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  text-align: center;
`;

export const Loading = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.two};
`;

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Actions = styled.div<QuotationsActionsProps>`
  ${({ right }) =>
    right &&
    `
    display: flex;
    justify-content: flex-end;
  `};
`;
