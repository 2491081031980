/**
 *
 * Banner
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cs from 'classnames';

import useBreakpoints from 'Utils/hooks/useBreakpoints';

import { hexToRgb } from 'Utils';
import LinkTo from 'Components/LinkTo';
import LazyImg from 'Components/LazyImg';
import Icon from 'Components/Icon';

const Banner = ({
  className,
  type,
  description,
  price,
  title,
  shadowColor,
  currency,
  image,
  url,
  icons,
  openInNewWindows
}) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const returnClass = isMobile
    ? 'mobile'
    : `${cs(
      className,
      { 'full': type === 1 },
      {
        'two-thirds': type === 2,
        'half': type === 3,
        'one-third': type === 4,
        'quarter': type === 5
      }
    )}`;
    
  return (
    <BannerContainer className={returnClass}>
      <div className='banner' style={{ backgroundImage: `url(${image})` }}>
        <LinkTo urlTo={url} openInNewTab={openInNewWindows} className='link'>
          <div className={`title-shadow ${title ? '' : 'noTitle'}`}>
            <div className="title">
              {title ? title : ''}
            </div>
          </div>
          {price && <div className='shadow'>
            <div className='price-holder'>
              <div className='desde-holder'>
                <div className='desde'>desde</div>
                <div className='currency'>{currency}</div>
              </div>
              <div className='price'>{price}</div>
            </div>
            {description && <div className='subtitle'>{description}</div>}
          </div>}
        </LinkTo>
      </div>
    </BannerContainer>
  )
}

export default Banner;

const BannerContainer = styled.div`
  display: flex;

  .link{
    text-decoration: none;
    color: #FFFFFF;
  }

  .desde-holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .price-holder{
    display: flex;
    justify-content: center;
    margin-top 10px;
    margin-bottom: -12px;
  }

  .price{
    font-size: 44px;
    font-weight: 600;
    margin-left: 5px;
  }

  .currency{
    font-size: 25px;
    font-weight: 600
  }

  .desde{
    font-size: 17px;
    font-weight: 500;
    margin-bottom: -6px
    border-bottom: 2px solid #F4640D;
    align-self: center;
    line-height: normal;
  }

  .subtitle{
    display: flex;
    justify-content: center;
    font-size: 12px;
  }

  .title{
    color: #FFFFFF;
    display: flex;
    text-align: center;
    font-size: 24px;
    font-weight: 600
    position: relative;
    top: 45%;
    z-index: 2;
    justify-content: center
  }
  
  .shadow{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25%;
    background-color: rgba(49, 23, 75, 0.75);
  }

  .banner{
    display: flex;
    color: #FFFFFF;
    height: 340px;
    width: 100%;
    position: relative;
    background-repeat: no-repeat
    background-size: cover;
    margin: 10px;
    border-radius: 10px;
    background-position-x: center;
    background-position-y: center;
    justify-content: center;

    @media (max-width: 768px) {
      margin: 0;
    }
  }

  .image {
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%; 
  }

  .title-shadow {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(0,0,0,0.4), transparent 90%);
    z-index: 1;
    &.noTitle {
      background: transparent;
    }
  }

  &.mobile {
    width: 100%;
  }

  &.full {
    width: 100%;
  }

  &.two-thirds {
    width: 66.66%;
  }

  &.half {
    width: 50%;
  }

  &.one-third {
    width: 33.33%;
  }

  &.quarter {
    width: 25%;
  }
`;


// const Banner = ({
//   className,
//   type,
//   description,
//   price,
//   title,
//   shadowColor,
//   currency,
//   image,
//   url,
//   icons,
//   openInNewWindows
// }) => {
//   const isXlDown = useBreakpoints().xlDown;
//   const isSmallDown = useBreakpoints().smallDown;

//   const returnClass = isSmallDown
//     ? 'mobile'
//     : `${cs(
//       className,
//       { 'col-lg-12': type === 1 },
//       {
//         'col-lg-8': type === 2,
//         'col-lg-6': type === 3,
//         'col-lg-4': type === 4,
//         'col-lg-3': type === 5
//       }
//     )}`;

//   return (
//     <div className={returnClass}>
//       <LinkTo
//         className={cs(className)}
//         urlTo={url}
//         alt=""
//         openInNewTab={openInNewWindows}>
//         <div
//           className={cs(
//             'bannerPerse',
//             { vertical: !isXlDown && type === 3 },
//             { mini: isXlDown || type === 5 || type === 4 }
//           )}>
//           <div className="backgroundHolder">
//             <LazyImg src={image} alt={title} className="backgroundHolder" />
//           </div>
//           {icons && icons.length > 0 && (
//             <IconsHolder>
//               {icons.map((icon, index) => (
//                 <Icon key={index} name={icon} themeColor="orange" size={32} />
//               ))}
//             </IconsHolder>
//           )}

//           {!description && !price && title && <div className="fog" />}
//           {!description && !price && title && (
//             <div className="only-title">{title}</div>
//           )}
//           {(description || price) && (
//             <div
//               className="information-holder bottom"
//               style={{
//                 backgroundImage: `linear-gradient(${
//                   type === 3 ? 'to left' : 'to bottom'
//                 }, ${hexToRgb(shadowColor, 0)}, ${hexToRgb(shadowColor, 1)}`
//               }}>
//               {title && <div className="info-block title">{title}</div>}
//               {description && (
//                 <div
//                   className={cs('info-block', 'description', {
//                     'd-none d-md-none d-lg-block': price
//                   })}>
//                   {description}
//                 </div>
//               )}
//               {price && (
//                 <div className="info-block price">
//                   <div className="currency-box">
//                     <span>desde</span>
//                     <span className="currency">{currency}</span>
//                   </div>
//                   <div className="price">{price}</div>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//       </LinkTo>
//     </div>
//   );
// };

// Banner.propTypes = {
//   className: PropTypes.string.isRequired,
//   type: PropTypes.number,
//   description: PropTypes.string,
//   price: PropTypes.any,
//   title: PropTypes.string,
//   shadowColor: PropTypes.string,
//   currency: PropTypes.string,
//   image: PropTypes.string,
//   openInNewWindows: PropTypes.bool,
//   url: PropTypes.string,
//   icons: PropTypes.array
// };

// const IconsHolder = styled.div`
//   ${({ theme }) => css`
//     position: absolute;
//     display: flex;
//     background: ${theme.colors.white};
//     border-radius: 0 ${theme.borderRadius.default};
//     padding: ${theme.spacing.one} ${theme.spacing.four};
//     top: 0;
//     right: 0;
//     span {
//       margin-right: ${theme.spacing.four};

//       &:last-child {
//         margin-right: 0;
//       }
//     }
//   `}
// `;

// export default styled(Banner)`
//   .only-title {
//     width: 100%;
//     position: absolute;
//     text-align: center;
//     font-size: 70px;
//     line-height: 70px;
//     top: 50%;
//     margin-top: -35px;
//     padding: 7px;
//   }

//   .information-holder {
//     position: relative;
//     bottom: 0;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     padding-right: 5px;
//     padding-left: 5px;

//     @media screen and (min-width: 768px) {
//       min-height: 171px;
//     }

//     .info-block {
//       padding: 10px;

//       &.title {
//         font-size: 30px;
//         letter-spacing: 0.3px;
//         max-width: 273px;
//         padding-top: 25px;
//         line-height: 26px;
//         text-align: center;
//         &.mini {
//           font-size: 18px;
//         }
//       }

//       &.description {
//         font-size: 16px;
//         letter-spacing: 0.2px;
//         font-weight: 300;
//         font-style: normal;
//         font-stretch: normal;
//         max-width: 458px;
//         max-height: 80px;
//         text-align: justify;
//         line-height: 15px;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         padding: 0;
//       }

//       &.price {
//         display: flex;
//         flex-direction: row;

//         .currency-box {
//           display: flex;
//           flex-direction: column;
//           font-family: Montserrat, Arial, Helvetica, sans-serif;
//           font-size: 18px;
//           font-weight: 300;
//           line-height: 21px;
//           font-style: normal;
//           font-stretch: normal;
//           letter-spacing: 0.2px;
//           text-transform: uppercase;
//           padding: 0;
//           margin: 0;
//         }

//         .currency {
//           font-size: 29px;
//         }

//         .price {
//           font-size: 46px;
//           line-height: 46px;
//           margin-left: 9px;
//         }
//       }
//     }
//   }

//   .bannerPerse {
//     margin: 12px 0;
//     border-radius: ${({ theme }) => theme.borderRadius.default};
//     min-height: 248px;
//     color: white;
//     position: relative;
//     padding: 0;
//     overflow: hidden;
//     display: flex;
//     flex-direction: column-reverse;
//     font-family: Montserrat, Arial, Helvetica, sans-serif;

//     .backgroundHolder {
//       background-position: center;
//       background-size: cover;
//       position: absolute;
//       width: 100%;
//       height: 100%;

//       @media screen and (min-width: 768px) {
//         &:hover {
//           transform: scale(1.03);
//         }
//       }
//     }

//     &.vertical {
//       flex-direction: row;

//       .information-holder {
//         flex-direction: column;
//         max-width: 50%;
//       }
//     }

//     &.mini {
//       min-height: 248px;
//       .information-holder {
//         flex-direction: column;
//       }

//       .only-title {
//         font-size: 30px;
//         line-height: 50px;
//       }

//       @media screen and (max-width: 767px) {
//         min-height: 190px;
//       }
//     }
//   }

//   .fog {
//     position: absolute;
//     top: 0;
//     left: 0;
//     background: black;
//     width: 100%;
//     height: 100%;
//     opacity: 0.1;
//   }
// `;
