import {
  BlogArticle,
  BasicBlogArticle,
  BlogCategories
} from './../../Services/Blog/types';

export const initialState: BlogState = {
  articleLoading: true,
  articlesLoading: true,
  categoriesLoading: true,
  articleData: null,
  articles: [],
  latestArticles: [],
  categories: null
};

export interface BlogState {
  articleLoading: boolean;
  articlesLoading: boolean;
  categoriesLoading: boolean;
  articleData: null | BlogArticle;
  articles: BasicBlogArticle[];
  latestArticles: BasicBlogArticle[];
  categories: BlogCategories | null;
}
