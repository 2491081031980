/**
 *
 * ConnectedDestinationsTop
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';

import DestinationsTop from './DestinationsTop';
import { DestinationTopProps } from './types';
import { selectorDestinationsTop } from '../../selectors';

const ConnectedDestinationsTop = () => {
  const state: DestinationTopProps = useSelector(selectorDestinationsTop);

  return state.status === 'ready' ? <DestinationsTop {...state} /> : null;
};

export { DestinationsTop };
export default ConnectedDestinationsTop;
