/**
 *
 * HotelError
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import Button from 'Components/Button';
import ErrorGone from 'Components/ErrorGone';
import { useDispatch, useSelector } from 'react-redux';
// Todo handle errors in hotels
import { cleanError } from 'Features/hotels';

import useBreakpoints from 'Utils/hooks/useBreakpoints';

import SkyBackground from 'Components/SkyBackground';
import lodging from 'Assets/lodging.svg';
import { ERRORS_API } from 'Constants';

const HotelError = ({ className }) => {
  const isMediumDown = useBreakpoints().mediumDown;
  const dispatch = useDispatch();
  const error = useSelector(({ hotels }) => hotels.error);

  const defaulErrors = () => (
    <Text className="error-label" context="white" size="xlarge">
      <b>¡Ups!</b> ha surgido una escala no esperada, volvamos a empezar.
    </Text>
  );

  const errorsNotifications = error => {
    switch (error.statusCode) {
      case ERRORS_API.notFound: {
        return <ErrorNotFound />;
      }
      case ERRORS_API.gone: {
        return (
          <ErrorGone
            onClick={handleSearchAgain}
            iconName="mv-globo"
            title="Lo sentimos, el hotel no nos ha devuelto disponibilidad para sus habitaciones"
            subtitle="Por favor, volvé al listado a buscar tu hotel."
          />
        );
      }
      case ERRORS_API.unprocessableEntity: {
        return <ErrorUE />;
      }
      default:
        return defaulErrors();
    }
  };

  const ErrorNotFound = () => (
    <Text className="error-label" context="white" size="xlarge">
      <b>¡Ups!</b> {error.messages}
    </Text>
  );

  const ErrorUE = () => (
    <Text className="error-label" context="white" size="xlarge">
      <b>¡Ups!</b> por favor cambiar su busqueda, volvamos a empezar.
    </Text>
  );

  const handleSearchAgain = () => {
    dispatch(cleanError());
  };

  return (
    <div className={cs(className)}>
      <SkyBackground />
      {error &&
        (error.statusCode === ERRORS_API.notFound ||
          error.statusCode === ERRORS_API.unprocessableEntity) && (
        <div className="lodging">
          <img alt="lodging" src={lodging} />
        </div>
      )}
      {error && errorsNotifications(error)}
      {isMediumDown && (
        <div className="buttonHolder">
          <Button onClick={handleSearchAgain}>Buscar de Nuevo</Button>
        </div>
      )}
    </div>
  );
};

HotelError.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(HotelError)`
  .error-label {
    position: absolute;
    width: 50%;
    top: calc(50% + 135px);
    left: calc(50% - 380px);
  }

  .lodging {
    position: absolute;
    left: 0;
    right: 0;
    top: 70%;

    z-index: 1;

    img {
      width: 100%;
    }
  }

  .buttonHolder {
    position: absolute;
    top: 300px;
    left: calc(50% - 80px);
  }

  @media all and (max-width: 900px) {
    .lodging {
      top: 80%;
    }

    .buttonHolder {
      top: 220px;
    }
  }
`;
