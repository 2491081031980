const apiActionTypes = [
  'MPTB_AVAILABILITY',
  'MPC_AVAILABILITY',
  'PAYMENT_SIMULATOR',
  'PAYMENT_CHECKOUT',
  'CORPO_CHECKOUT',
  'CHECK_SELECTION',
  'CONFIRM_PAYMENT_STATUS',
  'GET_APP_CONFIGURATION',
  'BANNERS',
  'HOTELS_SEARCH',
  'HOTELS_PROPERTY',
  'CITIZEN',
  'COUNTRIES',
  'DESTINATION',
  'LOCALIZE',
  'OWN_FINANCING'
];
const actionTypes = {};
apiActionTypes.forEach(item => {
  actionTypes[`${item}_REQUEST`] = `${item}_REQUEST`;
  actionTypes[`${item}_SUCCESS`] = `${item}_SUCCESS`;
  actionTypes[`${item}_FAILURE`] = `${item}_FAILURE`;
});

export default {
  ...actionTypes,
  RESET_TO_ENGINE: 'RESET_TO_ENGINE',
  SEARCH_TYPE_FLIGHT: 'UI_SEARCH_TYPE_FLIGHT',
  SET_SEARCH_DIALOG_DETAIL_MODE: 'SET_SEARCH_DIALOG_DETAIL_MODE',
  AVAILABILITY_RESET_FILTERS: 'AVAILABILITY_RESET_FILTERS',
  CLEAN_ERROR: 'CLEAN_ERROR',
  UPDATE_LOADER: 'UPDATE_LOADER',
  OWN_FINANCING_RESET: 'OWN_FINANCING_RESET',
  SET_HIDE_NAVBAR: 'SET_HIDE_NAVBAR',
  SET_NAVBAR_SMALL_MODE: 'SET_NAVBAR_SMALL_MODE',
  STORAGE_USER_DATA: 'STORAGE_USER_DATA',
  STORAGE_CORPO_DATA: 'STORAGE_CORPO_DATA',
  RESET_USER_DATA: 'RESET_USER_DATA',
  RESET_CORPO_DATA: 'RESET_CORPO_DATA',
  SELECT_AVAILABILITY: 'SELECT_AVAILABILITY',
  SELECT_AVAILABILITY_CORPO: 'SELECT_AVAILABILITY_CORPO',
  CHECKOUT_STEP_FILL: 'CHECKOUT_STEP_FILL',
  CORPO_CHECKOUT_STEP_FILL: 'CORPO_CHECKOUT_STEP_FILL'
};
