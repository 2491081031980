// TODO: PROPOSE TO CHANGE IT TO RE-DUCKS

export const TOGGLE_TAC_MODAL = 'TOGGLE_TAC_MODAL';
export const TOGGLE_CALL_ME = 'TOGGLE_CALL_ME';
export const MOST_WANTED_PACKAGES_REQUEST = 'MOST_WANTED_PACKAGES_REQUEST';
export const MOST_WANTED_PACKAGES_SUCCESS = 'MOST_WANTED_PACKAGES_SUCCESS';
export const MOST_WANTED_PACKAGES_FAILURE = 'MOST_WANTED_PACKAGES_FAILURE';

export const initialState = {
  tacModal: false,
  callMeStatus: 'small',
  mostWantedPackages: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_TAC_MODAL:
      return {
        ...state,
        tacModal: !state.tacModal
      };

    case TOGGLE_CALL_ME: {
      const newValue =
        action.payload || state.callMeStatus === 'small' ? 'form' : 'small';
      return {
        ...state,
        callMeStatus: newValue
      };
    }
    case MOST_WANTED_PACKAGES_REQUEST:
      return {
        ...state,
        status: 'loading'
      };
    case MOST_WANTED_PACKAGES_SUCCESS:
      return {
        ...state,
        mostWantedPackages: action.payload,
        status: 'ready'
      };
    case MOST_WANTED_PACKAGES_FAILURE: {
      return {
        ...state,
        status: 'ready'
      };
    }

    default:
      return state;
  }
};

export const toggleTacModal = () => dispatch => {
  dispatch({
    type: TOGGLE_TAC_MODAL
  });
};

export const toggleCallMe = value => dispatch => {
  dispatch({ type: TOGGLE_CALL_ME, payload: value });
};
