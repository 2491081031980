/**
 *
 * MobileFlightDestinationSearch
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Input from 'Components/Input';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import * as COLORS from 'Constants/colors';
import { debounce } from 'lodash';

import API from '../../../../Services';
import { searchDebounceTime } from 'Constants';

const MobileFlightDestinationSearch = ({ className, actions, onClose }) => {
  const [state, setState] = useState({
    cities: [],
    airports: [],
    recentSearches: [],
    pending: false,
    hasSearched: false
  });

  const fetchSuggestions = async value => {
    setState(prevState => ({ ...prevState, pending: true }));
    const { data } = await API.flights.suggestions(value);
    setState(prevState => ({
      ...prevState,
      cities: data.cities,
      airports: data.airports,
      pending: false,
      openMenu: true,
      hasSearched: true
    }));
  };

  const debouncefetchSuggestions = debounce(
    fetchSuggestions,
    searchDebounceTime.DESKTOP
  );

  const handleChange = value => {
    if (value.length < 3) return;
    debouncefetchSuggestions(value);
  };

  const onSelectSuggestion = ({ code, name, cityKeyword }) => () => {
    actions.setLocation(code, name, cityKeyword);
    onClose();
  };

  const { cities, airports, pending, hasSearched, recentSearches } = state;

  const recentOptions = () => (
    <div className="section-wrap" key="recent-searches">
      <div className="modal-divider">
        <Icon className="mfd-icon" name="mv-search" />
        <Text className="modal-divider-text">Búsquedas Recientes</Text>
      </div>

      <div className="container-options">
        {!recentSearches.length && (
          <div key="none">
            <Text paragraph className="option-text">
              Aún no se realizaron búsquedas
            </Text>
          </div>
        )}

        {recentSearches.length > 0 &&
          recentSearches.map(value => (
            <div
              key={`${value.code}-${value.name}`}
              onClick={onSelectSuggestion(value)}>
              <Text paragraph className="option-text">
                {value.name}
              </Text>
            </div>
          ))}
      </div>
    </div>
  );

  const renderOptions = (values, title, iconName) => (
    <div className="section-wrap">
      <div className="modal-divider">
        <Icon className="mfd-icon" name={iconName} />
        <Text className="modal-divider-text">{title}</Text>
      </div>
      <div className="container-options">
        {values.map(value => (
          <div
            key={`${value.code}-${value.name}`}
            onClick={onSelectSuggestion(value)}>
            <Text paragraph className="option-text">
              {value.name}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );

  const emptyView = (
    <div className="container-options">
      <Text paragraph className="option-text">
        {pending &&
          `
          Estamos buscando tu destino.
        `}

        {!pending &&
          hasSearched &&
          `
          No hemos encontrado resultados que coincidan.
        `}
      </Text>
    </div>
  );

  const suggestionOptions = () => {
    if (!pending && cities.length > 0 && airports.length > 0) {
      return (
        <>
          {renderOptions(cities, 'Ciudades', 'mv-cities')}
          {renderOptions(airports, 'Aeropuertos', 'mv-plane-ida')}
        </>
      );
    }

    if (!pending && airports.length > 0) {
      return renderOptions(airports, 'Aeropuertos', 'mv-plane-ida');
    }

    return emptyView;
  };

  return (
    <div className={cs(className)}>
      <div className="top-bar">
        <Input
          onChange={handleChange}
          placeholder="Ingresa un destino"
          isLoading={pending}
          setFocus
        />

        {!pending && (
          <span className="top-bar-close" onClick={onClose}>
            <Icon name="mv-cross" />
          </span>
        )}
      </div>

      <div className="modal-options-content">
        {!hasSearched ? recentOptions() : suggestionOptions()}
      </div>
    </div>
  );
};

MobileFlightDestinationSearch.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  actions: PropTypes.shape({
    setLocation: PropTypes.func.isRequired
  })
};

export default styled(MobileFlightDestinationSearch)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 99999999;
  width: 100%;
  height: 100%;

  .modal-options-content {
    width: 100%;
    height: calc(100% - 62px);
  }

  .section-wrap {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
  }

  .modal-divider {
    display: flex;
    width: 100%;
    height: 41px;
    align-items: center;
    box-shadow: ${COLORS.shadows.one};
    background-color: ${COLORS.violeta};
  }

  .modal-divider-text {
    flex-grow: 2;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    text-align: left;
    margin-left: 20px;
    color: ${COLORS.white};
  }

  .mfd-icon {
    margin-left: 15px;
    font-size: 20px;
    color: ${COLORS.white};
  }

  .container-options {
    display: flex;
    position: relative;
    width: 100%;
    height: calc(100% - 41px);
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .option-text {
    display: flex;
    width: 100%;
    font-size: 16px;
    letter-spacing: 0.12px;
    color: ${COLORS.middleGrey};
    padding: 5px 5px 5px 10px;
    margin-left: 0;
    cursor: pointer;
  }

  .top-bar {
    display: flex;
    width: 100%;
    height: 62px;
    background-color: ${COLORS.white};

    .mv-input {
      display: flex;
      width: 100%;

      .mv-input-container {
        display: flex;
        width: 100%;
        border-radius: 0;
        box-shadow: none;

        .mv-input-holder {
          width: 100%;

          .input-mevuelo {
            margin: auto;
            font-size: 18px;
            font-style: italic;
            letter-spacing: 0.18px;
            color: ${COLORS.middleGrey};
          }
        }
      }
    }
  }

  .top-bar-close {
    display: flex;
    position: absolute;
    width: 10%;
    height: 62px;
    right: 0;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    color: ${COLORS.lightGrey};
    float: right;
  }
`;
