import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';

import { getArticles, getSearchArticles, getCategories } from '../../actions';
import BlogContainer from './BlogContainer';
import { RootState } from 'ReduxConfig/store';
import { routes } from 'Constants';

export type CategoryType = 'region' | 'tips' | 'tripStyle';

interface BlogRouteParams {
  category?: string;
  catType?: string;
}

const selector = ({ blog }: RootState) => ({
  articles: blog.articles,
  categories: blog.categories,
  articlesLoading: blog.articlesLoading,
  categoriesLoading: blog.categoriesLoading
});

const ConnectedComponent = props => {
  const dispatch = useDispatch();
  const actions = {
    getArticles: (category?: string) => {
      dispatch(getArticles(category));
    },
    getSearchArticles: (info: string) => {
      dispatch(getSearchArticles(info));
    },
    getCategories: () => {
      dispatch(getCategories());
    }
  };

  const state = useSelector(selector);
  const params = useParams<BlogRouteParams>();

  if (params.catType) {
    if (
      params.catType !== 'region' &&
      params.catType !== 'tips' &&
      params.catType !== 'tripStyle'
    ) {
      return <Redirect to={routes.blog.replace(':catType', 'tips')} />;
    }
  }

  return (
    <BlogContainer
      actions={actions}
      {...state}
      {...props}
      selectedCategory={params?.category}
      categoryType={params.catType}
    />
  );
};

export default ConnectedComponent;
