import Text from '../../../Components/Text';
import React from 'react';
import { Price } from '../styles';
import { PriceProps } from '../types';

const PackagePriceBarStyle = ({
  color,
  value,
  minMax,
  currency
}: PriceProps) => (
  <Price color={color} value={value} currency={currency} minMax={minMax}>
    <Text context="white" weight="bold">
      {value} {currency}
    </Text>
  </Price>
);

export default PackagePriceBarStyle;
