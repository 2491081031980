/**
 *
 * MobileOneWay
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMPC, setInitialDate } from '../../index';
import { setActive } from 'Features/destinations/actions';
import MobileOneWay from './MobileOneWay';

const selector = ({ flights, destinations }) => ({
  isMPC: flights.search.isMPC,
  knowDestination: destinations.active,
  initialDate: flights.search.routesData[0].initialDate,
  isCurrentViewDestinations: destinations.isCurrentView
});

const ConnectedMobileOneWay = props => {
  const dispatch = useDispatch();
  const actions = {
    toggleMPC: () => {
      dispatch(toggleMPC());
    },
    setKnowDestination: () => {
      dispatch(setActive(!state.knowDestination));
    },
    setInitialDate: date => {
      dispatch(setInitialDate(0, date));
    }
  };

  const state = useSelector(selector);

  return <MobileOneWay {...props} {...state} actions={actions} />;
};

export default ConnectedMobileOneWay;
export { MobileOneWay };
