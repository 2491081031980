import React from 'react';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Divider from 'Components/Divider';
import CardImage from 'Components/CardImage';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import Masonry from 'react-masonry-css';
import { IBanners } from 'Features/destinations/types';
import { returnCenterSlidePercent } from '../../helpers';

interface BannersProps<T extends IBanners> {
  items: T[];
  title: string;
  forceOneColumn?: boolean;
}

const ItemBox = styled.div`
  margin: 0 ${props => props.theme.spacing.two};
`;

const MasonryContainer = styled.div`
  .card-holder {
    display: flex;
    margin-left: -30px;
  }

  .card-holder_column {
    padding-left: 30px;
    background-clip: padding-box;
  }
`;

const StyledCarousel = styled(Carousel)`
  .carousel .slide {
    background: transparent;
  }
`;

const Banners = <T extends IBanners>({
  items,
  title,
  forceOneColumn
}: BannersProps<T>) => {
  const breakpoints = useBreakpoints();
  const isHorizontal =
    items.some(item => item.description?.length > 400 || items.length < 10) &&
    !breakpoints.mediumDown;
  return (
    <div>
      <Divider titleText={title} />
      {isHorizontal ? (
        <MasonryContainer>
          <Masonry
            className="card-holder"
            columnClassName="card-holder_column"
            breakpointCols={forceOneColumn ? 1 : breakpoints.largeDown ? 1 : 2}>
            {items?.map(item => (
              <ItemBox key={item.id}>
                <CardImage
                  horizontal
                  name={item.title || item.name}
                  description={item.description}
                  distance={item.distanceToDowntown}
                  imageUrl={item.photo.publicUrl}
                />
              </ItemBox>
            ))}
          </Masonry>
        </MasonryContainer>
      ) : (
        <StyledCarousel
          centerMode
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          centerSlidePercentage={returnCenterSlidePercent(breakpoints)}
          showThumbs={false}>
          {items?.map(item => (
            <ItemBox key={item.id}>
              <CardImage
                name={item.title || item.name}
                description={item.description}
                imageUrl={item.photo.publicUrl}
              />
            </ItemBox>
          ))}
        </StyledCarousel>
      )}
    </div>
  );
};

export default Banners;
