import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getArticleBySlug,
  getLatestArticles,
  getCategories,
  cleanArticle
} from '../../actions';
import ArticleContainer from './ArticleContainer';
import { useParams } from 'react-router';
import { RootState } from 'ReduxConfig/store';
import Spinner from 'Components/Spinner';

const selector = ({ blog }: RootState) => ({
  article: blog.articleData,
  latestArticles: blog.latestArticles,
  loading: blog.articleLoading
});

interface ArticleRouteParams {
  slug?: string;
}

const ConnectedComponent = props => {
  const dispatch = useDispatch();
  const actions = {
    getArticleBySlug: slug => {
      dispatch(getArticleBySlug(slug));
    },
    getLatestArticles: () => {
      dispatch(getLatestArticles());
    },
    getMostReadCategories: () => {
      dispatch(getCategories());
    }
  };
  const params = useParams<ArticleRouteParams>();

  const state = useSelector(selector);

  useEffect(() => {
    actions.getArticleBySlug(params.slug);
    actions.getLatestArticles();
    actions.getMostReadCategories();
    window.scrollTo(0, 0);
    return () => {
      dispatch(cleanArticle());
    };
  }, [params.slug]);

  return state.loading || !state.article ? (
    <Spinner block minHeight={500} />
  ) : (
    <ArticleContainer actions={actions} {...state} {...props} {...params} />
  );
};

export default ConnectedComponent;
