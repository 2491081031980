/**
 *
 * PageNotFound
 *
 */

// vebdir
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

// assests
import logo from 'Assets/logo_normal.svg';
import estrellas from './assets/Estrellas.svg';
import world from './assets/mv-world-cartoon.png';
import moon from './assets/mv-fullmoon_3.png';

// components
import Text from 'Components/Text';
import Button from 'Components/Button';

// Constants
import { routes } from 'Constants';
import { gradientHomeMulti } from 'Constants/colors';
import { cleanSearch } from 'Features/flights/actions';
import { cleanErrorBoundary } from 'Features/app/actions';

const PageNotFound = ({ className }) => {
  const dispatch = useDispatch();

  const goHome = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(cleanSearch());
    dispatch(push(routes.home));
    dispatch(cleanErrorBoundary());
  };

  return (
    <div className={cs(className)}>
      <img className="logo" src={logo} alt="logo" onClick={goHome} />
      <img className="stars" src={estrellas} alt="estrellas" />
      <div className="content-box">
        <Text context="white" size="xlarge">
          ¡Ups! ha surgido una escala no esperada, volvamos a empezar.
        </Text>
        <div className="buttons-holder">
          <Button context="information" onClick={goHome}>
            Ir al Inicio
          </Button>
          <Button
            // La forma que encontre es pasarle una funcion al onClick que levante un button por id que esta puesto en el index con display "none". No se si el lo mejor, revisarlo luego con Fede
            onClick={() => document.getElementById('errorBtn').click()}>
            Contactanos
          </Button>
        </div>
      </div>
      <img className="world" src={world} alt="world" />
      <img className="moon" src={moon} alt="moon" />
    </div>
  );
};

PageNotFound.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(PageNotFound)`
  background-image: ${gradientHomeMulti};
  position: fixed;
  width: 100%;
  min-height: 100%;
  z-index: 99;
  top: 0;

  .moon {
    margin: 0 auto;
    position: absolute;
    left: 50%;
    margin-left: -213px;
    bottom: -35px;
  }

  .world {
    position: absolute;
    right: 83px;
    top: 68px;
  }

  .buttons-holder {
    width: 308px;
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
  }

  .content-box {
    z-index: 1;
    position: relative;
    text-align: center;
    margin-top: 30px;
  }

  .logo {
    width: 232px;
    margin: 0 auto;
    position: relative;
    display: block;
    margin-top: 80px;
    z-index: 1;
  }

  .stars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
