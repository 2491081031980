/**
 *
 * HotelAvailability
 *
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { typeOfFareFilter, typeOfOrderPrice, ratingOptions } from 'Constants';
import HotelAvailabilityDesktop from '../HotelAvailabilityDesktop';
import HotelAvailabilityMobile from '../HotelAvailabilityMobile';
import { PaymentPromoBanner } from '../../../../Components/PaymentPromoBanner';

import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { Helmet } from 'react-helmet';

const HotelAvailability = () => {
  const isMediumDown = useBreakpoints().mediumDown;

  const { search, payload, filtersData } = useSelector(({ hotels }) => ({
    filtersData: hotels.filtersData,
    search: hotels.search,
    payload: hotels.results
  }));

  const {
    checkIn,
    checkOut,
    roomInformation,
    placeIdGeoLocation,
    radiusInMeters,
    location: { placeId, description: placeName },
    sessionId
  } = search;

  const { facilities, rangePrices, accommodations } = filtersData || {
    facilities: [],
    rangePrices: {},
    accommodations: []
  };
  const {
    minPerNight,
    maxPerNight,
    minPackagePrice,
    maxPackagePrice
  } = rangePrices || {
    minPerNight: 0,
    maxPerNight: 0,
    minPackagePrice: 0,
    maxPackagePrice: 0
  };
  let timer = null;
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [ratingFilter, setratingFilter] = React.useState(1);
  const [reviewFilter, setReviewFilter] = useState(ratingOptions[0]);
  const [fareType, setFareType] = React.useState(typeOfFareFilter.noche);
  const sliderMin = () =>
    fareType === typeOfFareFilter.noche ? minPerNight : minPackagePrice;
  const sliderMax = () =>
    fareType === typeOfFareFilter.noche ? maxPerNight : maxPackagePrice;
  const [fareRange, setFareRange] = React.useState([sliderMin(), sliderMax()]);
  const [searchNameValue, setSearchNameValue] = React.useState('');
  const [orderBy, setOrderBy] = React.useState(typeOfOrderPrice.byPriority);

  const { pathname } = useSelector(state => state.router.location);

  const handleSelectStartFilter = value => {
    setratingFilter(value);
  };

  const handleSelectFareType = value => {
    setFareType(value);
    setFareRange([sliderMin(), sliderMax()]);
  };

  const handleFareFilterChange = value => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setFareRange(value);
    }, 1000);
  };

  const handleFacilityFilterClick = facilityId => {
    const newSelectedFilters = [...selectedFilters];
    if (newSelectedFilters.includes(facilityId)) {
      setSelectedFilters(
        newSelectedFilters.filter(facility => facility !== facilityId)
      );
    } else {
      newSelectedFilters.push(facilityId);
      setSelectedFilters(newSelectedFilters);
    }
  };

  const handleCategoryFilterClick = categoryId => {
    const newCategories = [...selectedCategories];
    if (newCategories.includes(categoryId)) {
      setSelectedCategories(
        newCategories.filter(category => category !== categoryId)
      );
    } else {
      newCategories.push(categoryId);
      setSelectedCategories(newCategories);
    }
  };

  const handleReviewFilterClick = value => () => {
    setReviewFilter(value);
  };

  const handleSearchHotelNameChange = value => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchNameValue(value);
    }, 500);
  };
  // Apply Facility filters
  let response =
    selectedFilters.length > 0
      ? payload.filter(item => {
        for (let index = 0; index < selectedFilters.length; index++) {
          if (!item.filterFacilitiesIds.includes(selectedFilters[index])) {
            return false;
          }
        }
        return true;
      })
      : payload;
  
  const existSomeHotelWithReview = payload.some(item => item.reviews);
  // Apply Category filters

  if (selectedCategories.length) {
    response = payload.filter(item =>
      selectedCategories.includes(item.accommodationCode)
    );
  }
  // Apply Review Filter
  response = response.filter(item => {
    if (item.reviews) {
      return item.reviews.rating >= reviewFilter;
    }else return true;
  });
  // Apply Stars Filter
  response = response.filter(item => item.rating >= ratingFilter);

  // Apply Price Filter
  // TODO FIX PRICE FILTER
  // response = response.filter(item => {
  //   if (typeOfFareFilter.noche) {
  //     return (
  //       item.lowestPackagePerNightPrice >= fareRange[0] &&
  //       item.lowestPackagePerNightPrice <= fareRange[1]
  //     );
  //   } else {
  //     return (
  //       item.lowestPackagePrice >= fareRange[0] &&
  //       item.lowestPackagePrice <= fareRange[1]
  //     );
  //   }
  // });
  // Apply Hotel Name Filter
  response =
    searchNameValue.length > 0
      ? response.filter(
        item =>
          item.name
            .toLowerCase()
            .indexOf(searchNameValue.trim().toLowerCase()) > -1
      )
      : response;

  //Apply Order
  if (orderBy === typeOfOrderPrice.byPriority) {
    response.sort((b, a) => a.priority - b.priority);
  } else {
    response.sort(
      (a, b) => a.lowestPackagePerNightPrice - b.lowestPackagePerNightPrice
    );
  }

  const onSelectHotelProperty = hotelId => () => {
    const requestObject = {
      hotelId,
      sessionId,
      returnTaxAndFee: true,
      timeoutSeconds: 0,
      checkIn,
      checkOut,
      placeId,
      roomInformation: JSON.stringify(roomInformation)
    };
    const string = qs.stringify(requestObject);

    const newUrl = `${pathname}?${string}`;
    window.open(newUrl);
  };

  let totalPeople = 0;
  roomInformation.forEach(item => {
    totalPeople += item.adultsCount;
    totalPeople += item.kidsAges.length;
  });

  const [paginationPage, setPaginationPage] = React.useState(1);
  const [hasMoreOptions, setHasMoreOptions] = React.useState(true);
  const parseResponse = response => {
    const pageSize = 50;
    const result = response.slice(0, pageSize * paginationPage);
    if (response.length <= result.length && hasMoreOptions) {
      setHasMoreOptions(false);
    }
    return result;
  };

  const addPage = () => {
    setPaginationPage(paginationPage + 1);
  };

  const header = (
    <Helmet>
      <meta charSet="utf-8" />
      <title> {`Hoteles en ${placeName} | Elegí en MeVuelo`}</title>
      <meta
        name="description"
        content={`Hoteles baratos y ofertas en ${placeName}. MeVuelo tiene los mejores precios de hoteles para vos. Hoteles baratos por noche y más. Hoteles en ${placeName}.`}
      />
      <meta
        name="keywords"
        content={`hoteles baratos y ofertas, precios de hoteles, hoteles baratos por noche, hotles en ${placeName}`}
      />
      <meta
        property="og:title"
        content={`Hoteles en ${placeName} | Elegí en MeVuelo`}
      />
      <meta
        property="og:description"
        content={`Hoteles baratos y ofertas en ${placeName}. MeVuelo tiene los mejores precios de hoteles para vos. Hoteles baratos por noche y más. Hoteles en ${placeName}.`}
      />
    </Helmet>
  );

  if (!payload.length > 0 || !payload || !facilities) {
    return null;
  }

  return (
    <>
      {header}
      <PaymentPromoBanner />
      {isMediumDown ? (
        <HotelAvailabilityMobile
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          ratingFilter={ratingFilter}
          handleSelectStartFilter={handleSelectStartFilter}
          searchNameValue={searchNameValue}
          handleSearchHotelNameChange={handleSearchHotelNameChange}
          sliderMax={sliderMax}
          sliderMin={sliderMin}
          fareRange={fareRange}
          handleFareFilterChange={handleFareFilterChange}
          fareType={fareType}
          handleSelectFareType={handleSelectFareType}
          handleFacilityFilterClick={handleFacilityFilterClick}
          facilities={facilities}
          selectedFilters={selectedFilters}
          response={response}
          paginatedResponse={parseResponse(response)}
          totalPeople={totalPeople}
          onSelectHotelProperty={onSelectHotelProperty}
          accommodations={accommodations}
          handleCategoryFilterClick={handleCategoryFilterClick}
          selectedCategories={selectedCategories}
          currency={rangePrices.currency}
          handleReviewFilterClick={handleReviewFilterClick}
          reviewFilter={reviewFilter}
          ratingOptions={existSomeHotelWithReview?ratingOptions:[]}
          addPage={addPage}
          hasMoreOptions={hasMoreOptions}
          radiusInMeters={radiusInMeters}
          initialCenter={{
            lat: placeIdGeoLocation.latitude,
            lng: placeIdGeoLocation.longitude
          }}
        />
      ) : (
        <HotelAvailabilityDesktop
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          ratingFilter={ratingFilter}
          handleSelectStartFilter={handleSelectStartFilter}
          searchNameValue={searchNameValue}
          handleSearchHotelNameChange={handleSearchHotelNameChange}
          sliderMax={sliderMax}
          sliderMin={sliderMin}
          fareRange={fareRange}
          handleFareFilterChange={handleFareFilterChange}
          fareType={fareType}
          handleSelectFareType={handleSelectFareType}
          handleFacilityFilterClick={handleFacilityFilterClick}
          facilities={facilities}
          selectedFilters={selectedFilters}
          response={response}
          paginatedResponse={parseResponse(response)}
          totalPeople={totalPeople}
          onSelectHotelProperty={onSelectHotelProperty}
          addPage={addPage}
          hasMoreOptions={hasMoreOptions}
          handleReviewFilterClick={handleReviewFilterClick}
          reviewFilter={reviewFilter}
          ratingOptions={existSomeHotelWithReview?ratingOptions:[]}
          radiusInMeters={radiusInMeters}
          initialCenter={{
            lat: placeIdGeoLocation.latitude,
            lng: placeIdGeoLocation.longitude
          }}
        />
      )}
    </>
  );
};

export default HotelAvailability;
