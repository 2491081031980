import {
  RESET_USER_PASSWORD_FAILURE,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  SET_LOGGED_USER_SUCCESS,
  SET_LOGGED_USER_FAILURE,
  SET_LOGGED_USER_REQUEST,
  TUserActionTypes
} from './actionTypes';
import { initialState } from './constants';
import { IErrorResponse } from 'Services/Quotations/types';
import { ILoginResponse } from 'Services/Users/types';
import { TReducerStatus } from '../../typings/general';

export interface UserState {
  status: TReducerStatus;
  error?: IErrorResponse | null;
  user: ILoginResponse;
}

export function userReducer(
  state: UserState = initialState,
  action: TUserActionTypes
): UserState {
  switch (action.type) {
    case USER_LOGIN_FAILURE:
    case RESET_USER_PASSWORD_FAILURE:
      return {
        ...state,
        status: 'ready',
        error: action.payload
      };

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        status: 'ready',
        error: null
      };

    case RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        status: 'ready',
        error: null
      };

    case USER_LOGIN_REQUEST:
    case RESET_USER_PASSWORD_REQUEST:
      return {
        ...state,
        status: 'pending',
        error: null
      };

    case SET_LOGGED_USER_SUCCESS:
      return {
        ...state,
        status: 'ready',
        user: {
          ...state.user,
          user: action.payload
        }
      };

    case SET_LOGGED_USER_FAILURE:
      return {
        ...state,
        status: 'ready'
      };

    case SET_LOGGED_USER_REQUEST:
      return {
        ...state,
        status: 'pending'
      };

    default:
      return state;
  }
}

export default userReducer;
