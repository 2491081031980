import React from 'react';
import styled from 'styled-components';
import Divider from 'Components/Divider';
import DealButton from './DealButton';
/* import Button from 'Components/Button'; */
import { StatsFlightPrice } from 'Services/FlightsGraphql/types';
import { roundTripRequestUrl } from 'Features/flights/helpers';
import { routes } from 'Constants';

const ButtonsHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.five};
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonItemContainer = styled.div`
  margin: ${props => props.theme.spacing.four} 0;
`;

/* const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  a {
    width: 100%;
    max-width: 300px;
    text-decoration: none;
  }
`
   const PackageButton = styled(Button)`
  width: 100%;
`; */

export interface BestDealsProps {
  banners: StatsFlightPrice[];
  packageUrl?: string;
  name: string;
  destination: string;
}

const getSearchObject = ({
  destination,
  origin,
  dateFrom,
  dateTo
}: StatsFlightPrice) => ({
  adults: 1,
  cabinCode: 'M',
  children: [],
  groupResults: true,
  isMPC: true,
  type: 'ida-vuelta',
  routesData: [
    {
      destination: destination,
      origin: origin,
      initialDate: dateFrom,
      endDate: dateTo
    }
  ]
});

const getUrl = (state: StatsFlightPrice, destination: string): string => {
  const searchObj = getSearchObject(state);
  const urlPArams = roundTripRequestUrl(searchObj);

  return `${routes.flightSearch.replace(
    ':destination',
    destination
  )}${urlPArams}`;
};

const BestDeals = (props: BestDealsProps) => (
  <div>
    <Divider titleText={` Los mejores precios de vuelos a ${props.name}`} />
    <ButtonsHolder>
      {props.banners.map(banner => (
        <ButtonItemContainer key={banner.id}>
          <DealButton
            title={banner.month}
            price={banner.price}
            url={getUrl(banner, props.destination)}
          />
        </ButtonItemContainer>
      ))}
    </ButtonsHolder>
    {/* <ActionButtons>
      {props.packageUrl && (
        <a href={props.packageUrl} target="_blank" rel="noopener noreferrer">
          <PackageButton>Ver Paquetes</PackageButton>
        </a>
      )}
    </ActionButtons> */}
  </div>
);

export default BestDeals;
