import { IDestinationsReducer } from 'Features/destinations/types';

export const selectorDestinationsTop = ({
  destinations: { data, status }
}: {
  destinations: IDestinationsReducer;
}) => ({
  name: data.name && data.name,
  description: data.description && data.description,
  status
});

export const selectorDestinationsBottom = ({
  destinations: { data, status, flightsBanners }
}: {
  destinations: IDestinationsReducer;
}) => ({
  name: data.name && data.name,
  bestZones: data.bestZone && data.bestZone,
  airports: data.airports,
  timeToTravel: data.timeToTravel,
  temperature: {
    maxDay: data.maxTemperature,
    minNight: data.minTemperature,
    rainy: data.rainy,
    ocean: data.overseas
  },
  tips: data.tips,
  whatToDos: data.whatToDo,
  howToMove: data.howToMove,
  events: data.events,
  didYouKnows: data.didYouKnow,
  banners: data.banner,
  prices: {
    comboMeal: data.comboMeal,
    bigMac: data.bigMac,
    milk: data.milk,
    apples: data.apples,
    superMarketBeer: data.superMarketBeer,
    coke: data.coke,
    gas: data.gas,
    dinner: data.dinner,
    pub: data.pub,
    cocktail: data.cocktail,
    beer: data.beer,
    wine: data.wine,
    capuccino: data.capuccino,
    taxi: data.taxi,
    publicTransport: data.publicTransport,
    currency: data.currency
  },
  practicalInfo: {
    time: data.timeZone,
    currency: data.currency,
    is220: data.is220,
    is110: data.is110,
    isPlugTypeA: data.isPlugTypeA,
    isPlugTypeB: data.isPlugTypeB,
    isPlugTypeC: data.isPlugTypeC,
    isPlugTypeD: data.isPlugTypeD,
    isPlugTypeE: data.isPlugTypeE,
    isPlugTypeF: data.isPlugTypeF,
    isPlugTypeG: data.isPlugTypeG,
    isPlugTypeH: data.isPlugTypeH,
    isPlugTypeI: data.isPlugTypeI,
    isPlugTypeJ: data.isPlugTypeJ,
    isPlugTypeK: data.isPlugTypeK,
    isPlugTypeL: data.isPlugTypeL,
    areaCode: data.areaCode
  },
  packageUrl: data[getPackageUrlByEnv()],
  seasonsEneMar: data.seasonsEneMar,
  seasonsAbrJun: data.seasonsAbrJun,
  seasonsJulSet: data.seasonsJulSet,
  seasonsOctDic: data.seasonsOctDic,
  metaTitle: data.metaTitle,
  metaDescription: data.metaDescription,
  metaKeywords: data.metaKeywords,
  status,
  flightsBanners
});

type PackageUrl = 'uyPackageUrl' | 'pyPackageUrl';

const getPackageUrlByEnv = (): PackageUrl => {
  const countryCode = process.env.REACT_APP_COUNTRY;
  const urlNames = {
    UY: 'uyPackageUrl',
    PY: 'pyPackageUrl'
  };
  return urlNames[countryCode];
};
