/**
 *
 * Modal
 *
 */

import React, { memo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import Card from '../Card';

import { usePortal } from '../../Utils/hooks/index';
import { white } from 'Constants/colors';
import Icon from '../Icon';

const Modal = memo(
  ({
    className,
    children,
    header,
    show,
    onClickOverlay,
    closeButton,
    transparent,
    headerCentered,
    whiteBackground,
    noCard,
    isSecondModal,
    size
  }) => {
    const rootElement = usePortal();

    const onClose = event => {
      if (
        typeof onClickOverlay === 'function' &&
        event.target.classList.contains('modal-overlay')
      ) {
        onClickOverlay(event);
      }
    };

    /*
     * Using useEffect to remove scrolling in the entire app while
     * Modal any modal component is showing.
     */

    useEffect(() => {
      document.body.classList.toggle('non-scroll', Boolean(show));
      // Fixes some cases where this toggle does not fire on unmount
      return () => {
        document.body.classList.remove('non-scroll');
      };
    }, [show]);

    if (noCard) {
      return createPortal(
        <div
          onClick={onClose}
          className={cs(className, { show }, 'modal-overlay', {
            'is-second-modal': isSecondModal,
            [`is-${size}`]: size,
            'no-card': noCard
          })}>
          <div className="modal-box">
            {closeButton && (
              <div className="no-card-header">
                <span className="no-card-header__content">{header}</span>

                <button
                  onClick={closeButton.onClick}
                  className="no-card-header__action">
                  <Icon name="mv-cross" color="white" />
                </button>
              </div>
            )}

            <div className="no-card-body">{children}</div>
          </div>
        </div>,
        rootElement
      );
    }

    return createPortal(
      <div
        onClick={onClose}
        className={cs(
          className,
          { show },
          { whiteBackground },
          { 'close-is-outside': closeButton && closeButton.isOutsideModal },
          { [`is-${size}`]: size },
          { 'is-second-modal': isSecondModal },
          'modal-overlay'
        )}>
        {!transparent && (
          <Card
            header={header || null}
            className="modal-box"
            onClose={closeButton && closeButton.onClick}
            headerCentered={headerCentered}>
            {children}
          </Card>
        )}
        {transparent && children}
      </div>,
      rootElement
    );
  }
);

Modal.propTypes = {
  className: PropTypes.string.isRequired,
  header: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClickOverlay: PropTypes.func,
  transparent: PropTypes.bool,
  headerCentered: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
  noCard: PropTypes.bool,
  isSecondModal: PropTypes.bool,
  closeButton: PropTypes.shape({
    isOutsideModal: PropTypes.bool,
    onClick: PropTypes.func.isRequired
    // Add any other shape prop here.
  })
};

export default styled(Modal)`
  display: none;

  &.no-card {
    .modal-box {
      .no-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${white};

        &__content {
          font-weight: 500;
        }

        &__action {
          border: none;
          background-color: transparent;
        }
      }

      .no-card-body {
        max-height: 85vh;
        border-radius: 14px;
        overflow-y: auto;
      }
    }
  }

  &.show {
    display: flex;
    z-index: ${({ theme }) => theme.zIndex.modal};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-content: center;
    overflow-x: auto;

    .modal-box {
      width: 590px;
      max-width: 95%;
      align-self: center;
      z-index: 999;
      margin: 0 auto;
      height: auto !important;

      .mv-card-content {
        max-height: 85vh;
        overflow-y: auto;
      }
    }

    &.is-second-modal {
      z-index: 999999999999999 !important;
    }

    &.whiteBackground {
      background-color: ${white};
    }

    &.is-small {
      .modal-box {
        width: 400px;
      }
    }

    &.is-large {
      .modal-box {
        width: 750px;
      }
    }
  }

  &.close-is-outside {
    .header-action {
      color: red;
    }
  }
`;
