import styled from 'styled-components';

export const TopContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TopLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(49, 23, 75, 0.5);
  padding: 95px 32px 32px 32px;
  border-radius: 0 0 14px 14px;
  max-width: 562px;
  margin-bottom: 48px;

  h4 {
    margin: ${props => props.theme.spacing.two} 0;
  }

  @media all and (max-width: 768px) {
    padding: 10px;
    margin: 0;
  }

  &__description {
    text-align: justify;

    @media all and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const MakeCall = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 357px;
  margin: auto;
  align-items: center;
  margin-top: 20px;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
`;

export const PartnersHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 49px;
`;

export const CardInformationContainer = styled.div`
  width: 200px;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  &:first-child {
    margin-right: ${({ theme }) => theme.spacing.eight};
  }
`;

export const CardInformationHeader = styled.div`
  height: 30px;
  background-color: ${({ theme }) => theme.colors.otroVioleta};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardInformationContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.four};
  text-align: center;
`;

export const Section = styled.div`
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const InfoCard = styled.div`
  max-width: 568px;
  padding: ${({ theme }) => theme.spacing.four};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  margin: 50px auto;
`;

export const InfoCardTopText = styled.div`
  color: ${({ theme }) => theme.colors.newBackground};
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

export const ProductsHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
`;

export const PhoneHolder = styled.div`
  display: flex;
  margin-top: ${props => props.theme.spacing.four};
`;

export const PhoneItem = styled.div`
  width: 98%;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.newBackground};

  img {
    margin-right: ${props => props.theme.spacing.two};
  }
`;
