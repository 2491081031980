/**
 *
 * MeVueloStepper
 *
 */

import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MUIStepper from '@material-ui/core/Stepper';
import MUIStep from '@material-ui/core/Step';
import MUIStepButton from '@material-ui/core/StepButton';
import MUIStepLabel from '@material-ui/core/StepLabel';
import { withStyles } from '@material-ui/core/styles';

import * as colors from 'Constants/colors';
import Text from '../Text';

const styles = () => ({
  stepper: {},
  step: {
    display: 'flex',
    '&:focus-visible': {
      border: 0
    }
  },
  baloon: {
    width: 58,
    height: 58,
    background: colors.lightGrey,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    outline: 'none'
  },
  baloonMobile: {
    width: 29,
    height: 29,
    background: colors.lightGrey,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    outline: 'none'
  },
  stepAllowed: {
    background: colors.violeta
  },
  stepActive: {
    background: colors.important,
    color: colors.white,
    border: `3px solid ${colors.important}`
  },
  stepLabel: {
    flexDirection: 'column-reverse',
    position: 'relative',
    color: colors.lightGrey
  },
  cajaGlobo: {
    color: colors.darkBlue,
    position: 'absolute',
    fontSize: 25,
    top: 50,
    left: 10
  },
  cajaGloboMobile: {
    color: colors.darkBlue,
    position: 'absolute',
    fontSize: 16,
    top: 22,
    left: 0
  }
});

const Stepper = ({ classes, steps, activeStep, onStepClick, stepsAllowed }) => {
  const isActiveStep = step => activeStep === step;
  const handleOnClick = index => () => {
    onStepClick(index);
  };

  const mobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className="mv-stepper">
      <MUIStepper
        nonLinear
        classes={{ root: classes.stepper }}
        activeStep={activeStep}>
        {steps.map((step, index) => (
          <MUIStep key={index} classes={{ root: classes.step }}>
            <MUIStepButton
              classes={{ root: classes.step }}
              onClick={handleOnClick(index)}
              disableRipple
              icon={
                <div
                  className={cs(
                    mobile ? classes.baloonMobile : classes.baloon,
                    {
                      [classes.stepAllowed]: stepsAllowed.includes(index),
                      [classes.stepActive]: isActiveStep(index)
                    }
                  )}>
                  <Text size="xlarge" context="white">
                    {index + 1}
                  </Text>
                </div>
              }>
              {mobile ? null : (
                <MUIStepLabel
                  classes={{
                    completed: classes.stepLabel,
                    root: classes.stepLabel,
                    active: classes.stepLabelActive
                  }}>
                  <Text
                    context={
                      // TODO: PLEASE, FIX THIS LATER WHEN MAKE THE 2.0 VERSION
                      isActiveStep(index)
                        ? 'important'
                        : stepsAllowed.includes(index)
                          ? 'primary'
                          : 'default'
                    }
                    weight="bold">
                    {step}
                  </Text>
                </MUIStepLabel>
              )}
            </MUIStepButton>
          </MUIStep>
        ))}
      </MUIStepper>

      {mobile && (
        <div
          style={{
            textAlign: 'center',
            color: colors.important,
            fontWeight: 'bold'
          }}>
          {steps[activeStep]}
        </div>
      )}
    </div>
  );
};

Stepper.propTypes = {
  classes: PropTypes.object,
  steps: PropTypes.array,
  activeStep: PropTypes.number,
  onStepClick: PropTypes.func,
  stepsAllowed: PropTypes.array
};

const StyledStepper = withStyles(styles)(Stepper);

export default StyledStepper;
