/**
 *
 * LinkTo
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

const LinkTo = ({ className, children, urlTo, alt, openInNewTab = false }) => {
  const target = openInNewTab ? { target: '_blank' } : {};

  return (
    <a className={cs(className)} href={urlTo} alt={alt} {...target}>
      {children}
    </a>
  );
};

LinkTo.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.any,
  urlTo: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool
};

export default styled(LinkTo)`
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;
