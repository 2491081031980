import React from 'react';
import styled from 'styled-components';
import Heading from 'Components/Heading';
import Icon, { TIconName } from 'Components/Icon';

const IconHolder = styled.div`
  min-width: 46px;
  border-radius: 50%;
  box-shadow: ${props => props.theme.shadows.two};
  display: flex;
  justify-content: center;
  align-content: center;
`;

const Container = styled.div`
  width: 320px;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.smooth};
  box-shadow: ${props => props.theme.shadows.two};
  padding: ${props => props.theme.spacing.two}
    ${props => props.theme.spacing.four};
  margin-bottom: 15px;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: inherit;
`;

export interface IsuranceInfoCardProps {
  title: string;
  iconName: TIconName;
}

const IsuranceInfoCard = (props: IsuranceInfoCardProps) => (
  <Container>
    <IconHolder>
      <Icon color="important" size={32} name={props.iconName} />
    </IconHolder>
    <TextContainer>
      <Heading type="h5" color="wine" fontWeight="semibold">
        {props.title}
      </Heading>
    </TextContainer>
  </Container>
);

export default IsuranceInfoCard;
