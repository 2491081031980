// Vendors
import { cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
// Actions
import { hideNavbar, updateLoader } from 'Features/app/actions';
import { getCitizens, getCountries } from 'ReduxConfig/places';
import {
  fillCheckoutSteps,
  getOwnFinancingText,
  paymentCheckout,
  paymentSimulator,
  resetOwnFinancing,
  resetUserData,
  storageUserData
} from 'ReduxConfig/checkout';

// Constants
import {
  ATC_STATUS,
  BANCARD_STATUS,
  engineCodes,
  engines,
  requestErrors,
  routes,
  SIEMPRE_PAGO_STATUS,
  urlBankcard,
  urlSiemprePago
} from 'Constants';

// Utils
import { isMobile } from 'Utils';
import { arrangeParams } from 'Utils/checkout';
import invokeLibrary from 'Utils/invokeLibrary';
import {
  bancardIframeGenerator,
  getPaymentSimulator,
  siemprePagoCheckout
} from './helpers';

// Actions
import { toggleTacModal } from 'Features/common';

const Proxi = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userData, availability, engine, tacModal, hotels } = useSelector(
    ({ checkout, availability, hotels, common }) => ({
      userData: checkout.requestData,
      availability: checkout.selectedAvailability,
      engine: availability.engine,
      steps: checkout.steps,
      tacModal: common.tacModal,
      hotels: {
        ...hotels,
        availability: checkout.selectedAvailability
      }
    })
  );
  const pagoWoompi = async params => {
    try {
      const result = await dispatch(
        paymentCheckout(engineCodes.hoteles, params)
      );
      const {
        reference,
        currency,
        publicKey,
        amountInCents,
        integritySignature,
        customerData
      } = result;
      const processPaymentRouteWithProccesId = routes.processPayment.replace(
        ':processId',
        reference
      );
      const processPaymentRoute = processPaymentRouteWithProccesId.replace(
        ':engine',
        'hotels'
      );
      if (publicKey) {
        // eslint-disable-next-line no-undef
        const woompiClient = new WidgetCheckout({
          currency,
          amountInCents,
          redirectUrl: `${process.env.REACT_APP_SITE_URL}${processPaymentRoute}`,
          reference: String(reference),
          publicKey,
          signature: {
            integrity: integritySignature,
          },
          customerData: {
            email: customerData.email,
            fullName: customerData.fullName,
            phoneNumber: customerData.phoneNumber,
            phoneNumberPrefix: customerData.phoneNumberPrefix,
            legalId: customerData.legalId,
            legalIdType: customerData.legalIdType
          }
        });
        woompiClient.open(function() {
          history.push(processPaymentRoute);
        });
      }
    } catch (error) {
      console.error('Error en el pago de', error);
    }
  };

  const runProcess = () => {
    const params = arrangeParams(
      { checkout: userData, availability, hotels },
      engine
    );

    switch (engine) {
      case 'vuelos':
      case 'vuelosMPC':
        if (userData.paymentMethod.payment === 'wireTransferPayment') {
          dispatch(getOwnFinancingText(engineCodes.vuelos, 1));
        }

        dispatch(paymentCheckout(engineCodes.vuelos, params))
          .then(res => {
            dispatch(
              storageUserData({
                paymentType: userData.paymentMethod
              })
            );

            dispatch(push(`/checkout/${res.reserveCode}/thank-you`));
          })
          .catch(handleError);

        break;

      case 'hotel-details':
      case engines.HOTELS.name:
        if (
          process.env.REACT_APP_WOMPI_STATUS === 'On' &&
          userData.paymentMethod.payment === 'creditCardPayment'
        ) {
          dispatch(paymentCheckout(engineCodes.hoteles, params));
          pagoWoompi(params);
        } else if (
          BANCARD_STATUS === 'On' &&
          userData.paymentMethod.payment === 'creditCardPayment'
        ) {
          dispatch(paymentCheckout(engineCodes.hoteles, params))
            .then(res => {
              // BANCARD
              invokeLibrary(urlBankcard)
                .then(() => {
                  dispatch(updateLoader(false));
                  bancardIframeGenerator(res);
                })
                .catch(() => {
                  updateLoader(false);
                  toast.error(requestErrors.default);
                });
            })
            .catch(handleError);
        } else if (
          ATC_STATUS === 'On' &&
          userData.paymentMethod.payment === 'creditCardPayment'
        ) {
          dispatch(paymentCheckout(engineCodes.hoteles, params))
            .then(res => {
              const routing = routes;
              dispatch(push(routing.checkoutAtcIniPayment));
            })
            .catch(handleError);
        } else if (
          SIEMPRE_PAGO_STATUS === 'On' &&
          userData.paymentMethod.payment === 'creditCardPayment'
        ) {
          dispatch(updateLoader(true));
          //SIEMPAGO
          invokeLibrary(urlSiemprePago)
            .then(() => {
              dispatch(updateLoader(false));
              const checkout = siemprePagoCheckout(userData, isMobile);

              checkout.Bind('tokenCreated', token => {
                dispatch(
                  paymentCheckout(engineCodes.hoteles, {
                    ...params,
                    paymentInfo: {
                      ...params.paymentInfo,
                      tokenSP: {
                        token: token.TokenId,
                        targetType: token.Brand
                      }
                    }
                  })
                )
                  .then(res => {
                    switch (
                      res.paymentResponse.transaction.TransactionStatusId
                    ) {
                      case 1:
                        dispatch(
                          storageUserData({
                            ...userData,
                            paymentType: userData.paymentMethod,
                            passengers: [userData.holder],
                            gateway: {
                              name: 'SIEMPRE PAGO'
                            }
                          })
                        );

                        dispatch(
                          push(`/checkout/${res.reserveCode}/thank-you`)
                        );

                        break;

                      case 4:
                        toast.error(requestErrors.default);
                        break;

                      default:
                        break;
                    }
                  })
                  .catch(handleError);
              });
            })
            .catch(() => {
              updateLoader(false);
              toast.error(requestErrors.default);
            });
        } else {
          //SI NO VA A NINGUNA PASARELA DE PAGOS
          if (userData.paymentMethod.payment === 'wireTransferPayment') {
            dispatch(getOwnFinancingText(engineCodes.hoteles, 1));
          }

          dispatch(paymentCheckout(engineCodes.hoteles, params))
            .then(res => {
              dispatch(
                storageUserData({
                  ...userData,
                  paymentType: userData.paymentMethod.payment
                })
              );

              dispatch(push(`/checkout/${res.reserveCode}/thank-you`));
            })
            .catch(handleError);

          break;
        }
        break;

      default:
      // Any other payment
    }
  };

  const handleTacModal = () => {
    dispatch(toggleTacModal());
  };

  const handleError = err => {
    if (err.response) {
      return toast.error(err.response.data.message || requestErrors.default);
    }

    toast.error(requestErrors.default);
  };

  useEffect(() => {
    if (availability) {
      dispatch(paymentSimulator(getPaymentSimulator(availability, engine)));
    }
    dispatch(getCitizens());
    dispatch(getCountries({ size: -1, page: 0 }));
    dispatch(updateLoader(false));

    dispatch(resetOwnFinancing());
  }, [availability, engine, dispatch]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });

    dispatch(fillCheckoutSteps(engine));
    dispatch(resetUserData());
    dispatch(hideNavbar(true));
    return () => {
      dispatch(hideNavbar(false));
    };
  }, []);

  return cloneElement(children, {
    runProcess,
    dispatch,
    availability,
    engine,
    handleTacModal,
    tacModal,
    userData,
    hotelProperty: hotels
  });
};

Proxi.propTypes = {
  children: PropTypes.element.isRequired
};

export default Proxi;
