import React from 'react';
import styled from 'styled-components';
import Heading from 'Components/Heading';
import Icon from 'Components/Icon';

const CheckoutContentHolder = styled.div`
  background-color: ${props => props.theme.colors.white};
  position: relative;
  margin: 0;
  padding-bottom: 100px;
`;

const CheckoutHeader = styled.div`
  height: 64px;
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing.four}
    ${props => props.theme.spacing.two};
  box-shadow: ${props => props.theme.shadows.two};
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MobileCheckout = ({ children }: { children: React.ReactNode }) => (
  <CheckoutContentHolder>
    <CheckoutHeader>
      <Heading color="newBackground" fontWeight="medium">
        Completá tus datos y finalizá tu compra
      </Heading>
      <Icon name="mv-cross" color="newBackground" size={28} />
    </CheckoutHeader>
    {children}
  </CheckoutContentHolder>
);

export default MobileCheckout;
