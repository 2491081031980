/**
 *
 * MobilePaxDetailAirCard
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';
import MobilePaxDetailAirCard from './MobilePaxDetailAirCard';

const selector = ({ flights }) => ({
  adults: flights.search.adults,
  kids: flights.search.children,
  cabinCode: flights.search.cabinCode
});

const ConnectedMobilePaxDetailAir = props => {
  const state = useSelector(selector);

  return <MobilePaxDetailAirCard {...state} {...props} />;
};

export default ConnectedMobilePaxDetailAir;
export { MobilePaxDetailAirCard };
