import { BlogCategories, BlogCategory } from 'Services/Blog/types';
import slugify from 'slugify';
import { formatLong } from 'Utils/dates';
import { routes } from 'Constants';

export const returnArticleUrl = (url: string): string =>
  routes.blogArticle.replace(':slug', url);

const getAllCategories = (categories: BlogCategories): BlogCategory[] => [
  ...categories.region,
  ...categories.tips,
  ...categories.tripStyle
];

export const returnCreatedDate = createDate => {
  const [year, month, day] = createDate.substr(0, 10).split('-');
  return createDate && formatLong(new Date(year, month - 1, day));
};

export const getCategoryNameBySlug = (
  slug: string,
  categories: BlogCategories
) => {
  const allCategories = getAllCategories(categories);
  const category = allCategories.find(
    category => slugify(category.name).toLowerCase() === slug
  );
  if (category) return category.name;
};
