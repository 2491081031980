import { IQuotationFull } from 'Services/Quotations/types';
import { ICountry } from './types';

export const getInitialValues = (detail: IQuotationFull | null) =>
  !detail
    ? {
      title: '',
      name: '',
      email: '',
      phone: '',
      country: {} as ICountry,
      clientQuote: '',
      backlogQuote: '',
      passengers: 1
    }
    : {
      title: detail?.title,
      name: detail?.clientName,
      email: detail?.clientEmail,
      phone: detail?.phoneNumber,
      country: { name: detail?.country, id: 999 } as ICountry,
      clientQuote: detail?.clientNotes,
      backlogQuote: detail?.internalNotes,
      passengers: detail?.totalPassenger
    };
