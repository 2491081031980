import React from 'react';

import Icon from 'Components/Icon';
import Button from 'Components/Button';

import {
  Body,
  Header,
  Wrapper,
  Code,
  Title,
  Actions,
  Days,
  Content,
  Services,
  Flights,
  Item,
  Hotels
} from './styles';

import { QuotationsAvailabilityCardProps } from './types';

const QuotationsAvailabilityCard = ({
  id,
  title,
  flights,
  hotels,
  services,
  nights,
  onDelete,
  onCopy
}: QuotationsAvailabilityCardProps) => (
  <Wrapper>
    <Header>
      <Code>{id}</Code>

      <Title>{title}</Title>
    </Header>

    <Body>
      <Content>
        {flights && (
          <Flights>
            {flights.map(f => (
              <Item key={f.id}>
                <Icon name="mv-plane-ida" color="important" />
                <span>{f.ida}</span>
                {/* TODO: ADD ROUND TRIP ICON */}
                <Icon name="mv-plane-vuelta" color="important" />
                <span>{f.vuelta}</span>
              </Item>
            ))}
          </Flights>
        )}

        <Hotels>
          <Icon name="mv-hotel" color="important" />
          <span>x{hotels}</span>
        </Hotels>

        <Services>
          {/* TODO: ADD PROPER TICKET ICON */}
          <Icon name="mv-flight-voucher" color="important" />
          <span>x{services}</span>
        </Services>

        <Days>
          <span>{nights} noches</span>
        </Days>
      </Content>

      <Actions>
        {/* TODO: REMOVE WHEN BUTTON IS TYPED
        //@ts-ignore */}
        <Button context="white" onClick={onCopy}>
          <Icon name="mv-details" color="information" />
        </Button>

        {/* TODO: REMOVE WHEN BUTTON IS TYPED
        //@ts-ignore */}
        <Button context="white" onClick={onDelete}>
          <Icon name="trash" color="warning" />
        </Button>
      </Actions>
    </Body>
  </Wrapper>
);

export default QuotationsAvailabilityCard;
