import ACTION_TYPES from './actionTypes';
import { engines, flightSearchTypes, getInitialStates } from 'Constants';

const initialState = {
  searchDialogDetailMode: false,
  // For Menu
  engine: engines.FLIGHTS.name,
  // For Search Flight tabs
  typeFlight: flightSearchTypes[0].id,
  status: 'unused',
  resetFilters: false,
  data: {
    request: getInitialStates(flightSearchTypes[0].id)
  },
  checkSelection: '',
  pending: false,
  errorBoundaryKey: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.RESET_TO_ENGINE:
      return {
        ...initialState,
        engine: action.payload
      };

    default:
      return state;
  }
};

export const resetToEngine = engine => {
  window.scrollTo(0, 0);
  return {
    type: ACTION_TYPES.RESET_TO_ENGINE,
    payload: engine
  };
};
