import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { parse } from 'query-string';

import { confirmPaymentStatus } from '../../ReduxConfig/checkout';
import TextHeader from '../../Components/TextHeader';
import Text from '../../Components/Text';

const BancardProcessPayment = ({ match, location }) => {
  const { status } = parse(location.search, { ignoreQueryPrefix: true });
  const [response, setResponse] = useState({});
  const { pending } = useSelector(({ checkout }) => ({
    pending: checkout.pending
  }));
  const dispatch = useDispatch();

  // TODO: MAKE ENGINE THING DYNAMIC
  useEffect(() => {
    dispatch(confirmPaymentStatus(6, match.params.processId)).then(res =>
      setResponse(res)
    );
  }, []);

  return (
    <Wrapper>
      {!pending && response.responseCode !== '00' && status === 'payment_fail' && (
        <div className="content-pay">
          <Text>Ups... algo salio mal.</Text>
          <small>Error: {response.description}</small>
        </div>
      )}

      {!pending && response.responseCode === '00' && (
        <div className="content-pay">
          <TextHeader size="xl" context="white">
            ¡El pago fue exitoso!
          </TextHeader>

          <Text context="white">
            {' '}
            Reservamos: <b>{response.reserveInfo.productInfo}</b>{' '}
          </Text>

          <br />

          <Text context="white">
            {' '}
            A nombre de: <b>{response.reserveInfo.titularFullName}</b>{' '}
          </Text>

          <br />

          <Text context="white">
            Este es tu codigo de reserva:{' '}
            <b>{response.reserveInfo.reserveCode}</b>
          </Text>
          <br />
        </div>
      )}

      {pending && <span>Espera! Estamos procesando tu pago...</span>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 25px;
    font-weight: bold;
    color: white;
  }

  .content-pay {
    margin-top: 70px;
    color: white;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  small {
    color: white;
  }
`;

BancardProcessPayment.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default BancardProcessPayment;
