/**
 *
 * BannersContainer
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Divider from 'Components/Divider';

import { useDispatch, useSelector } from 'react-redux';

import { engineCodes } from 'Constants';
import { bannersRequest } from '../../actions';
import Banner from '../Banner';
//import { returnImage } from 'Utils';

const useBannerReducer = () =>
  useSelector(({ banners }) => ({
    sections: banners.data && banners.data.sections && banners.data.sections
  }));

const BannersContainer = ({ className, engine }) => {
  const dispatch = useDispatch();
  const { sections } = useBannerReducer();

  const requestBanners = () => {
    const bannerCode = engine ? engineCodes[engine] : engineCodes.home;
    dispatch(bannersRequest(bannerCode));
  };

  useEffect(() => {
    const bannerCode = engine ? engineCodes[engine] : engineCodes.home;
    dispatch(bannersRequest(bannerCode));
  }, [engine]);

  return (
    <div className={cs(className)} onClick={requestBanners}>
      {sections &&
        sections &&
        sections.map(sectionItem => (
          <div className="container section-holder" key={sectionItem.id}>
            <Divider
              key={sectionItem.id}
              titleText={sectionItem.title}
              subtitleText={sectionItem.header}
              size="default"
              context="default"
            />

            {sectionItem.sectionLines.map((sectionLineItem, index) => (
              <div className="row" key={index}>
                {sectionLineItem.banners.map(bannerItem => (
                  <Banner
                    key={bannerItem.id}
                    //image={returnImage(bannerItem.image, bannerItem.imageWebp)}
                    image={bannerItem.imageWebp}
                    title={bannerItem.title}
                    price={bannerItem.price}
                    currency="USD"
                    description={bannerItem.subtitle}
                    type={bannerItem.bannerType}
                    shadowColor={bannerItem.shadowColor}
                    openInNewWindows={bannerItem.openInNewWindows}
                    url={bannerItem.url}
                    icons={bannerItem.icons}
                  />
                ))}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

BannersContainer.propTypes = {
  className: PropTypes.string.isRequired,
  engine: PropTypes.any
};

export default styled(BannersContainer)`
  display: 'block';
  .section-holder {
    margin-top: 40px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    @media screen and (max-width: 768px) {
      flex-wrap: nowrap;
      overflow-x: scroll;
    }
  }
  .mobile {
    @media screen and (max-width: 768px) {
      margin-top: 15px;
      margin-left: 15px;
      margin-bottom: 15px;
      min-width: 85%;
      :first-child {
        margin-left:20px;
      };
      :last-child {
        margin-right:20px;
      }
    }
  }
`;
