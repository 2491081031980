/**
 *
 * SliderArrows
 *
 */

import React from 'react';
import cs from 'classnames';
import styled from 'styled-components';
import Icon from '../Icon';
import { SliderArrowsProps } from './types';

const SliderArrows = ({
  to,
  color = 'white',
  size = 58,
  direction = 'mv-arrow-right',
  onClick,
  packagesArrows = false,
  className
}: SliderArrowsProps) => (
  <button
    type="button"
    onClick={onClick}
    className={cs(`button button--text button--icon ${className} ${to}`, {
      packagesArrows
    })}
    aria-label={to}>
    <Icon name={direction} color={color} size={size} />
  </button>
);

export default styled(SliderArrows)`
  &.packagesArrows {
    position: absolute;
    top: ${props => (props.isMobile ? 28 : 28)}px !important;
  }
`;
