import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';
import ErrorBoundary from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';

// Pages
import ThankYou from 'Containers/ThankYou';
import Checkout from 'Containers/Checkout';
import ProcessPayment from 'Containers/ProcessPayment';

// Components
import Loader from '../Loader';
import Footer from '../Footer';
import NavBar from '../NavBar';
import Backgrounds from '../Backgrounds';
import PageNotFound from '../PageNotFound';
import HomeMultiCountry from 'Components/HomeMultiCountry';
import WhoWeAre from 'Components/WhoWeAre';
import BlogContainer from 'Features/blog/components/BlogContainer';
import ArticleContainer from 'Features/blog/components/ArticleContainer';
import PackageContainer from 'Features/packages/components/PackageContainer';
import PackageThankyou from 'Features/packages/components/PackageThankyou';
import FlightsHome from 'Features/flights/components/FlightsHome';
import Proxi from 'Containers/Checkout/Proxi';
import HotelsHome from 'Features/hotels/components/HotelsHome';
import PackagesHome from 'Features/packages/components/PackagesHome';
import Login from 'Features/users/components/Login';
import Logout from 'Features/users/components/Logout';
import Reset from 'Features/users/components/Reset';
import QuotationsHome from 'Features/quotations/components/QuotationsHome';
import QuotationDetails from 'Features/quotations/components/QuotationDetails';
import NewCheckout from 'Features/checkout/components/HomeCheckout';
import TravelInsuranceHome from 'Features/travelInsurance/components/TravelInsuranceHome';
import { CallMe } from 'Components/CallMe';

// History
import { history } from 'ReduxConfig/store';

// Constants
import { ogLanguage, routes } from 'Constants';
import { getAppConfiguration } from '../../actions';
import PrivateRoute from '../PrivateRoute';
import DestinationsHome from 'Features/destinations/components/DestinationsHome';
import ManaguaHome from 'Features/managuaTemporary/components/ManaguaHome';
import LogoBackground from 'Assets/images/mv-logo-background.jpg';
import ProxyCorpo from '../../../../Containers/CheckoutCorpo/ProxyCorpo';
import CheckoutCorpo from '../../../../Containers/CheckoutCorpo';
import ThankYouCorpo from '../../../../Containers/ThankYouCorpo';
import AtcInitPayment from '../../../../Containers/AtcInitPaymentForm';
import AtcCheckPayment from '../../../../Containers/AtcCheckPayment';
import GatewaySuccessTransaction from '../../../../Containers/GatewaySuccessTransaction';
import GatewayCancelTransaction from '../../../../Containers/GatewayCancelTransaction';
import { LGPromo } from 'Features/lgPromo';

Modal.setAppElement('#root');

const routing = routes;

// These breakpoints are deprecated and only used for react-socks
const breakpoints = [
  { xsmall: 0 },
  { small: 540 },
  { medium: 768 },
  { large: 960 },
  { xl: 1140 }
];

const selector = ({ appConfiguration: { loader, errorBoundaryKey } }) => ({
  loader,
  errorBoundaryKey
});

const App = () => {
  const { loader, errorBoundaryKey } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAppConfiguration());
  }, []);

  setDefaultBreakpoints(breakpoints);

  return (
    <ConnectedRouter history={history}>
      <ErrorBoundary FallbackComponent={PageNotFound} key={errorBoundaryKey}>
        {loader && <Loader />}
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            MeVuelo | Promociones en paquetes vacacionales, vuelos baratos
            internacionales, hoteles baratos y ofertas de viaje.
          </title>
          <meta
            name="description"
            content="¡Vuelos baratos internacionales, Hoteles baratos y ofertas, promociones en Paquetes vacacionales a los mejores destinos! Los mejores hoteles All Inclusive, Pagos en cuotas Fácil y Seguro online o por teléfono."
          />
          <meta
            name="keywords"
            content="hoteles, viajes on line, hoteles baratos, vuelos baratos internacionales, pasajes aereos, pasajes baratos, hospedaje, promociones en paquetes vacacionales, autos, seguro de viaje, cruceros, disney, alojamiento barato, viajes, promociones aereas, viajes en cuotas, excursiones"
          />
          <meta property="og:image" content={LogoBackground} />
          <meta property="og:image:width" content="400" />
          <meta property="og:site_name" content="mevuelo.com" />
          <meta property="og:title" content="mevuelo.com" />
          <meta
            property="og:description"
            content="¡Vuelos baratos internacionales, Hoteles baratos y ofertas, promociones en Paquetes vacacionales a los mejores destinos! Los mejores hoteles All Inclusive, Pagos en cuotas Fácil y Seguro online o por teléfono."
          />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content={ogLanguage} />
        </Helmet>
        <Switch>
          <Route path="/404" component={PageNotFound} />
          <BreakpointProvider>
            {process.env.REACT_APP_IS_GLOBAL === 'On' ? (
              <HomeMultiCountry />
            ) : (
              <>
                <Backgrounds />
                <NavBar />
                <Switch>
                  {/*HOME*/}

                  <Route
                    exact
                    path={routing.lgPromo}
                    component={LGPromo}></Route>
                  <Route
                    exact
                    path={routing.managua}
                    component={ManaguaHome}></Route>
                  <Route
                    exact
                    path={routing.travelInsurance}
                    component={TravelInsuranceHome}></Route>
                  <Route
                    exact
                    path={routing.corporate}
                    component={() => {
                      window.location.href = 'https://corporativo.mevuelo.com';
                      return null;
                    }}></Route>
                  <Route exact path={routing.home} component={FlightsHome} />

                  {/*BLOG*/}
                  <Route exact path={routing.blog} component={BlogContainer} />
                  <Route
                    path={routing.blogCategory}
                    component={BlogContainer}
                  />
                  <Route
                    path={routing.blogArticle}
                    component={ArticleContainer}
                  />

                  {/*CHECKOUT*/}
                  <Route
                    exact
                    path={routing.checkoutCancelPayment}
                    component={GatewayCancelTransaction}
                  />
                  <Route
                    exact
                    path={routing.checkoutSuccessPayment}
                    component={GatewaySuccessTransaction}
                  />
                  <Route
                    exact
                    path={routing.checkoutAtcIniPayment}
                    component={AtcInitPayment}
                  />
                  <Route
                    path={routing.checkoutAtcCheckPayment}
                    component={AtcCheckPayment}
                  />
                  <Route exact path={routing.thankYou} component={ThankYou} />
                  <Route
                    exact
                    path={routing.checkout}
                    render={props => (
                      <Proxi>
                        <Checkout {...props} />
                      </Proxi>
                    )}
                  />
                  <Route
                    exact
                    path={routing.processPayment}
                    component={ProcessPayment}
                  />
                  <Route path={routing.newCheckout} component={NewCheckout} />
                  {/*CHECKOUT CORPO*/}
                  <Route
                    exact
                    path={routing.thankYouCorpo}
                    component={ThankYouCorpo}
                  />
                  <Route
                    exact
                    path={routing.checkoutCorpo}
                    render={props => (
                      <ProxyCorpo>
                        <CheckoutCorpo {...props} />
                      </ProxyCorpo>
                    )}
                  />

                  {/*WHO WE ARE*/}
                  <Route exact path={routing.whoWeAre} component={WhoWeAre} />

                  {/*MOTORES*/}
                  <Route
                    path={routing.packageItem}
                    component={PackageContainer}
                  />
                  <Route path={routing.package} component={PackagesHome} />
                  <Route
                    exact
                    path={routing.packageThankyou}
                    component={PackageThankyou}
                  />

                  <Route path={routing.flight} component={FlightsHome} />
                  {/* <Route
                    path={routing.flightMultiDestination}
                    component={MainSearch}
                  /> */}
                  <Route
                    exact
                    path={routing.flightSearch}
                    component={FlightsHome}
                  />

                  <Route path={routing.hotel} component={HotelsHome} />
                  <Route
                    exact
                    path={routing.hotelSearch}
                    component={HotelsHome}
                  />
                  <Route path={routing.login} component={Login} />
                  <Route path={routing.logout} component={Logout} />
                  <PrivateRoute
                    redirectTo="/"
                    path={routing.resetPassword}
                    component={Reset}
                  />

                  <PrivateRoute
                    redirectTo="/"
                    path={routing.quotations}
                    component={QuotationsHome}
                    exact
                  />

                  <PrivateRoute
                    redirectTo="/"
                    exact
                    path={routing.quotationsDetails}
                    component={QuotationDetails}
                  />

                  {/* AUTO SEARCH PER DESINATION*/}
                  <Route
                    exact
                    path={routing.destination}
                    component={DestinationsHome}
                  />

                  {/*NOT FOUND*/}

                  <Route component={PageNotFound} />
                </Switch>
                <Footer />
              </>
            )}
          </BreakpointProvider>
        </Switch>
        <CallMe />
      </ErrorBoundary>
    </ConnectedRouter>
  );
};

export default App;
