import React from 'react';

import Text from '../../Components/Text';

import { getLastFour, getFormatPrice } from '../../Utils';
import { getHours, addHours, parse } from 'date-fns';
import { formatLongWithTime } from '../../Utils/dates';

export const getTotalPrice = (selectedAvailability, isLocal = false) => {
  switch (selectedAvailability.engine) {
    case 'vuelos':
    case 'vuelosMPC':
      return isLocal && selectedAvailability.localPrice
        ? getFormatPrice(
          selectedAvailability.localPrice.totalPrice,
          selectedAvailability.localPrice.currency,
          true
        )
        : getFormatPrice(selectedAvailability.flightPrice.totalPrice, 'USD');

    case 'hotel-details':
    case 'hoteles':
      return isLocal
        ? getFormatPrice(
          selectedAvailability.hotelInformation.localPrice.finalPrice,
          selectedAvailability.hotelInformation.localPrice.currency,
          true
        )
        : getFormatPrice(
          selectedAvailability.hotelInformation.packagePrice.finalPrice,
          selectedAvailability.hotelInformation.packagePrice.currency
        );

    default:
      // Any other case
      return;
  }
};

export const getPaymentSpeech = (userData, ticket) => {
  switch (userData.paymentMethod.payment) {
    case 'wireTransferPayment': {
      return (
        <Text paragraph>
          Pago con <b>transferencia bancaria</b> a banco{' '}
          <b>{userData.wireTransferPayment.bank.name}</b>
          <br />
          Número de cuenta: <b>{userData.wireTransferPayment.accountNumber}</b>
          <br />
          Beneficiario: <b>{userData.wireTransferPayment.accountHolder}</b>
        </Text>
      );
    }
    case 'creditCardPayment': {
      if (userData.creditCardPayment.cardInfo.cardNumber) {
        return (
          <Text paragraph>
            Pago con tarjeta{' '}
            <b>{userData.creditCardPayment.checkedCard.card.name}</b> terminada
            en{' '}
            <b>{getLastFour(userData.creditCardPayment.cardInfo.cardNumber)}</b>
          </Text>
        );
      }

      return (
        <Text paragraph>
          Pago a través de pasarela de pago {userData.gateway.name}
        </Text>
      );
    }
    case 'paymentNetwork': {
      return (
        <Text paragraph>
          Para pagar tu reserva presentate en{' '}
          <b>{userData.paymentNetwork.name}</b> con tu número de ticket {ticket}
          .
        </Text>
      );
    }
    case 'branchOffices': {
      return (
        <Text paragraph>
          Sucursal: <b>{userData.branchOffices.name}</b>
          <br />
          Direccion: <b>{userData.branchOffices.address}</b>
          <br />
          Teléfono: <b>{userData.branchOffices.phoneNumber}</b>
          <br />
        </Text>
      );
    }

    default: {
      return null;
    }
  }
};

export const transferInfo = (descriptionTimeLimit, expirationInfo) => {
  let limit = '';
  if (descriptionTimeLimit) {
    if (expirationInfo.expirationType === 1) {
      const today = new Date();
      const hoursToAdd = getHours(
        parse(expirationInfo.time, 'HH:mm:ss', new Date())
      );
      const expiryDate = addHours(today, hoursToAdd);
      limit = formatLongWithTime(expiryDate);
    }
    if (expirationInfo.expirationType === 2) {
      limit = expirationInfo.time;
    }
    return descriptionTimeLimit.replace('{TIME_LIMIT_TOKEN}', limit);
  }
};
