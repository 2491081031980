/**
 *
 * TravelInformation
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import FlightItineraryDetails from '../../Features/flights/components/FlightItineraryDetails';
import Card from '../Card';
import HotelSelectFare from '../../Features/hotels/components/HotelSelectFare';

const TravelInformation = ({ availability, className }) => {
  const { engine, selectedSegment } = availability;

  if (engine === 'hotel-details') {
    return (
      <div className={cs(className)}>
        <img
          style={{ maxWidth: '100%' }}
          src={availability.hotels.property.images[0].imageUrl}
          alt="imagen hotel"
        />
        <HotelSelectFare
          className="fare"
          price={availability.hotelInformation.packagePrice.finalPrice}
          details={availability.hotelInformation.details}
          currency={availability.hotelInformation.packagePrice.currency}
          localCurrency={
            availability.hotelInformation.localPrice &&
            availability.hotelInformation.localPrice.currency
          }
          localPrice={
            availability.hotelInformation.localPrice &&
            availability.hotelInformation.localPrice.finalPrice
          }
          isCheckout
        />
      </div>
    );
  }

  return (
    <>
      <Card header="Detalle de ida" context="default" borderRadius="square">
        <div
          className={`content no-padding margin background-white ${className}`}>
          <FlightItineraryDetails
            baggage={selectedSegment.ida.item.baggage}
            duration={selectedSegment.ida.item.duration}
            scales={selectedSegment.ida.item.scales}
            segments={selectedSegment.ida.item.segments}
          />
        </div>
      </Card>

      {selectedSegment.vuelta.item && (
        <Card
          header="Detalle de vuelta"
          context="default"
          borderRadius="square">
          <div
            className={`content no-padding margin background-white ${className}`}>
            <FlightItineraryDetails
              baggage={selectedSegment.vuelta.item.baggage}
              duration={selectedSegment.vuelta.item.duration}
              scales={selectedSegment.vuelta.item.scales}
              segments={selectedSegment.vuelta.item.segments}
            />
          </div>
        </Card>
      )}
    </>
  );
};

TravelInformation.propTypes = {
  availability: PropTypes.object,
  className: PropTypes.string
};

export default styled(TravelInformation)`
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 0 auto;
  flex-direction: column;

  .fare {
    margin-top: 20px;
  }
`;
