// vendor
import React from 'react';
import styled from 'styled-components';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

// components
import PackageSearch from '../PackageSearch';
import PackageAvalability from '../PackageAvailability';
import { PaymentPromoBanner } from '../../../../Components/PaymentPromoBanner';

const PackagesHome = () => {
  const { mediumDown } = useBreakpoints();

  return (
    <>
      <SearchWrapper isMobile={mediumDown}>
        <PackageSearch />
      </SearchWrapper>
      <PaymentPromoBanner />
      <PackageAvalability />
    </>
  );
};

export default PackagesHome;

const SearchWrapper = styled.div`
  margin: 0 auto;
  max-width: 1100px;
  ${({ theme, isMobile }) => {
    if (!isMobile) {
      const margin = theme.spacing.four;
      return `
        margin-top: ${margin};
        margin-bottom: ${margin}
      `;
    }
  }}
`;
