/**
 *
 * HotelInvoiceForm
 *
 */

// Vendors
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

// Components
import Input from 'Components/Input';
import Select from 'Components/Select';

// Utils
import { formErrors, formValidators } from 'Constants';
import { getPhonesCodes, getCountriesCodes, existInLocalize } from 'Utils';

import { useValidatedForm } from 'Utils/hooks/useValidatedForm';
import { emailPattern } from 'Constants/regex';
import { defaultBillingDocumentType } from 'Features/checkout/constants';

const HotelInvoiceForm = ({
  className,
  actions,
  places,
  checkout,
  localize,
  documentTypes,
  phoneTypes,
  onValid,
  userData
}) => {
  const phoneCodes = useMemo(() => getPhonesCodes(places), [places]);

  const countryCodes = useMemo(() => getCountriesCodes(places), [places]);

  const { fields, errors, handleChange, handleValidate } = useValidatedForm({
    fields: {
      ...checkout.requestData.invoice,
      ...checkout.requestData.holder.holders.find(holder => holder.isHolder),
      documentType: defaultBillingDocumentType,
      country:
        countryCodes.find(
          code => code.id === existInLocalize(localize, 'countryCode')
        ) || {},
      code:
        phoneCodes.find(
          code => code.id === existInLocalize(localize, 'phoneCode')
        ) || {},
      city: existInLocalize(localize, 'city'),
      state: existInLocalize(localize, 'city'),
      phoneType: { id: 1 }
    },
    errors: {
      firstName: [formErrors.lessThanOne, formErrors.nonNumbers],
      lastName: [formErrors.lessThanOne, formErrors.nonNumbers],
      phone: formErrors.nonLetters,
      email: formErrors.wrongEmail,
      confirmEmail: formErrors.wrongEmail,
      document: formErrors.nonSymbols,
      city: formErrors.nonNumbers,
      state: formErrors.nonNumbers,
      dir: formErrors.lessThanFour
    },
    validators: {
      firstName: firstName => [firstName.length < 1, /\d/.test(firstName)],
      lastName: lastName => [lastName.length < 1, /\d/.test(lastName)],
      phone: phone => /\D/.test(phone),
      email: email => !emailPattern.test(email),
      confirmEmail: email => !emailPattern.test(email),
      document: formValidators.nonSymbols,
      city: city => /\d/.test(city),
      state: state => /\d/.test(state),
      dir: dir => dir.length < 4
    }
  });

  const handleBlur = () => {
    actions.setUserData({
      invoice: {
        ...userData.invoice,
        ...fields
      }
    });
  };

  useEffect(() => {
    actions.setUserData({
      invoice: {
        ...fields,
        ...checkout.requestData.invoice,
        ...checkout.requestData.holder
      }
    });
  }, []);

  useEffect(() => {
    onValid(handleValidate());
  }, [fields]);

  return (
    <div className={cs(className)}>
      <div className="fields">
        <Input
          isRequired
          label="Nombre"
          placeholder="Nombre"
          className="grid-2"
          value={fields.firstName}
          onChange={handleChange('firstName')}
          message={errors.firstName}
          context={errors.firstName && 'warning'}
          onBlur={handleBlur}
        />

        <Input
          isRequired
          placeholder="Apellido"
          label="Apellido"
          className="grid-2"
          onChange={handleChange('lastName')}
          value={fields.lastName}
          message={errors.lastName}
          context={errors.lastName && 'warning'}
          onBlur={handleBlur}
        />

        <Select
          isRequired
          label="Teléfono"
          className="grid-1"
          selectedValue={fields.phoneType && fields.phoneType.id}
          onSelect={handleChange('phoneType')}
          items={phoneTypes}
        />

        <Select
          isRequired
          label="Código de país"
          className="grid-1"
          selectedValue={fields.code && fields.code.id}
          onSelect={handleChange('code')}
          items={phoneCodes}
        />

        <Input
          isRequired
          className="grid-2"
          placeholder="Ej: 123456789"
          label="Número"
          value={fields.phone}
          message={errors.phone}
          context={errors.phone && 'warning'}
          onChange={handleChange('phone')}
          onBlur={handleBlur}
        />

        <Input
          isRequired
          label="Email"
          placeholder="Ej: acme@mevuelo.com"
          className="grid-4"
          value={fields.email}
          message={errors.email}
          context={errors.email && 'warning'}
          onChange={handleChange('email')}
          onBlur={handleBlur}
        />

        <Input
          isRequired
          label="Confirma tu Email"
          placeholder="Ej: acme@mevuelo.com"
          className="grid-4"
          value={fields.confirmEmail}
          message={errors.confirmEmail}
          context={errors.confirmEmail && 'warning'}
          onChange={handleChange('confirmEmail')}
          onBlur={handleBlur}
        />

        <Select
          isRequired
          className="grid-2"
          label="Tipo de Documento"
          selectedValue={fields.documentType && fields.documentType.id}
          onSelect={handleChange('documentType')}
          items={documentTypes}
        />

        <Input
          isRequired
          label="Número de Documento"
          placeholder="Ej: 123456789"
          className="grid-2"
          value={fields.document}
          onChange={handleChange('document')}
          context={
            (!fields.document && 'information') ||
            (errors.document && 'warning')
          }
          message={!fields.document ? formErrors.needToFill : errors.document}
          onBlur={handleBlur}
        />

        <Select
          isRequired
          label="País"
          className="grid-1"
          selectedValue={fields.country && fields.country.id}
          items={countryCodes}
          onSelect={handleChange('country')}
        />

        <Input
          isRequired
          label="Ciudad"
          placeholder="Ciudad"
          className="grid-2"
          value={fields.city}
          context={errors.city && 'warning'}
          message={errors.city}
          onBlur={handleBlur}
          onChange={handleChange('city')}
        />

        <Input
          isRequired
          label="Estado"
          placeholder="Estado"
          className="grid-1"
          value={fields.state}
          context={errors.state && 'warning'}
          message={errors.state}
          onBlur={handleBlur}
          onChange={handleChange('state')}
        />

        <Input
          isRequired
          label="Dirección"
          placeholder="Ej: AVELLANEDA 534"
          className="grid-4"
          value={fields.dir}
          context={(!fields.dir && 'information') || (errors.dir && 'warning')}
          message={!fields.dir ? formErrors.needToFill : errors.dir}
          onChange={handleChange('dir')}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};

HotelInvoiceForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  phoneTypes: PropTypes.array,
  documentTypes: PropTypes.array,
  checkout: PropTypes.object,
  places: PropTypes.object,
  actions: PropTypes.object,
  userData: PropTypes.object,
  onValid: PropTypes.func,
  localize: PropTypes.func
};

export default styled(HotelInvoiceForm)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .fields {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px 12px;
    margin-bottom: 16px;
    width: 90%;

    .grid-1 {
      grid-column: 1 span;
    }

    .grid-2 {
      grid-column: 2 span;
    }

    .grid-3 {
      grid-column: 3 span;
    }

    .grid-4 {
      grid-column: 4 span;
    }

    .right {
      grid-column: 4;
    }

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;

      > div {
        margin-bottom: 12px;
      }
    }
  }
`;
