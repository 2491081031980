import { defaultIda, defaultVuelta } from './helpers';

export const selector = ({ checkout, places, appConfiguration }) => ({
  citizens: places.citizen,
  countries: places.countries,
  localize: appConfiguration.localize,
  passengers: checkout.requestData.passengers,
  genders: appConfiguration.sharedConfig.genders,

  flightVuelta:
    (checkout.selectedAvailability &&
      checkout.selectedAvailability.selectedSegment &&
      checkout.selectedAvailability.selectedSegment.vuelta.item &&
      checkout.selectedAvailability.selectedSegment.vuelta.item
        .departureData) ||
    defaultVuelta,

  flightIda:
    (checkout.selectedAvailability &&
      checkout.selectedAvailability.selectedSegment &&
      checkout.selectedAvailability.selectedSegment.ida.item.departureData) ||
    defaultIda
});
