import React from 'react';
import { format } from 'date-fns';

import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Input from 'Components/NewInput';
import Heading from 'Components/Heading';
import PlusMinusSelector from 'Components/PlusMinusSelector';
import Select from 'Components/NewSelect';
import Label from 'Components/Label';

import { QuotationsGeneralProps, ICountry } from './types';

import {
  Header,
  History,
  Actions,
  Body,
  Container,
  ID,
  Passengers
} from './styles';

export const handlePassenger = (
  dir: string = null,
  onChange: QuotationsGeneralProps['onChange'],
  passengers: number | string
) => (): void => {
  const currentPassengers: number = Number(passengers);

  const i = dir === 'back' ? currentPassengers - 1 : currentPassengers + 1;

  onChange('passengers')(String(i));
};

const QuotationsGeneral = ({
  onSubmit,
  onChange,
  onDownload,
  onRefresh,
  fields,
  errors,
  createdBy,
  createdIn,
  id,
  countries
}: QuotationsGeneralProps) => (
  <Container>
    <Header>
      <Heading fontWeight="semibold" color="violet" type="h3">
        General
      </Heading>
      {createdBy && createdIn && (
        <History>
          <span>Creado por:</span> {createdBy}, <span>el:</span>{' '}
          {format(createdIn, 'yyyy-mm-dd')}
        </History>
      )}

      <Actions>
        <Button onClick={onDownload}>
          <Icon name="download" color="white" size={28} />
        </Button>

        <Button onClick={onRefresh}>
          {/* TODO: ADD PROPER ICON WHEN IS READY */}
          <Icon name="mv-arrow-faster" color="white" size={28} />
        </Button>

        <Button onClick={onSubmit}>
          {/* TODO: ADD PROPER ICON WHEN IS READY */}
          <Icon name="mv-arrow-faster" color="white" size={28} />
        </Button>
      </Actions>
    </Header>

    {id && <ID>{id}</ID>}

    <Body>
      <div className="full">
        <Label color="violet">Titulo:</Label>
        <Input
          placeholder="Titulo"
          value={fields.title}
          onChange={e => onChange('title')(e.target.value)}
          error={{ message: errors.title }}
        />
      </div>

      <div className="one">
        <Label color="violet">Nombre del cliente:</Label>
        <Input
          placeholder="Nombre del cliente:"
          value={fields.name}
          onChange={e => onChange('name')(e.target.value)}
        />
      </div>

      <div className="one">
        <Label color="violet">Correo electrónico:</Label>
        <Input
          placeholder="email@mevuelo.com"
          value={fields.email}
          onChange={e => onChange('email')(e.target.value)}
        />
      </div>

      <Passengers>
        <Label color="violet">CANTIDAD DE PASAJEROS:</Label>

        {/* TODO: REMOVE WHEN IS TYPED
           // @ts-ignore */}
        <PlusMinusSelector
          stateless
          onIncrement={handlePassenger(null, onChange, fields.passengers)}
          onDecrement={handlePassenger('back', onChange, fields.passengers)}
          value={fields.passengers}
        />
      </Passengers>

      <div className="one">
        <Label color="violet">Teléfono:</Label>
        <Input
          placeholder="Telefono:"
          value={fields.phone}
          onChange={e => onChange('phone')(e.target.value)}
        />
      </div>

      <div className="one">
        <Label>País:</Label>
        <Select<ICountry>
          items={countries}
          itemToString={item => item.name}
          label="Seleccione un país"
          onItemSelected={onChange('country')}
        />
      </div>

      <div className="full">
        <Label color="violet">Nota para el cliente:</Label>
        <Input
          placeholder="Nota para el cliente"
          value={fields.clientQuote}
          onChange={e => onChange('clientQuote')(e.target.value)}
        />
      </div>

      <div className="full">
        <Label color="violet">Nota interna:</Label>
        <Input
          placeholder="Nota interna"
          value={fields.backlogQuote}
          onChange={e => onChange('backlogQuote')(e.target.value)}
        />
      </div>
    </Body>
  </Container>
);

export default QuotationsGeneral;
