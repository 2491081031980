/**
 *
 * HotelCheckoutBreakdown
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import moment from 'moment';

import Text from 'Components/Text';
import Card from 'Components/Card';
import MobileHotelCard from '../MobileHotelCard';
import { isoDateToString, getFormatPrice } from 'Utils';
import Badge from 'Components/Badge';

const HotelCheckoutBreakdown = ({
  className,
  packageRoom,
  request,
  facilities,
  address,
  starRating,
  keyRating,
  textRating,
  propertyName,
  thumbnail,
  isAllInclusive,
  reviews,
  checkIn,
  checkOut,
  rateChanged
}) => {
  const totalPeople =
    packageRoom.peopleByRoom[0].adults + packageRoom.peopleByRoom[0].kids;
  const days = moment(request.checkOut).diff(moment(request.checkIn), 'days');

  return (
    <div className={cs(className)}>
      {/* 
      // TODO
      // @ts-ignore */}
      <Card
        header="Detalle de la Reserva"
        context="arena"
        headerCentered
        boxShadow>
        <div className="column">
          <Text weight="bold">Fecha de la reserva:</Text>
          <Text weight="semi-bold" context="important">
            Inicia: <Text>{`${isoDateToString(request.checkIn)}`}</Text>
          </Text>
          <Text weight="semi-bold" context="important">
            Finaliza: <Text>{`${isoDateToString(request.checkOut)}`}</Text>
          </Text>
        </div>
        <hr />
        <div className="column">
          <Text weight="bold">Registrarse:</Text>
          <div>
            <Text weight="semi-bold" context="important">
              Horario de Check In:
            </Text>{' '}
            <Text>a partir de las {checkIn}hs</Text>
          </div>

          <div>
            <Text weight="semi-bold" context="important">
              Horario de Check Out:
            </Text>{' '}
            <Text>hasta las {checkOut}hs</Text>
          </div>
        </div>
        <hr />
        <div className="column">
          {rateChanged && (
            <BadgeContainer>
              <Badge variation="orange" inverted>
                La tarifa ha sido actualizada por nuestro proveedores
              </Badge>
            </BadgeContainer>
          )}
          <Text context="important">
            Precio final por noche para {totalPeople} persona
            {totalPeople > 1 ? 's' : ''}
          </Text>
          <Text context="important" size="xlarge" weight="semi-bold">
            {getFormatPrice(
              packageRoom.packagePrice.finalPricePerNight,
              packageRoom.packagePrice.currency
            )}
          </Text>
          {packageRoom.localPrice && (
            <Text context="important" size="large" weight="semi-bold">
              {getFormatPrice(
                packageRoom.localPrice.finalPricePerNight,
                packageRoom.localPrice.currency,
                true
              )}
            </Text>
          )}
          <div>
            <Text size="small" context="secondary">
              Precio por {days} noche{days > 1 ? 's ' : ' '}
            </Text>

            <Text weight="semi-bold">
              {packageRoom.packagePrice.currency}{' '}
              {packageRoom.packagePrice.finalPrice}
            </Text>
          </div>
          {packageRoom.localPrice && (
            <Text weight="semi-bold" paragraph>
              {getFormatPrice(
                packageRoom.localPrice.finalPrice,
                packageRoom.localPrice.currency,
                true
              )}
            </Text>
          )}

          {packageRoom.hasFreeCancellation && (
            <Text size="small" className="pay-form" paragraph>
              Acepta todas nuestras formas de pago
            </Text>
          )}
        </div>
        <div className="column">
          <div>
            {packageRoom.details &&
              packageRoom.details.map((detail, index) => (
                <Text key={index} context={detail.context} paragraph>
                  {detail.label}
                </Text>
              ))}
          </div>
        </div>
        {/* 
        // TODO
        // @ts-ignore */}
        <MobileHotelCard
          address={address}
          starRating={starRating}
          keyRating={keyRating}
          textRating={textRating}
          propertyName={propertyName}
          thumbnail={thumbnail}
          wifi={facilities.facilitiesIcons.wifi}
          parking={facilities.facilitiesIcons.parking}
          pool={facilities.facilitiesIcons.pool}
          airConditioning={facilities.facilitiesIcons.airConditioning}
          gym={facilities.facilitiesIcons.gym}
          breakfast={facilities.facilitiesIcons.breakfast}
          isAllInclusive={isAllInclusive}
          reviews={reviews}
          currency={packageRoom.packagePrice.currency}
          localCurrency={packageRoom.localPrice?.currency}
          price={packageRoom.packagePrice.finalPrice}
          localPrice={packageRoom.localPrice?.finalPrice}
        />
      </Card>
    </div>
  );
};

HotelCheckoutBreakdown.propTypes = {
  className: PropTypes.string.isRequired,
  packageRoom: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  facilities: PropTypes.object,
  starRating: PropTypes.number,
  keyRating: PropTypes.number,
  textRating: PropTypes.string,
  breakfast: PropTypes.bool,
  isAllInclusive: PropTypes.bool,
  propertyName: PropTypes.string,
  onSelect: PropTypes.func,
  thumbnail: PropTypes.string,
  address: PropTypes.string,
  reviews: PropTypes.shape({
    rating: PropTypes.number,
    opinionQty: PropTypes.number,
    ratingText: PropTypes.string
  }),
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  rateChanged: PropTypes.bool
};

export default styled(HotelCheckoutBreakdown)`
  width: 369px;

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.two};
`;
