/**
 *
 * BranchOffices
 *
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import Well from '../Well';
import Select from '../Select';
import GMap from '../GMap';
import Icon from '../Icon';

// Utils
import { scrollIntoView } from 'Utils';

const BranchOffices = ({
  className,
  offices,
  selectedOffice,
  onSubmit,
  onValid
}) => {
  const container = useRef(null);

  const onSelect = office => {
    onSubmit(office);
  };

  useEffect(() => {
    onValid(selectedOffice.id);
  }, [selectedOffice]);

  useEffect(() => {
    scrollIntoView(container);
  }, []);

  return (
    <div className={cs(className, 'subsidiary')} ref={container}>
      <div className="subsidiary__content">
        <div className="subsidiary__info">
          <div className="subsidiary__banner">
            <Well>
              En la sucursal seleccionada podés realizar el pago de forma
              presencial. Deberás concurrir antes de la hora de cierre, de lo
              contrario tu reserva se cancelará a las 23:00 horas del día de
              hoy.
            </Well>
          </div>

          <div className="subsidiary__choices">
            <Select
              label="Selecciona la sucursal"
              items={offices}
              selectedValue={selectedOffice && selectedOffice.id}
              onSelect={onSelect}
              context="important"
            />
          </div>
        </div>

        <div
          className={cs('subsidiary__map', {
            'subsidiary__map--is-empty': !selectedOffice
          })}>
          {selectedOffice && (
            <GMap
              initialCenter={{
                lat: selectedOffice.lat,
                lng: selectedOffice.lng
              }}
              markerName="Branch Office"
              zoom={15}
            />
          )}

          {!selectedOffice && (
            <Icon name="mv-local-touch" className="subsidiary__icon" />
          )}
        </div>
      </div>
    </div>
  );
};

BranchOffices.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onValid: PropTypes.func,
  offices: PropTypes.array,
  selectedOffice: PropTypes.object
};

export default styled(BranchOffices)`
  .subsidiary {
    &__content {
      display: flex;
      justify-content: center;
      position: relative;

      @media screen and (max-width: 520px) {
        flex-direction: column;
        align-items: center;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      max-width: 250px;
      margin-right: 20px;

      @media screen and (max-width: 520px) {
        flex-direction: column;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &__banner {
      margin-bottom: 5px;
    }

    &__map {
      width: 380px;
      height: 400px;
      border-radius: 14px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.15);

      &--is-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 35px;
      }

      @media screen and (max-width: 520px) {
        max-width: 90%;
      }
    }

    &__icon {
      font-size: 70px !important;
      color: rgba(0, 0, 0, 0.2);
    }

    &__submit {
      text-align: center;
      margin: 30px 0;
    }
  }
`;
