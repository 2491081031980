import React from 'react';
import PaxDataComponent from './PaxData';
import { FormikValues, useFormik } from 'formik';
import {
  getArrayIndexByObjId,
  getInitialPassengersValues
} from '../../helpers';
const mockData = [
  {
    type: 'Adulto',
    code: 'ADT',
    passengerCode: 'ADT',
    id: 0
  },
  {
    type: 'Nino',
    code: 'ADT',
    passengerCode: 'ADT',
    id: 1
  },
  {
    type: 'Bebe',
    code: 'ADT',
    passengerCode: 'ADT',
    id: 2
  }
];
const IndexCheckoutPaxData = () => {
  const { values, setValues }: FormikValues = useFormik({
    initialValues: getInitialPassengersValues(mockData),
    //TODO: para resolver al momento de hacer el wiring  con el serivcio
    // validateOnBlur: false,
    // validateOnChange: false,
    // validationSchema: Yup.object({
    //   username: Yup.string().required(formErrors.required),
    //   password: Yup.string().required(formErrors.required)
    // }),
    onSubmit: ({ values }) => {
      // eslint-disable-next-line no-console
      console.log('submit', values);
    }
  });
  const handleSetValue = (paxId, field, value) => {
    const newValues = [...values];
    const indexOfId = getArrayIndexByObjId(values, paxId);
    newValues[indexOfId][field] = value;
    setValues(newValues);
  };
  return <PaxDataComponent passengers={values} onChange={handleSetValue} />;
};

export default IndexCheckoutPaxData;
