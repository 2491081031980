import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { updateLoader } from 'Features/app/actions';
import { confirmWompiPaymentStatus } from '../../ReduxConfig/checkout';
import TextHeader from '../../Components/TextHeader';
import Text from '../../Components/Text';

const WompiProcessPayment = ({ match }) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(true);

  // TODO: MAKE ENGINE THING DYNAMIC
  useEffect(() => {
    dispatch(updateLoader(true));
    setTimeout(() => {
      dispatch(confirmWompiPaymentStatus(match.params.processId)).then(res => {
        setResponse(res);
        setLoading(false);
        dispatch(updateLoader(false));
      });
    }, 3000);
  }, []);

  return (
    <Wrapper>
      {response.paymentStatus == 'ERROR' && (
        <div className="content-pay">
          <Text>Ups... algo salio mal.</Text>
          <small>Error: {response.description}</small>
        </div>
      )}

      {response.paymentStatus === 'APPROVED' && (
        <div className="content-pay">
          <TextHeader size="xl" context="white">
            ¡El pago fue exitoso!
          </TextHeader>

          <Text context="white">
            Reservamos: <b>{response.reserveInfo.productInfo}</b>{' '}
          </Text>
          <br />
          <Text context="white">
            A nombre de: <b>{response.reserveInfo.titularFullName}</b>{' '}
          </Text>

          <br />

          <Text context="white">
            Este es tu codigo de reserva:{' '}
            <b>{response.reserveInfo.reserveCode}</b>
          </Text>
          <br />
        </div>
      )}

      {loading && <span>Espera! Estamos procesando tu pago...</span>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 25px;
    font-weight: bold;
    color: white;
  }

  .content-pay {
    margin-top: 70px;
    color: white;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  small {
    color: white;
  }
`;

WompiProcessPayment.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default WompiProcessPayment;
