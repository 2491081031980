/**
 *
 * AirCheckout
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import InvoiceForm from 'Components/InvoiceForm';
import BillForm from 'Components/BillForm';
import PaymentMethod from 'Components/PaymentMethod';
import Wizard from 'Components/Wizard';
import FareCheckoutBreakdown from 'Components/FareCheckoutBreakdown';
import Passengers from '../Passengers';

// Utils
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { useUser } from '../../../users/utils';
import PassengersCorpo from '../PassengersCorpo';
import CommentaryInput from '../../../../Components/CommentaryInput';

// Steps
const steps = [
  {
    name: 'Medios de pago',
    render: props => <PaymentMethod {...props} />
  },
  {
    name: 'Datos de facturación',
    render: props => <InvoiceForm {...props} />
  },
  {
    name: 'Pasajeros',
    render: props => <Passengers {...props} />
  },
  {
    name: 'Contacto',
    render: props => <BillForm {...props} />
  }
];

const stepsAgent = [
  {
    name: 'Pasajeros',
    render: props => <PassengersCorpo {...props} />
  },
  {
    name: 'Comentarios',
    render: props => <CommentaryInput {...props} />
  }
];

const AirCheckout = ({ availability, onFinish, onClose, containerRef }) => {
  const breakpoints = useBreakpoints();
  const user = useUser();

  return (
    <>
      <div className="content">
        <Wizard
          headerCentered
          withBackButton
          header="COMPLETÁ TUS DATOS Y FINALIZÁ TU COMPRA"
          steps={user ? stepsAgent : steps}
          onClose={onClose}
          onFinish={onFinish}
          containerRef={containerRef}
          hiddenElement={{
            price: availability.flightPrice.totalPrice,
            render: (
              <FareCheckoutBreakdown selectedAvailability={availability} />
            )
          }}
        />
      </div>

      {!breakpoints.mediumDown && (
        <div className="details">
          <FareCheckoutBreakdown selectedAvailability={availability} />
        </div>
      )}
    </>
  );
};

AirCheckout.propTypes = {
  availability: PropTypes.object,
  onFinish: PropTypes.func,
  onClose: PropTypes.func,
  containerRef: PropTypes.object
};

export default styled(AirCheckout)`
  display: block;
`;
