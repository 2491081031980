// Get name (approx) by HEX: http://chir.ag/projects/name-that-color/#6195ED
export const arena = '#e9d6a6';
export const blue = '#0a194b';
export const darkBlue = '#09184c';
export const middleBlue = '#002456';
export const darkGrey = '#7b7b7b';
export const middleGrey = '#888988';
export const middle2Grey = '#7b7e7c';
export const faded = '#7996ad';
export const golf = '#faeed7';
export const greyBkg = '#f4f4f4';
export const greyHoverBkg = '#f6f6f6';
export const important = '#ff6700';
export const orange = '#ff6700';
export const darkOrange = '#ff9824';
export const information = '#71bced';
export const lightGrey = '#d2d2d2';
export const navBarBckgColor = '#1a1a20';
export const navBarGrey = '#c1c6c4';
export const navBarMobileBckgColor = '#16141a';
export const otroVioleta = '#8b67a7';
export const positive = '#77a334';
export const green = '#77a334';
export const darkGreen = '#8bb136';
export const secondary = '#171f4b';
export const violeta = '#8167a3';
export const warning = '#ff0000';
export const white = '#fff';
export const yellow = '#fcb427';
export const shipCove = '#747fbc';
export const cornFlower = '#96caed';
export const spindle = '#c0dbed';
export const givry = '#f8e6c3';
export const goldenRod = '#ffce6f';
export const atomicTangerine = '#ffa162';
export const eminence = '#612f94';
export const deluge = '#7c67a3';
export const greyNurse = '#f1f2f1';
export const midnight = '#002441';
export const bahamaBlue = '#006495';
export const pictonBlue = '#5cbeea';
export const greenPea = '#1f672d';
export const blackHaze = '#EAECEB';
export const seagull = '#71bbec';
export const newBackground = '#31174b';
export const backgroundBreaker = '#4e2973';
export const backgroundFooter = '#713f90';
export const sunShade = '#ff9724';
export const prim = '#f9f1f7';
export const tussock = '#d09c4a';
export const bridalHeath = '#fffaf1';
export const eggSour = '#fff3db';
export const sandyBeach = '#ffebc5';
export const peachYellow = '#f9dda7';
export const grandis = '#ffd78b';

export const gradientDefault = {
  left: orange,
  right: darkOrange
};
export const gradientPurple = {
  left: eminence,
  right: deluge
};

export const gradientVioleta = `linear-gradient(
  to right,
  ${eminence},
  ${deluge}
)`;

export const gradientOrange = `linear-gradient(to right, ${orange}, ${sunShade})`;

export const gradientHomeMulti = `linear-gradient(to bottom, ${newBackground}, ${backgroundBreaker} 38%, ${backgroundFooter})`;
export const gradientEminenceDeluge = `linear-gradient(to top, ${eminence}, ${deluge})`;
export const gradientImportant = `linear-gradient(to top, ${important}, ${sunShade})`;
export const gradientPositive = `linear-gradient(to top, ${greenPea}, ${darkGreen})`;
export const gradientInfo = `linear-gradient(to top, ${bahamaBlue}, ${pictonBlue})`;
export const gradientInfoRight = `linear-gradient(to Right, ${bahamaBlue}, ${pictonBlue})`;
export const gradientSky = `linear-gradient(to bottom, ${prim}, ${white})`;
export const gradientGoldRight = `linear-gradient(to Right, #FFD78B, ${white})`;

export const gradientDestinationsBG = `linear-gradient(to bottom, rgba(23, 31, 75, 0) 70%, ${newBackground})`;
/* COLORS WITH RGBA */

export const rgba = {
  white: 'rgba(255, 255, 255, 0.5)'
};

export const textShadows = {
  one: `1px 1px ${darkGrey}`
};

/*
          ------- Shadows reference ------
Elevation 1 - Principal, botones, cards, inputs, algunas moleculas.
Elevation 2 - Secundario, Hover de botones,  Hover de cards
Elevation 3 - Terciario lo usamos poco, PopUps, Toasts (si hubiese).
Elevation 4 - Modales, Cards que se apoyen directamente sobre el fondo Azul.
*/
export const shadows = {
  one: '1px 1px 3px 0 rgba(0, 0, 0, 0.25)',
  two: '2px 2px 6px 0 rgba(0, 0, 0, 0.25)',
  three: '2px 3px 12px 0 rgba(0, 0, 0, 0.25)',
  four: '4px 4px 25px 0 rgba(0, 0, 0, 0.15)',
  five: '0 1px 2px 0 rgba(0, 0, 0, 0.35)'
};
