/**
 *
 * DestinationsTop
 *
 */

import React from 'react';
import DestinationInfo from 'Features/destinations/components/DestinationInfo';
import { DestinationTopProps } from './types';
import styled from 'styled-components';

const DestinationTopWrapper = styled.div`
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
`;

const DestinationsTop = ({ name, description }: DestinationTopProps) => (
  <DestinationTopWrapper>
    {name && (
      <DestinationInfo name={name} description={description} color="white" />
    )}
  </DestinationTopWrapper>
);

export default DestinationsTop;
