/**
 *
 * ButtonCardThumbnail
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import ButtonCard from '../ButtonCard';

const ButtonCardThumbnail = ({
  className,
  imgSrc,
  imgAlt,
  onClick,
  children
}) => (
  <ButtonCard className={cs(className)} onClick={onClick}>
    <div className="container">
      <img src={imgSrc} alt={imgAlt} className="img" />
      <div className="text">{children}</div>
    </div>
  </ButtonCard>
);

ButtonCardThumbnail.propTypes = {
  className: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func
};

export default styled(ButtonCardThumbnail)`
  .container {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }

  .img {
    max-height: 51px;
    margin-left: 19px;
  }
  .text {
    margin-left: 14px;
  }
`;
