import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMPC, setInitialDate, setEndDate } from '../..';
import { setActive } from 'Features/destinations/actions';
import RoundTrip from './RoundTrip';
import { formatIso, isoFiveDays, parseIso } from 'Utils/dates';
import { addDays } from 'date-fns';
import { RootState } from 'ReduxConfig/store';

const selector = ({ flights, destinations, appConfiguration }: RootState) => ({
  isMPC: flights.search.isMPC,
  knowDestination: destinations.active,
  initialDate: flights.search.routesData[0].initialDate,
  minDateToStartSearch: appConfiguration.flightsConfig.minDateToStartSearch,
  endDate: flights.search.routesData[0].endDate
    ? flights.search.routesData[0].endDate
    : isoFiveDays,
  initialDateError: flights.search.validations.routesData[0].initialDate,
  endDateError: flights.search.validations.routesData[0].endDate,
  isCurrentViewDestinations: destinations.isCurrentView
});

const ConnectedRoundTrip = props => {
  const dispatch = useDispatch();
  const actions = {
    toggleMPC: () => {
      dispatch(toggleMPC());
    },
    setKnowDestination: () => {
      dispatch(setActive(!state.knowDestination));
    },
    setInitialDate: date => {
      const newDate = parseIso(date);
      const isStartDateIsAfterEndDate = newDate > parseIso(state.endDate);
      if (isStartDateIsAfterEndDate) {
        const newEndDate = formatIso(addDays(newDate, 5));
        dispatch(setEndDate(0, newEndDate));
      }
      dispatch(setInitialDate(0, date));
    },
    setEndDate: date => {
      dispatch(setEndDate(0, date));
    }
  };

  const state = useSelector(selector);

  return <RoundTrip {...props} {...state} actions={actions} />;
};

export { RoundTrip };
export default ConnectedRoundTrip;
