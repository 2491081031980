/**
 *
 * HotelServicesList
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as COLORS from 'Constants/colors';
import Text from 'Components/Text';

const HotelServicesList = ({ className, facilities = [] }) => (
  <div className={className}>
    <ul className="list">
      {facilities.map((facility, i) => (
        <li key={facility + i} className="list-item">
          <Text>{facility}</Text>
        </li>
      ))}
    </ul>
  </div>
);

HotelServicesList.propTypes = {
  className: PropTypes.string.isRequired,
  facilities: PropTypes.array,
  noPadding: PropTypes.bool
};

export default styled(HotelServicesList)`
  column-count: 3;
  padding: ${props => (props.noPadding ? 0 : 12)}px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  li {
    padding-left: 1em;
    text-indent: -0.7em;
    margin-bottom: 10px;
  }

  li::before {
    content: '• ';
    color: ${COLORS.orange};
    font-size: 14px !important;
  }
`;
