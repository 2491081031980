export default function(api) {
  async function simples(value) {
    return await api.get(`simples?departure=${encodeURIComponent(value)}`);
  }
  async function simplesByDestination(departure, destination) {
    return await api.get(
      `simples?departure=${encodeURIComponent(
        departure
      )}&destination=${encodeURIComponent(destination)}`
    );
  }

  async function assistances(value) {
    return await api.post('assistances', value);
  }

  async function getPackage(keyword, id) {
    return await api.get(`simples/${keyword}/${id}`);
  }

  async function getUpsellingOfPackage(id) {
    return await api.get(`simples/${id}/upselling`);
  }

  async function mostWanted() {
    return await api.get('most-wanted?days=30&count=9');
  }

  return {
    simples,
    simplesByDestination,
    mostWanted,
    assistances,
    getPackage,
    getUpsellingOfPackage
  };
}
