import slugify from 'slugify';

const resolveType = request =>
  request.allowedPets ? '&type=acepta-mascotas' : '&type=normal';
const resolveQuantity = request => `quantity=${request.roomInformation.length}`;
const resolveTo = request => `to=${slugify(request.location.description)}`;
const resolveCheckin = request => `from-date=${request.checkIn}`;
const resolveCheckout = request => `to-date=${request.checkOut}`;

const resolveChildAges = (kidsAges, roomIndex) =>
  `room-${roomIndex + 1}=${JSON.stringify(kidsAges)}&`;

const resolveHuespedes = request => {
  let data = '';
  request.roomInformation.forEach((room, index) => {
    data =
      room.kidsAges.length > 0
        ? data +
          `adults${index + 1}=${room.adultsCount}&childs${index + 1}=${
            room.kidsAges.length
          }&${resolveChildAges(room.kidsAges, index)}`
        : data + `adults${index + 1}=${room.adultsCount}&`;
  });
  return data;
};

export default request =>
  `${resolveQuantity(request)}&${resolveTo(request)}&${resolveCheckin(
    request
  )}&${resolveCheckout(request)}&${resolveHuespedes(
    request
  )}sorting=menor-precio&view-type=con-mapa&placeId=${
    request.location.placeId
  }&iconType=${request.location.iconType}${resolveType(request)}`;
