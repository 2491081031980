import { IFlightObject } from 'Features/flights/types';

export interface ICodeResponse {
  code: number;
}

export interface IErrorResponse {
  code: number;
  message: string;
  info: {};
}

export interface ISuggestionResponse {
  quotations: IQuotationSimple[];
}

export interface IOptionSuggestion {
  id: number;
  name: string;
}

export interface IQuotationSimple {
  id: number;
  code: string;
  title: string;
}

export interface IQuotationGeneralInfo {
  id?: number;
  title: string;
  totalPassenger: number;
  clientName?: string;
  clientEmail?: string;
  phoneNumber?: string;
  country?: string;
  clientNotes?: string;
  internalNotes?: string;
}

export interface IQuotation {
  id: number;
  code: string;
  title: string;
  passengers: number;
  fligths: number;
  services: number;
  accommodationOptions: number;
  clientName?: string;
  clientEmail?: string;
  dateTimeCreated: Date;
  emailCreatedBy: string;
  routes: IRoute[];
}

export interface IPagination {
  totalPages: number;
  currentPage: number;
  totalItems: number;
  limit: number;
}

export interface IQuotationFull {
  id: number;
  title: string;
  code: string;
  totalPassenger: number;
  quotationByTotal: boolean;
  dateTimeCreated?: string;
  clientName?: string;
  clientEmail?: string;
  phoneNumber?: string;
  country?: string;
  clientNotes?: string;
  internalNotes?: string;
  emailCreateBy?: string;
  optionsQuotation: IQuotationOption[];
  flightQuotations: IFlightQuotation[];
  accommodationQuotations: IQuotationAccomodation[];
  serviceQuotations: IQuotationService[];
}

export interface IQuotationOption {
  id?: number;
  optionNumber: number;
  flightsCost: number;
  totalCost: number;
  markPriceForEdit: boolean;
  totalPVP: number;
}

export interface IQuotationParams {
  sort?: string;
  size?: number;
  page?: number;
  dateFrom?: string;
  dateTo?: string;
  filter?: string;
}

export interface IRoute {
  origin: string;
  destination: string;
}

export interface IQuotationFlightPayload {
  flightQuotation: IFlightQuotation;
  routesData: IRoutesData[];
}

export interface IRoutesData {
  origin: string;
  destination: string;
  initialDate: string;
  endDate: string;
  originName?: string;
  destinationName?: string;
}

export interface IFLightUI {
  routesData: IRoutesData;
  initialDate: string;
  endDate: string;
  flightObject: IFlightObject;
  selectedSegment?: {
    ida: number;
    vuelta?: number;
  };
}

export interface IFlightQuotation {
  id?: number;
  flightUI: IFLightUI;
  passengers: IPaxPassenger[];
}

export interface IPostQuotationFlight {
  title: string;
  flightQuotationWithRoutes: IQuotationFlightPayload;
}

export interface IPaxPassenger {
  id?: number;
  paxType: PaxType;
  total: number;
  costByPax: number;
  salePriceByPax?: number;
  markup?: number;
  priceForEdit?: number | null;
}

export interface IQuotationService {
  id?: number;
  title: string;
  isIncluded: boolean;
  totalPax: number;
  isCostByPassenger: boolean;
  dateType: DateType;
  cost: number;
  description: string;
  internalNotes: string;
  providerName: string;
  /** ISO String Date */
  dateFrom: string;
  /** ISO String Date */
  dateTo: string;
  markup: number;
}

export interface IPostQuotationAccomodation {
  title: string;
}

export interface IQuotationAccomodation {
  title: string;
  accommodationName: string;
  inputDateTime: Date;
  outputDateTime: Date;
  totalNights: number;
  totalCost: number;
  roomsInfo: IRoomInfo;
  json: any;
}

export interface IRoomInfo {
  roomName: string;
  roomsInfo: IRoomPassenger[];
}

export interface IRoomPassenger {
  paxType: PaxType;
  total: number;
}

export enum PaxType {
  ADT = 1,
  CHDINS = 2,
  INF = 3
}

export enum DateType {
  noDate = 1,
  one = 2,
  range = 3
}
