/**
 *
 * Link
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

const Link = ({ children, ...rest }: LinkProps) => (
  <Container {...rest}>{children}</Container>
);

const Container = styled(RouterLink)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export default Link;
