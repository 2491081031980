import { engines } from 'Constants';

export const getCorpoSteps = engine => {
  switch (engine) {
    case engines.FLIGHTS.name:
    case `${engines.FLIGHTS.name}MPC`:
      return ['Pasajeros', 'Comentarios'];

    default:
      break;
  }
};
