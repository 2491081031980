/**
 *
 * ErrorGone
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import Card from '../Card';
import Text from '../Text';
import Icon from '../Icon';
import Button from '../Button';

const ErrorGone = ({ className, onClick, iconName, title, subtitle }) => (
  <Card className={cs(className)}>
    {iconName && <Icon name={iconName} size={58} color="important" />}
    {title && (
      <Text paragraph context="black" size="large">
        <b>{title}</b>
      </Text>
    )}
    {subtitle && (
      <Text paragraph size="large">
        {subtitle}
      </Text>
    )}
    {onClick && <Button onClick={onClick}> Volver al listado </Button>}
  </Card>
);

ErrorGone.propTypes = {
  className: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default styled(ErrorGone)`
  margin: 0 35%;
  position: absolute;
  top: 58%;
  z-index: 4;
  height: 310px;

  .mv-card-content {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
  }

  p {
    margin-bottom: 20px;
    text-align: center;

    &:first-child {
      margin-top: 20px;
    }
  }

  button {
    margin-top: auto;
  }

  @media all and (max-width: 900px) {
    margin: 0 6%;
    top: 48%;
  }
`;
