/**
 *
 * FlightSearchCompact
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import { textTruncate } from '../../../../Utils';
import returnDate from '../../../../Utils/returnDate';
import Button from 'Components/Button';
import Card from 'Components/Card';
import * as COLORS from 'Constants/colors';
import { airSearchTypes } from 'Constants';

const FlightSearchCompact = ({
  className,
  originName,
  destinationName,
  initialDate,
  endDate,
  adults,
  kids,
  type,
  actions
}) => {
  const passengers = adults + kids.length;

  return (
    <div className={className}>
      <Card>
        <div className="detail-bar">
          <div className="detail-bar-item">
            <Icon name="mv-plane-ida" />
            <Text>
              {textTruncate(originName, 30)} -{' '}
              {textTruncate(destinationName, 30)}
            </Text>
          </div>
          <div className="detail-bar-item">
            <Icon name="mv-calendar" />
            {type === airSearchTypes.roundTrip ? (
              <Text>
                {returnDate(initialDate)} - {returnDate(endDate)}
              </Text>
            ) : (
              <Text>{returnDate(initialDate)}</Text>
            )}
          </div>
          <div className="detail-bar-item">
            <Icon name="mv-user" />
            <Text>{passengers}</Text>
          </div>
          <Button weight="semi-bold" onClick={actions.setModify}>
            MODIFICAR
          </Button>
        </div>
      </Card>
    </div>
  );
};

FlightSearchCompact.propTypes = {
  className: PropTypes.string.isRequired,
  originName: PropTypes.string.isRequired,
  destinationName: PropTypes.string.isRequired,
  initialDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  adults: PropTypes.number.isRequired,
  kids: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      seat: PropTypes.bool
    })
  ),
  actions: PropTypes.shape({
    setModify: PropTypes.func.isRequired
  }),
  type: PropTypes.string.isRequired
};

export default styled(FlightSearchCompact)`
  padding-top: ${({ theme }) => theme.spacing.two};
  padding-bottom: ${({ theme }) => theme.spacing.two};
  width: 100%;

  .detail-bar {
    display: flex;
    align-items: center;

    .detail-bar-item {
      align-items: center;
      border-right: 3px solid ${COLORS.lightGrey};
      display: flex;
      height: 40px;
      margin-right: 8px;
      padding-right: 10px;

      .icon {
        color: ${COLORS.important};
        font-size: 20px;
        margin-right: 10px;
        position: relative;
        top: 2px;
      }
    }
  }
`;
