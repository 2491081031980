/**
 *
 * MobileCalendar
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import MobileCalendar from '../MobileCalendar';
import { dayInWeek } from '../../Utils';
import * as COLORS from 'Constants/colors';
import Text from '../Text';
import { formatSuperShort, parseIso, formatIso } from '../../Utils/dates';
import Portal from 'Components/Portal';

const getStringDate = (startDate, endDate, oneWay) => {
  if (oneWay) {
    return formatSuperShort(startDate);
  }
  return `${formatSuperShort(startDate)} - ${formatSuperShort(endDate)}`;
};
// TODO Add Funcional and Snapshot tests to this!

const MobileDateSelector = ({
  className,
  oneWay = false,
  multi,
  arrivalDate,
  departureDate,
  setArrivalDate,
  setDepartureDate,
  maxDate,
  isFlight = false
}) => {
  const [openModal, setOpenModal] = useState(false);

  const parsedArrivalDate = parseIso(arrivalDate);
  const parsedDepartureDate = parseIso(departureDate);

  const handleClick = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    document.body.classList.toggle('non-scroll', openModal);
  }, [openModal]);

  const DateSelectedBox = () => {
    if (multi) {
      return (
        <>
          <Text size="xlarge" context="important" weight="semi-bold">
            {getStringDate(parsedArrivalDate, parsedDepartureDate, oneWay)}
          </Text>
          <Text>{dayInWeek(parsedArrivalDate)}</Text>
        </>
      );
    }

    return (
      <>
        <Text weight="semi-bold" size="small">
          {oneWay ? 'FECHA' : 'FECHAS'}
        </Text>
        <Text weight="semi-bold" context="important" size="large">
          {getStringDate(parsedDepartureDate, parsedArrivalDate, oneWay)}
        </Text>
        <Text>
          {dayInWeek(parsedDepartureDate) +
            (oneWay ? '' : ' - ' + dayInWeek(parsedArrivalDate))}
        </Text>
      </>
    );
  };

  const handleSelectDates = (startDate, endDate) => {
    setOpenModal(false);
    if (!startDate || !endDate) return;
    if (oneWay) {
      setDepartureDate(formatIso(startDate));
    } else {
      setDepartureDate(formatIso(startDate));
      setArrivalDate(formatIso(endDate));
    }
  };

  return (
    <React.Fragment>
      {openModal && (
        <Portal>
          <MobileCalendar
            onSelected={handleSelectDates}
            askDateEnd={oneWay}
            initialStartDate={parsedDepartureDate}
            initialEndDate={parsedArrivalDate}
            maxDate={maxDate}
            oneWay={oneWay}
            isFlight={isFlight}
          />
        </Portal>
      )}
      <div
        className={cs(className, { 'rectangle-mobile-date-selector': true })}
        onClick={handleClick}>
        <DateSelectedBox />
      </div>
    </React.Fragment>
  );
};

MobileDateSelector.propTypes = {
  className: PropTypes.string.isRequired,
  oneWay: PropTypes.bool,
  onSelectDate: PropTypes.func,
  multi: PropTypes.bool,
  arrivalDate: PropTypes.string,
  departureDate: PropTypes.string,
  stateless: PropTypes.bool,
  setArrivalDate: PropTypes.func,
  setDepartureDate: PropTypes.func,
  isEmpty: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  isFlight: PropTypes.bool
};

export default styled(MobileDateSelector)`
  width: ${props => (props.multi ? 32 : 48)}%;
  min-height: ${props => (props.multi ? 104 : 130)}px;

  &.rectangle-mobile-date-selector {
    cursor: pointer;
    height: 84px;
    border-radius: 14px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
