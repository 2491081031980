/**
 * Check if an object has a property
 *
 * @param {object} aObject
 * @param {string} aAttribute
 *
 * @return boolean
 */
export const existsAttribute = (aObject, aAttribute) =>
  aObject && aAttribute && aObject.hasOwnProperty(aAttribute);

/**
 *
 * @param {object} aObjectColors
 * @param {string} typeMeteorology
 * @param {number} value
 * @param {string} key
 *
 * @return number || string
 */
export const getValueByKey = (aObjectColors, typeMeteorology, value, key) => {
  const listObject = aObjectColors[typeMeteorology];
  const firstItem = listObject[0];
  const lastItem = listObject[listObject.length - 1];

  let result = firstItem[key];

  if (value >= firstItem.value) {
    result = firstItem[key];
  } else if (value <= lastItem.value) {
    result = lastItem[key];
  } else {
    result = listObject.filter(item => item.value === value)[0][key];
  }

  return result;
};
