/**
 *
 * MobileHotelSearch
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import MobileHotelDestinationCard from '../MobileHotelDestinationCard';
import MobileDateSelector from 'Components/MobileDateSelector';
import MobilePaxDetailHotelCard from '../MobilePaxDetailHotelCard';
import Checkbox from 'Components/Checkbox';
import Button from 'Components/Button';
import Icon from 'Components/Icon';

const MobileHotelSearch = ({
  className,
  actions,
  checkIn,
  checkOut,
  isEmpty,
  showMap
}) => (
  <div className={cs(className)}>
    <MobileHotelDestinationCard />

    <div className="two margin">
      <MobileDateSelector
        stateless
        arrivalDate={checkOut}
        departureDate={checkIn}
        setArrivalDate={actions.setCheckOut}
        setDepartureDate={actions.setCheckIn}
        isEmpty={isEmpty}
      />
      <MobilePaxDetailHotelCard minHeight={130} />
    </div>

    {/* TODO
        <div className="center margin">
          <Checkbox onClick={handleKnowMore} checked={state.knowMore}>
            Conocer más de este destino
          </Checkbox>
        </div>
      */}

    <div className="center margin">
      <Checkbox onClick={actions.toggleMap} checked={showMap}>
        Ver en mapa
      </Checkbox>
    </div>

    <div className="holder-mobile-search-btn">
      <Button weight="semi-bold" fullWidth onClick={actions.doSearch}>
        <ButtonContent>
          <Icon color="white" name="mv-lense" /> BUSCAR
        </ButtonContent>
      </Button>
    </div>
  </div>
);

MobileHotelSearch.propTypes = {
  className: PropTypes.string.isRequired,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  actions: PropTypes.shape({
    setCheckIn: PropTypes.func.isRequired,
    setCheckOut: PropTypes.func.isRequired,
    doSearch: PropTypes.func.isRequired,
    toggleMap: PropTypes.func.isRequired
  }),
  isEmpty: PropTypes.bool,
  showMap: PropTypes.bool
};

export default styled(MobileHotelSearch)`
  display: block;
  padding: 13px;
  width: 100%;

  .margin {
    margin-top: 5px;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .two {
    display: flex;
    justify-content: space-between;
  }

  .holder-mobile-search-btn {
    margin-top: 12px;
    display: flex;
    justify-content: center;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    margin-right: ${({ theme }) => theme.spacing.one};
  }
`;
