/**
 *
 * ConnectedDestinationsBottom
 *
 */

import React from 'react';

import DestinationsBottom from './DestinationsBottom';
import { useSelector } from 'react-redux';
import { selectorDestinationsBottom } from 'Features/destinations/selectors';

const ConnectedDestinationsBottom = ({
  destination
}: {
  destination: string;
}) => {
  const { status, ...state } = useSelector(selectorDestinationsBottom);
  return status !== 'ready' ? null : (
    //@ts-ignore TODO
    <DestinationsBottom {...state} destination={destination} />
  );
};

export { DestinationsBottom };
export default ConnectedDestinationsBottom;
