export const dobMonth = [
  {
    id: 1,
    name: 'Enero'
  },
  {
    id: 2,
    name: 'Febrero'
  },
  {
    id: 3,
    name: 'Marzo'
  },
  {
    id: 4,
    name: 'Abril'
  },
  {
    id: 5,
    name: 'Mayo'
  },
  {
    id: 6,
    name: 'Junio'
  },
  {
    id: 7,
    name: 'Julio'
  },
  {
    id: 8,
    name: 'Agosto'
  },
  {
    id: 9,
    name: 'Septiembre'
  },
  {
    id: 10,
    name: 'Octubre'
  },
  {
    id: 11,
    name: 'Noviembre'
  },
  {
    id: 12,
    name: 'Diciembre'
  }
];

export const defaultFlightPassengerDocumentsType = {
  id: 1,
  name: 'Pasaporte'
};

export const flightPassengerDocumentsTypes = [
  defaultFlightPassengerDocumentsType,
  {
    id: 2,
    name: 'Cédula de identidad'
  },
  {
    id: 3,
    name: 'DNI'
  }
];

export const defaultBillingDocumentType = {
  id: 2,
  name: 'Cédula de identidad'
};

export const billingDocumentTypes = [
  {
    id: 1,
    name: 'RUC/RUT'
  },
  defaultBillingDocumentType,
  {
    id: 3,
    name: 'Pasaporte'
  },
  {
    id: 4,
    name: 'DNI'
  }
];

export const defaultPersonType = {
  id: 1,
  name: 'Persona física'
};

export const personTypes = [
  defaultPersonType,
  {
    id: 2,
    name: 'Persona jurídica / Empresa'
  }
];

export const maxAtcAttempts = 10;
export const defaultCheckPaymentErrorMessage =
  'Ocurrió un error al chequear el estado del pago';
