import { defaultIda, defaultVuelta } from './helpers';

export const selector = ({ checkoutCorpo, places, appConfiguration }) => ({
  citizens: places.citizen,
  countries: places.countries,
  localize: appConfiguration.localize,
  passengers: checkoutCorpo.requestData.passengers,
  genders: appConfiguration.sharedConfig.genders,

  flightVuelta:
    (checkoutCorpo.selectedAvailability &&
      checkoutCorpo.selectedAvailability.selectedSegment &&
      checkoutCorpo.selectedAvailability.selectedSegment.vuelta.item &&
      checkoutCorpo.selectedAvailability.selectedSegment.vuelta.item
        .departureData) ||
    defaultVuelta,

  flightIda:
    (checkoutCorpo.selectedAvailability &&
      checkoutCorpo.selectedAvailability.selectedSegment &&
      checkoutCorpo.selectedAvailability.selectedSegment.ida.item
        .departureData) ||
    defaultIda
});
