import API from '../../Services';
import ACTION_TYPES from './actionTypes';

const bannersRequestSuccess = data => ({
  type: ACTION_TYPES.BANNERS_SUCCESS,
  payload: {
    ...data
  }
});

export const bannersRequest = id => dispatch => {
  dispatch({
    type: ACTION_TYPES.BANNERS_REQUEST
  });
  API.common
    .banners(id)
    .then(function(response) {
      const { data } = response;
      dispatch(bannersRequestSuccess(data));
    })
    .catch(function(error) {
      return dispatch => {
        dispatch({
          type: ACTION_TYPES.BANNERS_FAILURE,
          payload: error
        });
      };
    });
};
