import React, { useEffect } from 'react';
import bck from 'Features/managuaTemporary/components/ManaguaHome/images/managua2.jpg';
import ImgWhatsApp from 'Features/travelInsurance/components/TravelInsuranceHome/images/whatsapp.png';
import ImgPhone from 'Features/travelInsurance/components/TravelInsuranceHome/images/mv-phone.png';

import SectionBgImages from 'Components/SectionBgImages';
import Divider from 'Components/Divider';
import Heading from 'Components/Heading';

import {
  Wrapper,
  TopLeftContainer,
  InfoCard,
  InfoCardTopText,
  PhoneHolder,
  PhoneItem,
  Section,
  TopContainer
} from './components';

const ManaguaHome = () => {
  useEffect(() => {
    // @ts-ignore
    window.openManaguaForm();
  }, []);
  return (
    <Wrapper>
      {/*
  // @ts-ignore*/}
      <SectionBgImages image={bck} />

      <TopContainer>
        <TopLeftContainer>
          <Divider titleText="MANAGUA" />

          <Heading type="h3" color="white">
            Estamos a disposición para evacuar tus dudas con respecto a la
            compra de pasajes aéreos a Managua
          </Heading>
          <Heading type="h4" color="white">
            Completá el siguiente formulario y nos ponemos en contacto contigo
          </Heading>
          <Heading type="h4" color="white">
            Además, si tenés la necesidad de solicitar tu visa podemos
            asesorarte con los requisitos e información necesaria
          </Heading>
        </TopLeftContainer>
      </TopContainer>

      <Section>
        <Divider titleText="Completá tus datos:" />

        <Section>
          <div id="#managuaFormLiveAgent" />
        </Section>
      </Section>

      <InfoCard>
        <InfoCardTopText>
          Por otras consultas no dudes en contactarte con nosotros:
        </InfoCardTopText>
        <PhoneHolder>
          <PhoneItem>
            <img src={ImgPhone} alt="Telefono" />
            +598 2903 3434
          </PhoneItem>

          <PhoneItem>
            <img src={ImgWhatsApp} alt="WhatsApp" />
            093 522 440
          </PhoneItem>
        </PhoneHolder>
      </InfoCard>
    </Wrapper>
  );
};

export default ManaguaHome;
