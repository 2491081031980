import React from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { selector } from './selector';
import QuotationsGeneral from './QuotationsGeneral';
import { formErrors } from 'Constants';
import API from 'Services/Quotations';
import { Id } from '../QuotationDetails/QuotationDetails';
import { IQuotationGeneralInfo } from 'Services/Quotations/types';
import { getInitialValues } from './helpers';
import { IQuotationsGeneralFields } from './types';
export interface ConnectedQuotationsGeneralProps {
  id: Id;
}

const ConnectedQuotationsGeneral = ({
  id
}: ConnectedQuotationsGeneralProps) => {
  const { countries, detail } = useSelector(selector);

  const { values, errors, setFieldValue, handleSubmit } = useFormik<
    IQuotationsGeneralFields
  >({
    initialValues: getInitialValues(detail),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      title: Yup.string().required(formErrors.required)
    }),
    onSubmit: fields => {
      const payload: IQuotationGeneralInfo = {
        title: fields.title,
        clientName: fields.name,
        clientEmail: fields.email,
        phoneNumber: fields.phone,
        country: fields.country.name,
        clientNotes: fields.clientQuote,
        internalNotes: fields.backlogQuote,
        totalPassenger: Number(fields.passengers)
      };
      if (id === 'new') {
        API.postQuotation(payload);
      } else {
        // TODO we need every part of the quotation to update one
        // Come back to this, or lift the update
        API.updateQuotation(id, { ...detail, ...payload });
      }
    }
  });

  const handleDownload = () => {
    alert('Download');
  };

  const handleRefresh = () => {
    alert('Refresh');
  };

  const handleChange = (key: keyof IQuotationsGeneralFields) => (
    value: IQuotationsGeneralFields[keyof IQuotationsGeneralFields]
  ) => {
    setFieldValue(key, value);
  };

  return (
    <QuotationsGeneral
      countries={countries}
      fields={values}
      errors={errors}
      onDownload={handleDownload}
      onRefresh={handleRefresh}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

export default ConnectedQuotationsGeneral;
