import styled, { keyframes } from 'styled-components';
import Icon from 'Components/Icon';

const BounceAnimation = keyframes`
    0% { margin-bottom: 0; }
    50% { margin-bottom: 15px }
    100% { margin-bottom: 0 }
  `;

const MoveDownArrow = styled(Icon)`
  display: block;
  position: absolute;
  bottom: 12%;

  /* Animation */

  animation: ${BounceAnimation} 1s linear infinite;
  @media screen and (max-width: 740px) {
    display: none;
  }
`;

export default MoveDownArrow;
