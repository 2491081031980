/**
 *
 * BillForm
 *
 */

// Vendors
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import Button from '../Button';
import Select from '../Select';
import Input from '../Input';
import Checkbox from '../Checkbox';
import TextHeader from '../TextHeader';

// Constants
import { emailPattern } from 'Constants/regex';

// InitialStates
const phoneInitialState = {
  code: '',
  phoneType: '',
  phone: ''
};

const initialState = {
  email: '',
  confirmEmail: '',
  receiveOffers: true,
  phones: [phoneInitialState]
};

const PHONES_LIMIT = 2;

const BillForm = ({
  onValid,
  phoneTypes,
  localize,
  countries,
  actions,
  userData
}) => {
  let tempErrors = {};
  const codeLocalize = localize && localize['phoneCode'];
  const [fields, setFields] = useState(initialState);
  const [errors, setErrors] = useState({});
  const isEnabled =
    fields.email &&
    fields.confirmEmail &&
    fields.phones.every(p => p.phoneType && p.code && p.phone);
  const listPhoneCodes =
    countries &&
    countries.map(country => ({
      id: `${country.phoneCode}`,
      name: `${country.name} ${country.phoneCode}`
    }));

  const handleChange = field => value => {
    setFields(oldState => ({
      ...oldState,
      [field]: value
    }));
  };

  const handleBlur = () => {
    actions.setUserData({
      bill: {
        ...userData.bill,
        ...fields
      }
    });
  };

  const handleSelectedCode = (code, index) =>
    code && fields.phones[index].code.id === code
      ? fields.phones[index].code.id
      : codeLocalize;

  const handlePhoneChange = (name, index) => value => {
    const newPhones = fields.phones.map((phone, i) => {
      if (i !== index) {
        return phone;
      }

      return {
        ...phone,
        [name]: value
      };
    });

    setFields(oldState => ({
      ...oldState,
      phones: newPhones
    }));

    actions.setUserData({
      bill: {
        ...userData.bill,
        phones: newPhones
      }
    });
  };

  const onChangeCheckbox = field => value => {
    setFields(oldState => ({
      ...oldState,
      [field]: !value
    }));

    actions.setUserData({
      bill: {
        ...userData.bill,
        [field]: !value
      }
    });
  };

  const handleAddPhone = event => {
    event.preventDefault();

    setFields(oldState => {
      if (oldState.phones.length >= PHONES_LIMIT) {
        const [firstPhone] = oldState.phones;

        return {
          ...oldState,
          phones: [firstPhone]
        };
      }

      return {
        ...oldState,
        phones: [...oldState.phones, phoneInitialState]
      };
    });

    actions.setUserData({
      bill: {
        ...userData.bill,
        ...fields
      }
    });
  };

  const handleValidate = e => {
    e && e.preventDefault();

    const handleErrors = (field, message) => {
      setErrors(oldErrors => ({
        ...oldErrors,
        [field]: message
      }));

      tempErrors = {
        ...tempErrors,
        [field]: message
      };
    };

    Object.keys(fields).forEach(key => {
      switch (key) {
        case 'email':
        case 'confirmEmail':
          if (fields[key].length > 0 && fields[key].length < 4) {
            return handleErrors(
              key,
              'La direccion de correo electrónico no puede contener menos de 4 caracteres.'
            );
          } else if (
            fields[key].length > 0 &&
            !emailPattern.test(fields[key])
          ) {
            return handleErrors(
              key,
              'Escriba el email correctamente, ej: mevuelo@hola.com'
            );
          } else if (
            fields.email.length > 0 &&
            fields[key].length > 0 &&
            fields.email !== fields[key]
          ) {
            return handleErrors(
              key,
              'Ambos correos electrónicos deben ser idénticos.'
            );
          }

          return handleErrors(key, '');

        default:
          return null;
      }
    });

    const errors = Object.keys(tempErrors);

    const isValid =
      errors.length > 0 && !errors.some(key => tempErrors[key].length > 0);

    return isValid && isEnabled;
  };

  useEffect(() => {
    const filterPhoneCode =
      countries &&
      listPhoneCodes.filter(phoneCode => phoneCode.id === codeLocalize);
    const totalFilterPhoneCode = countries && filterPhoneCode.length > 0;
    if (totalFilterPhoneCode) {
      const updatePhones = () =>
        fields.phones.map(phone => {
          if (phone.code) {
            return phone;
          }
          return {
            ...phone,
            phoneType: phoneTypes[0],
            code: filterPhoneCode[0]
          };
        });

      setFields(oldState => ({
        ...oldState,
        phones: updatePhones()
      }));
    }
  }, [codeLocalize, countries]);

  useEffect(() => {
    onValid(handleValidate());
  }, [fields]);

  useEffect(() => {
    actions.setUserData({
      bill: {
        ...userData.bill,
        ...fields
      }
    });
  }, []);

  return (
    <Form onSubmit={() => {}}>
      <TextHeader size="xs">¿A dónde enviamos tus vouchers?</TextHeader>

      <Fields>
        <Input
          isRequired
          placeholder="miguel.acme@mevuelo.com"
          label="Correo electrónico"
          type="email"
          message={errors.email}
          context={errors.email && 'warning'}
          value={fields.email}
          onBlur={handleBlur}
          onChange={handleChange('email')}
        />

        <Input
          isRequired
          type="email"
          placeholder="miguel.acme@mevuelo.com"
          label="Confirmá tu correo electrónico"
          message={errors.confirmEmail}
          context={errors.confirmEmail && 'warning'}
          value={fields.confirmEmail}
          onBlur={handleBlur}
          onChange={handleChange('confirmEmail')}
        />

        <Checkbox
          value={fields.receiveOffers}
          checked={fields.receiveOffers}
          onClick={onChangeCheckbox('receiveOffers')}>
          Quiero recibir las mejores ofertas en mi correo electrónico
        </Checkbox>
      </Fields>

      <TextHeader size="xs">¿A dónde podemos llamarte?</TextHeader>

      <Fields className="flex-inner">
        {fields.phones.map((phone, i) => (
          <Fragment key={i}>
            <Select
              isRequired
              label="Teléfono"
              className="item-inline"
              selectedValue={phone.phoneType && phone.phoneType.id}
              onSelect={handlePhoneChange('phoneType', i)}
              items={phoneTypes}
            />

            <Select
              isRequired
              label="Código de país"
              selectedValue={handleSelectedCode(phone.code, i)}
              className="item-inline"
              onSelect={handlePhoneChange('code', i)}
              items={listPhoneCodes}
            />

            <Input
              isRequired
              label="Número"
              className="item-inline"
              placeholder="Número de teléfono"
              onChange={handlePhoneChange('phone', i)}
              value={phone.phone}
            />
          </Fragment>
        ))}
      </Fields>

      <StyledButton
        className="add-phone"
        context="information"
        onClick={handleAddPhone}
        type="button">
        {fields.phones.length < PHONES_LIMIT
          ? '+ Agregar otro teléfono'
          : 'Quitar teléfono secundario'}
      </StyledButton>
    </Form>
  );
};

BillForm.propTypes = {
  onValid: PropTypes.func,
  onSubmit: PropTypes.func,
  pending: PropTypes.bool,
  phoneTypes: PropTypes.any,
  localize: PropTypes.any,
  countries: PropTypes.any,
  actions: PropTypes.object,
  userData: PropTypes.object
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Fields = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  label {
    margin-top: 20px;
  }

  h6 {
    margin: 0;
  }

  .checkbox-square {
    margin-top: 20px;
  }

  &.flex-inner {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    flex: 1 100%;
    justify-content: space-between;

    .item-inline {
      width: 32%;
    }

    @media screen and (max-width: 425px) {
      .item-inline {
        width: 100%;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  &.add-phone {
    width: 35%;
    align-self: flex-end;
    margin-top: 20px;

    @media (max-width: 464px) {
      width: 100%;
      align-self: center;
    }

    @media (max-width: 860px) {
      width: 70%;
      align-self: center;
    }

    @media (max-width: 1105px) {
      width: 72%;
      align-self: center;
    }
  }
`;

export default BillForm;
