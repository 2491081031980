/**
 *
 * DesktopHotelSearch
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setCheckIn,
  setCheckOut,
  doValidation,
  toggleMap
} from 'Features/hotels';
import DesktopHotelSearch from './DesktopHotelSearch';
import { parseIso, formatIso } from 'Utils/dates';
import { isAfter, addDays } from 'date-fns';

const selector = ({ hotels }) => ({
  checkIn: hotels.search.checkIn,
  checkOut: hotels.search.checkOut,
  showMap: hotels.search.showMap,
  checkInMessages: hotels.search.validations.checkIn,
  checkOutMessages: hotels.search.validations.checkOut
});

const ConnectedDesktopHotelSearch = props => {
  const dispatch = useDispatch();
  const state = useSelector(selector);

  const actions = {
    setCheckIn: date => {
      if (isAfter(parseIso(date), parseIso(state.checkOut))) {
        dispatch(setCheckOut(formatIso(addDays(parseIso(date), 1))));
      }
      dispatch(setCheckIn(date));
    },
    setCheckOut: date => {
      dispatch(setCheckOut(date));
    },
    doSearch: () => {
      dispatch(doValidation());
    },
    toggleMap: () => {
      dispatch(toggleMap());
    }
  };

  return <DesktopHotelSearch actions={actions} {...state} {...props} />;
};

export default ConnectedDesktopHotelSearch;
