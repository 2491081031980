import qs from 'query-string';
import { hotelSearchRequest, hotelPropertyRequest } from 'Features/hotels';
import urlToHotelRequest from 'Utils/urlToHotelRequest';

export const handleUrlChange = (location, dispatch) => {
  const queryParameters = qs.parse(location.search);
  if (queryParameters.hotelId) {
    const searchQuery = { ...queryParameters };
    // El tema del parseo es que los numeros pasan todos a string!! Y el backend espera sus tipos Numeric y Boolean.
    searchQuery.hotelId = parseInt(searchQuery.hotelId);
    searchQuery.timeoutSeconds = parseInt(searchQuery.timeoutSeconds);
    searchQuery.returnTaxAndFee = searchQuery.returnTaxAndFee === 'true';
    searchQuery.roomInformation = JSON.parse(searchQuery.roomInformation);
    // Disparo la accion del reducer con los parametros deseados
    dispatch(hotelPropertyRequest(searchQuery));
  } else if (Object.keys(queryParameters).length > 0) {
    const requestObj = urlToHotelRequest(queryParameters);
    dispatch(hotelSearchRequest(requestObj));
  }
};
