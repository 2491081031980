import { object, select } from '@storybook/addon-knobs';
import {
  IMeteorologyTemperature,
  ITemperatureList
} from 'Features/destinations/types';

export const data: IMeteorologyTemperature = {
  maxDay: {
    jan: 3,
    feb: 4,
    mar: 9,
    apr: 16,
    may: 21,
    jun: 26,
    jul: 29,
    aug: 28,
    sep: 24,
    oct: 18,
    nov: 12,
    dec: 6
  },
  minNight: {
    jan: 3,
    feb: 4,
    mar: 9,
    apr: 16,
    may: 21,
    jun: 26,
    jul: 29,
    aug: 28,
    sep: 24,
    oct: 18,
    nov: 12,
    dec: 6
  },
  rainy: {
    jan: 10,
    feb: 10,
    mar: 11,
    apr: 11,
    may: 11,
    jun: 10,
    jul: 9,
    aug: 9,
    sep: 8,
    oct: 7,
    nov: 10,
    dec: 11
  },
  ocean: {
    jan: 5,
    feb: 4,
    mar: 4,
    apr: 7,
    may: 12,
    jun: 17,
    jul: 17,
    aug: 21,
    sep: 21,
    oct: 17,
    nov: 12,
    dec: 8
  }
};

export const activeGraph = select(
  'activeGraph',
  ['maxDay', 'minNight', 'ocean', 'rainy'],
  'maxDay'
);

export const temperaturesData = object('temperaturesData', data);

export const getMinMax = (list: ITemperatureList) =>
  Object.values(list).reduce(
    (acc, val) => {
      acc.min = acc.min > val ? val : acc.min;
      acc.max = acc.max < val ? val : acc.max;
      return acc;
    },
    { min: 200000, max: 0 }
  );
