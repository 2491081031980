/**
 *
 * MobileFlightDestinationSearch
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { locationTypes } from 'Components/DestinationFlight';
import { setDestination, setOrigin } from '../../index';
import MobileFlightDestinationSearch from './MobileFlightDestinationSearch';
import { setCityKeyword } from 'Features/flights/actions';

const getValues = type => {
  switch (type) {
    case locationTypes.DESTINATION:
      return {
        action: setDestination
      };

    case locationTypes.ORIGIN:
      return {
        action: setOrigin
      };
    default:
      break;
  }
};

const ConnectedMobileFlightDestinationSearch = ({
  index = 0,
  type,
  ...props
}) => {
  const dispatch = useDispatch();
  const { action } = getValues(type);
  const actions = {
    setLocation: (location, locationName, cityKeyword) => {
      dispatch(action(index, location, locationName));
      if (cityKeyword) dispatch(setCityKeyword(cityKeyword));
    }
  };

  return <MobileFlightDestinationSearch actions={actions} {...props} />;
};

ConnectedMobileFlightDestinationSearch.propTypes = {
  index: PropTypes.number,
  type: PropTypes.oneOf(Object.values(locationTypes))
};

export default ConnectedMobileFlightDestinationSearch;
