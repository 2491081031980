/**
 *
 * MobileMpcAvailability
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { violeta, important, white, golf } from 'Constants/colors';
import MobileMPCItem from '../MobileMpcItem';
import { dateForMpc } from 'Utils/returnDate';
import Modal from 'Components/Modal';
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import ArrowSelector from 'Components/ArrowSelector';
import MobileMpcFlightDetails from '../MobileMpcFlightDetails';
import AirCheckError from '../AirCheckError';
import MpcLegend from '../MpcLegend';
import { mpcItemStatus } from 'Features/flights/constants';
import MpcItemResearch from 'Features/flights/components/MpcItemResearch';

const MobileMpcAvailability = ({
  className,
  canDecreaseInitialDate,
  canSubstract,
  handleArrowButton,
  departureDates,
  arrivalDates,
  detail,
  minPrice,
  middlePrice,
  maxPrice,
  handleShowAllFlights,
  modalData,
  showModal,
  toggleShowModal,
  handleMPCItemClick,
  arrowKeys,
  leftArrowContent,
  rightArrowContent,
  showErrorModal,
  toggleErrorModal
}) => {
  const statusByPrice = { minPrice, middlePrice, maxPrice };

  return (
    <>
      <div className={cs(className, 'container')}>
        <div className="arrow-selectors">
          <ArrowSelector
            disabledLeft={!canDecreaseInitialDate() && !canSubstract()}
            disabledRight={arrivalDates && !canSubstract()}
            content={leftArrowContent}
            onClickLeft={handleArrowButton(arrowKeys.left)}
            onClickRight={handleArrowButton(arrowKeys.right)}
          />
          {arrivalDates && (
            <ArrowSelector
              disabledLeft={!canSubstract()}
              content={rightArrowContent}
              onClickLeft={handleArrowButton(arrowKeys.up)}
              onClickRight={handleArrowButton(arrowKeys.down)}
            />
          )}
        </div>
        <div className="calendar-holder">
          <div className="departure-dates">
            {/* DEPARTURE DATES ROW */}
            {arrivalDates && <div className="empty-box"></div>}
            {departureDates.map((departureDate, i) => {
              const [day, date] = dateForMpc(departureDate).split(' ');
              return (
                <div key={departureDate} className="departure-date-item">
                  <Text context="white" weight={i !== 3 ? 'regular' : 'bold'}>
                    {day}
                  </Text>
                  <Text context="white" weight={i !== 3 ? 'regular' : 'bold'}>
                    {date}
                  </Text>
                </div>
              );
            })}
            {/* END DEPARTURE DATES ROW */}
          </div>

          <div className="dates-holder">
            {/* RETURN DATES COLUNN*/}
            <>
              {arrivalDates && (
                <div className="return-dates">
                  {arrivalDates.map((arrivalDate, i) => {
                    const [day, date] = dateForMpc(arrivalDate).split(' ');
                    return (
                      <div key={arrivalDate} className="return-date-item ">
                        <Text
                          context="white"
                          weight={i !== 3 ? 'regular' : 'bold'}>
                          {day}
                        </Text>
                        <Text
                          context="white"
                          weight={i !== 3 ? 'regular' : 'bold'}>
                          {date}
                        </Text>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
            {/* END RETURN DATES COLUMN */}
            <div className="rows-holder">
              {detail &&
                detail.map((detailRow, idxRow) => (
                  <div key={idxRow} className="mpc-row">
                    {detailRow.map((detailItem, idxCol) =>
                      detailItem.flightPrice && !detailItem.research ? (
                        <MobileMPCItem
                          key={idxCol}
                          status={statusByPrice}
                          currency="USD"
                          price={detailItem.flightPrice.totalForOneAdult}
                          description={`Por ${detailItem.flightPrice.totalPassenger} pasajeros`}
                          totalPrice={`USD ${detailItem.flightPrice.totalPrice}`}
                          onClick={handleMPCItemClick(detailItem)}
                          isCenter={detailItem.center}
                        />
                      ) : detailItem.research && !detailItem.flightPrice ? (
                        <MpcItemResearch
                          key={idxCol}
                          detailItem={detailItem}
                          row={idxRow}
                          col={idxCol}
                        />
                      ) : (
                        <MobileMPCItem
                          empty
                          key={idxCol}
                          status={mpcItemStatus.EMPTY}
                        />
                      )
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <MpcLegend />
        <div className="footer-info">
          <Icon name="mv-exlamation-circle" size={15} color="white" />
          <Text context="white">
            Se muestran precios para un adulto con tasas e impuestos incluidos
          </Text>
        </div>
      </div>
      {modalData && (
        <Modal show={showModal} transparent>
          <MobileMpcFlightDetails
            generalSegment={modalData.generalSegment}
            flightPrice={modalData.flightPrice}
            localPrice={modalData.localPrice}
            initialDate={modalData.initialDate}
            endDate={modalData.endDate}
            close={toggleShowModal}
            handleShowAllFlights={handleShowAllFlights}
            onClick={modalData.onClick}
          />
        </Modal>
      )}
      <Modal
        show={showErrorModal}
        onClickOverlay={toggleErrorModal}
        transparent>
        <AirCheckError close={toggleErrorModal} mobile />
      </Modal>
    </>
  );
};

MobileMpcAvailability.propTypes = {
  className: PropTypes.string.isRequired,
  canDecreaseInitialDate: PropTypes.func.isRequired,
  canSubstract: PropTypes.func.isRequired,
  handleArrowButton: PropTypes.func.isRequired,
  departureDates: PropTypes.array.isRequired,
  arrivalDates: PropTypes.array.isRequired,
  handleShowAllFlights: PropTypes.func.isRequired,
  toggleShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  modalData: PropTypes.object,
  handleMPCItemClick: PropTypes.func,
  arrowKeys: PropTypes.object,
  detail: PropTypes.array,
  minPrice: PropTypes.number,
  middlePrice: PropTypes.number,
  maxPrice: PropTypes.number,
  leftArrowContent: PropTypes.node,
  rightArrowContent: PropTypes.node,
  showErrorModal: PropTypes.bool,
  toggleErrorModal: PropTypes.func
};

export default styled(MobileMpcAvailability)`
  display: block;

  .calendar-holder {
    width: 100%;
    border-radius: 5px;
    background-color: #eaeceb;
    overflow: hidden;

    .departure-dates {
      height: 34px;
      background-color: ${violeta};
      display: flex;
    }

    .departure-date-item {
      display: flex;
      flex-direction: column;
      width: 14%;

      span {
        display: flex;
        width: 100%;
        height: 50%;
        justify-content: center;
        font-size: 12px;
      }
    }

    .empty-box {
      width: 15%;
      height: 32px;
    }

    .return-dates {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 14%;
      background-color: ${violeta};
    }

    .return-date-item {
      text-align: center;
      height: 34px;
      margin: 4px 0;

      span {
        display: flex;
        width: 100%;
        height: 50%;
        justify-content: center;
        font-size: 12px;
      }
    }

    .rows-holder {
      width: 100%;
    }

    .dates-holder {
      display: flex;

      .mpc-row {
        display: flex;
      }
    }
  }

  .button-bar {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;

    .context {
      padding: 5px;
      margin: 5px;
    }

    .cheaper {
      border-radius: 4px;
      box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.25);
      border: solid 2px ${important};
      background-color: ${golf};
      font-weight: bold;
      color: ${important};
    }
  }

  .footer-info {
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: ${white};

    .icon {
      margin-right: 5px;
    }
  }

  .arrow-selectors {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
  }
`;
