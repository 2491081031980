/* eslint-disable react/prop-types */
/**
 *
 * NewText
 *
 */

import React, { forwardRef } from 'react';
import styled from 'styled-components';
import {
  ThemeFont,
  ThemeFontSize,
  ThemeFontStyle,
  ThemeFontWeight,
  ThemeColor,
  TextBody
} from 'Theme';

interface NewTextProps {
  children: React.ReactNode;
  fontFamily?: ThemeFont;
  fontSize?: ThemeFontSize;
  fontStyle?: ThemeFontStyle;
  fontWeight?: ThemeFontWeight;
  color?: ThemeColor;
  type?: TextBody;
  paragraph?: boolean;
  className?: string;
}

const NewText = forwardRef<HTMLElement, NewTextProps>(
  ({ children, type = 'medium', paragraph, ...rest }, ref) => (
    <Container {...rest} type={type} as={paragraph ? 'p' : 'span'} ref={ref}>
      {children}
    </Container>
  )
);

const Container = styled.span<NewTextProps>`
  ${({ type, theme }) =>
    type ? theme.text.bodyStyle[type] : theme.text.bodyStyle.medium};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight && theme.text.fontWeights[fontWeight]};
  font-style: ${({ fontStyle }) => fontStyle && fontStyle};
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.palette.grays.meVueloGrey};
`;

export default NewText;
