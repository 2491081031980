/**
 *
 * Portal
 *
 */

import React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: React.ReactChild;
}

const Portal = ({ children }: PortalProps) => {
  const domElement = document.getElementById('modal-root');

  if (!domElement) {
    return null;
  }
  return ReactDOM.createPortal(children, domElement);
};

export default Portal;
