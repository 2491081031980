/**
 *
 * TextHeader
 *
 */

import React from 'react';
import styled from 'styled-components';
import cs from 'classnames';
import Theme from 'Theme';
import { ITextHeaderProps } from './types';

const TextHeader = ({
  className,
  size = 'm',
  children,
  context,
  transform = 'default',
  weight,
  noMargin
}: ITextHeaderProps) => {
  const classNames = cs(className, context, `text-${transform}`, weight, {
    'no-margin': noMargin
  });

  return (
    <>
      {size === 'xxl' && <h1 className={classNames}>{children}</h1>}
      {size === 'xl' && <h2 className={classNames}>{children}</h2>}
      {size === 'l' && <h3 className={classNames}>{children}</h3>}
      {size === 'm' && <h4 className={classNames}>{children}</h4>}
      {size === 's' && <h5 className={classNames}>{children}</h5>}
      {size === 'xs' && <h6 className={classNames}>{children}</h6>}
    </>
  );
};

export default styled(TextHeader)`
  font-family: Montserrat Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: ${Theme.colors.darkGrey};

  &.white {
    color: ${Theme.colors.white};
  }

  &.secondary {
    color: ${Theme.colors.secondary};
  }

  &.important {
    color: ${Theme.colors.important};
  }

  &.information {
    color: ${Theme.colors.information};
  }

  &.positive {
    color: ${Theme.colors.positive};
  }

  &.warning {
    color: ${Theme.colors.warning};
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 14px;
  }

  &.extra-bold {
    font-weight: 800;
    font-style: normal;
  }

  &.bold {
    font-weight: bold;
  }

  &.semi-bold {
    font-weight: 600;
    font-style: normal;
  }

  &.regular {
    font-style: normal;
  }

  &.italic {
    font-weight: 300;
    font-style: italic;
  }

  &.no-margin {
    margin: 0;
  }
`;
