/**
 *
 * BlogSearch
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import * as COLORS from 'Constants/colors';

import Text from 'Components/Text';
import Icon from 'Components/Icon';
import Input from 'Components/Input';

const BlogSearch = ({ className, value = '', onChange, onClick }) => (
  <div className={cs(className)}>
    <Text context="white" className="title-search">
      ¿De qu&eacute; hablamos?
    </Text>
    <div className="input-container">
      <Icon color="white" name="mv-lense" size={20} />
      <Input
        placeholder="Quiero saber de..."
        onChange={onChange}
        value={value}
      />
      {value && value.length > 3 && (
        <Icon
          className="i-close"
          color="important"
          name="mv-cross"
          onClick={onClick}
          size={20}
        />
      )}
    </div>
  </div>
);

BlogSearch.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func
};

export default styled(BlogSearch)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  flex: 1;
  margin: ${({ theme }) => theme.spacing.four} 0;

  .title-search {
    padding-top: 4px;
    margin-right: 10px;
  }

  .input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 276px;
    max-height: 30px;
    position: relative;
    z-index: 1;
    padding: 5px 10px;
    border-radius: 18px;
    box-shadow: ${COLORS.shadows.one};
    background: ${COLORS.rgba.white};

    span {
      &.icon {
        margin: 0;
        padding: 0;
        opacity: 1;
      }

      &.i-close {
        position: absolute;
        right: 8px;
        bottom: 0;
        top: 0;
        margin: auto 0;
        z-index: 2;
        cursor: pointer;
      }
    }

    .mv-input {
      width: 90%;
    }

    .mv-input-container {
      background: transparent;
      box-shadow: none;

      .mv-input-holder {
        background: transparent;
        box-shadow: none;

        .input-mevuelo {
          margin-left: 5px;
          width: 185px;
          max-height: 20px;
          outline: none;
          color: ${COLORS.white};
          border: none;
          background: transparent;
          font-family: Montserrat Arial, Helvetica, sans-serif;
          font-size: 14px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;

          ::placeholder {
            color: ${COLORS.white};
            opacity: 1;
          }
        }
      }
    }
  }
`;
