import { IUseBreakpoints } from 'Constants/types';
import bannerXXLarge from './assets/xxLarge.png';
import bannerXLarge from './assets/xLarge.png';
import bannerMedium from './assets/medium.png';
import bannerSmall from './assets/small.png';

export const returnCarrouselItems = (breakpoints: IUseBreakpoints) => {
  if (breakpoints.largeDown) {
    return 1;
  } else if (breakpoints.xlDown) {
    return 2;
  } else {
    return 3;
  }
};

export const logosColumnQty = (breakpoints: IUseBreakpoints) => {
  if (breakpoints.mediumDown) {
    return 3;
  } else if (breakpoints.largeDown) {
    return 4;
  } else {
    return 6;
  }
};

export const returnBanner = (breakpoints: IUseBreakpoints) => {
  if (breakpoints.smallDown) {
    return bannerSmall;
  } else if (breakpoints.largeDown) {
    return bannerMedium;
  } else if (breakpoints.xxlDown) {
    return bannerXLarge;
  }
  return bannerXXLarge;
};
