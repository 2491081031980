/**
 *
 * HotelSearchDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import DesktopHotelSearch from '../DesktopHotelSearch';
import MobileHotelSearchCompact from '../MobileHotelSearchCompact';
import DesktopHotelSearchCompact from '../DesktopHotelSearchCompact';
import MobileHotelSearch from '../MobileHotelSearch';
import SearchModalContainer from 'Components/SearchModalContainer';

import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { statusCodes } from 'Features/hotels/constants';

const HotelSearchDialog = ({ detailMode, status, actions }) => {
  const isMediumDown = useBreakpoints().mediumDown;
  const isReducerUnused = status === statusCodes.UNUSED;

  const setDetailMode = () => {
    actions.setDetailMode(true);
  };

  const renderMobileSearch = () =>
    isReducerUnused ? (
      <MobileHotelSearch />
    ) : (
      <SearchModalContainer close={setDetailMode}>
        <MobileHotelSearch />
      </SearchModalContainer>
    );

  if (detailMode) {
    if (isMediumDown) {
      return <MobileHotelSearchCompact />;
    }
    return <DesktopHotelSearchCompact />;
  }
  if (isMediumDown) {
    return renderMobileSearch();
  } else {
    return <DesktopHotelSearch />;
  }
};

HotelSearchDialog.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  detailMode: PropTypes.bool,
  setDetailMode: PropTypes.func,
  isReducerUnused: PropTypes.bool,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  roomInformation: PropTypes.array,
  location: PropTypes.shape({
    placeId: PropTypes.string,
    description: PropTypes.string,
    iconType: PropTypes.string
  }),
  status: PropTypes.string,
  search: PropTypes.object
};

export default HotelSearchDialog;
