/**
 *
 * Text
 *
 */

import React from 'react';
import styled, { css } from 'styled-components';
import cs from 'classnames';
import {
  important,
  information,
  positive,
  warning,
  blue,
  darkGrey,
  arena,
  white,
  secondary,
  violeta,
  newBackground
} from 'Constants/colors';
import { TextProps } from 'Components/Text/types';

const Text = ({
  className,
  children,
  context = 'default',
  size,
  weight,
  paragraph,
  h1,
  noWrap
}: TextProps) => {
  if (h1) {
    return (
      <h1 className={cs(className, context, size, weight, { noWrap })}>
        {children}
      </h1>
    );
  }
  if (paragraph) {
    return (
      <p className={cs(className, context, size, weight, { noWrap })}>
        {children}
      </p>
    );
  }
  return (
    <span className={cs(className, context, size, weight, { noWrap })}>
      {children}
    </span>
  );
};

export default styled(Text)<TextProps>`
  font-family: Montserrat, serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 14px;
  margin-bottom: 0;
  text-align: ${({ alignment }) => alignment || 'left'};

  ${({ isUppercase }) =>
    isUppercase &&
    css`
      text-transform: uppercase;
    `}

  &.default {
    color: ${darkGrey};
  }

  &.arena {
    color: ${arena};
  }

  &.white {
    color: ${white};
  }

  &.primary {
    color: ${violeta};
  }

  &.secondary {
    color: ${secondary};
  }

  &.important {
    color: ${important};
  }

  &.information {
    color: ${information};
  }

  &.positive {
    color: ${positive};
  }

  &.blue {
    color: ${blue};
  }

  &.warning {
    color: ${warning};
  }

  &.violeta {
    color: ${violeta};
  }

  &.wine {
    color: ${newBackground};
  }

  &.xlarge {
    font-size: 24px !important;
  }

  &.xxlarge {
    font-size: 36px;
  }

  &.xmlarge {
    font-size: 18px;
  }
  &.large {
    font-size: 16px;
  }

  &.small {
    font-size: 12px;
  }

  &.extra-bold {
    font-weight: 800;
    font-style: normal;
  }

  &.bold {
    font-weight: bold;
  }

  &.semi-bold {
    font-weight: 600;
    font-style: normal;
  }

  &.regular {
    font-style: normal;
  }

  &.italic {
    font-weight: 300;
    font-style: italic;
  }

  &.noWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
