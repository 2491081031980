import React from 'react';
import Text from 'Components/Text';

const AirFlexTextCommon = () => (
  <Text weight="bold" context="white">
    FLEX:{' '}
  </Text>
);

export default AirFlexTextCommon;
