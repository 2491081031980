/**
 *
 * QuotationsSearch
 *
 */

import React from 'react';
import styled from 'styled-components';
import NewCalendar from 'Components/NewCalendar';
import NewSelect from 'Components/NewSelect';
import NewInput from 'Components/NewInput';
import Label from 'Components/Label';

interface QuotationsSearchProps {
  searchString?: string;
  orderBy?: string;
  onChange: (
    name: 'orderBy' | 'searchString' | 'fromDate' | 'endDate'
  ) => (value: string) => void;
  fromDate?: string;
  endDate?: string;
  fromDateError?: string;
  endDateError?: string;
  searchStringError?: string;
  orderByError?: string;
}

const QuotationsSearch = ({
  searchString,
  orderBy,
  onChange,
  fromDate,
  endDate,
  fromDateError,
  endDateError,
  searchStringError,
  orderByError
}: QuotationsSearchProps) => (
  <Container>
    <FlexItem flex={3}>
      <Label color="orange">Buscar</Label>
      <NewInput
        value={searchString}
        onChange={e => onChange('searchString')(e.target.value)}
        placeholder="Título, ID, Nombre, Email, Alojamiento"
        icon={{ name: 'mv-lense', color: 'orange' }}
        error={{ message: searchStringError }}
      />
    </FlexItem>
    <FlexItem flex={2}>
      <Label color="orange">Fechas</Label>
      <NewCalendar
        startLabel="Ida"
        endLabel="Vuelta"
        onChangeStart={onChange('fromDate')}
        onChangeEnd={onChange('endDate')}
        color={{
          primaryColor: 'orange',
          lightColor: 'orangeLight'
        }}
        startDate={fromDate}
        endDate={endDate}
        startError={{ message: fromDateError }}
        endError={{ message: endDateError }}
        isRange
      />
    </FlexItem>
    <FlexItem flex={1} last>
      <Label color="orange">Ordenar por</Label>
      <NewSelect
        color="orange"
        items={['Fecha Asc', 'Fecha Desc']}
        itemToString={item => item}
        label="Seleccióne una"
        onItemSelected={onChange('orderBy')}
        initialSelectedItem={orderBy}
        error={{ message: orderByError }}
      />
    </FlexItem>
  </Container>
);

const Container = styled.div`
  max-width: 1110px;
  padding: ${({ theme }) => `${theme.spacing.four}`};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.four};
  display: flex;
  margin: 0 auto;
`;

interface FlexItem {
  flex?: number;
  last?: boolean;
}
const FlexItem = styled.div<FlexItem>`
  flex: ${({ flex }) => (flex ? flex : 1)};
  margin-right: ${({ theme, last }) => !last && theme.spacing.two};
`;

export default QuotationsSearch;
