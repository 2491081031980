/**
 *
 * Card
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Icon from '../Icon';
import {
  violeta,
  white,
  secondary,
  important,
  information,
  positive,
  warning,
  golf
} from 'Constants/colors';

const CardHeader = ({
  className,
  children,
  context,
  borderRadius,
  onClose,
  headerCentered = false
}) => (
  <div
    className={cs('mv-mevuelo', className, context, borderRadius, {
      'text-center': headerCentered
    })}>
    <div className="header-content">{children}</div>

    {onClose && typeof onClose === 'function' && (
      <button onClick={onClose} className="header-action">
        <Icon name="mv-cross" color="white" />
      </button>
    )}
  </div>
);

CardHeader.propTypes = {
  className: PropTypes.any,
  context: PropTypes.string,
  children: PropTypes.any,
  borderRadius: PropTypes.string,
  headerCentered: PropTypes.bool,
  onClose: PropTypes.func
};

const StyledCardHeader = styled(CardHeader)`
  color: ${white};
  background-color: ${violeta};
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.1px;
  text-align: left;
  padding: 9px 13px 7px 13px;
  text-transform: ${props => (props.noUppercase ? '' : 'uppercase')};
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.square {
    border-radius: 0;
  }

  &.secondary {
    background-color: ${secondary};
  }

  &.important {
    background-color: ${important};
  }

  &.information {
    background-color: ${information};
  }

  &.positive {
    background-color: ${positive};
  }

  &.warning {
    background-color: ${warning};
  }

  &.arena {
    background-color: ${golf};
    color: ${important};
  }

  .header {
    &-action {
      border: 0;
      background: transparent;
      color: ${white};
      font-weight: bold;
      font-size: 20px;
    }
  }
`;

const Card = ({
  className,
  header,
  context = 'default',
  children,
  borderRadius = 'default',
  headerCentered = false,
  boxShadow = false,
  noUppercase = false,
  onClose
}) => (
  <div className={cs(className, borderRadius, { boxShadow })}>
    {header && (
      <StyledCardHeader
        headerCentered={headerCentered}
        className={cs(borderRadius, context)}
        onClose={onClose}
        noUppercase={noUppercase}>
        {header}
      </StyledCardHeader>
    )}

    <div className="mv-card-content">{children}</div>
  </div>
);

Card.propTypes = {
  className: PropTypes.any,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  context: PropTypes.string,
  children: PropTypes.any,
  borderRadius: PropTypes.string,
  headerCentered: PropTypes.bool,
  boxShadow: PropTypes.bool,
  onClose: PropTypes.func,
  noUppercase: PropTypes.bool,
  noPadding: PropTypes.bool
};

export default styled(Card)`
  background-color: ${white};
  border-radius: 14px;
  position: relative;
  height: 100%;

  &.big {
    border-radius: 50px;
    padding: 30px;
  }

  &.boxShadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
  }

  &.square {
    border-radius: 0;
  }

  & > .mv-card-content {
    padding: ${props => (props.noPadding ? 0 : 12)}px;
  }

  .header-content {
    width: 100%;
  }
`;
