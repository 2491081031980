/**
 *
 * PaymentMethod
 *
 */

// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Component
import PaymentMethod from './PaymentMethod';

// Actions
import { storageUserData } from '../../ReduxConfig/checkout';

// Constants
import { selector } from './PaymentMethod.selector';

const ConnectedPaymentMethod = props => {
  const state = useSelector(selector);
  const dispatch = useDispatch();
  const actions = {
    setUserData: data => dispatch(storageUserData(data))
  };

  return <PaymentMethod actions={actions} {...props} {...state} />;
};

export default ConnectedPaymentMethod;
