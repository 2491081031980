export const initialState = {
  flightsConfig: {},
  hotelsConfig: {},
  packagesConfig: {},
  paymentsConfig: {},
  sharedConfig: {},
  localize: {},
  sites: [],
  engines: [],
  loader: false,
  pending: false,
  navBarSmallMode: false,
  hideNavbar: false,
  errorBoundaryKey: 0
};
