import cs from 'classnames';
import React, { useState } from 'react';
import useBreakpoints from '../../../../Utils/hooks/useBreakpoints';
import { Product } from '../Product';
import products from '../../products.json';
import * as Styles from './styles';

export const SlideList = ({ slideList, dividerTitle }: Props) => {
  const { largeDown, mediumDown } = useBreakpoints();

  const itemClassName = cs('item', {
    medium: mediumDown,
    large: largeDown
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const length = slideList.length;

  const next = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex(prevState => prevState + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1);
    }
  };

  return (
    <Styles.SlideListContainer>
      <div>
        {currentIndex > 0 && !mediumDown && (
          <button onClick={prev} className="left-arrow">
            &lt;
          </button>
        )}
        <div
          className={cs(
            'more-slides-mobile',
            { large: largeDown },
            { medium: mediumDown }
          )}
        >
          {products.map((item, index) => (
            <div
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              className={itemClassName} >
              <Product key={index} product={item}
              />
            </div>
          ))}
        </div>
        {currentIndex < length - 1 && !mediumDown && (
          <button onClick={next} className="right-arrow">
            &gt;
          </button>
        )}
      </div>
    </Styles.SlideListContainer>
  );
};

interface Props {
  slideList: ProductProps[];
  dividerTitle?: string;
};

interface ProductProps {
  id: string;
  title: string;
  price: string;
  image: string;
  link: string;
  currency: string;
  fees: string;
};