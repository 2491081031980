/**
 *
 * Wizard
 *
 */

import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { CSSTransition } from 'react-transition-group';

// Components
import Stepper from 'Components/Stepper';
import Button from 'Components/Button';
import MobileNavbar from 'Components/MobileNavBar';
import MobileNavbarPrice from 'Components/MobileNavBarPrice';
import Card from 'Components/Card';
import Icon from 'Components/Icon';

// Utils
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { toMoney } from 'Utils/transforms';

const Wizard = ({
  className,
  steps,
  withBackButton,
  header,
  hiddenElement,
  onClose,
  onFinish,
  containerRef
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsAllowed, setStepsAllowed] = useState([0]);
  const [showDetails, setShowDetails] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [isContinuable, setIsContinuable] = useState(false);
  const isLastStep = currentStep === steps.length - 1;
  const breakpoints = useBreakpoints();
  const stepTitles = useMemo(() => steps.map(s => s.name), [steps]);
  const [isButtonNextDisabled, setIsButtonNextDisabled] = useState(false);

  const handleClickNext = () => {
    setCurrentStep(old => old + 1);
    setAnimate(false);
    setStepsAllowed(old => [...old, currentStep + 1]);
  };

  const handleClickBack = () => {
    setCurrentStep(old => old - 1);
    setAnimate(false);
  };

  const handleClickStep = step => {
    if (step === currentStep) {
      return;
    }

    if (stepsAllowed.includes(step)) {
      setCurrentStep(step);
      setAnimate(false);
    }
  };

  const handleTabDetails = () => {
    setShowDetails(old => !old);
  };

  useEffect(() => {
    setAnimate(true);
  }, [animate]);

  useEffect(() => {
    if (breakpoints.mediumDown) {
      if (containerRef.current) {
        containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentStep]);

  const isDisableNextOrFinishButton = () =>
    (steps[currentStep].byPass ? false : !isContinuable) ||
    isButtonNextDisabled;

  const handleDisableButtonNext = () => {
    setIsButtonNextDisabled(true);
    setTimeout(() => setIsButtonNextDisabled(false), 1000);
  };
  const handleClickNextOrFinishButton = () => {
    if (isLastStep) {
      handleDisableButtonNext();
      onFinish();
    } else {
      handleDisableButtonNext();
      handleClickNext();
    }
  };

  return (
    <Card
      headerCentered
      header={header}
      onClose={onClose}
      borderRadius={breakpoints.mediumDown ? 'square' : undefined}
      className={cs('mv-wizard', className)}>
      <Stepper
        activeStep={currentStep}
        steps={stepTitles}
        onStepClick={handleClickStep}
        stepsAllowed={stepsAllowed}
      />

      <div className="mv-wizard__body">
        <CSSTransition in={animate} timeout={370} classNames="mv-wizard__body-">
          {steps[currentStep].render({
            onValid: setIsContinuable
          })}
        </CSSTransition>
      </div>

      {!breakpoints.mediumDown && (
        <div className="mv-wizard__footer">
          {withBackButton && (
            <Button onClick={handleClickBack} disabled={!currentStep} left>
              ANTERIOR
            </Button>
          )}

          <Button
            onClick={handleClickNextOrFinishButton}
            disabled={isDisableNextOrFinishButton()}
            right>
            {isLastStep ? 'FINALIZAR' : 'CONTINUAR'}
          </Button>
        </div>
      )}

      {breakpoints.mediumDown && (
        <MobileNavbar show={showDetails} hiddenElement={hiddenElement.render}>
          <Button
            onClick={showDetails ? handleTabDetails : handleClickBack}
            disabled={!currentStep && !showDetails}
            size="lg"
            left>
            {showDetails && (
              <Icon name="mv-arrow-down" size={20} color="white" />
            )}

            {!showDetails && (
              <Icon name="mv-arrow-left" size={20} color="white" />
            )}
          </Button>

          <MobileNavbarPrice
            topText="Precio total final"
            middleText={toMoney(hiddenElement.price, 'USD')}
            onClick={handleTabDetails}
            hasIcon
          />

          <Button
            onClick={handleClickNextOrFinishButton}
            disabled={isDisableNextOrFinishButton()}
            right>
            {isLastStep ? 'FINALIZAR' : 'CONTINUAR'}
          </Button>
        </MobileNavbar>
      )}
    </Card>
  );
};

Wizard.propTypes = {
  className: PropTypes.string,
  withBackButton: PropTypes.bool,
  steps: PropTypes.array.isRequired,
  header: PropTypes.string,
  hiddenElement: PropTypes.object,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  containerRef: PropTypes.object
};

export default styled(Wizard)`
  display: block;
  overflow: hidden;

  .mv-wizard {
    &__body {
      padding: 20px;

      &--enter {
        transform: translateX(calc(-100% - 20px));
      }

      &--enter-active {
        transform: translateX(0);
        transition: transform 200ms;
      }

      &--exit {
        transform: translateX(0);
      }

      &--exit-active {
        transform: translateX(calc(100% + 20px));
        transition: transform 200ms;
      }

      @media screen and (max-width: 768px) {
        padding: 20px 0 100px 0;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      margin-left: -12px;
      margin-right: -12px;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
`;
