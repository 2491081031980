/**
 *
 * DestinationInfo
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import { DestinationInfoProps } from './types';

const DestinationInfo = ({
  name,
  description,
  color = 'default',
  className
}: DestinationInfoProps) => (
  <div className={cs(className, 'destination-content')}>
    <Text context={color} size="xxlarge">
      {name}
    </Text>

    {description && (
      <Text
        className="destination-content__description"
        size="large"
        context={color}
        paragraph>
        {description}
      </Text>
    )}
  </div>
);

DestinationInfo.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  color: PropTypes.string
};

export default styled(DestinationInfo)`
  display: flex;
  flex-direction: column;
  background: rgba(49, 23, 75, 0.5);
  padding: 95px 32px 32px 32px;
  border-radius: 0 0 14px 14px;
  max-width: 562px;
  margin-bottom: 48px;

  @media all and (max-width: 768px) {
    padding: 10px;
    margin: 0;
  }

  .destination-content {
    display: flex;

    &__description {
      text-align: justify;

      @media all and (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;
