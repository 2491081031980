import React, { useState } from 'react';

import QuotationsService from './QuotationsService';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { QuotationsFields } from './types';
import { IQuotationService } from 'Services/Quotations/types';
import API from 'Services/Quotations';
import { getInitialValues } from './helpers';
import { getQuotation } from 'Features/quotations/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ICostCardOnChangePayload } from '../CostCard';
import { toast } from 'react-toastify';
import { RootState } from 'ReduxConfig/store';
interface ConnectedQuotationsServiceProps {
  service?: IQuotationService;
  number: string | number;
  quotationId: number;
  onCancelNew: () => void;
}

const passengerSelector = ({ quotations }: RootState) =>
  quotations.detail.totalPassenger;

const ConnectedQuotationsService = ({
  quotationId,
  number,
  service,
  onCancelNew
}: ConnectedQuotationsServiceProps) => {
  const passengers = useSelector(passengerSelector);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const openConfirmation = () => setConfirmationOpen(true);
  const closeConfirmation = () => setConfirmationOpen(false);
  const initialValues = getInitialValues(service);
  const dispatch = useDispatch();
  const { values, setFieldValue, errors, submitForm } = useFormik<
    QuotationsFields
  >({
    initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required(),
      description: Yup.string().required(),
      totalPax: Yup.string().required()
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ dateType, dateTo, dateFrom, ...rest }) => {
      const payload: IQuotationService = {
        ...rest,
        dateType,
        dateTo: dateType > 2 ? dateTo : undefined,
        dateFrom: dateType > 1 ? dateFrom : undefined,
        totalPax: passengers
      };
      if (service) {
        try {
          await API.putService(quotationId, payload, service.id);
          toast.success('El servicio se actualizó con éxito');
        } catch (error) {
          toast.error(error.message);
        }
      } else {
        try {
          await API.patchService(quotationId, payload);
          toast.success('El servicio se agregó con éxito');
        } catch (error) {
          toast.error(error.message);
        }
      }
      dispatch(getQuotation(quotationId));
    }
  });

  const handleChange = (key: keyof QuotationsFields) => (
    value:
      | QuotationsFields[keyof QuotationsFields]
      | React.FormEvent<HTMLInputElement>
  ) => {
    if (typeof value === 'object') {
      setFieldValue(key, value.currentTarget.value);
    } else {
      setFieldValue(key, value);
    }
  };

  const handleCostChange = ({ markup, cost }: ICostCardOnChangePayload) => {
    setFieldValue('markup', markup);
    setFieldValue('cost', cost);
  };

  const handleDelete = async () => {
    if (number === 'Nuevo') {
      onCancelNew();
      return;
    }
    try {
      await API.deleteService(quotationId, service.id);
      toast.success('El servicio se eliminó con éxito');
      dispatch(getQuotation(quotationId));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSave = () => {
    submitForm();
  };

  return (
    <QuotationsService
      fields={values}
      errors={errors}
      onChange={handleChange}
      onCostChange={handleCostChange}
      onDelete={handleDelete}
      onSave={handleSave}
      number={number}
      confirmationOpen={confirmationOpen}
      openConfirmation={openConfirmation}
      closeConfirmation={closeConfirmation}
      passengers={passengers}
    />
  );
};

export default ConnectedQuotationsService;
