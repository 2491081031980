import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCombobox } from 'downshift';
import Icon from '../Icon';
import API from '../../Services';
import { searchDebounceTime } from 'Constants';
import spinner from 'Assets/loader/spinner.svg';
import { useDebounceState } from 'Hooks/useDebounceState';



export interface DestinationFlightActions {
  setLocation: (
    location: string,
    locatioName: string,
    cityKeyword: string
  ) => void;
}

export interface DestinationFlightProps {
  actions: DestinationFlightActions;
  locationName: string;
  label: string;
  placeHolder: string;
  right?: boolean;
  messages: string[];
}

interface DestinationFlightsState {
  cities: Suggestion[];
  airports: Suggestion[];
  pending: boolean;
  hasSearched: boolean;
  cache: {};
}

interface Suggestion {
  code: string;
  name: string;
  cityKeyword: string;
  type: string;
  numberOfTimes: number;
}

const initialState: DestinationFlightsState = {
  cities: [],
  airports: [],
  pending: false,
  hasSearched: false,
  cache: {}
};

const DestinationFlight = ({
  actions,
  locationName,
  label,
  placeHolder,
  right,
  messages
}: DestinationFlightProps) => {
  const [state, setState] = useState<DestinationFlightsState>(initialState);
  const [searchValue, setSearchValue] = useState<string>('');

const fetchSuggestions = useCallback(async (value: string) => {
    setState(prevState => ({ ...prevState, pending: true }));
    const { cache } = state;
    let data;
    if (cache[value]) {
      data = cache[value];
    } else {
      const response = await API.flights.suggestions(value);
      data = response.data;
    }
    setState(prevState => ({
      ...prevState,
      cities: data.cities,
      airports: data.airports,
      pending: false,
      hasSearched: true,
      cache: { ...prevState.cache, [value]: data }
    }));
  }, [state, setState]);

  const debouncedSearch: string = useDebounceState(searchValue, searchDebounceTime.DESKTOP);

  useEffect(() => {
    if (debouncedSearch.length < 3) return;
    fetchSuggestions(debouncedSearch);
  }, [debouncedSearch])



  const handleChange = ({ inputValue }): void => {
    if (inputValue.length < 3) return;
    setSearchValue(inputValue);
  };

  const onSelectSuggestion = ({ code, name, cityKeyword }: Suggestion) => {
    actions.setLocation(code, name, cityKeyword);
    setState(prevState => ({ ...prevState, openMenu: false }));
  };

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    setInputValue
  } = useCombobox({
    items: [...state.cities, ...state.airports],
    itemToString: item => item?.name,
    onInputValueChange: handleChange,
    onSelectedItemChange: changes => {
      if (changes.selectedItem) {
        onSelectSuggestion(changes.selectedItem);
      }
    },
    initialSelectedItem: { name: locationName } as Suggestion
  });

  const setInputValueRef = useCallback(setInputValue, []);

  useEffect(() => {
    setInputValueRef(locationName);
  }, [locationName, setInputValueRef]);

  const { cities, airports, pending } = state;

  return (
    <Container>
      <Label {...getLabelProps()}>{label}</Label>
      <InputContainer {...getComboboxProps()}>
        <ErrorMessage show={messages.length > 0}>
          {messages.length > 0 && messages[0]}
        </ErrorMessage>
        <Input
          type="text"
          {...getInputProps()}
          placeholder={placeHolder}
          defaultValue={locationName}
        />
        <LocationIcon name="mv-location-2" color="important" size={20} />
        {/* @ts-ignore */}
        <Loader loading={isOpen && pending} src={spinner} />
      </InputContainer>
      <SuggestionList {...getMenuProps()} right={right}>
        {isOpen && !pending && (cities.length > 0 || airports.length > 0) && (
          <>
            <SuggestionTitle>Ciudades</SuggestionTitle>
            {cities.map((city, index) => (
              <SuggestionItem
                selected={highlightedIndex === index}
                {...getItemProps({ item: city, index })}
                key={city.code}>
                <Icon name="mv-cities" />

                {city.name}
              </SuggestionItem>
            ))}

            <SuggestionTitle>Aeropuertos</SuggestionTitle>
            {airports.map((airport, i) => (
              <SuggestionItem
                selected={highlightedIndex === i + cities.length}
                key={airport.code}
                {...getItemProps({
                  item: airport,
                  index: i + cities.length
                })}>
                <Icon name="mv-plane-ida" />
                {airport.name}
              </SuggestionItem>
            ))}
          </>
        )}
      </SuggestionList>
    </Container>
  );
};

export default DestinationFlight;

const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;

interface SuggestionListProps {
  right?: boolean;
}

const SuggestionList = styled.div<SuggestionListProps>`
  width: 200%;
  position: absolute;
  ${({ right }) => (right ? 'right: 0' : 'left: 0')};
  max-height: 200px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 20;
  box-shadow: ${({ theme }) => theme.shadows.one};
  overflow-y: auto;
  top: 100%;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  ${({ theme }) => theme.scrollbar(theme.colors.important)}
`;

interface SuggestionProps {
  selected?: boolean;
}

const SuggestionItem = styled.div<SuggestionProps>`
  display: flex;
  padding: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.meVueloGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  font-size: 14px;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  ${({ selected, theme }) =>
    selected && `background: ${theme.colors.lightGrey}`};
  .icon {
    margin-right: ${({ theme }) => theme.spacing.two};
  }
`;
// @ts-ignore
const SuggestionTitle = styled(SuggestionItem)`
  color: ${({ theme }) => theme.colors.darkBlue};
  padding-left: ${({ theme }) => theme.spacing.four};
  background-color: ${({ theme }) => theme.colors.arena};
  box-shadow: ${({ theme }) => theme.shadows.one};
  pointer-events: none;
`;

const Label = styled.label`
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 0.96;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.orange};
`;

interface LoaderProps {
  loading?: boolean;
}

const Loader = styled.img<LoaderProps>`
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 10px;
  width: 22px;
  height: 22px;
  transition: opacity 200ms ease-in-out;
  ${({ loading }) => loading && 'opacity: 1'};
  z-index: 2;
`;

const Input = styled.input`
  height: 33px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.meVueloGrey};
  border-radius: 14px;
  padding: ${({ theme }) => theme.spacing.one};
  padding-left: 34px;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.one};
  z-index: 1;
  position: relative;
  border: none;
  text-overflow: ellipsis;

  :focus {
    border: none;
    outline: none;
  }
`;

const InputContainer = styled.div`
  position: relative;
`;

interface ErrorMessageProps {
  show?: boolean;
}

const ErrorMessage = styled.div<ErrorMessageProps>`
  position: absolute;
  width: 100%;
  height: 33px;
  z-index: 0;
  background-color: ${({ theme }) => theme.colors.important};
  box-shadow: ${({ theme }) => theme.shadows.one};
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 600;
  opacity: 0;
  transition: all 750ms ease-in-out;
  ${({ show }) =>
    show &&
    `
  transform: translateY(50%);
  opacity: 1;
  `};
`;

const LocationIcon = styled(Icon)`
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 2;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

