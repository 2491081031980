import React from 'react';
import PropTypes from 'prop-types';

import Input from 'Components/Input';
import Text from 'Components/Text';
import { useFormik } from 'formik';
import { initialFieldState } from '../HolderFormCommon/HolderForm.helpers';
import * as Yup from 'yup';
import { formErrors } from '../../Constants';

export const HolderFieldsCorpo = ({
  title,
  roomIndex,
  onSubmit,
  rateKey,
  initialData
}) => {
  // Memoize function
  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    touched,
    isValid
  } = useFormik({
    initialValues: {
      ...initialFieldState,
      ...initialData,
      rateKey
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: Yup.object({
      firstName: Yup.string().min(4, formErrors.lessThanFour),
      lastName: Yup.string().min(4, formErrors.lessThanFour)
    }),
    onSubmit: fields => onSubmit(fields, roomIndex, isValid)
  });

  return (
    <>
      <Text weight="bold" isUppercase className="title">
        Habitacion {roomIndex + 1}: {title}
      </Text>

      <div className="fields">
        <Input
          isRequired
          className="grid-2"
          placeholder="Nombre"
          label="Nombre"
          value={values.firstName}
          message={touched.firstName && errors.firstName}
          context={touched.firstName && errors.firstName && 'warning'}
          onChange={value => setFieldValue('firstName', value)}
          onBlur={handleSubmit}
        />

        <Input
          isRequired
          className="grid-2"
          placeholder="Apellido"
          label="Apellido"
          value={values.lastName}
          message={touched.lastName && errors.lastName}
          context={touched.lastName && errors.lastName && 'warning'}
          onChange={value => setFieldValue('lastName', value)}
          onBlur={handleSubmit}
        />
      </div>
    </>
  );
};

HolderFieldsCorpo.propTypes = {
  title: PropTypes.string,
  roomIndex: PropTypes.number,
  localize: PropTypes.object,
  phoneTypes: PropTypes.array,
  places: PropTypes.array,
  onSubmit: PropTypes.func,
  rateKey: PropTypes.string,
  initialData: PropTypes.object
};
