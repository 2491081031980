// vendor
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

// utils
import { packageSteps, packagesWhenType } from 'Constants';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

// styles
import styles from './DesktopPackageSearch.styles';

// Components
import PackageSearchIcon from '../PackageSearchIcon';
import Text from 'Components/Text';
import Card from 'Components/Card';
import Input from 'Components/Input';
import MenuTab from 'Components/MenuTab';
import Tag from 'Components/Tag';
import Label from 'Components/Label';
import NewSelect from '../../../../Components/NewSelect';

const calcIconHolderStyles = (isXlDown, numberOfItems) => {
  if (!isXlDown) {
    return null;
  }
  const iconSize = 120;
  return {
    width: numberOfItems * iconSize
  };
};

const DesktopPackageSearch = ({
  className,
  actions,
  when,
  where,
  how,
  orderBy,
  orderByOptions,
  departureOptions,
  selectedDepartureOption,
  handleSelectDeparture,
  region,
  stateWhen,
  stateHow,
  quantity,
  selectedTabId,
  text
}) => {
  const { xlDown } = useBreakpoints();

  const renderRegions = () =>
    where && (
      <div className="icons-list_holder">
        <div
          className="icons-list wrap"
          style={calcIconHolderStyles(xlDown, where.length)}>
          {where.map(d => (
            <PackageSearchIcon
              onClick={actions.handleSelectRegion(d)}
              context={region && region.id === d.id ? 'active' : 'default'}
              key={d.id}
              name={d.name}
              icon={d.icon}
              labelPosition="bottom"
            />
          ))}
        </div>
      </div>
    );

  const renderMonths = () =>
    when.months && (
      <div className="icons-list_holder">
        <div className="icons-list wrap">
          {when.months.map(d => (
            <PackageSearchIcon
              onClick={actions.handleSelectMonth(d)}
              context={
                stateWhen &&
                stateWhen.type === packagesWhenType.MONTH &&
                stateWhen.option &&
                stateWhen.option.id === d.id
                  ? 'active'
                  : 'default'
              }
              key={d.id}
              name={d.name}
              icon={d.icon}
              size="small"
              noText
            />
          ))}
        </div>
      </div>
    );

  const renderSeasons = () =>
    when.seasons && (
      <div className="icons-list_holder">
        <div
          className="icons-list wrap"
          style={calcIconHolderStyles(xlDown, when.seasons.length)}>
          {when.seasons.map(d => (
            <PackageSearchIcon
              onClick={actions.handleSelectSeason(d)}
              context={
                stateWhen &&
                stateWhen.type === packagesWhenType.SEASON &&
                stateWhen.option &&
                stateWhen.option.id === d.id
                  ? 'active'
                  : 'default'
              }
              key={d.id}
              name={d.name}
              icon={d.icon}
              labelPosition="bottom"
            />
          ))}
        </div>
      </div>
    );

  const renderHow = () =>
    how && (
      <div className="icons-list_holder">
        <div
          className="icons-list wrap"
          style={calcIconHolderStyles(xlDown, how.length)}>
          {how.map(d => (
            <PackageSearchIcon
              onClick={actions.handleSelectHow(d)}
              context={stateHow && stateHow.id === d.id ? 'active' : 'default'}
              key={d.id}
              name={d.name}
              icon={d.icon}
              labelPosition="bottom"
            />
          ))}
        </div>
      </div>
    );

  const renderSteps = () =>
    packageSteps && (
      <>
        <div
          className={cs('package-search-tabs_item', {
            active: packageSteps[1].id === selectedTabId
          })}
          key={packageSteps[1].id}
          onClick={actions.handleSelectTab(packageSteps[1].id)}>
          <Text
            weight="semi-bold"
            size="large"
            context={
              packageSteps[1].id === selectedTabId ? 'white' : 'important'
            }>
            {stateWhen === null || stateWhen.option === null
              ? packageSteps[1].label
              : stateWhen.option.name}
          </Text>
        </div>
        <div
          className={cs('package-search-tabs_item', {
            active: packageSteps[0].id === selectedTabId
          })}
          key={packageSteps[0].id}
          onClick={actions.handleSelectTab(packageSteps[0].id)}>
          <Text
            weight="semi-bold"
            size="large"
            context={
              packageSteps[0].id === selectedTabId ? 'white' : 'important'
            }>
            {region === null ? packageSteps[0].label : region.name}
          </Text>
        </div>
        <div
          className={cs('package-search-tabs_item', {
            active: packageSteps[2].id === selectedTabId
          })}
          key={packageSteps[2].id}
          onClick={actions.handleSelectTab(packageSteps[2].id)}>
          <Text
            weight="semi-bold"
            size="large"
            context={
              packageSteps[2].id === selectedTabId ? 'white' : 'important'
            }>
            {stateHow === null ? packageSteps[2].label : stateHow.name}
          </Text>
        </div>
      </>
    );

  return (
    <div className={cs(className)}>
      <div className="package-search_top-card">
        <div className="package-search-tabs_holder">{renderSteps()}</div>

        <div className="package-search-options_holder">
          {selectedTabId === packageSteps[0].id && <>{renderRegions()}</>}

          {selectedTabId === packageSteps[1].id && (
            <>
              {renderSeasons()}
              {renderMonths()}
            </>
          )}

          {selectedTabId === packageSteps[2].id && <>{renderHow()}</>}
        </div>
      </div>
      <div className="package-search_bottom-card">
        <Card boxShadow>
          <div className="form-item space-between">
            {departureOptions.length > 1 && (
              <div className="form-item">
                <Label>Saliendo desde</Label>
                <NewSelect
                  items={departureOptions}
                  initialSelectedItem={selectedDepartureOption}
                  itemToString={item => item.name}
                  onItemSelected={handleSelectDeparture}
                />
              </div>
            )}
            <div className="form-item">
              <Label>Filtrar Por</Label>
              <Input
                context="violeta"
                icon="mv-lense"
                placeholder="Ej: Miami"
                value={text}
                onChange={actions.handleFilterByText}
              />
            </div>
            <div className="form-item to-right">
              <Text context="violeta" weight="bold">
                Hay {quantity} paquetes disponibles
              </Text>
            </div>
          </div>
        </Card>
      </div>

      <div className="tags-holder">
        {!(stateWhen === null || stateWhen.option === null) && (
          <Tag onClick={actions.cleanWhen}>{stateWhen.option.name}</Tag>
        )}
        {region !== null && (
          <Tag onClick={actions.cleanRegion}>{region.name}</Tag>
        )}
        {stateHow !== null && (
          <Tag onClick={actions.cleanHow}>{stateHow.name}</Tag>
        )}
      </div>

      <MenuTab>
        <MenuTab.Item
          active={orderBy === orderByOptions.NOMBRE}
          onClick={actions.handleUpdateOrderBy(orderByOptions.NOMBRE)}>
          {orderByOptions.NOMBRE}
        </MenuTab.Item>

        <MenuTab.Item
          active={orderBy === orderByOptions.PRECIO}
          onClick={actions.handleUpdateOrderBy(orderByOptions.PRECIO)}>
          {orderByOptions.PRECIO}
        </MenuTab.Item>

        <MenuTab.Item
          active={orderBy === orderByOptions.DURACION}
          onClick={actions.handleUpdateOrderBy(orderByOptions.DURACION)}>
          {orderByOptions.DURACION}
        </MenuTab.Item>
      </MenuTab>
    </div>
  );
};

DesktopPackageSearch.propTypes = {
  //TODO remplazar todos los 'any' por lo que realmente aplique
  className: PropTypes.string.isRequired,
  responseData: PropTypes.any,
  onChange: PropTypes.func,
  actions: PropTypes.object,
  departures: PropTypes.array,
  departureOptions: PropTypes.array,
  selectedDepartureOption: PropTypes.object,
  when: PropTypes.object,
  where: PropTypes.array,
  how: PropTypes.array,
  orderByName: PropTypes.bool,
  handleSelectDeparture: PropTypes.func,
  orderBy: PropTypes.string,
  region: PropTypes.any,
  city: PropTypes.any,
  stateWhen: PropTypes.any,
  stateHow: PropTypes.any,
  queryString: PropTypes.string,
  engine: PropTypes.string,
  orderByOptions: PropTypes.object,
  quantity: PropTypes.number,
  selectedTabId: PropTypes.number,
  text: PropTypes.string
};

export default styles(DesktopPackageSearch);
