/**
 *
 * FeeList
 *
 */

import React from 'react';

// Components
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import { List, Fee, Body, Action } from './styles';

// Types
import { FeeListProps } from './types';

// Utils
import { toMoney } from 'Utils/transforms';

const FeeList = ({ fees, currency, selectedFee, onClickFee }: FeeListProps) => (
  <List>
    {fees.map((f, i) => {
      const selected: boolean = selectedFee && selectedFee.fee === f.fee;

      return (
        <Fee onClick={onClickFee(f, i)} isSelected={selected} key={f.fee}>
          <Body>
            <Text weight="bold" context="wine">
              {f.fee} pago{f.fee > 1 && '(s)'}{' '}
              {f.fee > 1 && (
                <>{f.withInterest ? 'con interés' : 'sin interés'}</>
              )}
            </Text>

            <br />

            <Text>de {toMoney(f.priceByFee, currency)}</Text>

            <br />

            <Text context="important" weight="semi-bold">
              Total a pagar: {toMoney(f.totalPrice, currency)}
            </Text>
          </Body>

          <Action>
            <Icon name="mv-arrow-right" color="important" />
          </Action>
        </Fee>
      );
    })}
  </List>
);

export default FeeList;
