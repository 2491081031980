/**
 *
 * MobileHotelSearch
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setCheckIn,
  setCheckOut,
  doValidation,
  toggleMap
} from 'Features/hotels';
import MobileHotelSearch from './MobileHotelSearch';

const selector = ({ hotels }) => ({
  checkIn: hotels.search.checkIn,
  checkOut: hotels.search.checkOut,
  showMap: hotels.search.showMap,
  isEmpty: false
});

const ConnectedDesktopHotelSearch = props => {
  const dispatch = useDispatch();
  const actions = {
    setCheckIn: date => {
      dispatch(setCheckIn(date));
    },
    setCheckOut: date => {
      dispatch(setCheckOut(date));
    },
    doSearch: () => {
      dispatch(doValidation());
    },
    toggleMap: () => {
      dispatch(toggleMap());
    }
  };

  const state = useSelector(selector);

  return <MobileHotelSearch actions={actions} {...state} {...props} />;
};

export default ConnectedDesktopHotelSearch;
