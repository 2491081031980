import styled from 'styled-components';

import Button from 'Components/Button';

import { PassengerActionProps } from './types';

import { breakpoints } from 'Constants';

export const Container = styled.div`
  .pax-icon-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: auto;
  margin-right: auto;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    width: 100%;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadows.two};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
    border: none;
    padding: 16px;
  }
`;

export const Action = styled(Button)<PassengerActionProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.three};
  outline: none;
  cursor: pointer;
  border-radius: 0;

  .icon {
    order: 1;
  }
`;

export const Data = styled.div`
  flex: 1;
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing.three};

  .icon {
    height: 72px;
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    width: 100%;
    margin-left: 0;
    margin-right: 12px;
  }
`;

export const Body = styled.div`
  margin-left: 12px;

  .names {
    font-size: 17.5px;
    font-weight: 500;
    line-height: 1.17;
    letter-spacing: 0.18px;
    color: ${({ theme }) => theme.colors.lightGrey};
    margin-bottom: 0;
    margin-top: 5px;
  }

  span,
  i {
    display: block;
  }

  i {
    color: #71bced;
  }
`;

export const Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 7px));
  grid-gap: 10px;

  .grid-2 {
    grid-column: span 2;
  }

  .grid-3 {
    grid-column: span 3;
  }

  .grid-4 {
    grid-column: span 4;
  }
`;

export const Submit = styled(Button)`
  &.submit {
    margin: 10px auto;
    display: flex;
    justify-content: center;

    &--next {
      display: block;
      text-align: center;
      margin: 0 auto 60px auto;
      position: relative;
      bottom: 0;
      top: 10px;
    }
  }
`;
