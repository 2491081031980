/**
 *
 * MobileFlightDestinationCard
 *
 */

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import Text from 'Components/Text';
import Icon from 'Components/Icon';

import MobileFlightDestinationSearch from '../MobileFlightDestinationSearch';
import * as COLORS from 'Constants/colors';
import useOutsideClick from '../../../../Utils/hooks/useOutsideClick';
import { locationTypes } from 'Components/DestinationFlight';
import Portal from 'Components/Portal';

const MobileFlightDestinationCard = ({
  className,
  location,
  locationName,
  label,
  type,
  index
}) => {
  const [openModal, setOpenModal] = useState(false);
  const refOpenModal = useRef();

  useOutsideClick(refOpenModal, () => {
    if (openModal) setOpenModal(false);
  });

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    document.body.classList.toggle('non-scroll', openModal);
  }, [openModal]);

  return (
    <div className={cs(className)}>
      <div className="container-mfd" onClick={toggleModal}>
        <div
          className={cs('container-header-mfd', {
            row: location.length > 0
          })}>
          <Text
            className={cs(
              'icon-mfd',
              { sm: location.length > 0 },
              { lg: location === 0 }
            )}
            paragraph
            context="important">
            <Icon name="mv-location" />
          </Text>
          <Text
            weight="semi-bold"
            paragraph
            className={cs(
              'header-mfd',
              { sm: location.length > 0 },
              { lg: location === 0 }
            )}>
            {label}
          </Text>
        </div>
        <Text
          className={cs('location-code', { none: location.length === 0 })}
          weight="semi-bold"
          paragraph>
          {location}
        </Text>

        <Text className={cs('location-name')} context="italic" paragraph>
          {locationName || 'Ingresá donde viajas'}
        </Text>
      </div>
      {openModal && (
        <Portal>
          <MobileFlightDestinationSearch
            onClose={toggleModal}
            type={type}
            index={index}
          />
        </Portal>
      )}
    </div>
  );
};

MobileFlightDestinationCard.propTypes = {
  className: PropTypes.string,
  location: PropTypes.string,
  locationName: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(locationTypes)),
  index: PropTypes.number
};

export default styled(MobileFlightDestinationCard)`
  display: block;
  background-color: ${COLORS.white};
  width: 48%;

  .container-mfd {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 130px;
    border-radius: 14px;
    box-shadow: ${COLORS.shadows.two};
    background-color: ${COLORS.white};
    padding: 10px;
  }

  .container-header-mfd {
    display: flex;
    flex-direction: column;

    &.row {
      flex-direction: row;
      justify-content: space-between;
    }

    .header-mfd {
      color: ${COLORS.darkGrey};
      padding: 5px;
      align-self: center;

      &.sm {
        height: 16px;
        font-size: 12px;
      }

      &.lg {
        height: 29px;
        font-size: 24px;
        font-weight: 600;
      }
    }

    .icon-mfd {
      height: 22px;
      align-self: center;
      padding: 5px;

      &.sm {
        font-size: 16px;
      }

      &.lg {
        font-size: 18px;
      }
    }
  }

  .location-code {
    display: block;
    font-size: 20px;

    &.none {
      display: none;
    }
  }

  .location-name {
    font-size: 16px;
    height: 40px;
    text-align: center;
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
