import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMPC, setInitialDate, setEndDate } from '../../index';
import { setActive } from 'Features/destinations/actions';
import MobileRoundTrip from './MobileRoundTrip';

const selector = ({ flights, destinations }) => ({
  isMPC: flights.search.isMPC,
  knowDestination: destinations.active,
  initialDate: flights.search.routesData[0].initialDate,
  endDate: flights.search.routesData[0].endDate,
  isCurrentViewDestinations: destinations.isCurrentView
});

const ConnectedMobileRoundTrip = props => {
  const dispatch = useDispatch();
  const actions = {
    toggleMPC: () => {
      dispatch(toggleMPC());
    },
    setKnowDestination: () => {
      dispatch(setActive(!state.knowDestination));
    },
    setInitialDate: date => {
      dispatch(setInitialDate(0, date));
    },
    setEndDate: date => {
      dispatch(setEndDate(0, date));
    }
  };

  const state = useSelector(selector);

  return <MobileRoundTrip {...props} {...state} actions={actions} />;
};

export { MobileRoundTrip };
export default ConnectedMobileRoundTrip;
