/**
 *
 * CostCard
 *
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import NewText from 'Components/NewText';
import { ThemeFontWeight, ThemeColor } from 'Theme';
import NewInput, { NewInputProps } from 'Components/NewInput';
import NewCheckbox from 'Components/NewCheckbox';
import { getPVP } from './helpers';
import { formatMoney } from 'Utils/moneyFormatter';
import { searchDebounceTime } from 'Constants';

const leftTextStyles = {
  fontWeight: 'semiBold' as ThemeFontWeight,
  color: 'wine' as ThemeColor
};

const currencyStyles = {
  fontWeight: 'semiBold' as ThemeFontWeight,
  color: 'orange' as ThemeColor
};
export interface ICostCardState {
  cost: number;
  markup: number;
  pvpeValue: number;
  pve: boolean;
  total: number;
  isDirty: boolean;
}
export interface ICostCardOnChangePayload {
  cost: number;
  markup: number;
  pvpeValue: number;
  pvpTotal: number;
  pve: boolean;
  total: number;
}
export interface CostCardProps {
  calculatesPVP?: boolean;
  cost: number;
  markup?: number;
  passengerQty: number;
  pvpeValue?: number;
  pvpeCheckbox?: boolean;
  onChange: (data: ICostCardOnChangePayload) => void;
  showTotalCost?: boolean;
  children?: React.ReactNode | React.ReactNodeArray;
}

let costCardTimer = null;

const CostCard = ({
  calculatesPVP,
  markup = 0,
  cost,
  passengerQty,
  pvpeValue = cost,
  pvpeCheckbox = false,
  onChange,
  children,
  showTotalCost
}: CostCardProps) => {
  const [state, setState] = useState<ICostCardState>({
    cost,
    markup,
    pvpeValue,
    pve: pvpeCheckbox,
    total: 0,
    isDirty: false
  });

  const pvpTotal = state.pve
    ? state.pvpeValue
    : getPVP(state.markup, state.cost);

  const handleOnChange = () => {
    clearTimeout(costCardTimer);
    costCardTimer = setTimeout(() => {
      onChange({
        cost: state.cost,
        markup: state.markup,
        pvpTotal,
        pvpeValue: state.pvpeValue,
        pve: state.pve,
        total: total()
      });
    }, searchDebounceTime.DESKTOP);
  };

  useEffect(() => {
    if (state.isDirty) {
      handleOnChange();
    }
  }, [pvpTotal]);

  let errorMessage: string;
  if (calculatesPVP && pvpTotal < state.cost) {
    errorMessage = 'No puede ser menos';
  } else {
    errorMessage = undefined;
  }
  const total = () => {
    if (state.pve) {
      return state.pvpeValue;
    } else {
      return calculatesPVP ? pvpTotal : Number(pvpTotal) * passengerQty;
    }
  };

  const togglePVE = (value: boolean) =>
    setState(prev => ({ ...prev, pve: value, isDirty: true }));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setState(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
      isDirty: true
    }));
  };

  return (
    <Container>
      <LinesContainer>
        {children}
        <Line>
          <NewText {...leftTextStyles}>Costo</NewText>
          <Flex>
            <NewInput
              type="number"
              prefix={<NewText {...currencyStyles}>USD</NewText>}
              value={String(state.cost)}
              name="cost"
              onChange={handleInputChange}
            />
          </Flex>
        </Line>
        {calculatesPVP && (
          <Line>
            <NewText {...leftTextStyles}>Mark UP</NewText>
            <Flex>
              <NewInput
                type="number"
                prefix={<NewText {...currencyStyles}>%</NewText>}
                value={String(state.markup)}
                name="markup"
                onChange={handleInputChange}
              />
            </Flex>
          </Line>
        )}
        {calculatesPVP && !state.pve && (
          <CostCardLine label="Pvp x Pax">
            <NewInput
              type="number"
              prefix={<NewText {...currencyStyles}>U$S</NewText>}
              value={String(Math.round(pvpTotal))}
              onChange={() => {}}
              error={{
                message: errorMessage
              }}
            />
            {/*<CostCardText value={`${PaxType[passenger.paxType]} x${passenger.total}`} />*/}
          </CostCardLine>
        )}
        {calculatesPVP && (
          <Line>
            <Flex>
              <NewText {...leftTextStyles}>PVPE</NewText>
              <Checkbox
                name="PVPE"
                value="PVPE"
                color="orange"
                checked={state.pve}
                onChange={togglePVE}
              />
            </Flex>
            <Flex>
              {state.pve && (
                <NewInput
                  type="number"
                  prefix={<NewText {...currencyStyles}>U$S</NewText>}
                  value={String(state.pvpeValue)}
                  name="pvpeValue"
                  onChange={handleInputChange}
                  error={{
                    message: errorMessage
                  }}
                />
              )}
            </Flex>
          </Line>
        )}
      </LinesContainer>
      {showTotalCost && (
        <FooterLine last>
          <NewText {...leftTextStyles} color="white">
            {showTotalCost ? 'Precio Total' : 'PVP por Pax'}
          </NewText>
          <NewText {...leftTextStyles} color="white">
            U$S {formatMoney(total())}
          </NewText>
        </FooterLine>
      )}
    </Container>
  );
};

export interface CostCardInputProps extends NewInputProps {
  withCurrency?: boolean;
}

const CostCardInput = ({ withCurrency, ...rest }: CostCardInputProps) => (
  <NewInput
    type="number"
    {...rest}
    prefix={withCurrency && <NewText {...currencyStyles}>U$S</NewText>}
  />
);

export interface CostCardTextProps {
  withCurrency?: boolean;
  formatAsMoney?: boolean;
  value?: number | string;
}

const CostCardText = ({
  withCurrency,
  formatAsMoney,
  value
}: CostCardTextProps) => (
  <div>
    {withCurrency && <NewText {...currencyStyles}>U$S </NewText>}
    <NewText>
      {formatAsMoney && typeof value === 'number'
        ? formatMoney(value)
        : String(value)}
    </NewText>
  </div>
);
export interface CostCardLineProps {
  label: string;
  labelImportant?: boolean;
  children: React.ReactNode;
  last?: boolean;
}

const CostCardLine = ({
  label,
  labelImportant,
  children,
  last
}: CostCardLineProps) => (
  <Line last={last}>
    <NewText {...leftTextStyles} {...(labelImportant && { color: 'orange' })}>
      {label}
    </NewText>
    {children}
  </Line>
);

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.grays.white};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.shadows.two};
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-width: 205px;
`;

interface LineProps {
  last?: boolean;
}
const Line = styled.div<LineProps>`
  display: flex;
  align-items: center;
  width: 100%;
  & > * {
    flex: 1;
  }

  ${({ theme, last }) => !last && `margin-bottom: ${theme.spacing.four}`}
`;

const FooterLine = styled(Line)`
  background-color: ${({ theme }) => theme.palette.primary.orange};
  padding: ${({ theme }) => theme.spacing.two};
`;

const LinesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.two};
`;

const Flex = styled.div`
  display: flex;
  min-height: 33px;
  align-items: center;
`;

const Checkbox = styled(NewCheckbox)`
  margin-left: ${({ theme }) => theme.spacing.two};
`;

CostCard.Line = CostCardLine;
CostCard.Input = CostCardInput;
CostCard.Text = CostCardText;
export default CostCard;
