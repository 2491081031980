/**
 *
 * MobileFlightSearchCompact
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import { airSearchTypes } from 'Constants';
import { formatSuperShort } from '../../../../Utils/dates';

const MobileFlightSearchCompact = ({
  className,
  origin,
  destination,
  initialDate,
  endDate,
  adults,
  kids,
  type,
  actions
}) => {
  const passengers = adults + kids.length;

  return (
    <div className={cs(className)}>
      <div className="flight-container">
        <div className="flex-row space-around">
          <div className="flex-row">
            <Icon name="mv-plane-ida" size={20} />
            <Text weight="semi-bold">
              {origin} - {destination}
            </Text>
          </div>
          <div className="flex-row margin-left">
            <Icon name="mv-user" size={20} />
            <Text weight="semi-bold">{passengers}</Text>
          </div>
        </div>
        <div className="flex-row">
          <Icon name="mv-calendar" size={20} />
          {type === airSearchTypes.roundTrip ? (
            <Text>
              {formatSuperShort(initialDate)}{' '}
              {endDate ? `- ${formatSuperShort(endDate)}` : ''}
            </Text>
          ) : (
            <Text>{formatSuperShort(initialDate)}</Text>
          )}
        </div>
      </div>
      <div className="search-container" onClick={actions.setModify}>
        <Icon name="mv-lense" size={24} />
      </div>
    </div>
  );
};

MobileFlightSearchCompact.propTypes = {
  className: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  initialDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  adults: PropTypes.number.isRequired,
  kids: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      seat: PropTypes.bool
    })
  ),
  actions: PropTypes.shape({
    setModify: PropTypes.func.isRequired
  }),
  type: PropTypes.string.isRequired
};

export default styled(MobileFlightSearchCompact)`
  display: flex;
  justify-content: space-between;
  padding: 5px 15px 5px 15px;
  background: ${COLORS.white};
  width: 100%;

  .search-container {
    color: ${COLORS.important};
    border-left: 1px solid ${COLORS.darkGrey};
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 15px;
      margin-right: 10px;
    }
  }

  .flight-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .flex-row {
      display: flex;
      align-items: center;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
        color: ${COLORS.important};
      }
    }
  }

  .margin-left {
    margin-left: 15px;
    margin-right: 30px;
  }
`;
