import { Container } from '../../../destinations/components/MeteorologyBar/styles';
import React from 'react';
import { PricesProps } from '../../types';
import PackagePriceBarStyle from '../../PackagePriceColor';
import { MonthChip } from '../../styles';
import styled from 'styled-components';
import { breakpoints } from 'Constants';

const PackagePriceBar = ({
  color,
  month,
  amount,
  currency,
  minMax
}: PricesProps) => (
  <Container>
    <PackagePriceBarStyle
      color={color}
      value={amount}
      currency={currency}
      minMax={minMax}
    />
    <MonthNameContainer>
      <MonthChip size="large" weight="bold">
        {month}
      </MonthChip>
    </MonthNameContainer>
  </Container>
);

const MonthNameContainer = styled.div`
  width: 70px;
  height: 30px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.blackHaze};
  overflow: hidden;
  position: absolute;
  bottom: 0;

  @media screen and (max-width: ${breakpoints.medium}px) {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.newBackground};
    font-weight: ${({ theme }) => theme.text.fontWeights.semibold};
    box-shadow: ${({ theme }) => theme.shadows.two};
  }
`;

export default PackagePriceBar;
