import { billingDocumentTypes } from 'Features/checkout/constants';

export const selector = ({ appConfiguration, places, checkout }) => ({
  documentTypes: billingDocumentTypes,
  phoneTypes: appConfiguration.sharedConfig.phoneTypes,
  userData: checkout.requestData,
  localize: appConfiguration.localize,
  places,
  checkout
});
