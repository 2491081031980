import { IDestinationsReducer } from 'Features/destinations/types';

export const initialState: IDestinationsReducer = {
  status: 'unused',
  error: false,
  active: false,
  // If Destinations is already displaying on screen
  isCurrentView: false,
  data: {},
  packagesByDestinationResult: [],
  flightsBanners: []
};
