import React from 'react';
import styled from 'styled-components';
import successIcon from '../../Assets/icons/success-icon.png';
import Colors from '../../Theme/colors';
import Text from '../../Components/Text';
import * as COLORS from 'Constants/colors';

const GatewaySuccessTransactionHolder = styled.div`
   {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    color: #333;
    background-image: ${COLORS.gradientSky};
    border: 2px solid ${Colors.green};
    padding: 10px;
    border-radius: 10px;
  }

  h6 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    color: ${Colors.green};
  }

  p {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }
`;

const GatewaySuccessTransaction = () => (
  <GatewaySuccessTransactionHolder>
    <img src={successIcon} width="10%" height="auto" alt="success-icon" />
    <h6>El pago fue procesado satisfactoriamente</h6>

    <Text paragraph size="large">
      En la próximas horas recibirás un email con el estado de tu reserva. Si no
      lo recibes, revisá la carpeta de correo no deseado o spam, a veces los
      correos se esconden ahí.
    </Text>

    <Text paragraph weight="extra-bold">
      {' '}
      Ante cualquier consulta, no dudes en contactarte con nosotros.
    </Text>
  </GatewaySuccessTransactionHolder>
);

export default GatewaySuccessTransaction;
