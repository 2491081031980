const getPaymentInfo = (checkout, engine) => {
  // If there is no credit card data then is a gateway process
  if (
    checkout.paymentMethod.payment === 'creditCardPayment' &&
    !checkout.cardInfo &&
    engine !== 'vuelos' &&
    engine !== 'vuelosMPC'
  ) {
    return {
      paymentSummaryCC: {
        bank: {
          id: checkout.creditCardPayment.checkedBank.bank.id,
          name: checkout.creditCardPayment.checkedBank.bank.name,
          // issuerBankPaymentProviderId: 1, // TODO: ASK ABOUT THIS MISSING KEY
          image: checkout.creditCardPayment.checkedBank.bank.image
        },
        card: {
          id: checkout.creditCardPayment.checkedCard.card.id,
          name: checkout.creditCardPayment.checkedCard.card.name,
          // paymentMediaPaymentProviderId: 1, // TODO: ASK ABOUT THIS MISSING KEY
          image: checkout.creditCardPayment.checkedCard.card.image
        },
        fee: checkout.creditCardPayment.checkedFee.fee
      },
      billingFinancingType:
        checkout.creditCardPayment.checkedFee.billingFinancingType,
      // I know, I don't like this either but is a good solution for handling futures gateways
      ...(checkout.creditCardPayment.gateway && {
        tokenSP: {
          token: checkout.creditCardPayment.gateway.TokenId,
          targetType: checkout.creditCardPayment.gateway.Brand
        }
      })
    };
  } else if (checkout.paymentMethod.payment === 'wireTransferPayment') {
    return {
      accountHolder: checkout.wireTransferPayment.accountHolder,
      accountNumber: checkout.wireTransferPayment.accountNumber,
      paymentHolder: `${checkout.invoice.firstName} ${checkout.invoice.lastName}`,
      bank: checkout.wireTransferPayment.bank,
      accountType: checkout.wireTransferPayment.accountType,
      coin: checkout.wireTransferPayment.coin,
      billingFinancingType: checkout.wireTransferPayment.billingFinancingType,
      commercialCode: checkout.wireTransferPayment.commercialCode,
      phoneCollect: checkout.wireTransferPayment.phoneCollect
    };
  } else if (checkout.paymentMethod.payment === 'creditCardPayment') {
    return {
      bank: checkout.creditCardPayment.checkedBank.bank,
      card: checkout.creditCardPayment.checkedCard.card,
      targetNumber: checkout.creditCardPayment.cardInfo.cardNumber.replace(
        /\s/g,
        ''
      ),
      securityNumber: checkout.creditCardPayment.cardInfo.cvc,
      monthExpirationCard: checkout.creditCardPayment.cardInfo.exp.id,
      yearExpirationCard: Number(checkout.creditCardPayment.cardInfo.year.name),
      fee: checkout.creditCardPayment.checkedFee.fee,
      coefficient: checkout.creditCardPayment.checkedFee.coefficient,
      paymentHolder: checkout.creditCardPayment.cardInfo.cardName,
      document: checkout.creditCardPayment.cardInfo.document,
      billingFinancingType:
        checkout.creditCardPayment.checkedFee.billingFinancingType,
      commercialCode: checkout.creditCardPayment.checkedFee.commercialCode,
      phoneCollect: checkout.creditCardPayment.checkedFee.phoneCollect
    };
  } else if (checkout.paymentMethod.payment === 'paymentNetwork') {
    return {
      paymentNetworkId: checkout.paymentNetwork.id,
      coin: checkout.paymentNetwork.coins[0],
      billingFinancingType: checkout.paymentNetwork.billingFinancingType
    };
  } else if (checkout.paymentMethod.payment === 'branchOffices') {
    return {
      branchOfficeId: checkout.branchOffices.id,
      billingFinancingType: checkout.branchOffices.billingFinancingType
    };
  }
};

export const arrangeParams = ({ availability, checkout, hotels }, engine) => {
  if (engine === 'vuelos' || engine === 'vuelosMPC') {
    return {
      airSellInfo: {
        selectedOption: {
          completeFlights: Object.keys(availability.selectedSegment)
            .filter(key => availability.selectedSegment[key].item)
            .map(key => ({
              segments: availability.selectedSegment[key].item.segments
            })),
          validatingCarrier: availability.flightPrice.validatingCarrier
        },
        totalPriceSale: availability.flightPrice.totalPrice,
        coinId: 10
      },
      pnrData: {
        passengers: checkout.passengers.map(passenger => ({
          firstName: passenger.firstNames,
          lastName: passenger.lastNames,
          birthDate: {
            day: Number(passenger.day.name),
            month: Number(passenger.month.id),
            year: Number(passenger.year.name)
          },
          gender: passenger.gender.id || 1,
          documentData: {
            nationality: passenger.country.plCitizenCode || 'URY',
            documentType:
              passenger.documentType.id === 1 ? passenger.documentType.id : 2,
            document: passenger.document
          },
          travelerCode: passenger.code
        })),
        callContacts: checkout.bill.phones.map(p => ({
          phoneType: p.phoneType.id || 1,
          countryCode: p.code.id,
          phoneNumber: p.phone
        })),
        voucherEmail: checkout.bill.email,
        receiveOffersByEmail: checkout.bill.receiveOffers
      },
      markRuleInfoApplied: availability.markRuleInfoApplied,
      paymentInfo: getPaymentInfo(checkout, engine),
      billReserveData: {
        documentType: checkout.invoice.documentType.id,
        personType: checkout.invoice.fiscal.id,
        document: checkout.invoice.document,
        firstName: checkout.invoice.firstName,
        lastName: checkout.invoice.lastName,
        address: checkout.invoice.dir,
        country: {
          code: checkout.invoice.country.code,
          name: checkout.invoice.country.name
        },
        cityName: checkout.invoice.city,
        stateName: checkout.invoice.state
      }
    };
  } else {
    return {
      additionalInfo: {
        bookingPrice: availability.hotelInformation.packagePrice.finalPrice,
        hotelId: hotels.property.id,
        coinId: 10, // TODO: THIS IS HARDCODED BUT IN THE FUTURE WILL BE DYNAMIC (ASK RENE)
        packageId: availability.hotelInformation.packageId,
        sessionId: hotels.search.sessionId,
        supplierId: availability.hotelInformation.supplierId,
        checkIn: hotels.search.checkIn + 'T' + hotels.property.checkIn,
        checkOut: hotels.search.checkOut + 'T' + hotels.property.checkOut,
        priceInSupplier: availability.hotelInformation.packagePrice.netPrice,
        isFreeCancellation: availability.hotelInformation.hasFreeCancellation
      },
      passengersRooms: {
        rooms: availability.hotelInformation.rooms.map(
          ({ rateKey, boardName, roomName, occupancy, rateComments }) => ({
            rateKey,
            boardName,
            roomName,
            rateComments,
            totalRooms: occupancy.rooms,
            totalAdults: occupancy.adults,
            totalChildren: occupancy.children,
            childrenAges: occupancy.paxes
              ? occupancy.paxes.map(({ age }) => age)
              : [],
            holders: checkout.holder.holders
              .filter(holder => holder.rateKey === rateKey)
              .map(({ firstName, lastName }) => ({ firstName, lastName }))
          })
        ),
        titularPassenger: {
          firstName: checkout.holder.holders.find(holder => holder.isHolder)
            .firstName,
          lastName: checkout.holder.holders.find(holder => holder.isHolder)
            .lastName
        }
      },
      billReserveData: {
        documentType: checkout.invoice.documentType.id,
        document: checkout.invoice.document,
        firstName: checkout.invoice.firstName,
        lastName: checkout.invoice.lastName,
        address: checkout.invoice.dir,
        country: {
          code: checkout.invoice.country.id,
          name: checkout.invoice.country.name
        },
        cityName: checkout.invoice.city,
        stateName: checkout.invoice.state,
        phoneData: {
          phoneType: checkout.invoice.phoneType.id,
          countryCode: checkout.invoice.code.id,
          phoneNumber: checkout.invoice.phone
        },
        email: checkout.invoice.email
      },
      contactInfo: {
        phoneData: {
          phoneType: checkout.invoice.phoneType.id,
          countryCode: checkout.invoice.code.id,
          phoneNumber: checkout.invoice.phone
        },
        email: checkout.invoice.email,
        receiveOffersByEmail: true // TODO: THIS IS NOT IN THE FORM, SO IT WILL BE TRUE BY DEFAULT1
      },
      paymentInfo: getPaymentInfo(checkout),
      additionalRequirements: checkout.holder.addons
    };
  }
};
