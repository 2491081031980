/**
 *
 * ModalPoliticsAndPrivacy
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import Modal from '../../../../Components/Modal';
import Button from '../../../../Components/Button';
import WarningIcon from '../../../../Assets/images/warning-round.png';

const CorpoNotRefundableConfirmation = ({
  className,
  showNotRefundableModal,
  onShowNotRefundableModal,
  onAccept
}) => (
  <div className={cs(className)}>
    <button className="link-button" onClick={onShowNotRefundableModal}>
      Tarifa No Reembolsable
    </button>
    <Modal
      show={showNotRefundableModal}
      className="tac-modal"
      closeButton={{ onClick: () => onShowNotRefundableModal() }}
      header="Tarifa No Reembolsable"
      onClickOverlay={onShowNotRefundableModal}
      size="large"
      headerCentered>
      <div style={{ textAlign: 'center' }}>
        <img
          src={WarningIcon}
          style={{ width: '15%', height: 'auto' }}
          alt="warning-icon"
        />
        <p>
          Tarifa no reembolsable, por lo que ya está en gastos, en el caso de
          querer cancelar, no será posible
        </p>
      </div>
      <Button
        onClick={() => {
          onShowNotRefundableModal();
          onAccept();
        }}
        right>
        Aceptar
      </Button>
    </Modal>
  </div>
);

CorpoNotRefundableConfirmation.propTypes = {
  className: PropTypes.string,
  showNotRefundableModal: PropTypes.bool,
  onShowNotRefundableModal: PropTypes.func,
  onAccept: PropTypes.func
};

export default styled(CorpoNotRefundableConfirmation)`
  .link-button {
    border: 0;
    outline: none;
    background: transparent;
    color: white;
  }
`;
