import React, { memo } from 'react';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';

// Components
import Input from 'Components/NewInput';
import Label from 'Components/Label';
import Form from '../Form';

// Types
import { LoginProps, LoginValues } from './types';

// Constants
import { formErrors } from 'Constants';

const Login = memo(({ error, pending, onSubmit }: LoginProps) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit
  }: FormikValues = useFormik<LoginValues>({
    initialValues: {
      username: '',
      password: ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      username: Yup.string().required(formErrors.required),
      password: Yup.string().required(formErrors.required)
    }),
    onSubmit
  });

  return (
    <Form onSubmit={handleSubmit} pending={pending} error={error}>
      <div>
        <Label color="violet">Usuario:</Label>

        <Input
          placeholder="Usuario"
          onChange={handleChange('username')}
          value={values.username}
          error={{ message: errors.username }}
        />
      </div>

      <div>
        <Label color="violet">Contraseña:</Label>

        <Input
          type="password"
          placeholder="Contraseña:"
          onChange={handleChange('password')}
          value={values.password}
          error={{ message: errors.password }}
        />
      </div>
    </Form>
  );
});

export default Login;
