import API from 'Services';
import ACTION_TYPES from './actionTypes';
import { getCountries } from '../../ReduxConfig/places';
import { fetchUser } from '../users/actions';

export const getAppConfiguration = () => async dispatch => {
  dispatch(getAppConfigurationRequest());

  try {
    const response = await API.appGeneral.getInitialConfig();
    dispatch(getAppConfigurationSuccess(response.data));
  } catch (error) {
    dispatch(getAppConfigurationFailure());
    throw error;
  }
  dispatch(getLocalization());
  dispatch(getCountries());
  dispatch(fetchUser());
};

export const getLocalization = () => async dispatch => {
  try {
    const { data } = await API.common.localize();

    dispatch({
      type: ACTION_TYPES.LOCALIZE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.LOCALIZE_FAILURE
    });
  }
};

// Actions creators
const getAppConfigurationRequest = () => ({
  type: ACTION_TYPES.GET_APP_CONFIGURATION_REQUEST
});

const getAppConfigurationSuccess = payload => ({
  type: ACTION_TYPES.GET_APP_CONFIGURATION_SUCCESS,
  payload
});

const getAppConfigurationFailure = () => ({
  type: ACTION_TYPES.GET_APP_CONFIGURATION_FAILURE
});

export const updateLoader = payload => ({
  type: ACTION_TYPES.UPDATE_LOADER,
  payload
});

export const setNavbarSmallMode = bool => ({
  type: ACTION_TYPES.SET_NAVBAR_SMALL_MODE,
  payload: bool
});

export const hideNavbar = bool => ({
  type: ACTION_TYPES.SET_HIDE_NAVBAR,
  payload: bool
});

export const cleanErrorBoundary = () => ({
  type: ACTION_TYPES.CLEAN_ERROR_BOUNDARY
});
