/**
 *
 * CreditCard
 *
 */

// Vendors
import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { Breakpoint } from 'react-socks';
import valid from 'card-validator';

// Components
import Card from 'Components/Card';
import Radio from 'Components/Radio';
import FeeList from 'Components/FeeList';
import CardForm from 'Components/CreditCardForm';
import ButtonCard from 'Components/ButtonCard';
import Text from 'Components/Text';

// Utils
import { generateCustomCards } from 'Utils';
import { useValidatedForm } from 'Utils/hooks/useValidatedForm';

// Constants
import { important } from 'Constants/colors';
import { formErrors } from 'Constants';
import { scrollIntoView } from '../../Utils';

// TODO: FIND BETTER PLACE FOR THIS
generateCustomCards();

const CreditCard = ({ cardInfo, cards, isGateway, onSubmit, onValid }) => {
  const { fields, errors, handleChange, handleValidate } = useValidatedForm({
    fields: cardInfo.cardInfo || {},
    errors: {
      cardNumber: [formErrors.cardNotValid, formErrors.cardNotMatch],
      cardName: formErrors.nonNumbers,
      cvc: formErrors.cardCVCNotValid,
      year: formErrors.cardYearNotValid,
      exp: formErrors.cardMonthNotValid,
      document: formErrors.nonLetters
    },
    validators: {
      cardName: name => /\d/.test(name),
      cvc: cvc => !valid.cvv(cvc).isPotentiallyValid,
      year: year => !valid.expirationYear(year.name).isPotentiallyValid,
      document: doc => /\D/.test(doc),
      cardNumber: number => [
        !valid.number(number).isPotentiallyValid,
        valid.number(number).card &&
          valid.number(number).card.type !==
            cardInfo.checkedCard.card.name.toLowerCase()
      ],
      exp: exp => {
        const currentMonth = valid.expirationMonth(String(exp.id));
        const currentYear = new Date().getFullYear();

        if (fields.year && Number(fields.year.name) === currentYear) {
          return !currentMonth.isValidForThisYear;
        }

        return !currentMonth.isPotentiallyValid;
      }
    }
  });
  const [currentRef, setCurrentRef] = useState(null);

  const bankRef = useRef(null);
  const cardsRef = useRef(null);
  const feeRef = useRef(null);
  const cardInfoRef = useRef(null);

  const onClickCard = (card, priceFee) => () => {
    onSubmit({
      checkedCard: {
        card,
        priceFee
      }
    });

    setCurrentRef(feeRef);
  };

  const onClickFee = (fee, i) => () => {
    onSubmit({
      checkedFee: {
        ...fee,
        id: i
      }
    });

    setCurrentRef(cardInfoRef);
  };

  const onClickBank = (bank, cardPriceFee) => () => {
    onSubmit({
      checkedBank: {
        bank,
        cardPriceFee
      }
    });

    setCurrentRef(cardsRef);
  };

  const handleFieldBlur = () => {
    onSubmit({
      cardInfo: {
        ...cardInfo.cardInfo,
        ...fields
      }
    });
  };

  useEffect(() => {
    onValid(handleValidate());
  }, [fields]);

  useEffect(() => {
    if (currentRef) {
      scrollIntoView(currentRef);
    } else {
      scrollIntoView(bankRef);
    }
  }, [currentRef]);

  return (
    <CreditCardInformation>
      <Card
        headerCentered
        borderRadius="square"
        header="¿Cuál es tu banco?"
        className="bank-list-container">
        <ul className="bank-list" ref={bankRef}>
          {cards &&
            cards.map(({ bank, cardPriceFee }) => {
              const checked =
                cardInfo.checkedBank &&
                cardInfo.checkedBank.bank.id === bank.id;

              return (
                <Fragment key={bank.id}>
                  <Breakpoint medium up>
                    <li className="card">
                      <Radio
                        onClick={onClickBank(bank, cardPriceFee)}
                        checked={checked}>
                        <img src={bank.image} alt={bank.name} />
                      </Radio>
                    </li>
                  </Breakpoint>

                  <Breakpoint small down>
                    <ButtonCard
                      className={cs('card', { 'card--is-selected': checked })}
                      onClick={onClickBank(bank, cardPriceFee)}>
                      <img src={bank.image} alt={bank.name} />
                      &nbsp;
                      <Text
                        context="important"
                        size="xlarge"
                        weight="semi-bold">
                        {bank.name}
                      </Text>
                    </ButtonCard>
                  </Breakpoint>
                </Fragment>
              );
            })}
        </ul>
      </Card>

      {cardInfo.checkedBank && (
        <Card
          headerCentered
          borderRadius="square"
          header="¿Cuál es tu tarjeta?"
          className="card-list-container">
          <ul className="card-list" ref={cardsRef}>
            {cardInfo.checkedBank.cardPriceFee.map(({ card, priceFee }) => {
              const checked =
                cardInfo.checkedCard &&
                cardInfo.checkedCard.card.id === card.id;

              return (
                <Fragment key={card.id}>
                  <Breakpoint medium up>
                    <li className="card">
                      <Radio
                        onClick={onClickCard(card, priceFee)}
                        checked={checked}>
                        <img src={card.image} alt={card.name} />
                      </Radio>
                    </li>
                  </Breakpoint>

                  <Breakpoint small down>
                    <ButtonCard
                      className={cs('card', { 'card--is-selected': checked })}
                      onClick={onClickCard(card, priceFee)}>
                      <img src={card.image} alt={card.name} />
                      &nbsp;
                      <Text context="important" size="large" weight="semi-bold">
                        {card.name}
                      </Text>
                    </ButtonCard>
                  </Breakpoint>
                </Fragment>
              );
            })}
          </ul>
        </Card>
      )}

      {cardInfo.checkedCard && (
        <span ref={feeRef}>
          <Card
            headerCentered
            borderRadius="square"
            header="¿En cuantas cuotas queres pagar?"
            className="card-information">
            <FeeList
              fees={cardInfo.checkedCard.priceFee}
              currency="U$S "
              selectedFee={cardInfo.checkedFee}
              onClickFee={onClickFee}
            />
          </Card>
        </span>
      )}

      {!isGateway && cardInfo.checkedFee && (
        <Card
          headerCentered
          borderRadius="square"
          header="Información de la tarjeta">
          <div className="card-information" ref={cardInfoRef}>
            <CardForm
              fields={fields}
              errors={errors}
              onChange={handleChange}
              onBlur={handleFieldBlur}
            />
          </div>
        </Card>
      )}
    </CreditCardInformation>
  );
};

CreditCard.propTypes = {
  onSubmit: PropTypes.func,
  cardInfo: PropTypes.object,
  cards: PropTypes.array,
  pending: PropTypes.bool,
  isGateway: PropTypes.bool,
  onValid: PropTypes.func
};

const CreditCardInformation = styled.div`
  .bank-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 0;
    padding: 0;
    margin-top: 7px;

    @media screen and (max-width: 576px) {
      display: flex;
      flex-direction: column;

      .card {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        flex-direction: row;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .card {
    border: none;
    transition: all 200ms ease;
    outline: none;

    &--is-selected {
      border: 3px solid ${important} !important;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: center;
      margin-bottom: 0;
      padding: 0;
      margin-top: 7px;

      @media screen and (max-width: 576px) {
        display: flex;
        flex-direction: column;

        .card {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-bottom: 8px;
        }
      }
    }

    &-information {
      margin-top: 12px;

      @media screen and (max-width: 576px) {
        margin-top: 0;

        &.selected {
          display: none;
        }
      }
    }

    img {
      width: 100px;
      height: auto;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }

    @media screen and (max-width: 576px) {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .card-list-container {
      &.selected {
        display: none;
      }
    }
  }
`;

export default CreditCard;
