import { isoNextFriday, isoNextSaturday } from '../../Utils/dates';

export const initialRoom = {
  adultsCount: 2,
  kidsAges: []
};

export const types = {
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT'
};

export const limits = {
  adultsCount: {
    min: 1
  },
  peoplePerRoom: {
    min: 1,
    max: 4
  },
  roomAmmount: { min: 1, max: 4 }
};

export const statusCodes = {
  UNUSED: 'unused',
  READY: 'ready',
  LOADING: 'loading',
  ERROR: 'error'
};

export const viewingCodes = {
  AVAILABILITY: 'availability',
  PROPERTY: 'property'
};

export const initialState = {
  search: {
    checkIn: isoNextFriday,
    checkOut: isoNextSaturday,
    roomInformation: [
      {
        adultsCount: 2,
        kidsAges: []
      }
    ],
    allowedPets: false,
    showMap: false,
    location: {},
    detailMode: false,
    suggestions: {
      predictions: [],
      openMenu: false,
      pending: false,
      value: ''
    },
    isEmpty: {
      dates: true,
      rooms: true
    },
    validations: {
      checkIn: [],
      checkOut: [],
      roomInformation: [],
      location: []
    }
  },
  results: [],
  status: statusCodes.UNUSED,
  viewing: viewingCodes.AVAILABILITY
};
