import styled from 'styled-components';
import { breakpoints } from '../../../../Constants';

export const Container = styled.div``;

export const Seasons = styled.div`
  display: flex;

  div {
    margin-right: ${({ theme }) => theme.spacing.one};

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Months = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  min-height: 180px;

  & > div {
    margin-bottom: ${({ theme }) => theme.spacing.two};

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.one};
    }

    @media screen and (max-width: ${breakpoints.medium}px) {
      width: 100%;
      margin-right: 0 !important;
    }
  }
`;

export const Month = styled.div`
  display: flex;
  align-items: flex-end;

  > div:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.one};
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
    margin-right: 0;
    align-items: flex-start;

    > div:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.two};
    }
  }
`;
