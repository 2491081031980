import moment from 'moment';
import { formErrors, formValidators } from 'Constants';

import { flightPassengerDocumentsTypes } from 'Features/checkout/constants';

export const getCountries = countries =>
  [...countries].map(country => ({
    ...country,
    id: country.code
  }));

export const getCitizen = (citizens, localize) =>
  localize &&
  citizens.length &&
  citizens.find(c => c.code === localize.countryCode);

export const getYears = (ys, passenger, ida, vuelta) => {
  const years = [...ys]
    .map((y, idx) => ({
      ...y,
      id: idx
    }))
    .filter(y => {
      const current = new Date().getFullYear();

      return Number(y.name) <= current && Number(y.name) >= current - 100;
    });

  if (passenger) {
    const getDate = flight =>
      moment(flight.flightDate, 'YYYY/MM/DD').format('Y');
    let newYears = [...years];

    if (passenger.code === 'ADT') {
      newYears = [...years].filter(
        year => Number(year.name) <= Number(getDate(ida)) - 12
      );
    } else if (passenger.code === 'CNN') {
      if (vuelta) {
        newYears = [...years].filter(
          year =>
            Number(year.name) >= Number(getDate(ida)) - 12 &&
            Number(year.name) <= Number(getDate(vuelta)) - 2
        );
      } else {
        newYears = [...years].filter(
          year => Number(year.name) >= Number(getDate(ida)) - 12
        );
      }
    } else if (passenger.code === 'INF' || passenger.code === 'INS') {
      if (vuelta) {
        newYears = [...years].filter(
          year => Number(year.name) >= Number(getDate(vuelta)) - 2
        );
      } else {
        newYears = [...years].filter(year => Number(year.name));
      }
    }

    return newYears;
  }

  return years;
};

export const getMonths = (
  months,
  years,
  passenger,
  selectedYear,
  ida,
  vuelta
) => {
  const getDate = flight => ({
    month: moment(flight.flightDate, 'YYYY/MM/DD').format('M'),
    year: moment(flight.flightDate, 'YYYY/MM/DD').format('Y')
  });

  const today = new Date();
  let newMonths = [{ id: 1, name: 'Seleccione un año' }];
  let limit = getDate(ida);

  if (passenger && selectedYear) {
    switch (passenger.code) {
      case 'INF':
      case 'INS':
        limit = getDate(vuelta);

        newMonths = [...months].filter(m => {
          if (Number(selectedYear) === today.getFullYear()) {
            return m.id <= today.getMonth() + 1;
          }

          if (Number(selectedYear) === Number(limit.year) - 2) {
            return m.id >= limit.month;
          }

          return true;
        });

        break;

      case 'CNN':
        newMonths = [...months].filter(m => {
          if (Number(selectedYear) === Number(getDate(vuelta).year) - 2) {
            return m.id <= getDate(vuelta).month;
          }

          if (Number(selectedYear) === Number(limit.year) - 12) {
            return m.id >= limit.month;
          }

          return true;
        });

        break;

      default:
        limit = getDate(ida);

        newMonths = [...months].filter(m => {
          if (Number(selectedYear) === Number(limit.year) - 12) {
            return Number(m.id) <= Number(limit.month);
          }

          return true;
        });

        break;
    }
  }

  return newMonths;
};

export const getDays = (
  days,
  years,
  passenger,
  selectedYear,
  selectedMonth,
  ida,
  vuelta
) => {
  const getDate = (flight: any): any => ({
    day: moment(flight.flightDate, 'YYYY/MM/DD').format('D'),
    month: moment(flight.flightDate, 'YYYY/MM/DD').format('M'),
    year: moment(flight.flightDate, 'YYYY/MM/DD').format('Y')
  });

  const today = new Date();
  let newDays = [{ id: 1, name: 'Seleccione un mes y/o año' }];
  let limit = getDate(ida);

  if (passenger && selectedYear && selectedMonth) {
    switch (passenger.code) {
      case 'INF':
      case 'INS':
        limit = getDate(vuelta);

        newDays = [...days].filter(day => {
          if (
            Number(selectedYear) === today.getFullYear() &&
            Number(selectedMonth.id) === today.getMonth() + 1
          ) {
            return Number(day.id) <= today.getDate();
          }

          if (
            Number(selectedYear) === Number(limit.year) - 2 &&
            Number(selectedMonth.id) === Number(limit.month)
          ) {
            return (
              Number(day.id) > Number(limit.day) &&
              Number(day.id) <= selectedMonth.days
            );
          }

          return true;
        });

        break;

      case 'CNN':
        limit = getDate(vuelta);

        newDays = [...days].filter(day => {
          if (
            Number(selectedYear) === Number(limit.year) - 2 &&
            Number(selectedMonth.id) === Number(limit.month)
          ) {
            return Number(day.id) <= Number(limit.day);
          }

          if (
            Number(selectedYear) === Number(getDate(ida).year - 12) &&
            Number(selectedMonth.id) === Number(getDate(ida).month)
          ) {
            return (
              Number(day.id) > Number(getDate(ida).day) &&
              Number(day.id) <= selectedMonth.days
            );
          }

          return true;
        });

        break;

      default:
        limit = getDate(ida);

        newDays = [...days].filter(day => {
          if (
            Number(selectedYear) === Number(limit.year) - 12 &&
            Number(selectedMonth.id) === Number(limit.month)
          ) {
            return Number(day.id) <= Number(limit.day);
          }

          return Number(day.id) <= selectedMonth.days;
        });

        break;
    }
  }

  return newDays;
};

export const getPassengerType = passengerCode => {
  switch (passengerCode) {
    case 'CNN':
      return 'Niño';

    case 'INS':
      return 'Bebe con asiento';

    case 'INF':
      return 'Bebe';

    default:
      return 'Adulto';
  }
};

export const getPassengers = (passengers = []) => {
  const newPassengers = [];

  passengers.forEach(passengerType => {
    const passengersWithType = new Array(passengerType.totalPassenger)
      .fill(null)
      .map((...[, idx]) => ({
        ...passengerType,
        id: idx,
        type: getPassengerType(passengerType.passengerCode),
        code: passengerType.passengerCode
      }));

    newPassengers.push(...passengersWithType);
  });

  return newPassengers.map((p, i) => ({
    ...p,
    id: i
  }));
};

export const initialFields = {
  firstNames: '',
  lastNames: '',
  day: {},
  month: {},
  year: {},
  country: {},
  document: '',
  documentType: {},
  gender: {}
};

export const defaultVuelta = {
  // Sin esto no anda el storybook porque no hay availability
  airport: {
    code: 'CDG',
    lat: 49,
    lng: 2.57,
    name: 'Internacional Charles De Gaulle',
    city: { code: 'PAR', name: 'París', isIata: true }
  },
  flightDate: '2019-09-27',
  flightTime: '11:50'
};

export const defaultIda = {
  airport: {
    code: 'MVD',
    lat: -34.84,
    lng: -56.03,
    name: 'Aeropuerto Int. de Carrasco',
    city: { code: 'MVD', name: 'Montevideo', isIata: true }
  },
  flightDate: '2019-09-22',
  flightTime: '19:30'
};

export const getSchema = citizen => ({
  fields: {
    ...initialFields,
    country: citizen,
    documentType: flightPassengerDocumentsTypes[0]
  },
  errors: {
    firstNames: [formErrors.lessThanOne, formErrors.nonNumbers],
    lastNames: [formErrors.lessThanOne, formErrors.nonNumbers],
    day: formErrors.required,
    month: formErrors.required,
    year: formErrors.required,
    country: formErrors.required,
    documentType: formErrors.required,
    document: formErrors.nonSymbols,
    gender: formErrors.required
  },
  validators: {
    firstNames: names => [names.length < 4, /\d/.test(names)],
    lastNames: names => [names.length < 4, /\d/.test(names)],
    document: formValidators.nonSymbols,
    day: day => !day,
    month: month => !month,
    year: year => !year,
    gender: gender => !gender
  }
});

export const humanizeGender = genderCode => {
  switch (genderCode) {
    case 'F':
      return 'Mujer';

    case 'M':
      return 'Hombre';

    default:
      return;
  }
};
