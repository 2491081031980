/**
 *
 * MeteorologyTemperature
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import Divider from 'Components/Divider';
import Card from 'Components/Card';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import Graph from '../MeteorologyGraph';
import {
  Container,
  GraphContainer,
  Wrapper,
  Description,
  Menu
} from './styles';

// Types
import {
  MeteorologyTemperatureProps,
  ITemperaturesTab,
  TTemperatureValues
} from './types';

const temperaturesTab: ITemperaturesTab[] = [
  { id: 'maxDay', name: 'Máxima (día)' },
  { id: 'minNight', name: 'Mínima (noche)' },
  { id: 'ocean', name: 'Del Mar' },
  { id: 'rainy', name: 'Días de lluvia por mes' }
];

const MeteorologyTemperature = ({
  title,
  subtitle,
  description,
  temperaturesData,
  seasons
}: MeteorologyTemperatureProps) => {
  const [stateActiveGraph, setActiveGraph] = useState<TTemperatureValues>(
    'maxDay'
  );

  return (
    <Container>
      <Divider titleText={title} subtitleText={subtitle} size="lg" />
      {description && (
        <Description>
          <Text paragraph context="white">
            {description}
          </Text>
        </Description>
      )}
      <Wrapper>
        {/*
        // @ts-ignore */}
        <Card boxShadow>
          <Menu>
            {temperaturesTab.map(tab => {
              if (temperaturesData[tab.id])
                return (
                  <Menu.Item
                    key={tab.id}
                    onClick={() => setActiveGraph(tab.id)}
                    active={tab.id === stateActiveGraph}>
                    {tab.icon && (
                      <Icon name={tab.icon} color="white" size={15} />
                    )}

                    {tab.name}
                  </Menu.Item>
                );
            })}
          </Menu>

          {temperaturesData && stateActiveGraph && (
            <GraphContainer>
              <Graph
                temperaturesData={temperaturesData}
                activeGraph={stateActiveGraph}
                seasons={seasons}
              />
            </GraphContainer>
          )}
        </Card>
      </Wrapper>
    </Container>
  );
};

export default styled(MeteorologyTemperature)`
  .mv-card-content {
    padding: 0;
    overflow: hidden;
  }
`;
