// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import BillForm from './BillForm';

// Selector
import { selector } from './BillForm.selector.jsx';
import { storageUserData } from '../../ReduxConfig/checkout';

const ConnectedBillForm = props => {
  const state = useSelector(selector);
  const dispatch = useDispatch();
  const actions = {
    setUserData: data => dispatch(storageUserData(data))
  };

  return <BillForm actions={actions} {...props} {...state} />;
};

export { BillForm };
export default ConnectedBillForm;
