/**
 *
 * Destination
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setHotelDestination } from 'Features/hotels';
import DestinationHotel from './DestinationHotel';

const selector = ({ hotels }) => ({
  location: hotels.search.location,
  messages: hotels.search.validations.location
});

const ConnectedDestinationHotel = props => {
  const dispatch = useDispatch();
  const actions = {
    setLocation: location => {
      dispatch(setHotelDestination(location));
    }
  };

  const state = useSelector(selector);

  return (
    <DestinationHotel
      label="Destino"
      placeHolder="Elige un Destino"
      actions={actions}
      {...state}
      {...props}
    />
  );
};

export default ConnectedDestinationHotel;
