/**
 *
 * MpcAvailabilityDesktop
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import MPCItem from '../MpcItem';
import { dateForMpc } from 'Utils/returnDate';
import Modal from 'Components/Modal';
import AirAvailabilityCard from '../AirAvailabilityCard';
import Icon from 'Components/Icon';
import Button from 'Components/Button';
import AirCheckError from '../AirCheckError';
import Text from 'Components/Text';
import MpcLegend from '../MpcLegend';
import MpcItemResearch from 'Features/flights/components/MpcItemResearch';
import { mpcItemStatus } from 'Features/flights/constants';

const CloseIconHolder = styled.div`
  display: flex;
  flex-direction: row-reverse;

  > .icon {
    cursor: pointer;
    margin-bottom: 8px;
  }
`;

const MpcAvailabilityDesktop = ({
  className,
  canDecreaseInitialDate,
  canSubstract,
  handleArrowButton,
  departureDates,
  arrivalDates,
  detail,
  minPrice,
  middlePrice,
  maxPrice,
  handleShowAllFlights,
  modalData,
  showModal,
  toggleShowModal,
  handleMPCItemClick,
  arrowKeys,
  showErrorModal,
  toggleErrorModal
}) => {
  const statusByPrice = { minPrice, middlePrice, maxPrice };

  return (
    <>
      <div className={cs(className, 'container')}>
        <div className="calendar-holder">
          <div className="departure-dates">
            {/* DEPARTURE DATES ROW */}
            {arrivalDates && <div className="empty-box"></div>}
            {canDecreaseInitialDate() ? (
              <div onClick={handleArrowButton(arrowKeys.left)}>
                <Icon
                  name="mv-arrow-left"
                  size={24}
                  color="white"
                  className="icon-left"
                />
              </div>
            ) : null}
            {departureDates.map((departureDate, i) => (
              <div key={departureDate} className="departure-date-item">
                <Text context="white" weight={i !== 3 ? 'regular' : 'bold'}>
                  {dateForMpc(departureDate)}
                </Text>
              </div>
            ))}
            {canSubstract() ? (
              <div onClick={handleArrowButton(arrowKeys.right)}>
                <Icon
                  name="mv-arrow-right"
                  size={24}
                  color="white"
                  className="icon-right"
                />
              </div>
            ) : null}
            {/* END DEPARTURE DATES ROW */}
          </div>

          <div className="dates-holder">
            {/* RETURN DATES COLUNN */}
            {arrivalDates && (
              <div className="return-dates">
                <>
                  {canSubstract() ? (
                    <div onClick={handleArrowButton(arrowKeys.up)}>
                      <Icon
                        name="mv-arrow-up"
                        className="icon-up"
                        size={24}
                        color="white"
                      />
                    </div>
                  ) : null}
                  {arrivalDates.map((arrivalDate, i) => (
                    <div key={arrivalDate} className="return-date-item ">
                      <Text
                        context="white"
                        weight={i !== 3 ? 'regular' : 'bold'}>
                        {dateForMpc(arrivalDate)}
                      </Text>
                    </div>
                  ))}
                  <div onClick={handleArrowButton(arrowKeys.down)}>
                    <Icon
                      name="mv-arrow-down"
                      className="icon-down"
                      size={24}
                      color="white"
                    />
                  </div>
                </>
              </div>
            )}

            {/* END RETURN DATES COLUMN */}

            <div className="rows-holder">
              {detail &&
                detail.map((detailRow, idxRow) => (
                  <div key={idxRow} className="mpc-row">
                    {detailRow.map((detailItem, idxCol) =>
                      detailItem.flightPrice && !detailItem.research ? (
                        <MPCItem
                          key={idxCol}
                          status={statusByPrice}
                          currency="USD"
                          price={detailItem.flightPrice.totalForOneAdult}
                          description={`Por ${
                            detailItem.flightPrice.totalPassenger
                          } ${
                            detailItem.flightPrice.totalPassenger > 1
                              ? 'pasajeros'
                              : 'pasajero'
                          }`}
                          totalPrice={`USD ${detailItem.flightPrice.totalPrice}`}
                          onClick={handleMPCItemClick(detailItem)}
                          isCenter={detailItem.center}
                        />
                      ) : detailItem.research && !detailItem.flightPrice ? (
                        <MpcItemResearch
                          key={idxCol}
                          detailItem={detailItem}
                          row={idxRow}
                          col={idxCol}
                        />
                      ) : (
                        <MPCItem
                          empty
                          key={idxCol}
                          status={mpcItemStatus.EMPTY}
                        />
                      )
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <MpcLegend />
        {showModal && modalData && (
          <div className="button-bar">
            <Button
              context="information"
              onClick={() =>
                handleShowAllFlights({
                  initialDate: modalData.initialDate,
                  endDate: modalData.endDate
                })
              }>
              <Icon name="mv-list" color="white" />
              Ver todos los vuelos para estas fechas
            </Button>
          </div>
        )}
      </div>
      {modalData && (
        <Modal show={showModal} onClickOverlay={toggleShowModal} transparent>
          <div style={{ marginTop: 50, marginBottom: 50, width: 884 }}>
            <CloseIconHolder>
              <Icon
                name="mv-cross"
                size={24}
                color="white"
                onClick={toggleShowModal}
              />
            </CloseIconHolder>
            <AirAvailabilityCard
              mpcView
              generalSegment={modalData.generalSegment}
              flightPrice={modalData.flightPrice}
              localPrice={modalData.localPrice}
              initialDate={modalData.initialDate}
              endDate={modalData.endDate}
              pending={modalData.pending}
              routesData={modalData.routesData}
              onClick={modalData.onClick}
              showAllFlights={handleShowAllFlights}
              id={modalData.id}
              toggleShowModal={toggleShowModal}
            />
          </div>
        </Modal>
      )}
      <Modal
        show={showErrorModal}
        onClickOverlay={toggleErrorModal}
        noCard
        modalWidth={700}>
        <AirCheckError close={toggleErrorModal} desktop />
      </Modal>
    </>
  );
};

MpcAvailabilityDesktop.propTypes = {
  className: PropTypes.string.isRequired,
  canDecreaseInitialDate: PropTypes.func.isRequired,
  canSubstract: PropTypes.func.isRequired,
  handleArrowButton: PropTypes.func.isRequired,
  departureDates: PropTypes.array.isRequired,
  arrivalDates: PropTypes.array,
  handleShowAllFlights: PropTypes.func.isRequired,
  toggleShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  modalData: PropTypes.object,
  handleMPCItemClick: PropTypes.func,
  handleResearchMPTBItemClick: PropTypes.func,
  arrowKeys: PropTypes.object,
  detail: PropTypes.array,
  minPrice: PropTypes.number,
  middlePrice: PropTypes.number,
  maxPrice: PropTypes.number,
  showErrorModal: PropTypes.bool,
  toggleErrorModal: PropTypes.func
};

export default styled(MpcAvailabilityDesktop)`
  display: block;

  .calendar-holder {
    width: 1110px;
    border-radius: 14px;
    background-color: #eaeceb;
    overflow: hidden;
    margin-top: 30px;

    .departure-dates {
      height: 45px;
      background-color: ${COLORS.violeta};
      display: flex;
      position: relative;
    }

    .departure-date-item {
      width: ${props => (props.arrivalDates ? '151px' : '159px')};
      height: 20px;
      margin-top: 12px;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    .empty-box {
      width: 53px;
      height: 20px;
    }

    .return-dates {
      position: relative;
      width: 53px;
    }

    .return-date-item {
      text-align: center;
      height: 91px;
      padding-left: 3px;
      padding-right: 3px;
      background-color: ${COLORS.violeta};
      display: flex;
      align-items: center;

      span {
        text-align: center;
      }
    }

    .dates-holder {
      display: flex;

      .mpc-row {
        display: flex;

        > div {
          width: ${props => (props.arrivalDates ? '151px' : '159px')};
        }
      }
    }

    .icon-left {
      position: absolute;
      left: ${props => (props.arrivalDates ? '55px' : '10px')};
      top: 8px;
      cursor: pointer;
    }

    .icon-right {
      position: absolute;
      right: 5px;
      top: 8px;
      cursor: pointer;
    }

    .icon-up {
      position: absolute;
      top: -4px;
      left: 14px;
      cursor: pointer;
    }

    .icon-down {
      position: absolute;
      bottom: -4px;
      left: 14px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .button-bar {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .icon {
      margin-right: 10px;
    }

    .context {
      width: 89.5px;
      height: 25px;
      padding: 5px;
      margin: 5px;
      color: ${COLORS.white};
    }

    .cheaper {
      border-radius: 4px;
      box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.25);
      border: solid 2px ${COLORS.important};
      background-color: ${COLORS.golf};
      font-weight: bold;
      color: ${COLORS.important};
    }
  }
`;
