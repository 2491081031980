import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import PassengersModal from '../PassengersModal';

// Utils
import {
  getCitizen,
  getCountries,
  getDays,
  getMonths,
  getYears,
  getSchema,
  initialFields
} from './helpers';

// Utils
import { useValidatedForm } from 'Utils/hooks/useValidatedForm';

// Constants
import { Container, ListItem, List, Action, Body, Data } from './styles';
import {
  defaultFlightPassengerDocumentsType,
  flightPassengerDocumentsTypes
} from 'Features/checkout/constants';

// @ts-ignore TODO: NO IDEA WHY THIS IS FAILING
import Years from 'Resources/years';
// @ts-ignore
import Months from 'Resources/months';
// @ts-ignore
import Days from 'Resources/days';

const Passengers = ({
  countries,
  flightVuelta,
  flightIda,
  localize,
  actions,
  passengers,
  genders,
  onValid
}) => {
  const [show, setModal] = useState(false);
  const [selectedPassenger, setSelectedPassenger] = useState(null);

  const citizens = useMemo(() => getCountries(countries), [countries]);
  const citizenItem = useMemo(() => getCitizen(citizens, localize), [
    citizens,
    localize
  ]);

  const schema = getSchema(citizenItem);
  const formCallback = () => {
    onSubmitPassenger(fields, selectedPassenger);
  };

  const {
    fields,
    errors,
    handleChange,
    handleValidate,
    handleReset,
    isDisabled
  } = useValidatedForm(schema, formCallback);

  const years = useMemo(
    () => getYears(Years, selectedPassenger, flightIda, flightVuelta),
    [Years, selectedPassenger, flightIda, flightVuelta]
  );

  const months = useMemo(
    () =>
      getMonths(
        Months,
        years,
        selectedPassenger,
        fields.year.name,
        flightIda,
        flightVuelta
      ),
    [
      Months,
      years,
      selectedPassenger,
      fields.year.name,
      flightIda,
      flightVuelta
    ]
  );

  const days = useMemo(
    () =>
      getDays(
        Days,
        years,
        selectedPassenger,
        fields.year.name,
        fields.month,
        flightIda,
        flightVuelta
      ),
    [
      Days,
      years,
      selectedPassenger,
      fields.year.name,
      fields.month,
      flightIda,
      flightVuelta
    ]
  );

  const handleClickAction = passenger => () => {
    setSelectedPassenger(passenger);

    if (passenger.firstNames) {
      handleReset(passenger);
    } else {
      handleReset({
        ...initialFields,
        country: citizenItem,
        documentType: defaultFlightPassengerDocumentsType
      });
    }

    setModal(true);
  };

  const onSubmitPassenger = (data, selectedPassenger) => {
    passengers[selectedPassenger.id] = {
      ...passengers[selectedPassenger.id],
      ...data
    };

    actions.setUserData({
      passengers
    });

    setModal(false);
    setSelectedPassenger(null);
  };

  useEffect(() => {
    onValid(
      passengers.every(
        passenger =>
          passenger &&
          passenger.firstNames &&
          passenger.lastNames &&
          passenger.day.id &&
          passenger.month.id &&
          passenger.year.id &&
          passenger.country &&
          passenger.document &&
          passenger.documentType &&
          passenger.gender.id
      )
    );
  }, [selectedPassenger]);

  return (
    <Container>
      <List>
        {passengers &&
          passengers.map(p => (
            <ListItem key={p.id}>
              <Data>
                <div className="pax-icon-holder">
                  <Icon name="mv-user-circle" size={58} color="important" />
                  <Text context="important">{p.type}</Text>
                </div>

                {p.firstNames && (
                  <Body>
                    <Text className="names">
                      {p.firstNames} {p.lastNames}
                    </Text>

                    <span>
                      {p.documentType.name} {p.country.name} - {p.document}
                    </span>

                    <span>
                      {p.day.id}/{p.month.id}/{p.year.name}
                    </span>

                    <i>{p.isOwner && 'Titular'}</i>
                  </Body>
                )}

                {!p.firstNames && (
                  <Body>
                    <Text className="names">Pasajero</Text>

                    <span>Documento</span>

                    <span>Fecha de nacimiento</span>
                  </Body>
                )}
              </Data>

              <Action isEmpty={!p.firstNames} onClick={handleClickAction(p)}>
                <Icon
                  name={!p.firstNames ? 'mv-arrow-right' : 'mv-writing'}
                  size={20}
                  color="white"
                />

                {p.firstNames ? 'Editar' : 'Cargar Datos'}
              </Action>
            </ListItem>
          ))}
      </List>

      <PassengersModal
        show={show}
        fields={fields}
        errors={errors}
        onClose={setModal}
        onChange={handleChange}
        onValidated={handleValidate}
        months={months}
        days={days}
        years={years}
        citizens={citizens}
        genders={genders}
        isDisabled={isDisabled}
      />
    </Container>
  );
};

Passengers.propTypes = {
  countries: PropTypes.array,
  data: PropTypes.array,
  flightIda: PropTypes.object,
  flightVuelta: PropTypes.object,
  actions: PropTypes.object,
  onSubmit: PropTypes.func,
  passengers: PropTypes.array,
  genders: PropTypes.array,
  onValid: PropTypes.func,
  localize: PropTypes.shape({
    countryCode: PropTypes.string,
    countryName: PropTypes.string,
    city: PropTypes.string,
    citizen: PropTypes.string,
    phoneCode: PropTypes.string
  })
};

export default Passengers;
