/**
 *
 * Calendar
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import {
  isoDateToString,
  timeStringWithoutSeconds,
  getFormatPrice
} from '../../../../Utils';
import GloboToggle from 'Components/GloboToggle';
import Scales from 'Components/Scales';
import Icon from 'Components/Icon';
import AirLineLogo from '../AirLineLogo';
import Text from 'Components/Text';
import Button from 'Components/Button';
import AirFlexTextCommon from '../AirFlexTextCommon';
import Badge from 'Components/Badge';
import { excludeAirlineBaggage } from 'Constants';

const MobileAirAvailabilityCard = ({
  className,
  generalSegment,
  flightPrice,
  localPrice,
  onSelect,
  showPrice,
  showFlight,
  id,
  hasFlex
}) => {
  const [selectedSegments, setSelectedSegments] = useState({
    0: { index: 0 },
    1: { index: 0 }
  });

  const { flexDescription, maxFees } = flightPrice;

  useEffect(() => {
    const ida = generalSegment[0];
    const vuelta = generalSegment[1] ? generalSegment[1] : false;

    setSelectedSegments({
      0: { index: 0, item: ida[0] },
      1: { index: 0, item: vuelta[0] }
    });
  }, [generalSegment]);

  const selectSegment = (segmentIndex, item, indexItem) => {
    setSelectedSegments({
      ...selectedSegments,
      [segmentIndex]: { index: indexItem, item }
    });
  };

  const Travel = props => (
    <div className={cs('TravelContainer', 'Segment')}>
      <div
        className={cs('TravelHeader', 'header', {
          TopRadius: props.travelIndex === 0
        })}>
        {props.travelIndex === 0 ? (
          <Icon name="mv-plane-ida" />
        ) : (
          <Icon name="mv-plane-vuelta" />
        )}
        <span className="white-text-header">
          {props.travelIndex === 0 ? 'IDA' : 'VUELTA'}
        </span>
        <span className="white-text-header alignRight">
          {isoDateToString(
            generalSegment[props.travelIndex][0].departureData.flightDate
          )}
        </span>
      </div>
      {generalSegment[props.travelIndex].map((item, indexItem) => (
        <div key={indexItem}>
          {indexItem > 0 ? <div className="Divider"></div> : ''}
          <div className="SegmentBlock">
            <div className="globoHolder">
              <GloboToggle
                active={indexItem === selectedSegments[props.travelIndex].index}
                onClick={() =>
                  selectSegment(props.travelIndex, item, indexItem)
                }
              />
            </div>
            <div className="main-items">
              <div className="airlinelogo-holder">
                {item.airLines.length > 1 ? (
                  item.airLines.map((airlineItem, indexAirlinItem) => (
                    <AirLineLogo
                      code={airlineItem.code}
                      key={indexAirlinItem}
                    />
                  ))
                ) : (
                  <AirLineLogo
                    code={item.airLines[0].code}
                    name={item.airLines[0].name}
                    showName
                  />
                )}
              </div>
              <div className="main-bottom">
                <div className="main-bottom-row">
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>{item.departureData.airport.code}</Text>
                    <Text weight="semi-bold">
                      {timeStringWithoutSeconds(item.departureData.flightTime)}
                    </Text>
                  </div>
                  <div
                    className="scales-holder"
                    onClick={() => showFlight(item, props.travelIndex + 1)}>
                    <Scales scales={item.scales} />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>{item.arrivalData.airport.code}</Text>
                    <Text weight="semi-bold">
                      {timeStringWithoutSeconds(item.arrivalData.flightTime)}
                    </Text>
                  </div>
                </div>
                <div className="main-bottom-row left">
                  <Text size="small" weight="semi-bold">
                    {item.duration.totalHours > 1
                      ? `Duración ${item.duration.totalHours}h`
                      : `Duración ${item.duration.totalHours}hs`}
                    {item.duration.totalMinutes > 0
                      ? `${item.duration.totalMinutes}m`
                      : null}
                  </Text>
                </div>
              </div>
            </div>
            <div className="right-items">
              <div onClick={() => showFlight(item, props.travelIndex + 1)}>
                <Badge
                  inverted
                  leftIcon="mv-information-circle"
                  variation="information">
                  +info
                </Badge>
              </div>
              <div className="baggage-icons-holder">
                <Icon
                  name="mv-briefcase"
                  className={cs('briefcaseIcon', {
                    disabled: item.baggage.total === 0
                  })}
                />
                <Icon
                  name="mv-hand-bag"
                  className={cs('handBagIcon', {
                    disabled:
                      item.baggage.total === 0 &&
                      excludeAirlineBaggage.includes(item.airLines[0].code)
                  })}
                />
                <Icon name="mv-backpack" className="handBagIcon" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  Travel.propTypes = {
    travelIndex: PropTypes.number.isRequired
  };

  const Footer = () => (
    <div className="Footer BottomRadius ">
      <div className="actions">
        <div onClick={showPrice}>
          <Icon name="mv-information-circle" className="price-icon" />
        </div>
        <div className="footer-prices">
          {flightPrice && (
            <Text context="important" weight="semi-bold" size="large">
              {getFormatPrice(flightPrice.totalPrice, flightPrice.currency)}
            </Text>
          )}
          {localPrice && (
            <Text context="important" weight="semi-bold" size="small">
              {getFormatPrice(localPrice.totalPrice, localPrice.currency, true)}
            </Text>
          )}
          {(flightPrice || localPrice) && (
            <Text context="important">Precio total final</Text>
          )}
        </div>
        <Button
          right
          onClick={() =>
            onSelect(
              { ida: selectedSegments[0], vuelta: selectedSegments[1] },
              id
            )
          }>
          Seleccionar
        </Button>
      </div>
      {maxFees && <Text>¡Hasta en {maxFees} cuotas! </Text>}
    </div>
  );

  return (
    <FlexibleContainer hasFlex={hasFlex}>
      {hasFlex && (
        <FlexText>
          <Icon name="mv-timeleft" size={28} color="white" />
          <span>
            <AirFlexTextCommon />
            {flexDescription}
          </span>
        </FlexText>
      )}
      <div className={cs(className)}>
        {generalSegment.map((s, i) => (
          <Travel key={i} travelIndex={i} />
        ))}
        <Footer />
      </div>
    </FlexibleContainer>
  );
};

export const FlexibleContainer = styled.div`
  margin-bottom: 12px;
  ${({ hasFlex, theme }) =>
    hasFlex &&
    css`
      border-radius: 14px;
      background-color: ${theme.palette.primary.orange};
      padding-top: ${theme.spacing.two};
      padding-bottom: ${theme.spacing.two};
      .holder-detail-prices .header--mpc: {
        border-radius: 0 !important;
      }
    `}
`;

export const FlexText = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    width: 100%;
    padding: ${theme.spacing.two};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    .icon {
      margin-right: ${theme.spacing.three};
    }
  `}
`;

MobileAirAvailabilityCard.propTypes = {
  className: PropTypes.string.isRequired,
  generalSegment: PropTypes.array.isRequired,
  flightPrice: PropTypes.object,
  localPrice: PropTypes.object,
  onSelect: PropTypes.func,
  showPrice: PropTypes.func,
  showFlight: PropTypes.func,
  hasFlex: PropTypes.bool,
  id: PropTypes.string
};

export default styled(MobileAirAvailabilityCard)`
  position: relative;
  & > div {
    width: 100%;
  }

  .info-holder {
    display: inline-flex
  }

  .modal-width {
    width: 100%;
  }

  .Divider {
    width: 100%;
    height: 2px;
    background-color: ${COLORS.navBarGrey};
  }

  .Footer {
    width: 100%;
    background-color: ${COLORS.white};
    padding-bottom: 7px;
    padding-top: 7px;
    display: flex;
    align-items: center;
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.35);
    flex-direction: column;
    .actions {
      display: flex;
      width: 100%;
    }

    .footer-prices {
      display: flex;
      flex-direction: column;
    }

    .price-icon {
      margin-left: 7px;
      margin-right: 9px;
      font-size: 35px;
      color: ${COLORS.important};
      display: flex;
      align-items: center;
    }
  }

  /* .iconInformation {
    font-size: 29px;
    color: ${COLORS.information};
  } */

  .SegmentBlock {
    width: 100%;
    background-color: ${COLORS.white};
    display: flex;
    align-items: center;
    justify-content: space-between;

    .airlinelogo-holder {
      display: flex;
      flex-direction: row;
    }

    .main-items {
      display: flex;
      flex-direction: column;
      flex: 1;

      .main-bottom {
        display: flex;
        flex-direction: column;
      }

      .main-bottom-row {
        display: flex;
        justify-content: space-around;

        &.left {
          justify-content: flex-start;
        }
      }
    }

    .right-items {
      margin-right: 4px;
      display: grid;
      place-items: center;
    }
  }

  .globoHolder {
    margin-right: 22px;
    margin-left: 7px;
  }

  .TravelHeader {
    width: 100%;
    height: 26px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.violeta};
    display: flex;
    align-items: center;
  }

  .TopRadius {
    border-radius: 14px 14px 0 0;
  }

  .BottomRadius {
    border-radius: 0 0 14px 14px;
  }

  .TravelContainer {
    width: 100%;
    height: 100%;
  }

  .Segment {
    .header {
      display: flex;
      background: ${COLORS.deluge};
      height: 25px;
      padding: 8px 8px 6px 8px;
      flex: 1;
      align-items: center;

      .icon {
        color: ${COLORS.white};
        font-size: 18px;
        position: relative;
        margin-right: 10px;
        margin-left: 4px;
      }

      .orange-header {
        font-family: Montserrat, Arial, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 14px;
        letter-spacing: 0.14px;
        text-transform: uppercase;
        text-align: left;
        color: ${COLORS.important};
        margin-right: 12px;
      }

      .white-text-header {
        font-family: Montserrat, Arial, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 14px;
        letter-spacing: 0.14px;
        text-align: left;
        color: ${COLORS.white};
      }

      .alignRight {
        position: absolute;
        right: 18px;
      }
    }
  }

  .baggage-icons-holder {
    display: flex;
    align-items: baseline;

    .briefcaseIcon {
      font-size: 37px;
      color: ${COLORS.important};

      &.disabled {
        color: ${COLORS.darkGrey};
      }
    }

    .handBagIcon {
      font-size: 20px;
      color: ${COLORS.important};
      margin-left: 2px;
      &.disabled {
        color: ${COLORS.darkGrey};
      }
    }
  }
`;
