/**
 *
 * PrivateRoute
 *
 */

import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'ReduxConfig/store';
import Loader from '../Loader';

interface PrivateRouteProps extends RouteProps {
  redirectTo: string;
}

const userSelector = ({ users }: RootState) => ({
  user: users.user,
  status: users.status
});

const PrivateRoute = ({ redirectTo, ...rest }: PrivateRouteProps) => {
  const { user, status } = useSelector(userSelector);
  if (status !== 'ready') {
    // @ts-ignore
    return <Loader />;
  }
  if (!user) {
    return <Redirect to={redirectTo} />;
  }
  return <Route {...rest} />;
};

export default PrivateRoute;
