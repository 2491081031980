import styled, { css } from 'styled-components';

export const List = styled.ul`
  padding: ${({ theme }) => theme.spacing.four};
`;

export const Fee = styled.li<any>`
  margin-bottom: ${({ theme }) => theme.spacing.two};
  box-shadow: ${({ theme }) => theme.shadows.one};
  padding: ${({ theme }) => theme.spacing.four};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  display: flex;
  align-items: center;
  cursor: pointer;
  list-style: none;
  border: 2px solid transparent;
  transition: border-color 300ms ease, background-color 320ms ease;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border-color: ${theme.colors.important};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.greyHoverBkg};
  }
`;

export const Body = styled.div`
  flex: 1;
`;

export const Action = styled.div``;
