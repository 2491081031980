export default (
  /** HEX Color string Required */
  color: string,
  /** Width in pixels, defaults to 20 */
  width: number = 20
) => `
overflow-y: auto;

-webkit-overflow-scrolling: touch;

-ms-overflow-style: -ms-autohiding-scrollbar;

/* WEBKIT MOTORS */

&::-webkit-scrollbar {
  width: ${width}px;
}

&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 ${width / 2}px ${color};
  border-radius: 10px;
  border: solid ${width / 4}px transparent;
  background-clip: padding-box;
}

&::-webkit-scrollbar-thumb {
  background: ${color};
  border-radius: 10px;
  border: solid ${width / 4}px transparent;
  background-clip: padding-box;
}

&::-webkit-scrollbar-thumb:hover {
  background: ${color};
  border: solid ${width / 4}px transparent;
  background-clip: padding-box;
}
`;
