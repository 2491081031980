import { initialState, IQuotationState } from './constants';
import {
  GET_QUOTATIONS_LIST_FAILURE,
  GET_QUOTATIONS_LIST_REQUEST,
  GET_QUOTATIONS_LIST_SUCCESS,
  GET_QUOTATION_DETAILS_REQUEST,
  GET_QUOTATION_DETAILS_FAILURE,
  GET_QUOTATION_DETAILS_SUCCESS,
  DELETE_FLIGHT_QUOTATION_FAILURE,
  DELETE_FLIGHT_QUOTATION_SUCCESS,
  TQuotationAction
} from './actionTypes';

export default function quotationsReducer(
  state: IQuotationState = initialState,
  action: TQuotationAction
): IQuotationState {
  switch (action.type) {
    case GET_QUOTATIONS_LIST_REQUEST:
      return {
        ...state,
        pending: true
      };

    case GET_QUOTATIONS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        list: action.payload.data,
        pagination: {
          ...state.pagination,
          currentPage: Number(action.payload.headers['X-Page']),
          totalItems: Number(action.payload.headers['X-Page-Size']),
          totalPages: Math.ceil(
            action.payload.headers['X-Page-Total'] / state.pagination.limit
          )
        }
      };

    case GET_QUOTATIONS_LIST_FAILURE:
      return {
        ...state,
        pending: false
      };

    case GET_QUOTATION_DETAILS_REQUEST:
      return {
        ...state,
        pending: true
      };

    case GET_QUOTATION_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case GET_QUOTATION_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        detail: action.payload
      };

    case DELETE_FLIGHT_QUOTATION_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case DELETE_FLIGHT_QUOTATION_SUCCESS: {
      if (state.detail.id === action.payload.id) {
        const newFlightQuotations = state.detail.flightQuotations.filter(
          quotation => quotation.id !== action.payload.flightId
        );
        return {
          ...state,
          pending: false,
          detail: {
            ...state.detail,
            flightQuotations: newFlightQuotations
          }
        };
      } else {
        return {
          ...state,
          pending: false
        };
      }
    }

    default:
      return state;
  }
}
