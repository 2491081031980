/**
 *
 * MeteorologyGraphGraph
 *
 */

import React from 'react';
import { chunk } from 'lodash';

// Components
import MeteorologySeasons from '../MeteorologySeasons';
import MeteorologyBar from '../MeteorologyBar';
import { Container, Months, Month, Seasons } from './styles';

// Types
import { MeteorologyGraphProps } from './types';
import { TSeason } from '../../types';

// Utils
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { getMinMax } from './utils';
import { TTemperatureColors } from '../MeteorologyColor/types';

const seasonColors: { [key in TSeason]: TTemperatureColors } = {
  Otono: 'shipCove',
  Invierno: 'cornFlower',
  Primavera: 'goldenRod',
  Verano: 'atomicTangerine'
};

const MeteorologyGraph = ({
  temperaturesData,
  activeGraph,
  seasons
}: MeteorologyGraphProps) => {
  const typeGraph = activeGraph !== 'ocean' ? 'temperature' : 'sea';
  const symbol = activeGraph !== 'rainy' && 'º';
  const breakpoints = useBreakpoints();

  const months =
    temperaturesData[activeGraph] &&
    Object.keys(temperaturesData[activeGraph]).map((key, index) => (
      <MeteorologyBar
        key={index}
        type={typeGraph}
        month={key}
        symbol={symbol}
        minMax={getMinMax(temperaturesData[activeGraph])}
        temperature={temperaturesData[activeGraph][key]}
      />
    ));

  const monthsbySeason = chunk(months, 3);

  return (
    <Container>
      {!breakpoints.mediumDown && (
        <>
          <Months>
            {monthsbySeason.map((months, i) => (
              <Month key={i}>{months}</Month>
            ))}
          </Months>

          <Seasons>
            {seasons.map(season => (
              <MeteorologySeasons
                key={season}
                season={season === 'Otono' ? 'Otoño' : season}
                color={seasonColors[season]}
              />
            ))}
          </Seasons>
        </>
      )}

      {breakpoints.mediumDown && (
        <Months>
          {monthsbySeason.map((month, i) => (
            <>
              <MeteorologySeasons
                key={seasons[i]}
                season={seasons[i] === 'Otono' ? 'Otoño' : seasons[i]}
                color={seasonColors[seasons[i]]}
              />

              <Month key={i}>{month}</Month>
            </>
          ))}
        </Months>
      )}
    </Container>
  );
};

export default MeteorologyGraph;
