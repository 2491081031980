/**
 *
 * ArrowSelector
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../Icon';

const ArrowSelector = ({
  content,
  onClickLeft,
  onClickRight,
  disabledLeft,
  disabledRight
}) => (
  <Container>
    <Arrow
      disabled={disabledLeft}
      isLeft
      onClick={!disabledLeft && onClickLeft}>
      <Icon name="mv-arrow-left" size={24} color="white" />
    </Arrow>
    <Content>{content}</Content>
    <Arrow disabled={disabledRight} onClick={!disabledRight && onClickRight}>
      <Icon name="mv-arrow-right" size={24} color="white" />
    </Arrow>
  </Container>
);

ArrowSelector.propTypes = {
  onClickLeft: PropTypes.func.isRequired,
  onClickRight: PropTypes.func.isRequired,
  content: PropTypes.node,
  disabledLeft: PropTypes.bool,
  disabledRight: PropTypes.bool
};

const Container = styled.div`
  display: flex;
  height: 50px;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.5 ease-in-out;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.shadows.two};
  ${({ isLeft }) =>
    isLeft ? 'border-radius: 14px 0 0 14px' : 'border-radius: 0 14px 14px 0'};
  ${({ disabled, theme, isLeft }) =>
    disabled
      ? `background: linear-gradient(
        ${isLeft ? 'to left' : 'to right'},
        ${theme.colors.darkGrey},
        ${theme.colors.lightGrey}
        );`
      : `background: linear-gradient(
        ${isLeft ? 'to right' : 'to left'},
          ${theme.colors.orange},
          ${theme.colors.darkOrange}
      );
  `}
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.one};
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  min-width: 100px;
  flex: 1;
`;

export default ArrowSelector;
