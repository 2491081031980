/**
 *
 * ArticleContainer
 *
 */

import React from 'react';
import styled from 'styled-components';
import cs from 'classnames';
import get from 'lodash.get';
import * as COLORS from 'Constants/colors';
import Markdown from 'markdown-to-jsx';

import Text from 'Components/Text';
import { returnArticleUrl, returnCreatedDate } from 'Features/blog/helpers';
import Divider from 'Components/Divider';
import BlogCard from '../BlogCard';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import BlogBreadcrumbs from '../BlogBreadcrumbs';
import { Helmet } from 'react-helmet';
import { BlogArticle, BasicBlogArticle } from 'Services/Blog/types';

interface ArticleContainerProps {
  className?: string;
  actions: any;
  articleId: string;
  article: BlogArticle;
  latestArticles: BasicBlogArticle[];
}

const ArticleContainer = ({
  className,
  article,
  latestArticles
}: ArticleContainerProps) => {
  const { largeDown, mediumDown } = useBreakpoints();
  const itemClassName = cs('item', {
    medium: mediumDown,
    large: largeDown
  });

  return (
    <div className={cs(className)}>
      {article && <BlogBreadcrumbs title={article.name} className="" />}

      <div className="content-body">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {' '}
            {`${get(article, 'meta.title', article.metaTitle)} | mevuelo.com`}
          </title>
          <meta
            name="description"
            content={`${get(
              article,
              'meta.description',
              article.metaDescription
            )} | Blog mevuelo.com`}
          />
          <meta
            name="keywords"
            content={`${get(
              article,
              'meta.keywords',
              article.metaKeywords
            )} | Blog mevuelo.com`}
          />
          <meta property="og:image" content={article.mainPhoto.publicUrl} />
          <meta property="og:title" content={article.name} />
          <meta
            property="og:description"
            content={`${get(
              article,
              'meta.description',
              article.metaDescription
            )} | Blog mevuelo.com`}
          />
          <meta property="og:type" content="article" />
        </Helmet>
        <div className="article">
          <img
            className="article-main-image"
            alt={article.name}
            src={article.mainPhoto.publicUrl}
          />
          <div className="content-header">
            <Text h1 size="xlarge" context="default" weight="semi-bold">
              {article.name}
            </Text>
            <div className="created-date">
              <Text size="small" weight="regular">
                {returnCreatedDate(article.createdDate)} - {article.timeToRead}{' '}
                min read
              </Text>
            </div>
          </div>

          <div className="author">
            <div className={cs('author_background')} />
            <div
              className="author_avatar"
              style={{
                backgroundImage: `url(${article.author.photo.publicUrl})`
              }}
            />
            <div className="author_name">
              <Text context="white">por {article.author.name}</Text>
            </div>
          </div>

          <div className="md-content">
            <Markdown>{article.content}</Markdown>
          </div>
        </div>
      </div>

      <div className="latest-articles">
        <Divider
          titleText="ARTÍCULOS RECIENTES"
          subtitleText="No te pierdas las ultimas experiencias de nuestros viajeros"
          size="default"
          context="default"
        />

        <div className={cs('articles-list', 'container')}>
          {latestArticles.map(item => {
            const date = returnCreatedDate(item.createdDate);
            return (
              <div className={itemClassName} key={item.id}>
                {
                  // TODO
                  // @ts-ignore
                  <BlogCard
                    imageUrl={item.thumbnailPhoto.publicUrl}
                    avatarUrl={item.author.photo.publicUrl}
                    author={item.author.name}
                    url={returnArticleUrl(item.url)}
                    date={date}
                    timeRead={item.timeToRead}
                    title={item.name}
                  />
                }
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default styled(ArticleContainer)`
  max-width: 1110px;
  margin: 0 auto;
  .content-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    .article {
      width: 100%;
      border-radius: 14px;
      overflow: hidden;

      @media all and (max-width: 960px) {
        border-radius: 0;
      }

      .article-main-image {
        width: 100%;
        background-color: ${COLORS.white};
      }

      .content-header {
        background: ${COLORS.white};
        padding: 2px 30px;

        .created-date {
          text-align: right;
        }
      }

      .md-content {
        background-color: ${COLORS.white};
        padding: 30px;

        div {
          p {
            img {
              width: 100%;
            }
          }
        }
      }

      .author {
        height: 30px;
        position: relative;
        top: calc(100% - 30px);

        &_avatar {
          width: 70px;
          height: 70px;
          background-position: center;
          background-size: cover;
          border-radius: 50%;
          position: absolute;
          left: 19px;
          top: -22px;
        }

        &_name {
          margin-left: 108px;
          position: absolute;
          top: 0;
        }

        &_background {
          position: absolute;
          opacity: 0.75;
          background-image: ${COLORS.gradientEminenceDeluge};
          width: 100%;
          height: 100%;

          &.important {
            background-image: ${COLORS.gradientImportant};
          }

          &.positive {
            background-image: ${COLORS.gradientPositive};
          }

          &.information {
            background-image: ${COLORS.gradientInfo};
          }
        }
      }
    }
  }

  .latest-articles {
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    .articles-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        display: flex;
        flex: 0 1 33%;

        &.large {
          width: 50%;
        }

        &.medium {
          width: 100%;
          margin-top: 25px;
          padding: 0;
          flex: 1 1 auto;
        }
      }
    }
  }

  .most-read-categories {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }

  .mv-divider-holder {
    max-width: 1110px;
    width: 100%;
    margin: 0 auto;
  }
`;
